import mastercardLogo from 'app/assets/png/mastercard-logo.png';
import visaLogo from 'app/assets/png/visa-logo.png';
import americanExpressLogo from 'app/assets/png/american-express-logo.png';
import maestroLogo from 'app/assets/png/maestro-logo.png';
import visaElectronLogo from 'app/assets/png/visa-electron-logo.png';
import defaultCardLogo from 'app/assets/png/card-default-logo.png';

export const LOGOS_MAP = {
  MASTERCARD: mastercardLogo,
  VISA: visaLogo,
  AMERICAN_EXPRESS: americanExpressLogo,
  VISAELECTRON: visaElectronLogo,
  MAESTRO: maestroLogo,
  CARD_DEFAULT: defaultCardLogo
};

export const TYPE_CARD_MAP = {
  CREDIT: 'controlpanel.global.credit_card',
  DEBIT: 'controlpanel.global.debit_card'
};
