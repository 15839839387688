import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from 'app/utils/currency';

export const FormatAmount = ({ text }) => (
  <p className="small purse-data-table currency">{formatCurrency(text)}</p>
);

FormatAmount.propTypes = {
  text: PropTypes.number.isRequired
};
