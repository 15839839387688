import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import Icon from 'app/components/Icon';
import { STATUS_MAP } from 'app/modules/Devolutions/components/TableProducts/formatters/ProductFormatter/utils/constants';

const StatusFormatter = ({ status }) => {
  const intl = useIntl();
  const { color, backgroundColor, translation } = useMemo(
    () => ({
      color: STATUS_MAP?.[status]?.color || '',
      backgroundColor: STATUS_MAP?.[status]?.backgroundColor || '',
      translation: STATUS_MAP?.[status]?.translation || ''
    }),
    [status]
  );

  return (
    <span
      role="none"
      className="bb-trackings__data-table__cell--tag"
      style={{
        backgroundColor
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className="bb-trackings__data-table__cell medium"
        style={{ color }}
      >
        {translation ? intl.formatMessage({ id: translation }) : ''}
        {status === 'OUTDATED' && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {intl.formatMessage({
                  id: 'controlpanel.rma.outdated_warning'
                })}
              </Tooltip>
            }
          >
            <div className="bb-trackings__data-table__cell--tag-tooltip">
              <Icon size="1x" icon="square-info" color={color} />
            </div>
          </OverlayTrigger>
        )}
      </label>
    </span>
  );
};

StatusFormatter.propTypes = {
  status: PropTypes.string.isRequired
};

export default StatusFormatter;
