import React from 'react';

import { getMultipleSelectDefaultValues, Select } from 'app/components/Select';
import { SelectDisable } from 'app/components/DataTableFilters/Components';
import Checkbox from 'app/components/Checkbox';
import Input from 'app/components/Input';
import RangeSlider from 'app/components/RangeSlider';
import SelectDateRange from 'app/components/SelectDateRange';

export const INITIAL_VALUES = {
  amount: [],
  dropshipping: '',
  virtual: '',
  date: {
    start: '',
    end: ''
  },
  invoice: '',
  payment: '',
  status: '',
  expedition: {
    start: '',
    end: ''
  }
};

export const getFiltersConfiguration = ({
  intl,
  formik,
  statusOptions,
  maxTotalAmount,
  paymentMethods
}) => [
  {
    value: 'all',
    label: intl.formatMessage({ id: 'controlpanel.global.all' }),
    disabled: false,
    component: (
      <SelectDisable
        text={intl.formatMessage({
          id: 'controlpanel.global.placeholder.select'
        })}
      />
    )
  },
  {
    value: 'date',
    label: intl.formatMessage({ id: 'controlpanel.global.order_date' }),
    disabled: false,
    component: (
      <SelectDateRange
        nameStartDate="date.start"
        nameEndDate="date.end"
        dateFormat="yyyy-MM-dd"
        defaultStartDate={formik.values?.date?.start}
        defaultEndDate={formik.values?.date?.end}
      />
    )
  },
  {
    value: 'payment',
    label: intl.formatMessage({
      id: 'controlpanel.global.paymentmethod'
    }),
    disabled: false,
    component: (
      <Select
        multiple
        formik={formik}
        name="payment"
        id="payment"
        defaultValue={getMultipleSelectDefaultValues(paymentMethods, [
          formik.values?.payment
        ])}
        options={paymentMethods}
      />
    )
  },
  {
    value: 'dropshipping',
    label: intl.formatMessage({
      id: 'controlpanel.orders.filters.dropshipping'
    }),
    disabled: false,
    component: (
      <div data-id="dropshipping">
        <Checkbox
          formik={formik}
          id="radio1"
          name="dropshipping"
          type="radio"
          label="Si"
          value="true"
        />
        <Checkbox
          formik={formik}
          id="radio2"
          name="dropshipping"
          type="radio"
          label="No"
          value="false"
        />
      </div>
    )
  },
  {
    value: 'virtual',
    label: intl.formatMessage({
      id: 'controlpanel.orders.filters.type_order'
    }),
    disabled: false,
    component: (
      <div data-id="virtual">
        <Checkbox
          formik={formik}
          id="radio1"
          name="virtual"
          type="radio"
          label={intl.formatMessage({
            id: 'controlpanel.orders.filters.virtual.order_products_tag'
          })}
          value="false"
        />
        <Checkbox
          formik={formik}
          id="radio2"
          name="virtual"
          type="radio"
          label={intl.formatMessage({
            id: 'controlpanel.orders.filters.virtual.order_services_tag'
          })}
          value="true"
        />
      </div>
    )
  },
  {
    value: 'amount',
    label: intl.formatMessage({
      id: 'controlpanel.orders.filters.amount'
    }),
    disabled: false,
    component: (
      <RangeSlider
        initialValue={
          formik.values?.amount?.length === 2
            ? formik.values?.amount
            : [0, maxTotalAmount]
        }
        min={0}
        max={maxTotalAmount}
        name="amount"
      />
    )
  },
  {
    value: 'expedition',
    label: intl.formatMessage({
      id: 'controlpanel.global.filters.expedition'
    }),
    disabled: false,
    component: (
      <SelectDateRange
        nameStartDate="expedition.start"
        nameEndDate="expedition.end"
        dateFormat="yyyy-MM-dd"
        defaultStartDate={formik.values?.expedition?.start}
        defaultEndDate={formik.values?.expedition?.end}
      />
    )
  },
  {
    value: 'invoice',
    label: intl.formatMessage({
      id: 'controlpanel.orders.filters.invoice'
    }),
    disabled: false,
    component: (
      <div data-id="invoice">
        <Input
          name="invoice"
          placeholder={intl.formatMessage({
            id: 'controlpanel.orders.filters.invoice'
          })}
        />
      </div>
    )
  },
  {
    value: 'addressee',
    label: intl.formatMessage({
      id: 'controlpanel.global.filter.addressee'
    }),
    disabled: false,
    component: (
      <div data-id="addressee">
        <Input
          name="addressee"
          placeholder={intl.formatMessage({
            id: 'controlpanel.global.filter.addressee'
          })}
        />
      </div>
    )
  },
  {
    value: 'status',
    label: intl.formatMessage({
      id: 'controlpanel.orders.filters.status'
    }),
    disabled: false,
    component: (
      <Select
        multiple
        formik={formik}
        name="status"
        id="status"
        defaultValue={getMultipleSelectDefaultValues(statusOptions, [
          formik.values?.status
        ])}
        options={statusOptions}
      />
    )
  }
];
