import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { map } from 'lodash';

import Icon from 'app/components/Icon';
import { Colors } from 'app/utils/colors';
import { CUSTOMER_TYPES } from 'app/utils/constants';

import 'app/modules/MyAccount/pages/Billing/components/CustomerType/CustomerType.scss';

export default function CustomerType({ customerType }) {
  return (
    <Row className="customer-type">
      <Col md={3} xs={0} />
      <Col md={7} xs={12} className="customer-type__container">
        {map(CUSTOMER_TYPES, (type) => {
          const { id, key, text, icon } = type;
          const bgColor =
            id === customerType ? Colors.bbPrimary : Colors.bbGrey3;

          return (
            <div className="customer-type__container-element" key={id}>
              <Icon icon={icon} size="5x" color={bgColor} />
              <p
                className={classNames({
                  medium: key === customerType
                })}
              >
                {text}
              </p>
            </div>
          );
        })}
      </Col>
    </Row>
  );
}

CustomerType.propTypes = {
  customerType: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};
