import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';

import { formatDateToLocale } from 'app/utils/dates';
import { PACK_TYPE_NUMBER } from 'app/utils/constants';
import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';
import ModalLanguages from 'app/modules/Downloads/components/ModalLanguages';
import ModalWrongPack from 'app/components/ModalWrongPack';

import 'app/modules/Downloads/components/CategoryList/CategoryList.scss';
import { getMediaZipFiles } from 'app/api/downloads/getMediaZipFiles';
import Spinner from 'app/components/Spinner';

export default function CategoryList({ list }) {
  const [idsLang, setIdsLang] = useState([]);
  const [idCategory, setIdCategory] = useState(0);
  const [viewModal, setViewModal] = useState(false);
  const [title, setTitle] = useState('');
  const [showModalWrongPack, setModalWrongPack] = useState(false);
  const [loading, setLoading] = useState(false);

  if (loading) return <Spinner />;

  return (
    <>
      <ModalWrongPack show={showModalWrongPack} setShow={setModalWrongPack} />
      <ModalLanguages
        title={title}
        idCategory={idCategory}
        setIdCategory={setIdCategory}
        viewModal={viewModal}
        setViewModal={setViewModal}
        idsLang={idsLang}
        setIdsLang={setIdsLang}
      />
      <div className="category-list">
        {map(list, (section, key) => (
          <div className="category-list__section" key={key}>
            <p className="category-list__section-title">
              {section?.attributes?.title}
            </p>
            <div>
              {map(section?.attributes?.elements, (category, index) => (
                <Children
                  idSection={section?.id}
                  downloadable={section?.attributes?.downloadable}
                  key={index}
                  data={category}
                  setIdCategory={setIdCategory}
                  setViewModal={setViewModal}
                  setTitle={setTitle}
                  setModalWrongPack={setModalWrongPack}
                  setLoading={setLoading}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

CategoryList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const Children = ({
  data,
  setViewModal,
  setIdCategory,
  setTitle,
  setModalWrongPack,
  idSection,
  downloadable,
  setLoading
}) => {
  const { type, name, date } = data;

  const {
    user: {
      attributes: { packId }
    }
  } = useSelector((state) => state.auth);

  const clickFnc = () => {
    if (packId === PACK_TYPE_NUMBER?.PRO) {
      setLoading(true);
      (async () => {
        const request = await getMediaZipFiles([idSection], 'csv-section', []);
        if (request?.links?.download_url) {
          window.open(request?.links?.download_url);
        }
        setLoading(false);
      })();

      return true;
    }

    if (downloadable && packId !== PACK_TYPE_NUMBER?.PRO) {
      setViewModal(true);
      setIdCategory(idSection);
      setTitle(name);
      return true;
    }

    setModalWrongPack(true);
    return true;
  };

  return (
    <div
      className="category-list__section-children"
      onClick={clickFnc}
      role="button"
      onKeyDown={clickFnc}
      tabIndex={0}
    >
      <div className="icon">
        <SVG
          src={toAbsoluteUrl(`/media/svg/bbfiles/${type.toLowerCase()}.svg`)}
        />
      </div>
      <div className="description">
        <p className="small bold">{name}</p>
        <p className="date small">{formatDateToLocale(date * 1000)}</p>
      </div>
    </div>
  );
};

Children.propTypes = {
  idSection: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.number
  }).isRequired,
  setViewModal: PropTypes.func.isRequired,
  setIdCategory: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  setModalWrongPack: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  downloadable: PropTypes.bool.isRequired
};
