import useRmaStatus from 'app/hooks/useRmaStatus.hook';
import { useIntl } from 'react-intl';

import { isMobile } from 'app/utils/sizes';

export default function Columns(status) {
  const intl = useIntl();
  const rmaStatus = useRmaStatus();
  return [
    {
      dataField: 'id',
      text: 'id',
      sort: false,
      hidden: true
    },
    {
      dataField: 'product',
      text: intl.formatMessage({ id: 'controlpanel.global.product' }),
      sort: true,
      hidden: false
    },
    {
      dataField: 'sku',
      text: 'SKU',
      sort: true,
      hidden: false
    },
    {
      dataField: 'orderId',
      text: intl.formatMessage({ id: 'controlpanel.global.order_number' }),
      sort: true,
      hidden: false
    },
    {
      dataField: 'orderDate',
      text: intl.formatMessage({ id: 'controlpanel.global.order_date' }),
      sort: true,
      hidden: false
    },
    {
      dataField: 'returnReason',
      text: intl.formatMessage({ id: 'controlpanel.global.issue_description' }),
      sort: true,
      hidden: isMobile(),
      classes: 'rma-editable-row'
    },
    {
      dataField: 'quantity',
      text: intl.formatMessage({
        id: 'controlpanel.orders.view.detail_table.quantity'
      }),
      sort: true,
      hidden: false,
      classes: 'rma-editable-row'
    },
    {
      dataField: 'price',
      text: intl.formatMessage({ id: 'controlpanel.global.amount' }),
      sort: true,
      hidden: false
    },
    {
      dataField: 'statusCol',
      text: intl.formatMessage({ id: 'controlpanel.global.filter.status' }),
      sort: true
    },
    {
      dataField: 'totalReturned',
      text: intl.formatMessage({ id: 'controlpanel.global.total_returned' }),
      sort: true,
      hidden: !(
        rmaStatus[status]?.status === 'PAID' ||
        rmaStatus[status]?.status === 'PENDING_PAYMENT'
      )
    },
    {
      dataField: 'actions',
      text: intl.formatMessage({ id: 'controlpanel.global.table_actions' }),
      classes: 'column-action',
      sort: false,
      hidden: rmaStatus[status]?.status !== 'DRAFT'
    }
  ];
}
