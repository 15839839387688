import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { URLS } from 'app/config/urls';
import AsideMenuItem from 'app/components/Aside/aside-menu/AsideMenuItem';
import useGetPermisionsSection from 'app/hooks/useGetPermisionsSection.hook';
import { ACADEMY_NAME } from 'app/utils/constants';

export function AsideMenuList() {
  const intl = useIntl();

  const { user } = useSelector((state) => state.auth);
  const [linkMip] = useState(user?.attributes.linkMip);
  const showAcademy = useGetPermisionsSection('academy');
  return (
    <ul className="menu-nav">
      <AsideMenuItem
        url={URLS.dashboard}
        icon="layer-group"
        iconClass="bb-icon"
        title={intl.formatMessage({ id: 'controlpanel.asidemenu.dashboard' })}
      />
      <AsideMenuItem
        url={`${URLS.myAccount}/${URLS.myAccountSummary}`}
        icon="user"
        iconClass="bb-icon"
        title={intl.formatMessage({
          id: 'controlpanel.asidemenu.my_account'
        })}
      />
      <AsideMenuItem
        url={URLS.orders}
        icon="clipboard"
        iconClass="bb-icon"
        title={intl.formatMessage({ id: 'controlpanel.asidemenu.orders' })}
      />
      <AsideMenuItem
        url={URLS.tracking}
        icon="map-marker-alt"
        iconClass="bb-icon"
        title={intl.formatMessage({ id: 'controlpanel.asidemenu.tracking' })}
      />
      <AsideMenuItem
        url={URLS.invoices}
        icon="file-invoice-dollar"
        iconClass="bb-icon"
        title={intl.formatMessage({ id: 'controlpanel.asidemenu.invoices' })}
      />
      <AsideMenuItem
        url={URLS.devolutions}
        icon="retweet"
        iconClass="bb-icon"
        title={intl.formatMessage({
          id: 'controlpanel.asidemenu.devolutions'
        })}
      />
      <AsideMenuItem
        url={URLS.purse}
        icon="database"
        iconClass="bb-icon"
        title={intl.formatMessage({ id: 'controlpanel.asidemenu.purse' })}
      />
      <AsideMenuItem
        url={URLS.shops}
        icon="store"
        iconClass="bb-icon"
        title={intl.formatMessage({ id: 'controlpanel.asidemenu.shops' })}
      />
      <AsideMenuItem
        url={URLS.downloadsMKT}
        icon="download"
        iconClass="bb-icon"
        title={intl.formatMessage({ id: 'controlpanel.asidemenu.downloads' })}
      />
      <AsideMenuItem
        url={URLS.wishlist}
        icon="heart-square"
        iconClass="bb-icon"
        title={intl.formatMessage({ id: 'controlpanel.asidemenu.wishlist' })}
      />
      <AsideMenuItem
        url={linkMip || URLS.multichannel}
        icon="mip"
        prefix="fak"
        iconClass="bb-icon medium"
        title={intl.formatMessage({
          id: 'controlpanel.asidemenu.multichannel'
        })}
        isExternalLink={!!linkMip}
      />
      {showAcademy && (
        <AsideMenuItem
          url={URLS.academy}
          icon="book-open-cover"
          iconClass="bb-icon"
          title={ACADEMY_NAME}
        />
      )}
    </ul>
  );
}
