import React from 'react';

import { size } from 'lodash';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ACTIVE_RMA_STATUS } from 'app/modules/Devolutions/utils/constants';
import { URLS } from 'app/config/urls';
import Button from 'app/components/Button/Button';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';
import useFeedbackContext from 'app/context/FeedbackContext';

import 'app/modules/Devolutions/components/Notifications/Notifications.scss';

export default function Notifications() {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    api: { apiResponse }
  } = useDataTableContext();
  const { giveFeedback } = useFeedbackContext();

  const activesRmas = apiResponse?.data?.filter((obj) =>
    ACTIVE_RMA_STATUS?.includes(obj?.attributes?.status)
  );

  const showContinueRmaButton = size(activesRmas) > 0;
  const activeRmaId = activesRmas?.[0]?.id;

  const handleClick = () => {
    if (showContinueRmaButton === true) {
      giveFeedback({
        variant: 'info',
        content: intl.formatMessage({
          id: 'controlpanel.feedback.rma_exists'
        }),
        autoClose: true
      });

      return navigate(`${URLS.devolutionsAdd}/${activeRmaId}`, {
        replace: true
      });
    }

    return navigate(URLS.devolutionsAdd, { replace: true });
  };

  const textExplain = intl
    .formatMessage({
      id: 'controlpanel.devolutions.devolution_explain'
    })
    .replace('{url}', `${process.env.REACT_APP_BIGBUY_URL}contact#tabpanel2`);

  return (
    <div className="devolutions-notification">
      <p
        className="devolutions-notification__text"
        dangerouslySetInnerHTML={{
          __html: textExplain
        }}
      />
      <p>
        {intl.formatMessage({
          id: 'controlpanel.devolutions.devolution_explain_2'
        })}
      </p>
      <div className="devolutions-notification__button-container">
        <Button
          text={intl.formatMessage({
            id: 'controlpanel.devolutions.RMA.button'
          })}
          type="outline"
          size="medium"
          disabled={showContinueRmaButton}
          onClick={handleClick}
        />
        {showContinueRmaButton === true && (
          <Button
            text={intl.formatMessage({
              id: 'controlpanel.devolutions.RMA.continue_button'
            })}
            type="secondary"
            size="medium"
            onClick={handleClick}
          />
        )}
      </div>
    </div>
  );
}
