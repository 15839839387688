import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { PACK_TYPE_NUMBER } from 'app/utils/constants';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import PackContainerBlock from 'app/components/PackContainerBlock';

import 'app/components/ModalWrongPack/ModalWrongPack.scss';

export default function ModalWrongPack({ show, setShow }) {
  const intl = useIntl();
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal className="modal-wrong-pack" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          <p className="big bold">
            {intl.formatMessage({ id: 'controlpanel.global.warning' })}
          </p>
          <Icon
            icon="close"
            onClick={handleClose}
            color={Colors.bbGrey3}
            prefix="far"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="wrong-pack">{wrongPackContent(intl)}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleClose}
          text={intl.formatMessage({ id: 'controlpanel.global.accept' })}
          size="big"
          type="primary"
        />
      </Modal.Footer>
    </Modal>
  );
}

ModalWrongPack.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired
};

export const wrongPackContent = (intl) => (
  <>
    <p className="big bold modal-wrong-pack__title">
      {intl.formatMessage({ id: 'controlpanel.downloads.warning_title' })}
    </p>
    <p className="modal-wrong-pack__text">
      {intl.formatMessage({ id: 'controlpanel.global.warning_text' })}
    </p>
    <div className="modal-wrong-pack__container">
      <PackContainerBlock packId={PACK_TYPE_NUMBER?.ECOMMERCE} />
      <PackContainerBlock packId={PACK_TYPE_NUMBER?.MARKETPLACES} />
    </div>
  </>
);
