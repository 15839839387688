import { INITIAL_VALUES } from 'app/modules/Trackings/config/filters';
import { getTabs } from 'app/modules/Trackings/config/tabs';
import {
  getColumnsToDataTable,
  getCustomDropdownLogic,
  getDataTableStructure,
  getDropdownOptions,
  getNoResultsConfig,
  rowClasses
} from 'app/modules/Trackings/config/table';
import { useFiltersConfiguration } from 'app/modules/Trackings/hooks/useFiltersConfiguration';

export const getTrackingsDataTableConfiguration = ({ intl }) => ({
  contextId: 'trackings',
  INITIAL_VALUES,
  apiUrl: 'trackings',
  tabs: getTabs(intl),
  useFiltersConfiguration,
  getColumnsToDataTable,
  getNoResultsConfig,
  getDataTableStructure,
  getDropdownOptions,
  getCustomDropdownLogic,
  extraTableConfiguration: {
    rowClasses
  }
});
