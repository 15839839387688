export const defaultOptions = {
  options: {
    page: 1,
    sizePerPage: 10,
    paginationSize: 5,
    sizePerPageList: [
      {
        text: '10',
        value: 10
      },
      {
        text: '50',
        value: 50
      },
      {
        text: '100',
        value: 100
      }
    ]
  },
  loading: false
};
