import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

export default function FaqsOrderDelivered() {
  const intl = useIntl();

  const urlContact = `${process.env.REACT_APP_BIGBUY_URL}contact#tabpanel2`;

  return (
    <div className="devolutions-faqs">
      <Row>
        <Col md={12} className="text-container">
          <p className="bold devolutions-faqs-title">
            {intl.formatMessage({
              id: 'controlpanel.devolutions.faqs.order_delivered.title'
            })}
          </p>
          <p className="bold devolutions-faqs-subtitle">
            <a className="no-bold" href={urlContact}>
              {intl.formatMessage({
                id: 'controlpanel.devolutions.faqs.order_delivered.subtitle_1'
              })}
            </a>
            &nbsp;
            <span className="no-bold">
              {intl.formatMessage({
                id: 'controlpanel.devolutions.faqs.order_delivered.subtitle_2'
              })}
            </span>
            &nbsp;
            {intl.formatMessage({
              id: 'controlpanel.devolutions.faqs.order_delivered.subtitle_3'
            })}
          </p>
          <ul className="devolutions-faqs-list">
            <li>
              <p className="bold">
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.faqs.order_delivered.list_1.part_1'
                })}
                &nbsp;
                <a className="no-bold" href={urlContact}>
                  {intl.formatMessage({
                    id: 'controlpanel.devolutions.faqs.order_delivered.list_1.part_2'
                  })}
                </a>
                &nbsp;
                <span className="no-bold">
                  {intl.formatMessage({
                    id: 'controlpanel.devolutions.faqs.order_delivered.list_1.part_3'
                  })}
                </span>
              </p>
            </li>
            <li>
              <p className="bold">
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.faqs.order_delivered.list_2.part_1'
                })}
                &nbsp;
                <a className="no-bold" href={urlContact}>
                  {intl.formatMessage({
                    id: 'controlpanel.devolutions.faqs.order_delivered.list_2.part_2'
                  })}
                </a>
                &nbsp;
                <span className="no-bold">
                  {intl.formatMessage({
                    id: 'controlpanel.devolutions.faqs.order_delivered.list_2.part_3'
                  })}
                </span>
              </p>
            </li>
            <li>
              <p className="bold">
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.faqs.order_delivered.list_3.part_1'
                })}
                &nbsp;
                <span className="no-bold">
                  {intl.formatMessage({
                    id: 'controlpanel.devolutions.faqs.order_delivered.list_3.part_2'
                  })}
                </span>
              </p>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}
