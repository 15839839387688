import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
  useCallback
} from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  classifyFetchedPaymentMethods,
  ACTIONS_MAP,
  CLOSE_POPUP,
  INITIAL_POPUP_CONTENT,
  handleTokens
} from 'app/modules/MyAccount/pages/PaymentMethods/utils';
import { genericRequest } from 'app/api';

const PaymentMethodsContext = createContext();

export const PaymentMethodsProvider = ({ children }) => {
  const intl = useIntl();
  const {
    user: { id: userId }
  } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [popupContent, setPopupContent] = useState(INITIAL_POPUP_CONTENT);
  const [{ creditCards, paypal, paycomet }, setUserPaymentMethods] = useState({
    creditCards: [],
    paypal: [],
    paycomet: []
  });
  const { hasCreditCards, hasPayPal, hasPaycomet } = useMemo(
    () => ({
      hasCreditCards: creditCards?.length > 0,
      hasPayPal: paypal?.length > 0,
      hasPaycomet: paycomet?.length > 0
    }),
    [creditCards, paypal, paycomet]
  );

  const handleTogglePopup = useCallback((action) => {
    if (action === CLOSE_POPUP) {
      setPopupIsOpen((current) => !current);
      return setPopupContent(INITIAL_POPUP_CONTENT);
    }
    setPopupContent(ACTIONS_MAP[action]);
    return setPopupIsOpen((current) => !current);
  }, []);

  const fetchInitialData = useCallback(async () => {
    try {
      const result = await genericRequest({
        url: `payment-methods`
      });

      if (result?.data) {
        setUserPaymentMethods(classifyFetchedPaymentMethods(result.data));
      }
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }
  }, []);

  const initializeData = useCallback(async () => {
    setLoading(true);
    await handleTokens({ userId, intl, fetchInitialData });
    setLoading(false);
  }, [fetchInitialData, userId, intl]);

  const handleDeleteCreditCard = useCallback(
    async (id) => {
      try {
        await genericRequest({
          url: `${process.env.REACT_APP_API_URL}payment/me/actions`,
          method: 'POST',
          data: {
            data: {
              type: 'adyen-payment-method-deletion',
              attributes: {
                paymentMethodId: id
              }
            }
          },
          customSuccessFeedback: {
            variant: 'success',
            content: intl.formatMessage({
              id: 'controlpanel.my_account.payment_methods.delete_card_message'
            }),
            autoClose: true
          }
        });
        setLoading(false);
        await fetchInitialData();
      } catch {
        handleTogglePopup(CLOSE_POPUP);
      }
    },
    [fetchInitialData, handleTogglePopup, intl]
  );
  const handleDeletePaycomet = useCallback(async () => {
    await genericRequest({
      url: `${process.env.REACT_APP_API_URL}payment/${userId}/actions`,
      method: 'POST',
      data: {
        data: {
          type: 'paycomet-delete-card',
          attributes: {
            type: 'paycomet-delete-card'
          }
        }
      },
      customSuccessFeedback: {
        variant: 'success',
        content: intl.formatMessage({
          id: 'controlpanel.my_account.payment_methods.delete_card_message'
        }),
        autoClose: true
      }
    });

    await fetchInitialData();
  }, [fetchInitialData, userId, intl]);

  const handleGoToPayPalForm = useCallback(async () => {
    setLoading(true);
    try {
      const response = await genericRequest({
        url: `${process.env.REACT_APP_API_URL}payment/${userId}/actions`,
        method: 'POST',
        data: {
          data: {
            type: 'paypal-url-billing-agreement',
            attributes: {
              type: 'paypal-url-billing-agreement'
            }
          }
        },
        blockFeedback: true
      });

      const targetURL = response?.data?.attributes?.urlBillingAgreement;

      window.location.replace(targetURL);
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }
  }, [userId]);

  // INITIALIZE THE STATES
  useEffect(() => {
    initializeData();
    return () => {
      setLoading(false);
    };
  }, [initializeData]);

  const memoedValue = useMemo(
    () => ({
      loading,
      popupIsOpen,
      popupContent,
      creditCards,
      paycomet,
      paypal,
      hasCreditCards,
      hasPayPal,
      hasPaycomet,
      handleTogglePopup,
      handleDeleteCreditCard,
      handleDeletePaycomet,
      handleGoToPayPalForm
    }),
    [
      creditCards,
      loading,
      paypal,
      paycomet,
      popupContent,
      popupIsOpen,
      hasCreditCards,
      hasPayPal,
      hasPaycomet,
      handleTogglePopup,
      handleDeleteCreditCard,
      handleDeletePaycomet,
      handleGoToPayPalForm
    ]
  );

  return (
    <PaymentMethodsContext.Provider value={memoedValue}>
      {children}
    </PaymentMethodsContext.Provider>
  );
};

const usePaymentMethodsContext = () => useContext(PaymentMethodsContext);

PaymentMethodsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default usePaymentMethodsContext;
