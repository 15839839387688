import PropTypes from 'prop-types';

export const PROP_TYPES = {
  background: PropTypes.string,
  children: PropTypes.node,
  autoClose: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  fixedTo: PropTypes.oneOf(['top', 'bottom', null]),
  fixedAlignment: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  header: PropTypes.node,
  content: PropTypes.node,
  dismissible: PropTypes.bool,
  textColor: PropTypes.string
};

export const FROM_CONTEXT_PROP_TYPES = {
  ...PROP_TYPES,
  show: PropTypes.bool
};

export const DEFAULT_PROPS = {
  background: null,
  children: null,
  autoClose: null,
  fixedTo: 'bottom',
  fixedAlignment: 'right',
  className: null,
  variant: 'info',
  header: null,
  content: null,
  dismissible: true,
  textColor: null
};

export const FROM_CONTEXT_DEFAULT_PROPS = {
  ...DEFAULT_PROPS,
  show: false
};
