import React from 'react';

import { map } from 'lodash';

import { sortCaret, headerSortingStyle } from 'app/components/DataTable/utils';
import { isMobile } from 'app/utils/sizes';
import {
  formatAmount,
  formatDropshipping,
  formatGeneric,
  formatVirtual
} from 'app/modules/Orders/utils/formatters';
import { Colors } from 'app/utils/colors';
import { DateFormatter } from 'app/utils/columnsFormatters/DateFormatter';
import { StatusFormatter } from 'app/modules/Orders/utils/formatters/StatusFormatter';
import ActionsFormatter from 'app/modules/Orders/utils/formatters/ActionsFormatter';
import Icon from 'app/components/Icon';
import PaymentFormatter from 'app/utils/columnsFormatters/PaymentFormatter';

export const getColumnsToDataTable = (intl) => [
  {
    dataField: 'icon',
    text: '',
    sort: false,
    hidden: false,
    classes: 'icon',
    draggable: false
  },
  {
    dataField: 'id',
    text: intl.formatMessage({ id: 'controlpanel.orders.table.order' }),
    sort: true,
    hidden: false,
    classes: 'column-id',
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'date',
    text: intl.formatMessage({ id: 'controlpanel.orders.table.date' }),
    sort: true,
    hidden: false,
    classes: 'column-date',
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'addressee',
    text: intl.formatMessage({
      id: 'controlpanel.global.filter.addressee'
    }),
    sort: true,
    hidden: isMobile(),
    classes: 'column-recipient',
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'internalReference',
    text: intl.formatMessage({
      id: 'controlpanel.orders.table.internal_reference'
    }),
    sort: true,
    hidden: isMobile(),
    classes: 'column-reference',
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'paymentMethod',
    text: intl.formatMessage({
      id: 'controlpanel.orders.table.payment_method'
    }),
    sort: true,
    hidden: isMobile(),
    classes: 'column-payment-method',
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'isDropshipping',
    text: intl.formatMessage({
      id: 'controlpanel.orders.table.dropshipping'
    }),
    sort: true,
    hidden: true,
    classes: 'column-dropshipping',
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'virtual',
    text: intl.formatMessage({
      id: 'controlpanel.orders.filters.type_order'
    }),
    sort: true,
    hidden: true,
    classes: 'column-dropshipping',
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'amount',
    text: intl.formatMessage({ id: 'controlpanel.orders.table.amount' }),
    sort: true,
    hidden: false,
    classes: 'column-total',
    headerAlign: 'right',
    align: 'right',
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'status',
    text: intl.formatMessage({ id: 'controlpanel.orders.table.status' }),
    sort: true,
    hidden: isMobile(),
    classes: 'column-status',
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'invoice',
    text: intl.formatMessage({
      id: 'controlpanel.orders.table.invoice'
    }),
    sort: true,
    hidden: true,
    classes: 'column-invoice',
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'expeditionDate',
    text: intl.formatMessage({
      id: 'controlpanel.orders.table.expedition_date'
    }),
    sort: true,
    hidden: true,
    classes: 'column-expedition-date',
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'itemQuantity',
    text: intl.formatMessage({
      id: 'controlpanel.orders.table.item_quantity'
    }),
    sort: true,
    hidden: true,
    classes: 'column-total-products',
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'carrier',
    text: intl.formatMessage({
      id: 'controlpanel.orders.table.carrier'
    }),
    sort: true,
    hidden: true,
    classes: 'column-carrier',
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'actions',
    text: intl.formatMessage({
      id: 'controlpanel.global.table_actions'
    }),
    classes: 'column-action',
    hidden: false,
    draggable: true,
    sort: false
  }
];

export const getNoResultsConfig = (intl) => ({
  text: intl.formatMessage({ id: 'controlpanel.orders.no_results' }),
  icon: 'clipboard'
});

export const getDataTableStructure = (data) => {
  const dataStructure = [];

  map(data, (value) => {
    const {
      id,
      attributes: {
        // Los atributos comentados llegan del endpoint, pero no están en uso
        date = null,
        internalReference = null,
        // sellingChannel = null,
        paymentMethod = null,
        isDropshipping = null,
        amount = null,
        status = null,
        expeditionDate = null,
        itemCount = null,
        // subtotal = null,
        // equivalenceSurcharge = null,
        // shipping = null,
        // vat = null,
        // paymentMethodCost = null,
        // handlingCost = null,
        availableActions = null,
        addressee = null,
        // shippingCompany = null,
        // shippingAddress = null,
        // shippingCountry = null,
        // shippingState = null,
        // shippingCity = null,
        // shippingPostalCode = null,
        // shippingPhone = null,
        carrier = null,
        invoice = null,
        virtual = null
      }
    } = value;

    dataStructure.push({
      id,
      icon:
        status === 'PENDING_PAYMENT' || status === 'PENDING_INVOICING' ? (
          <Icon icon="exclamation-triangle" color={Colors.bbRed} />
        ) : (
          ''
        ),
      orderNumber: formatGeneric({ text: id, status }),
      date: <DateFormatter date={date} />,
      addressee: formatGeneric({ text: addressee, status }),
      internalReference: formatGeneric({ text: internalReference, status }),
      paymentMethod: <PaymentFormatter paymentMethod={paymentMethod} />,
      carrier: formatGeneric({ text: carrier }),
      isDropshipping: formatDropshipping({ isDropshipping }),
      virtual: formatVirtual({ virtual }),
      amount: formatAmount({ text: amount }),
      status: <StatusFormatter status={status} />,
      expeditionDate: <DateFormatter date={expeditionDate} />,
      itemQuantity: formatGeneric({ text: itemCount, status }),
      invoice: formatGeneric({ text: invoice, status }),
      actions: (
        <ActionsFormatter
          actions={availableActions}
          id={id}
          tracking={!!value?.relationships?.tracking}
        />
      )
    });
  });

  return dataStructure;
};

export const rowClasses = (row) => {
  const statusRow = row?.status?.props?.status;

  if (statusRow === 'PENDING_PAYMENT' || statusRow === 'PENDING_INVOICING') {
    return 'red';
  }
  return '';
};
