import { map } from 'lodash';

export const formatFiltersToEndpoint = (filters) => {
  if (filters) {
    const newFilters = {};
    map(filters, (value, filter) => {
      switch (typeof value) {
        case 'object':
          if (Array.isArray(value) && value.length > 0) {
            newFilters[filter] = value;
          }

          // En caso de que hayan más tipos de filtro de tipo objeto añadir la validación
          if (value.toString() === '[object Object]') {
            if (value?.start !== '' && value?.end !== '') {
              newFilters[filter] = [value?.start, value?.end];
            }
          }
          break;

        default:
          if (value !== '') {
            newFilters[filter] = value;
          }
          break;
      }
    });

    return newFilters;
  }

  return null;
};

/**
 * @param {Array} options - Options to convert to selects options
 * @param {String} value - equivalence of value on select option
 * @param {String} label - equivalence of label on select option
 * @returns Array of objects for select options
 */
export const getSelectStructure = (options, value, label) => {
  const selectStructure = [];

  map(options, (option) => {
    selectStructure.push({
      label: option?.[label],
      value: option?.[value]
    });
  });

  return selectStructure;
};
