import React from 'react';
import PropTypes from 'prop-types';

import { Formik, Form } from 'formik';

import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

export const FiltersContext = ({ validate = null, children }) => {
  const {
    filters: {
      submitFiltersToContext,
      filters,
      setRenderedFilters,
      removeSearchFilter,
      addNewFilterToRenderList,
      INITIAL_VALUES,
      renderedFilters
    }
  } = useDataTableContext();
  return (
    <Formik
      enableReinitialize
      initialValues={filters}
      onSubmit={(values) => {
        submitFiltersToContext(values);
      }}
      validate={(values) => {
        if (validate) return validate({ values, renderedFilters });
        return null;
      }}
    >
      {(formik) => (
        <Form
          onChange={(e) => {
            addNewFilterToRenderList({
              e,
              setRenderedFilters,
              removeSearchFilter,
              currentStatus: formik,
              INITIAL_VALUES
            });
          }}
        >
          {children}
        </Form>
      )}
    </Formik>
  );
};

FiltersContext.propTypes = {
  children: PropTypes.node.isRequired,
  validate: PropTypes.func
};
