import React from 'react';

import { useIntl } from 'react-intl';

import { Image } from 'app/components/Image/Image';
import Faqs from 'app/components/Faqs';

import faq1img1 from 'app/assets/svg/wishlist/faq_1_img_1.svg';
import faq1img2 from 'app/assets/svg/wishlist/faq_1_img_2.svg';
import faq1img3 from 'app/assets/svg/wishlist/faq_1_img_3.svg';
import faq1img4 from 'app/assets/svg/wishlist/faq_1_img_4.svg';
import faq2img1 from 'app/assets/svg/wishlist/faq_2_img_1.svg';
import faq2img2 from 'app/assets/svg/wishlist/faq_2_img_2.svg';

export default function HowToStockTracking() {
  const intl = useIntl();
  const faqs = [
    {
      title: (
        <p className="big bold">
          {intl.formatMessage({ id: 'controlpanel.wishlist.faq1_title' })}
        </p>
      ),
      content: (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'controlpanel.wishlist.faq1_paragraph1'
              })
            }}
          />
          <div className="faq__image-container">
            <Image src={faq1img1} />
            <Image src={faq1img2} />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'controlpanel.wishlist.faq1_paragraph2'
              })
            }}
          />
          <div className="faq__image-container">
            <Image src={faq1img3} />
            <Image src={faq1img4} />
          </div>
        </div>
      )
    },
    {
      title: (
        <p className="big bold">
          {intl.formatMessage({ id: 'controlpanel.wishlist.faq2_title' })}
        </p>
      ),
      content: (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'controlpanel.wishlist.faq2_paragraph'
              })
            }}
          />

          <div className="faq__image-container">
            <Image src={faq2img1} />
            <Image src={faq2img2} />
          </div>
        </div>
      )
    }
  ];

  return (
    <div className="wishlist__howto-stock-tracking">
      <Faqs faqs={faqs} />
    </div>
  );
}
