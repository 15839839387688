import React from 'react';

import { useIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';

import Button from 'app/components/Button';
import { downloadFile } from 'app/utils/downloadFile';
import Icon from 'app/components/Icon';
import useRMAContext from 'app/modules/Devolutions/context/context';

import 'app/modules/Devolutions/components/NotificationDevolution/components/PrintForm/PrintForm.scss';

export default function PrintForm() {
  const intl = useIntl();
  const { rmaInfo } = useRMAContext();

  const handleDownloadLabel = () => {
    downloadFile({
      url: rmaInfo.data.links.labelUrl,
      nameFile: `${rmaInfo.data.id}_label.pdf`
    });
  };

  const handleDownloadProductsList = () => {
    downloadFile({
      url: rmaInfo.data.links.productList,
      nameFile: `${rmaInfo.data.id}_products.pdf`
    });
  };

  return (
    <Row className="print-form">
      <Col className="print-form__col" md={6}>
        <p
          className="small print-form__text"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'controlpanel.devolutions.print_form.tags.text'
            })
          }}
        />
        <Button
          size="big"
          type="primary"
          icon=<Icon icon="box-open" swapOpacity />
          text={intl.formatMessage({
            id: 'controlpanel.devolutions.print_form.tags.button'
          })}
          onClick={handleDownloadLabel}
        />
      </Col>
      <Col className="print-form__col" md={6}>
        <p
          className="small print-form__text"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'controlpanel.devolutions.print_form.product_list.text'
            })
          }}
        />
        <Button
          size="big"
          type="primary"
          icon=<Icon icon="box-open" swapOpacity />
          text={intl.formatMessage({
            id: 'controlpanel.devolutions.print_form.product_list.button'
          })}
          onClick={handleDownloadProductsList}
        />
      </Col>
    </Row>
  );
}
