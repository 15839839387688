import { getOptions } from 'app/config-chart/dashboard-chart';
import { formatDateToLocale } from 'app/utils/dates';
import { formatCurrency } from '../currency';

export const parserDashboardChart = (
  titleValue,
  color,
  response,
  totalAmount,
  mobile
) => {
  const options = getOptions(mobile);
  let chartConfig = { ...options };
  const info = [];
  if (response && totalAmount > 0) {
    response?.forEach((res) => {
      const { startDate, amount } = res.attributes;
      const item = {
        date: formatDateToLocale(startDate, 'es', { month: 'long' }),
        amount
      };
      info.push(item);
    });
    const ordered = info.sort((a, b) => a.date - b.date);
    const serie = { name: titleValue, data: ordered.map((o) => o.amount) };
    const categories = ordered.map((o) => o.date);

    chartConfig = {
      ...chartConfig,
      series: [serie],
      options: {
        ...chartConfig.options,
        colors: [color],
        yaxis: {
          labels: {
            formatter(value) {
              return formatCurrency(value);
            }
          }
        },
        xaxis: {
          convertedCatToNumeric:
            chartConfig.options.xaxis.convertedCatToNumeric,
          categories,
          position: 'bottom',
          labels: {
            show: true,
            offsetY: 1.5,
            style: {
              cssClass: 'apexcharts-custom-axis-label'
            }
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        }
      }
    };
    return chartConfig;
  }
  return null;
};
