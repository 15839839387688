import React from 'react';

import Tag from 'app/components/Tag';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

import 'app/components/DataTableFiltersTags/DataTableFiltersTags.scss';

export default function DataTableFiltersTags() {
  const {
    filters: { parsedFiltersArray, removeFilter, filters },
    tags: { dataTableFiltersTagsConfiguration }
  } = useDataTableContext();
  const { formatter } = dataTableFiltersTagsConfiguration;
  const translationsTags = formatter(filters);

  return (
    <div className="datatable-filters-tags">
      {Boolean(parsedFiltersArray?.length) &&
        parsedFiltersArray.map(([filter, value]) => (
          <Tag
            key={`${filter}: ${value}`}
            color="blue"
            id="tag"
            onClose={() => removeFilter(filter)}
            size="small"
            text={translationsTags?.[filter]}
            showIcon
          />
        ))}
    </div>
  );
}
