import React from 'react';

import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';

export const sortCaret = (order) => {
  if (order === 'asc') {
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Up-2.svg')} />
      </span>
    );
  }

  if (order === 'desc') {
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Down-2.svg')} />
      </span>
    );
  }

  return null;
};
