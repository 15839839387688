import { Colors } from 'app/utils/colors';

const { bbRed, bbSecondary } = Colors;

export const ICON_MAP = {
  redExclamationTriangle: {
    icon: 'exclamation-triangle',
    color: bbRed
  },
  blueExlamationBell: {
    icon: 'bell-exclamation',
    color: bbSecondary
  }
};

export const ALL = 'ALL';
export const NONE = 'NONE';
export const READ = 'READ';
export const NOT_READ = 'NOT_READ';
