import { Colors } from 'app/utils/colors';

const { bbSecondary, bbOrange, bbGreen, bbPurple } = Colors;

export const ICON_COLOR_MAP = {
  info: bbSecondary,
  warning: bbOrange,
  success: bbGreen,
  reminder: bbPurple
};
