import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { size } from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import NoResultsFound from 'app/components/NoResultsFound';
import Spinner from 'app/components/Spinner';

import 'app/components/DataTable/DataTable.scss';
import 'app/components/DataTable/BasicDataTable.scss';

export function BasicDataTable({
  tableConfiguration = {
    wrapperClasses: 'table-responsive',
    classes: 'table table-head-custom table-vertical-center overflow-hidden',
    bootstrap4: true,
    bordered: false
  },
  loading = false,
  noResultsConfig = { text: 'No results found', icon: 'block-question' },
  options = null,
  customSpinner = null,
  id,
  columns,
  keyField,
  entities,
  ...rest
}) {
  const pagination = useMemo(
    () => (options ? paginationFactory(options) : null),
    [options]
  );

  return (
    <div className="bb-data-table bb-basic-datatable">
      {loading === true && (customSpinner || <Spinner />)}

      {size(entities) > 0 ? (
        <div>
          <BootstrapTable
            id={id}
            {...tableConfiguration}
            keyField={keyField}
            data={entities}
            columns={columns}
            pagination={pagination}
            {...rest}
          />
        </div>
      ) : (
        loading === false && <NoResultsFound {...noResultsConfig} />
      )}
    </div>
  );
}

BasicDataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      sort: PropTypes.bool.isRequired
    })
  ).isRequired,
  entities: PropTypes.arrayOf(PropTypes.shape).isRequired,
  tableConfiguration: PropTypes.objectOf(PropTypes.shape),
  loading: PropTypes.bool,
  noResultsConfig: PropTypes.shape({
    text: PropTypes.string,
    icon: PropTypes.string
  }),
  keyField: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({
    totalSize: PropTypes.number,
    sizePerPageList: PropTypes.arrayOf(PropTypes.shape()),
    sizePerPage: PropTypes.number,
    page: PropTypes.number,
    defaultSorted: PropTypes.arrayOf(PropTypes.shape()),
    keyField: PropTypes.string,
    showTotal: PropTypes.bool,
    paginationTotalRenderer: PropTypes.func,
    sizePerPageRenderer: PropTypes.func,
    pageListRenderer: PropTypes.func
  }),
  customSpinner: PropTypes.node
};
