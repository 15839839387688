export const BLUE_ALERT = ['CANCELLED', 'REIMBURSED_PAYMENT', 'RETURNED'];

export const RED_ALERT = [
  'PENDING_PAYMENT',
  'PAYMENT_INCIDENT',
  'PAYPAL_VALIDATION_PENDING',
  'VALIDATION_PENDING',
  'PENDING_INVOICING',
  'CANCELLED_PAYMENT'
];
