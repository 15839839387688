import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import 'app/components/ShopShippingProcess/components/Time/Time.scss';

export default function Time({
  leftSide = {
    dateSubtitle: '',
    dateColor: 'green'
  },
  rightSide = {
    eventSubtitle: '',
    eventColor: 'green'
  },
  circleColor = 'green',
  lastChild
}) {
  const intl = useIntl();
  const { dateTitle, dateSubtitle, dateColor } = leftSide;
  const { eventTitle, eventSubtitle, eventColor } = rightSide;

  return (
    <div className="bb-time">
      <div className={`bb-time__left bb-time__left--${dateColor}`}>
        <p className="bb-time__left__title medium">{dateTitle}</p>
        {dateSubtitle && (
          <p className="bb-time__left__subtitle small">{dateSubtitle}</p>
        )}
      </div>
      <div className="bb-time__spaced">
        <div
          className={`bb-time__spaced__circle bb-time__spaced__circle--${circleColor}`}
        />
        {!lastChild && <div className="bb-time__spaced__line" />}
      </div>
      <div className={`bb-time__circle bb-time__circle--${circleColor}`} />
      <div className={`bb-time__right bb-time__right--${eventColor}`}>
        <p className="bb-time__right__title medium">
          {intl.formatMessage({ id: `${eventTitle}` })}
        </p>
        <p className="bb-time__right__subtitle small">{eventSubtitle}</p>
      </div>
    </div>
  );
}

const colors = PropTypes.oneOf([
  'red',
  'green',
  'yellow',
  'blue',
  'purple',
  'grey'
]);

Time.propTypes = {
  lastChild: PropTypes.bool.isRequired,
  leftSide: PropTypes.shape({
    dateTitle: PropTypes.string.isRequired,
    dateSubtitle: PropTypes.string,
    dateColor: colors
  }),
  rightSide: PropTypes.shape({
    eventTitle: PropTypes.string.isRequired,
    eventSubtitle: PropTypes.string,
    eventColor: colors
  }),
  circleColor: colors
};
