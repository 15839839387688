import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import useDevolutionsStatus from 'app/hooks/useDevolutionsStatus.hook';
import Badge from 'app/components/Badge';

export default function FaqsReturnStatus() {
  const intl = useIntl();
  const devolutionsStatus = useDevolutionsStatus();

  return (
    <div className="devolutions-faqs">
      <Row>
        <Col md={12} className="text-container">
          <p className="devolutions-faqs-title devolutions-faqs-subtitle">
            {intl.formatMessage({
              id: 'controlpanel.devolutions.faqs.return_states.title'
            })}
          </p>
          <div className="devolutions-faqs-status">
            <div className="devolutions-faqs-status-item">
              <Badge
                text={devolutionsStatus.DRAFT.translation}
                size="xsmall"
                color={devolutionsStatus.DRAFT.badgeColor}
              />
              <p>
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.faqs.return_states.list.draft'
                })}
              </p>
            </div>
            <div className="devolutions-faqs-status-item">
              <Badge
                text={devolutionsStatus.PENDING_CONFIRMATION.translation}
                size="xsmall"
                color={devolutionsStatus.PENDING_CONFIRMATION.badgeColor}
              />
              <p>
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.faqs.return_states.list.pending_confirmation'
                })}
              </p>
            </div>
            <div className="devolutions-faqs-status-item">
              <Badge
                text={devolutionsStatus.PENDING_PICK_UP_ADDRESS.translation}
                size="xsmall"
                color={devolutionsStatus.PENDING_PICK_UP_ADDRESS.badgeColor}
              />
              <p>
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.faqs.return_states.list.pending_pick_up_address'
                })}
              </p>
            </div>
            <div className="devolutions-faqs-status-item">
              <Badge
                text={devolutionsStatus.PENDING_COLLECTION.translation}
                size="xsmall"
                color={devolutionsStatus.PENDING_COLLECTION.badgeColor}
              />
              <p>
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.faqs.return_states.list.pending_collection'
                })}
              </p>
            </div>
            <div className="devolutions-faqs-status-item">
              <Badge
                text={devolutionsStatus.PENDING_VALUATION.translation}
                size="xsmall"
                color={devolutionsStatus.PENDING_VALUATION.badgeColor}
              />
              <p>
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.faqs.return_states.list.pending_valuation'
                })}
              </p>
            </div>
            <div className="devolutions-faqs-status-item">
              <Badge
                text={devolutionsStatus.PENDING_PAYMENT.translation}
                size="xsmall"
                color={devolutionsStatus.PENDING_PAYMENT.badgeColor}
              />
              <p>
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.faqs.return_states.list.pending_payment'
                })}
              </p>
            </div>
            <div className="devolutions-faqs-status-item">
              <Badge
                text={devolutionsStatus.PAID.translation}
                size="xsmall"
                color={devolutionsStatus.PAID.badgeColor}
              />
              <p>
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.faqs.return_states.list.paid'
                })}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
