import React from 'react';

import { sortCaret, headerSortingStyle } from 'app/components/DataTable/utils';
import { Image } from 'app/components/Image/Image';
import ActionsFormatter from 'app/modules/Downloads/Pages/ProductsManuals/utils/formatters/ActionsFormatter';
import LangFormatter from 'app/modules/Downloads/Pages/ProductsManuals/utils/formatters/LangFormatter';

export const getColumnsToDataTable = (intl) => [
  {
    dataField: 'id',
    hiddenToUser: true,
    hidden: true,
    sort: false,
    draggable: false,
    text: 'id'
  },
  {
    dataField: 'reference',
    text: intl.formatMessage({
      id: 'controlpanel.global.reference'
    }),
    sort: true,
    hidden: false,
    draggable: false,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'product',
    text: intl.formatMessage({
      id: 'controlpanel.global.product'
    }),
    sort: true,
    hidden: false,
    draggable: false,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'lang',
    text: intl.formatMessage({
      id: 'controlpanel.global.language'
    }),
    sort: false,
    hidden: false,
    draggable: false
  },
  {
    dataField: 'actions',
    text: intl.formatMessage({
      id: 'controlpanel.global.table_actions'
    }),
    sort: false,
    hidden: false,
    draggable: false,
    sortCaret,
    headerSortingStyle
  }
];

export const getNoResultsConfig = (intl) => ({
  text: intl.formatMessage({ id: 'controlpanel.global.no_results_founds' }),
  icon: 'image'
});

export const getDataTableStructure = (data) =>
  data.map(
    ({
      id,
      attributes: {
        image = null,
        languages = null,
        link = null,
        name = null,
        sku = null
      }
    }) => ({
      id,
      reference: <p className="languages-reference">{sku}</p>,
      product: (
        <div className="d-flex align-items-center languages-description">
          <Image src={image} />
          <p>{name}</p>
        </div>
      ),
      lang: (
        <LangFormatter
          langs={languages}
          productInfo={{ id, languages, link, name, sku }}
        />
      ),
      actions: <ActionsFormatter link={link} />
    })
  );
