import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

export default function Redirect({ to, replace = true }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to, { replace });
  }, [to, replace, navigate]);

  return <div />;
}

Redirect.propTypes = {
  to: PropTypes.string.isRequired,
  replace: PropTypes.bool
};
