import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';

import 'app/modules/MyAccount/pages/Notifications/components/DefaultLanguage.scss';

export default function DefaultLanguage({ currentLanguage }) {
  const intl = useIntl();

  return (
    <div className="bb-notification-panel__language-wrapper margin">
      <div className="bb-notification-panel__language-wrapper--default">
        {intl.formatMessage({
          id: 'controlpanel.my_account.notifications.newsletter_message'
        })}
        <span className="bb-notification-panel__language">
          <span className="bb-notification-panel__language--name">
            {' '}
            {currentLanguage.name}
          </span>
          <div className="bb-notification-panel__language-tooltip">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  {intl.formatMessage({
                    id: 'controlpanel.my_account.notifications.tooltip'
                  })}
                </Tooltip>
              }
            >
              <SVG
                src={toAbsoluteUrl('/media/svg/bigbuy/info-square.svg')}
                className="label-tooltip"
              />
            </OverlayTrigger>
          </div>
          <div className="bb-notification-panel__language-flag">
            <img
              className="rounded flag-icon"
              src={currentLanguage.flag}
              alt={currentLanguage.name}
            />
          </div>
        </span>
      </div>
    </div>
  );
}

DefaultLanguage.propTypes = {
  currentLanguage: PropTypes.shape().isRequired
};
