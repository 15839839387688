import {
  getColumnsToDataTable,
  getDataTableStructure,
  getNoResultsConfig
} from 'app/modules/Downloads/Pages/ProductsManuals/config/table';

export const getProductsManualsDataTableConfiguration = () => ({
  apiUrl: 'manuals',
  noSelectRow: true,
  INITIAL_VALUES: {
    search: ''
  },
  getColumnsToDataTable,
  getNoResultsConfig,
  getDataTableStructure
});
