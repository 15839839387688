import React, { useLayoutEffect } from 'react';

import { useIntl } from 'react-intl';

import { DataTableProvider } from 'app/components/DataTable/context/DataTableContext';
import Body from 'app/modules/Trackings/components/Body';
import { getTrackingsDataTableConfiguration } from 'app/modules/Trackings/config';
import Card from 'app/components/Card';
import { useSubheader } from 'app/context/SubheaderContext';

import 'app/modules/Trackings/pages/Trackings/Trackings.scss';

export default function Trackings() {
  const intl = useIntl();
  const subheader = useSubheader();
  const { setTitle, setIcon, setBreadcrumbs } = subheader;

  useLayoutEffect(() => {
    setTitle(intl.formatMessage({ id: 'controlpanel.asidemenu.tracking' }));
    setIcon({ icon: 'location-dot' });
    setBreadcrumbs([]);
  }, [intl, setTitle, setBreadcrumbs, setIcon]);

  return (
    <DataTableProvider
      getTableContextConfiguration={getTrackingsDataTableConfiguration}
    >
      <div className="trackings">
        <Card extraClass="trackings-card-container" body={<Body />} />
      </div>
    </DataTableProvider>
  );
}
