import React, { useState } from 'react';

import { Collapse, Image } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import { Colors } from 'app/utils/colors';

import { PROP_TYPES } from 'app/components/EcommerceConnectorCard/utils/types';

import 'app/components/EcommerceConnectorCard/EcommerceConnectorCard.scss';

const { bbGreen, bbSecondary } = Colors;

export default function EcommerceConnectorCard({ purchased = [], id, image }) {
  const intl = useIntl();

  const [open, setOpen] = useState(false);
  const hasPurchases = purchased.length > 0;
  const availablePurchases = purchased.length < 2;
  const onClickButton = () => {
    window.open(
      `${process.env.REACT_APP_BIGBUY_URL}subscription/checkout?serviceProductId=${id}`,
      '_blank'
    );
  };

  return (
    <div
      className={classNames('bb-ecommerce-connector-card', {
        'bb-ecommerce-connector-card--border': hasPurchases
      })}
    >
      <div className="bb-ecommerce-connector-card__container">
        <Icon
          aria-controls="collapse-text"
          aria-expanded={open}
          color={bbGreen}
          className={classNames(
            'bb-ecommerce-connector-card__container__chevron-icon',
            {
              'bb-ecommerce-connector-card__container__chevron-icon--up': open
            },
            { 'v-hidden': !hasPurchases }
          )}
          icon="chevron-down"
          onClick={() => setOpen(!open)}
          prefix="fas"
          size="lg"
        />

        <div className="bb-ecommerce-connector-card__container__images">
          <Image src={image} />
        </div>

        {hasPurchases && (
          <div
            className="bb-ecommerce-connector-card__container__text"
            style={{ justifyContent: hasPurchases ? 'flex-end' : 'center' }}
          >
            <p className="medium">
              {intl.formatMessage({ id: 'controlpanel.global.active' })}
            </p>
          </div>
        )}
        {availablePurchases && (
          <div className="bb-ecommerce-connector-card__container__button">
            <Button
              icon={<Icon icon="shopping-cart" color={bbSecondary} />}
              onClick={onClickButton}
              size="medium"
              text={intl.formatMessage({ id: 'controlpanel.global.buy' })}
            />
          </div>
        )}
      </div>
      <Collapse in={open}>
        <div
          id="collapse-text"
          className="bb-ecommerce-connector-card__collapse"
        >
          {purchased.map((date, index) => (
            <div
              key={index}
              className="bb-ecommerce-connector-card__collapse__texts"
            >
              <p>{date}</p>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
}

EcommerceConnectorCard.propTypes = PROP_TYPES;
