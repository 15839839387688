import { combineReducers } from 'redux';
import AuthReducer from 'app/modules/Auth/_redux/authReducer';

const RootReducer = (injectedReducers = {}) =>
  combineReducers({
    auth: AuthReducer,
    ...injectedReducers
  });

export default RootReducer;
