import {
  GREY_ROW,
  RED_ROW
} from 'app/modules/Devolutions/components/TableProducts/formatters/ProductFormatter/utils/constants';

export const getRowClass = (row) => {
  if (RED_ROW.includes(row?.status)) {
    return 'red';
  }
  if (GREY_ROW.includes(row?.status)) {
    return 'grey';
  }

  return '';
};
