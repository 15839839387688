import React from 'react';
import {
  Billing,
  Information,
  Notifications,
  Password,
  PaymentMethods,
  Summary,
  Syncronize,
  UserData,
  WholesalerPack
} from 'app/modules/MyAccount';

import { URLS } from 'app/config/urls';

export const myAccountRoutes = {
  path: URLS.myAccount,
  children: [
    { path: URLS.myAccountSummary, element: <Summary /> },
    { path: URLS.myAccountUserData, element: <UserData /> },
    { path: URLS.myAccountPassword, element: <Password /> },
    { path: URLS.myAccountInformation, element: <Information /> },
    { path: URLS.myAccountPaymentMethods, element: <PaymentMethods /> },
    { path: URLS.myAccountBilling, element: <Billing /> },
    { path: URLS.myAccountSyncronize, element: <Syncronize /> },
    { path: URLS.myAccountWholesalerPack, element: <WholesalerPack /> },
    { path: URLS.myAccountNotifications, element: <Notifications /> }
  ]
};
