import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import { Col, Row } from 'react-bootstrap';

import { Colors } from 'app/utils/colors';
import { size } from 'lodash';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import PopupDelete from 'app/modules/Wishlist/components/PopupDelete';
import PopupDownloadCSV from 'app/components/PopupDownloadCSV/PopupDownloadCSV';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';
import PopupAddToCart from './PopupAddToCart';

export default function ButtonsHeader() {
  const intl = useIntl();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showAddToCartPopup, setShowAddToCartPopup] = useState(false);
  const {
    datatable: {
      rows: { selectedRows }
    }
  } = useDataTableContext();

  return (
    <Row>
      <Col className="wishlist__datatable-container__buttons-actions">
        <PopupDownloadCSV fileType="wishlist" />
        <div className="wishlist__datatable-buttons-header">
          <Button
            className="button-delete"
            type="default"
            text={intl.formatMessage({ id: 'controlpanel.global.delete' })}
            size="medium"
            icon={<Icon icon="xmark" color={Colors.bbRed} />}
            disabled={size(selectedRows) === 0}
            onClick={() => setShowDeletePopup(true)}
          />
          <PopupDelete setShow={setShowDeletePopup} show={showDeletePopup} />
          <PopupAddToCart
            setShow={setShowAddToCartPopup}
            show={showAddToCartPopup}
          />
          <Button
            type="primary"
            text={intl.formatMessage({ id: 'controlpanel.global.add_to_cart' })}
            size="medium"
            icon={
              <Icon icon="shopping-cart" color={Colors.bbWhite} prefix="fas" />
            }
            id="bulk_add_cart_clic_on_wishlist"
            disabled={size(selectedRows) === 0}
            onClick={() => setShowAddToCartPopup(true)}
          />
        </div>
      </Col>
    </Row>
  );
}
