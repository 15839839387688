import React from 'react';

import { Col } from 'react-bootstrap';

export const getRowToCards = (label, value) => (
  <Col md={12}>
    <p className="bold">
      {label}
      &#58; &nbsp;
      <span>{value}</span>
    </p>
  </Col>
);
