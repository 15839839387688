import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';

export default function AlertBody({
  content = null,
  children = null,
  textColor: color = null
}) {
  let componentContent = content;

  if (children && isValidElement(children)) componentContent = children;

  if (typeof content === 'string')
    componentContent = (
      <p
        dangerouslySetInnerHTML={{ __html: content }}
        className="small"
        style={{ color }}
      />
    );

  return <div className="bb-alert__body">{componentContent}</div>;
}

AlertBody.propTypes = {
  content: PropTypes.node,
  children: PropTypes.node,
  textColor: PropTypes.string
};
