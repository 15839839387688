import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import useRMAContext from 'app/modules/Devolutions/context/context';

import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import Popup from 'app/components/Popup';
import Spinner from 'app/components/Spinner';

export default function PopupDelete({
  id = null,
  rmaId = '',
  sku = '',
  show,
  setShow,
  productDescription
}) {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const { deleteRmaLine } = useRMAContext();

  const handleClose = () => {
    setShow(false);
    setLoading(false);
  };

  return (
    <Popup
      show={show}
      header={
        <p className="bold">
          {intl.formatMessage({
            id: 'controlpanel.global.delete_product_title'
          })}
        </p>
      }
      body={
        <p>
          {intl.formatMessage({
            id: 'controlpanel.devolutions.RMA.products.popup_delete_text_1'
          })}
          &nbsp;
          <span>{sku}</span>
          &nbsp;
          <span>
            {intl.formatMessage({
              id: 'controlpanel.devolutions.RMA.products.popup_delete_text_2'
            })}
          </span>
          &nbsp;
          <span>{rmaId?.toUpperCase()}</span>
        </p>
      }
      footer={
        <div className="bb-popup-cancelorder__footer">
          <Button
            text={intl.formatMessage({
              id: 'controlpanel.global.close'
            })}
            type="default"
            size="big"
            onClick={handleClose}
          />
          <Button
            text={intl.formatMessage({ id: 'controlpanel.global.delete' })}
            type="primary"
            size="big"
            icon={
              loading === false ? (
                <Icon icon="trash" color={Colors.bbGrey2} />
              ) : (
                <Spinner />
              )
            }
            onClick={() => {
              setLoading(true);
              deleteRmaLine({
                id,
                feedbackText: intl.formatMessage(
                  {
                    id: 'controlpanel.devolutions.delete_product_success'
                  },
                  {
                    product: productDescription
                  }
                )
              });
            }}
          />
        </div>
      }
      handleClose={handleClose}
    />
  );
}

PopupDelete.propTypes = {
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  sku: PropTypes.string,
  id: PropTypes.string,
  rmaId: PropTypes.string,
  productDescription: PropTypes.string.isRequired
};
