export const getOptions = (mobile) => ({
  options: {
    colors: ['#0090FF', '#ECF0F3'],
    legend: {
      show: false
    },
    chart: {
      height: '100%',
      fontFamily: 'Roboto',
      foreColor: '#B5B5C3', // Color de los valores x e y
      redrawOnWindowResize: true,
      parentHeightOffset: 0,
      // Opciones de configuración de la barra de herramientas
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          // eslint-disable-next-line no-bitwise
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        export: {
          csv: {
            filename: 'ruben',
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            }
          },
          svg: {
            filename: undefined
          },
          png: {
            filename: undefined
          }
        },
        autoSelected: 'zoom'
      }
    },
    xaxis: {
      position: 'bottom',
      labels: {
        show: true,
        offsetY: 1.5,
        style: {
          cssClass: 'apexcharts-custom-axis-label'
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          cssClass: 'apexcharts-custom-axis-label'
        }
      }
    },
    grid: {
      show: true,
      borderColor: '#ECF0F3',
      // Linea de puntos interior 0 sin puntos
      strokeDashArray: 4,
      position: 'back',
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      row: {
        colors: undefined,
        opacity: 0.5
      },
      column: {
        colors: undefined,
        opacity: 0.5
      },
      padding: {
        top: mobile ? 0 : 20,
        right: mobile ? 10 : 20,
        bottom: mobile ? 10 : 20,
        left: mobile ? 18 : 28
      }
    },
    // Configuración para cuando no hay datos:
    noData: {
      text: 'No hay datos disponibles',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        s̶t̶a̶r̶t̶i̶n̶g̶S̶h̶a̶p̶e̶: 'flat',
        e̶n̶d̶i̶n̶g̶S̶h̶a̶p̶e̶: 'flat',
        borderRadius: 4,
        columnWidth: mobile ? '30%' : '70%',
        barHeight: '70%',
        distributed: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
        colors: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: undefined
            }
          ],
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0
        },
        // Numeros interiores de las columnas
        dataLabels: {
          position: 'center',
          maxItems: 100,
          hideOverflowingLabels: true,
          orientation: 'horizontal'
        }
      }
    },
    // Numeros interiores de las columnas
    dataLabels: {
      enabled: true,
      formatter() {
        return '';
      }
    }
  }
});
