import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { base64RemovetHeader, filetoBase64 } from 'app/utils/file-conversor64';
import { Col, Row } from 'react-bootstrap';
import { Colors } from 'app/utils/colors';
import { genericRequest } from 'app/api';
import Button from 'app/components/Button';
import Input from 'app/components/Input';
import InputFile from 'app/components/InputFile';
import Popup from 'app/components/Popup';
import Spinner from 'app/components/Spinner';
import useDataProviderContext from 'app/hooks/useDataProviderContext';
import { clone } from 'lodash';

export default function AttachInvoiceForm({ show, handleClose }) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const {
    order: { id },
    setDownloadFilesLinks,
    downloadFilesLinks
  } = useDataProviderContext();

  return (
    <Formik
      initialValues={{ file: '', invoiceNumber: '', amount: '' }}
      validationSchema={Yup.object().shape({
        file: Yup.mixed().required(
          intl.formatMessage({ id: 'controlpanel.form.validation.file' })
        ),
        invoiceNumber: Yup.string().required(
          intl.formatMessage({ id: 'controlpanel.form.validation.required' })
        ),
        amount: Yup.number().required(
          intl.formatMessage({ id: 'controlpanel.form.validation.required' })
        )
      })}
      onSubmit={async (values, formikBag) => {
        const { file, amount, invoiceNumber } = values;

        if (file) {
          try {
            const base64 = await filetoBase64(file);
            const response = await genericRequest({
              url: 'invoice-files',
              method: 'post',
              data: {
                data: {
                  type: 'invoice-file',
                  attributes: {
                    file: base64RemovetHeader(base64),
                    amount,
                    invoiceNumber
                  },
                  relationships: {
                    order: { data: { type: 'order', id } }
                  }
                }
              },
              setLoading,
              getHeaders: true,
              customSuccessFeedback: {
                variant: 'success',
                content: intl.formatMessage({
                  id: 'controlpanel.global.upload_file_success'
                })
              }
            });

            if (response?.status === 200) {
              const options = clone(downloadFilesLinks);
              const links = response?.data?.data?.links;

              if (links?.invoices) {
                let titleInvoices = intl.formatMessage({
                  id: 'controlpanel.global.download_invoice'
                });

                if (links?.invoices?.meta?.count > 1) {
                  titleInvoices = intl.formatMessage({
                    id: 'controlpanel.global.download_invoices'
                  });
                }

                const keyOption = options.findIndex((obj) => obj.id === 1);

                if (keyOption > -1) {
                  options.splice(keyOption, 1);
                }

                options.push({
                  id: 1,
                  title: titleInvoices,
                  value: links?.invoices?.href
                });

                setDownloadFilesLinks(options);
              }

              formikBag.resetForm();
              handleClose();
            }
          } catch {
            handleClose();
          }
        }
      }}
    >
      {(formik) => (
        <Popup
          size="lg"
          show={show}
          handleClose={handleClose}
          header={
            <p className="bold">
              {intl.formatMessage({
                id: 'controlpanel.orders.view.title.attach_invoice'
              })}
            </p>
          }
          body={
            <Form>
              {!loading ? (
                <>
                  <Row>
                    <Col md={12}>
                      <InputFile
                        formik={formik}
                        name="file"
                        label={intl.formatMessage({
                          id: 'controlpanel.global.select_file.label'
                        })}
                        placeholder={intl.formatMessage({
                          id: 'controlpanel.global.select_file.placeholder'
                        })}
                        accept="image/jpeg,image/png,application/pdf"
                        svg={{ icon: 'upload', size: 'lg' }}
                        svgPosition="right"
                        bottomComponent={
                          <p style={{ color: Colors.bbGrey4 }}>
                            {intl.formatMessage(
                              { id: 'controlpanel.global.valid_formats' },
                              { formats: 'PNG, JPG, PDF' }
                            )}
                          </p>
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Input
                        name="invoiceNumber"
                        label={intl.formatMessage({
                          id: 'controlpanel.global.indicate_invoice_number'
                        })}
                        placeholder="XXXXXXXXX"
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        name="amount"
                        textPrepend="€"
                        type="number"
                        label={intl.formatMessage({
                          id: 'controlpanel.global.indicate_invoice_amount'
                        })}
                        bottomComponent={
                          <p style={{ color: Colors.bbGrey3 }}>
                            {intl.formatMessage({
                              id: 'controlpanel.global.in_euros'
                            })}
                          </p>
                        }
                        placeholder="405,26"
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <Spinner />
              )}
            </Form>
          }
          footer={
            <Button
              text={intl.formatMessage({ id: 'controlpanel.global.send' })}
              type="primary"
              size="big"
              typeButton="submit"
              onClick={formik.handleSubmit}
            />
          }
        />
      )}
    </Formik>
  );
}

AttachInvoiceForm.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};
