/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import React from 'react';
import { createRoot } from 'react-dom/client';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { MetronicSplashScreenProvider } from 'app/layout/core/MetronicSplashScreen';
import { MetronicI18nProvider } from 'app/context/i18n';
import { PostHogProvider } from 'posthog-js/react';
import { SubheaderProvider } from 'app/context/SubheaderContext';
import App from 'app/App';
import posthog from 'posthog-js';
import store from 'app/config/redux/store';

import './index.scss'; // Standard version
import 'react-datepicker/dist/react-datepicker.css'; // Datepicker
import 'socicon/css/socicon.css';
import 'app/assets/scss/theme/metronic/_assets/plugins/keenthemes-icons/font/ki.css';

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
});

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
// /* const mock = */ _redux.mockAxios(axios);
/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// _redux.setupAxios(axios, store);

const container = document.getElementById('root');
const root = createRoot(container);

(async () => {
  root.render(
    <MetronicI18nProvider>
      <SubheaderProvider>
        <MetronicSplashScreenProvider>
          <PostHogProvider client={posthog}>
            <App store={store} basename={PUBLIC_URL} />
          </PostHogProvider>
        </MetronicSplashScreenProvider>
      </SubheaderProvider>
    </MetronicI18nProvider>
  );
})();
