import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { BasicDataTable } from 'app/components/DataTable';
import { DateFormatter } from 'app/utils/columnsFormatters/DateFormatter';
import { formatCurrency } from 'app/utils/currency';
import Card from 'app/components/Card';
import GetColDataTable from 'app/modules/Purse/components/PendingMovements/utils';
import Badge from 'app/components/Badge';
import useOrdersStatus from 'app/hooks/useOrdersStatus.hook';

export default function PendingMovements({ pendingMovements }) {
  const intl = useIntl();
  return (
    <Card
      extraClass="purse-card-pending-movements"
      icon="exclamation-triangle"
      title={intl.formatMessage({
        id: 'controlpanel.purse.pending_movements'
      })}
      body={
        <BasicDataTable
          id="pending-movements"
          columns={GetColDataTable()}
          entities={FormattedMovementsData(pendingMovements)}
          keyField="id"
        />
      }
    />
  );
}

const FormattedMovementsData = (movements) => {
  const orderStatus = useOrdersStatus();

  return movements?.map((movement) => ({
    id: movement?.id,
    orderNumber: <a href={`/orders/view/${movement?.id}`}>{movement?.id}</a>,
    date: <DateFormatter date={movement?.attributes?.date} />,
    concept: movement?.attributes?.concept,
    amount: formatCurrency(movement?.attributes?.amount),
    status: (
      <Badge
        text={orderStatus?.[movement?.attributes?.status].translation}
        color={orderStatus?.[movement?.attributes?.status].badgeColor}
      />
    ),
    modality: movement?.attributes?.modality
  }));
};

PendingMovements.propTypes = {
  pendingMovements: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      attributes: PropTypes.shape({
        id: PropTypes.number,
        modality: PropTypes.string,
        status: PropTypes.string,
        amount: PropTypes.number,
        concept: PropTypes.string,
        date: PropTypes.number
      })
    })
  ).isRequired
};
