import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';
import Icon from 'app/components/Icon';
import { Colors as colors } from 'app/utils/colors';
import 'app/components/PackInfoCard/components/PackInfoAction.scss';

export default function PackInfoAction({
  action: { icon, customIcon, text, link, alt },
  isMultipleLines,
  useSmallView
}) {
  return (
    <div
      className={classNames([
        'bb-pack-info-action',
        {
          'bb-pack-info-action--small': useSmallView,
          'bb-pack-info-action--normal': !useSmallView
        }
      ])}
    >
      <a
        className={classNames([
          'bb-pack-info-action__button',
          {
            'bb-pack-info-action__button--alt': alt,
            'bb-pack-info-action--multiple-lines': isMultipleLines,
            'bb-pack-info-action--one-line': !isMultipleLines
          }
        ])}
        href={link}
      >
        {customIcon ? (
          <div className="bb-pack-info-action__custom-svg-icon">
            <SVG src={toAbsoluteUrl(customIcon)} />
          </div>
        ) : (
          <Icon
            icon={icon}
            className="bb-pack-info-action__svg-icon"
            size="3x"
            color={alt ? colors.bbWhite : colors.bbSecondary}
          />
        )}

        <p className="bb-pack-info-action__text big medium">{text}</p>
      </a>
    </div>
  );
}

export const ActionPropType = PropTypes.shape({
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.string,
  customIcon: PropTypes.string,
  alt: PropTypes.bool
});

PackInfoAction.propTypes = {
  action: ActionPropType.isRequired,
  isMultipleLines: PropTypes.bool.isRequired,
  useSmallView: PropTypes.bool.isRequired
};
