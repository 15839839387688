import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { useFormikContext } from 'formik';

import DataTable from 'app/components/DataTable';

import DataTableTabs from 'app/components/DataTableTabs';
import SearchBar from 'app/components/SearchBar';
import DataTableFilters from 'app/components/DataTableFilters';
import DataTableFiltersTags from 'app/components/DataTableFiltersTags';

import { Colors } from 'app/utils/colors';
import Icon from 'app/components/Icon';

import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

export default function Table() {
  const intl = useIntl();

  const {
    filters: { filters, removeFilter, parsedFilters }
  } = useDataTableContext();
  const { submitForm } = useFormikContext();

  return (
    <>
      <DataTableTabs />
      <Row className="bb-data-table__filters">
        <Col xs={12} md={5}>
          <SearchBar
            placeholder={intl.formatMessage({
              id: 'controlpanel.global.search'
            })}
            name="search"
            button={{
              onClick: submitForm,
              type: 'secondary',
              size: 'big',
              icon: <Icon icon="search" prefix="fas" color={Colors.bbWhite} />
            }}
            value={filters?.search || ''}
            onClear={() => removeFilter('search')}
          />
        </Col>
        <Col xs={12} md={7} className="bb-data-table__filters-buttons">
          <DataTableFilters />
        </Col>
        {parsedFilters && Object.entries(parsedFilters).length > 0 && (
          <Col md={12}>
            <DataTableFiltersTags />
          </Col>
        )}
      </Row>
      <DataTable />
    </>
  );
}
