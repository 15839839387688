import React from 'react';

import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import 'app/modules/Orders/utils/formatters/styles.scss';

export const formatDropshipping = ({ isDropshipping }) => (
  <div className="dropshipping-formatter">
    <div
      className={classNames('_bb-elipse-notification', {
        green: isDropshipping === true,
        red: isDropshipping === false
      })}
    />
    <FormattedMessage
      id={isDropshipping ? 'controlpanel.global.yes' : 'controlpanel.global.no'}
    />
  </div>
);
