import React from 'react';

import { FormattedMessage } from 'react-intl';

export const formatVirtual = ({ virtual }) => (
  <FormattedMessage
    id={
      virtual
        ? 'controlpanel.orders.filters.virtual.order_services_tag'
        : 'controlpanel.orders.filters.virtual.order_products_tag'
    }
  />
);
