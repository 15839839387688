import React from 'react';

import { URLS } from 'app/config/urls';
import { OrderView } from 'app/modules/OrderView';
import OrderByCsv from 'app/modules/Orders/pages/OrderByCsv';
import Orders from 'app/modules/Orders';
import DeliveryNotes from 'app/modules/OrderView/pages/DeliveryNotes';

export const ordersRoutes = {
  path: URLS.orders,
  children: [
    { path: '', element: <Orders /> },
    { path: URLS.ordersView, element: <OrderView /> },
    { path: URLS.orderByCsv, element: <OrderByCsv /> },
    { path: URLS.ordersDeliveryNotes, element: <DeliveryNotes /> }
  ]
};
