export const parserServices = (response) => {
  const info = [];
  if (response) {
    response?.forEach((res) => {
      const purchased = [];
      const { id, attributes, relationships } = res;
      const { image, mainText } = attributes;
      if (relationships) {
        const { data } = relationships.purchased;
        data.forEach((activeService) => {
          purchased.push(activeService.meta.date);
        });
      }
      const item = {
        id,
        image,
        mainText,
        purchased
      };
      info.push(item);
    });
    return info;
  }
  return null;
};
