import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';
import SVG from 'react-inlinesvg';

import { Colors } from 'app/utils/colors';
import Icon from 'app/components/Icon';

export function BreadCrumbs({ items = [] }) {
  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot p-0 my-2">
      <li className="breadcrumb-item">
        <Link to="/dashboard">
          <SVG
            src={toAbsoluteUrl('/media/svg/bigbuy/small/home.svg')}
            className="bb-icon hover-blue"
          />
        </Link>
        {items > 0 && (
          <Icon
            className="breadcrumb-item__icon"
            icon="circle"
            size="xs"
            prefix="fas"
            color={Colors.bbGrey2}
          />
        )}
      </li>
      {items.map((item, index) => (
        <li key={`bc${index}`} className="breadcrumb-item">
          <Link className="text-muted" to={{ pathname: item.pathname }}>
            <p className="regular">{item.title}</p>
          </Link>
          <Icon
            className="breadcrumb-item__icon"
            icon="circle"
            prefix="fas"
            color={Colors.bbGrey2}
          />
        </li>
      ))}
    </ul>
  );
}

BreadCrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  )
};
