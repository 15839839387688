import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { BasicDataTable } from 'app/components/DataTable';
import { Colors } from 'app/utils/colors';
import { DateFormatter } from 'app/modules/OrderView/config/TableFormatters/DateFormatter';
import { genericRequest } from 'app/api';
import { isMobile } from 'app/utils/sizes';
import Alert from 'app/components/Alert';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import Badge from 'app/components/Badge';
import useDeliveryNoteStatus from 'app/hooks/useDeliveryNoteStatus.hook';

import 'app/modules/OrderView/config/TableFormatters/ActionsFormatter/ActionsFormatter.scss';

export default function DeliveryNotesDatatable({
  fetchedDeliveryNotes = null,
  isLoading = false,
  showView = true,
  orderId
}) {
  const intl = useIntl();

  const [loading, setLoading] = useState(isLoading);
  const [deliveryNotes, setDeliveryNotes] = useState([]);

  useEffect(() => setLoading(isLoading), [isLoading]);

  const fetchData = useCallback(async () => {
    const tableContent =
      fetchedDeliveryNotes ||
      (await genericRequest({
        url: `orders/${orderId}/delivery-notes`,
        setLoading
      })?.data) ||
      [];

    setDeliveryNotes(getDataTableStructure(tableContent));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedDeliveryNotes, orderId]);

  useEffect(() => {
    fetchData();

    return () => {
      setDeliveryNotes([]);
    };
  }, [fetchData]);

  return (
    <>
      <Alert
        variant="info"
        content={intl.formatMessage({
          id: 'controlpanel.feedback.loading_delivery_notes'
        })}
        autoClose
        dismissible
      />
      <BasicDataTable
        id="delivery-notes-datatable"
        columns={GetColDataTable({ showView, orderId })}
        entities={deliveryNotes}
        keyField="deliveryNoteId"
        loading={loading}
      />
    </>
  );
}

const getDataTableStructure = (data) =>
  data.map(
    ({
      id: deliveryNoteId,
      attributes: {
        created: date,
        name: deliveryNoteNumber,
        status,
        carrier,
        weight,
        numberOfPackages,
        linesCount: productsNumber,
        pod,
        file
      }
    }) => ({
      deliveryNoteId,
      date,
      deliveryNoteNumber,
      status,
      carrier,
      weight,
      numberOfPackages,
      productsNumber,
      pod,
      file
    })
  );

function GetColDataTable({ showView, orderId }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const deliveryNoteStatus = useDeliveryNoteStatus();

  const columnsArray = [
    {
      dataField: 'id',
      text: '',
      hidden: true,
      sort: false
    },
    {
      dataField: 'date',
      text: intl.formatMessage({ id: 'controlpanel.global.filter.date' }),
      sort: true,
      hidden: false,
      formatter: DateFormatter
    },
    {
      dataField: 'deliveryNoteNumber',
      text: intl.formatMessage({
        id: 'controlpanel.trackings.table.delivery_note_number'
      }),
      sort: true,
      hidden: false
    },
    {
      dataField: 'status',
      text: intl.formatMessage({ id: 'controlpanel.global.filter.status' }),
      sort: true,
      hidden: false,
      formatter: StatusFormatter,
      formatExtraData: { deliveryNoteStatus }
    },
    {
      dataField: 'carrier',
      text: intl.formatMessage({ id: 'controlpanel.orders.table.carrier' }),
      sort: true,
      hidden: false
    },
    {
      dataField: 'weight',
      text: intl.formatMessage({ id: 'controlpanel.global.weight' }),
      sort: true,
      hidden: isMobile()
    },
    {
      dataField: 'numberOfPackages',
      text: intl.formatMessage({ id: 'controlpanel.global.bulk_number' }),
      sort: true,
      hidden: isMobile()
    },
    {
      dataField: 'productsNumber',
      text: intl.formatMessage({ id: 'controlpanel.global.products_number' }),
      sort: true,
      hidden: isMobile()
    }
  ];

  columnsArray.push({
    dataField: 'actions',
    text: intl.formatMessage({ id: 'controlpanel.global.table_actions' }),
    formatter: ActionsFormatter,
    classes: 'column-action',
    sort: false,
    formatExtraData: {
      navigate,
      intl,
      orderId,
      params,
      showView
    }
  });

  return columnsArray;
}

const getPod = async (deliveryNoteId, orderId) => {
  const result = await genericRequest({
    url: `orders/${orderId}/delivery-notes/${deliveryNoteId}/pod`
  });
  if (result) {
    const url = result.data.attributes.link;
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', `test`);
    downloadLink.click();
  }
};

const downloadDeliveryNote = async (deliveryNoteId, orderId) => {
  const result = await genericRequest({
    url: `orders/${orderId}/delivery-notes/downloads`,
    method: 'POST',
    data: {
      data: {
        type: 'delivery-note-files',
        id: orderId,
        attributes: {
          format: 'pdf',
          includedIds: [deliveryNoteId]
        }
      }
    }
  });
  if (result) {
    const url = result.data.attributes.link;
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', `test`);
    downloadLink.click();
  }
};

function ActionsFormatter(cell, row, rowIndex, formatExtraData) {
  const { navigate, intl, params, orderId, showView } = formatExtraData;
  const { bbSecondary } = Colors;
  const { deliveryNoteId, pod, file } = row;

  return (
    <div className="actions-formatter">
      {showView && (
        <Button
          icon={<Icon icon="eye" color={bbSecondary} prefix="fad" />}
          onClick={() =>
            navigate(`/orders/${params.id}/delivery-notes/${deliveryNoteId}`)
          }
          size="medium"
          tooltipText={intl.formatMessage({
            id: 'controlpanel.global.view_associated_delivery_notes'
          })}
        />
      )}
      <Button
        icon={<Icon icon="file-download" color={bbSecondary} prefix="fad" />}
        onClick={() => getPod(deliveryNoteId, orderId)}
        disabled={!pod}
        size="medium"
        tooltipText={intl.formatMessage({
          id: 'controlpanel.global.download_pod'
        })}
      />
      <Button
        icon={<Icon icon="download" color={bbSecondary} prefix="fad" />}
        onClick={() => downloadDeliveryNote(deliveryNoteId, orderId)}
        disabled={!file}
        size="medium"
        tooltipText={intl.formatMessage({
          id: 'controlpanel.global.download_delivery_note'
        })}
      />
    </div>
  );
}

const StatusFormatter = (cell, row, rowIndex, formatExtraData) => {
  const { deliveryNoteStatus } = formatExtraData;

  if (!cell) {
    return null;
  }

  return (
    <Badge
      text={deliveryNoteStatus?.[cell]?.translation}
      color={deliveryNoteStatus?.[cell]?.color}
    />
  );
};

DeliveryNotesDatatable.propTypes = {
  fetchedDeliveryNotes: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.array
  ]),
  isLoading: PropTypes.bool,
  showView: PropTypes.bool,
  orderId: PropTypes.number.isRequired
};
