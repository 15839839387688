import React from 'react';

import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { REGEX_ADDRESS, REGEX_PHONE } from 'app/utils/regex';
import { testZipCodes } from 'app/modules/MyAccount/pages/Billing/utils';

export const companyFormFields = [
  {
    name: 'company',
    labelKey: 'controlpanel.form.label.company',
    disabled: true
  },
  {
    name: 'commercialName',
    labelKey: 'controlpanel.form.label.comercial_name',
    disabled: true
  },
  {
    name: 'address',
    labelKey: 'controlpanel.form.label.address',
    disabled: true
  },
  {
    name: 'vat',
    labelKey: 'controlpanel.form.label.nif',
    disabled: true
  },
  {
    name: 'city',
    labelKey: 'controlpanel.form.label.city',
    disabled: true
  },
  {
    name: 'postCode',
    labelKey: 'controlpanel.form.label.postal_code',
    disabled: true
  },
  {
    name: 'state',
    labelKey: 'controlpanel.form.label.province',
    disabled: true
  },
  {
    name: 'web',
    labelKey: 'controlpanel.form.label.web',
    disabled: true
  }
];

export const companyFormValidation = Yup.object({
  address: Yup.string()
    .max(
      255,
      <FormattedMessage
        id="controlpanel.form.validation.max_length"
        values={{ length: 255 }}
      />
    )
    .matches(
      REGEX_ADDRESS,
      <FormattedMessage id="controlpanel.form.validation.invalid_telephone" />
    )
    .required(<FormattedMessage id="controlpanel.form.validation.required" />),
  city: Yup.string()
    .max(
      100,
      <FormattedMessage
        id="controlpanel.form.validation.max_length"
        values={{ length: 100 }}
      />
    )
    .required(<FormattedMessage id="controlpanel.form.validation.required" />),
  postCode: Yup.string()
    .test(
      'validate-postCode',
      <FormattedMessage
        id="controlpanel.form.validation.zip_code_format"
        values={{ length: 50 }}
      />,
      function (value) {
        const test = testZipCodes.call(value, this);
        return test;
      }
    )
    .required(<FormattedMessage id="controlpanel.form.validation.required" />),
  phone: Yup.string()
    .max(
      30,
      <FormattedMessage
        id="controlpanel.form.validation.max_length"
        values={{ length: 30 }}
      />
    )
    .matches(REGEX_PHONE, {
      message: (
        <FormattedMessage id="controlpanel.form.validation.invalid_telephone" />
      )
    })
    .required(<FormattedMessage id="controlpanel.form.validation.required" />)
});
