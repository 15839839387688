import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import Process from 'app/modules/Shops/pages/Shopify/components/Process';

import 'app/modules/Shops/pages/Shopify/components/Header.scss';

export default function Header({ steps, hasHistory, academyLink = null }) {
  return (
    <Row>
      <Col md={12} className="shopify-form__header">
        <Process
          steps={steps}
          hasHistory={hasHistory}
          academyLink={academyLink}
        />
      </Col>
    </Row>
  );
}

Header.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      translationKey: PropTypes.string.isRequired
    })
  ).isRequired,
  hasHistory: PropTypes.bool.isRequired,
  academyLink: PropTypes.string
};
