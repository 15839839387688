/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';

import { authActionTypes } from 'app/modules/Auth/_redux/AuthConstants';

const initialState = {
  user: null
};

const AuthReducer = (state = initialState, action = '') =>
  produce(state, (draft) => {
    switch (action.type) {
      case authActionTypes.Login: {
        draft.user = action.payload?.user;
        break;
      }
      case authActionTypes.Logout: {
        draft.user = null;
        window.location.replace(
          `${process.env.REACT_APP_BIGBUY_URL}account/logout`
        );
        break;
      }
      case authActionTypes.SetUser: {
        draft.user = action.payload?.user;
        break;
      }
      case authActionTypes.SetAccessToken: {
        break;
      }
      case authActionTypes.SetWalletLimit: {
        if (draft.user) {
          draft.user.attributes = {
            ...draft.user.attributes,
            walletLimit: action.payload
          };
        }
        break;
      }
      case authActionTypes.SetProductsInCart: {
        if (draft.user) {
          draft.user.attributes = {
            ...draft.user.attributes,
            productsInCart: action.payload
          };
        }
        break;
      }
      default:
        return state;
    }
  });

export default AuthReducer;
