/* eslint-disable no-shadow */
import React, { useEffect, useState, useCallback } from 'react';

import { Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { genericRequest } from 'app/api';
import { URLS } from 'app/config/urls';
import Button from 'app/components/Button';
import FormCard from 'app/components/FormCard';
import FormSpinner from 'app/components/FormSpinner';
import InformationForm from 'app/modules/MyAccount/pages/Information/InformationForm';
import MyAccountLayout from 'app/modules/MyAccount/components/MyAccountLayout';

export default function Information() {
  const answersInitialValues = {
    additionalInfo: '',
    contactPreferences: null,
    onlineShop: null,
    parentContactPreferences: null,
    solutions: [],
    initialState: true
  };
  const [answers, setAnswers] = useState(answersInitialValues);
  const [showInput, setShowInput] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [parentContactPreferences, setParentContactPreferences] =
    useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const intl = useIntl();
  const userId = useSelector((state) => state.auth.user.id);

  const getData = useCallback(async () => {
    const result = await genericRequest({
      url: `answers/${userId}`,
      setLoading: setIsLoading
    });
    setParentContactPreferences(
      result?.data?.attributes?.parentContactPreferences?.toString()
    );
    setAnswers(parseAnswers(result?.data?.attributes));
  }, [userId]);

  useEffect(() => {
    if (!answers?.initialState) {
      onChangeFormHandler(
        'onlineShop',
        answers.onlineShop,
        setShowSelect,
        setParentContactPreferences,
        setShowInput
      );
      onChangeFormHandler(
        'parentContactPreferences',
        answers.parentContactPreferences,
        setShowSelect,
        setParentContactPreferences,
        setShowInput
      );
    }
  }, [answers]);

  const onSubmitHandler = async (values, userId) => {
    const {
      additionalInfo,
      solutions,
      onlineShop,
      onlineShopURL,
      contactPreferences,
      parentContactPreferences
    } = values;

    const data = {
      type: 'customer-answers',
      id: userId,
      attributes: {
        additionalInfo,
        solutions: solutions.map((str) => Number(str)),
        onlineShop: Number(onlineShop),
        contactPreferences:
          parentContactPreferences !== '58' ? Number(contactPreferences) : null,
        parentContactPreferences: Number(parentContactPreferences)
      }
    };

    if (Number(onlineShop) === 32) {
      data.attributes.onlineShopURL = onlineShopURL;
    }

    try {
      await genericRequest({
        url: `answers/${userId}`,
        method: 'patch',
        data: { data },
        setLoading: setIsLoading,
        customSuccessFeedback: {
          variant: 'success',
          content: intl.formatMessage({
            id: 'controlpanel.feedback.save_changes.success'
          }),
          autoClose: true,
          dismissible: false
        }
      });
      setAnswers({ initialState: true });
      await getData();
    } catch {
      setAnswers({ initialState: true });
      await getData();
    }
  };

  useEffect(() => {
    getData();

    return () => {
      setAnswers(answersInitialValues);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  if (isLoading || answers?.initialState) {
    return (
      <div>
        <MyAccountLayout currentPagePath={URLS.myAccountInformation}>
          <Col md={8}>
            <FormSpinner title="controlpanel.global.information" showButtons />
          </Col>
        </MyAccountLayout>
      </div>
    );
  }

  return (
    <MyAccountLayout currentPagePath={URLS.myAccountInformation}>
      <Col md={8}>
        <Formik
          initialValues={answers}
          validationSchema={getFormValidations(intl, showInput, showSelect)}
          onSubmit={async (values) => onSubmitHandler(values, userId)}
        >
          {(formik) => (
            <Form
              onChange={(e) =>
                onChangeFormHandler(
                  e.target.name,
                  e.target.value,
                  setShowSelect,
                  setParentContactPreferences,
                  setShowInput
                )
              }
            >
              <FormCard
                title={intl.formatMessage({
                  id: 'controlpanel.global.information'
                })}
                buttons={
                  <>
                    <Button
                      size="big"
                      text="Cancelar"
                      onClick={() => formik.handleReset()}
                    />
                    <Button
                      size="big"
                      type="secondary"
                      typeButton="submit"
                      text={intl.formatMessage({
                        id: 'controlpanel.global.save'
                      })}
                      disabled={!formik.dirty}
                    />
                  </>
                }
              >
                <InformationForm
                  showSelect={showSelect}
                  showInput={showInput}
                  parentContactPreferences={parentContactPreferences}
                />
              </FormCard>
            </Form>
          )}
        </Formik>
      </Col>
    </MyAccountLayout>
  );
}

const parseAnswers = (answers) => {
  const {
    additionalInfo,
    solutions,
    onlineShop,
    onlineShopURL,
    parentContactPreferences,
    contactPreferences
  } = answers;

  return {
    additionalInfo: additionalInfo || '',
    solutions:
      solutions !== null ? solutions?.map((str) => str.toString()) : [],
    onlineShop: onlineShop?.toString() || null,
    onlineShopURL: onlineShopURL || '',
    parentContactPreferences: parentContactPreferences?.toString() || null,
    contactPreferences: contactPreferences?.toString() || ''
  };
};

const onChangeFormHandler = (
  name,
  value,
  setShowSelect,
  setParentContactPreferences,
  setShowInput
) => {
  switch (name) {
    case 'parentContactPreferences':
      if (value && value !== '58') {
        setShowSelect(true);
      } else {
        setShowSelect(false);
      }

      setParentContactPreferences(value);
      break;

    case 'onlineShop':
      if (value && value === '32') {
        setShowInput(true);
      } else {
        setShowInput(false);
      }
      break;

    default:
      break;
  }
};

const getFormValidations = (intl, showInput, showSelect) =>
  Yup.object().shape({
    additionalInfo: Yup.string()
      .max(
        254,
        intl.formatMessage(
          {
            id: 'controlpanel.form.validation.max_length'
          },
          {
            length: 255
          }
        )
      )
      .min(
        40,
        intl.formatMessage(
          {
            id: 'controlpanel.form.validation.min_length'
          },
          {
            length: 40
          }
        )
      )
      .required(
        intl.formatMessage({ id: 'controlpanel.form.validation.required' })
      ),
    solutions: Yup.array().required(
      intl.formatMessage({ id: 'controlpanel.form.validation.required' })
    ),
    onlineShop: Yup.mixed(),
    onlineShopURL:
      showInput &&
      Yup.string()
        .required(
          intl.formatMessage({ id: 'controlpanel.form.validation.required' })
        )
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          intl.formatMessage({ id: 'controlpanel.form.validation.url' })
        ),
    parentContactPreferences: Yup.mixed(),
    contactPreferences:
      showSelect &&
      Yup.string().required(
        intl.formatMessage({ id: 'controlpanel.form.validation.required' })
      )
  });
