import React, { Suspense } from 'react';

import { useRoutes } from 'react-router-dom';

import { devolutionsRoutes } from 'app/config/Routes/devolutionsRoutes';
import { LayoutSplashScreen } from 'app/layout';
import { myAccountRoutes } from 'app/config/Routes/myAccountRoutes';
import { MyPage } from 'app/pages/MyPage/MyPage';
import { ordersRoutes } from 'app/config/Routes/ordersRoutes';
import { shopsRoutes } from 'app/config/Routes/shopsRoutes';
import { URLS } from 'app/config/urls';
import DashboardPage from 'app/pages/DashboardPage';
import Downloads from 'app/modules/Downloads';
import ErrorPage from 'app/pages/ErrorPage/ErrorPage';
import Invoices from 'app/modules/Invoices';
import Logout from 'app/modules/Auth/pages/Logout';
import MultiChannelPage from 'app/pages/MIP/MultiChannelPage';
import Purse from 'app/modules/Purse';
import Redirect from 'app/components/Redirect';
import Trackings from 'app/modules/Trackings';
import Wishlist from 'app/modules/Wishlist';
import Academy from 'app/pages/Academy';

export default function BasePage() {
  const routes = useRoutes([
    {
      path: URLS.home,
      element: <Redirect to={URLS.dashboard} />
    },
    {
      path: URLS.myAccount,
      element: <Redirect to={URLS.myAccountSummary} />
    },
    myAccountRoutes,
    ordersRoutes,
    devolutionsRoutes,
    {
      path: URLS.dashboard,
      element: <DashboardPage />
    },
    {
      path: URLS.tracking,
      element: <Trackings />
    },
    {
      path: URLS.invoices,
      element: <Invoices />
    },
    {
      path: URLS.purse,
      element: <Purse />
    },
    {
      path: URLS.credit,
      element: <MyPage />
    },
    shopsRoutes,
    {
      path: URLS.downloads,
      element: <Downloads />
    },
    {
      path: URLS.wishlist,
      element: <Wishlist />
    },
    {
      path: URLS.productInformation,
      element: <MyPage />
    },
    {
      path: URLS.multichannel,
      element: <MultiChannelPage />
    },
    {
      path: URLS.logout,
      element: <Logout />
    },
    {
      path: URLS.error,
      element: <ErrorPage />
    },
    {
      path: '*',
      element: <ErrorPage />
    },
    {
      path: URLS.academy,
      element: <Academy />
    }
  ]);
  return <Suspense fallback={<LayoutSplashScreen />}>{routes}</Suspense>;
}
