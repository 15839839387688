import React from 'react';
import PropTypes from 'prop-types';
import getPackColor from 'app/utils/parsers/getPackColor';
import 'app/modules/MyAccount/pages/WholesalerPack/components/WholesalerPackTag.scss';

export default function WholesalerPackTag({ name, id }) {
  return (
    <span
      role="none"
      className="bb-wholesaler-pack-tag"
      style={{ backgroundColor: getPackColor(id) }}
    >
      <p className="small bold">{name}</p>
    </span>
  );
}

WholesalerPackTag.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
};
