import React, { useState, useLayoutEffect, useMemo } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Colors } from 'app/utils/colors';
import { DataTableProvider } from 'app/components/DataTable/context/DataTableContext';
import { DropshippingShopBanner } from 'app/components/DropshippingShopBanner/DropshippingShopBanner';
import { filter, size } from 'lodash';
import { getShopsTableConfiguration } from 'app/modules/Shops/config/config';
import { useSubheader } from 'app/context/SubheaderContext';
import DataTable from 'app/components/DataTable';
import Icon from 'app/components/Icon';
import useNotificationsServiceHook from 'app/hooks/useNotificationsService.hook';
import UserNotificationAlert from 'app/components/UserNotificationAlert';

import 'app/modules/Shops/ShopsPage.scss';

const { bbSecondaryDark } = Colors;

export default function ShopsPage() {
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const [shops] = useState(user?.relationships?.shops360?.data || []);
  const hasShops = useMemo(() => !!shops?.length, [shops]);
  const subheader = useSubheader();
  const { setTitle, setIcon, setBreadcrumbs } = subheader;

  useLayoutEffect(() => {
    setTitle(
      intl.formatMessage({
        id: 'controlpanel.breadcrumbs.shops'
      })
    );
    setIcon({ icon: 'store' });
    setBreadcrumbs([]);
  }, [intl, setBreadcrumbs, setIcon, setTitle]);

  const {
    notifications: { alerts }
  } = useNotificationsServiceHook();

  const alertsFiltered = filter(
    alerts,
    (alert) => alert?.attributes?.section === 'SHOP'
  );

  return (
    <>
      {size(alertsFiltered) > 0 && (
        <UserNotificationAlert alerts={alertsFiltered} />
      )}

      {hasShops && (
        <DataTableProvider
          getTableContextConfiguration={getShopsTableConfiguration}
        >
          <div className="bb-formcard bb-shops">
            <div className="bb-formcard bb-shops__data-table__wrapper bb-formcard__container">
              <div className="bb-shops__data-table__header">
                <div className="bb-shops__data-table__header__icon">
                  <Icon icon="store" color={bbSecondaryDark} size="lg" />
                </div>
                <p className="big bold bb-shops__data-table__header__title">
                  {intl.formatMessage({
                    id: 'controlpanel.shops.card_header'
                  })}
                </p>
              </div>
              <div className="bb-formcard__body">
                <DataTable />
              </div>
            </div>
          </div>
        </DataTableProvider>
      )}
      <Row>
        <Col md={12} className="">
          <DropshippingShopBanner hasShops={hasShops} horizontally />
        </Col>
      </Row>
    </>
  );
}
