import { useCallback, useEffect } from 'react';

import { genericRequest } from 'app/api';
import useDataTableComponentsContext from 'app/components/DataTableComponents/hooks/useDataTableComponentsContext';
import { getFiltersUrl } from 'app/components/DataTable/utils';

export default function useApi() {
  const { context, setContext } = useDataTableComponentsContext();

  const { url, options } = context?.api;
  const filtersUrl = getFiltersUrl(context?.filters);

  const fetchData = useCallback(
    async (controller) => {
      const result = await genericRequest({
        url: `${url}?page[size]=${
          options?.sizePerPage || 10
        }&page[number]=${options?.page - 1 || 0}${filtersUrl}`,
        signal: controller.signal
      });

      setContext((prevState) => ({
        ...prevState,
        api: { ...prevState?.api, ...result, loading: false }
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options?.sizePerPage, options?.page, filtersUrl, url]
  );

  useEffect(() => {
    setContext((prevState) => ({
      ...prevState,
      api: { ...prevState?.api, loading: true }
    }));

    const controller = new AbortController();
    fetchData(controller);

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);
}
