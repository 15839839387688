import React from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';

import Tag from 'app/components/Tag';

import 'app/components/DataTableFiltersTags/DataTableFiltersTags.scss';

export default function TagsGroup({ tags }) {
  return (
    <div className="datatable-filters-tags">
      {map(tags, (tag, key) => {
        const { closeHandler, translation } = tag;
        return (
          <Tag
            key={key}
            color="blue"
            id="tag"
            onClose={() => closeHandler()}
            size="small"
            text={translation}
            showIcon
          />
        );
      })}
    </div>
  );
}

TagsGroup.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      closeHandler: PropTypes.func,
      translation: PropTypes.string
    })
  ).isRequired
};
