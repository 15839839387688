import React from 'react';

import { map } from 'lodash';

import { sortCaret, headerSortingStyle } from 'app/components/DataTable/utils';
import { WishlistNoResultsFound } from 'app/modules/Wishlist/Wishlist';
import ActionsFormatters from 'app/modules/Wishlist/config/table/formatters/ActionsFormatter';
import NextEntries from 'app/modules/Wishlist/config/table/formatters/NextEntries';
import ProductFormatter from 'app/modules/Wishlist/config/table/formatters/ProductFormatter';
import StockFormatter from 'app/modules/Wishlist/config/table/formatters/StockFormatter';

export const getColumnsToDataTable = (intl) => [
  {
    dataField: 'id',
    text: '',
    hidden: true,
    sort: false,
    draggable: false
  },
  {
    dataField: 'reference',
    text: intl.formatMessage({ id: 'controlpanel.global.reference' }),
    hidden: false,
    sort: true,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'product',
    text: intl.formatMessage({ id: 'controlpanel.global.product' }),
    hidden: false,
    sort: true,
    draggable: true,
    style: {
      width: '400px',
      minWidth: '400px'
    },
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'stock',
    text: intl.formatMessage({ id: 'controlpanel.global.stock' }),
    hidden: false,
    sort: false,
    draggable: true,
    style: {
      width: '150px',
      minWidth: '150px'
    },
    sortCaret
  },
  {
    dataField: 'nextEntries',
    text: intl.formatMessage({ id: 'controlpanel.global.next_entries' }),
    hidden: false,
    sort: false,
    draggable: true,
    style: {
      width: '200px',
      minWidth: '200px'
    },
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'actions',
    text: intl.formatMessage({
      id: 'controlpanel.global.table_actions'
    }),
    hidden: false,
    sort: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  }
];

export const getDataTableStructure = (data) => {
  const dataStructure = [];
  map(data, (value) => {
    const {
      id,
      attributes: { image, nextEntries, reference, stock, name, link }
    } = value;

    dataStructure.push({
      id,
      reference,
      product: <ProductFormatter image={image} title={name} />,
      stock: <StockFormatter stock={stock} />,
      nextEntries: <NextEntries nextEntries={nextEntries} />,
      actions: <ActionsFormatters productUrl={link} id={id} />
    });
  });

  return dataStructure;
};

export const getNoResultsConfig = () => ({
  component: <WishlistNoResultsFound />
});
