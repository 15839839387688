import React from 'react';

import { useIntl } from 'react-intl';

import Card from 'app/components/Card/Card';
import CollectionAddressForm from 'app/modules/Devolutions/components/CollectionAddressForm';

import 'app/modules/Devolutions/components/CollectionAddressCard/CollectionAddressCard.scss';

export default function CollectionAddressCard() {
  const intl = useIntl();

  return (
    <Card
      extraClass="collection-address-card"
      icon="truck"
      title={intl.formatMessage({
        id: 'controlpanel.global.collection_address_data'
      })}
      body={<CollectionAddressForm disabled />}
    />
  );
}
