import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import Button from 'app/components/Button';
import Input from 'app/components/Input';

export default function NotificationFormDisabled({
  notificationAmount,
  updateWalletMovements,
  setNotificationAmount,
  setPopupConfig
}) {
  const intl = useIntl();

  return (
    <Row className="purse-card-notifications__active-form">
      <Col md={12} className="purse-card-notifications__active-form-label">
        <p>
          {intl.formatMessage({
            id: 'controlpanel.purse.notification.active_label'
          })}
        </p>
      </Col>
      <Col md={8}>
        <Formik>
          <Input
            disabled
            placeholder={notificationAmount?.toString()}
            textPrepend="€"
            name="amount"
          />
        </Formik>
      </Col>
      <Col md={4}>
        <Button
          type="secondary"
          text={intl.formatMessage({
            id: 'controlpanel.purse.form.disabled_button'
          })}
          size="big"
          onClick={async () => {
            const update = await updateWalletMovements(null, false);

            if (update?.status === 204) {
              setPopupConfig({
                showPopup: true,
                popupTitle: intl.formatMessage({
                  id: 'controlpanel.purse.popup.disabled_title'
                }),
                popupMsg: intl.formatMessage({
                  id: 'controlpanel.purse.popup.disabled_msg'
                })
              });
              setNotificationAmount(null);
            }
          }}
        />
      </Col>
    </Row>
  );
}

NotificationFormDisabled.propTypes = {
  notificationAmount: PropTypes.number.isRequired,
  updateWalletMovements: PropTypes.func.isRequired,
  setNotificationAmount: PropTypes.func.isRequired,
  setPopupConfig: PropTypes.func.isRequired
};
