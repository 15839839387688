export const getColumnsToDataTable = (intl) => [
  {
    dataField: 'name',
    sort: false,
    text: intl.formatMessage({
      id: 'controlpanel.global.product_name'
    })
  },
  {
    dataField: 'taric',
    sort: false,
    text: intl.formatMessage({
      id: 'controlpanel.orders.view.detail_table.taric'
    })
  },
  {
    dataField: 'reference',
    sort: false,
    text: intl.formatMessage({
      id: 'controlpanel.orders.view.detail_table.reference'
    })
  },
  {
    dataField: 'quantity',
    sort: false,
    text: intl.formatMessage({
      id: 'controlpanel.orders.view.detail_table.quantity'
    })
  },
  {
    dataField: 'unitPrice',
    align: 'right',
    headerAlign: 'right',
    sort: false,
    text: intl.formatMessage({
      id: 'controlpanel.orders.view.detail_table.unit_price'
    }),
    classes: 'numeric'
  },
  {
    dataField: 'discount',
    align: 'right',
    headerAlign: 'right',
    sort: false,
    text: intl.formatMessage({
      id: 'controlpanel.orders.view.detail_table.discount'
    }),
    classes: 'numeric'
  },
  {
    dataField: 'priceAfterDiscount',
    align: 'right',
    headerAlign: 'right',
    sort: false,
    text: intl.formatMessage({
      id: 'controlpanel.orders.view.detail_table.price_after_discount'
    }),
    classes: 'numeric'
  },
  {
    dataField: 'totalAmount',
    align: 'right',
    headerAlign: 'right',
    sort: false,
    text: intl.formatMessage({
      id: 'controlpanel.orders.view.detail_table.total_amount'
    }),
    classes: 'numeric'
  }
];

export const tableConfiguration = {
  wrapperClasses: 'table-responsive',
  classes: 'table table-head-custom table-vertical-center overflow-hidden',
  bootstrap4: true,
  bordered: false
};
