import React from 'react';

import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { DropdownTopbarItemToggler } from 'app/layout/partials/DropdownTopbarItemToggler';
import { logout } from 'app/modules/Auth/_redux/AuthActions';
import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';
import { URLS } from 'app/config/urls';
import Button from 'app/components/Button';
import UserInitials from 'app/components/UserInitials';

import 'app/components/UserProfileDropdown/index.scss';

export function UserProfileDropdown() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  return (
    <Dropdown drop="down" className="user-profile-dropdown">
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="tooltip-dropdown-toggle-user-profile">
              {intl.formatMessage({ id: 'controlpanel.asidemenu.my_account' })}
            </Tooltip>
          }
        >
          <div className="bb-btn-item">
            <p className="medium d-none d-lg-inline mr-1 user-profile-dropdown__greeting">
              {`${intl.formatMessage({
                id: 'controlpanel.global.hi'
              })}, `}
            </p>
            <p className="medium d-none d-lg-inline mr-3 user-profile-dropdown__greeting--name">
              {user?.attributes?.name}
            </p>
            <span className="symbol symbol-35">
              <span className="symbol-label font-size-h5 font-weight-bold">
                <UserInitials />
              </span>
            </span>
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <div className="d-flex align-items-center p-8 rounded-top dropdown-menu-header">
          <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
            <span className="symbol-label font-size-h5 font-weight-bold">
              <UserInitials />
            </span>
          </div>
          <div className="name-container flex-grow-1">
            <p className="big bold">{`${user?.attributes?.name} ${user?.attributes?.surnames}`}</p>
            <p className="medium">{`ID: ${user.id}`}</p>
          </div>
        </div>
        <div className="separator separator-solid" />

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to={URLS.myAccount} className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-text">
                <SVG
                  src={toAbsoluteUrl('/media/svg/bigbuy/large/user.svg')}
                  width={26}
                  height={26}
                />
                <p className="bold">
                  {intl.formatMessage({
                    id: 'controlpanel.asidemenu.my_account'
                  })}
                </p>
              </div>
            </div>
          </Link>
          <div className="navi-separator mt-3" />
          <div className="navi-footer px-8 py-5">
            <Button
              className="bb-btn bb-btn--big"
              onClick={() => dispatch(logout())}
              text={intl.formatMessage({ id: 'controlpanel.global.logout' })}
              size="big"
            />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
