import React from 'react';
import PropTypes from 'prop-types';

import placeholderImage from 'app/assets/svg/placeholder.svg';

export const Image = ({ src = '', alt = 'default-image' }) => {
  const onImageError = (e) => {
    e.target.src = placeholderImage;
  };
  return <img src={src || placeholderImage} alt={alt} onError={onImageError} />;
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string
};
