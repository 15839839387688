import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { genericRequest } from 'app/api';
import { getValidationSchema } from 'app/modules/Shops/pages/Shopify/context/config';
import {
  SHOP_PROPTYPES,
  parseShop
} from 'app/modules/Shops/pages/Shopify/utils';
import { translateCountryToLocale } from 'app/utils/parsers/translateCountryToLocale';

export default function FormikContext({
  children,
  shop,
  setIsLoading,
  setShop,
  setShopHistory,
  disabledLogoAndFavicon
}) {
  const intl = useIntl();
  const validationSchema = useMemo(
    () => getValidationSchema(intl, disabledLogoAndFavicon),
    [intl, disabledLogoAndFavicon]
  );

  const onUploadInfo = async (payload, actions) => {
    setIsLoading(true);
    const {
      shopId,
      address,
      city,
      email,
      province,
      zipCode,
      mainLanguage,
      languages,
      currency,
      favicon,
      logo,
      shopName,
      theme,
      catalogue,
      customizedLogo
    } = payload;

    const parsedPayload = {
      address,
      city,
      email,
      province,
      zipCode,
      mainLanguage,
      languages,
      currency,
      shopName,
      theme,
      catalogue
    };

    if (favicon) {
      parsedPayload.favicon = favicon;
    }
    if (logo) {
      parsedPayload.logo = logo;
    }

    if (customizedLogo) {
      parsedPayload.customizedLogo = customizedLogo.length > 0;
    }

    await genericRequest({
      url: `shops360/${shopId}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'shopify',
          attributes: parsedPayload,
          id: shopId
        }
      },
      customSuccessFeedback: {
        variant: 'success',
        content: intl.formatMessage({
          id: 'controlpanel.feedback.save_changes.success'
        }),
        autoClose: true,
        dismissible: false
      }
    });

    const { data: allShops } = await genericRequest({
      url: 'shops360',
      blockFeedback: true
    });
    const targetShop = allShops.find((item) => item.id === shopId);

    const parsedShop = parseShop({
      ...targetShop?.attributes,
      shopId,
      country: translateCountryToLocale({
        country: targetShop?.attributes?.country,
        lang: intl.locale
      })
    });

    setShop(parsedShop);
    actions.resetForm({ ...parsedShop, confirmEmail: parsedShop.email });

    const history = await genericRequest({
      url: `shops360/${shopId}/statuses`,
      blockFeedback: true
    });
    setShopHistory(history?.data);

    setIsLoading(false);
  };
  return (
    <Formik
      initialValues={parseShop(shop)}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        const info = { ...values };

        onUploadInfo(info, actions);
        return false;
      }}
      enableReinitialize
    >
      {children}
    </Formik>
  );
}

FormikContext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  shop: SHOP_PROPTYPES.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setShop: PropTypes.func.isRequired,
  setShopHistory: PropTypes.func.isRequired,
  disabledLogoAndFavicon: PropTypes.bool.isRequired
};
