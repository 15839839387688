import React from 'react';

import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { downloadFile } from 'app/utils/downloadFile';
import { LANGUAGE_AVAILABLE_CSV } from 'app/utils/constants';
import Icon from 'app/components/Icon';
import Button from 'app/components/Button';

export default function ImportManualCard() {
  const intl = useIntl();
  const domain = process.env.REACT_APP_BIGBUY_URL;
  const handleManual = () => {
    const language = intl.locale;

    const targetLanguage = LANGUAGE_AVAILABLE_CSV.includes(language)
      ? language
      : 'en';

    downloadFile({
      url: `${domain}/public/demo/ordercsv/csv_manual_${targetLanguage}.pdf`,
      nameFile: `manual-${language}.pdf`,
      target: '_blank'
    });
  };

  const handleExample = () => {
    downloadFile({
      url: `${domain}/public/demo/ordercsv/demo.csv`,
      nameFile: 'demo.csv'
    });
  };

  return (
    <div>
      <p className="order-by-csv__import-manual-card__text">
        {intl.formatMessage({ id: 'controlpanel.order_csv.text.part_a' })}
      </p>
      <p className="order-by-csv__import-manual-card__text">
        {intl.formatMessage({ id: 'controlpanel.order_csv.text.part_b' })}
      </p>
      <div className="order-by-csv__import-manual-card__buttons-container">
        <Button
          className="button-manual"
          text={intl.formatMessage({
            id: 'controlpanel.order_csv.text.button_manual'
          })}
          type="primary"
          size="medium"
          icon={<Icon icon="download" />}
          onClick={handleManual}
        />
        <Button
          className="button-example"
          text={intl.formatMessage({
            id: 'controlpanel.order_csv.text.button_example'
          })}
          type="default"
          size="medium"
          icon={<Icon icon="eye" color={Colors.bbSecondary} />}
          onClick={handleExample}
        />
      </div>
      <p className="order-by-csv__import-manual-card__text">
        {intl.formatMessage({ id: 'controlpanel.order_csv.text.part_c' })}
      </p>
      <p className="order-by-csv__import-manual-card__text medium">
        {intl.formatMessage({ id: 'controlpanel.order_csv.text.part_d' })}
      </p>
    </div>
  );
}
