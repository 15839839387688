import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import Notification from 'app/components/Notification/Notification';
import { MAP_STATUS_DEVOLUTION } from 'app/modules/Devolutions/utils/mapStatus';
import useRMAContext from '../../context/context';

export default function NotificationDevolution({ rmaStatus = 'DRAFT' }) {
  const intl = useIntl();

  const { rmaProductsMeta } = useRMAContext();

  const {
    alert: { variant, title, content }
  } = MAP_STATUS_DEVOLUTION[rmaStatus];

  let contentPrint = content;
  if (rmaStatus === 'PENDING_PAYMENT') {
    if (rmaProductsMeta?.rejectedUnits > 0) {
      contentPrint = intl.formatMessage({
        id: 'controlpanel.devolutions.payment_pending.line_rejected.text'
      });
    }
  }

  return (
    <Notification
      className="devolutions-add__card-notification"
      variant={variant}
      header={intl.formatMessage({
        id: title
      })}
      content={contentPrint}
      infoIcon={{ icon: 'info-square' }}
    />
  );
}

NotificationDevolution.propTypes = {
  rmaStatus: PropTypes.string
};
