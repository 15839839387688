/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Chip from 'app/components/Chip/Chip';
import CourseCardButton from 'app/components/CourseCard/components/CourseCardButton';
import CourseCardDialog from 'app/components/CourseCard/components/CourseCardDialog/CourseCardDialog';
import Discount from 'app/components/CourseCard/components/Discount';

import 'app/components/CourseCard/CourseCard.scss';

export default function CourseCard({
  bodyHeight = null,
  btnLink = null,
  description = null,
  descriptionLines = null,
  discount = null,
  link = null,
  linkText = null,
  serviceCheckout = false,
  state = 'default',
  tags = null,
  teacher = null,
  video = null,
  features,
  image,
  id,
  title
}) {
  const [disabled, setDisabled] = useState(false);
  const [purchased, setPurchased] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    switch (state) {
      case 'disabled':
        setDisabled(true);
        break;
      case 'purchased':
        setPurchased(true);
        break;
      default:
        break;
    }
  }, [state]);

  const bodyStyle = bodyHeight
    ? {
        height: `${bodyHeight}px`
      }
    : null;
  const descriptionStyle = descriptionLines
    ? {
        '--lineNumber': descriptionLines
      }
    : null;

  return (
    <>
      {!disabled && (
        <CourseCardDialog
          btnLink={btnLink}
          description={description}
          disabled={disabled}
          discount={discount}
          features={features}
          id={id}
          link={link}
          linktext={linkText}
          open={open}
          serviceCheckout={serviceCheckout}
          setOpen={setOpen}
          state={state}
          tags={tags}
          title={title}
          video={video}
          image={image}
        />
      )}
      <div className={`course-card course-card--${state}`}>
        {!disabled ? (
          <div
            className="course-card-header"
            onClick={() => setOpen(true)}
            onKeyUp={() => setOpen(true)}
            role="button"
            tabIndex={0}
            style={{ cursor: 'pointer' }}
          >
            {video && (
              <i className="card-course-header-play fa-duotone fa-swap-opacity fa-circle-play" />
            )}
            <img src={image} alt={title} />
          </div>
        ) : (
          <div className="course-card-header">
            <img src={image} alt={title} />
          </div>
        )}
        <div className="course-card-body" style={bodyStyle}>
          <div className="course-card-content-price">
            <div className="course-card-content-chips">
              <div className="course-card-title">
                <div className="course-card-name">{title}</div>
                {teacher && (
                  <p className="course-card-teacher small">
                    Teacher:
                    <strong>{teacher}</strong>
                  </p>
                )}
              </div>
              {description && (
                <p
                  className="course-card-description"
                  style={descriptionStyle}
                  dangerouslySetInnerHTML={{
                    __html: description
                  }}
                />
              )}
              {tags && (
                <div className="course-card-tags">
                  {tags.map((tag, index) => (
                    <Chip
                      key={index}
                      text={tag.name}
                      color={tag.color}
                      disabled={disabled}
                    />
                  ))}
                </div>
              )}
            </div>

            {discount && (
              <Discount
                originalPrice={discount.originalPrice}
                percentage={discount.percentage}
              />
            )}
          </div>
          <div className="course-card-cta">
            <CourseCardButton
              serviceCheckout={serviceCheckout}
              purchased={purchased}
              disabled={disabled}
              btnLink={btnLink}
              state={state}
              id={id}
            />
          </div>
        </div>
      </div>
    </>
  );
}

CourseCard.propTypes = {
  bodyHeight: PropTypes.number,
  btnLink: PropTypes.string,
  discount: PropTypes.shape({
    percentage: PropTypes.number,
    originalPrice: PropTypes.number
  }),
  description: PropTypes.string,
  descriptionLines: PropTypes.number,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  ).isRequired,
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkText: PropTypes.string,
  serviceCheckout: PropTypes.bool,
  state: PropTypes.oneOf(['purchased', 'default', 'disabled']),
  teacher: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string
    })
  ),
  title: PropTypes.string.isRequired,
  video: PropTypes.string
};
