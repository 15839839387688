import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';
import classNames from 'classnames';
import Collapse from 'react-bootstrap/Collapse';

import { Colors } from 'app/utils/colors';
import Icon from 'app/components/Icon';

import 'app/components/Faqs/styles.scss';

export default function Faqs({ faqs }) {
  return (
    <div className="bb-faqs">
      {map(faqs, (faq, key) => (
        <Faq key={key} title={faq?.title} content={faq?.content} />
      ))}
    </div>
  );
}

function Faq({ title, content, options = { initialOpen: false } }) {
  const { initialOpen } = options;
  const [open, setOpen] = useState(initialOpen);
  const handleClick = () => setOpen(!open);

  return (
    <div className="faq">
      <div
        className="faq__title"
        role="button"
        tabIndex={0}
        aria-hidden="true"
        onClick={handleClick}
      >
        <div>{title}</div>
        <div>
          <Icon
            className={classNames('bb-card__container__header__collapsable', {
              open
            })}
            icon="chevron-double-left"
            color={Colors.bbSecondary}
            aria-expanded={open}
            aria-controls="collapse-card"
          />
        </div>
      </div>
      <Collapse in={open}>
        <div id="collapse-card" className="faq__content">
          {content}
        </div>
      </Collapse>
    </div>
  );
}

const faqPt = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  options: PropTypes.shape({
    initialOpen: PropTypes.bool
  })
};

Faq.propTypes = faqPt;

Faqs.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.shape(faqPt)).isRequired
};
