import React, { useMemo } from 'react';

import { Formik } from 'formik';
import { size } from 'lodash';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { RMAProvider } from 'app/modules/Devolutions/context/context';
import Add from 'app/modules/Devolutions/pages/Add/Add';

export default function AddPage() {
  const intl = useIntl();
  const initialValues = useMemo(
    () => ({
      orderNumber: '',
      idProduct: { value: '', label: '' },
      description: '',
      units: 0
    }),
    []
  );
  const validationSchema = useMemo(
    () =>
      Yup.object({
        orderNumber: Yup.string()
          .min(
            3,
            intl.formatMessage(
              { id: 'controlpanel.form.validation.min_length' },
              { length: 3 }
            )
          )
          .required(
            intl.formatMessage({ id: 'controlpanel.form.validation.required' })
          ),
        description: Yup.string().required(
          intl.formatMessage({ id: 'controlpanel.form.validation.required' })
        ),
        idProduct: Yup.string().when(['orderNumber'], (orderNumber, schema) => {
          if (size(orderNumber) === 8) {
            return schema.required(
              intl.formatMessage({
                id: 'controlpanel.form.validation.required'
              })
            );
          }
          return schema.nullable();
        }),
        units: Yup.string().when(['idProduct'], (idProduct, schema) => {
          if (size(idProduct) > 0) {
            return schema.required(
              intl.formatMessage({
                id: 'controlpanel.form.validation.required'
              })
            );
          }
          return schema.nullable();
        })
      }),
    [intl]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {() => (
        <RMAProvider>
          <Add />
        </RMAProvider>
      )}
    </Formik>
  );
}
