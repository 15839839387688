import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Field } from 'formik';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';

import Label from 'app/components/Label';
import InputPrepend from 'app/components/Input/components/InputPrepend';
import InputAppend from 'app/components/Input/components/InputAppend';
import { getInputClasses } from 'app/components/Input/input.utils';
import { inputsType } from 'app/components/Input/Input.constants';

import 'app/components/Input/Input.scss';

export default function Input({
  disabled = false,
  type = 'text',
  label = '',
  tooltipText = null,
  textAppend = null,
  textPrepend = null,
  bottomComponent = null,
  loading = false,
  name,
  placeholder
}) {
  const [isFocus, setIsFocus] = useState(false);
  const [usedInputType, setInputType] = useState(type);
  const isTypePassword = useMemo(() => type === inputsType.password, [type]);
  const isHidingText = useMemo(
    () => usedInputType === inputsType.password,
    [usedInputType]
  );

  return (
    <Field name={name}>
      {({ field, meta: { error, touched } }) => {
        let fieldProps = field;
        if (disabled) {
          fieldProps = {};
        }
        return (
          <Form.Group className="bb-form-group form-group fv-plugins-icon-container">
            {label && (
              <Label label={label} tooltipText={tooltipText} htmlFor={name} />
            )}
            <div className="input-group">
              {textPrepend && (
                <InputPrepend
                  disabled={disabled}
                  textPrepend={textPrepend}
                  error={error}
                  touched={touched}
                />
              )}
              <input
                {...fieldProps}
                id={name}
                autoComplete="off"
                placeholder={placeholder}
                type={usedInputType}
                disabled={disabled}
                className={classNames(
                  'form-control',
                  getInputClasses(error, touched),
                  {
                    'no-border-rigth': textAppend,
                    'no-border-left': textPrepend
                  },
                  {
                    loading
                  }
                )}
                onFocus={() => setIsFocus(true)}
              />
              {(textAppend || isTypePassword) && (
                <InputAppend
                  disabled={disabled}
                  textAppend={textAppend}
                  error={error}
                  touched={touched}
                  isFocus={isFocus}
                  isTypePassword={isTypePassword}
                  isHidingText={isHidingText}
                  setInputType={setInputType}
                />
              )}
            </div>
            {touched && error ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{error}</div>
              </div>
            ) : (
              bottomComponent
            )}
          </Form.Group>
        );
      }}
    </Field>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  textAppend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textPrepend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  bottomComponent: PropTypes.element,
  loading: PropTypes.bool
};
