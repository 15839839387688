import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';
import Button from 'app/components/Button';
import Checkbox from 'app/components/Checkbox';
import Divider from 'app/components/Divider';
import Input from 'app/components/Input';
import WholesalerPackCancelPackModal from 'app/modules/MyAccount/pages/WholesalerPack/components/WholesalerPackCancelPackModal';
import WholesalerPackTag from 'app/modules/MyAccount/pages/WholesalerPack/components/WholesalerPackTag';

import 'app/modules/MyAccount/pages/WholesalerPack/components/WholesalerPackForm.scss';

export default function WholesalerPackForm({
  actualPackName,
  actualPackID,
  setIsLoading
}) {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const intl = useIntl();

  const {
    user: {
      attributes: { packRenovation, packExpirationDate }
    }
  } = useSelector((state) => state.auth);

  const handleCloseCancelModal = () => setShowCancelModal(false);
  const handleShowCancelModal = () => setShowCancelModal(true);

  const handleSubscription = () => {
    window.location.href = `${process.env.REACT_APP_BIGBUY_URL}subscription/checkout`;
  };

  return (
    <>
      <div className="bb-wholesaler-pack-form">
        <Row>
          <Col xs={{ span: 12, order: 2 }} md={{ span: 3, order: 1 }}>
            <p className="bb-wholesaler-pack-form__p-date">
              {intl.formatMessage({
                id: 'controlpanel.my_account.pack.form.pack_active'
              })}
            </p>
          </Col>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 9, order: 2 }}>
            <WholesalerPackTag id={actualPackID} name={actualPackName} />
          </Col>
        </Row>
        <Row>
          {packExpirationDate && (
            <Col md={{ span: 9, offset: 3 }}>
              <Input
                id="expirationDate"
                name="expirationDate"
                placeholder={packExpirationDate}
                disabled
                bottomComponent={
                  <p className="bb-wholesaler-pack-form__label">
                    {packRenovation
                      ? intl.formatMessage({
                          id: 'controlpanel.my_account.pack.form.next_renewal'
                        })
                      : intl.formatMessage({
                          id: 'controlpanel.pack_info_card.date_of_expiry'
                        })}
                  </p>
                }
              />
            </Col>
          )}
        </Row>

        <Divider type="dash" />
        <Row>
          <Col md={3}>
            <p className="bb-wholesaler-pack-form__p-paypal">
              {intl.formatMessage({
                id: 'controlpanel.my_account.pack.form.incidence'
              })}
            </p>
          </Col>
          <Col md={9}>
            <Checkbox
              id="isActivatePaymentByWallet"
              name="isActivatePaymentByWallet"
              type="checkbox"
              label={intl.formatMessage({
                id: 'controlpanel.my_account.pack.form.active_wallet'
              })}
            />
          </Col>
        </Row>
        {packRenovation && (
          <>
            <Divider type="dash" />
            <Row>
              <Col md={{ span: 9, offset: 3 }}>
                <div className="bb-wholesaler-pack-form__cancel-button">
                  <Button
                    text={intl.formatMessage({
                      id: 'controlpanel.my_account.pack.form.cancel_renewal'
                    })}
                    type="default"
                    size="big"
                    onClick={handleShowCancelModal}
                  />
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        {intl.formatMessage({
                          id: 'controlpanel.my_account.pack.form.cancel_renewal_tooltip'
                        })}
                      </Tooltip>
                    }
                  >
                    <SVG
                      src={toAbsoluteUrl('/media/svg/bigbuy/info-square.svg')}
                    />
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
          </>
        )}

        {!packRenovation && (
          <>
            <Divider type="dash" />
            <Row>
              <Col md={{ span: 9, offset: 3 }}>
                <div className="bb-wholesaler-pack-form__renovation-button">
                  <Button
                    text={intl.formatMessage({
                      id: 'controlpanel.my_account.pack.form.subscription_renewal'
                    })}
                    type="primary"
                    size="big"
                    onClick={handleSubscription}
                  />
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        {intl.formatMessage({
                          id: 'controlpanel.my_account.pack.form.subscription_renewal'
                        })}
                      </Tooltip>
                    }
                  >
                    <SVG
                      src={toAbsoluteUrl('/media/svg/bigbuy/info-square.svg')}
                    />
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
      <WholesalerPackCancelPackModal
        show={showCancelModal}
        handleClose={handleCloseCancelModal}
        setIsLoading={setIsLoading}
      />
    </>
  );
}

WholesalerPackForm.propTypes = {
  actualPackName: PropTypes.string.isRequired,
  actualPackID: PropTypes.number.isRequired,
  setIsLoading: PropTypes.func.isRequired
};
