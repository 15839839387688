import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { thunk } from 'redux-thunk';
import rootReducer from 'app/config/redux/rootReducer';

const initialState = {};

const middlewares = [thunk];

const enhancers = [applyMiddleware(...middlewares)];

const store = createStore(
  rootReducer(),
  initialState,
  composeWithDevTools(...enhancers)
);

export default store;
