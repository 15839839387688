import { map } from 'lodash';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export default function usePaymentMethods() {
  const intl = useIntl();

  return {
    BANKWIRE: {
      translation: intl.formatMessage({ id: 'controlpanel.global.bankwire' }),
      csvOrders: true
    },
    CREDIT_CARD: {
      translation: intl.formatMessage({
        id: 'controlpanel.global.credit_card'
      }),
      csvOrders: false
    },
    CREDIT: {
      translation: intl.formatMessage({ id: 'controlpanel.global.on_credit' }),
      csvOrders: true
    },
    WALLET: {
      translation: intl.formatMessage({ id: 'controlpanel.global.wallet' }),
      csvOrders: true
    },
    PAYCOMET: {
      translation: intl.formatMessage({ id: 'controlpanel.global.paycomet' }),
      csvOrders: false
    },
    PAYPAL: {
      translation: intl.formatMessage({ id: 'controlpanel.global.paypal' }),
      csvOrders: true
    },
    OTHER: {
      translation: intl.formatMessage({ id: 'controlpanel.global.other' }),
      csvOrders: false
    },
    PAYPAL_REVIEW: {
      translation: intl.formatMessage({
        id: 'controlpanel.global.paypal_review'
      }),
      csvOrders: false
    }
  };
}

export const GetActivePaymenthMethods = () => {
  const allPaymentMethods = usePaymentMethods();
  const activePaymentMethods = {};
  const {
    user: {
      attributes: { paymentMethods }
    }
  } = useSelector((state) => state.auth);

  map(paymentMethods, (paymentMethod) => {
    if (allPaymentMethods[paymentMethod.key]) {
      activePaymentMethods[paymentMethod.key] =
        allPaymentMethods[paymentMethod.key];
    }
  });

  return activePaymentMethods;
};

export const GetSelectStructure = () => {
  const paymentMethods = GetActivePaymenthMethods();
  const selectStructure = [];

  map(paymentMethods, (data, paymentMethod) => {
    selectStructure.push({
      value: paymentMethod,
      label: data.translation
    });
  });

  return selectStructure;
};
