import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import Button from 'app/components/Button';
import Popup from 'app/components/Popup';
import Spinner from 'app/components/Spinner';
import usePaymentMethodsContext from 'app/modules/MyAccount/pages/PaymentMethods/context/PaymentMethodsContext';

import './PopupDeleteCard.scss';

export default function PopupDelete({ show, setShow }) {
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const { handleDeletePaycomet } = usePaymentMethodsContext();

  const handleClose = () => {
    setShow(false);
    setDisabled(false);
  };

  return (
    <Popup
      show={show}
      header={
        <div className="bb-popup-delete-card__header">
          <p className="bold">
            {intl.formatMessage({
              id: 'controlpanel.payment_methods.delete_card.title'
            })}
          </p>
        </div>
      }
      body={
        <div className="bb-popup-delete-card__body">
          <p>
            {intl.formatMessage({
              id: 'controlpanel.payment_methods.delete_card.text'
            })}
          </p>
        </div>
      }
      footer={
        <div className="bb-popup-delete-card__footer">
          <Button
            text={intl.formatMessage({
              id: 'controlpanel.payment_methods.delete_card.cancel'
            })}
            type="default"
            size="big"
            onClick={handleClose}
          />
          <Button
            text={intl.formatMessage({
              id: 'controlpanel.payment_methods.delete_card.confirm'
            })}
            type="primary"
            size="big"
            disabled={disabled}
            icon={loading === false ? null : <Spinner />}
            onClick={async () => {
              setLoading(true);
              handleDeletePaycomet();
            }}
          />
        </div>
      }
      handleClose={handleClose}
    />
  );
}

PopupDelete.propTypes = {
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};
