import React from 'react';

import { useIntl } from 'react-intl';

export default function DraftList() {
  const intl = useIntl();

  return (
    <ul>
      <li>
        <p
          className="small"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'controlpanel.devolutions.alert.draft.text1'
            })
          }}
        />
      </li>
      <li>
        <p className="small">
          {intl.formatMessage({
            id: 'controlpanel.devolutions.alert.draft.text2'
          })}
        </p>
      </li>
    </ul>
  );
}
