import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { logout } from 'app/modules/Auth/_redux/AuthActions';

export default function Logout() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
    window.location.href = process.env.REACT_APP_BIGBUY_URL;
  }, [dispatch]);

  return <div />;
}
