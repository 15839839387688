import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import Popup from 'app/components/Popup';
import Spinner from 'app/components/Spinner';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';
import { genericRequest } from 'app/api';
import { filter } from 'lodash';

export default function PopupDeleteWishlist({ show, setShow }) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const intl = useIntl();
  const {
    context: { readContextStorage }
  } = useDataTableContext();
  const { wishlistId, tabs, setTabs, selectedTab, setSelectedTab } =
    readContextStorage('wishlistData');

  if (typeof tabs === 'undefined' || typeof selectedTab === 'undefined')
    return null;

  const handleClose = () => {
    setShow(false);
    setDisabled(false);
  };

  return (
    <Popup
      show={show}
      header={
        <div className="bb-popup-repeat-order__header">
          <p className="bold">
            {intl.formatMessage(
              {
                id: 'controlpanel.wishlist.delete_wishlist.title'
              },
              {
                name: tabs[selectedTab]?.name
              }
            )}
          </p>
        </div>
      }
      body={
        <div className="bb-popup-repeat-order__body">
          <p>
            {intl.formatMessage({
              id: 'controlpanel.wishlist.delete_wishlist.text'
            })}
          </p>
        </div>
      }
      footer={
        <div className="bb-popup-repeat-order__footer">
          <Button
            text={intl.formatMessage({
              id: 'controlpanel.global.close'
            })}
            type="default"
            size="big"
            onClick={handleClose}
          />
          <Button
            text={intl.formatMessage({ id: 'controlpanel.global.delete' })}
            type="primary"
            size="big"
            disabled={disabled}
            icon={
              loading === false ? (
                <Icon icon="trash" color={Colors.bbGrey2} />
              ) : (
                <Spinner />
              )
            }
            onClick={async () => {
              try {
                setLoading(true);
                await genericRequest({
                  url: `wishlists/${wishlistId}`,
                  method: 'DELETE'
                });

                const newTabs = filter(tabs, (tab) => tab?.id !== wishlistId);
                setTabs(newTabs);
                setSelectedTab(0);
                handleClose();
                setLoading(false);
              } catch {
                handleClose();
              }
            }}
          />
        </div>
      }
      handleClose={handleClose}
    />
  );
}

PopupDeleteWishlist.propTypes = {
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};
