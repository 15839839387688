import React from 'react';

import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { DataTableComponentsProvider } from 'app/components/DataTableComponents/context/DataTableComponentsContext';
import { DataTableProvider } from 'app/components/DataTable/context/DataTableContext';
import { defaultOptions } from 'app/components/DataTableComponents/utils/defaultConfig';
import { getProductsManualsDataTableConfiguration } from 'app/modules/Downloads/Pages/ProductsManuals/config/config';
import { URLS } from 'app/config/urls';
import BannersNewsletters from 'app/modules/Downloads/Pages/BannersNewsletters';
import Card from 'app/components/Card/Card';
import CsvXmlFiles from 'app/modules/Downloads/Pages/CsvXmlFiles';
import MenuLayout from 'app/components/MenuLayout';
import ProductsManuals from 'app/modules/Downloads/Pages/ProductsManuals';
import ResourcesMKT from 'app/modules/Downloads/Pages/ResourcesMKT';
import SocialSellingResources from 'app/modules/Downloads/Pages/SocialSellingResources';
import useBreadcrumbs from 'app/modules/Downloads/hooks/useBreadcrumbs.hook';

export default function Downloads() {
  const { section } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  useBreadcrumbs();

  return (
    <MenuLayout
      items={[
        {
          title: intl.formatMessage({
            id: 'controlpanel.downloads.mkt_resources'
          }),
          icon: 'download',
          children: (
            <DataTableComponentsProvider
              config={{
                api: { url: 'marketing-media', ...defaultOptions }
              }}
            >
              <ResourcesMKT key="mkt_resources" />
            </DataTableComponentsProvider>
          ),
          active: section === 'marketing-resources',
          onClick: () => navigate(URLS.downloadsMKT)
        },
        {
          title: intl.formatMessage({
            id: 'controlpanel.downloads.social_selling_resources'
          }),
          icon: 'photo-video',
          children: (
            <DataTableComponentsProvider
              config={{
                api: { url: 'social-selling', ...defaultOptions }
              }}
            >
              <SocialSellingResources key="social_selling_resources" />
            </DataTableComponentsProvider>
          ),
          active: section === 'social-selling-resources',
          onClick: () => navigate(URLS.downloadsSocialSelling),
          extraInfo: (
            <Card
              extraClass="social-selling-resources__card-info"
              icon="info-square"
              title={intl.formatMessage({
                id: 'controlpanel.social_selling_resources.info_title'
              })}
              body={
                <div className="text-container">
                  <p>
                    {intl.formatMessage({
                      id: 'controlpanel.social_selling_resources.info_text_1'
                    })}
                  </p>
                  <p>
                    {intl.formatMessage({
                      id: 'controlpanel.social_selling_resources.info_text_2'
                    })}
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: intl
                        .formatMessage({
                          id: 'controlpanel.social_selling_resources.info_text_3'
                        })
                        .replace('{link}', 'https://bigbuy.eu')
                    }}
                  />
                </div>
              }
            />
          )
        },
        {
          title: intl.formatMessage({
            id: 'controlpanel.downloads.banners_newsletters'
          }),
          icon: 'envelope-open-text',
          children: (
            <DataTableComponentsProvider
              config={{
                api: { url: 'newsletter-banners', ...defaultOptions }
              }}
            >
              <BannersNewsletters key="banners_newsletters" />
            </DataTableComponentsProvider>
          ),
          active: section === 'banners-newsletters',
          onClick: () => navigate(URLS.downloadsBanners)
        },
        {
          title: intl.formatMessage({
            id: 'controlpanel.downloads.product_manuals'
          }),
          icon: 'tags',
          children: (
            <DataTableProvider
              getTableContextConfiguration={
                getProductsManualsDataTableConfiguration
              }
            >
              <ProductsManuals />
            </DataTableProvider>
          ),
          active: section === 'products-manuals',
          onClick: () => navigate(URLS.downloadsManuals)
        },
        {
          title: intl.formatMessage({
            id: 'controlpanel.downloads.csv_xml_files'
          }),
          icon: 'file-csv',
          children: <CsvXmlFiles />,
          active: section === 'csv-xml-files',
          onClick: () => navigate(URLS.downloadsCSV)
        }
      ]}
    />
  );
}
