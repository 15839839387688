/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import 'app/components/Switch/Switch.scss';

export default function Switch({
  disabled = false,
  labelComponent = null,
  formik = null,
  id,
  name,
  checked,
  onChange
}) {
  const [active, setActive] = useState(checked);

  const onChangeStatus = () => {
    const status = !active;
    setActive(status);
    onChange({ id, checked: status });
  };

  useEffect(() => {
    setActive(checked);
  }, [checked]);

  return (
    <div className="bb-switch">
      <div className="bb-switch__container">
        <label
          className="bb-switch__container__label"
          htmlFor={id}
          aria-label="Checkbox label"
        >
          <input
            id={id}
            name={name}
            type="checkbox"
            checked={active}
            disabled={disabled}
            className="bb-switch__container__label__input"
            onChange={onChangeStatus}
          />
          <span className="bb-switch__container__label__circle" />
        </label>
        <div className="bb-switch__container__text">{labelComponent}</div>
      </div>
      {formik && formik.touched[name] && formik.errors[name] && (
        <div className="bb-switch__error fv-plugins-message-container">
          <div className="fv-help-block">{formik.errors[name]}</div>
        </div>
      )}
    </div>
  );
}

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  labelComponent: PropTypes.element,
  onChange: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  })
};
