import React from 'react';
import { AsideMenuList } from 'app/components/Aside/aside-menu/AsideMenuList';

export function AsideMenu() {
  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className="aside-menu my-4 scroll ps"
        data-menu-dropdown-timeout={500}
        data-menu-scroll="1"
      >
        <AsideMenuList />
      </div>
      {/* end::Menu Container */}
    </>
  );
}
