import * as Yup from 'yup';

export const getSchemaValidation = (intl) =>
  Yup.object().shape({
    contactPerson: Yup.string().required(
      intl.formatMessage({
        id: 'controlpanel.form.validation.required'
      })
    ),
    companyName: Yup.string().required(
      intl.formatMessage({
        id: 'controlpanel.form.validation.required'
      })
    ),
    address: Yup.string().required(
      intl.formatMessage({
        id: 'controlpanel.form.validation.required'
      })
    ),
    country: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'controlpanel.form.validation.required'
        })
      ),
    city: Yup.string().required(
      intl.formatMessage({
        id: 'controlpanel.form.validation.required'
      })
    ),
    zipCode: Yup.string().required(
      intl.formatMessage({
        id: 'controlpanel.form.validation.required'
      })
    ),
    phone: Yup.string().required(
      intl.formatMessage({
        id: 'controlpanel.form.validation.required'
      })
    ),
    packageType: Yup.string().required(
      intl.formatMessage({
        id: 'controlpanel.form.validation.required'
      })
    ),
    quantity: Yup.number()
      .min(
        1,
        intl.formatMessage({
          id: 'controlpanel.form.validation.required'
        })
      )
      .required(
        intl.formatMessage({
          id: 'controlpanel.form.validation.required'
        })
      ),
    packages: Yup.array().of(
      Yup.object().shape({
        height: Yup.number().required(
          intl.formatMessage({
            id: 'controlpanel.form.validation.required'
          })
        ),
        width: Yup.number().required(
          intl.formatMessage({
            id: 'controlpanel.form.validation.required'
          })
        ),
        depth: Yup.number().required(
          intl.formatMessage({
            id: 'controlpanel.form.validation.required'
          })
        ),
        weight: Yup.number().required(
          intl.formatMessage({
            id: 'controlpanel.form.validation.required'
          })
        )
      })
    ),
    comments: Yup.string()
  });
