import React, { useState } from 'react';

import { Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { genericRequest } from 'app/api';
import { URLS } from 'app/config/urls';
import {
  INITIAL_VALUES,
  getValidationSchema
} from 'app/modules/MyAccount/pages/Password/utils';
import Button from 'app/components/Button';
import ChangePasswordForm from 'app/modules/MyAccount/pages/Password/components/ChangePasswordForm';
import FormCard from 'app/components/FormCard';
import FormSpinner from 'app/components/FormSpinner';
import MyAccountLayout from 'app/modules/MyAccount/components/MyAccountLayout';

import 'app/modules/MyAccount/pages/Password/Password.scss';

export default function Password() {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const {
    user: { id }
  } = useSelector((state) => state.auth);

  const handleSubmit = async (values) => {
    const { oldPassword, newPassword } = values;
    const idUser = id;
    await genericRequest({
      url: `customers/${idUser}/actions`,
      method: 'POST',
      data: {
        data: {
          type: 'customer-action',
          attributes: {
            type: 'password-change',
            oldPassword,
            newPassword
          }
        }
      },
      setLoading,
      customSuccessFeedback: {
        variant: 'success',
        content: intl.formatMessage({
          id: 'controlpanel.feedback.save_changes.success'
        }),
        autoClose: true,
        dismissible: false
      }
    });
  };

  if (loading) {
    return (
      <MyAccountLayout
        currentPagePath={URLS.myAccountPassword}
        className="bb-change-password-page"
      >
        <Col md={8}>
          <FormSpinner
            title="controlpanel.my_account.password.title"
            showButtons
          />
        </Col>
      </MyAccountLayout>
    );
  }

  return (
    <MyAccountLayout
      currentPagePath={URLS.myAccountPassword}
      className="bb-change-password-page"
    >
      <Col md={8}>
        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={getValidationSchema(intl)}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <FormCard
              title={intl.formatMessage({
                id: 'controlpanel.my_account.password.title'
              })}
              buttons={
                <>
                  <Button
                    size="big"
                    text={intl.formatMessage({
                      id: 'controlpanel.global.cancel'
                    })}
                    onClick={formik.resetForm}
                  />
                  <Button
                    size="big"
                    type="secondary"
                    text={intl.formatMessage({
                      id: 'controlpanel.global.save'
                    })}
                    onClick={formik.handleSubmit}
                    disabled={!(formik.isValid && formik.dirty)}
                  />
                </>
              }
            >
              <ChangePasswordForm />
            </FormCard>
          )}
        </Formik>
      </Col>
    </MyAccountLayout>
  );
}
