import { genericRequest } from 'app/api/genericRequest';
import { map } from 'lodash';

/**
 * @returns Array of all filters values
 */
export const getFilters = async (lang) => {
  try {
    const request = await genericRequest({
      url: `general-services/filters?lang=${lang}`,
      method: 'GET'
    });

    return request;
  } catch (error) {
    return error;
  }
};

/**
 * @param {Array[String]} fieldNames - (catalogue, theme ...)
 * @returns Array of filters values filtered by fieldNames
 */
export const getFiltersByFieldName = async (fieldNames, lang) => {
  const filters = await getFilters(lang);

  const filteredResults = [];
  map(filters?.data, (data) => {
    map(data?.attributes?.enumerations, (enumerations) => {
      if (fieldNames.includes(enumerations?.fieldName)) {
        filteredResults.push(enumerations);
      }
    });
  });

  return filteredResults;
};
