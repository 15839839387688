import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import Icon from 'app/components/Icon';
import Button from 'app/components/Button';
import { useWindow } from 'app/hooks/useWindow.hook';
import { useText } from 'app/hooks/useText';
import { SIZE } from 'app/utils/sizes';
import { getEcommerceLogo } from 'app/components/DropshippingShopCard/utils/getEcommerceLogo';

import 'app/components/DropshippingShopBanner/DropshippingShopBanner.scss';

export const DropshippingShopBanner = ({ hasShops = false }) => {
  const intl = useIntl();
  const { width } = useWindow();
  const mobile = width < SIZE.MD;
  const noShopsClass = useMemo(() => (!hasShops ? 'no-shops' : ''), [hasShops]);
  const title = intl.formatMessage({
    id: 'controlpanel.dropshipping_shop_banner.title'
  });
  const titleNoShops = intl.formatMessage({
    id: 'controlpanel.dropshipping_shop_card.title_no_shop'
  });
  const text = intl.formatMessage({
    id: 'controlpanel.dropshipping_shop_banner.text'
  });
  const textNoShops = intl.formatMessage({
    id: 'controlpanel.dropshipping_shop_banner.text_no_shop'
  });
  const ctaText = intl.formatMessage({
    id: 'controlpanel.dropshipping_shop_card.cta'
  });
  const ctaTextNoShops = intl.formatMessage({
    id: 'controlpanel.dropshipping_shop_card.cta_no_shop'
  });
  const bannerTitle = useText(hasShops, title, titleNoShops);
  const bannerText = useText(hasShops, text, textNoShops);
  const cta = useText(hasShops, ctaText, ctaTextNoShops);

  const onClickButton = () => {
    window.location.href = `${process.env.REACT_APP_BIGBUY_URL}subscription/checkout`;
  };

  return (
    <div className={`dropshipping-shop-banner ${noShopsClass}`}>
      <Row>
        <Col md={12} className="center dropshipping-shop-banner-logo">
          <Icon icon="store" color="white" />
        </Col>
      </Row>
      <div className="dropshipping-shop-banner-content">
        <Row>
          <Col
            md={12}
            className={`dropshipping-shop-banner-title ${noShopsClass}`}
          >
            <h4 className="bold">{bannerTitle}</h4>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="center dropshipping-shop-banner-description">
            <p className="regular">{bannerText}</p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="center dropshipping-shop-banner-button">
            <Button
              type="primary"
              size="big"
              text={cta}
              icon={<Icon icon="shopping-cart" />}
              onClick={onClickButton}
              className={
                hasShops && mobile
                  ? 'dropshipping-shop-banner-button--fullwidth'
                  : ''
              }
            />
          </Col>
        </Row>
      </div>
      {(hasShops && mobile) || (
        <Row>
          <Col
            md={12}
            className="center dropshipping-shop-banner-ecomerce-logos"
          >
            {getEcommerceLogo('shopify')}
          </Col>
        </Row>
      )}
    </div>
  );
};

DropshippingShopBanner.propTypes = {
  hasShops: PropTypes.bool
};
