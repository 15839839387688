export const parserShops = (data) => {
  const shops = [];
  try {
    data?.forEach((d) => {
      const { type, attributes } = d;
      const { domain, theme, catalogue, logo } = attributes;
      const shop = {
        type,
        url: domain,
        theme,
        catalog: catalogue,
        logo
      };
      shops.push(shop);
    });
    return shops;
  } catch (error) {
    return null;
  }
};
