import React from 'react';

import { Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { getRowToCards } from 'app/modules/OrderView/utils';
import useDataProviderContext from 'app/hooks/useDataProviderContext';

export default function ShippingInfoCard() {
  const intl = useIntl();
  const { order } = useDataProviderContext();

  const {
    addressee,
    carrier,
    isDropshipping,
    shippingAddress,
    shippingCity,
    shippingCompany,
    shippingCountry,
    shippingPhone,
    shippingState,
    shippingPostalCode
  } = order.attributes;

  return (
    <Row>
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.company' }),
        shippingCompany
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.name' }),
        addressee
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.address' }),
        shippingAddress
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.country' }),
        shippingCountry
      )}
      {shippingState &&
        getRowToCards(
          intl.formatMessage({ id: 'controlpanel.global.province' }),
          shippingState
        )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.city' }),
        shippingCity
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.zipCode' }),
        shippingPostalCode
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.phone' }),
        shippingPhone
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.shipping_method' }),
        carrier
      )}
      {getRowToCards(
        intl.formatMessage({
          id: 'controlpanel.global.dropshipping_anonymous_shipping'
        }),
        isDropshipping
          ? intl.formatMessage({ id: 'controlpanel.global.yes' })
          : intl.formatMessage({ id: 'controlpanel.global.no' })
      )}
    </Row>
  );
}
