import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import getCroppedImg from 'app/components/CropImage/cropImage.functions';
import 'app/components/CropImage/CropImage.scss';

export default function CropImage({
  cropSize = { width: 125, height: 125 },
  minZoom = 1,
  maxZoom = 3,
  image,
  onComplete
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    const croppedImageUrl = await getCroppedImg(image, croppedAreaPixels);
    onComplete(croppedImageUrl);
  };

  return (
    <div className="crop-image">
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        aspect={1}
        cropSize={cropSize}
        onZoomChange={(newZoom) => setZoom(newZoom)}
        onCropChange={({ x, y }) => setCrop({ x, y })}
        onCropComplete={onCropComplete}
        minZoom={minZoom}
        maxZoom={maxZoom}
      />
    </div>
  );
}

CropImage.propTypes = {
  image: PropTypes.string.isRequired,
  cropSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  }),
  onComplete: PropTypes.func.isRequired,
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number
};
