import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Image } from 'react-bootstrap';
import classNames from 'classnames';

import { Colors } from 'app/utils/colors';
import Icon from 'app/components/Icon';
import UserInitials from 'app/components/UserInitials';

import 'app/components/ProfileCard/ProfileCard.scss';

const { bbSecondary, bbGrey3 } = Colors;

export default function ProfileCard({
  title,
  id,
  email,
  emailText,
  tag,
  routes,
  onRedirect,
  onClickedTag,
  image = '',
  currentPagePath = null
}) {
  const [active, setActive] = useState(currentPagePath);

  const onUpdateActive = (activeItem) => {
    setActive(activeItem);
    onRedirect(activeItem);
  };

  return (
    <div className="bb-profile-card">
      <div className="bb-profile-card__header">
        <div className="bb-profile-card__header__logo">
          {image ? (
            <Image src={image} />
          ) : (
            <UserInitials
              classes={{
                userInitials: 'user-initials',
                nameFirstCharacter:
                  'user-initials__name-letter font-weight-bold',
                surnameFirstCharacter:
                  'user-initials__surname-letter font-weight-bold'
              }}
            />
          )}
        </div>
        <div className="bb-profile-card__header__info">
          <span
            role="none"
            className="bb-profile-card__tag bb-profile-card__tag--regular"
            onClick={() => onClickedTag(tag.id)}
            onKeyDown={() => onClickedTag(tag.id)}
            style={{ backgroundColor: tag.color }}
          >
            <p className="medium">{tag.text}</p>
          </span>
          <p className="bb-profile-card__header__info__text big bold">
            {title}
          </p>
          <p className="bb-profile-card__header__info__text">{`ID: ${id}`}</p>
        </div>
      </div>
      <div className="bb-profile-card__subheader">
        <p className="bb-profile-card__subheader__title medium">{emailText}</p>
        <p className="bb-profile-card__subheader__email" title={email}>
          {email}
        </p>
      </div>
      <div className="bb-profile-card__routes">
        {routes.map((route, index) => (
          <div
            role="none"
            key={`${index}-${route.title}`}
            className={classNames('bb-profile-card__routes__card', {
              'bb-profile-card__routes__card--active': route.path === active
            })}
            onClick={() => onUpdateActive(route.path)}
            onKeyDown={() => onUpdateActive(route.path)}
          >
            <Icon
              icon={route.icon}
              size="lg"
              color={route.path === active ? bbSecondary : bbGrey3}
            />
            <p>{route.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const Route = PropTypes.shape({
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
});

ProfileCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  emailText: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(Route).isRequired,
  tag: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    color: PropTypes.string
  }).isRequired,
  image: PropTypes.string,
  onRedirect: PropTypes.func.isRequired,
  onClickedTag: PropTypes.func.isRequired,
  currentPagePath: PropTypes.string
};
