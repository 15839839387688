import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { clone, filter, findIndex, map } from 'lodash';
import classNames from 'classnames';

import Icon from 'app/components/Icon';
import { Colors } from 'app/utils/colors';

import 'app/components/MenuLayout/MenuLayout.scss';

export default function MenuLayout({
  items,
  config = {
    menu: {
      xs: 12,
      md: 4,
      lg: 4,
      xl: 4
    },
    layout: {
      xs: 12,
      md: 8,
      lg: 8,
      xl: 8
    }
  }
}) {
  const [menu, setMenu] = useState(items);

  return (
    <Row className="menu-layout">
      <Col {...config.menu}>
        <div className="menu-layout__container">
          {map(menu, (item, key) => (
            <div
              className={classNames('item', { active: item?.active === true })}
              key={key}
              tabIndex={0}
              role="button"
              onClick={() => {
                setActive(key, menu, setMenu);
                if (item?.onClick) {
                  item?.onClick();
                }
              }}
              onKeyDown={() => setActive(key, menu, setMenu)}
            >
              <Icon
                icon={item?.icon}
                color={
                  item?.active === true ? Colors.bbSecondary : Colors.bbGrey3
                }
              />
              <p>{item?.title}</p>
            </div>
          ))}
        </div>
        {getExtraInfo(menu)}
      </Col>
      <Col {...config.layout}>{getChildren(menu)}</Col>
    </Row>
  );
}

const getExtraInfo = (menu) => {
  const arrayFiltered = filter(menu, (obj) => obj?.active === true);

  return arrayFiltered[0]?.extraInfo;
};

const getChildren = (menu) => {
  const arrayFiltered = filter(menu, (obj) => obj?.active === true);

  return arrayFiltered[0]?.children;
};

const setActive = (key, menu, setMenu) => {
  const newMenu = clone(menu);
  const activeKey = findIndex(menu, (obj) => obj?.active === true);

  newMenu[activeKey].active = false;
  newMenu[key].active = true;

  setMenu(newMenu);
};

MenuLayout.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      children: PropTypes.node,
      active: PropTypes.bool,
      extraInfo: PropTypes.node
    })
  ).isRequired,
  config: PropTypes.shape({
    menu: PropTypes.shape({
      xs: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
      xl: PropTypes.number
    }),
    layout: PropTypes.shape({
      xs: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
      xl: PropTypes.number
    })
  })
};
