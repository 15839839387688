export const classifyFetchedPaymentMethods = (fetchedResults) => {
  const creditCards = [];
  const paypal = [];
  const paycomet = [];

  fetchedResults.forEach(({ id, attributes }) => {
    switch (attributes.paymentPlatform) {
      case 'PAYPAL':
        paypal.push({ id, ...attributes });
        break;
      case 'PAYCOMET':
        paycomet.push({ id, ...attributes });
        break;
      default:
        creditCards.push({ id, ...attributes });
    }
  });

  // MOCK. UNCOMMENT IF YOU NEED TEST DATA
  // return {
  //   creditCards: [
  //     {
  //       id: 'XZQ3FP4NMV53HX42',
  //       type: 'scheme',
  //       name: 'MasterCard',
  //       urlImage: 'https://www.bigbuy.eu/public/img/logos/default/mc.svg',
  //       brand: 'mc',
  //       expiryMonth: '04',
  //       expiryYear: '26',
  //       lastFour: '2679',
  //       iban: null,
  //       ownerName: null,
  //       paymentPlatform: 'ADYEN'
  //     }
  //   ],
  //   paycomet: [
  //     {
  //       id: '443671',
  //       type: 'scheme',
  //       name: 'CREDIT',
  //       urlImage:
  //         'https://www.bigbuy.eu/public/img/logos/default/paycomet_cards.svg',
  //       brand: 'VISA',
  //       expiryMonth: '2024/06',
  //       expiryYear: null,
  //       lastFour: '456355-XX-XXXX-4304',
  //       iban: null,
  //       ownerName: 'HYKS  ',
  //       paymentPlatform: 'PAYCOMET'
  //     }
  //   ],
  //   paypal: [
  //     {
  //       id: 'B-0MH5736837991592S',
  //       type: 'paypal',
  //       name: 'PAYPAL',
  //       urlImage: 'https://www.bigbuy.eu/public/img/logos/default/paypal.svg',
  //       brand: null,
  //       expiryMonth: null,
  //       expiryYear: null,
  //       lastFour: null,
  //       iban: null,
  //       ownerName: 'hyks-webshop@outlook.com',
  //       paymentPlatform: 'PAYPAL'
  //     }
  //   ]
  // };
  return { creditCards, paypal, paycomet };
};
