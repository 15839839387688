export const recalculatePgOptionsOnDeleteItems = (
  paginationOptions,
  countDeletedItems = 1
) => {
  const { page, totalSize, sizePerPage } = paginationOptions;

  let newPage = page;
  const newTotalSize = totalSize - countDeletedItems;
  const newMaxPage = Math.ceil(newTotalSize / sizePerPage);

  if (page > newMaxPage) {
    newPage = newMaxPage;
  }

  if (newPage <= 0) {
    newPage = 1;
  }

  return {
    ...paginationOptions,
    totalSize: newTotalSize,
    page: newPage
  };
};
