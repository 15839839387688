import React from 'react';

import { sortCaret, headerSortingStyle } from 'app/components/DataTable/utils';
import {
  FormatAmount,
  FormatConcept
} from 'app/modules/Purse/utils/formatters';
import { DateFormatter } from 'app/utils/columnsFormatters/DateFormatter';

export const getColumnsToDataTable = (intl) => [
  {
    dataField: 'id',
    hiddenToUser: true,
    hidden: true,
    sort: false,
    draggable: false,
    text: 'id'
  },
  {
    dataField: 'purseId',
    hiddenToUser: true,
    hidden: true,
    sort: false,
    draggable: true,
    text: 'id'
  },
  {
    dataField: 'orderNumber',
    text: intl.formatMessage({
      id: 'controlpanel.purse.table.column.orderNumber'
    }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'date',
    text: intl.formatMessage({
      id: 'controlpanel.purse.table.column.date'
    }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'concept',
    text: intl.formatMessage({
      id: 'controlpanel.purse.table.column.concept'
    }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'amount',
    text: intl.formatMessage({
      id: 'controlpanel.purse.table.column.amount'
    }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'balanceBefore',
    text: intl.formatMessage({
      id: 'controlpanel.purse.table.column.balanceBefore'
    }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'balanceAfter',
    text: intl.formatMessage({
      id: 'controlpanel.purse.table.column.balanceAfter'
    }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  }
];

export const getNoResultsConfig = (intl) => ({
  text: intl.formatMessage({ id: 'controlpanel.purse.no_results' }),
  icon: 'wallet'
});

export const getDataTableStructure = (data) =>
  data.map(
    ({
      id,
      attributes: {
        amount = null,
        balanceAfter = null,
        balanceBefore = null,
        concept = null,
        date = null,
        orderNumber = null
      }
    }) => ({
      id,
      amount: <FormatAmount text={amount} />,
      balanceAfter: <FormatAmount text={balanceAfter} />,
      balanceBefore: <FormatAmount text={balanceBefore} />,
      concept: <FormatConcept concept={concept} />,
      date: <DateFormatter date={date} />,
      orderNumber
    })
  );
