import React from 'react';

import { BasicDataTable } from 'app/components/DataTable';
import GetColDataTable from 'app/modules/Orders/pages/OrderByCsv/config/table';
import useDataProviderContext from 'app/hooks/useDataProviderContext';
import ControlledLoadingBar from 'app/components/ControlledLoadingBar';

export default function ResultUploadForm() {
  const {
    loading,
    entities,
    options,
    countPendingOrders,
    totalRequestedOrders
  } = useDataProviderContext();

  const rowClasses = (row) => {
    if (row.error) {
      return 'error';
    }

    return '';
  };

  const keyField = 'csvLine';

  return (
    <>
      <ControlledLoadingBar
        current={countPendingOrders}
        total={totalRequestedOrders}
        hideWhenCompleted
        hideWhenZero
      />
      <BasicDataTable
        id="import-csv-result"
        columns={GetColDataTable()}
        entities={entities}
        keyField={keyField}
        loading={loading}
        rowClasses={rowClasses}
        options={options}
        headerClasses="import-csv-table-header"
      />
    </>
  );
}
