import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';

const { bbSecondary } = Colors;

export const ActionsFormatters = ({ link, invoiceNumber }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const seeOrders = async () => {
    navigate(`/orders?addFilters=true&invoice=${invoiceNumber}`);
  };

  const handleDownloadPDF = async () => {
    window.location.href = link;
  };

  return (
    <div className="actions-formatter">
      <Button
        icon={<Icon icon="eye" color={bbSecondary} prefix="fad" />}
        onClick={() => seeOrders({ invoiceNumber })}
        size="medium"
        tooltipText={intl.formatMessage({
          id: 'controlpanel.global.view_associated_orders'
        })}
      />
      <Button
        icon={<Icon icon="download" color={bbSecondary} prefix="fad" />}
        onClick={() => handleDownloadPDF()}
        size="medium"
        tooltipText={intl.formatMessage({
          id: 'controlpanel.global.download_invoice_pdf'
        })}
      />
    </div>
  );
};

ActionsFormatters.propTypes = {
  link: PropTypes.string.isRequired,
  invoiceNumber: PropTypes.string.isRequired
};
