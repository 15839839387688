import React from 'react';
import PropTypes from 'prop-types';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Colors } from 'app/utils/colors';
import Icon from 'app/components/Icon';
import NotificationBadge from 'app/components/NotificationBadge';

export default function TopbarItem({
  target = '_blank',
  tooltip = false,
  tooltipText = null,
  notificationBadge = null,
  url,
  icon
}) {
  const content = (
    <div className="bb-btn-item">
      <a href={url} target={target}>
        {notificationBadge && <NotificationBadge value={notificationBadge} />}
        <Icon icon={icon} color={Colors.bbSecondary} size="lg" prefix="fad" />
      </a>
    </div>
  );
  return (
    <div className="topbar-item">
      {tooltip && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{tooltipText}</Tooltip>}
        >
          {content}
        </OverlayTrigger>
      )}

      {!tooltip}
    </div>
  );
}

TopbarItem.propTypes = {
  target: PropTypes.string,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  tooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  notificationBadge: PropTypes.string
};
