import React from 'react';

import { getMultipleSelectDefaultValues, Select } from 'app/components/Select';
import { SelectDisable } from 'app/components/DataTableFilters/Components';
import RangeSlider from 'app/components/RangeSlider';
import SelectDateRange from 'app/components/SelectDateRange';

export const INITIAL_VALUES = {
  date: {
    start: '',
    end: ''
  },
  amount: [],
  payment: []
};

export const getFiltersConfiguration = ({
  intl,
  formik,
  maxTotalAmount,
  paymentMethods
}) => [
  {
    value: 'all',
    label: intl.formatMessage({ id: 'controlpanel.global.all' }),
    disabled: false,
    component: (
      <SelectDisable
        text={intl.formatMessage({
          id: 'controlpanel.global.placeholder.select'
        })}
      />
    )
  },
  {
    value: 'date',
    label: intl.formatMessage({ id: 'controlpanel.global.date' }),
    disabled: false,
    component: (
      <SelectDateRange
        nameStartDate="date.start"
        nameEndDate="date.end"
        dateFormat="yyyy-MM-dd"
        defaultStartDate={formik.values?.date?.start}
        defaultEndDate={formik.values?.date?.end}
      />
    )
  },
  {
    value: 'amount',
    label: intl.formatMessage({ id: 'controlpanel.global.amount' }),
    disabled: false,
    component: (
      <RangeSlider
        initialValue={
          formik.values?.amount?.length === 2
            ? formik.values?.amount
            : [0, maxTotalAmount]
        }
        min={0}
        max={maxTotalAmount}
        name="amount"
      />
    )
  },
  {
    value: 'payment',
    label: intl.formatMessage({ id: 'controlpanel.global.paymentmethod' }),
    disabled: false,
    component: (
      <Select
        multiple
        formik={formik}
        name="payment"
        id="payment"
        defaultValue={getMultipleSelectDefaultValues(paymentMethods, [
          formik.values?.payment
        ])}
        options={paymentMethods}
      />
    )
  }
];
