import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';

import { filter, size } from 'lodash';
import { useIntl } from 'react-intl';

import { DataTableProvider } from 'app/components/DataTable/context/DataTableContext';
import { genericRequest } from 'app/api';
import { getPurseDataTableConfiguration } from 'app/modules/Purse/config/config';
import { useSubheader } from 'app/context/SubheaderContext';
import Body from 'app/modules/Purse/components/Body';
import Card from 'app/components/Card';
import Header from 'app/modules/Purse/components/Header';
import NotificationForm from 'app/modules/Purse/components/NotificationForm';
import useNotificationsServiceHook from 'app/hooks/useNotificationsService.hook';
import UserNotificationAlert from 'app/components/UserNotificationAlert';
import PendingMovements from 'app/modules/Purse/components/PendingMovements';

import 'app/modules/Purse/Purse.scss';

export default function Purse() {
  const intl = useIntl();
  const subheader = useSubheader();
  const { setTitle, setIcon, setBreadcrumbs } = subheader;

  useLayoutEffect(() => {
    setTitle(intl.formatMessage({ id: 'controlpanel.asidemenu.purse' }));
    setIcon({ icon: 'database' });
    setBreadcrumbs([]);
  }, [intl, setTitle, setBreadcrumbs, setIcon]);

  const {
    notifications: { alerts }
  } = useNotificationsServiceHook();
  const alertsFiltered = filter(
    alerts,
    (alert) => alert?.attributes?.section === 'WALLET'
  );

  const [pendingMovements, setPendingMovements] = useState([]);

  const fetchData = useCallback(async (controller) => {
    const resultPendingMovements = await genericRequest({
      url: 'orders/pending',
      signal: controller.signal
    });

    setPendingMovements(resultPendingMovements?.data);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller);

    return () => controller.abort();
  }, [fetchData]);

  return (
    <div className="purse">
      {size(alertsFiltered) > 0 && (
        <UserNotificationAlert alerts={alertsFiltered} />
      )}
      {pendingMovements.length > 0 && (
        <PendingMovements pendingMovements={pendingMovements} />
      )}
      <DataTableProvider
        getTableContextConfiguration={getPurseDataTableConfiguration}
      >
        <Card
          extraClass="purse-card-movements"
          icon="exchange"
          title={intl.formatMessage({ id: 'controlpanel.purse.movements' })}
          header={<Header />}
          body={<Body />}
        />
      </DataTableProvider>
      <Card
        extraClass="purse-card-notifications col-xs-12 col-md-8"
        icon="bell-on"
        title={intl.formatMessage({
          id: 'controlpanel.purse.notification.title'
        })}
        body={<NotificationForm />}
      />
    </div>
  );
}
