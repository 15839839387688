import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';
import 'app/components/Badge/Badge.scss';

export default function badge({
  text,
  size = 'medium',
  color = 'green',
  svg = '',
  tooltipText = ''
}) {
  const children = (
    <div className={`bb-badge bb-badge__${size} bb-badge__${color}`}>
      <div>{text}</div>
      {svg && tooltipText && <SVG src={toAbsoluteUrl(svg)} />}
    </div>
  );

  if (tooltipText) {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>{tooltipText}</Tooltip>}
      >
        {children}
      </OverlayTrigger>
    );
  }

  return children;
}

badge.propTypes = {
  text: PropTypes.string.isRequired,
  svg: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small', 'xsmall']),
  color: PropTypes.oneOf([
    'green',
    'purple',
    'red',
    'blue',
    'yellow',
    'orange',
    'grey'
  ]),
  tooltipText: PropTypes.string
};
