import React from 'react';

import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { genericRequest } from 'app/api';
import { getColumnsToDataTable } from 'app/modules/Trackings/config/table';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';

const { bbSecondary, bbGrey2 } = Colors;

export function GetColDataTable(fetchData) {
  const intl = useIntl();

  const defaultCols = getColumnsToDataTable(intl);
  const deliveryNotesCols = defaultCols.filter(
    (col) => col?.dataField !== 'actions'
  );
  deliveryNotesCols.push({
    dataField: 'actions',
    text: intl.formatMessage({ id: 'controlpanel.global.table_actions' }),
    formatter: ActionsFormatter,
    classes: 'column-action',
    sort: false,
    formatExtraData: {
      intl,
      fetchData
    }
  });

  return deliveryNotesCols;
}

function ActionsFormatter(cell, row, rowIndex, formatExtraData) {
  const { intl, fetchData } = formatExtraData;

  const { id, read } = row;

  const color = read ? bbGrey2 : bbSecondary;
  const envelopeIcon = read ? 'envelope-open-text' : 'envelope';
  const message = read
    ? 'controlpanel.global.mark_not_read'
    : 'controlpanel.global.mark_read';

  return (
    <div className="actions-formatter">
      <Button
        icon={<Icon icon={envelopeIcon} color={color} prefix="fad" />}
        onClick={async () => {
          await genericRequest({
            url: `trackings/${id}`,
            method: 'PATCH',
            data: {
              data: {
                id,
                type: 'tracking',
                attributes: {
                  read: !read
                }
              }
            },
            customSuccessFeedback: {
              variant: 'success',
              content: intl.formatMessage({
                id: 'controlpanel.feedback.save_changes.success'
              }),
              autoClose: true,
              dismissible: false
            }
          });

          await fetchData();
        }}
        size="medium"
        tooltipText={intl.formatMessage({
          id: message
        })}
      />
    </div>
  );
}
