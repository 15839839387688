import React from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';
import { useLang, setLanguage } from 'app/context/i18n';
import { DropdownTopbarItemToggler } from 'app/layout/partials/DropdownTopbarItemToggler';
import { languages } from 'app/utils/constants';

import 'app/components/LangSelector/index.scss';

export default function LangSelector() {
  const intl = useIntl();
  const lang = useLang();
  const currentLanguage = languages.find((x) => x.lang === lang);
  const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false
  };

  return (
    <Dropdown drop="down" className="lang-selector" align="end">
      <Dropdown.Toggle as={DropdownTopbarItemToggler}>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              {intl.formatMessage({ id: 'controlpanel.global.language' })}
            </Tooltip>
          }
        >
          <div className="bb-btn-item">
            <img
              className="rounded flag-icon"
              src={currentLanguage.flag}
              alt={currentLanguage.name}
            />
            <SVG
              src={toAbsoluteUrl('/media/svg/bigbuy/caret-down.svg')}
              className="flag-dropdown d-none d-md-inline-flex"
            />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>

      <Dropdown.Menu className="lang-selector-dropdown-options p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <div className="navi navi-hover py-4">
          <PerfectScrollbar
            options={perfectScrollbarOptions}
            className="scroll"
            style={{ maxHeight: '200px', position: 'relative' }}
          >
            {map(languages, (language) => (
              <div
                key={language.lang}
                className={classNames('navi-item', {
                  active: language.lang === currentLanguage.lang
                })}
              >
                <div
                  onClick={() => setLanguage(language.lang)}
                  aria-hidden="true"
                  className="navi-link"
                >
                  <span className="symbol symbol-20 mr-3">
                    <img src={language.flag} alt={language.name} />
                  </span>
                  <span className="navi-text">{language.name}</span>
                </div>
              </div>
            ))}
          </PerfectScrollbar>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
