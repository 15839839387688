import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Field, useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';

import Icon from 'app/components/Icon';
import Label from 'app/components/Label';

import 'app/components/InputFile/InputFile.scss';

export default function InputFile({
  label = '',
  disabled = false,
  accept = 'image/png, image/jpg, image/jpeg',
  svg = null,
  svgPosition = 'left',
  bottomComponent = null,
  name,
  placeholder
}) {
  const fileInput = useRef(null);
  const [title, setTitle] = useState(placeholder);
  const formik = useFormikContext();

  const handleFileUpload = (event) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setTitle(files[0].name);
      formik.setFieldValue(name, files[0]);
    }
  };

  const handleOpenDialog = () => {
    fileInput.current.click();
  };

  return (
    <Field name={name}>
      {({ meta: { error, touched } }) => (
        <Form.Group className="bb-form-group form-group fv-plugins-icon-container">
          {label && <Label label={label} htmlFor={name} />}
          <div
            role="none"
            className={classNames('input-file-group', {
              'invalid-file-field': error && touched,
              'valid-file-field': !error && touched,
              'input-file-disabled': disabled
            })}
            onClick={handleOpenDialog}
          >
            {svg && svgPosition === 'left' && <Icon {...svg} />}
            <div className="input-file-group-container">
              <p className={`${svg ? '--spaced' : ''}`}>{title}</p>
              <input
                id={name}
                name={name}
                ref={fileInput}
                placeholder={placeholder}
                type="file"
                accept={accept}
                disabled={disabled}
                multiple={false}
                onChange={handleFileUpload}
              />
              {error && touched && <div className="invalid-file-field-icon" />}
              {!error && touched && <div className="valid-file-field-icon" />}
            </div>
            {svg && svgPosition === 'right' && <Icon {...svg} />}
          </div>
          {error && touched ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{error}</div>
            </div>
          ) : (
            bottomComponent
          )}
        </Form.Group>
      )}
    </Field>
  );
}

InputFile.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  svg: PropTypes.shape({}),
  accept: PropTypes.string,
  svgPosition: PropTypes.string,
  bottomComponent: PropTypes.element
};
