import React from 'react';
import PropTypes from 'prop-types';
import { FormikProvider } from 'formik';

export default function Form({
  formik = {},
  onChange = null,
  onBlur = null,
  children
}) {
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} onChange={onChange} onBlur={onBlur}>
        {children}
      </form>
    </FormikProvider>
  );
}

Form.propTypes = {
  formik: PropTypes.shape(),
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};
