import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';

export { CURRENCIES } from 'app/utils/currencies';
export { COUNTRIES } from 'app/utils/countries';

export const languages = [
  {
    id: 1,
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/svg/flags/260-united-kingdom.svg')
  },
  {
    id: 4,
    lang: 'es',
    name: 'Spanish',
    flag: toAbsoluteUrl('/media/svg/flags/128-spain.svg')
  },
  {
    id: 5,
    lang: 'fr',
    name: 'Français',
    flag: toAbsoluteUrl('/media/svg/flags/195-france.svg')
  },
  {
    id: 6,
    lang: 'de',
    name: 'Deutsch',
    flag: toAbsoluteUrl('/media/svg/flags/162-germany.svg')
  },
  {
    id: 7,
    lang: 'pt',
    name: 'Português',
    flag: toAbsoluteUrl('/media/svg/flags/224-portugal.svg')
  },
  {
    id: 8,
    lang: 'el',
    name: 'Ελληνικά',
    flag: toAbsoluteUrl('/media/svg/flags/170-greece.svg')
  },
  {
    id: 9,
    lang: 'hr',
    name: 'Hrvatski',
    flag: toAbsoluteUrl('/media/svg/flags/164-croatia.svg')
  },
  {
    id: 10,
    lang: 'it',
    name: 'Italiano',
    flag: toAbsoluteUrl('/media/svg/flags/013-italy.svg')
  },
  {
    id: 11,
    lang: 'et',
    name: 'Eesti',
    flag: toAbsoluteUrl('/media/svg/flags/008-estonia.svg')
  },
  {
    id: 12,
    lang: 'da',
    name: 'Dansk',
    flag: toAbsoluteUrl('/media/svg/flags/174-denmark.svg')
  },
  {
    id: 13,
    lang: 'fi',
    name: 'Suomi',
    flag: toAbsoluteUrl('/media/svg/flags/125-finland.svg')
  },
  {
    id: 14,
    lang: 'ro',
    name: 'Română',
    flag: toAbsoluteUrl('/media/svg/flags/109-romania.svg')
  },
  {
    id: 15,
    lang: 'bg',
    name: 'Български',
    flag: toAbsoluteUrl('/media/svg/flags/168-bulgaria.svg')
  },
  {
    id: 16,
    lang: 'hu',
    name: 'Magyar',
    flag: toAbsoluteUrl('/media/svg/flags/115-hungary.svg')
  },
  {
    id: 18,
    lang: 'sk',
    name: 'Slovenčina',
    flag: toAbsoluteUrl('/media/svg/flags/091-slovakia.svg')
  },
  {
    id: 19,
    lang: 'si',
    name: 'Slovenščina',
    flag: toAbsoluteUrl('/media/svg/flags/010-slovenia.svg')
  },
  {
    id: 20,
    lang: 'lt',
    name: 'Lietuvių',
    flag: toAbsoluteUrl('/media/svg/flags/064-lithuania.svg')
  },
  {
    id: 21,
    lang: 'lv',
    name: 'Latviešu',
    flag: toAbsoluteUrl('/media/svg/flags/044-latvia.svg')
  },
  {
    id: 22,
    lang: 'pl',
    name: 'Polski',
    flag: toAbsoluteUrl('/media/svg/flags/211-poland.svg')
  },
  {
    id: 24,
    lang: 'nl',
    name: 'Nederlands',
    flag: toAbsoluteUrl('/media/svg/flags/237-netherlands.svg')
  },
  {
    id: 25,
    lang: 'ru',
    name: 'Pусский',
    flag: toAbsoluteUrl('/media/svg/flags/248-russia.svg')
  },
  {
    id: 26,
    lang: 'no',
    name: 'Norsk',
    flag: toAbsoluteUrl('/media/svg/flags/143-norway.svg')
  },
  {
    id: 27,
    lang: 'sv',
    name: 'Svenska',
    flag: toAbsoluteUrl('/media/svg/flags/184-sweden.svg')
  },
  {
    id: 28,
    lang: 'cs',
    name: 'Čeština',
    flag: toAbsoluteUrl('/media/svg/flags/149-czech-republic.svg')
  }
];

/**
 * Mapeo de los tipos de usuario
 */
export const CUSTOMER_TYPES = {
  particular: {
    id: 1,
    key: 'INDIVIDUAL',
    text: <FormattedMessage id="controlpanel.global.user_type.particular" />,
    icon: 'user'
  },
  company: {
    id: 3,
    key: 'COMPANY',
    text: <FormattedMessage id="controlpanel.global.user_type.enterprise" />,
    icon: 'building'
  },
  freelancer: {
    id: 2,
    key: 'SELF_EMPLOYED',
    text: <FormattedMessage id="controlpanel.global.user_type.autonomous" />,
    icon: 'briefcase'
  }
};

export const myAccountRoutes = [
  {
    title: 'Resumen',
    icon: '/media/svg/bigbuy/large/user.svg',
    path: '/my-account/resume'
  },
  {
    title: 'Datos de usuario',
    icon: '/media/svg/bigbuy/large/edit.svg',
    path: '/my-account/user-data'
  },
  {
    title: 'Contraseña',
    icon: '/media/svg/bigbuy/large/shield-alt.svg',
    path: '/my-account/password'
  },
  {
    title: 'Información',
    icon: '/media/svg/bigbuy/large/information.svg',
    path: '/my-account/information'
  },
  {
    title: 'Métodos de pago',
    icon: '/media/svg/bigbuy/large/credit-card.svg',
    path: '/my-account/payment'
  },
  {
    title: 'Datos de facturación',
    icon: '/media/svg/bigbuy/large/database.svg',
    path: '/my-account/billing'
  },
  {
    title: 'Sincroniza con BigBuy',
    icon: '/media/svg/bigbuy/large/cog.svg',
    path: '/my-account/sincronize'
  },
  {
    title: 'Pack Mayorista',
    icon: '/media/svg/bigbuy/large/cube.svg',
    path: '/my-account/wholesaler-pack'
  },
  {
    title: 'Notificaciones',
    icon: '/media/svg/bigbuy/large/envelope-close.svg',
    path: '/my-account/notifications'
  }
];
export const SESSION_COOKIE = 'PHPSESSID';
export const DIALOG_COOKIE = 'notification-dialog';

export const TYPE_MOVEMENT = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL'
};

export const COLOR_MOVEMENT = {
  RED: 'red',
  GREEN: 'green',
  GREY: 'grey'
};

export const PACK_TYPE = {
  WITHOUT: 'without',
  B2B: 'b2b',
  ECOMMERCE: 'ecommerce',
  MARKETPLACES: 'marketplaces'
};

export const PACK_TYPE_NUMBER = {
  WITHOUT: 0,
  B2B: 201,
  ECOMMERCE: 210,
  MARKETPLACES: 211,
  BASIC: 1,
  PRO: 2,
  BUSINESS: 150,
  PREMIUM: 151,
  FREE: 167,
  DROPSHIP: 202,
  ENTERPRISE: 203
};

export const PACK_COLORS = {
  0: Colors.bbGrey4,
  201: Colors.bbOrange,
  210: Colors.bbPrimaryDark,
  211: Colors.bbGreen,
  1: Colors.bbPrimary,
  2: Colors.bbOrange,
  150: Colors.bbPrimary,
  151: Colors.bbGreen,
  167: Colors.bbSecondary,
  202: Colors.bbPrimaryDark,
  203: Colors.bbOrange
};

export const RESPONSE = {
  OK: 200
};

export const SHOP_SHIPMENT_STATUS = {
  ENTER_DETAILS: 1,
  CHECKING_REQUIREMENTS: 2,
  WRONG_REQUIREMENTS: 3,
  INSTALLATION_IN_PROGRESS: 4,
  SHOP_FINISHED: 5,
  SHOP_ON_HOLD: 4,
  SHOP_NOT_SYNCHRONIZED: 4
};

export const LANGUAGE_AVAILABLE_CSV = ['es', 'en', 'fr'];

export const MAX_DOWNLOAD_FILES = 5000;

export const MAX_DOWNLOAD_INVOICES = 50;

export const TAG_MANAGER_ID = 'GTM-PZVKJ7V';

export const ACADEMY_NAME = 'BigBuy Academy';
