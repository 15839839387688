import makeOrder from '../services/makeOrder';
import splitArray from './splitArray';

const useBatchAPICalls = (setCountPendingOrders) => {
  const batchAPICalls = async ({ pendingOrders, batchSize = 1 }) => {
    const batches = splitArray(pendingOrders, batchSize);

    const results = [];

    // eslint-disable-next-line no-restricted-syntax
    for await (const batch of batches) {
      try {
        const pendingAPICalls = batch.map((order) => makeOrder(order));
        const result = await Promise.all(pendingAPICalls);
        result.map((orderLine, key) => results.push(orderLine[key].attributes));
      } catch (err) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      }
      setCountPendingOrders((prev) => prev - batch.length);
    }

    return results.flat();
  };

  return batchAPICalls;
};

export default useBatchAPICalls;
