import { genericRequest } from 'app/api';
import { OK, KO } from 'app/modules/MyAccount/pages/PaymentMethods/utils';

export const handleTokens = async ({ userId, intl, fetchInitialData }) => {
  // Primero recogemos los parámetros, por si tenemos que realizar
  // alguna acción con los tokens de paycomet o paypal
  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchParams = Object.fromEntries(urlSearchParams.entries());
  const { paytpvToken, paypalStatus, token } = searchParams;

  // Si no hay tokens, detenemos esta función
  if (!paytpvToken && !paypalStatus) {
    await fetchInitialData();
    return;
  }

  // Si hay tokens configuramos nuestra llamada
  const data = getTokenAPICallConfiguration({
    paytpvToken,
    paypalStatus,
    token,
    intl
  });

  // Mandamos la llamada
  try {
    if (paypalStatus === KO) {
      await fetchInitialData();
      const errorEvent = new CustomEvent('run-custom-feedback', {
        bubbles: true,
        detail: {
          variant: 'danger',
          content: intl.formatMessage({
            id: 'controlpanel.my_account.payment_methods.add_paypal_error'
          }),
          dismissible: true
        }
      });
      document.dispatchEvent(errorEvent);
    } else {
      await genericRequest({
        url: `${process.env.REACT_APP_API_URL}payment/${userId}/actions`,
        method: 'POST',
        data: { data },
        customSuccessFeedback: {
          variant: 'success',
          content: intl.formatMessage({
            id: 'controlpanel.feedback.save_changes.success'
          }),
          autoClose: true,
          dismissible: false
        },
        errorAlertProps: {
          content: intl.formatMessage({
            id: 'controlpanel.global.undefined_error'
          }),
          dismissible: true
        }
      });
      await fetchInitialData();
    }
  } catch (err) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  // Y limpiamos la URL
  window.history.pushState({}, document.title, window.location.pathname);
};

const getTokenAPICallConfiguration = ({
  paytpvToken,
  paypalStatus,
  token,
  intl
}) => {
  if (paytpvToken) {
    return {
      type: 'paycomet-save-card',
      attributes: {
        type: 'paycomet-save-card',
        paytpvToken
      }
    };
  }
  if (paypalStatus) {
    return getPaypalAPIConfiguration({ paypalStatus, token, intl });
  }
  return null;
};

const getPaypalAPIConfiguration = ({ paypalStatus, token }) => {
  if (paypalStatus === OK) {
    return {
      type: 'paypal_save_billing_agreement',
      attributes: {
        type: 'paypal_save_billing_agreement',
        token
      }
    };
  }
  if (paypalStatus === KO) {
    return {
      type: 'payment-messages',
      attributes: {
        type: 'payment-message-paypal-record-ko'
      }
    };
  }
  return null;
};
