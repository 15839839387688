import React, { isValidElement } from 'react';

import classNames from 'classnames';

import { NotificationCloseButton } from 'app/components/Notification/components';
import { HEADER_PROPS } from 'app/components/Notification/utils';
import { Colors } from 'app/utils/colors';

export default function NotificationHeader({
  header = null,
  children = null,
  className = null,
  closeIcon = { icon: 'xmark', prefix: 'fad', color: Colors.bbGrey2 },
  handleClose = null,
  dismissible = false
}) {
  if (children && isValidElement(children)) {
    return children;
  }

  const classes = classNames('bb-notification__header', className);

  return (
    <div className={classes}>
      {typeof header === 'string' ? (
        <p
          dangerouslySetInnerHTML={{ __html: header }}
          className="bold bb-notification__header--title"
        />
      ) : (
        header
      )}
      {dismissible && (
        <NotificationCloseButton
          className="bb-notification__close-btn bb-notification__close-btn--in-header"
          closeIcon={closeIcon}
          handleClose={handleClose}
        />
      )}
    </div>
  );
}

NotificationHeader.propTypes = HEADER_PROPS;
