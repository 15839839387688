import { Colors } from 'app/utils/colors';
import { SHOP_SHIPMENT_STATUS } from 'app/utils/constants';

const {
  ENTER_DETAILS,
  CHECKING_REQUIREMENTS,
  INSTALLATION_IN_PROGRESS,
  SHOP_FINISHED
} = SHOP_SHIPMENT_STATUS;

export const COLOR_MAP = {
  DONE: Colors.bbGrey4,
  CURRENT: Colors.bbSecondary,
  PENDING: Colors.bbGrey2
};

export const STEPS = [
  {
    key: ENTER_DETAILS,
    translationKey: 'controlpanel.shop_form.status.insert_data',
    icon: 'edit'
  },
  {
    key: CHECKING_REQUIREMENTS,
    translationKey: 'controlpanel.shop_form.status.checking_requirements',
    icon: 'cogs'
  },
  {
    key: INSTALLATION_IN_PROGRESS,
    translationKey: 'controlpanel.shop_form.status.work_in_progress',
    icon: 'digging'
  },
  {
    key: SHOP_FINISHED,
    translationKey: 'controlpanel.shop_form.status.finished',
    icon: 'store'
  }
];

export const MAIN_TITLE = 'controlpanel.shopify_form.title.data';
export const SELECTS_TITLE =
  'controlpanel.shopify_form.title.catalog_and_theme';

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
export const KB = 1024;
