import React, { useMemo } from 'react';

import { languages } from 'app/utils/constants';
import { useLang } from 'app/context/i18n';
import Section from 'app/modules/MyAccount/pages/Notifications/components/Section';

export default function NotificationsUserPanel() {
  const lang = useLang();

  const currentLanguage = useMemo(
    () => languages.find((x) => x.lang === lang),
    [lang]
  );

  return (
    <div className="bb-notification-panel">
      <Section
        currentLanguage={currentLanguage}
        showCurrentLanguage
        sectionTranslationKey="newsletter"
        formikKey="newsletter"
      />
    </div>
  );
}
