import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import Button from 'app/components/Button';
import Input from 'app/components/Input';

export default function NotificationFormActive({
  updateWalletMovements,
  setNotificationAmount,
  setPopupConfig
}) {
  const intl = useIntl();
  const [disabledButton, setDisabledButton] = useState(true);

  return (
    <Formik
      initialValues={{ amount: '' }}
      validationSchema={Yup.object().shape({
        amount: Yup.number().required(
          intl.formatMessage({ id: 'controlpanel.form.validation.required' })
        )
      })}
      onSubmit={async (values) => {
        setDisabledButton(true);

        const saveData = await updateWalletMovements(values.amount, true);

        if (saveData?.status === 204) {
          setPopupConfig({
            showPopup: true,
            popupTitle: intl.formatMessage({
              id: 'controlpanel.purse.popup.active_title'
            }),
            popupMsg: intl.formatMessage(
              {
                id: 'controlpanel.purse.popup.active_msg'
              },
              {
                amount: values?.amount
              }
            )
          });
          setNotificationAmount(values?.amount);
        }

        return true;
      }}
    >
      {() => (
        <Form
          className="purse-card-notifications__active"
          onChange={() => setDisabledButton(false)}
        >
          <Row className="purse-card-notifications__active-msg">
            <Col md={12}>
              <p>
                {intl.formatMessage({
                  id: 'controlpanel.purse.notification.active'
                })}
              </p>
            </Col>
          </Row>
          <Row className="purse-card-notifications__active-form">
            <Col
              md={12}
              className="purse-card-notifications__active-form-label"
            >
              <p>
                {intl.formatMessage({
                  id: 'controlpanel.purse.notification.active_label'
                })}
              </p>
            </Col>
            <Col md={8}>
              <Input
                type="number"
                name="amount"
                placeholder={intl.formatMessage({
                  id: 'controlpanel.purse.filters.amount'
                })}
                textPrepend="€"
              />
            </Col>
            <Col md={4}>
              <Button
                type="primary"
                typeButton="submit"
                disabled={disabledButton}
                text={intl.formatMessage({
                  id: 'controlpanel.purse.form.active_button'
                })}
                size="big"
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

NotificationFormActive.propTypes = {
  updateWalletMovements: PropTypes.func.isRequired,
  setNotificationAmount: PropTypes.func.isRequired,
  setPopupConfig: PropTypes.func.isRequired
};
