import React from 'react';
import PropTypes from 'prop-types';

import { DataTableProvider } from 'app/components/DataTable/context/DataTableContext';
import { getOrdersDataTableConfiguration } from 'app/modules/Orders/config/config';

export default function OrdersContext({ children }) {
  return (
    <DataTableProvider
      getTableContextConfiguration={getOrdersDataTableConfiguration}
    >
      {children}
    </DataTableProvider>
  );
}

OrdersContext.propTypes = {
  children: PropTypes.node.isRequired
};
