import axios from 'axios';

export async function changeLanguage(locale) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BIGBUY_URL}${locale}/front/api/session`,
      { withCredentials: true }
    );

    return response?.status;
  } catch (error) {
    return error;
  }
}
