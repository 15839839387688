import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';
import { useIntl } from 'react-intl';

import { formatCurrency } from 'app/utils/currency';
import { genericRequest } from 'app/api';
import { BasicDataTable } from 'app/components/DataTable';
import GetColDataTable from 'app/modules/OrderView/config/Invoice';
import useDataProviderContext from 'app/hooks/useDataProviderContext';
import usePaymentMethods from 'app/hooks/usePaymentMethods.hook';

export default function InvoicesDatatable({ fetchedInvoices = null }) {
  const intl = useIntl();

  const { order } = useDataProviderContext();
  const paymentMethods = usePaymentMethods();
  const { id } = order;

  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  const fetchData = useCallback(async () => {
    if (fetchedInvoices) {
      setInvoices(getDataTableStructure(fetchedInvoices, paymentMethods));
      setLoading(false);
      return;
    }
    const result = await genericRequest({
      url: `orders/${id}/order-invoices`,
      setLoading
    });

    setInvoices(getDataTableStructure(result?.data, paymentMethods));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fetchedInvoices]);

  useEffect(() => {
    fetchData();
    return () => fetchData();
  }, [fetchData]);

  return (
    <BasicDataTable
      id="invoice-datatable"
      columns={GetColDataTable(intl)}
      entities={invoices}
      keyField="invoiceNumber"
      loading={loading}
    />
  );
}

const getDataTableStructure = (data, paymentMethods) => {
  const dataEstructure = [];

  map(data, (value) => {
    const { invoiceNumber, date, payment, amount } = value.attributes;

    dataEstructure.push({
      invoiceNumber,
      date,
      payment: paymentMethods[payment]?.translation,
      type: value?.type,
      amount: formatCurrency(amount),
      extraData: value
    });
  });

  return dataEstructure;
};

InvoicesDatatable.propTypes = {
  fetchedInvoices: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.array
  ])
};
