/* eslint-disable object-shorthand */
import React, { useState, useMemo, isValidElement } from 'react';

import classNames from 'classnames';

import {
  parseStyles,
  PROP_TYPES
} from 'app/components/Notification/utils/index';
import { NotificationStructure } from 'app/components/Notification/components';

import 'app/components/Notification/Notification.scss';

export default function Notification({
  background = null,
  textColor = null,
  children = null,
  className = null,
  padding,
  ...rest
}) {
  const [show, setShow] = useState(true);

  const styles = useMemo(
    () => parseStyles({ background, textColor }),
    [background, textColor]
  );

  const handleClose = () => setShow(false);

  if (!show) {
    return null;
  }

  const classes = classNames(
    'bb-notification',
    `bb-notification-${rest.variant}`,
    className
  );

  return (
    <div className={classes} style={{ styles, padding }}>
      {children && isValidElement(children) ? (
        children
      ) : (
        <NotificationStructure {...rest} handleClose={handleClose} />
      )}
    </div>
  );
}

Notification.propTypes = PROP_TYPES;
