import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Image } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import Checkbox from 'app/components/Checkbox';
import CropImage from 'app/components/CropImage';
import Divider from 'app/components/Divider';
import Icon from 'app/components/Icon';
import Input from 'app/components/Input';
import Modal from 'app/components/Modal';

import 'app/modules/MyAccount/pages/UserData/UserForm/UserForm.scss';

export default function UserForm({
  clearImage,
  onClearImage,
  formik,
  inputLoading
}) {
  const intl = useIntl();

  const inputFile = useRef(null);

  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(formik.initialValues.logo);
  const [show, setShow] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [croped, setCroped] = useState(!!formik.initialValues.logo);

  useEffect(() => {
    if (clearImage) {
      setCroped(false);
      setCroppedImage(null);
      onClearImage();
    }
  }, [clearImage, onClearImage]);

  useEffect(() => {
    if (croppedImage) {
      formik.setFieldValue('file', croppedImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedImage]);

  const onOpenDialog = () => {
    setCroped(false);
    setCroppedImage(null);
    setDisabledSaveButton(true);
    setShow(true);
    inputFile.current.click();
  };

  const handleFileUpload = (e) => {
    const { files, name } = e.target;
    if (files && files.length) {
      setImage(URL.createObjectURL(files[0]));
      formik.setFieldValue(name, files[0]);
    }
  };

  const onCancelModal = () => {
    setShow(false);
    setCroped(false);
    setCroppedImage(null);
  };

  const onSaveModal = () => {
    setShow(false);
    setCroped(true);
  };

  const onCropImage = (imageFile) => {
    setDisabledSaveButton(false);
    setCroppedImage(imageFile);
  };

  return (
    <>
      {show && image && (
        <Modal
          title={intl.formatMessage({
            id: 'controlpanel.my_account.user_data.modal.title'
          })}
          show={show}
          buttons={
            <>
              <Button
                text={intl.formatMessage({ id: 'controlpanel.global.cancel' })}
                onClick={onCancelModal}
              />
              <Button
                type="secondary"
                text={intl.formatMessage({
                  id: 'controlpanel.my_account.user_data.modal.save_button'
                })}
                disabled={disabledSaveButton}
                onClick={onSaveModal}
              />
            </>
          }
          body={<CropImage image={image} onComplete={onCropImage} />}
        />
      )}

      <div className="bb-user-panel">
        <Row className="bb-user-panel__header">
          <Col md={3} />
          <Col md={7} className="bb-user-panel__header__title">
            <p className="big medium">
              {intl.formatMessage({
                id: 'controlpanel.my_account.user_data.panel.title'
              })}
            </p>
          </Col>
          <Col md={2} />
        </Row>

        <Row className="bb-user-panel__personal">
          <Col
            md={3}
            className="bb-user-panel__personal__title user-form-label"
          >
            <p>
              {intl.formatMessage({
                id: 'controlpanel.my_account.user_data.inputs.identifyer'
              })}
            </p>
          </Col>
          <Col md={7} className="bb-user-panel__personal__input">
            <Input
              formik={formik}
              name="clientId"
              disabled
              placeholder={formik.initialValues.clientId}
            />
          </Col>
          <Col md={2} />
        </Row>

        <Row className="bb-user-panel__personal">
          <Col
            md={3}
            className="bb-user-panel__personal__title user-form-label"
          >
            <p>
              {intl.formatMessage({
                id: 'controlpanel.form.label.name'
              })}
            </p>
          </Col>
          <Col md={7} className="bb-user-panel__personal__input">
            <Input
              formik={formik}
              name="name"
              disabled
              placeholder={formik.initialValues.name}
            />
          </Col>
          <Col md={2} />
        </Row>

        <Row className="bb-user-panel__personal">
          <Col
            md={3}
            className="bb-user-panel__personal__title user-form-label"
          >
            <p>
              {intl.formatMessage({
                id: 'controlpanel.form.label.lastname'
              })}
            </p>
          </Col>
          <Col md={7} className="bb-user-panel__personal__input">
            <Input
              formik={formik}
              name="lastname"
              disabled
              placeholder={formik.initialValues.lastname}
            />
          </Col>
          <Col md={2} />
        </Row>

        <Row className="bb-user-panel__personal">
          <Col
            md={3}
            className="bb-user-panel__personal__title user-form-label"
          >
            <p>
              {intl.formatMessage({
                id: 'controlpanel.global.email'
              })}
            </p>
          </Col>
          <Col md={7} className="bb-user-panel__personal__input">
            <Input
              formik={formik}
              name="email"
              placeholder={intl.formatMessage({
                id: 'controlpanel.global.email'
              })}
              textPrepend="@"
              loading={inputLoading}
            />
          </Col>
          <Col md={2} />
        </Row>

        <Divider type="dash" />
        <Row className="bb-user-panel__header">
          <Col md={3} />
          <Col md={7} className="bb-user-panel__header__title">
            <p className="big medium">
              {intl.formatMessage({
                id: 'controlpanel.my_account.user_data.panel.company'
              })}
            </p>
          </Col>
          <Col md={2} />
        </Row>
        <Row className="bb-user-panel__personal">
          <Col
            md={3}
            className="bb-user-panel__personal__title user-form-label"
          >
            <p>
              {intl.formatMessage({
                id: 'controlpanel.my_account.user_data.inputs.company-website'
              })}
            </p>
          </Col>
          <Col md={7} className="bb-user-panel__personal__input">
            <Input
              formik={formik}
              name="company"
              placeholder={intl.formatMessage({
                id: 'controlpanel.form.label.company'
              })}
            />
          </Col>
          <Col md={2} />
        </Row>

        <Row className="bb-user-panel__personal">
          <Col md={3} className="bb-user-panel__personal__title">
            <p>
              {intl.formatMessage({
                id: 'controlpanel.my_account.user_data.inputs.logo'
              })}
            </p>
          </Col>
          <Col md={7} className="bb-user-panel__personal__container">
            <div className="bb-user-panel__personal__container__row">
              {croppedImage && croped ? (
                <Image src={croppedImage} alt="logo" />
              ) : (
                <div className="bb-user-panel__personal__container__row__logo" />
              )}
              <span role="none" onClick={onOpenDialog}>
                <input
                  id="file"
                  name="file"
                  type="file"
                  accept="image/png, image/jpeg"
                  ref={inputFile}
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                />
                <Icon icon="pen-to-square" color={Colors.bbGrey3} size="lg" />
              </span>
              <div className="bb-user-panel__personal__container__row__title">
                <p>
                  {intl.formatMessage({
                    id: 'controlpanel.my_account.user_data.inputs.logo-info'
                  })}
                </p>
              </div>
            </div>
            {formik.touched.file && formik.errors.file ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.file}</div>
              </div>
            ) : null}
          </Col>
          <Col md={2} />
        </Row>
        <Row className="bb-user-panel__description">
          <Col md={3} />
          <Col md={7} className="bb-user-panel__description__title">
            <p className="small">
              {intl.formatMessage({
                id: 'controlpanel.my_account.user_data.inputs.logo-description'
              })}
            </p>
          </Col>
          <Col md={2} />
        </Row>
        <Divider type="dash" />
        <Row className="bb-user-panel__verification">
          <Col md={3} />
          <Col md={7} className="bb-user-panel__verification-title">
            <p className="big medium">
              {intl.formatMessage({
                id: 'controlpanel.global.two_steps_verification'
              })}
            </p>
            <div className="bb-user-panel__verification-title-container">
              <Checkbox
                formik={formik}
                type="radio"
                name="verification"
                id="verificationYes"
                label={intl.formatMessage({
                  id: 'controlpanel.global.yes'
                })}
                value="yes"
              />
              <Checkbox
                formik={formik}
                type="radio"
                name="verification"
                id="verificationNo"
                label={intl.formatMessage({
                  id: 'controlpanel.global.no'
                })}
                value="no"
              />
            </div>
            <p className="small">
              {intl.formatMessage({
                id: 'controlpanel.global.two_steps_verification_text'
              })}
            </p>
          </Col>
          <Col md={2} />
        </Row>
      </div>
    </>
  );
}

UserForm.propTypes = {
  formik: PropTypes.shape().isRequired,
  clearImage: PropTypes.bool.isRequired,
  onClearImage: PropTypes.func.isRequired,
  inputLoading: PropTypes.bool.isRequired
};
