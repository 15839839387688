import PropTypes from 'prop-types';

export const SHOP_PROPTYPES = PropTypes.shape({
  address: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  city: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  country: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  email: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  province: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  zipCode: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  currency: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])])
  ),
  domain: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  favicon: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  mainLanguage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null])
  ]),
  languages: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])])
  ),
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  shopName: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  statusKey: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  updateDate: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  catalogue: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  theme: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])])
});

export const SHOP_HISTORY_PROPTYPES = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    attributes: PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null])
      ]),
      date: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      status: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])])
    })
  })
).isRequired;

export const SHOPIFY_FORM_PROPTYPES = {
  shop: SHOP_PROPTYPES.isRequired,
  shopHistory: SHOP_HISTORY_PROPTYPES,
  setShop: PropTypes.func.isRequired,
  setShopHistory: PropTypes.func.isRequired
};
