import React from 'react';
import PropTypes from 'prop-types';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from 'app/components/Icon/Icon';
import { Colors } from 'app/utils/colors';

import 'app/modules/Devolutions/components/TableProducts/formatters/TotalRefund/TotalRefund.scss';

export default function TotalRefund({
  percentage = null,
  comment = null,
  total
}) {
  return (
    <div className="total-refund">
      <div>
        <p className="small">{`${total}€`}</p>
        {percentage && (
          <p className="small percentage">{`(-${percentage}%)`}</p>
        )}
      </div>
      {comment && (
        <div className="tooltip-container">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{comment}</Tooltip>}
          >
            <div>
              <Icon icon="info-square" color={Colors.bbSecondary} />
            </div>
          </OverlayTrigger>
        </div>
      )}
    </div>
  );
}

TotalRefund.propTypes = {
  total: PropTypes.number.isRequired,
  percentage: PropTypes.number,
  comment: PropTypes.string
};
