import React from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import { downloadFile } from 'app/utils/downloadFile';
import Icon from 'app/components/Icon';

const { bbSecondary } = Colors;

export const FormatActions = ({ status, id, labelUrl = '' }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const color = bbSecondary;

  const handleDownloadLabel = () => {
    downloadFile({
      url: labelUrl,
      nameFile: `${id}_label.pdf`
    });
  };

  const handleToggleRead = async () => {
    navigate(`/devolutions/add/${id}`);
  };

  return (
    <div className="actions-formatter">
      {status === 'DRAFT' || status === 'PENDING_PICK_UP_ADDRESS' ? (
        <Button
          icon={<Icon icon="pen-to-square" color={color} />}
          onClick={handleToggleRead}
          size="medium"
          tooltipText={intl.formatMessage({
            id: 'controlpanel.rma.edit_rma'
          })}
        />
      ) : (
        <Button
          icon={<Icon icon="eye" color={color} />}
          onClick={handleToggleRead}
          size="medium"
          tooltipText={intl.formatMessage({
            id: 'controlpanel.rma.view_rma'
          })}
        />
      )}

      {status === 'PENDING_COLLECTION' && (
        <Button
          icon={<Icon icon="file-download" color={color} />}
          onClick={handleDownloadLabel}
          size="medium"
          tooltipText={intl.formatMessage({
            id: 'controlpanel.rma.label_download'
          })}
        />
      )}
    </div>
  );
};

FormatActions.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  labelUrl: PropTypes.string
};
