import React, { useEffect, useMemo, useState, useCallback } from 'react';

import { Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import 'app/modules/MyAccount/pages/Summary/Summary.scss';
import { genericRequest } from 'app/api';
import { parserServices } from 'app/utils/parsers/services.parser';
import { parserShops } from 'app/utils/parsers/summary-shops.parser';
import { parserWalletcard } from 'app/utils/parsers/walletcard.parser';
import { URLS } from 'app/config/urls';
import ConnectorList from 'app/components/ConnectorList';
import DropshippingShopCard from 'app/components/DropshippingShopCard';
import MarketplaceConnectorList from 'app/components/MarketplaceConnectorList';
import MyAccountLayout from 'app/modules/MyAccount/components/MyAccountLayout';
import PackInfoCard from 'app/components/PackInfoCard';
import WalletCard from 'app/components/WalletCard';
import { useLang } from 'app/context/i18n';
import { useWindow } from 'app/hooks/useWindow.hook';
import { SIZE } from 'app/utils/sizes';

export default function Summary() {
  const intl = useIntl();
  const { width } = useWindow();
  const mobile = width < SIZE.MD;
  const currentLang = useLang();
  const { user: loggedInfo } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const { packName, packExpirationDate, packId, packRenovation, wallet } =
    useMemo(() => loggedInfo?.attributes, [loggedInfo?.attributes]);
  const [dataCard, setDataCard] = useState(null);
  const [dataShops, setDataShops] = useState(null);
  const [dataEcommerce, setDataEcommerce] = useState(null);
  const [dataMarketplace, setDataMarketplace] = useState(null);

  useEffect(() => {
    const getMovements = async () => {
      const movements = await genericRequest({
        url: 'wallet-movements?page[size]=3&page[number]=0&sort=-date'
      });
      const parsedMovements = parserWalletcard(movements.data, intl);
      setDataCard(parsedMovements);
    };
    getMovements();

    return () => {
      setDataCard(null);
    };
  }, [intl]);

  useEffect(() => {
    const getEcommerceService = async () => {
      const { data } = await genericRequest({
        url: 'general-services/ecommerces'
      });
      const parsedEcommerceServices = parserServices(data);
      setDataEcommerce(parsedEcommerceServices);
    };
    getEcommerceService();

    return () => {
      setDataEcommerce(null);
    };
  }, []);

  useEffect(() => {
    const getMarketplaceService = async () => {
      const { data } = await genericRequest({
        url: 'general-services/marketplaces'
      });
      const parsedMarketplaceServices = parserServices(data);
      setDataMarketplace(parsedMarketplaceServices);
    };
    getMarketplaceService();

    return () => {
      setDataMarketplace(null);
    };
  }, []);

  const getShops = useCallback(async () => {
    const { data } = await genericRequest({
      url: 'shops360',
      setLoading: setIsLoading
    });
    const parsedDataShop = parserShops(data);
    setDataShops(parsedDataShop);
  }, []);

  useEffect(() => {
    getShops();

    return () => {
      setDataShops(null);
    };
  }, [getShops]);

  return (
    <MyAccountLayout currentPagePath={URLS.myAccountSummary}>
      <Col md={4} className="equal-heigth">
        <PackInfoCard
          title={intl.formatMessage({ id: 'controlpanel.pack.default.title' })}
          packId={packId}
          packNameText={packName}
          isRenovationActive={packRenovation}
          date={packExpirationDate}
        />
      </Col>
      <Col md={4} className="equal-heigth">
        {dataCard && (
          <WalletCard
            type={mobile ? 'default' : 'medium'}
            titleWallet={intl.formatMessage({
              id: 'controlpanel.walletcard.purse'
            })}
            balanceTitle={intl.formatMessage({
              id: 'controlpanel.walletcard.balance'
            })}
            balanceValue={wallet}
            titleInfo={intl.formatMessage({
              id: 'controlpanel.walletcard.info'
            })}
            payInTitle={intl.formatMessage({
              id: 'controlpanel.walletcard.deposit_money'
            })}
            drawOutTitle={intl.formatMessage({
              id: 'controlpanel.walletcard.transfer_money'
            })}
            times={dataCard.times}
            numTimes={3}
            currentLang={currentLang}
          />
        )}
      </Col>
      {dataMarketplace && (
        <Col md={12}>
          <MarketplaceConnectorList
            title={intl.formatMessage({
              id: 'controlpanel.my_account.summary.marketplace.title'
            })}
            connectors={dataMarketplace}
          />
        </Col>
      )}
      {!isLoading && dataShops && (
        <Col md="6" xs={{ order: 'last' }}>
          <DropshippingShopCard shops={dataShops} />
        </Col>
      )}
      {dataEcommerce && (
        <Col md={{ span: 6, order: 'last' }}>
          <ConnectorList
            title={intl.formatMessage({
              id: 'controlpanel.my_account.summary.ecommerce.title'
            })}
            connectors={dataEcommerce}
          />
        </Col>
      )}
    </MyAccountLayout>
  );
}
