import React from 'react';

import { getMultipleSelectDefaultValues, Select } from 'app/components/Select';
import { SelectDisable } from 'app/components/DataTableFilters/Components';
import Checkbox from 'app/components/Checkbox';
import SelectDateRange from 'app/components/SelectDateRange';

export const INITIAL_VALUES = {
  date: {
    start: '',
    end: ''
  },
  carrier: [],
  status: [],
  expedition_date: {
    start: '',
    end: ''
  },
  is_read: ''
};

export const getFiltersConfiguration = ({
  intl,
  formik,
  parsedCarriers,
  statusOptions
}) => [
  {
    value: 'all',
    label: intl.formatMessage({ id: 'controlpanel.global.all' }),
    disabled: false,
    component: (
      <SelectDisable
        text={intl.formatMessage({
          id: 'controlpanel.global.placeholder.select'
        })}
      />
    )
  },
  {
    value: 'date',
    label: intl.formatMessage({ id: 'controlpanel.global.order_date' }),
    disabled: false,
    component: (
      <SelectDateRange
        nameStartDate="date.start"
        nameEndDate="date.end"
        dateFormat="yyyy-MM-dd"
        defaultStartDate={formik.values?.date?.start}
        defaultEndDate={formik.values?.date?.end}
      />
    )
  },
  {
    value: 'carrier',
    label: intl.formatMessage({ id: 'controlpanel.trackings.filters.carrier' }),
    disabled: false,
    component: (
      <Select
        multiple
        formik={formik}
        name="carrier"
        id="carrier"
        defaultValue={getMultipleSelectDefaultValues(parsedCarriers, [
          formik.values?.carrier
        ])}
        options={parsedCarriers}
      />
    )
  },
  {
    value: 'status',
    label: intl.formatMessage({ id: 'controlpanel.trackings.filters.status' }),
    disabled: false,
    component: (
      <Select
        multiple
        formik={formik}
        name="status"
        id="status"
        defaultValue={getMultipleSelectDefaultValues(statusOptions, [
          formik.values?.status
        ])}
        options={statusOptions}
      />
    )
  },
  {
    value: 'expedition_date',
    label: intl.formatMessage({
      id: 'controlpanel.trackings.filters.expedition_date'
    }),
    disabled: false,
    component: (
      <SelectDateRange
        nameStartDate="expedition_date.start"
        nameEndDate="expedition_date.end"
        dateFormat="yyyy-MM-dd"
        defaultStartDate={formik.values?.expedition_date?.start}
        defaultEndDate={formik.values?.expedition_date?.end}
      />
    )
  },
  {
    value: 'is_read',
    label: intl.formatMessage({ id: 'controlpanel.trackings.filters.is_read' }),
    disabled: false,
    component: (
      <div data-id="is_read">
        <Checkbox
          formik={formik}
          id="radio1"
          name="is_read"
          type="radio"
          label="Si"
          value="true"
        />
        <Checkbox
          formik={formik}
          id="radio2"
          name="is_read"
          type="radio"
          label="No"
          value="false"
        />
      </div>
    )
  }
];

export const getCarriers = ({ attributes: { carriers } }) =>
  carriers.map(({ key: value, name: label }) => ({
    value,
    label
  }));

export const clearField = (formik, field) => formik.setFieldValue(field, '');

export const submitFilters = ({ values, setFilters }) => {
  if (!values) {
    return;
  }

  const formValuesArray = Object.entries(values);
  const newConfig = {};

  formValuesArray.forEach(([key, value]) => {
    if (
      // No guardamos valores vacíos
      !value ||
      // Tampoco guardamos objetos que no tengan valores asignados
      (typeof value === 'object' && Object.values(value)?.length) ||
      // Tampoco guardamos arrays sin contenido
      (Array.isArray(value) && !value?.length)
    ) {
      return;
    }
    // El resto sí se guarda
    newConfig[key] = value;
  });

  setFilters(newConfig);
};
