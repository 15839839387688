/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React from 'react';
import classNames from 'classnames';

import './styles.scss';

function Button({
  children,
  size = 'default',
  type = 'flat',
  variant = 'primary',
  disabled = false,
  loading = false,
  onClick,
  iconRight,
  iconLeft,
  htmlProps = {}
}) {
  const buttonClassNames = classNames(
    `bb-group-btn bb-group-btn-size--${size} bb-group-btn--${type}`,
    {
      [`bb-group-btn--${type}--${variant}`]: type !== 'link',
      'bb-group-btn--loading': loading
    },
    htmlProps.className
  );

  const buttonHtmlProps = {
    type: 'button',
    ...htmlProps
  };

  const handleOnClick = (event) => {
    if (!loading) {
      onClick?.(event);
    }
    event.currentTarget.blur();
  };

  return (
    <button
      {...buttonHtmlProps}
      disabled={disabled}
      onClick={handleOnClick}
      className={buttonClassNames}
      tabIndex={loading ? -1 : htmlProps.tabIndex}
    >
      <div className="bb-group-btn__content">
        {iconLeft}
        {children}
        {iconRight}
      </div>
    </button>
  );
}

export default Button;
