export const parserCourses = (courses) => {
  const parsedCourses = [];
  const { data, meta } = courses;
  const { subscriptionActive } = meta;
  data?.forEach((course) => {
    let state = '';
    const {
      active,
      btnLink,
      description,
      discount,
      features,
      image,
      id,
      link,
      linktext,
      purchased,
      serviceCheckout,
      tags,
      teacher,
      title,
      video
    } = course.attributes;
    if (active) {
      if (purchased && subscriptionActive) {
        state = 'purchased';
      } else {
        state = 'default';
      }
    } else {
      state = 'disabled';
    }
    const item = {
      btnLink,
      description,
      discount,
      features,
      id,
      image,
      link,
      linktext,
      serviceCheckout,
      state,
      tags,
      teacher,
      title,
      video
    };
    parsedCourses.push(item);
  });
  return {
    parsedCourses: parsedCourses || []
  };
};
