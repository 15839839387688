import { INITIAL_VALUES } from 'app/modules/Devolutions/config/filters';
import { getTabs } from 'app/modules/Devolutions/config/tabs';
import {
  getColumnsToDataTable,
  getDataTableStructure,
  getNoResultsConfig
} from 'app/modules/Devolutions/config/table';
import { useFiltersConfiguration } from 'app/modules/Devolutions/hooks/useFiltersConfiguration';

export const getDevolutionsDataTableConfiguration = ({ intl }) => ({
  contextId: 'devolutions',
  INITIAL_VALUES,
  noSelectRow: true,
  apiUrl: 'rma',
  tabs: getTabs(intl),
  useFiltersConfiguration,
  getColumnsToDataTable,
  getNoResultsConfig,
  getDataTableStructure
});
