import React from 'react';
import { useIntl } from 'react-intl';

import Button from 'app/components/Button';
import useRMAContext from 'app/modules/Devolutions/context/context';
import { patchRma } from 'app/modules/Devolutions/services';

export default function TableProductsHeader() {
  const intl = useIntl();
  const { canAskForDevolution, rmaId, initializeRma, isEdittingId } =
    useRMAContext();

  return (
    <Button
      size="big"
      text={
        <strong>
          {intl.formatMessage({
            id: 'controlpanel.rma.ask_for_devolution'
          })}
        </strong>
      }
      tooltipText={intl.formatMessage({
        id: 'controlpanel.rma.conditions_message'
      })}
      type="primary"
      disabled={!canAskForDevolution || isEdittingId}
      onClick={async () => {
        const response = await patchRma(rmaId);

        // initializeRma
        if (response?.status === 200) {
          initializeRma();
        }
      }}
    />
  );
}
