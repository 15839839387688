import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
  useCallback
} from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { isEqual } from 'lodash';

import useDatatableApi from 'app/components/DataTable/hooks/useDataTableApi';
import {
  parseFilters,
  saveInContextStorage as saveInContextStorageAPI,
  readContextStorage as readContextStorageAPI,
  updateContextStorage as updateContextStorageAPI,
  removeFromContextStorage as removeFromContextStorageAPI
} from 'app/components/DataTable/utils';
import { FiltersContext } from 'app/components/DataTable/context/FiltersContext';
import {
  removeSearchFilter,
  addNewFilterToRenderList
} from 'app/components/DataTableFilters/utils';
import { useDataTableSelectRow } from 'app/components/DataTable';
import {
  defaultPgOptions,
  defaultTableConfiguration
} from 'app/utils/defaultDataTableConfig';
import useDefaultFormatterTag from 'app/components/DataTableFiltersTags/hooks/useDefaultFormatterTag.hook';

const DataTableContext = createContext();

export const DataTableProvider = ({
  children,
  getTableContextConfiguration
}) => {
  const intl = useIntl();
  const {
    contextId = '',
    INITIAL_VALUES = {},
    noSelectRow = false,
    apiUrl = '',
    tabs = [],
    useFiltersConfiguration = null,
    getColumnsToDataTable,
    dataTableFiltersTagsConfiguration = {
      formatter: useDefaultFormatterTag
    },
    getNoResultsConfig,
    getDataTableStructure,
    tableConfiguration = defaultTableConfiguration,
    extraTableConfiguration = null,
    pgOptions = defaultPgOptions,
    getDropdownOptions = null,
    getCustomDropdownLogic = null,
    useDataTableCustomApi = null,
    validate = null
  } = useMemo(
    () => getTableContextConfiguration({ intl }),
    [getTableContextConfiguration, intl]
  );
  const [initialized, setInitialized] = useState(false);

  const [loading, setLoading] = useState(false);

  const [entities, setEntities] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState(pgOptions);
  const [previousPaginationOptions, setPreviousPaginationOptions] =
    useState(pgOptions);

  const [totalSize, setTotalSize] = useState(pgOptions?.totalSize);

  const [columns, setColumns] = useState(getColumnsToDataTable(intl));
  const { draggableColumns, nonDraggableColumns } = useMemo(
    () => ({
      draggableColumns: columns.filter((col) => col?.draggable),
      nonDraggableColumns: columns.filter((col) => !col?.draggable)
    }),
    [columns]
  );

  const [filters, setFilters] = useState({
    ...INITIAL_VALUES,
    ...tabs?.[0]?.filters
  });
  const parsedFilters = useMemo(() => parseFilters(filters), [filters]);
  const [prevParsedFilters, setPrevParsedFilters] = useState({});
  const parsedFiltersArray = useMemo(
    () => Object.entries(parsedFilters),
    [parsedFilters]
  );
  const activeFilters = useMemo(
    () => removeSearchFilter(parsedFilters),
    [parsedFilters]
  );
  const [renderedFilters, setRenderedFilters] = useState(
    Object.keys(activeFilters)
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const [currentTab, setCurrentTab] = useState(0);

  const [apiResponse, setApiResponse] = useState(null);

  const [contextStorage, setContextStorage] = useState({});

  const api = useMemo(
    () => useDataTableCustomApi || useDatatableApi,
    [useDataTableCustomApi]
  );

  const { fetchData } = api({
    paginationOptions,
    filters: parsedFilters,
    setLoading,
    entities,
    setEntities,
    setTotalSize,
    baseUrl: apiUrl,
    getDataTableStructure,
    setApiResponse
  });

  const submitFiltersToContext = useCallback(
    (newFilters) => {
      if (!isEqual(newFilters, filters)) {
        setFilters(newFilters);
      }
    },
    [filters]
  );

  const addFilters = (newFilters) => {
    setFilters((prev) => ({ prev, ...newFilters }));
  };

  const removeFilter = useCallback(
    (filterToRemove) => {
      setFilters((prev) => {
        const newFilters = { ...prev };
        const initialValue = INITIAL_VALUES[filterToRemove];
        newFilters[filterToRemove] = initialValue;
        return newFilters;
      });
    },
    [INITIAL_VALUES]
  );

  const resetFilters = useCallback(
    (newFilters = null) => {
      if (newFilters) {
        setFilters({ ...INITIAL_VALUES, ...newFilters });
      } else {
        setFilters(INITIAL_VALUES);
      }
    },
    [INITIAL_VALUES]
  );

  const removeFilterFromModal = useCallback((targetFilter) => {
    setRenderedFilters((prev) => {
      const cleanFilters = prev.filter((filter) => filter !== targetFilter);
      return cleanFilters;
    });
  }, []);

  const reorderColumns = useCallback(
    (newColumns) => {
      setColumns([...nonDraggableColumns, ...newColumns]);
    },
    [nonDraggableColumns]
  );

  useEffect(() => {
    const newOptions = {
      ...paginationOptions,
      totalSize
    };

    if (!isEqual(newOptions, paginationOptions)) {
      setPaginationOptions(newOptions);
    }
  }, [paginationOptions, totalSize]);

  useEffect(() => {
    // Trackeamos los diferentes casos en que debemos recargar los datos
    // de la tabla para evitar llamadas duplicadas a la API

    const filtersDidChange = !isEqual(parsedFilters, prevParsedFilters);
    const sortDidChange =
      JSON.stringify(previousPaginationOptions.defaultSorted) !==
      JSON.stringify(paginationOptions.defaultSorted);
    const pageDidChange =
      previousPaginationOptions.page !== paginationOptions.page;
    const resultsPerPageDidChange =
      previousPaginationOptions.sizePerPage !== paginationOptions.sizePerPage;
    const paginationDidChange =
      pageDidChange || resultsPerPageDidChange || sortDidChange;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const searchParams = Object.fromEntries(urlSearchParams.entries());
    // eslint-disable-next-line no-shadow
    const { addFilters } = searchParams;

    // Caso 1: Todavía no hemos iniciado el sistema. Necesitamos la primera
    // visualización de datos
    if (!initialized) {
      setInitialized(true);

      if (!addFilters) {
        fetchData();
      }
      return;
    }
    // Caso 2: Ha habido cambios en la tabla, pero no son relevantes para sus datos

    if (!filtersDidChange && !paginationDidChange) {
      return;
    }

    if (filtersDidChange) {
      // Caso 3: Se han aplicado filtros. Debemos recargar los datos, evitar
      // nuevas llamadas por filtros mientras no se apliquen nuevos y volver
      // a la página 1 de resultados
      setPaginationOptions({ ...paginationOptions, page: 1 });
      setPrevParsedFilters(parsedFilters);
      fetchData();
      return;
    }
    // Caso 4: El usuario ha cambiado de página o desea mostrar más/menos
    // resultados. Obtenemos los nuevos datos y bloqueamos futuras llamadas
    // por esta causa hasta que no se vuelva a cambiar de página/n.º de resultados
    if (paginationDidChange) {
      setPreviousPaginationOptions(paginationOptions);
      fetchData();
    }
  }, [
    fetchData,
    initialized,
    paginationOptions,
    parsedFilters,
    prevParsedFilters,
    previousPaginationOptions
  ]);

  const handleTabChange = useCallback(
    (_, value) => {
      const { filters: tabFilters = null, columns: tabColumns = null } =
        tabs.find(({ id }) => id === value);

      if (tabFilters) resetFilters(tabFilters);
      if (!tabFilters) resetFilters();

      if (tabColumns) setColumns(getColumnsToDataTable(intl, tabColumns));

      setCurrentTab(value);
      setPaginationOptions(pgOptions);
    },
    [tabs, resetFilters, getColumnsToDataTable, intl, pgOptions]
  );

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const searchParams = Object.fromEntries(urlSearchParams.entries());
    // eslint-disable-next-line no-shadow
    const { addFilters, ...queryParams } = searchParams;

    if (addFilters) {
      setFilters((prev) => ({ ...prev, ...queryParams }));
      // window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  const {
    selectRow: defaultSelectRow,
    selectAll,
    excludedRows,
    selectCondition,
    setSelectAll
  } = useDataTableSelectRow({
    selectedRows,
    setSelectedRows,
    keyField: paginationOptions.keyField,
    dropdownOptions: getDropdownOptions ? getDropdownOptions(intl) : null,
    dropdownLogic: getCustomDropdownLogic,
    entities
  });

  const selectRowConfig = useMemo(
    () => (noSelectRow ? false : defaultSelectRow),
    [defaultSelectRow, noSelectRow]
  );

  const saveInContextStorage = useCallback(
    (field, data) =>
      saveInContextStorageAPI({ field, data, setContextStorage }),
    []
  );

  const readContextStorage = useCallback(
    () => readContextStorageAPI({ contextStorage }),
    [contextStorage]
  );

  const removeFromContextStorage = useCallback(
    (field) => removeFromContextStorageAPI({ field, setContextStorage }),
    []
  );

  const updateContextStorage = useCallback(
    (field, data) =>
      updateContextStorageAPI({ field, data, setContextStorage }),
    []
  );

  const memoedValue = useMemo(
    () => ({
      api: {
        apiResponse,
        apiUrl,
        fetchData
      },
      context: {
        contextId,
        readContextStorage,
        removeFromContextStorage,
        saveInContextStorage,
        updateContextStorage
      },
      datatable: {
        entities,
        getNoResultsConfig,
        loading,
        paginationOptions,
        setPaginationOptions,
        tableConfiguration,
        extraTableConfiguration,
        totalSize,
        setTotalSize,
        columns: {
          columns,
          draggableColumns,
          nonDraggableColumns,
          reorderColumns,
          setColumns
        },
        rows: {
          selectedRows,
          setSelectedRows,
          selectRowConfig,
          selectAll,
          setSelectAll,
          excludedRows,
          selectCondition
        }
      },
      dropdown: {
        getCustomDropdownLogic,
        getDropdownOptions
      },
      filters: {
        activeFilters,
        addFilters,
        addNewFilterToRenderList,
        filters,
        INITIAL_VALUES,
        parsedFilters,
        parsedFiltersArray,
        removeFilter,
        removeFilterFromModal,
        removeSearchFilter,
        renderedFilters,
        resetFilters,
        setFilters,
        setRenderedFilters,
        submitFiltersToContext,
        useFiltersConfiguration
      },
      tags: {
        dataTableFiltersTagsConfiguration
      },
      tabs: {
        currentTab,
        handleTabChange,
        setCurrentTab,
        tabs
      }
    }),
    [
      activeFilters,
      apiResponse,
      apiUrl,
      columns,
      contextId,
      currentTab,
      dataTableFiltersTagsConfiguration,
      draggableColumns,
      entities,
      excludedRows,
      fetchData,
      filters,
      getCustomDropdownLogic,
      getDropdownOptions,
      getNoResultsConfig,
      handleTabChange,
      INITIAL_VALUES,
      loading,
      nonDraggableColumns,
      paginationOptions,
      parsedFilters,
      parsedFiltersArray,
      readContextStorage,
      removeFilter,
      removeFilterFromModal,
      removeFromContextStorage,
      renderedFilters,
      reorderColumns,
      resetFilters,
      saveInContextStorage,
      selectAll,
      setSelectAll,
      selectCondition,
      selectedRows,
      selectRowConfig,
      submitFiltersToContext,
      tableConfiguration,
      extraTableConfiguration,
      tabs,
      totalSize,
      updateContextStorage,
      useFiltersConfiguration
    ]
  );

  return (
    <DataTableContext.Provider value={memoedValue}>
      <FiltersContext validate={validate}>{children}</FiltersContext>
    </DataTableContext.Provider>
  );
};

const useDataTableContext = () => useContext(DataTableContext);

DataTableProvider.propTypes = {
  children: PropTypes.node.isRequired,
  getTableContextConfiguration: PropTypes.func.isRequired
};

export default useDataTableContext;
