import React from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { genericRequest } from 'app/api';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

import 'app/modules/Trackings/utils/formatters/FormatActions/FormatActions.scss';

const { bbSecondary, bbGrey2 } = Colors;

export const FormatActions = ({
  id: trackingId,
  orderId,
  read
  // carrierLink -> V2
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    api: { fetchData }
  } = useDataTableContext();

  const color = read ? bbGrey2 : bbSecondary;
  const envelopeIcon = read ? 'envelope-open-text' : 'envelope';

  const handleToggleRead = async () => {
    await genericRequest({
      url: `trackings/${trackingId}`,
      method: 'PATCH',
      data: {
        data: {
          id: trackingId,
          type: 'tracking',
          attributes: {
            read: !read
          }
        }
      },
      customSuccessFeedback: {
        variant: 'success',
        content: intl.formatMessage({
          id: 'controlpanel.feedback.save_changes.success'
        }),
        autoClose: true,
        dismissible: false
      }
    });

    await fetchData();
  };

  let tooltipText = intl.formatMessage({ id: 'controlpanel.global.mark_read' });

  if (read) {
    tooltipText = intl.formatMessage({
      id: 'controlpanel.global.mark_not_read'
    });
  }

  return (
    <div className="bb-trackings__data-table__cell__action-icons">
      <Button
        icon={<Icon icon="eye" color={color} prefix="fad" />}
        onClick={() => navigate(`/orders/view/${orderId}`)}
        size="medium"
        tooltipText={intl.formatMessage({
          id: 'controlpanel.global.view_order'
        })}
      />
      <Button
        icon={<Icon icon={envelopeIcon} color={color} prefix="fad" />}
        onClick={handleToggleRead}
        size="medium"
        tooltipText={tooltipText}
      />
    </div>
  );
};

FormatActions.propTypes = {
  id: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
  read: PropTypes.bool.isRequired
};
