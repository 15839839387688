/* eslint-disable react/no-unknown-property */
import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import Button from 'app/components/Button';
import useScript from 'app/hooks/useScript';

import 'app/modules/MyAccount/pages/PaymentMethods/components/PopupContent/AddCreditCardPopupContent.scss';

export function AddCreditCardHeader() {
  const intl = useIntl();
  return (
    <p className="title">
      {intl.formatMessage({
        id: 'controlpanel.payment_methods.popup.add_card'
      })}
    </p>
  );
}

export const AddCreditCardBody = forwardRef((_, ref) => {
  const intl = useIntl();

  useScript('https://api.paycomet.com/gateway/paycomet.jetiframe.js?lang=es');

  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const options = [];

    for (let i = 0; i < 10; i++) {
      const targetYear = Number(currentYear) + i;
      const lastTwoDigits = targetYear.toString().slice(+2);
      options.push(
        <option value={lastTwoDigits} key={lastTwoDigits}>
          {targetYear}
        </option>
      );
    }

    return options;
  }, []);

  return (
    <div className="add-credit-card-form">
      <form
        id="paycometPaymentForm"
        action={`${process.env.REACT_APP_CONTROLPANEL_URL}my-account/payment`}
        method="GET"
        ref={ref}
      >
        <input
          type="hidden"
          data-paycomet="jetID"
          value={process.env.REACT_APP_PAYCOMET_JET_ID}
        />
        <div className="add-credit-card-form__form-group">
          <label htmlFor="username">
            <p className="regular">
              {intl.formatMessage({
                id: 'controlpanel.my_account.payment_methods.add_card.name_label'
              })}
              <span className="add-credit-card-form__required-label"> *</span>
            </p>
          </label>
          <div className="add-credit-card-form__input-group">
            <input
              id="username"
              type="text"
              className="form-control"
              data-paycomet="cardHolderName"
              placeholder=""
              required=""
            />
          </div>
        </div>

        <div className="add-credit-card-form__form-group">
          <label htmlFor="cardNumber">
            <p className="regular">
              {intl.formatMessage({
                id: 'controlpanel.my_account.payment_methods.add_card.number_label'
              })}
              <span className="add-credit-card-form__required-label"> *</span>
            </p>
          </label>
          <div className="add-credit-card-form__input-group">
            <div id="paycomet-pan" style={{ padding: '0px', height: '36px' }} />
            <input
              paycomet-style="display: block;
              width: -webkit-fill-available;
              height: 20px;
              padding: 0.375rem 0.75rem;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5;
              color: #495057;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #ced4da;
              border-radius: 0.25rem;
              transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
              paycomet-name="pan"
              paycomet-placeholder=""
              id="cardNumber"
              className="form-control"
            />
          </div>
        </div>

        <div className="add-credit-card-form__horizontal-in-desktop">
          <div className="add-credit-card-form__form-group">
            <label htmlFor="cardDate">
              <p className="medium">
                <span className="hidden-xs">
                  {intl.formatMessage({
                    id: 'controlpanel.my_account.payment_methods.add_card.date_label'
                  })}
                  <span className="add-credit-card-form__required-label">
                    {' '}
                    *
                  </span>
                </span>
              </p>
            </label>
            <div className="add-credit-card-form__form-inline">
              <select
                className="add-credit-card-form__month form-control"
                data-paycomet="dateMonth"
                id="cardDate"
                defaultValue=""
              >
                <option value="" disabled hidden>
                  {intl.formatMessage({
                    id: 'controlpanel.global.month'
                  })}
                </option>
                <option value="01">
                  {`01 - ${intl.formatMessage({
                    id: 'controlpanel.global.month.january'
                  })}`}
                </option>
                <option value="02">
                  {`02 - ${intl.formatMessage({
                    id: 'controlpanel.global.month.february'
                  })}`}
                </option>
                <option value="03">
                  {`03 - ${intl.formatMessage({
                    id: 'controlpanel.global.month.march'
                  })}`}
                </option>
                <option value="04">
                  {`04 - ${intl.formatMessage({
                    id: 'controlpanel.global.month.april'
                  })}`}
                </option>
                <option value="05">
                  {`05 - ${intl.formatMessage({
                    id: 'controlpanel.global.month.may'
                  })}`}
                </option>
                <option value="06">
                  {`06 - ${intl.formatMessage({
                    id: 'controlpanel.global.month.june'
                  })}`}
                </option>
                <option value="07">
                  {`07 - ${intl.formatMessage({
                    id: 'controlpanel.global.month.july'
                  })}`}
                </option>
                <option value="08">
                  {`08 - ${intl.formatMessage({
                    id: 'controlpanel.global.month.august'
                  })}`}
                </option>
                <option value="09">
                  {`09 - ${intl.formatMessage({
                    id: 'controlpanel.global.month.september'
                  })}`}
                </option>
                <option value="10">
                  {`10 - ${intl.formatMessage({
                    id: 'controlpanel.global.month.october'
                  })}`}
                </option>
                <option value="11">
                  {`11 - ${intl.formatMessage({
                    id: 'controlpanel.global.month.november'
                  })}`}
                </option>
                <option value="12">
                  {`12 - ${intl.formatMessage({
                    id: 'controlpanel.global.month.december'
                  })}`}
                </option>
              </select>
              <select
                className="add-credit-card-form__year form-control"
                data-paycomet="dateYear"
                defaultValue=""
              >
                <option value="" disabled hidden>
                  {intl.formatMessage({
                    id: 'controlpanel.global.year'
                  })}
                </option>
                {yearOptions}
              </select>
            </div>
          </div>

          <div className="add-credit-card-form__form-group">
            <label htmlFor="cvv">
              <p className="medium">
                {intl.formatMessage({
                  id: 'controlpanel.my_account.payment_methods.add_card.cvv_label'
                })}
                <span className="add-credit-card-form__required-label"> *</span>
              </p>
            </label>
            <div
              id="paycomet-cvc2"
              style={{ height: '36px', padding: '0px' }}
            />
            <input
              paycomet-name="cvc2"
              paycomet-style="display: block;
            width: -webkit-fill-available;
            height: 20px;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
              paycomet-placeholder={intl.formatMessage({
                id: 'controlpanel.my_account.payment_methods.add_card.cvv_placeholder'
              })}
              className="form-control"
              required=""
              type="text"
              id="cvv"
            />
          </div>
        </div>
        {/*

        SOLO NECESARIO CUANDO SE PUEDAN AÑADIR VARIAS TARJETAS Y SE
        QUIERA GUARDAR ESTA COMO PREDEFINIDA
        
        <div className="add-credit-card-form__form-group add-credit-card-form__horizontal-in-desktop">
          <p className="medium add-credit-card-form__switch--title">
            {intl.formatMessage({
              id: 'controlpanel.my_account.payment_methods.add_card.save_as_default_title'
            })}
          </p>
          <Switch
            id="setAsDefault"
            name="setAsDefault"
            checked={formik?.values?.setAsDefault}
            onChange={({ id, checked }) => formik.setFieldValue(id, checked)}
            labelComponent={
              <p className="regular add-credit-card-form__switch--label">
                {intl.formatMessage({
                  id:
                    'controlpanel.my_account.payment_methods.add_card.save_as_default_label'
                })}
              </p>
            }
            formik={formik}
          />
        </div>
         */}
      </form>
      <div id="paymentErrorMsg" />
    </div>
  );
});

export function AddCreditCardFooter({ onClose }) {
  const intl = useIntl();
  const formik = useFormikContext();

  return (
    <div className="add-credit-card-form__actions">
      <Button
        text={intl.formatMessage({ id: 'controlpanel.global.back' })}
        className="add-credit-card-form__actions__back"
        onClick={onClose}
      />
      <Button
        text={intl.formatMessage({
          id: 'controlpanel.payment_methods.popup.add_card'
        })}
        type="primary"
        onClick={formik.submitForm}
      />
    </div>
  );
}

AddCreditCardFooter.propTypes = {
  onClose: PropTypes.func.isRequired
};
