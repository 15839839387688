import axios from 'axios';

// Step-1: Create a new Axios instance with a custom config.
const endPoint = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL
});

// Step-2: Create request, response & error handlers
const requestHandler = (request) => request;

const responseHandler = (response) => response;

const errorHandler = (error) => Promise.reject(error);

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
endPoint.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

endPoint.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export { endPoint };
