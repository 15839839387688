import React, { useCallback, useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { deposit } from 'app/modules/Purse/components/Modals';
import { genericRequest } from 'app/api';
import { formatCurrency } from 'app/utils/currency';
import Button from 'app/components/Button';
import classNames from 'classnames';
import Input from 'app/components/Input';
import Notification from 'app/components/Notification';
import Spinner from 'app/components/Spinner';
import useGetPermisionsSection from 'app/hooks/useGetPermisionsSection.hook';

export default function Deposit() {
  const intl = useIntl();
  const [show, setShow] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const adyenAllowed = useGetPermisionsSection('adyenMoneybox');
  const MIN_WALLET = 300;
  const BANKWIRE_ID = 5;

  const fetchData = useCallback(
    async (controller) => {
      if (adyenAllowed) {
        setLoading(true);
        setIsFirstLoad(false);
        const result = await genericRequest({
          url: 'payment-methods/moneybox',
          method: 'POST',
          data: {
            data: {
              type: 'payment-method-moneybox',
              attributes: {
                type: 'payment-method-moneybox',
                amount: amount < MIN_WALLET ? 0 : amount
              }
            }
          },
          signal: controller.signal
        });

        const adyenPaymentMethods = result?.data?.filter(
          (paymentMethod) => paymentMethod?.attributes?.id !== BANKWIRE_ID
        );

        setPaymentMethods(adyenPaymentMethods);
        setLoading(false);
      }
    },
    [adyenAllowed, amount]
  );

  const FormObserver = () => {
    const { values, setFieldValue } = useFormikContext();
    useEffect(() => {
      setAmount(Number(values?.amount));
      setFieldValue('idPaymentMethod', '');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.amount]);
    return null;
  };

  useEffect(() => {
    const controller = new AbortController();
    setTimeout(
      () => {
        fetchData(controller);
      },
      isFirstLoad ? 0 : 1000
    );

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, amount]);

  return (
    <div className="purse-modal-deposit">
      <Button
        onClick={() => setShow(true)}
        text={intl.formatMessage({
          id: 'controlpanel.purse.modal_deposit.button'
        })}
        type="primary"
        size="small"
      />
      <Formik
        initialValues={{ amount: '', idPaymentMethod: '' }}
        validationSchema={Yup.object().shape({
          amount: Yup.number()
            .test(
              'Min Value',
              intl.formatMessage(
                { id: 'controlpanel.form.validation.min_value' },
                { value: MIN_WALLET }
              ),
              (value) => {
                if (value < MIN_WALLET) {
                  return false;
                }
                return true;
              }
            )
            .required(
              intl.formatMessage({
                id: 'controlpanel.form.validation.required'
              })
            ),
          idPaymentMethod: Yup.number(
            intl.formatMessage({
              id: 'controlpanel.global.required_paymentmethod'
            })
          ).required(
            intl.formatMessage({
              id: 'controlpanel.global.required_paymentmethod'
            })
          )
        })}
        onSubmit={async (values) => {
          const response = await deposit(
            values?.idPaymentMethod,
            values?.amount
          );

          if (response?.status === 200) {
            if (response?.data?.data?.attributes?.paymentUrl !== null) {
              window.location.href =
                response?.data?.data?.attributes?.paymentUrl;
            } else {
              window.location.reload();
            }
          }

          return false;
        }}
      >
        {(formik) => (
          <Modal
            size="lg"
            show={show}
            onHide={() => {
              setShow(false);
              formik.resetForm();
            }}
            className="purse-modal-deposit-modal"
          >
            <Modal.Header>
              <p className="bold">
                {intl.formatMessage({
                  id: 'controlpanel.purse.modal_deposit.header'
                })}
              </p>
            </Modal.Header>
            <Modal.Body>
              <FormObserver />
              <Notification
                infoIcon={{ icon: 'info-square' }}
                variant="info"
                content={intl.formatMessage({
                  id: 'controlpanel.global.required_paymentmethod'
                })}
              />
              <Input
                type="number"
                name="amount"
                label={intl.formatMessage({
                  id: 'controlpanel.global.quantity'
                })}
                placeholder={intl.formatMessage({
                  id: 'controlpanel.purse.modal_deposit.input_placeholder'
                })}
                bottomComponent={
                  <p className="purse-bottom-label">
                    {intl.formatMessage({
                      id: 'controlpanel.purse.modal_deposit.input_bottom'
                    })}
                  </p>
                }
                textPrepend="€"
              />
              <p className="bold">
                {intl.formatMessage({
                  id: 'controlpanel.orders.filters.paymentmethod'
                })}
              </p>
              <div
                className={classNames('bankwire-payment', {
                  checked: formik?.values?.idPaymentMethod === BANKWIRE_ID
                })}
                onClick={() =>
                  formik.setFieldValue('idPaymentMethod', BANKWIRE_ID)
                }
                aria-hidden="true"
              >
                <p className="title">
                  {intl.formatMessage({
                    id: 'controlpanel.purse.modal_deposit.notification_title'
                  })}
                </p>
                <p className="subtitle">
                  {intl.formatMessage({
                    id: 'controlpanel.purse.modal_deposit.notification_content'
                  })}
                </p>
              </div>
              {adyenAllowed && (
                <>
                  <p className="bold">
                    {intl.formatMessage({
                      id: 'controlpanel.global.other_paymentmethod'
                    })}
                  </p>

                  {loading ? (
                    <Spinner />
                  ) : (
                    <>
                      {paymentMethods?.length === 0 && (
                        <p className="bold">
                          {intl.formatMessage({
                            id: 'controlpanel.global.error_no_paymentmethods'
                          })}
                        </p>
                      )}
                      <div className="other-payment-methods">
                        {paymentMethods?.map((paymentMethod) => (
                          <div
                            aria-hidden="true"
                            className={classNames(
                              'other-payment-methods__card',
                              {
                                checked:
                                  formik?.values?.idPaymentMethod ===
                                  paymentMethod?.attributes?.id
                              }
                            )}
                            key={paymentMethod?.attributes?.id}
                            onClick={() =>
                              formik.setFieldValue(
                                'idPaymentMethod',
                                paymentMethod?.attributes?.id
                              )
                            }
                          >
                            <img
                              src={`${process.env.REACT_APP_BIGBUY_URL}${paymentMethod?.attributes?.imageUrl}`}
                              alt={paymentMethod?.attributes?.paymentTitle}
                            />
                            <span>
                              {paymentMethod?.attributes?.paymentTitle}
                            </span>
                            {paymentMethod?.attributes?.commissionAmount && (
                              <span className="comision">
                                {`${intl.formatMessage({
                                  id: 'controlpanel.global.comision'
                                })} ${formatCurrency(
                                  paymentMethod?.attributes?.commissionAmount
                                )}`}
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="default"
                onClick={() => {
                  setShow(false);
                  formik.resetForm();
                }}
                text={intl.formatMessage({
                  id: 'controlpanel.global.cancel'
                })}
                size="big"
              />
              <Button
                type="primary"
                typeButton="submit"
                text={intl.formatMessage({
                  id: 'controlpanel.global.deposit'
                })}
                size="big"
                disabled={formik?.isSubmitting}
                icon={formik?.isSubmitting && <Spinner />}
                onClick={() => formik.submitForm()}
              />
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </div>
  );
}
