import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { getRedColumnsStatus } from 'app/hooks/useShopsStatus.hook';
import Icon from 'app/components/Icon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const FormatActions = ({ statusKey, id }) => {
  const intl = useIntl();
  const icon = getRedColumnsStatus().includes(statusKey) ? 'pencil' : 'eye';
  let tooltipText = intl.formatMessage({ id: 'controlpanel.global.edit_data' });

  if (icon === 'eye') {
    tooltipText = intl.formatMessage({ id: 'controlpanel.global.view_shop' });
  }

  return (
    <div className="bb-shops__data-table__cell__action-icon">
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>{tooltipText}</Tooltip>}
      >
        <Link to={id}>
          <Icon icon={icon} color={Colors.bbSecondary} />
        </Link>
      </OverlayTrigger>
    </div>
  );
};

FormatActions.propTypes = {
  id: PropTypes.string.isRequired,
  statusKey: PropTypes.string.isRequired
};
