import React from 'react';
import PropTypes from 'prop-types';

import { useWindow } from 'app/hooks/useWindow.hook';
import { SIZE } from 'app/utils/sizes';

import Promo from './Promo';
import Carousel from '../bigbuy-group-library/Carousel';

export default function Promos({ promos }) {
  const { width } = useWindow();

  const mobile = width < SIZE.LG;

  if (promos.length === 0) {
    return null;
  }

  if (promos.length === 1) {
    const promo = promos[0];
    return (
      <Promo
        buttons={promo.buttons}
        image={mobile ? promo?.mobileImage.url : promo?.desktopImage.url}
      />
    );
  }

  return (
    <Carousel>
      {promos.map((x) => (
        <Promo
          buttons={x.buttons}
          image={mobile ? x?.mobileImage.url : x?.desktopImage.url}
        />
      ))}
    </Carousel>
  );
}

Promos.propTypes = {
  promos: PropTypes.arrayOf(
    PropTypes.shape({
      mobileImage: PropTypes.shape({
        url: PropTypes.string
      }),
      desktopImage: PropTypes.shape({
        url: PropTypes.string
      }),
      buttons: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          url: PropTypes.string
        })
      ).isRequired
    })
  )
};
