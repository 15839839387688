import PropTypes from 'prop-types';
import { Colors as colors } from 'app/utils/colors';

export const colorsTypes = {
  marketplaces: {
    hedaderBG: colors.bbGreenMedium,
    hedaderTheme: colors.bbGreen,
    dateBG: colors.bbGreenLight,
    dateIcon: colors.bbGreen,
    dateText: colors.bbGreen
  },
  b2b: {
    hedaderBG: colors.bbOrangeLight,
    hedaderTheme: colors.bbOrange,
    dateBG: colors.bbOrangeLight,
    dateIcon: colors.bbOrange,
    dateText: colors.bbOrange
  },
  ecommerce: {
    hedaderBG: colors.bbPrimaryLight,
    hedaderTheme: colors.bbPrimaryDark,
    dateBG: colors.bbPrimaryLight,
    dateIcon: colors.bbPrimaryDark,
    dateText: colors.bbPrimaryDark
  },
  without: {
    hedaderBG: colors.bbSecondaryLight,
    hedaderTheme: colors.bbSecondary,
    dateBG: colors.bbSecondaryLight,
    dateIcon: colors.bbSecondary,
    dateText: colors.bbSecondary
  }
};

export const getColorOfType = (type = 'without') => colorsTypes[type];

export const colorsTypePropType = PropTypes.shape({
  hedaderBG: PropTypes.string,
  hedaderTheme: PropTypes.string,
  dateBG: PropTypes.string,
  dateIcon: PropTypes.string,
  dateText: PropTypes.string
});
