import React, { useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { filter, size } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { URLS } from 'app/config/urls';
import { useSubheader } from 'app/context/SubheaderContext';
import getPackColor from 'app/utils/parsers/getPackColor';
import ProfileCard from 'app/components/ProfileCard';
import useNotificationsServiceHook from 'app/hooks/useNotificationsService.hook';
import UserNotificationAlert from 'app/components/UserNotificationAlert';

import 'app/modules/MyAccount/components/MyAccountLayout.scss';

export default function MyAccountLayout({
  currentPagePath = null,
  children = null
}) {
  const navigate = useNavigate();
  const intl = useIntl();
  const { setTitle, setIcon, setBreadcrumbs } = useSubheader();

  const [viewSubSection, titleTranslationKey] = useMemo(() => {
    const { pathname } = window.location;
    const pathsArray = pathname.split('/');
    const pathLastFragment = pathsArray[pathsArray.length - 1];

    const subSection =
      breadcrumbsTitlesMap?.[pathLastFragment] || pathLastFragment;
    const translationKey =
      breadcrumbsTitlesMap?.[pathLastFragment] ||
      'controlpanel.breadcrumbs.my-account';

    return [subSection, translationKey];
  }, []);

  useLayoutEffect(() => {
    setTitle(intl.formatMessage({ id: titleTranslationKey }));
    setIcon({ icon: 'user' });
    setBreadcrumbs([
      {
        pathname: '/my-account',
        title: intl.formatMessage({ id: 'controlpanel.breadcrumbs.my-account' })
      },
      {
        pathname: window.location.pathname,
        title: intl.formatMessage({ id: viewSubSection })
      }
    ]);
  }, [
    intl,
    setTitle,
    setBreadcrumbs,
    setIcon,
    viewSubSection,
    titleTranslationKey
  ]);

  const user = useSelector((state) => state.auth.user);
  const { attributes } = user;

  const myAccountRoutes = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'controlpanel.my_account_profile_card.summary'
        }),
        icon: 'user',
        path: `${URLS.myAccount}/${URLS.myAccountSummary}`
      },
      {
        title: intl.formatMessage({
          id: 'controlpanel.my_account_profile_card.user_data'
        }),
        icon: 'edit',
        path: `${URLS.myAccount}/${URLS.myAccountUserData}`
      },
      {
        title: intl.formatMessage({
          id: 'controlpanel.my_account_profile_card.password'
        }),
        icon: 'shield-halved',
        path: `${URLS.myAccount}/${URLS.myAccountPassword}`
      },
      {
        title: intl.formatMessage({
          id: 'controlpanel.my_account_profile_card.information'
        }),
        icon: 'square-info',
        path: `${URLS.myAccount}/${URLS.myAccountInformation}`
      },
      {
        title: intl.formatMessage({
          id: 'controlpanel.my_account_profile_card.payment_methods'
        }),
        icon: 'credit-card',
        path: `${URLS.myAccount}/${URLS.myAccountPaymentMethods}`
      },
      {
        title: intl.formatMessage({
          id: 'controlpanel.my_account_profile_card.billing'
        }),
        icon: 'database',
        path: `${URLS.myAccount}/${URLS.myAccountBilling}`
      },
      {
        title: intl.formatMessage({
          id: 'controlpanel.my_account_profile_card.synchronize'
        }),
        icon: 'gear',
        path: `${URLS.myAccount}/${URLS.myAccountSyncronize}`
      },
      {
        title: intl.formatMessage({
          id: 'controlpanel.my_account_profile_card.wholesale_pack'
        }),
        icon: 'cube',
        path: `${URLS.myAccount}/${URLS.myAccountWholesalerPack}`
      },
      {
        title: intl.formatMessage({
          id: 'controlpanel.my_account_profile_card.notifications'
        }),
        icon: 'envelope',
        path: `${URLS.myAccount}/${URLS.myAccountNotifications}`
      }
    ],
    [intl]
  );

  const {
    notifications: { alerts }
  } = useNotificationsServiceHook();
  const alertsFiltered = filter(
    alerts,
    (alert) => alert?.attributes?.section === 'ACCOUNT'
  );

  return (
    <Row className="bb-my-accout-layout">
      {size(alertsFiltered) > 0 && (
        <Col md={12}>
          <UserNotificationAlert alerts={alertsFiltered} />
        </Col>
      )}

      <Col md={4} className="equal-heigth">
        <ProfileCard
          title={attributes.name}
          id={user.id}
          emailText={`${intl.formatMessage({
            id: 'controlpanel.global.email'
          })}:`}
          email={attributes.email}
          tag={{
            id: attributes.packId,
            text: attributes.packName,
            color: getPackColor(attributes.packId)
          }}
          routes={myAccountRoutes}
          currentPagePath={`${URLS.myAccount}/${currentPagePath}`}
          onRedirect={(path) => navigate(path, { replace: true })}
          onClickedTag={() => {}}
        />
      </Col>
      {children}
    </Row>
  );
}

const breadcrumbsTitlesMap = {
  resume: 'controlpanel.my_account_profile_card.summary',
  'user-data': 'controlpanel.my_account_profile_card.user_data',
  password: 'controlpanel.my_account_profile_card.password',
  information: 'controlpanel.my_account_profile_card.information',
  payment: 'controlpanel.my_account_profile_card.payment_methods',
  billing: 'controlpanel.my_account_profile_card.billing',
  syncronize: 'controlpanel.my_account_profile_card.synchronize',
  'wholesaler-pack': 'controlpanel.my_account_profile_card.wholesale_pack',
  notifications: 'controlpanel.my_account_profile_card.notifications'
};

MyAccountLayout.propTypes = {
  currentPagePath: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
