import React from 'react';

import { getSelectDefaultValues, Select } from 'app/components/Select';
import { SelectDisable } from 'app/components/DataTableFilters/Components';
import RangeSlider from 'app/components/RangeSlider';
import SelectDateRange from 'app/components/SelectDateRange';

export const INITIAL_VALUES = {
  date: {
    start: '',
    end: ''
  },
  concept: '',
  amount: [],
  search: ''
};

const optionsSelectConcept = (intl) => [
  {
    value: 'DEPOSIT',
    label: intl.formatMessage({
      id: 'controlpanel.purse.table.column.concept.deposit'
    })
  },
  {
    value: 'WITHDRAWAL',
    label: intl.formatMessage({
      id: 'controlpanel.purse.table.column.concept.withdrawal'
    })
  }
];

export const getFiltersConfiguration = ({ intl, formik, maxTotalAmount }) => [
  {
    value: 'all',
    label: intl.formatMessage({ id: 'controlpanel.global.all' }),
    disabled: false,
    component: (
      <SelectDisable
        text={intl.formatMessage({
          id: 'controlpanel.global.placeholder.select'
        })}
      />
    )
  },
  {
    value: 'date',
    label: intl.formatMessage({ id: 'controlpanel.purse.filters.date' }),
    disabled: false,
    component: (
      <SelectDateRange
        nameStartDate="date.start"
        nameEndDate="date.end"
        dateFormat="yyyy-MM-dd"
        defaultStartDate={formik.values?.date?.start}
        defaultEndDate={formik.values?.date?.end}
      />
    )
  },
  {
    value: 'concept',
    label: intl.formatMessage({ id: 'controlpanel.purse.filters.concept' }),
    disabled: false,
    component: (
      <Select
        formik={formik}
        name="concept"
        id="concept"
        defaultValue={getSelectDefaultValues(optionsSelectConcept(intl), [
          formik.values?.concept
        ])}
        options={optionsSelectConcept(intl)}
      />
    )
  },
  {
    value: 'amount',
    label: intl.formatMessage({ id: 'controlpanel.purse.filters.amount' }),
    disabled: false,
    component: (
      <RangeSlider
        initialValue={
          formik.values?.amount?.length === 2
            ? formik.values?.amount
            : [0, maxTotalAmount]
        }
        min={0}
        max={maxTotalAmount}
        name="amount"
      />
    )
  }
];
