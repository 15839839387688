import React from 'react';

import classNames from 'classnames';

import { BLUE_ALERT, RED_ALERT } from 'app/modules/Orders/utils';
import { formatCurrency } from 'app/utils/currency';

export const formatAmount = ({ text, status }) => {
  const classes = classNames('bb-orders__data-table__cell small', {
    red: RED_ALERT.includes(status),
    blue: BLUE_ALERT.includes(status)
  });

  return <p className={classes}>{formatCurrency(text)}</p>;
};
