export const selectedOnClick = (id, info, context, setContext) => {
  let newArray = context?.selected || [];

  if (newArray.includes(id)) {
    newArray = newArray.filter((value) => value !== id);
  } else {
    newArray.push(id);
  }

  setContext((prevState) => ({
    ...prevState,
    selected: newArray,
    info
  }));
};
