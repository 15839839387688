import React from 'react';

import { getMultipleSelectDefaultValues, Select } from 'app/components/Select';
import RangeSlider from 'app/components/RangeSlider';
import { SelectDisable } from 'app/components/DataTableFilters/Components';
import SelectDateRange from 'app/components/SelectDateRange';

export const INITIAL_VALUES = {
  date: {
    start: '',
    end: ''
  },
  carrier: [],
  status: [],
  expedition_date: {
    start: '',
    end: ''
  },
  is_read: ''
};

export const getFiltersConfiguration = ({
  intl,
  formik,
  statusOptions,
  maxTotal
}) => [
  {
    value: 'all',
    label: intl.formatMessage({ id: 'controlpanel.global.all' }),
    disabled: false,
    component: (
      <SelectDisable
        text={intl.formatMessage({
          id: 'controlpanel.global.placeholder.select'
        })}
      />
    )
  },
  {
    value: 'date',
    label: intl.formatMessage({ id: 'controlpanel.global.date' }),
    disabled: false,
    component: (
      <SelectDateRange
        nameStartDate="date.start"
        nameEndDate="date.end"
        dateFormat="yyyy-MM-dd"
        defaultStartDate={formik.values?.date?.start}
        defaultEndDate={formik.values?.date?.end}
      />
    )
  },
  {
    value: 'status',
    label: intl.formatMessage({
      id: 'controlpanel.global.filter.status'
    }),
    disabled: false,
    component: (
      <Select
        multiple
        formik={formik}
        name="status"
        id="status"
        defaultValue={getMultipleSelectDefaultValues(statusOptions, [
          formik.values?.status
        ])}
        options={statusOptions}
      />
    )
  },
  {
    value: 'amount',
    label: intl.formatMessage({
      id: 'controlpanel.global.amount'
    }),
    disabled: false,
    component: (
      <RangeSlider
        initialValue={
          formik.values?.amount?.length === 2
            ? formik.values?.amount
            : [0, maxTotal]
        }
        min={0}
        max={maxTotal}
        name="amount"
      />
    )
  }
];

export const clearField = (formik, field) => formik.setFieldValue(field, '');

export const submitFilters = ({ values, setFilters }) => {
  if (!values) {
    return;
  }

  const formValuesArray = Object.entries(values);
  const newConfig = {};

  formValuesArray.forEach(([key, value]) => {
    if (
      // No guardamos valores vacíos
      !value ||
      // Tampoco guardamos objetos que no tengan valores asignados
      (typeof value === 'object' && Object.values(value)?.length) ||
      // Tampoco guardamos arrays sin contenido
      (Array.isArray(value) && !value?.length)
    ) {
      return;
    }
    // El resto sí se guarda
    newConfig[key] = value;
  });

  setFilters(newConfig);
};
