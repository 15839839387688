import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Layout } from 'app/layout';
import BasePage from 'app/config/Routes/BasePage';

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
  );

  return (
    <div>
      {!isAuthorized ? (
        <div />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </div>
  );
}
