import React from 'react';
import PropTypes from 'prop-types';

import EcommerceConnectorCard from 'app/components/EcommerceConnectorCard';

import 'app/components/ConnectorList/ConnectorList.scss';

export default function ConnectorList({ title = '', connectors = [] }) {
  return (
    <div className="bb-connector-list">
      <div className="bb-connector-list__container">
        <h5 className="bold">{title}</h5>

        {connectors.map((connector) => (
          <div
            key={connector.id}
            className="bb-connector-list__container__card"
          >
            <EcommerceConnectorCard
              id={connector.id}
              image={connector.image}
              onClick={connector.onClick}
              purchased={connector.purchased}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const EcommerceConnectorCardItem = PropTypes.shape({
  border: PropTypes.bool,
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  purchased: PropTypes.arrayOf(PropTypes.string)
});

ConnectorList.propTypes = {
  title: PropTypes.string,
  connectors: PropTypes.arrayOf(EcommerceConnectorCardItem)
};
