import { map } from 'lodash';
import { useIntl } from 'react-intl';

export default function useDevolutionsStatus() {
  const intl = useIntl();

  return {
    DRAFT: {
      status: 'DRAFT',
      translation: intl.formatMessage({
        id: 'controlpanel.devolutions.status.draft'
      }),
      badgeColor: 'red'
    },
    PENDING_CONFIRMATION: {
      status: 'PENDING_CONFIRMATION',
      translation: intl.formatMessage({
        id: 'controlpanel.devolutions.status.pending_confirmation'
      }),
      badgeColor: 'purple'
    },
    PENDING_PICK_UP_ADDRESS: {
      status: 'PENDING_PICK_UP_ADDRESS',
      translation: intl.formatMessage({
        id: 'controlpanel.devolutions.status.pending_pick_up_address'
      }),
      badgeColor: 'red'
    },
    PENDING_COLLECTION: {
      status: 'PENDING_COLLECTION',
      translation: intl.formatMessage({
        id: 'controlpanel.devolutions.status.pending_collection'
      }),
      badgeColor: 'purple'
    },
    PENDING_VALUATION: {
      status: 'PENDING_VALUATION',
      translation: intl.formatMessage({
        id: 'controlpanel.devolutions.status.pending_valuation'
      }),
      badgeColor: 'purple'
    },
    PENDING_PAYMENT: {
      status: 'PENDING_PAYMENT',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.pending_payment'
      }),
      badgeColor: 'purple'
    },
    PAID: {
      status: 'PAID',
      translation: intl.formatMessage({
        id: 'controlpanel.devolutions.status.paid'
      }),
      badgeColor: 'green'
    },
    REJECTED: {
      status: 'REJECTED',
      translation: intl.formatMessage({
        id: 'controlpanel.global.rejected'
      }),
      badgeColor: 'red'
    }
  };
}

export const GetSelectStructure = () => {
  const orderStatus = useDevolutionsStatus();
  const selectStructure = [];

  map(orderStatus, (data, status) => {
    selectStructure.push({
      value: status,
      label: data.translation
    });
  });

  return selectStructure;
};
