import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { PACK_TYPE_NUMBER } from 'app/utils/constants';

import 'app/components/PackContainerBlock/PackContainerBlock.scss';

export default function PackContainerBlock({ packId }) {
  const intl = useIntl();
  let name = '';

  switch (packId) {
    case PACK_TYPE_NUMBER?.ECOMMERCE:
      name = 'Ecommerce';
      break;

    case PACK_TYPE_NUMBER?.MARKETPLACES:
      name = 'Marketplaces';
      break;

    default:
      break;
  }

  return (
    <div className={`pack-container-block ${name.toLowerCase()}`}>
      <div className="pack-container-block__name">
        <p>
          {intl.formatMessage({
            id: 'controlpanel.my_account_profile_card.wholesale_pack'
          })}
        </p>
        <p className="bold">{name}</p>
      </div>
    </div>
  );
}

PackContainerBlock.propTypes = {
  packId: PropTypes.number.isRequired
};
