import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  orderMessagesByDateAsc,
  getFirstTwoElements
} from 'app/modules/Devolutions/components/ChatMessage/utils/transformMessages';

import ChatMessageWithActions from 'app/modules/Devolutions/components/ChatMessage/components/ChatMessageWithActions';

export default function ChatMessagesList({ messages = [], rmaLineId }) {
  const [showingAll, setShowingAll] = useState(false);
  const [visibleMessages, setVisibleMessages] = useState([]);
  const [expandable, setExpandable] = useState(false);
  const [isTextAreaEnabled, setIsTextAreaEnabled] = useState(false);

  const ordered = useMemo(() => orderMessagesByDateAsc(messages), [messages]);
  const canAnswerLine = useMemo(
    () => ordered[ordered.length - 1].attributes.admin,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ordered, ordered.length]
  );

  const handleExpand = () => setShowingAll(true);
  const handleToggleComment = (value) =>
    setIsTextAreaEnabled((prev) => Boolean(value) || !prev);

  useEffect(() => {
    if (ordered?.length >= 2 && !showingAll) {
      setVisibleMessages(getFirstTwoElements(ordered));
      setExpandable(true);
      return;
    }
    setVisibleMessages(ordered);
    setExpandable(false);
  }, [ordered, showingAll]);

  return (
    <div className="devolution-chat">
      {visibleMessages.map((msg, index, array) => (
        <ChatMessageWithActions
          {...msg}
          key={index}
          index={index}
          length={array.length}
          expandable={expandable}
          handleExpand={handleExpand}
          fullLength={messages.length}
          isTextAreaEnabled={isTextAreaEnabled}
          handleToggleComment={handleToggleComment}
          rmaLineId={rmaLineId}
          canAnswerLine={canAnswerLine}
        />
      ))}
    </div>
  );
}

ChatMessagesList.propTypes = {
  rmaLineId: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape())
};
