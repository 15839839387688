import React, { isValidElement } from 'react';

import classNames from 'classnames';

import Button from 'app/components/Button';
import { ACTIONS_PROPS } from 'app/components/Notification/utils';

export default function NotificationActions({
  actions = null,
  className = null,
  children = null,
  button
}) {
  let componentContent;

  if (children && isValidElement(children)) {
    componentContent = children;
  }

  if (actions && isValidElement(actions)) {
    componentContent = actions;
  }

  if (!componentContent) {
    componentContent = <Button {...button} />;
  }

  const classes = classNames('bb-notification__actions', className);

  return <div className={classes}>{componentContent}</div>;
}

NotificationActions.propTypes = ACTIONS_PROPS;
