/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SVG from 'react-inlinesvg';
import { Field, useFormikContext } from 'formik';

import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';
import Dropdown from 'app/components/Dropdown/Dropdown';

import 'app/components/Checkbox/Checkbox.scss';

export default function Checkbox({
  label = '',
  value = '',
  checked = false,
  dropdown = false,
  disabled = false,
  multiple = false,
  showError = true,
  options = [],
  svg = '/media/svg/bigbuy/large/caret-down.svg',
  onUpdateChecks = () => null,
  id,
  name,
  type,
  bootstrap
}) {
  const formik = useFormikContext();
  const [show, setShow] = useState(false);

  const onCheckSelected = () => {
    onUpdateChecks(!checked);
  };

  let input = (
    <Field
      id={id}
      name={name}
      type={type}
      className="form-check-input"
      value={value}
      disabled={disabled}
    />
  );

  if (!value) {
    input = (
      <Field id={id} name={name} type={type} className="form-check-input" />
    );
  }

  if (bootstrap) {
    input = (
      <input
        id={id}
        name={name}
        type={type}
        className="form-check-input"
        disabled={disabled}
        checked={checked}
        onChange={onCheckSelected}
      />
    );
  }

  return (
    <div className="form-check form-check-custom form-check-solid bb-checkbox">
      {input}
      {dropdown && (
        <>
          <SVG
            src={toAbsoluteUrl(svg)}
            className="dropdown-tooltip"
            onClick={() => setShow(!show)}
          />
          <Dropdown show={show} options={options} onSelected={onUpdateChecks} />
        </>
      )}
      {label && (
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      )}
      {label && !multiple && formik.touched[name] && formik.errors[name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{formik.errors[name]}</div>
        </div>
      ) : null}
      {label &&
      multiple &&
      showError &&
      formik.touched[name] &&
      formik.errors[name] ? (
        <div className="fv-plugins-message-container-multiple">
          <div className="fv-help-block-multiple">{formik.errors[name]}</div>
        </div>
      ) : null}
    </div>
  );
}

const OptionItem = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  value: PropTypes.bool
});

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  label: PropTypes.node,
  value: PropTypes.string,
  dropdown: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  showError: PropTypes.bool,
  options: PropTypes.arrayOf(OptionItem),
  checked: PropTypes.bool,
  svg: PropTypes.string,
  onUpdateChecks: PropTypes.func
};
