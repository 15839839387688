import React from 'react';
import PropTypes from 'prop-types';

import { map, size } from 'lodash';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Colors } from 'app/utils/colors';
import { markAsRead } from 'app/api/notifications';
import { setNotificationsRead } from 'app/utils/notifications';
import { useIntl } from 'react-intl';
import Icon from 'app/components/Icon';
import useNotificationsServiceHook from 'app/hooks/useNotificationsService.hook';
import useNotificationsTypes from 'app/hooks/useNotificationsTypes.hook';

import 'app/components/UserNotificationsBody/UserNotificationsBody.scss';

export default function UserNotificationsBody({
  type = 'dropdown',
  height = 300
}) {
  const intl = useIntl();
  const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false
  };

  const {
    notifications: { notifications },
    setNotifications
  } = useNotificationsServiceHook();
  const types = useNotificationsTypes();
  const existsNotifications = size(notifications) > 0;

  return (
    <PerfectScrollbar
      options={perfectScrollbarOptions}
      style={{ maxHeight: `${height}px`, position: 'relative' }}
      className={`user-notifications ${type}`}
    >
      <div className="user-notifications-body">
        {existsNotifications ? (
          map(notifications, (notification, index) => {
            const { text, title, section, link, read } =
              notification?.attributes;
            const { icon } = types[section];

            const handleClick = async () => {
              if (!read) {
                const newNotifications = setNotificationsRead(
                  notifications,
                  notification?.id
                );

                setNotifications((prevState) => ({
                  ...prevState,
                  notifications: [...newNotifications]
                }));
              }

              await markAsRead(notification?.id);

              if (link) {
                window.open(link, '_SELF');
              }
            };

            return (
              <div
                tabIndex={0}
                role="button"
                onClick={handleClick}
                onKeyDown={handleClick}
                key={index}
                className={classNames('user-notifications-body__item', {
                  read
                })}
              >
                <div className="user-notifications-body__item-content">
                  <div className="notification-icon">
                    <Icon icon={icon} color="#F2B801" size="lg" />
                  </div>
                  <div className="notification-text">
                    <p className="bold notification-text__title">{title}</p>
                    <p className="small notification-text__description">
                      {text}
                    </p>
                  </div>
                </div>
                <div className="user-notifications-body__item-button">
                  {link && (
                    <Icon
                      icon="arrow-right"
                      color="#F2B801"
                      size="lg"
                      prefix="fas"
                    />
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="user-notifications-body__empty">
            <Icon icon="bell-on" color={Colors.bbGrey5} size="7x" />
            <p className="big">
              {intl.formatMessage({
                id: 'controlpanel.global.without_notifications'
              })}
            </p>
          </div>
        )}
      </div>
    </PerfectScrollbar>
  );
}

UserNotificationsBody.propTypes = {
  type: PropTypes.oneOf(['card', 'dropdown']),
  height: PropTypes.number
};
