import PropTypes from 'prop-types';

export const DEFAULT_PROPS = {
  onClick: () => null,
  purchased: []
};

export const PROP_TYPES = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  purchased: PropTypes.arrayOf(PropTypes.string)
};
