import React from 'react';
import PropTypes from 'prop-types';

import 'app/components/Chip/Chip.scss';

export default function Chip({ color = '#73AF00', disabled = false, text }) {
  return (
    <p
      className={disabled ? 'chip disabled' : 'chip'}
      style={{
        backgroundColor: color
      }}
    >
      {text}
    </p>
  );
}

Chip.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.string
};
