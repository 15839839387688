import { useEffect, useLayoutEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { URLS } from 'app/config/urls';
import { useSubheader } from 'app/context/SubheaderContext';

export default function useBreadcrumbs() {
  const { section } = useParams();

  const subheader = useSubheader();
  const { setTitle, setBreadcrumbs, setIcon } = subheader;
  //   console.log('section', section);

  const intl = useIntl();
  const [newBreadCrumbs, setNewBreadcrumbs] = useState([
    {
      pathname: URLS.downloadsMKT,
      title: intl.formatMessage({
        id: 'controlpanel.asidemenu.downloads'
      })
    }
  ]);
  const [newTitle, setNewTitle] = useState(
    intl.formatMessage({
      id: 'controlpanel.asidemenu.downloads'
    })
  );

  useEffect(() => {
    switch (section) {
      case 'marketing-resources':
        setNewBreadcrumbs([
          {
            pathname: URLS.downloadsMKT,
            title: intl.formatMessage({
              id: 'controlpanel.asidemenu.downloads'
            })
          },
          {
            pathname: URLS.downloadsMKT,
            title: intl.formatMessage({
              id: 'controlpanel.downloads.mkt_resources'
            })
          }
        ]);

        setNewTitle(
          intl.formatMessage({
            id: 'controlpanel.downloads.mkt_resources'
          })
        );
        break;
      case 'social-selling-resources':
        setNewBreadcrumbs([
          {
            pathname: URLS.downloadsSocialSelling,
            title: intl.formatMessage({
              id: 'controlpanel.asidemenu.downloads'
            })
          },
          {
            pathname: URLS.downloadsSocialSelling,
            title: intl.formatMessage({
              id: 'controlpanel.downloads.social_selling_resources'
            })
          }
        ]);
        setNewTitle(
          intl.formatMessage({
            id: 'controlpanel.downloads.social_selling_resources'
          })
        );
        break;
      case 'banners-newsletters':
        setNewBreadcrumbs([
          {
            pathname: URLS.downloadsBanners,
            title: intl.formatMessage({
              id: 'controlpanel.asidemenu.downloads'
            })
          },
          {
            pathname: URLS.downloadsBanners,
            title: intl.formatMessage({
              id: 'controlpanel.downloads.banners_newsletters'
            })
          }
        ]);
        setNewTitle(
          intl.formatMessage({
            id: 'controlpanel.downloads.banners_newsletters'
          })
        );
        break;
      case 'products-manuals':
        setNewBreadcrumbs([
          {
            pathname: URLS.downloadsManuals,
            title: intl.formatMessage({
              id: 'controlpanel.asidemenu.downloads'
            })
          },
          {
            pathname: URLS.downloadsManuals,
            title: intl.formatMessage({
              id: 'controlpanel.downloads.product_manuals'
            })
          }
        ]);
        setNewTitle(
          intl.formatMessage({
            id: 'controlpanel.downloads.product_manuals'
          })
        );
        break;
      case 'csv-xml-files':
        setNewBreadcrumbs([
          {
            pathname: URLS.downloadsCSV,
            title: intl.formatMessage({
              id: 'controlpanel.asidemenu.downloads'
            })
          },
          {
            pathname: URLS.downloadsCSV,
            title: intl.formatMessage({
              id: 'controlpanel.downloads.csv_xml_files'
            })
          }
        ]);
        setNewTitle(
          intl.formatMessage({
            id: 'controlpanel.downloads.csv_xml_files'
          })
        );
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  useLayoutEffect(() => {
    setTitle(newTitle);
    setIcon({ icon: 'download' });
    setBreadcrumbs(newBreadCrumbs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTitle, setIcon, setBreadcrumbs, newBreadCrumbs, intl]);

  return { newTitle, newBreadCrumbs };
}
