import React from 'react';

import { Colors } from 'app/utils/colors';
import {
  languages,
  CURRENCIES /* SHOP_SHIPMENT_STATUS */
} from 'app/utils/constants';
import Icon from 'app/components/Icon';
import { getFiltersByFieldName } from 'app/api/generalServices/filters';
import { getSelectStructure } from 'app/utils/filters';
import { filter } from 'lodash';

export const USER_INPUTS = [
  {
    paragraph: 'controlpanel.shopify_form.label.name',
    input: {
      name: 'shopName',
      placeholder: 'controlpanel.shopify_form.label.name'
    }
  },
  {
    paragraph: 'controlpanel.shopify_form.label.domain',
    input: {
      name: 'domain',
      placeholder: 'controlpanel.shopify_form.label.domain',
      textAppend: '',
      disabled: true
    }
  },
  {
    paragraph: 'controlpanel.shopify_form.label.main_language',
    input: {
      type: 'select',
      options: languages.map((language) => ({
        value: language.lang,
        label: language.name
      })),
      name: 'mainLanguage',
      placeholder: 'controlpanel.shopify_form.placeholder.language',
      textAppend: <Icon icon="language" color={Colors.bbGrey4} size="1x" />
    }
  },
  {
    paragraph: 'controlpanel.shopify_form.label.languages',
    input: {
      type: 'select',
      options: languages.map((language) => ({
        value: language.lang,
        label: language.name
      })),
      name: 'languages',
      placeholder: 'controlpanel.shopify_form.placeholder.languages',
      textAppend: <Icon icon="language" color={Colors.bbGrey4} size="1x" />
    }
  },
  {
    paragraph: 'controlpanel.shopify_form.label.currency',
    input: {
      type: 'select',
      options: CURRENCIES.map((currency) => ({
        value: currency.code,
        label: `${currency.code} - ${currency.name}`
      })),
      name: 'currency',
      placeholder: 'controlpanel.shopify_form.placeholder.currency',
      textAppend: <Icon icon="coin" color={Colors.bbGrey4} size="1x" />
    }
  },
  {
    paragraph: 'controlpanel.shopify_form.label.email',
    input: {
      name: 'email',
      placeholder: 'controlpanel.global.email',
      textPrepend: '@'
    }
  },
  {
    paragraph: 'controlpanel.shopify_form.label.confirmEmail',
    input: {
      name: 'confirmEmail',
      placeholder: 'controlpanel.global.email',
      textPrepend: '@'
    }
  },
  {
    paragraph: 'controlpanel.global.country',
    input: {
      name: 'country',
      placeholder: 'controlpanel.global.country',
      textPrepend: (
        <Icon icon="globe-europe" color={Colors.bbGrey4} size="1x" />
      ),
      disabled: true
    }
  },
  {
    paragraph: 'controlpanel.global.state_province',
    input: {
      name: 'province',
      placeholder: 'controlpanel.global.state_province'
    }
  },
  {
    paragraph: 'controlpanel.global.address',
    input: {
      name: 'address',
      placeholder: 'controlpanel.global.address'
    }
  },
  {
    paragraph: 'controlpanel.global.town_city',
    input: {
      name: 'city',
      placeholder: 'controlpanel.global.town_city'
    }
  },
  {
    paragraph: 'controlpanel.global.zipCode',
    input: {
      name: 'zipCode',
      placeholder: 'controlpanel.global.zipCode'
    }
  }
];

export const LOGO_INPUT = {
  title: 'controlpanel.shopify_form.title.images',
  label: 'controlpanel.shopify_form.label.logo',
  warning: 'controlpanel.shopify_form.warning.logo',
  description: 'controlpanel.shopify_form.description.logo',
  field: {
    name: 'logo',
    type: 'file',
    accept: 'image/png, image/jpeg',
    file: null,
    fileColumn: 'controlpanel.my_account.user_data.inputs.logo',
    infoFile: 'controlpanel.my_account.user_data.inputs.logo-info',
    description: 'controlpanel.my_account.user_data.inputs.logo-description'
  }
};

export const FAVICON_INPUT = {
  label: 'controlpanel.shopify_form.label.favicon',
  warning: 'controlpanel.shopify_form.warning.favicon',
  description: 'controlpanel.shopify_form.description.favicon',
  field: {
    name: 'favicon',
    type: 'file',
    accept: 'image/png, image/jpeg',
    file: null,
    fileColumn: 'controlpanel.my_account.user_data.inputs.logo',
    infoFile: 'controlpanel.my_account.user_data.inputs.logo-info',
    description: 'controlpanel.my_account.user_data.inputs.logo-description'
  }
};

export const SELECT_INPUTS = async (lang) => {
  const filters = await getFiltersByFieldName(['theme', 'catalogue'], lang);

  const themeOptions = filter(filters, (obj) => obj?.fieldName === 'theme');
  const themeOptionsFormatted = getSelectStructure(
    themeOptions[0]?.values,
    'key',
    'value'
  );

  const catalogueOptions = filter(
    filters,
    (obj) => obj?.fieldName === 'catalogue'
  );
  const catalogueOptionsFormatted = getSelectStructure(
    catalogueOptions[0]?.values,
    'key',
    'value'
  );

  return [
    {
      paragraph: 'controlpanel.global.catalog',
      warning: 'controlpanel.shopify_form.warning.catalog',
      field: {
        name: 'catalogue',
        placeholder: 'controlpanel.shopify_form.placeholder.catalog',
        options: catalogueOptionsFormatted
      }
    },
    {
      paragraph: 'controlpanel.global.theme',
      warning: 'controlpanel.shopify_form.warning.theme',
      field: {
        name: 'theme',
        placeholder: 'controlpanel.shopify_form.placeholder.theme',
        options: themeOptionsFormatted
      }
    }
  ];
};
