import { useCallback } from 'react';

import { useIntl } from 'react-intl';

import { genericRequest } from 'app/api';
import { getFiltersUrl } from 'app/components/DataTable/utils';

export default function useDatatableApi({
  paginationOptions = null,
  filters,
  setLoading,
  entities,
  setEntities,
  setTotalSize,
  baseUrl,
  getDataTableStructure,
  setApiResponse
}) {
  const intl = useIntl();

  const fetchData = useCallback(async () => {
    const {
      page = 1,
      sizePerPage = entities?.length || 999,
      defaultSorted = [{ dataField: 'id', order: 'desc' }]
    } = paginationOptions;

    const filtersUrl = getFiltersUrl(filters);

    const orderBy = defaultSorted[0].order === 'desc' ? '-' : '';

    const result = await genericRequest({
      url: `${baseUrl}?page[size]=${sizePerPage}&page[number]=${
        page - 1
      }&sort=${orderBy}${defaultSorted[0].dataField}${filtersUrl}`,
      setLoading
    });

    if (result?.data) {
      const dataTableStructure = getDataTableStructure(result?.data, intl);
      setApiResponse(result);
      setEntities(dataTableStructure);
      setTotalSize(result?.meta?.count);
    }
  }, [
    entities?.length,
    paginationOptions,
    filters,
    baseUrl,
    setLoading,
    getDataTableStructure,
    intl,
    setApiResponse,
    setEntities,
    setTotalSize
  ]);

  return {
    fetchData
  };
}
