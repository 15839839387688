export const getInputClasses = (error, touched) => {
  let nameClass = '';
  if (error && touched) {
    nameClass = 'is-invalid';
  }
  if (!error && touched) {
    nameClass = 'is-valid';
  }
  return nameClass;
};
