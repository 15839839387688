import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Field } from 'formik';

import classNames from 'classnames';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';
import Button from 'app/components/Button';

import 'app/components/SearchBar/SearchBar.scss';

export default function SearchBar({
  button = null,
  closeIcon = '/media/svg/bigbuy/large/times.svg',
  disabled = false,
  placeholder = '',
  size = 'normal',
  value = '',
  className = '',
  onClear = null,
  name
}) {
  const [inputValue, setInputValue] = useState(value);
  const classes = classNames(`bb-searchbar bb-searchbar--${size}`, className);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Field name={name}>
      {({ field, form }) => (
        <div className="bb-search__container">
          <div className={classes}>
            <input
              {...field}
              name={name}
              type="text"
              disabled={disabled}
              placeholder={placeholder}
              className="bb-searchbar__input"
              id={name}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
                form.setFieldValue(name, e.target.value);
              }}
            />
            {inputValue !== '' && onClear && (
              <SVG
                className="bb-searchbar__close-icon"
                src={toAbsoluteUrl(closeIcon)}
                onClick={() => {
                  onClear();
                  setInputValue('');
                }}
              />
            )}
          </div>
          {button && <Button disabled={inputValue === ''} {...button} />}
        </div>
      )}
    </Field>
  );
}

SearchBar.propTypes = {
  button: PropTypes.shape({}),
  closeIcon: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['big', 'normal']),
  value: PropTypes.string,
  className: PropTypes.string,
  onClear: PropTypes.func
};
