import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';

export default function useTrackingsStatus() {
  const intl = useIntl();
  const { bbGreen, bbGreenLight, bbPurple, bbPurpleLight, bbRed, bbRedLight } =
    Colors;
  return {
    PENDING: {
      status: 'IN_TRANSIT',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.in_transit'
      }),
      color: bbGreen,
      backgroundColor: bbGreenLight
    },
    INFO_RECEIVED: {
      status: 'IN_TRANSIT',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.in_transit'
      }),
      color: bbGreen,
      backgroundColor: bbGreenLight
    },
    PROCESSING: {
      status: 'IN_TRANSIT',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.in_transit'
      }),
      color: bbGreen,
      backgroundColor: bbGreenLight
    },
    DEPARTED: {
      status: 'IN_TRANSIT',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.in_transit'
      }),
      color: bbGreen,
      backgroundColor: bbGreenLight
    },
    IN_TRANSIT: {
      status: 'IN_TRANSIT',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.in_transit'
      }),
      color: bbGreen,
      backgroundColor: bbGreenLight
    },
    ON_HOLD: {
      status: 'IN_TRANSIT',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.in_transit'
      }),
      color: bbGreen,
      backgroundColor: bbGreenLight
    },
    DELAY: {
      status: 'IN_TRANSIT',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.in_transit'
      }),
      color: bbGreen,
      backgroundColor: bbGreenLight
    },
    DELIVERED: {
      status: 'DELIVERED',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.delivered'
      }),
      color: bbGreen,
      backgroundColor: bbGreenLight
    },
    FAILED_ATTEMPT: {
      status: 'DELIVERY_EXCEPTION',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.exception'
      }),
      color: bbRed,
      backgroundColor: bbRedLight
    },
    EXCEPTION: {
      status: 'DELIVERY_EXCEPTION',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.exception'
      }),
      color: bbRed,
      backgroundColor: bbRedLight
    },
    PICKUP_POINT: {
      status: 'DELIVERY_EXCEPTION',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.exception'
      }),
      color: bbRed,
      backgroundColor: bbRedLight
    },
    DAMAGE: {
      status: 'OTHER_EXCEPTION',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.exception'
      }),
      color: bbRed,
      backgroundColor: bbRedLight
    },
    LOST: {
      status: 'OTHER_EXCEPTION',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.exception'
      }),
      color: bbRed,
      backgroundColor: bbRedLight
    },
    RETURNED: {
      status: 'OTHER_EXCEPTION',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.exception'
      }),
      color: bbRed,
      backgroundColor: bbRedLight
    },
    WAITING_TO_COMPUTE: {
      status: 'READY_TO_SHIP',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.ready_to_ship'
      }),
      color: bbGreen,
      backgroundColor: bbGreenLight
    },
    OUT_FOR_DELIVERY: {
      status: 'OUT_FOR_DELIVERY',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.out_for_delivery'
      }),
      color: bbGreen,
      backgroundColor: bbGreenLight
    },
    VOIDED: {
      status: 'VOIDED',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.voided'
      }),
      color: bbPurple,
      backgroundColor: bbPurpleLight
    },
    DELIVERY_EXCEPTION: {
      status: 'DELIVERY_EXCEPTION',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.delivery_exception'
      }),
      color: bbRed,
      backgroundColor: bbRedLight
    },
    OTHER_EXCEPTION: {
      status: 'OTHER_EXCEPTION',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.other_exception'
      }),
      color: bbPurple,
      backgroundColor: bbPurpleLight
    },
    READY_TO_SHIP: {
      status: 'READY_TO_SHIP',
      translation: intl.formatMessage({
        id: 'controlpanel.trackings.status.ready_to_ship'
      }),
      color: bbPurple,
      backgroundColor: bbPurpleLight
    }
  };
}

export const GetSelectStructure = () => {
  const intl = useIntl();

  return [
    {
      value: 'IN_TRANSIT',
      label: intl.formatMessage({
        id: 'controlpanel.trackings.status.in_transit'
      })
    },
    {
      value: 'DELIVERED',
      label: intl.formatMessage({
        id: 'controlpanel.trackings.status.delivered'
      })
    },
    {
      value: 'DELIVERY_EXCEPTION',
      label: intl.formatMessage({
        id: 'controlpanel.trackings.status.delivery_exception'
      })
    },
    {
      value: 'OTHER_EXCEPTION',
      label: intl.formatMessage({
        id: 'controlpanel.trackings.status.other_exception'
      })
    },
    {
      value: 'READY_TO_SHIP',
      label: intl.formatMessage({
        id: 'controlpanel.trackings.status.ready_to_ship'
      })
    },
    {
      value: 'OUT_FOR_DELIVERY',
      label: intl.formatMessage({
        id: 'controlpanel.trackings.status.out_for_delivery'
      })
    }
  ];
};

export const getGroupedStatus = () => {
  const IN_TRANSIT = [
    'PENDING',
    'INFO_RECEIVED',
    'PROCESSING',
    'DEPARTED',
    'IN_TRANSIT',
    'ON_HOLD',
    'DELAY'
  ];

  const DELIVERED = ['DELIVERED'];

  const DELIVERY_EXCEPTION = [
    'FAILED_ATTEMPT',
    'EXCEPTION',
    'PICKUP_POINT',
    'DELIVERY_EXCEPTION'
  ];

  const OTHER_EXCEPTION = ['DAMAGE', 'LOST', 'RETURNED', 'OTHER_EXCEPTION'];

  const READY_TO_SHIP = ['WAITING_TO_COMPUTE'];

  const OUT_FOR_DELIVERY = ['OUT_FOR_DELIVERY'];

  const VOIDED = ['VOIDED'];

  return {
    IN_TRANSIT: [...IN_TRANSIT],
    DELIVERED: [...DELIVERED],
    DELIVERY_EXCEPTION: [...DELIVERY_EXCEPTION],
    OTHER_EXCEPTION: [...OTHER_EXCEPTION],
    READY_TO_SHIP: [...READY_TO_SHIP],
    OUT_FOR_DELIVERY: [...OUT_FOR_DELIVERY],
    VOIDED: [...VOIDED]
  };
};

export const getAllStatusExceptions = () => {
  const groupedStatus = getGroupedStatus();
  return [
    ...groupedStatus.DELIVERY_EXCEPTION,
    ...groupedStatus.OTHER_EXCEPTION
  ];
};
