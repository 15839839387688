import React from 'react';
import PropTypes from 'prop-types';
import { Col, Image, Row } from 'react-bootstrap';

import 'app/components/DropshippingShopCard/DropshippingShopCardEcommerceList.scss';

export default function DropshippingShopCardEcommerceList({
  image = null,
  mainText = null,
  ecommerceLogo,
  url,
  catalog,
  type
}) {
  return (
    <Row className="dropshipping-shop-card-ecommerce-list">
      <Col md={12} className="dropshipping-shop-card-ecommerce-list__container">
        <div className="dropshipping-shop-card-ecommerce-list__container-logos">
          {ecommerceLogo}
          {image && <Image src={image} className="logo-client" />}
          {mainText && <p className="bold">{mainText}</p>}
        </div>

        <div className="dropshipping-shop-card-ecommerce-list__container-info">
          {url && <p className="medium big url">{url}</p>}
          {(type || catalog) && (
            <p className="medium catalog">
              {type}
              {type && catalog && ' - '}
              {catalog}
            </p>
          )}
        </div>
      </Col>
    </Row>
  );
}

DropshippingShopCardEcommerceList.propTypes = {
  image: PropTypes.string,
  mainText: PropTypes.string,
  ecommerceLogo: PropTypes.element.isRequired,
  url: PropTypes.string.isRequired,
  catalog: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};
