import React, { useEffect, useState } from 'react';

import { ProgressBar } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import useFeedbackContext from 'app/context/FeedbackContext';

export default function AnimateLoading() {
  const location = useLocation();
  const { animationBarIsRunning, animateLoadingBar } = useFeedbackContext();
  const [actualPath, setActualPath] = useState(window.location.pathname);
  const [countOfProgess, setCountOfProgess] = useState(0);
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setActualPath(location.pathname);
    if (location.pathname !== actualPath) {
      setCountOfProgess(0);
      setDisplay(true);
    }
  }, [location, actualPath]);

  useEffect(() => {
    if (animationBarIsRunning) {
      setCountOfProgess(0);
      setDisplay(true);
    }
  }, [animationBarIsRunning]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountOfProgess((oldProgress) => {
        if (oldProgress === 200) {
          setDisplay(false);
        }
        return Math.min(oldProgress + Math.random() * 20, 200);
      });
    }, 50);

    return () => {
      clearInterval(timer);
      if (animationBarIsRunning) {
        animateLoadingBar();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="header-progress-bar"
      style={{ height: '3px', width: '100%' }}
    >
      {display && (
        <ProgressBar
          variant="info"
          now={countOfProgess}
          style={{ height: '3px' }}
        />
      )}
    </div>
  );
}
