import React, { useState, useEffect, useRef } from 'react';

import { Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { genericRequest } from 'app/api';
import { URLS } from 'app/config/urls';
import Button from 'app/components/Button';
import FormCard from 'app/components/FormCard';
import FormSpinner from 'app/components/FormSpinner';
import MyAccountLayout from 'app/modules/MyAccount/components/MyAccountLayout';
import NotificationsUserPanel from 'app/modules/MyAccount/pages/Notifications/components/NotificationsUserPanel';

export default function Notifications() {
  const intl = useIntl();

  const {
    user: {
      id,
      attributes: { email }
    }
  } = useSelector((state) => state.auth);

  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  const container = useRef(null);

  const formValidations = Yup.object().shape({
    newsletter: Yup.object().shape({
      isActive: Yup.bool(),
      lang: Yup.string(),
      subscriptors: Yup.array().of(
        Yup.object().shape({
          language: Yup.string(),
          email: Yup.string().email()
        })
      )
    }),
    orders: Yup.object().shape({
      isActive: Yup.bool(),
      subscriptors: Yup.array().of(
        Yup.object().shape({
          language: Yup.string(),
          email: Yup.string().email()
        })
      )
    }),
    tracking: Yup.object().shape({
      isActive: Yup.bool(),
      subscriptors: Yup.array().of(
        Yup.object().shape({
          language: Yup.string(),
          email: Yup.string().email(
            intl.formatMessage({ id: 'controlpanel.errors.email' })
          )
        })
      )
    })
  });

  useEffect(() => {
    const getNewsletter = async () => {
      const newsletter = await genericRequest({
        url: 'notifications/config',
        setLoading: setIsLoading
      });

      const values = {
        newsletter: {
          isActive: newsletter.data.attributes.newsletter
        }
      };
      setInitialValues(values);
    };
    getNewsletter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values, actions) => {
    setIsLoading(true);

    const { newsletter } = values;
    const attributes = {
      order: false,
      orderAccounts: [],
      tracking: false,
      trackingAccounts: [],
      newsletter: newsletter.isActive,
      newsletterAccounts: [
        {
          account: email,
          language: 'en'
        }
      ]
    };

    await genericRequest({
      url: `notifications/config`,
      method: 'PATCH',
      data: {
        data: {
          type: 'notification-config',
          id,
          attributes
        }
      },
      customSuccessFeedback: {
        variant: 'success',
        content: intl.formatMessage({
          id: 'controlpanel.feedback.save_changes.success'
        }),
        autoClose: true,
        dismissible: false
      }
    });

    const currentState = await genericRequest({
      url: 'notifications/config'
    });

    const currentValues = {
      newsletter: {
        isActive: currentState.data.attributes.newsletter
      }
    };
    actions.resetForm({ values: currentValues });
    setInitialValues(currentValues);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <MyAccountLayout currentPagePath={URLS.myAccountNotifications}>
        <Col md={8}>
          <FormSpinner
            title="controlpanel.my_account_profile_card.billing"
            showButtons
            containerMaxHeight={container?.current?.clientHeight}
          />
        </Col>
      </MyAccountLayout>
    );
  }

  return (
    <MyAccountLayout currentPagePath={URLS.myAccountNotifications}>
      <Col md={8}>
        {initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={formValidations}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <div ref={container}>
                <FormCard
                  title={intl.formatMessage({
                    id: 'controlpanel.my_account.notifications.title'
                  })}
                  buttons={
                    <>
                      <Button
                        size="big"
                        text={intl.formatMessage({
                          id: 'controlpanel.global.cancel'
                        })}
                        onClick={() => formik.handleReset()}
                      />
                      <Button
                        size="big"
                        type="secondary"
                        typeButton="submit"
                        text={intl.formatMessage({
                          id: 'controlpanel.global.save'
                        })}
                        onClick={() => formik.submitForm()}
                        disabled={!(formik.isValid && formik.dirty)}
                      />
                    </>
                  }
                >
                  <NotificationsUserPanel />
                </FormCard>
              </div>
            )}
          </Formik>
        )}
      </Col>
    </MyAccountLayout>
  );
}

/* const INITIAL_VALUES = {
  newsletter: {
    isActive: false,
    lang: 'es',
    subscriptors: [
      {
        language: 'en',
        email: 'test-newsletter@bigbuy.eu'
      },
      {
        language: 'fi',
        email: 'test-newsletter2@bigbuy.eu'
      }
    ]
  },
  orders: {
    isActive: true,
    subscriptors: [
      {
        language: 'es',
        email: 'test-orders@bigbuy.eu'
      },
      {
        language: 'sk',
        email: 'test-orders2@bigbuy.eu'
      }
    ]
  },
  tracking: {
    isActive: false,
    subscriptors: [
      {
        language: 'en',
        email: 'test-tracking@bigbuy.eu'
      },
      {
        language: 'es',
        email: 'test-tracking2@bigbuy.eu'
      }
    ]
  }
}; */
