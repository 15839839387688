import { useIntl } from 'react-intl';

export default function useRmaStatus() {
  const intl = useIntl();

  return {
    DRAFT: {
      translation: intl.formatMessage({ id: 'controlpanel.rma.status.draft' }),
      status: 'DRAFT'
    },
    PENDING_CONFIRMATION: {
      translation: intl.formatMessage({
        id: 'controlpanel.rma.status.pending_confirmation'
      }),
      status: 'PENDING_CONFIRMATION'
    },
    PENDING_PICK_UP_ADDRESS: {
      translation: intl.formatMessage({
        id: 'controlpanel.rma.status.pending_pick_up_address'
      }),
      status: 'PENDING_PICK_UP_ADDRESS'
    },
    PENDING_COLLECTION: {
      translation: intl.formatMessage({
        id: 'controlpanel.rma.status.pending_collection'
      }),
      status: 'PENDING_COLLECTION'
    },
    PENDING_VALUATION: {
      translation: intl.formatMessage({
        id: 'controlpanel.rma.status.pending_valuation'
      }),
      status: 'PENDING_VALUATION'
    },
    PENDING_PAYMENT: {
      translation: intl.formatMessage({
        id: 'controlpanel.rma.status.pending_payment'
      }),
      status: 'PENDING_PAYMENT'
    },
    PAID: {
      translation: intl.formatMessage({ id: 'controlpanel.rma.status.paid' }),
      status: 'PAID'
    }
  };
}
