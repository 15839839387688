import { useIntl } from 'react-intl';

import { URLS } from 'app/config/urls';

export default function useNotificationsTypes() {
  const intl = useIntl();

  //* defaultLink y defaultTextLink se usa solo para las alertas
  return {
    ORDER: {
      icon: 'clipboard',
      defaultLink: URLS?.orders,
      defaultTextLink: intl.formatMessage({
        id: 'controlpanel.global.view'
      })
    },
    WALLET: {
      icon: 'database',
      defaultLink: URLS?.purse,
      defaultTextLink: intl.formatMessage({
        id: 'controlpanel.global.view'
      })
    },
    ACCOUNT: {
      icon: 'user',
      defaultLink: URLS?.trackings,
      defaultTextLink: intl.formatMessage({
        id: 'controlpanel.global.view'
      })
    },
    INVOICE: {
      icon: 'file-invoice-dollar',
      defaultLink: URLS?.trackings,
      defaultTextLink: intl.formatMessage({
        id: 'controlpanel.global.view'
      })
    },
    SHOP: {
      icon: 'store',
      defaultLink: URLS?.shops,
      defaultTextLink: intl.formatMessage({
        id: 'controlpanel.global.view'
      })
    },
    RETURN: {
      icon: 'retweet',
      defaultLink: URLS?.devolutions,
      defaultTextLink: intl.formatMessage({
        id: 'controlpanel.global.view'
      })
    },
    OTHER: {
      icon: 'location-dot',
      defaultLink: URLS?.trackings,
      defaultTextLink: intl.formatMessage({
        id: 'controlpanel.global.view'
      })
    },
    PRODUCT: {
      icon: 'tags',
      defaultLink: URLS?.wishlist,
      defaultTextLink: intl.formatMessage({
        id: 'controlpanel.global.view'
      })
    },
    CALENDAR: {
      icon: 'calendar-alt',
      defaultLink: URLS?.dashboard,
      defaultTextLink: intl.formatMessage({
        id: 'controlpanel.global.view'
      })
    },
    DOWNLOAD: {
      icon: 'download',
      defaultLink: URLS?.downloads,
      defaultTextLink: intl.formatMessage({
        id: 'controlpanel.global.view'
      })
    }
  };
}
