import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { formatDateByFormat } from 'app/utils/dates';
import useDataProviderContext from 'app/hooks/useDataProviderContext';
import usePaymentMethods from 'app/hooks/usePaymentMethods.hook';
import useOrdersStatus from 'app/hooks/useOrdersStatus.hook';

export default function OrderInfoCard() {
  const intl = useIntl();
  const paymentMethods = usePaymentMethods();
  const { order } = useDataProviderContext();
  const {
    paymentMethod,
    status,
    date,
    invoice,
    invoiceDate,
    internalReference
  } = order?.attributes;
  const formatedDate = formatDateByFormat(new Date(date * 1000), 'dd/mm/yyyy');
  const formatedInvoiceDate = formatDateByFormat(
    new Date(invoiceDate * 1000),
    'dd/mm/yyyy'
  );

  const orderStatus = useOrdersStatus();

  return (
    <Row>
      <Col md={12} className="margin-bottom">
        <p className="bold">
          {intl.formatMessage({ id: 'controlpanel.orders.view.paymentmethod' })}
          &nbsp;
          <span>{paymentMethods[paymentMethod].translation}</span>
        </p>
      </Col>
      <Col md={12}>
        <p className="bold">
          {intl.formatMessage({ id: 'controlpanel.orders.view.creation_date' })}
          &nbsp;
          <span>{date ? formatedDate : ''}</span>
        </p>
      </Col>
      <Col md={12} className="margin-bottom">
        <p className="bold">
          {intl.formatMessage({ id: 'controlpanel.orders.view.order_status' })}
          &nbsp;
          <span>{orderStatus[status].translation}</span>
        </p>
      </Col>
      <Col md={12}>
        <p className="bold">
          {intl.formatMessage({
            id: 'controlpanel.orders.view.invoice_number'
          })}
          &nbsp;
          <span>{invoice}</span>
        </p>
      </Col>
      <Col md={12} className="margin-bottom">
        <p className="bold">
          {intl.formatMessage({ id: 'controlpanel.orders.view.invoice_date' })}
          &nbsp;
          <span>{invoiceDate ? formatedInvoiceDate : ''}</span>
        </p>
      </Col>
      <Col md={12}>
        <p className="bold">
          {intl.formatMessage({ id: 'controlpanel.global.internal_reference' })}
          &nbsp;
          <span>{internalReference}</span>
        </p>
      </Col>
    </Row>
  );
}
