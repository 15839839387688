import React from 'react';

import { genericRequest } from 'app/api';
import Deposit from 'app/modules/Purse/components/Modals/Deposit';
import Withdrawal from 'app/modules/Purse/components/Modals/Withdrawal';

import 'app/modules/Purse/components/Modals/Modal.scss';

export default function Modals() {
  return (
    <div className="purse-modal">
      <Withdrawal />
      <Deposit />
    </div>
  );
}

export const deposit = async (paymentMethodId, amount, feedback) => {
  const response = await genericRequest({
    url: 'wallet-movements/deposit',
    method: 'post',
    data: {
      data: {
        type: 'wallet-action',
        attributes: {
          type: 'wallet-deposit',
          id: Number(paymentMethodId),
          amount: Number(amount)
        }
      }
    },
    getHeaders: true,
    customSuccessFeedback: {
      variant: 'info',
      ...feedback
    }
  });

  return response;
};

export const purseMovements = async (attributes, feedback) => {
  const response = await genericRequest({
    url: 'wallet-movements/actions',
    method: 'post',
    data: {
      data: {
        type: 'wallet-action',
        attributes
      }
    },
    getHeaders: true,
    customSuccessFeedback: {
      variant: 'info',
      ...feedback
    }
  });

  return response;
};
