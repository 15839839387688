import React from 'react';
import Proptypes from 'prop-types';
import { useIntl } from 'react-intl';

import Icon from 'app/components/Icon';

import { Colors } from 'app/utils/colors';

import 'app/modules/Shops/pages/Shopify/components/Step.scss';

export default function Step({
  step: { translationKey, icon, status, color }
}) {
  const intl = useIntl();
  const isDone = status === 'DONE';
  const isPending = status === 'PENDING';
  return (
    <div className="shopify-form__header__process__step">
      <Icon
        prefix="fad"
        icon={isDone ? 'check-circle' : icon}
        color={isDone ? Colors.bbGreen : color}
        size="1x"
      />
      <p
        style={{ color: isPending ? Colors.bbGrey3 : color }}
        className="shopify-form__header__process__text"
      >
        {intl.formatMessage({ id: translationKey })}
      </p>
    </div>
  );
}

Step.propTypes = {
  step: Proptypes.shape({
    translationKey: Proptypes.string,
    icon: Proptypes.string,
    status: Proptypes.string,
    color: Proptypes.string
  }).isRequired
};
