import React, { useEffect, useState, useLayoutEffect, useMemo } from 'react';

import { Col, Row } from 'react-bootstrap';
import { size } from 'lodash';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Colors } from 'app/utils/colors';
import { genericRequest } from 'app/api';
import { getDateRange, formatDateByFormat } from 'app/utils/dates';
import { parserDashboardChart } from 'app/utils/parsers/dashboard-chart-parser';
import { parserWalletcard } from 'app/utils/parsers/walletcard.parser';
import { SIZE } from 'app/utils/sizes';
import { useLang } from 'app/context/i18n';
import { useSubheader } from 'app/context/SubheaderContext';
import { useWindow } from 'app/hooks/useWindow.hook';
import ApexChart from 'app/components/ApexChart';
import PackInfoCard from 'app/components/PackInfoCard';
import useNotificationsServiceHook from 'app/hooks/useNotificationsService.hook';
import UserNotificationAlert from 'app/components/UserNotificationAlert';
import Promos from 'app/components/Promo/Promos';
import WalletCard from 'app/components/WalletCard';
import cmsRequest from 'app/api/cmsRequest';

import 'app/pages/DashboardPage/DashboardPage.scss';

export default function DashboardPage() {
  const intl = useIntl();

  const {
    notifications: { alerts }
  } = useNotificationsServiceHook();

  const [wallet, setWallet] = useState();
  const { user: loggedInfo } = useSelector((state) => state.auth);
  const { packName, packExpirationDate, packId, packRenovation } = useMemo(
    () => loggedInfo?.attributes,
    [loggedInfo?.attributes]
  );
  const { width } = useWindow();
  const locale = useLang();
  const subheader = useSubheader();
  const { setTitle, setIcon, setBreadcrumbs } = subheader;
  const mobile = width < SIZE.LG;
  const [dataCard, setDataCard] = useState(null);
  const [dataChart, setDataChart] = useState(null);
  const [dataPromos, setDataPromos] = useState(null);

  useLayoutEffect(() => {
    setTitle('Dashboard');
    setIcon({ icon: 'layer-group' });
    setBreadcrumbs([]);
  }, [setTitle, setBreadcrumbs, setIcon]);

  useEffect(() => {
    const getChart = async () => {
      const today = formatDateByFormat(new Date());
      const period = formatDateByFormat(getDateRange(12));
      const sales = await genericRequest({
        url: `customers/me/sales-statistics/month?filter[date]=${period},${today}`
      });
      const parsedData = parserDashboardChart(
        intl.formatMessage({ id: 'controlpanel.dashboard.chart_title' }),
        Colors.bbSecondary,
        sales.data,
        sales.meta.total,
        mobile
      );
      setDataChart(parsedData);
    };
    getChart();
  }, [intl, mobile]);

  useEffect(() => {
    const getMovements = async () => {
      const movements = await genericRequest({
        url: 'wallet-movements?page[size]=2&page[number]=0&sort=-date'
      });
      const parsedData = parserWalletcard(movements.data, intl);
      setWallet(movements?.meta.wallet);
      setDataCard(parsedData);
    };
    getMovements();
  }, [intl]);

  useEffect(() => {
    const setCMSPromo = async () => {
      const response = await cmsRequest({
        api: 'banners',
        locale
      });
      setDataPromos(response.data);
    };
    setCMSPromo();
  }, [locale]);
  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-row-fluid flex-column">
        {size(alerts) > 0 && <UserNotificationAlert alerts={alerts} />}
        <Row className="bb-dashboard">
          {!!dataPromos?.length && (
            <Col md={6} xl={4}>
              <Promos promos={dataPromos} />
            </Col>
          )}
          <Col md={6} xl={4}>
            <PackInfoCard
              title={intl.formatMessage({
                id: 'controlpanel.pack.default.title'
              })}
              packId={packId}
              packNameText={packName}
              isRenovationActive={packRenovation}
              date={packExpirationDate}
              useSmallView
            />
          </Col>
          <Col md={12} xl={4}>
            {dataCard && (
              <WalletCard
                titleWallet={intl.formatMessage({
                  id: 'controlpanel.walletcard.purse'
                })}
                balanceTitle={intl.formatMessage({
                  id: 'controlpanel.walletcard.balance'
                })}
                balanceValue={wallet}
                titleInfo={intl.formatMessage({
                  id: 'controlpanel.walletcard.info'
                })}
                payInTitle={intl.formatMessage({
                  id: 'controlpanel.walletcard.deposit_money'
                })}
                drawOutTitle={intl.formatMessage({
                  id: 'controlpanel.walletcard.transfer_money'
                })}
                times={dataCard.times}
                numTimes={2}
                currentLang={locale}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="bb-dashboard__chart">
              <ApexChart
                title={intl.formatMessage({
                  id: 'controlpanel.dashboard.chart_title'
                })}
                data={dataChart}
                type="bar"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
