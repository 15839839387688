import { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  getFiltersConfiguration,
  getCarriers
} from 'app/modules/Trackings/config';
import { GetSelectStructure } from 'app/hooks/useTrackingsStatus.hook';

export const useFiltersConfiguration = () => {
  const intl = useIntl();
  const formik = useFormikContext();
  const statusOptions = GetSelectStructure();

  const { user } = useSelector((state) => state.auth);
  const parsedCarriers = useMemo(() => getCarriers(user), [user]);

  const completeConfiguration = useMemo(
    () =>
      getFiltersConfiguration({
        intl,
        formik,
        parsedCarriers,
        statusOptions
      }),
    [formik, intl, parsedCarriers, statusOptions]
  );

  const getComponentFilter = (targetFilter) =>
    completeConfiguration.find((filter) => filter.value === targetFilter)
      .component;

  const getOptionsToSelect = () =>
    completeConfiguration.map(({ component, ...filter }) => filter);

  return {
    completeConfiguration,
    getComponentFilter,
    getOptionsToSelect
  };
};
