import React from 'react';
/* eslint-disable react/jsx-one-expression-per-line */
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import Chip from 'app/components/Chip/Chip';
import CourseCardButton from 'app/components/CourseCard/components/CourseCardButton';
import Discount from 'app/components/CourseCard/components/Discount';
import Icon from 'app/components/Icon';

import 'app/components/CourseCard/components/CourseCardDialog/styles.scss';

export default function CourseCardDialog({
  tags = null,
  serviceCheckout = false,
  link = null,
  linktext = null,
  disabled = false,
  purchased = false,
  open,
  video,
  title,
  description,
  btnLink,
  id,
  state,
  discount,
  features,
  setOpen,
  image
}) {
  return (
    <Modal size="lg" show={open} onHide={() => setOpen(false)}>
      <Modal.Body className="data-table-column-order">
        <div className="course-card-dialog">
          {video && (
            <div className="course-card-dialog-header">
              <iframe
                src={`https://www.youtube.com/embed/${video}?rel=0&controls=0&autoplay=1`}
                allow="autoplay; encrypted-media"
                frameBorder="0"
                allowFullScreen
                title={title}
              />
            </div>
          )}
          {!video && image && (
            <div className="course-card-dialog-header">
              <img src={image} alt={image} />
            </div>
          )}
          <h5 className="course-card-dialog-name">{title}</h5>
          {tags && (
            <div className="course-card-dialog-tags">
              {tags.map((tag, index) => (
                <Chip key={index} text={tag.name} color={tag.color} />
              ))}
            </div>
          )}
          <div className="course-card-dialog-content-button">
            <div className="course-card-dialog-info">
              <div className="course-card-dialog-content-info">
                {description && (
                  <p
                    className="course-card-dialog-description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
                {link && (
                  <a className="course-card-dialog-link" href={link}>
                    <p>{linktext}</p>
                  </a>
                )}
              </div>
              <div className="course-card-dialog-features">
                {features &&
                  features.map((feature, index) => (
                    <div className="course-card-dialog-feature" key={index}>
                      <div className="course-card-dialog-feature-title">
                        <Icon
                          icon="ecommerce"
                          size="lg"
                          prefix="fa-kit"
                          color="#F2B801"
                        />

                        <p>{feature.title}</p>
                      </div>
                      <p>{feature.description}</p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="course-card-dialog-discount-button">
              {discount && (
                <Discount
                  originalPrice={discount.originalPrice}
                  percentage={discount.percentage}
                />
              )}
              {btnLink && (
                <CourseCardButton
                  serviceCheckout={serviceCheckout}
                  purchased={purchased}
                  disabled={disabled}
                  btnLink={btnLink}
                  state={state}
                  id={id}
                />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

CourseCardDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  video: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string
    })
  ),
  discount: PropTypes.shape({
    percentage: PropTypes.string.isRequired,
    originalPrice: PropTypes.string.isRequired
  }).isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  ).isRequired,
  purchased: PropTypes.bool,
  btnLink: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  serviceCheckout: PropTypes.bool,
  link: PropTypes.string,
  linktext: PropTypes.string,
  setOpen: PropTypes.func.isRequired,
  image: PropTypes.string
};
