export const getCookieValue = (name) => {
  const cookies = document.cookie;
  const cookie = cookies.split(`; ${name}=`);
  return cookie.pop().split(';').shift();
};

export const removeCookie = (name) => {
  const now = new Date();
  const yesterday = now.setDate(now.getDate() - 1);

  document.cookie = `${name}=; expires=${yesterday}; path=/; domain=${process.env.REACT_APP_DOMAIN};`;
};
