import PropTypes from 'prop-types';

import { genericRequest } from 'app/api/genericRequest';

export async function getNotifications(controller) {
  try {
    const notificationsData = await genericRequest({
      url: `notifications`,
      signal: controller.signal
    });

    return notificationsData?.data || [];
  } catch (error) {
    return [];
  }
}

export async function markAsRead(id) {
  try {
    const notificationsData = await genericRequest({
      url: `notifications/${id}`,
      method: 'PATCH',
      data: {
        data: {
          id,
          type: 'notification',
          attributes: {
            read: true
          }
        }
      },
      getHeaders: true
    });

    return notificationsData;
  } catch (error) {
    return error;
  }
}

export const NOTIFICATION_PROPS = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    read: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['NOTIFICATION', 'ERROR', 'WARNING', 'INFO'])
      .isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    // TODO: Añadir oneOf con todos los tipos
    section: PropTypes.string.isRequired,
    link: PropTypes.string,
    linkText: PropTypes.string
  })
};
