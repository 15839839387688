import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import PopupDelete from 'app/modules/MyAccount/pages/PaymentMethods/components/CreditCards/PopupDeleteCard';

import './CreditCardsDetail.scss';

export default function CreditCardsDetail({
  ownerName = null,
  lastFour = null,
  expiryMonth = null,
  expiryYear = null,
  iban = null,
  mobile,
  name,
  type,
  urlImage,
  id
}) {
  const intl = useIntl();
  const date = `${expiryMonth}/${expiryYear}`;

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const formattedCardTitle = () => {
    if (lastFour) {
      return `${name.replace('_', ' ')} ****${lastFour.substr(
        lastFour.length - 4
      )}`;
    }
    return '';
  };

  return (
    <>
      <PopupDelete
        show={showDeletePopup}
        setShow={setShowDeletePopup}
        id={id}
      />
      <Row className="bb-payment-methods-credit-cards__credit-cards-item">
        <Col>
          <Row>
            {mobile && (
              <Col
                className="bb-payment-methods-credit-cards__credit-cards-item--mobile-name"
                sm={6}
              >
                <p className="medium">{ownerName}</p>
              </Col>
            )}
            <Col
              className="bb-payment-methods-credit-cards__credit-cards-item--image"
              md={1}
            >
              {!mobile && <img src={urlImage} alt={name} />}
            </Col>
            <Col
              className="bb-payment-methods-credit-cards__credit-cards-item--info"
              md={6}
            >
              {mobile && <img src={urlImage} alt={name} />}
              <div className="bb-payment-methods-credit-cards__credit-cards-item--info-container">
                <p className="bb-payment-methods-credit-cards__credit-cards-item--info-title medium">
                  {type === 'sepadirectdebit' ? iban : formattedCardTitle()}
                </p>
                <p className="bb-payment-methods-credit-cards__credit-cards-item--info-subtitle">
                  {`${intl.formatMessage({
                    id: 'controlpanel.payment_methods.credit_cards_detail.due_date_message'
                  })} ${date}`}
                </p>
              </div>
            </Col>
            <Col
              className="bb-payment-methods-credit-cards__credit-cards-item--interface"
              md={5}
            >
              <div className="bb-payment-methods-credit-cards__credit-cards-item--actions">
                <Button
                  tooltipText={intl.formatMessage({
                    id: 'controlpanel.payment_methods.popup.delete_card'
                  })}
                  icon={
                    mobile ? null : (
                      <Icon
                        icon="trash"
                        color={Colors.bbSecondary}
                        size="lg"
                        className="bb-payment-methods-credit-cards__button-icon"
                        swapOpacity
                      />
                    )
                  }
                  text={
                    mobile
                      ? intl.formatMessage({
                          id: 'controlpanel.payment_methods.credit_cards_detail.delete_button'
                        })
                      : null
                  }
                  onClick={() => setShowDeletePopup(true)}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

CreditCardsDetail.propTypes = {
  ownerName: PropTypes.string,
  expiryMonth: PropTypes.string,
  expiryYear: PropTypes.string,
  lastFour: PropTypes.string,
  mobile: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  urlImage: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  iban: PropTypes.string
};
