import React from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';

import Label from 'app/components/Label';

import 'app/components/Textarea/Textarea.scss';

export default function Textarea({
  label = '',
  tooltipText = null,
  placeholder = '',
  disabled = false,
  style = {},
  name
}) {
  const formik = useFormikContext();
  return (
    <Form.Group className="bb-textarea-form-group" style={style}>
      {label && (
        <Label label={label} tooltipText={tooltipText} htmlFor={name} />
      )}
      <textarea
        id={name}
        name={name}
        disabled={disabled}
        value={formik.values[name]}
        placeholder={placeholder}
        className={classNames('bb-textarea-form-group__textarea', {
          'bb-textarea-form-group__textarea--disabled': disabled,
          'bb-textarea-form-group__textarea--error':
            formik.touched[name] && formik.errors[name]
        })}
        onChange={(event) => formik.setFieldValue(name, event.target.value)}
      />
      {!disabled && formik.touched[name] && formik.errors[name] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{formik.errors[name]}</div>
        </div>
      )}
    </Form.Group>
  );
}

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
