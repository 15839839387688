import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  EditAction as Edit,
  CloneAction as Clone,
  DeleteAction as Delete,
  ExpandRowAction as Expand
} from 'app/modules/Devolutions/components/TableProducts/formatters/ProductFormatter/components';
import {
  ALLOWED_ACTIONS,
  STATUS_WITHOUT_EDIT_BUTTON,
  EDIT,
  CLONE,
  DELETE
} from 'app/modules/Devolutions/components/TableProducts/formatters/ProductFormatter/utils/constants';
import useRMAContext from 'app/modules/Devolutions/context/context';

export default function ActionsFormatter({
  id,
  status,
  sku,
  orderId,
  rmaId,
  quantity,
  eligibleQuantity,
  returnReason,
  hasMessages,
  productDescription
}) {
  const actions = useMemo(() => ALLOWED_ACTIONS[status], [status]);

  const context = useRMAContext();

  return (
    <div className="actions-formatter">
      {hasMessages && <Expand id={id} />}
      {(hasMessages && STATUS_WITHOUT_EDIT_BUTTON.includes(status)) || (
        <Edit
          id={id}
          sku={sku}
          orderId={orderId}
          enabled={actions.includes(EDIT)}
        />
      )}
      <Clone
        sku={sku}
        rmaId={rmaId}
        orderId={orderId}
        quantity={quantity}
        eligibleQuantity={eligibleQuantity}
        returnReason={returnReason}
        enabled={actions.includes(CLONE) && Boolean(eligibleQuantity)}
      />
      <Delete
        id={id}
        sku={sku}
        rmaId={context?.rmaApiInfo?.attributes?.code}
        enabled={actions.includes(DELETE)}
        productDescription={productDescription}
      />
    </div>
  );
}

ActionsFormatter.propTypes = {
  status: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  rmaId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  eligibleQuantity: PropTypes.number.isRequired,
  returnReason: PropTypes.string.isRequired,
  hasMessages: PropTypes.bool.isRequired,
  productDescription: PropTypes.string.isRequired
};
