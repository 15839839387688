import classNames from 'classnames';

export const getClasses = ({
  disableInPage, // first-page, last-page
  isFirstPage = false, // only needed if "disableInPage" is provided
  isLastPage = false, // only needed if "disableInPage" is provided
  active = false,
  disabled = false,
  additional // string: inject additional classes
}) =>
  classNames('regular pagination-links__btn', additional, {
    'pagination-no-action':
      (disableInPage === 'first-page' && isFirstPage) ||
      (disableInPage === 'last-page' && isLastPage) ||
      disabled,
    bold: active,
    active
  });
