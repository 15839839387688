export const Colors = {
  bbPrimary: '#ffce33',
  bbPrimaryDark: '#f6bb00',
  bbPrimaryLight: '#fff0c2',
  bbSecondary: '#0090ff',
  bbSecondaryDark: '#0072ca',
  bbSecondaryMedium: '#b3deff',
  bbSecondaryLight: '#e8f5ff',
  bbOrange: '#ef6327',
  bbOrangeMedium: '#f7b497',
  bbOrangeLight: '#fce7df',
  bbGreen: '#1bc5bd',
  bbGreenMedium: '#d7f9ef',
  bbGreenLight: '#effdfc',
  bbRed: '#f64e60',
  bbRedMedium: '#fccacf',
  bbRedLight: '#feeff1',
  bbPurple: '#8950fc',
  bbPurpleMedium: '#b896fd',
  bbPurpleLight: '#eee5ff',
  bbBlackPure: '#000000',
  bbGrey1: '#1e1e2d',
  bbGrey2: '#464e5f',
  bbGrey3: '#7e8299',
  bbGrey4: '#b5b5c3',
  bbGrey5: '#dddfe5',
  bbGrey6: '#e5eaee',
  bbGrey7: '#ecf0f3',
  bbGrey8: '#eef0f8',
  bbGrey9: '#f3f6f9',
  bbWhite: '#ffffff'
};
