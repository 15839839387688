import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { URLS } from 'app/config/urls';
import { useLang } from 'app/context/i18n';
import { UserProfileDropdown } from 'app/components/UserProfileDropdown/UserProfileDropdown';
import LangSelector from 'app/components/LangSelector/LangSelector';
import TopbarItem from 'app/components/TopbarItem/TopbarItem';
import UserNotificationsDropdown from 'app/components/UserNotificationsDropdown/UserNotificationsDropdown';

import 'app/components/Topbar/Topbar.scss';
import useGetPermisionsSection from 'app/hooks/useGetPermisionsSection.hook';

export default function Topbar() {
  const intl = useIntl();
  const locale = useLang();
  const [numberProductCart, setNumberProductCart] = useState(null);

  const {
    user: {
      attributes: { productsInCart }
    }
  } = useSelector((state) => state.auth);

  const showNotifications = useGetPermisionsSection('notifications');

  useEffect(() => {
    if (productsInCart > 0) {
      setNumberProductCart(String(productsInCart));
    }
  }, [productsInCart]);

  return (
    <div className="topbar bb-topbar">
      <TopbarItem
        url={URLS.wishlist}
        target="_self"
        icon="square-heart"
        tooltip
        tooltipText={intl.formatMessage({
          id: 'controlpanel.asidemenu.wishlist'
        })}
      />
      <TopbarItem
        url={`${process.env.REACT_APP_BIGBUY_URL}${locale}${intl.formatMessage({
          id: 'controlpanel.url.b2bshop'
        })}`}
        icon="store"
        tooltip
        tooltipText={intl.formatMessage({
          id: 'controlpanel.topbar.title.b2bshop'
        })}
      />
      {showNotifications && <UserNotificationsDropdown />}
      <TopbarItem
        url={`${process.env.REACT_APP_BIGBUY_URL}${locale}${intl.formatMessage({
          id: 'controlpanel.url.cart'
        })}`}
        icon="shopping-cart"
        tooltip
        tooltipText={intl.formatMessage({
          id: 'controlpanel.topbar.title.cart'
        })}
        notificationBadge={numberProductCart}
      />
      <TopbarItem
        url={`${process.env.REACT_APP_BIGBUY_URL}${locale}${intl.formatMessage({
          id: 'controlpanel.url.contact'
        })}`}
        icon="envelope"
        tooltip
        tooltipText={intl.formatMessage({
          id: 'controlpanel.topbar.title.contact'
        })}
      />
      <LangSelector />
      <UserProfileDropdown />
    </div>
  );
}
