import React from 'react';

import { useIntl } from 'react-intl';

import Notification from 'app/components/Notification/Notification';

export default function FormInfo() {
  const intl = useIntl();

  return (
    <Notification
      className="devolutions-add__card-notification"
      variant="info"
      header={intl.formatMessage({
        id: 'controlpanel.devolutions.form.info.title'
      })}
      content={
        <div>
          <p className="mb-4 mt-4">
            {intl.formatMessage({
              id: 'controlpanel.devolutions.form.info.subtitle'
            })}
          </p>
          <ul>
            <li>
              <p className="small mb-4">
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.form.info.text2'
                })}
              </p>
            </li>
            <li>
              <p className="small mb-4">
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.form.info.text3'
                })}
              </p>
            </li>
            <li>
              <p className="small mb-4">
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.form.info.text4'
                })}
              </p>
            </li>
            <li>
              <p className="small mb-4">
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.form.info.text5'
                })}
              </p>
            </li>
            <li>
              <p className="small mb-4">
                {intl.formatMessage({
                  id: 'controlpanel.devolutions.form.info.text6'
                })}
              </p>
            </li>
          </ul>
        </div>
      }
      infoIcon={{ icon: 'info-square' }}
    />
  );
}
