import React from 'react';

import { Row, Col } from 'react-bootstrap';

import CreditCardsDetails from 'app/modules/MyAccount/pages/PaymentMethods/components/CreditCards/CreditCardsDetails';

import './CreditCards.scss';

export default function CreditCards() {
  return (
    <Row className="bb-payment-methods-credit-cards__row-info">
      <Col
        xxl={{ offset: 2, span: 10 }}
        className="bb-payment-methods-credit-cards__col-info"
      >
        <CreditCardsDetails />
      </Col>
    </Row>
  );
}
