/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { base64RemovetHeader, filetoBase64 } from 'app/utils/file-conversor64';
import { Colors } from 'app/utils/colors';
import { genericRequest } from 'app/api';
import Button from 'app/components/Button';
import InputFile from 'app/components/InputFile';
import Popup from 'app/components/Popup';
import Spinner from 'app/components/Spinner';
import useDataProviderContext from 'app/hooks/useDataProviderContext';
import { clone } from 'lodash';

export default function AttachReceiptForm({ show, handleClose }) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const {
    order: { id },
    setDownloadFilesLinks,
    downloadFilesLinks
  } = useDataProviderContext();

  return (
    <Formik
      initialValues={{ file: '' }}
      validationSchema={Yup.object().shape({
        file: Yup.mixed().required(
          intl.formatMessage({ id: 'controlpanel.form.validation.file' })
        )
      })}
      onSubmit={async (values, formikBag) => {
        const { file } = values;

        if (file) {
          try {
            const base64 = await filetoBase64(file);
            const response = await genericRequest({
              url: 'receipt-files',
              method: 'post',
              data: {
                data: {
                  type: 'receipt-file',
                  attributes: {
                    file: base64RemovetHeader(base64)
                  },
                  relationships: {
                    order: { data: { type: 'order', id } }
                  }
                }
              },
              setLoading,
              getHeaders: true,
              customSuccessFeedback: {
                variant: 'success',
                content: intl.formatMessage({
                  id: 'controlpanel.global.upload_file_success'
                })
              }
            });

            if (response?.status === 200) {
              const options = clone(downloadFilesLinks);
              const links = response?.data?.data?.links;
              if (links?.receipts) {
                let titleRecepits = intl.formatMessage({
                  id: 'controlpanel.global.download_receipt'
                });

                if (links?.receipts?.meta?.count > 1) {
                  titleRecepits = intl.formatMessage({
                    id: 'controlpanel.global.download_receipts'
                  });
                }

                const keyOption = options.findIndex((obj) => obj.id === 2);
                if (keyOption > -1) {
                  options.splice(keyOption, 1);
                }

                options.push({
                  id: 2,
                  title: titleRecepits,
                  value: links?.receipts?.href
                });
                setDownloadFilesLinks(options);
              }

              formikBag.resetForm();
              handleClose();
            }
          } catch {
            formikBag.resetForm();
            handleClose();
          }
        }
      }}
    >
      {(formik) => (
        <Popup
          show={show}
          handleClose={handleClose}
          header={
            <p className="bold">
              {intl.formatMessage({
                id: 'controlpanel.orders.view.title.attach_receipt'
              })}
            </p>
          }
          body={
            <Form>
              {!loading ? (
                <>
                  <InputFile
                    formik={formik}
                    name="file"
                    label={intl.formatMessage({
                      id: 'controlpanel.global.select_file.label'
                    })}
                    placeholder={intl.formatMessage({
                      id: 'controlpanel.global.select_file.placeholder'
                    })}
                    accept="image/jpeg,image/png,application/pdf"
                    svg={{ icon: 'upload', size: 'lg' }}
                    svgPosition="right"
                  />
                  <p style={{ color: Colors.bbGrey4 }}>
                    {intl.formatMessage(
                      { id: 'controlpanel.global.valid_formats' },
                      { formats: 'PNG, JPG, PDF' }
                    )}
                  </p>
                </>
              ) : (
                <Spinner />
              )}
            </Form>
          }
          footer={
            <Button
              text={intl.formatMessage({ id: 'controlpanel.global.send' })}
              type="primary"
              size="big"
              typeButton="submit"
              onClick={formik.handleSubmit}
            />
          }
        />
      )}
    </Formik>
  );
}

AttachReceiptForm.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};
