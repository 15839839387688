import React from 'react';
import PropTypes from 'prop-types';

import { colorsTypePropType } from 'app/components/PackInfoCard/PackInfoCard.utils';
import { formatDateByFormat } from 'app/utils/dates';
import { SIZE } from 'app/utils/sizes';
import { useWindow } from 'app/hooks/useWindow.hook';
import Icon from 'app/components/Icon';

import 'app/components/PackInfoCard/components/PackInfoDate.scss';

export default function PackInfoDate({ date, dateText, colors }) {
  const formatDate = formatDateByFormat(new Date(date), 'dd/mm/yyyy');
  const { width } = useWindow();
  const mobile = width < SIZE.MD;
  return (
    <div
      className="bb-pack-info-date"
      style={{
        backgroundColor: colors.dateBG,
        color: colors.dateText
      }}
    >
      <p>{dateText}</p>
      <div className="bb-pack-info-date__calendar">
        <Icon
          icon="calendar-alt"
          size={mobile ? 'xl' : '2xl'}
          color={colors.dateIcon}
        />
        <h4 className="bb-pack-info-date__calendar__text bold">{formatDate}</h4>
      </div>
    </div>
  );
}

PackInfoDate.propTypes = {
  dateText: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  colors: colorsTypePropType.isRequired
};
