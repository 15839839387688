import React from 'react';

import { size } from 'lodash';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import ButtonDropdown from 'app/components/ButtonDropdown';
import Icon from 'app/components/Icon';
import useDataProviderContext from 'app/hooks/useDataProviderContext';

export default function DownloadFiles() {
  const intl = useIntl();
  const { downloadFilesLinks } = useDataProviderContext();

  if (size(downloadFilesLinks) === 0) return null;

  return (
    <div className="attach-files-modal">
      <ButtonDropdown
        dropdownOptions={{
          options: downloadFilesLinks,
          onSelected: (downloadLink) => {
            window.location.href = downloadLink;
          }
        }}
        buttonOptions={{
          text: intl.formatMessage({ id: 'controlpanel.global.download' }),
          type: 'default',
          icon: <Icon icon="file-import" color={Colors.bbSecondary} />
        }}
      />
    </div>
  );
}
