import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import Button from '../Button';

import 'app/components/NotificationDialog/NotificationDialog.scss';

export default function NotificationDialog({ data, show, setShow }) {
  const { title, description, button, image } = data;
  const handleClose = () => {
    setShow(false);
    window.open(button.url, '_SELF');
  };
  return (
    <Modal size="md" show={show} onHide={() => setShow(false)}>
      <Modal.Body className="data-table-column-order">
        <div className="notification-dialog">
          <div
            className="notification-dialog-close"
            aria-label="close"
            role="button"
            tabIndex="0"
            onClick={() => setShow(false)}
            onKeyDown={() => setShow(false)}
          >
            <i className="fa-duotone fa-circle-xmark fa-lg" />
          </div>
          {image && (
            <img
              className="notification-dialog-image"
              src={image.url}
              alt={image.name}
            />
          )}
          <div className="notification-dialog-info">
            <h5 className="notification-dialog-title bold">{title}</h5>
            <p
              className="notification-dialog-description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {button && (
              <>
                <div className="notification-dialog-separator" />
                <Button
                  type="primary"
                  role="link"
                  text={button.content}
                  onClick={handleClose}
                />
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

NotificationDialog.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }),
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button: PropTypes.shape({
      content: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  }).isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired
};
