export default function prestashopData(data) {
  const formData = [
    {
      sectionTitle: 'controlpanel.prestashop_form.label.ssh_ftp.title',
      data: [
        {
          title: 'controlpanel.prestashop_form.label.host',
          input: {
            text: data.sshHost
          }
        },
        {
          title: 'controlpanel.prestashop_form.label.user',
          input: {
            text: data.sshUser
          }
        },
        {
          title: 'controlpanel.prestashop_form.label.pass',
          input: {
            text: data.sshPassword
          }
        },
        {
          title: 'controlpanel.prestashop_form.label.port',
          input: {
            text: data.sshPort
          }
        }
      ]
    },
    {
      sectionTitle: 'controlpanel.prestashop_form.label.access_shop.title',
      data: [
        {
          title: 'controlpanel.prestashop_form.label.url',
          input: {
            text: data.shopUrl
          }
        },
        {
          title: 'controlpanel.prestashop_form.label.user',
          input: {
            text: data.shopUser
          }
        },
        {
          title: 'controlpanel.prestashop_form.label.pass',
          input: {
            text: data.shopPassword
          }
        }
      ]
    },
    {
      sectionTitle: 'controlpanel.prestashop_form.label.access_blog.title',
      data: [
        {
          title: 'controlpanel.prestashop_form.label.url',
          input: {
            text: data.blogUrl
          }
        },
        {
          title: 'controlpanel.prestashop_form.label.user',
          input: {
            text: data.blogUser
          }
        },
        {
          title: 'controlpanel.prestashop_form.label.pass',
          input: {
            text: data.blogPassword
          }
        }
      ]
    },
    {
      sectionTitle: 'controlpanel.prestashop_form.label.email.title',
      data: [
        {
          title: 'controlpanel.prestashop_form.label.email.webmail',
          input: {
            text: data.emailUrl
          }
        },
        {
          title: 'controlpanel.prestashop_form.label.user',
          input: {
            text: data.emailUser
          }
        },
        {
          title: 'controlpanel.prestashop_form.label.pass',
          input: {
            text: data.emailPassword
          }
        }
      ]
    },
    {
      sectionTitle: 'controlpanel.prestashop_form.label.base_data.title',
      data: [
        {
          title: 'controlpanel.prestashop_form.label.user_blog',
          input: {
            text: data.databaseUser
          }
        },
        {
          title: 'controlpanel.prestashop_form.label.pass',
          input: {
            text: data.databasePassword
          }
        }
      ]
    },
    {
      sectionTitle: 'controlpanel.prestashop_form.label.base_data_blog.title',
      data: [
        {
          title: 'controlpanel.prestashop_form.label.user_blog',
          input: {
            text: data.blogDatabaseUser
          }
        },
        {
          title: 'controlpanel.prestashop_form.label.pass',
          input: {
            text: data.blogDatabasePassword
          }
        }
      ]
    }
  ];

  return formData;
}
