import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { size } from 'lodash';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { formatFiltersToEndpoint } from 'app/utils/filters';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import Popup from 'app/components/Popup';
import Spinner from 'app/components/Spinner';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';
import { genericRequest } from 'app/api';
import { setProductsInCart } from 'app/modules/Auth/_redux/AuthActions';
import { useDispatch } from 'react-redux';

export default function PopupAddToCart({ show, setShow, id = null }) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const intl = useIntl();
  const {
    datatable: {
      rows: { excludedRows, selectedRows, selectAll }
    },
    filters: { filters },
    context: { readContextStorage }
  } = useDataTableContext();
  const { wishlistId } = readContextStorage('wishlistData');

  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
    setDisabled(false);
  };

  const attributes = {};
  const formatedFilters = formatFiltersToEndpoint(filters);

  if (id) {
    attributes.includedIds = [id];
  } else {
    attributes.all = selectAll;
    attributes.includedIds = selectedRows;
    attributes.excludedIds = excludedRows;
  }

  if (size(formatedFilters) > 0) {
    attributes.filter = formatedFilters;
  }

  return (
    <Popup
      show={show}
      header={
        <div className="bb-popup-repeat-order__header">
          <p className="bold">
            {intl.formatMessage({
              id: 'controlpanel.global.add_to_cart'
            })}
          </p>
        </div>
      }
      body={
        <div className="bb-popup-repeat-order__body">
          <p>
            {intl.formatMessage({
              id: 'controlpanel.global.add_to_cart.text'
            })}
          </p>
        </div>
      }
      footer={
        <div className="bb-popup-repeat-order__footer">
          <Button
            text={intl.formatMessage({
              id: 'controlpanel.global.close'
            })}
            type="default"
            size="big"
            onClick={handleClose}
          />
          <Button
            text={intl.formatMessage({ id: 'controlpanel.global.add_to_cart' })}
            type="primary"
            size="big"
            disabled={disabled}
            icon={
              loading === false ? (
                <Icon
                  icon="shopping-cart"
                  color={Colors.bbBlackPure}
                  prefix="fas"
                />
              ) : (
                <Spinner />
              )
            }
            onClick={async () => {
              setDisabled(true);
              const result = await genericRequest({
                url: `wishlists/${wishlistId}/add-products-to-cart`,
                method: 'POST',
                data: {
                  data: {
                    type: 'add-products-to-cart',
                    attributes
                  }
                },
                getHeaders: true,
                setLoading,
                customSuccessFeedback: {
                  variant: 'success',
                  content: intl.formatMessage({
                    id: 'controlpanel.global.add_to_cart.success'
                  }),
                  autoClose: true,
                  dismissible: false
                }
              });

              if (result.status === 201) {
                // TODO: Cambiar por la respuesta del total
                dispatch(setProductsInCart(result?.data?.meta?.numProducts));
              }

              handleClose();
            }}
          />
        </div>
      }
      handleClose={handleClose}
    />
  );
}

PopupAddToCart.propTypes = {
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  id: PropTypes.string
};
