import React, { useMemo, useState } from 'react';

import { size } from 'lodash';
import { useIntl } from 'react-intl';

import Card from 'app/components/Card';
import DataProviderContext from 'app/context/DataProviderContext';
import ImportManualCard from 'app/modules/Orders/pages/OrderByCsv/components/ImportManualCard';
import ResultUploadForm from 'app/modules/Orders/pages/OrderByCsv/components/ResultUploadForm';
import UploadForm from 'app/modules/Orders/pages/OrderByCsv/components/UploadForm';

import 'app/modules/Orders/pages/OrderByCsv/OrderByCsv.scss';
import { Col, Row } from 'react-bootstrap';
import BasicTableConfig from 'app/components/DataTable/utils/BasicTableConfig';

export default function OrderByCsv() {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [entities, setEntities] = useState([]);
  const [canSendFile, setCanSendFile] = useState(true);
  const [countPendingOrders, setCountPendingOrders] = useState(0);
  const [totalRequestedOrders, setTotalRequestedOrders] = useState(0);

  const defaultOptions = BasicTableConfig();
  const keyField = 'csvLine';
  const [options, setOptions] = useState({
    sizePerPageList: [
      { text: '10', value: 10 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ],
    sizePerPage: 10,
    page: 1,
    defaultSorted: [
      {
        dataField: keyField,
        order: 'asc'
      }
    ],
    keyField,
    showTotal: true,
    ...defaultOptions
  });

  const valueProvider = useMemo(
    () => ({
      loading,
      setLoading,
      entities,
      setEntities,
      options,
      setOptions,
      canSendFile,
      setCanSendFile,
      countPendingOrders,
      setCountPendingOrders,
      loadingForm,
      setLoadingForm,
      totalRequestedOrders,
      setTotalRequestedOrders
    }),
    [
      loading,
      entities,
      options,
      canSendFile,
      countPendingOrders,
      loadingForm,
      totalRequestedOrders
    ]
  );

  return (
    <DataProviderContext.Provider value={valueProvider}>
      <Row className="order-by-csv">
        <Col md={8}>
          <Card
            extraClass="order-by-csv__import-manual-card"
            body={<ImportManualCard />}
          />
        </Col>
        <Col md={4}>
          <Card
            icon="upload"
            title={intl.formatMessage({
              id: loading
                ? 'controlpanel.global.orders.status.processing_csv'
                : 'controlpanel.orders.csv.upload.title'
            })}
            extraClass="order-by-csv__upload-form"
            body={<UploadForm />}
          />
        </Col>
        {size(entities) > 0 && (
          <Col md={12}>
            <Card
              extraClass="order-by-csv__result-upload-form"
              body={<ResultUploadForm />}
            />
          </Col>
        )}
      </Row>
    </DataProviderContext.Provider>
  );
}
