export const isValidDate = (date) =>
  // eslint-disable-next-line no-restricted-globals
  date && new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));

export const formatDateToLocale = (
  date,
  locale = 'es',
  config = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }
) =>
  isValidDate(date) ? new Date(date).toLocaleDateString(locale, config) : '';

export const formatDateByFormat = (inputDate, format = 'yyyy-mm-dd') => {
  const date = new Date(inputDate);
  const map = {
    dd: date.getDate().toString().padStart(2, '0'),
    mm: (date.getMonth() + 1).toString().padStart(2, '0'),
    yyyy: date.getFullYear(),
    yy: date.getFullYear().toString().slice(-2)
  };
  return format.replace(/dd|mm|yyyy|yy/gi, (matched) => map[matched]);
};

export const formatHours = (inputDate) => {
  const date = new Date(inputDate);
  return (
    // eslint-disable-next-line prefer-template
    date.getHours().toString().padStart(2, '0') +
    ':' +
    date.getMinutes().toString().padStart(2, '0') +
    ':' +
    date.getSeconds().toString().padStart(2, '0')
  );
};
export const getHoursAndMinutes = (date) =>
  `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;

export const formatDayByMonth = (day, month) => {
  if (day > 28 && month === 2) {
    return 28;
  }
  if (day > 30) {
    if (month === 4 || month === 6 || month === 9 || month === 11) {
      return 30;
    }
  }
  return day;
};

export const getDateRange = (range = 6) => {
  const date = new Date();
  const actualMonth = formatDateToLocale(date, 'es', { month: 'numeric' });
  const actualDay = formatDateToLocale(date, 'es', { day: 'numeric' });
  let rangeYear = formatDateToLocale(date, 'es', { year: 'numeric' });
  let rangeMonth;

  if (actualMonth - range <= 0) {
    rangeYear -= 1;
    rangeMonth = 12 + (actualMonth - range);
  } else {
    rangeMonth = actualMonth - range;
  }
  const rangeDay = formatDayByMonth(actualDay, rangeMonth);
  const newDate = `${rangeYear}-${rangeMonth}-${rangeDay}`;
  return new Date(newDate);
};
