import React from 'react';

import Icon from 'app/components/Icon';

import { Colors } from 'app/utils/colors';

const red = Colors.bbRed;

const AlertFormatter = () => <Icon icon="bell-exclamation" color={red} />;

export default AlertFormatter;
