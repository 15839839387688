import React from 'react';

import { useIntl } from 'react-intl';
import { size } from 'lodash';

import { SIZE } from 'app/utils/sizes';
import { useWindow } from 'app/hooks/useWindow.hook';
import CreditCardsDetail from 'app/modules/MyAccount/pages/PaymentMethods/components/CreditCards/CreditCardsDetail';
import PaycometDetail from 'app/modules/MyAccount/pages/PaymentMethods/components/CreditCards/PaycometDetail';
import Divider from 'app/components/Divider';
import usePaymentMethodsContext from 'app/modules/MyAccount/pages/PaymentMethods/context/PaymentMethodsContext';

import './CreditCardsDetails.scss';

export default function CreditCardsDetails() {
  const intl = useIntl();
  const { width } = useWindow();
  const mobile = width < SIZE.MD;
  const { creditCards, paycomet, hasCreditCards, hasPaycomet } =
    usePaymentMethodsContext();
  return (
    <div className="bb-payment-methods-credit-cards__credit-cards-items">
      <div className="bb-payment-methods-credit-cards__credit-cards-items--header">
        <p className="medium">
          {intl.formatMessage({
            id: 'controlpanel.payment_methods.credit_cards_details.title'
          })}
        </p>
      </div>
      <div className="bb-payment-methods-credit-cards__credit-cards-items--content">
        {hasCreditCards &&
          creditCards.map(({ id, ...creditCard }, index) => (
            <>
              <CreditCardsDetail
                key={index}
                {...creditCard}
                id={id}
                mobile={mobile}
              />
              {index + 1 !== size(creditCards) && <Divider type="dash" />}
            </>
          ))}
        {hasPaycomet &&
          paycomet.map(({ id, ...creditCard }, index) => (
            <>
              <PaycometDetail
                key={index}
                {...creditCard}
                id={id}
                mobile={mobile}
              />
              {index + 1 !== size(creditCards) && <Divider type="dash" />}
            </>
          ))}
      </div>
    </div>
  );
}
