import React, { useRef, useLayoutEffect, useMemo } from 'react';

import { useIntl } from 'react-intl';

import { MAP_STATUS_DEVOLUTION } from 'app/modules/Devolutions/utils/mapStatus';
import { useSubheader } from 'app/context/SubheaderContext';
import Card from 'app/components/Card';
import NotificationDevolution from 'app/modules/Devolutions/components/NotificationDevolution';
import Spinner from 'app/components/Spinner';
import TableProducts from 'app/modules/Devolutions/components/TableProducts';
import TableProductsHeader from 'app/modules/Devolutions/components/TableProductsHeader';
import useRMAContext from 'app/modules/Devolutions/context/context';

import 'app/modules/Devolutions/pages/Add/Add.scss';

export default function Add() {
  const intl = useIntl();

  const { rmaId, loading, rmaStatus, rmaApiInfo } = useRMAContext();
  const internalRmaId = rmaApiInfo?.attributes?.code?.toUpperCase();
  const alertAditionalContent =
    MAP_STATUS_DEVOLUTION?.[rmaStatus]?.alertAditionalContent;
  const iconCard = MAP_STATUS_DEVOLUTION?.[rmaStatus]?.alert?.iconCard;

  const container = useRef(null);

  const { setTitle, setBreadcrumbs, setIcon } = useSubheader();
  const newBreadCrumbs = useMemo(
    () => [
      {
        pathname: '/devolutions',
        title: intl.formatMessage({ id: 'controlpanel.asidemenu.devolutions' })
      },
      {
        pathname: `/devolutions/add/${rmaId}`,
        title: intl.formatMessage(
          { id: 'controlpanel.global.devolution_number' },
          {
            number: internalRmaId || ''
          }
        )
      }
    ],
    [intl, internalRmaId, rmaId]
  );

  const containerHeight = useMemo(() => {
    if (container?.current?.clientHeight) {
      return container?.current?.clientHeight;
    }
    return containerHeight;
    // Evita saltos. Actualizar solo cuando haya altura
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container?.current?.clientHeight]);

  useLayoutEffect(() => {
    setTitle(
      intl.formatMessage(
        { id: 'controlpanel.global.devolution_number' },
        {
          number: internalRmaId || ''
        }
      ) || intl.formatMessage({ id: 'controlpanel.asidemenu.devolutions' })
    );
    setIcon({ icon: 'retweet' });
    setBreadcrumbs(newBreadCrumbs);
  }, [setTitle, setIcon, setBreadcrumbs, newBreadCrumbs, intl, internalRmaId]);

  if (!rmaId) {
    return <Spinner />;
  }

  const cardTitle = getCardTitle(intl, rmaStatus, internalRmaId);

  const showTableInCardAlert =
    rmaStatus === 'PAID' ||
    rmaStatus === 'REJECTED' ||
    rmaStatus === 'PENDING_PAYMENT' ||
    rmaStatus === 'PENDING_CONFIRMATION' ||
    rmaStatus === 'PENDING_VALUATION';

  return (
    <div className="devolutions-add">
      <Card
        extraClass="devolutions-add__card"
        icon={iconCard}
        title={cardTitle}
        body={
          loading ? (
            <div
              style={{
                minHeight: `${containerHeight}px`,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spinner size="4x" />
            </div>
          ) : (
            <div ref={container}>
              {rmaStatus !== 'REJECTED' && (
                <NotificationDevolution rmaStatus={rmaStatus} />
              )}
              {alertAditionalContent}
              {showTableInCardAlert === true && (
                <div className="devolutions-add__card-table">
                  <TableProducts />
                </div>
              )}
            </div>
          )
        }
      />
      {MAP_STATUS_DEVOLUTION?.[rmaStatus]?.extraContent}
      {showTableInCardAlert === false && (
        <Card
          extraClass="devolutions-add__rma-detail"
          icon="tags"
          title={intl.formatMessage(
            { id: 'controlpanel.global.rma_detail' },
            { id: internalRmaId || '' }
          )}
          header={rmaStatus === 'DRAFT' && <TableProductsHeader />}
          body={<TableProducts />}
        />
      )}
    </div>
  );
}

const getCardTitle = (intl, rmaStatus, internalRmaId) => {
  switch (rmaStatus) {
    case 'PENDING_PICK_UP_ADDRESS':
      return intl.formatMessage({
        id: 'controlpanel.global.confirm_pick_up_address'
      });

    case 'PENDING_COLLECTION':
      return intl.formatMessage({
        id: 'controlpanel.global.print_documentation'
      });

    case 'PENDING_PAYMENT':
    case 'PENDING_VALUATION':
    case 'PENDING_CONFIRMATION':
    case 'PAID':
      return intl.formatMessage(
        { id: 'controlpanel.global.rma_detail' },
        { id: internalRmaId || '' }
      );
    case 'REJECTED':
      return intl.formatMessage(
        { id: 'controlpanel.global.rejected' },
        { id: internalRmaId || '' }
      );

    default:
      return intl.formatMessage({ id: 'controlpanel.global.add_product' });
  }
};
