import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';

import { SHOP_SHIPMENT_STATUS } from 'app/utils/constants';

const { SHOP_FINISHED } = SHOP_SHIPMENT_STATUS;

export default function FileFormDescription({
  currentProcessStatus,
  globalDescription = '',
  title = ''
}) {
  const intl = useIntl();

  return (
    <Row>
      <Col
        md={
          currentProcessStatus === SHOP_FINISHED
            ? { span: 7, offset: 2 }
            : { span: 9, offset: 3 }
        }
        className={`shopify-form__input__files__description ${
          title ? '' : 'last'
        } ${currentProcessStatus === SHOP_FINISHED ? 'full-size' : ''}`}
      >
        <p className="small">{intl.formatMessage({ id: globalDescription })}</p>
      </Col>
    </Row>
  );
}

FileFormDescription.propTypes = {
  currentProcessStatus: PropTypes.number.isRequired,
  globalDescription: PropTypes.string,
  title: PropTypes.string
};
