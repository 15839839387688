import React from 'react';
import { useIntl } from 'react-intl';

import IconsList from 'app/components/IconsList';

import { ITEMS } from 'app/pages/MIP/components/constants';
import 'app/pages/MIP/components/MainCard.scss';

export default function MainCard() {
  const intl = useIntl();
  return (
    <>
      <p className="bb-multichannel__main-card-title limit-width">
        {intl.formatMessage({
          id: 'controlpanel.multichannel.main_card.title'
        })}
      </p>
      <IconsList
        items={ITEMS}
        title="controlpanel.multichannel.icons_list.title"
        listClassName="bb-multichannel__main-card-list"
      />
      <p className="bb-multichannel__main-card-subtitle bold limit-width">
        {intl.formatMessage({
          id: 'controlpanel.multichannel.main_card.subtitle'
        })}
      </p>
      <p className="bb-multichannel__main-card-content limit-width">
        {intl.formatMessage({
          id: 'controlpanel.multichannel.main_card.content'
        })}
      </p>
    </>
  );
}
