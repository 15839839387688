import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

// LayoutContext
// Import Layout components
import { Header } from 'app/components/Header/Header';
import { HeaderMobile } from 'app/components/HeaderMobile/HeaderMobile';
import { Aside } from 'app/components/Aside/Aside';
import { SubHeader } from 'app/components/SubHeader/SubHeader';
import { ScrollTop } from 'app/layout/components/ScrollTop';
import { useLang } from 'app/context/i18n';
import { LayoutInit } from 'app/layout/LayoutInit';

import ImpersonateAlert from 'app/components/ImpersonateAlert';
import NotificationDialog from 'app/components/NotificationDialog';
import cmsRequest from 'app/api/cmsRequest';
import { DIALOG_COOKIE } from 'app/utils/constants';

export function Layout({ children = null }) {
  const { user } = useSelector((state) => state.auth);
  const locale = useLang();
  const IMPERSONATE = user?.attributes?.impersonated;
  const [show, setShow] = useState(false);
  const [dataDialog, setDataDialog] = useState(null);

  useEffect(() => {
    const setCMSDialog = async () => {
      const response = await cmsRequest({ api: 'dialog', locale });
      setDataDialog(response.data);
    };
    setCMSDialog();
  }, [locale]);

  useEffect(() => {
    if (dataDialog) {
      const validateCookie = () => {
        const dialogCookie = Cookies.get(DIALOG_COOKIE);
        if (dialogCookie !== dataDialog.name) {
          setShow(true);
          Cookies.set(DIALOG_COOKIE, dataDialog.name, {
            expires: 4
          });
        }
      };
      validateCookie();
    }
  }, [dataDialog]);

  return (
    <>
      {/* begin::Main */}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/* begin::Page */}
        <div className="d-flex flex-row flex-column-fluid page">
          <Aside />
          {/* begin::Wrapper */}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <Header />
            {/* begin::Content */}
            {IMPERSONATE && <ImpersonateAlert />}
            {dataDialog && (
              <NotificationDialog
                data={dataDialog}
                show={show}
                setShow={setShow}
              />
            )}

            <div
              id="kt_content"
              className={`${
                IMPERSONATE === true ? 'impersonate' : ''
              } content d-flex flex-column flex-column-fluid`}
            >
              <SubHeader impersonate={IMPERSONATE} />
              {/* begin::Entry */}
              <div className="d-flex flex-column-fluid">
                {/* begin::Container */}
                <div className="container">{children}</div>
                {/* end::Container */}
              </div>
              {/* end::Entry */}
            </div>
            {/* end::Content */}
            {/* UnComment to show footer */}
            {/* <Footer /> */}
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Page */}
      </div>
      <ScrollTop />
      {/* <StickyToolbar /> */}
      {/* end::Main */}
      <LayoutInit />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
