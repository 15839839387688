import React, { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { Row, Col, Image } from 'react-bootstrap';

import { b64toBlob } from 'app/utils/file-conversor64';
import { Colors } from 'app/utils/colors';
import { SHOP_SHIPMENT_STATUS } from 'app/utils/constants';
import { SIZE } from 'app/utils/sizes';
import { useWindow } from 'app/hooks/useWindow.hook';
import Button from 'app/components/Button';
import CropImageModal from 'app/modules/Shops/pages/Shopify/components/CropImageModal';
import FileFormDescription from 'app/modules/Shops/pages/Shopify/components/FileFormDescription';
import Icon from 'app/components/Icon';

import 'app/modules/Shops/pages/Shopify/components/FileForm.scss';

const { ENTER_DETAILS, WRONG_REQUIREMENTS, SHOP_FINISHED } =
  SHOP_SHIPMENT_STATUS;
export default function FileForm({
  currentProcessStatus,
  content: {
    field: { name, type, accept, file },
    title,
    label,
    warning,
    description: globalDescription
  },
  disabled = false
}) {
  const intl = useIntl();
  const inputFile = useRef(null);
  const formik = useFormikContext();
  const { width } = useWindow();
  const mobile = width < SIZE.MD;

  const [image, setImage] = useState(file);
  const [croppedImage, setCroppedImage] = useState(null);
  const [provisionalCroppedImage, setProvisionalCroppedImage] = useState(null);
  const [show, setShow] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);

  useEffect(() => {
    if (croppedImage) {
      formik.setFieldValue(name, croppedImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedImage]);

  useEffect(() => {
    if (disabled === true) {
      formik.setFieldValue(name, null);
      setImage(null);
      setShow(false);
      setProvisionalCroppedImage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const isDisabled = useCallback(() => {
    if (
      currentProcessStatus === ENTER_DETAILS ||
      currentProcessStatus === WRONG_REQUIREMENTS
    ) {
      return false;
    }
    return true;
  }, [currentProcessStatus]);

  useEffect(() => {
    const base64File = formik.values?.[name];

    if (!base64File) {
      return;
    }

    setImage(base64File);
    setCroppedImage(base64File);
    setProvisionalCroppedImage(base64File);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenDialog = () => {
    setProvisionalCroppedImage(null);
    setDisabledSaveButton(true);
    setShow(true);
    if (!provisionalCroppedImage) {
      inputFile.current.click();
    }
  };

  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      setImage(URL.createObjectURL(files[0]));
      formik.setFieldValue(name, files[0]);
    }
  };

  const onUpdateModal = () => {
    setDisabledSaveButton(true);
    inputFile.current.click();
  };

  const onCancelModal = () => {
    setShow(false);
  };

  const onSaveModal = () => {
    setShow(false);
    setCroppedImage(provisionalCroppedImage);
  };

  const onCropImage = (imageFile) => {
    setDisabledSaveButton(false);
    setProvisionalCroppedImage(imageFile);
  };

  const handleDownloadImage = () => {
    const base64File = formik.values?.[name];

    if (!base64File) {
      return;
    }

    const [metadata, byteCharacters] = base64File.split(',');
    const [dataType] = metadata.split(';');
    const contentType = dataType.replace('data:', '');

    const blob = b64toBlob(byteCharacters, contentType);
    const url = URL.createObjectURL(blob);

    const extension = blob.type.split('/')[1];

    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', `${name}.${extension}`);
    downloadLink.click();
  };

  return (
    <>
      {show && image && (
        <CropImageModal
          name={name}
          image={image}
          label={label}
          show={show}
          onCancelModal={onCancelModal}
          onUpdateModal={onUpdateModal}
          disabledSaveButton={disabledSaveButton}
          onSaveModal={onSaveModal}
          onCropImage={onCropImage}
        />
      )}
      <div className="shopify-form__container">
        {title && (
          <Row className="shopify-form__container-header">
            <Col
              md={
                currentProcessStatus === SHOP_FINISHED
                  ? { span: 10, offset: 2 }
                  : { span: 9, offset: 3 }
              }
              className="shopify-form__container-title"
            >
              <p className="big medium">{intl.formatMessage({ id: title })}</p>
            </Col>
          </Row>
        )}

        <Row className="shopify-form__input-wrapper files">
          <Col
            md={currentProcessStatus === SHOP_FINISHED ? 2 : 3}
            className="shopify-form__input__files__label"
          >
            <p>{intl.formatMessage({ id: label })}</p>
          </Col>
          <Col
            md={currentProcessStatus === SHOP_FINISHED ? 10 : 9}
            className="shopify-form__input"
          >
            <div className="shopify-form__input__files__container__row">
              {croppedImage ? (
                <Image
                  src={croppedImage}
                  alt="file"
                  style={{ objectFit: 'contain' }}
                />
              ) : (
                <div className="shopify-form__input__files__container__row__logo" />
              )}
              {isDisabled() || (
                <span role="none" onClick={onOpenDialog}>
                  <input
                    id={name}
                    name={name}
                    type={type}
                    accept={accept}
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                    disabled={isDisabled() || disabled}
                  />

                  <Icon icon="pen-to-square" color={Colors.bbGrey3} size="lg" />
                </span>
              )}
              <div
                className={`shopify-form__input__files__container__row__title ${
                  currentProcessStatus === SHOP_FINISHED && 'full-size'
                }`}
              >
                <p>{intl.formatMessage({ id: warning })}</p>
                {mobile && (
                  <FileFormDescription
                    currentProcessStatus={currentProcessStatus}
                    globalDescription={globalDescription}
                    title={title}
                  />
                )}
                {currentProcessStatus === SHOP_FINISHED && (
                  <Button
                    text={intl.formatMessage({
                      id: `controlpanel.shopify_form.download_button.${name}`
                    })}
                    type="secondary"
                    size="big"
                    icon={
                      <Icon icon="download" color={Colors.bbWhite} size="lg" />
                    }
                    onClick={handleDownloadImage}
                  />
                )}
              </div>
            </div>
            {formik.touched[name] && formik.errors[name] ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors[name]}</div>
              </div>
            ) : null}
          </Col>
        </Row>
        {!mobile && (
          <FileFormDescription
            currentProcessStatus={currentProcessStatus}
            globalDescription={globalDescription}
            title={title}
          />
        )}
      </div>
    </>
  );
}

FileForm.propTypes = {
  currentProcessStatus: PropTypes.number.isRequired,
  content: PropTypes.shape().isRequired,
  disabled: PropTypes.bool
};
