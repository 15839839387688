import { map } from 'lodash';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';

export default function useOrdersStatus() {
  const intl = useIntl();
  const {
    bbGreen,
    bbGreenLight,
    bbPurple,
    bbPurpleLight,
    bbOrange,
    bbOrangeLight,
    bbRed,
    bbRedLight,
    bbSecondary,
    bbSecondaryLight
  } = Colors;

  return {
    NOT_VISIBLE: {
      status: 'NOT_VISIBLE',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.not_visible'
      }),
      color: bbPurple,
      backgroundColor: bbPurpleLight,
      badgeColor: 'purple'
    },
    PENDING_PAYMENT: {
      status: 'PENDING_PAYMENT',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.pending_payment'
      }),
      color: bbOrange,
      backgroundColor: bbOrangeLight,
      badgeColor: 'orange'
    },
    PROCESSING: {
      status: 'PROCESSING',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.processing'
      }),
      color: bbPurple,
      backgroundColor: bbPurpleLight,
      badgeColor: 'purple'
    },
    PAYMENT_INCIDENT: {
      status: 'PAYMENT_INCIDENT',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.payment_incident'
      }),
      color: bbOrange,
      backgroundColor: bbOrangeLight,
      badgeColor: 'orange'
    },
    SHIPPED: {
      status: 'SHIPPED',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.shipped'
      }),
      color: bbGreen,
      backgroundColor: bbGreenLight,
      badgeColor: 'green'
    },
    CANCELLED: {
      status: 'CANCELLED',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.cancelled'
      }),
      color: bbRed,
      backgroundColor: bbRedLight,
      badgeColor: 'red'
    },
    PROCESSED: {
      status: 'PROCESSED',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.processed'
      }),
      color: bbPurple,
      backgroundColor: bbPurpleLight,
      badgeColor: 'purple'
    },
    PAYPAL_VALIDATION_PENDING: {
      status: 'PAYPAL_VALIDATION_PENDING',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.paypal_validation_pending'
      }),
      color: bbOrange,
      backgroundColor: bbOrangeLight,
      badgeColor: 'orange'
    },
    VALIDATION_PENDING: {
      status: 'VALIDATION_PENDING',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.validation_pending'
      }),
      color: bbOrange,
      backgroundColor: bbOrangeLight,
      badgeColor: 'orange'
    },
    DELIVERED: {
      status: 'DELIVERED',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.delivered'
      }),
      color: bbGreen,
      backgroundColor: bbGreenLight,
      badgeColor: 'green'
    },
    PARTIALLY_SHIPPED: {
      status: 'PARTIALLY_SHIPPED',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.partially_shipped'
      }),
      color: bbGreen,
      backgroundColor: bbGreenLight,
      badgeColor: 'green'
    },
    PENDING_INVOICING: {
      status: 'PENDING_INVOICING',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.pending_invoicing'
      }),
      color: bbOrange,
      backgroundColor: bbOrangeLight,
      badgeColor: 'orange'
    },
    RETURNED: {
      status: 'RETURNED',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.returned'
      }),
      color: bbSecondary,
      backgroundColor: bbSecondaryLight,
      badgeColor: 'blue'
    },
    CANCELLED_PAYMENT: {
      status: 'CANCELLED_PAYMENT',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.cancelled_payment'
      }),
      color: bbRed,
      backgroundColor: bbRedLight,
      badgeColor: 'red'
    },
    REIMBURSED_PAYMENT: {
      status: 'REIMBURSED_PAYMENT',
      translation: intl.formatMessage({
        id: 'controlpanel.global.orders.status.reimbursed_payment'
      }),
      color: bbSecondary,
      backgroundColor: bbSecondaryLight,
      badgeColor: 'blue'
    }
  };
}

export const GetSelectStructure = () => {
  const orderStatus = useOrdersStatus();
  const selectStructure = [];

  map(orderStatus, (data, status) => {
    selectStructure.push({
      value: status,
      label: data.translation
    });
  });

  return selectStructure;
};
