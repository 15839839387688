import React from 'react';

import { URLS } from 'app/config/urls';

import ShopsPage from 'app/modules/Shops';
import ShopsLayout from 'app/modules/Shops/pages';

export const shopsRoutes = {
  path: URLS.shops,
  children: [
    { path: '', element: <ShopsPage /> },
    { path: ':id', element: <ShopsLayout /> }
  ]
};
