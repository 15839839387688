import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'app/components/Icon';

import 'app/components/Spinner/Spinner.scss';

export default function Spinner({
  containerHeight = 0,
  containerWidth = 0,
  ...rest
}) {
  const minimumMeasures = {
    minHeight: `${containerHeight}px`,
    minWidth: `${containerWidth}px`
  };

  return (
    <div className="bb-spinner-container" style={minimumMeasures}>
      <Icon
        icon="circle-notch"
        spin
        prefix={rest.prefix || 'fad'}
        className="fa-spin"
        {...rest}
      />
    </div>
  );
}

Spinner.propTypes = {
  containerHeight: PropTypes.number,
  containerWidth: PropTypes.number
};
