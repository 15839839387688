import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { clone } from 'lodash';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import DataTableColumnOrder from 'app/components/DataTableColumnOrder';
import Icon from 'app/components/Icon';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

export default function ColumnReorderModal({
  className = null,
  buttonClassName = null
}) {
  const {
    datatable: {
      columns: { draggableColumns, reorderColumns }
    }
  } = useDataTableContext();
  const intl = useIntl();
  const [show, setShow] = useState(false);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    const items = reorder(
      draggableColumns,
      result.source.index,
      result.destination.index
    );
    reorderColumns(items);
  };

  const onChangeCheckbox = (index) => {
    const newColumns = clone(draggableColumns);
    newColumns[index].hidden = !draggableColumns[index].hidden;
    reorderColumns(newColumns);
  };

  return (
    <div className={className}>
      <Button
        onClick={() => setShow(true)}
        text={intl.formatMessage({ id: 'controlpanel.global.columns' })}
        size="big"
        icon={<Icon icon="columns" prefix="fas" color={Colors.bbSecondary} />}
        className={buttonClassName}
      />
      <Modal
        size="sm"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="modal-columns"
      >
        <Modal.Body className="data-table-column-order">
          <DataTableColumnOrder
            title={intl.formatMessage({ id: 'controlpanel.global.columns' })}
            onDragEnd={onDragEnd}
            onChangeCheckbox={onChangeCheckbox}
            columns={draggableColumns}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

ColumnReorderModal.propTypes = {
  buttonClassName: PropTypes.string,
  className: PropTypes.string
};
