import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import {
  getInitialValues,
  getValidationSchema
} from 'app/modules/MyAccount/pages/UserData/utils';
import { genericRequest } from 'app/api';
import { setUser } from 'app/modules/Auth/_redux/AuthActions';
import { URLS } from 'app/config/urls';
import Button from 'app/components/Button';
import FormCard from 'app/components/FormCard';
import FormSpinner from 'app/components/FormSpinner';
import MyAccountLayout from 'app/modules/MyAccount/components/MyAccountLayout';
import UserForm from 'app/modules/MyAccount/pages/UserData/UserForm/UserForm';

export default function UserData() {
  const intl = useIntl();

  const [clearImage, setClearImage] = useState(false);
  const [inputLoading, setInputLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const handleSubmit = async (values) => {
    setIsLoading(true);

    const { email, company, verification, file } = values;
    const attributes = {
      dropshippingName: company,
      multifactorAuthenticationEnabled: verification === 'yes'
    };

    if (file) {
      attributes.logo = file;
    }

    if (email.toLowerCase() !== user?.attributes?.email.toLowerCase()) {
      attributes.email = email.toLowerCase();
    }

    try {
      await genericRequest({
        url: `customers/me`,
        method: 'PATCH',
        data: {
          data: {
            id: values.clientId,
            type: 'customer',
            attributes
          }
        },
        customSuccessFeedback: {
          variant: 'success',
          content: intl.formatMessage({
            id: 'controlpanel.feedback.save_changes.success'
          }),
          autoClose: true,
          dismissible: false
        }
      });
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }

    const newUserData = await genericRequest({ url: 'customers/me' });
    dispatch(setUser(newUserData.data));

    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <MyAccountLayout currentPagePath={URLS.myAccountUserData}>
        <Col md={8}>
          <FormSpinner
            title="controlpanel.my_account.user_data.title"
            showButtons
          />
        </Col>
      </MyAccountLayout>
    );
  }

  return (
    <MyAccountLayout currentPagePath={URLS.myAccountUserData}>
      <Col md={8}>
        <Formik
          initialValues={getInitialValues(user)}
          validationSchema={getValidationSchema(intl, setInputLoading, user)}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Row>
              <Col md={12}>
                <FormCard
                  title={intl.formatMessage({
                    id: 'controlpanel.my_account.user_data.title'
                  })}
                  buttons={
                    <>
                      <Button
                        size="big"
                        text={intl.formatMessage({
                          id: 'controlpanel.global.cancel'
                        })}
                        onClick={() => {
                          formik.resetForm();
                          setClearImage(true);
                        }}
                      />
                      <Button
                        size="big"
                        type="secondary"
                        typeButton="submit"
                        text={intl.formatMessage({
                          id: 'controlpanel.global.save'
                        })}
                        onClick={formik.handleSubmit}
                        disabled={!(formik.isValid && formik.dirty)}
                      />
                    </>
                  }
                >
                  <UserForm
                    formik={formik}
                    clearImage={clearImage}
                    onClearImage={() => setClearImage(false)}
                    inputLoading={inputLoading}
                  />
                </FormCard>
              </Col>
            </Row>
          )}
        </Formik>
      </Col>
    </MyAccountLayout>
  );
}
