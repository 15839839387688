import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import { PLATFORMS_NAMES_MAP } from 'app/modules/Shops/utils/constants';
import { getRedColumnsStatus } from 'app/hooks/useShopsStatus.hook';

export const FormatPlatform = ({ platform, statusKey }) => {
  let classes = 'bb-shops__data-table__cell small';
  const message = PLATFORMS_NAMES_MAP[platform];

  if (getRedColumnsStatus().includes(statusKey)) {
    classes += ' warning';
  }

  return (
    <p className={classes}>
      <FormattedMessage id={message} />
    </p>
  );
};

FormatPlatform.propTypes = {
  platform: PropTypes.string.isRequired,
  statusKey: PropTypes.string.isRequired
};
