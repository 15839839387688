import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import Textarea from 'app/components/Textarea';
import useRMAContext from 'app/modules/Devolutions/context/context';

const EditReason = ({ returnReason, id }) => {
  const { isEdittingId } = useRMAContext();
  const intl = useIntl();
  const formik = useFormikContext();

  useEffect(() => {
    if (isEdittingId === id) {
      formik.setFieldValue('lineDescription', returnReason);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEdittingId]);

  if (isEdittingId === id) {
    return (
      <Textarea
        tooltipText={intl.formatMessage({
          id: 'controlpanel.devolutions.tooltip.description'
        })}
        name="lineDescription"
        placeholder={returnReason}
      />
    );
  }

  return <p>{returnReason}</p>;
};

EditReason.propTypes = {
  returnReason: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default EditReason;
