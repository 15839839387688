import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import PopupDelete from 'app/modules/Devolutions/components/TableProducts/formatters/ProductFormatter/components/PopupDelete';

const DeleteAction = ({ id, sku, rmaId, enabled, productDescription }) => {
  const intl = useIntl();
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  return (
    <>
      <Button
        icon={<Icon icon="trash" color={Colors.bbSecondary} />}
        size="medium"
        tooltipText={intl.formatMessage({ id: 'controlpanel.global.delete' })}
        onClick={() => setShowDeletePopup(true)}
        disabled={!enabled}
      />
      <PopupDelete
        setShow={setShowDeletePopup}
        show={showDeletePopup}
        id={id}
        sku={sku}
        rmaId={rmaId}
        productDescription={productDescription}
      />
    </>
  );
};

DeleteAction.propTypes = {
  id: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  rmaId: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  productDescription: PropTypes.string.isRequired
};

export default DeleteAction;
