import React from 'react';

import { SelectDisable } from 'app/components/DataTableFilters/Components';
import Checkbox from 'app/components/Checkbox';
import Input from 'app/components/Input';

export const INITIAL_VALUES = {
  reference: '',
  stock: '',
  pvp: [],
  pvd: [],
  discount: [],
  search: ''
};

export const getFiltersConfiguration = ({ intl, formik }) => [
  {
    value: 'all',
    label: intl.formatMessage({ id: 'controlpanel.global.all' }),
    disabled: false,
    component: (
      <SelectDisable
        text={intl.formatMessage({
          id: 'controlpanel.global.placeholder.select'
        })}
      />
    )
  },
  {
    value: 'reference',
    label: intl.formatMessage({ id: 'controlpanel.global.reference' }),
    disabled: false,
    component: (
      <div data-id="reference">
        <Input
          name="reference"
          placeholder={intl.formatMessage({
            id: 'controlpanel.global.reference'
          })}
        />
      </div>
    )
  },
  {
    value: 'stock',
    label: intl.formatMessage({ id: 'controlpanel.global.stock' }),
    disabled: false,
    component: (
      <div data-id="stock">
        <Checkbox
          formik={formik}
          id="radio1"
          name="stock"
          type="radio"
          label="Si"
          value="true"
        />
        <Checkbox
          formik={formik}
          id="radio2"
          name="stock"
          type="radio"
          label="No"
          value="false"
        />
      </div>
    )
  }
];
