import React, { useLayoutEffect, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { Col, Row } from 'react-bootstrap';

import { MainCard, MIPCard } from 'app/pages/MIP/components';
import { useSubheader } from 'app/context/SubheaderContext';
import Card from 'app/components/Card';

import 'app/pages/MIP/MultiChannelPage.scss';

export default function MultiChannelPage() {
  const intl = useIntl();
  const subheader = useSubheader();

  const { setTitle, setIcon, setBreadcrumbs } = subheader;

  useLayoutEffect(() => {
    setTitle(
      intl.formatMessage({ id: 'controlpanel.breadcrumbs.multichannel' })
    );
    setIcon({ icon: 'mip', prefix: 'fak' });
    setBreadcrumbs([]);
  }, [intl, setBreadcrumbs, setIcon, setTitle]);

  const marketplacesCard = useMemo(() => {
    const handleClick = () => {
      window.location.replace(
        'https://www.bigbuy.eu/es/integraciones-mip.html'
      );
    };

    return {
      className: 'bb-multichannel__marketplaces-card',
      titleTranslationKey: 'controlpanel.multichannel.marketplaces_card.title',
      subtitleTranslationKey:
        'controlpanel.multichannel.marketplaces_card.content.title',
      contents: ['controlpanel.multichannel.marketplaces_card.content.body'],
      button: {
        text: 'controlpanel.multichannel.marketplaces_card.button',
        handleClick
      }
    };
  }, []);

  const ecommerceCard = useMemo(() => {
    const handleClick = () => {
      window.location.replace(
        'https://www.bigbuy.eu/es/integraciones-mip.html'
      );
    };

    return {
      className: 'bb-multichannel__ecommerce-card',
      titleTranslationKey: 'controlpanel.multichannel.ecommerce_card.title',
      subtitleTranslationKey:
        'controlpanel.multichannel.ecommerce_card.content.title',
      contents: [
        'controlpanel.multichannel.ecommerce_card.content.body_item_1',
        'controlpanel.multichannel.ecommerce_card.content.body_item_2'
      ],
      button: {
        text: 'controlpanel.multichannel.ecommerce_card.button',
        handleClick
      }
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-root bb-multichannel container">
      <div className="d-flex flex-row-fluid flex-column">
        <Row>
          <Col md={12} className="bb-multichannel__main-card">
            <Card
              icon="chart-network"
              title="Multi-channel Integration Platform"
              extraClass="bb-multichannel__mip-card"
              body={<MainCard />}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="bb-multichannel__secondary-cards">
              <MIPCard {...marketplacesCard} />
              <MIPCard {...ecommerceCard} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
