import { map } from 'lodash';
import { useIntl } from 'react-intl';

export default function GetColDataTable(numberOfPackages) {
  const intl = useIntl();

  return [
    {
      dataField: 'name',
      text: intl.formatMessage({ id: 'controlpanel.global.product_name' }),
      sort: true,
      hidden: false
    },
    {
      dataField: 'package',
      text: intl.formatMessage({ id: 'controlpanel.global.bulk' }),
      sort: true,
      hidden: numberOfPackages === 1
    },
    {
      dataField: 'reference',
      text: intl.formatMessage({ id: 'controlpanel.global.code' }),
      sort: true,
      hidden: false
    },
    {
      dataField: 'quantity',
      text: intl.formatMessage({ id: 'controlpanel.global.quantity' }),
      sort: true,
      hidden: false
    }
  ];
}

export const getTableEstructure = (data) => {
  const dataEstructured = [];

  map(data, (value) => {
    //! No se puede destructurar package, por que es una palabra reservada
    const { name, reference, quantity } = value.attributes;
    dataEstructured.push({
      name,
      package: value.attributes.package,
      reference,
      quantity
    });
  });

  return dataEstructured;
};
