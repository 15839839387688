import React from 'react';
/* eslint-disable react/jsx-one-expression-per-line */
import PropTypes from 'prop-types';

import 'app/components/CourseCard/components/Discount/styles.scss';

export default function Discount({ originalPrice, percentage }) {
  return (
    <div className="course-card-discount">
      <p dangerouslySetInnerHTML={{ __html: originalPrice }} />
      {percentage && <p>&nbsp;- &nbsp;{percentage}</p>}
    </div>
  );
}

Discount.defaultValues = {
  percentage: ''
};

Discount.propTypes = {
  originalPrice: PropTypes.string.isRequired,
  percentage: PropTypes.string
};
