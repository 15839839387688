import PropTypes from 'prop-types';

const OptionItem = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.node])
});

export const PROP_TYPES = {
  options: PropTypes.arrayOf(OptionItem).isRequired,
  show: PropTypes.bool,
  onSelected: PropTypes.func,
  onClick: PropTypes.func
};

export const DEFAULT_PROPS = {
  show: false,
  onSelected: () => null,
  onClick: () => {}
};
