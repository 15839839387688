import React from 'react';
import PropTypes from 'prop-types';

import 'app/components/NotificationBadge/NotificationBadge.scss';
import classNames from 'classnames';

export default function NotificationBadge({
  type = 'default',
  color = 'red',
  value = ''
}) {
  return (
    <div
      className={classNames(
        `bb-notification-badge bb-notification-badge__${type} bb-notification-badge__${type}--${color}`,
        {
          text: value
        }
      )}
    >
      {value && <div className={`${type}`}>{value}</div>}
    </div>
  );
}

NotificationBadge.propTypes = {
  value: PropTypes.string,
  type: PropTypes.oneOf(['square', 'circle', 'default']),
  color: PropTypes.oneOf(['red', 'green', 'yellow', 'blue', 'purple', 'grey'])
};
