import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';

import { genericRequest } from 'app/api';
import { getRowToCards } from 'app/modules/OrderView/utils';
import { CUSTOMER_TYPES } from 'app/utils/constants';
import Spinner from 'app/components/Spinner';

export default function InvoiceInfoCard() {
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const [address, setAddress] = useState();
  const customerType = useMemo(() => {
    let customerTypeText;

    Object.keys(CUSTOMER_TYPES).forEach((type) => {
      if (CUSTOMER_TYPES[type].id === user.attributes.customerType) {
        customerTypeText = CUSTOMER_TYPES[type].text;
      }
    });

    return customerTypeText || '';
  }, [user.attributes.customerType]);

  const fetchData = useCallback(
    async (controller) => {
      if (user?.relationships['invoice-address'].links.related) {
        const result = await genericRequest({
          url: user?.relationships['invoice-address'].links.related,
          signal: controller.signal
        });

        setAddress(result?.data);
      }
    },
    [user]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller);

    return () => controller.abort();
  }, [fetchData]);

  if (!address) {
    return <Spinner />;
  }

  return (
    <Row>
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.client_type' }),
        customerType
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.nif_cif_vat' }),
        address.attributes.vat
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.name' }),
        `${user.attributes.name} ${user.attributes.surnames}`
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.company' }),
        address.attributes.company
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.address' }),
        address.attributes.address
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.country' }),
        address.attributes.country
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.state_province' }),
        address.attributes.state
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.city' }),
        address.attributes.city
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.phone' }),
        address.attributes.phone
      )}
      {getRowToCards(
        intl.formatMessage({ id: 'controlpanel.global.equivalence_surcharge' }),
        address.attributes.equivalenceSurcharge === 0
          ? intl.formatMessage({ id: 'controlpanel.global.no' })
          : intl.formatMessage({ id: 'controlpanel.global.yes' })
      )}
    </Row>
  );
}
