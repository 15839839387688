import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';

import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useLang } from 'app/context/i18n';

import { Colors } from 'app/utils/colors';
import { genericRequest } from 'app/api';
import { singleOrderActions } from 'app/api/singleOrderActions';
import { URLS } from 'app/config/urls';
import { useSubheader } from 'app/context/SubheaderContext';
import AttachFilesModal from 'app/modules/OrderView/components/AttachFilesModal';
import Button from 'app/components/Button';
import Card from 'app/components/Card';
import DataProviderContext from 'app/context/DataProviderContext';
import DownloadFiles from 'app/modules/OrderView/components/DownloadFiles';
import Icon from 'app/components/Icon';
import InvoiceInfoCard from 'app/modules/OrderView/components/InvoiceInfoCard';
import DeliveryNotesDatatable from 'app/modules/OrderView/components/DeliveryNotesDatatable';
import InvoicesDatatable from 'app/modules/OrderView/components/InvoicesDatatable';
import OrderInfoCard from 'app/modules/OrderView/components/OrderInfoCard';
import OrdersLineDatatable from 'app/modules/OrderView/components/OrdersLineDatatable';
import RepeatOrder from 'app/modules/Orders/components/Modals/RepeatOrder';
import ShippingInfoCard from 'app/modules/OrderView/components/ShippingInfoCard';
import Spinner from 'app/components/Spinner';

import 'app/modules/OrderView/OrderView.scss';

const { bbWhite } = Colors;

export function OrderView() {
  const intl = useIntl();
  const { id } = useParams();
  const subheader = useSubheader();
  const { setTitle, setBreadcrumbs, setIcon } = subheader;
  const [newBreadCrumbs] = useState([
    {
      pathname: URLS.orders,
      title: intl.formatMessage({ id: 'controlpanel.breadcrumbs.orders' })
    },
    {
      pathname: `${URLS.orders}/view/${id}`,
      title: intl.formatMessage(
        { id: 'controlpanel.breadcrumbs.orders.view' },
        { id }
      )
    }
  ]);
  const [modalRepeat, setModalRepeat] = useState({
    show: false,
    orderId: null
  });
  const [deliveryNotes, setDeliveryNotes] = useState([]);
  const [hasFiles, setHasFiles] = useState(false);
  const [loadingDeliveryNotes, setLoadingDeliveryNotes] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const lang = useLang();

  useLayoutEffect(() => {
    setTitle(
      intl.formatMessage({ id: 'controlpanel.breadcrumbs.orders.view' }, { id })
    );
    setIcon({ icon: 'clipboard' });
    setBreadcrumbs(newBreadCrumbs);
  }, [intl, id, setTitle, setIcon, setBreadcrumbs, newBreadCrumbs]);

  const [order, setOrder] = useState(null);
  const [downloadFilesLinks, setDownloadFilesLinks] = useState([]);

  const fetchOrder = useCallback(
    async (controller) => {
      const result = await genericRequest({
        url: `orders/${id}`,
        signal: controller.signal
      });

      setOrder(result?.data);
    },
    [id]
  );

  const fetchInvoices = useCallback(
    async (controller) => {
      const fetchedInvoices = await genericRequest({
        url: `orders/${id}/order-invoices`,
        signal: controller.signal
      });

      setInvoices(fetchedInvoices?.data);
    },
    [id]
  );

  const fetchDeliveryNotes = useCallback(
    async (controller) => {
      setLoadingDeliveryNotes(true);

      const fetchedDeliveryNotes = await genericRequest({
        url: `orders/${id}/delivery-notes`,
        signal: controller.signal
      });

      setDeliveryNotes(fetchedDeliveryNotes?.data);

      setLoadingDeliveryNotes(false);
    },
    [id]
  );

  const downloadDeliveryNotes = async () => {
    const deliveryNotesId = [];
    deliveryNotes.forEach((deliveryNote) => {
      deliveryNotesId.push(deliveryNote.id);
    });

    const result = await genericRequest({
      url: `orders/${id}/delivery-notes/downloads`,
      method: 'POST',
      data: {
        data: {
          type: 'delivery-note-files',
          id,
          attributes: {
            format: 'pdf',
            includedIds: deliveryNotesId
          }
        }
      }
    });
    if (result) {
      const url = result.data.attributes.link;
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.setAttribute('download', `test`);
      downloadLink.click();
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    fetchOrder(controller);
    fetchInvoices(controller);
    fetchDeliveryNotes(controller);

    return () => {
      setOrder(null);
      controller.abort();
    };
  }, [fetchInvoices, fetchOrder, fetchDeliveryNotes]);

  useEffect(() => {
    if (order?.links) {
      const { links } = order;
      const options = [];

      if (links?.invoices) {
        let titleInvoices = intl.formatMessage({
          id: 'controlpanel.global.download_invoice'
        });

        if (links?.invoices?.meta?.count > 1) {
          titleInvoices = intl.formatMessage({
            id: 'controlpanel.global.download_invoices'
          });
        }

        options.push({
          id: 1,
          title: titleInvoices,
          value: links?.invoices?.href
        });
      }

      if (links?.receipts) {
        let titleRecepits = intl.formatMessage({
          id: 'controlpanel.global.download_receipt'
        });

        if (links?.receipts?.meta?.count > 1) {
          titleRecepits = intl.formatMessage({
            id: 'controlpanel.global.download_receipts'
          });
        }

        options.push({
          id: 2,
          title: titleRecepits,
          value: links?.receipts?.href
        });
      }

      setDownloadFilesLinks(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (deliveryNotes) {
      deliveryNotes.forEach((deliveryNote) => {
        if (deliveryNote.attributes.file) {
          setHasFiles(true);
        }
      });
    }
  }, [deliveryNotes]);

  const valueProvider = useMemo(
    () => ({
      order,
      downloadFilesLinks,
      setDownloadFilesLinks
    }),
    [order, downloadFilesLinks]
  );

  const trackings = useMemo(
    () => order?.relationships?.tracking?.data,
    [order?.relationships?.tracking?.data]
  );

  useEffect(() => {
    if (!trackings?.length) {
      return;
    }
    try {
      const markAsRead = trackings.map((tracking) =>
        genericRequest({
          url: `trackings/${tracking.id}`,
          method: 'PATCH',
          data: {
            data: {
              id: tracking.id,
              type: 'tracking',
              attributes: {
                read: true
              }
            }
          }
        })
      );
      Promise.all(markAsRead);
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }
  }, [trackings]);

  if (!order) {
    return <Spinner />;
  }

  let repeat = false;

  if (order?.attributes?.availableActions?.indexOf('REPEAT') >= 0) {
    repeat = true;
  }

  return (
    <DataProviderContext.Provider value={valueProvider}>
      <Row className="order-view">
        <RepeatOrder modalData={modalRepeat} setModalData={setModalRepeat} />
        <Col md={4}>
          <Card
            extraClass="order-info-card"
            icon="info-square"
            title={intl.formatMessage({
              id: 'controlpanel.orders.view.order_info'
            })}
            body={<OrderInfoCard />}
          />
        </Col>
        <Col md={4}>
          <Card
            extraClass="order-info-card"
            icon="truck"
            title={intl.formatMessage({
              id: 'controlpanel.orders.view.shipping_info'
            })}
            body={<ShippingInfoCard />}
          />
        </Col>
        <Col md={4}>
          <Card
            extraClass="order-info-card"
            icon="file-invoice-dollar"
            title={intl.formatMessage({
              id: 'controlpanel.orders.view.invoice_info'
            })}
            body={<InvoiceInfoCard />}
          />
        </Col>
        <Col md={12}>
          <Card
            extraClass="order-detail"
            icon="search"
            title={intl.formatMessage({
              id: 'controlpanel.global.order_detail'
            })}
            body={<OrdersLineDatatable orderId={id} order={order} />}
            header={
              <div className="order-detail__button-container">
                {repeat && (
                  <div>
                    <Button
                      text={intl.formatMessage({
                        id: 'controlpanel.orders.modal_repeat.title'
                      })}
                      type="secondary"
                      size="medium"
                      typeButton="button"
                      onClick={async () => {
                        const request = await singleOrderActions({
                          id,
                          type: 'repeat-order'
                        });

                        if (request) {
                          setModalRepeat({
                            show: true,
                            orderId: null
                          });
                        }
                      }}
                      icon={<Icon icon="copy" color={bbWhite} />}
                    />
                  </div>
                )}
                <AttachFilesModal />
                <DownloadFiles />
              </div>
            }
          />
        </Col>
        <Col md={12}>
          <Card
            extraClass="delivery-note"
            icon="file"
            title={intl.formatMessage({
              id: 'controlpanel.global.delivery_notes'
            })}
            header={
              Boolean(deliveryNotes?.length) && (
                <div className="order-detail__button-container">
                  <Button
                    text={intl.formatMessage({
                      id: 'controlpanel.global.download_delivery_notes'
                    })}
                    type="secondary"
                    size="medium"
                    typeButton="button"
                    disabled={!hasFiles}
                    onClick={async () => {
                      await downloadDeliveryNotes();
                    }}
                    icon={<Icon icon="download" color={bbWhite} />}
                  />
                </div>
              )
            }
            body={
              <div>
                <DeliveryNotesDatatable
                  fetchedDeliveryNotes={deliveryNotes}
                  isLoading={loadingDeliveryNotes}
                  orderId={order.id}
                />
                <p
                  className="small delivery-notes__info"
                  dangerouslySetInnerHTML={{
                    __html: intl
                      .formatMessage({
                        id: 'controlpanel.orders.view.delivery_notes.info'
                      })
                      .replace(
                        '{link}',
                        `${process.env.REACT_APP_BIGBUY_URL}${lang}/contact#tabpanel2`
                      )
                  }}
                />
              </div>
            }
          />
        </Col>
        <Col md={12}>
          <Card
            extraClass="invoice"
            icon="file-invoice-dollar"
            title={intl.formatMessage({ id: 'controlpanel.global.invoice' })}
            body={<InvoicesDatatable fetchedInvoices={invoices} />}
          />
        </Col>
      </Row>
    </DataProviderContext.Provider>
  );
}
