// TODO: Revisar las propstypes
/* eslint-disable react/prop-types */
import React, { useMemo, useEffect, useRef } from 'react';

import { filter } from 'lodash';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import ChatComment from 'app/modules/Devolutions/components/ChatMessage/components/ChatComment';
import ChatMessage from 'app/modules/Devolutions/components/ChatMessage';
import Divider from 'app/components/Divider';
import Icon from 'app/components/Icon';
import useRMAContext from 'app/modules/Devolutions/context/context';
import { STATUS_WITHOUT_BUTTON_RESPONSE_CHAT } from '../../TableProducts/formatters/ProductFormatter/utils/constants';

const iconColor = Colors.bbSecondary;

const ChatMessageWithActions = ({
  attributes,
  id,
  index,
  length,
  handleExpand,
  fullLength,
  handleToggleComment,
  isTextAreaEnabled,
  rmaLineId,
  canAnswerLine
}) => {
  const intl = useIntl();

  const { rmaProducts } = useRMAContext();
  const rmaLine = filter(rmaProducts, (obj) => obj?.id === rmaLineId);

  const nestedCommentsNumber = useMemo(
    () => fullLength - length,
    [fullLength, length]
  );

  const lastUserComment = useMemo(() => {
    if (attributes.admin) return null;
    return attributes.comment;
  }, [attributes]);

  const lastComment = useRef(null);

  useEffect(() => {
    if (!lastComment?.current) return;
    lastComment.current.scrollIntoView({ behavior: 'smooth' });
  }, [lastComment]);

  let component = <ChatMessage {...attributes} />;

  if (index < length - 1) {
    component = (
      <React.Fragment key={id}>
        {component}
        <Divider type="dash" />
      </React.Fragment>
    );
  }

  if (index === length - 1) {
    component = (
      <React.Fragment key={id}>
        {component}
        {!STATUS_WITHOUT_BUTTON_RESPONSE_CHAT.includes(
          rmaLine[0]?.attributes?.status
        ) && (
          <div className="chat-message-actions" ref={lastComment}>
            {isTextAreaEnabled || (
              <>
                {canAnswerLine ? (
                  <Button
                    type="secondary"
                    size="small"
                    text={intl.formatMessage({
                      id: 'controlpanel.global.answer'
                    })}
                    onClick={() => {
                      handleExpand();
                      handleToggleComment(true);
                    }}
                  />
                ) : (
                  <Button
                    type="secondary"
                    size="small"
                    text={intl.formatMessage({
                      id: 'controlpanel.global.edit'
                    })}
                    onClick={() => {
                      handleExpand();
                      handleToggleComment(true);
                    }}
                  />
                )}

                {Boolean(nestedCommentsNumber) && (
                  <Button
                    size="small"
                    text={nestedCommentsNumber}
                    icon={
                      <Icon icon="comments" color={iconColor} swapOpacity />
                    }
                    onClick={handleExpand}
                  />
                )}
              </>
            )}
          </div>
        )}
        {isTextAreaEnabled && (
          <ChatComment
            handleToggleComment={handleToggleComment}
            rmaLineId={rmaLineId}
            comment={lastUserComment}
            isEditting={Boolean(lastUserComment)}
            commentId={id}
          />
        )}
      </React.Fragment>
    );
  }

  return component;
};

export default ChatMessageWithActions;
