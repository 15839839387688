export const SIZE = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400
};

export const isMobile = () => window.innerWidth <= SIZE.LG;
