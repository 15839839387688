import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';

import { Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useLang } from 'app/context/i18n';

import { genericRequest } from 'app/api';
import { URLS } from 'app/config/urls';
import { useSubheader } from 'app/context/SubheaderContext';
import Card from 'app/components/Card';
import DeliveryNotesDatatable from 'app/modules/OrderView/components/DeliveryNotesDatatable';
import Products from 'app/modules/OrderView/components/DeliveryNotes/Products';
import Trackings from 'app/modules/OrderView/components/DeliveryNotes/Trackings';
import DataProviderContext from 'app/context/DataProviderContext';

import 'app/modules/OrderView/pages/DeliveryNotes/DeliveryNotes.scss';

export default function DeliveryNotes() {
  const intl = useIntl();
  const { orderId, deliveryNoteId } = useParams();
  const [deliveryNote, setDeliveryNote] = useState(null);
  const [loading, setLoading] = useState(true);
  const lang = useLang();

  const fetchData = useCallback(
    async (controller) => {
      const result = await genericRequest({
        url: `delivery-notes/${deliveryNoteId}`,
        signal: controller?.signal || null,
        getHeaders: true,
        setLoading
      });

      setDeliveryNote(result?.data);
    },
    [deliveryNoteId]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller);
    return () => {
      setDeliveryNote(null);
      controller.abort();
    };
  }, [fetchData]);

  const subheader = useSubheader();
  const [newBreadCrumbs] = useState([
    {
      pathname: URLS.orders,
      title: intl.formatMessage({ id: 'controlpanel.breadcrumbs.orders' })
    },
    {
      pathname: `${URLS.orders}/view/${orderId}`,
      title: intl.formatMessage(
        { id: 'controlpanel.breadcrumbs.orders.view' },
        { id: orderId }
      )
    },
    {
      pathname: `${URLS.orders}/${orderId}/delivery-notes`,
      title: intl.formatMessage(
        { id: 'controlpanel.breadcrumbs.delivery_note' },
        { id: deliveryNoteId }
      )
    }
  ]);

  const { setTitle, setBreadcrumbs, setIcon } = subheader;
  useLayoutEffect(() => {
    setTitle(
      intl.formatMessage(
        { id: 'controlpanel.breadcrumbs.delivery_note' },
        { id: deliveryNoteId }
      )
    );
    setIcon({ icon: 'clipboard' });
    setBreadcrumbs(newBreadCrumbs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTitle, setIcon, setBreadcrumbs, newBreadCrumbs]);

  const valueProvider = useMemo(
    () => ({
      deliveryNote: deliveryNote?.data,
      trackings: deliveryNote?.included?.trackings,
      products: deliveryNote?.included?.deliveryNoteLines,
      fetchData
    }),
    [deliveryNote?.data, deliveryNote?.included, fetchData]
  );

  if (!deliveryNote) {
    return null;
  }

  return (
    <DataProviderContext.Provider value={valueProvider}>
      <Row className="delivery-notes">
        <Col md={12} className="delivery-notes__delivery-note">
          <Card
            extraClass="delivery-note"
            icon="file"
            title={intl.formatMessage({
              id: 'controlpanel.global.delivery_note'
            })}
            body={
              <>
                <DeliveryNotesDatatable
                  fetchedDeliveryNotes={[deliveryNote?.data]}
                  isLoading={loading}
                  orderId={orderId}
                  showView={false}
                  fetchedShowPod={[deliveryNote?.data.attributes.pod]}
                  fetchedShowDeliveryNotes={[
                    deliveryNote?.data.attributes.file
                  ]}
                />
                <p
                  className="small delivery-notes__info"
                  dangerouslySetInnerHTML={{
                    __html: intl
                      .formatMessage({
                        id: 'controlpanel.orders.view.delivery_notes.info'
                      })
                      .replace(
                        '{link}',
                        `${process.env.REACT_APP_BIGBUY_URL}${lang}/contact#tabpanel2`
                      )
                  }}
                />
              </>
            }
          />
        </Col>
        <Col md={12} className="delivery-notes__trackings">
          <Card
            icon="map-marker-alt"
            title={intl.formatMessage({
              id: 'controlpanel.global.trackings'
            })}
            body={<Trackings isLoading={loading} />}
          />
        </Col>
        <Col md={12} className="delivery-notes__products">
          <Formik
            initialValues={{ search: '', selectedPackage: '' }}
            validationSchema={Yup.object().shape({
              search: Yup.string(),
              selectedPackage: Yup.string()
            })}
          >
            {() => (
              <Form className="delivery-notes__products-form">
                <Card
                  icon="tags"
                  title={intl.formatMessage({
                    id: 'controlpanel.global.products'
                  })}
                  body={<Products />}
                />
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </DataProviderContext.Provider>
  );
}
