import React from 'react';
import PropTypes from 'prop-types';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';

import 'app/components/Label/Label.scss';

export default function Label({
  label,
  htmlFor,
  tooltipText = null,
  bold = false,
  size = '',
  fontWeight = '',
  style = {}
}) {
  return (
    <div
      className={classNames('label-container', {
        show: label || tooltipText
      })}
    >
      {label && (
        <label
          htmlFor={htmlFor}
          className={classNames(`${size} ${fontWeight}`, {
            bold
          })}
          style={style}
        >
          {label}
          {tooltipText && label && (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{tooltipText}</Tooltip>}
            >
              <SVG
                src={toAbsoluteUrl('/media/svg/bigbuy/info-square.svg')}
                className="label-tooltip"
              />
            </OverlayTrigger>
          )}
        </label>
      )}
    </div>
  );
}

Label.propTypes = {
  label: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  size: PropTypes.oneOf(['', 'small']),
  fontWeight: PropTypes.oneOf(['', 'medium']),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  bold: PropTypes.bool
};
