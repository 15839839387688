import React from 'react';

import Orders from 'app/modules/Orders/pages/Orders/Orders';
import OrdersContext from 'app/modules/Orders/contexts/OrdersContext';

export default function OrdersPage() {
  return (
    <OrdersContext>
      <Orders />
    </OrdersContext>
  );
}
