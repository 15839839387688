import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Icon from 'app/components/Icon';

import { Colors } from 'app/utils/colors';

import 'app/modules/Devolutions/components/TableProducts/formatters/OpenArrowFormatter.scss';

const red = Colors.bbRed;

const OpenArrowFormatter = ({ expanded }) => {
  const classes = useMemo(
    () => (expanded ? 'devolutions-open-arrow open' : 'devolutions-open-arrow'),
    [expanded]
  );

  return (
    <Icon icon="chevron-down" prefix="fas" color={red} className={classes} />
  );
};

OpenArrowFormatter.propTypes = {
  expanded: PropTypes.bool.isRequired
};

export default OpenArrowFormatter;
