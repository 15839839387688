import React, { useEffect, useRef, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { map, size } from 'lodash';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { postRmaLine } from 'app/modules/Devolutions/services';
import { Select } from 'app/components/Select';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import InputFilter from 'app/components/InputFilter';
import Textarea from 'app/components/Textarea';
import useRMAContext from 'app/modules/Devolutions/context/context';

import 'app/modules/Devolutions/components/AddProductForm/AddProductForm.scss';

export default function AddProductForm() {
  const intl = useIntl();
  const {
    elegibleOrders,
    setFilteredOrder,
    elegibleProducts,
    setSelecedProduct,
    elegibleQuantity,
    setSelectedQuantity,
    setLoading,
    setElegibleQuantity,
    setElegibleProducts,
    setElegibleOrders,
    rmaId,
    loadingProductsSelect,
    getRmaProducts
  } = useRMAContext();
  const formik = useFormikContext();

  const handleSubmit = () => {
    const {
      values: { description, idProduct, orderNumber, units }
    } = formik;

    const createRmaLine = async () => {
      await postRmaLine(
        {
          data: {
            type: 'rma-line',
            attributes: {
              sku: idProduct,
              orderId: orderNumber,
              rmaId,
              returnReason: description,
              quantity: units
            }
          }
        },
        {
          customSuccessFeedback: {
            variant: 'success',
            content: intl.formatMessage({
              id: 'controlpanel.feedback.save_changes.success'
            }),
            autoClose: true,
            dismissible: false
          },
          setLoading
        }
      );

      await getRmaProducts();

      setElegibleProducts([]);
      setSelecedProduct('');
      setElegibleOrders([]);
      setFilteredOrder('');
      setElegibleQuantity([]);
      setSelectedQuantity(0);
      formik.resetForm();
    };
    createRmaLine();
  };

  const [optionsSelectProducts, setOptionsSelectProducts] = useState([]);
  const [optionsSelectUnits, setOptionsSelectUnits] = useState([]);
  const selectRefProduct = useRef();
  const selectRefUnits = useRef();

  useEffect(() => {
    if (size(elegibleProducts) > 0) {
      const newOptionsProducts = [];
      map(elegibleProducts, (product) => {
        newOptionsProducts.push({
          value: product?.reference,
          label: `${product?.reference} - ${product?.name}`
        });
      });
      setOptionsSelectProducts(newOptionsProducts);
    } else {
      setOptionsSelectProducts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elegibleProducts]);

  useEffect(() => {
    if (size(elegibleQuantity) > 0) {
      const newOptionsUnits = [];
      map(elegibleQuantity, (quantity) => {
        newOptionsUnits.push({
          value: quantity,
          label: quantity
        });
      });
      setOptionsSelectUnits(newOptionsUnits);
    } else {
      setOptionsSelectUnits([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elegibleQuantity]);

  return (
    <div className="add-product-form">
      <div className="add-product-form__container">
        <Row>
          <Col lg={3} className="add-product-form__container-input">
            <InputFilter
              tooltipText="La búsqueda empieza a partir de 3 caracteres"
              arrayToFilter={elegibleOrders}
              name="orderNumber"
              label={intl.formatMessage({
                id: 'controlpanel.global.order_number'
              })}
              placeholder={intl.formatMessage({
                id: 'controlpanel.global.order_number'
              })}
              type="number"
              onChange={(e) => {
                setFilteredOrder(e.target.value);
                selectRefProduct?.current?.clearValue();
                selectRefUnits?.current?.clearValue();

                formik?.setTouched({ idProduct: false, units: false });
              }}
              onClickHandler={({ key }) => setFilteredOrder(key)}
              minCharsToFilter={3}
            />
          </Col>
          <Col lg={4} className="add-product-form__container-input">
            <Select
              name="idProduct"
              id="idProduct"
              label={intl.formatMessage({
                id: 'controlpanel.global.product'
              })}
              placeholder={intl.formatMessage({
                id: 'controlpanel.global.placeholder.select'
              })}
              disabled={!elegibleProducts?.length}
              onChange={(e) => {
                formik?.setTouched({ idProduct: false, units: false });
                if (e?.value) return setSelecedProduct(e.value);
                return { value: '', label: '' };
              }}
              loading={loadingProductsSelect}
              options={optionsSelectProducts}
              reactSelect={{
                ref: selectRefProduct
              }}
            />
          </Col>
          <Col lg={2} className="add-product-form__container-input">
            <Select
              name="units"
              id="units"
              label={intl.formatMessage({
                id: 'controlpanel.global.form.units'
              })}
              placeholder={intl.formatMessage({
                id: 'controlpanel.global.placeholder.select'
              })}
              onChange={(e) => {
                if (e?.value) return setSelectedQuantity(e.value);
                return { value: '', label: '' };
              }}
              disabled={!elegibleQuantity?.length}
              options={optionsSelectUnits}
              reactSelect={{
                ref: selectRefUnits
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="add-product-form__container-input">
            <Textarea
              tooltipText={intl.formatMessage({
                id: 'controlpanel.devolutions.tooltip.description'
              })}
              name="description"
              label={intl.formatMessage({
                id: 'controlpanel.global.issue_description'
              })}
              placeholder={intl.formatMessage({
                id: 'controlpanel.global.issue_description_placeholder'
              })}
              disabled={!formik.values.units > 0}
            />
          </Col>
          <Col lg={3} className="add-product-form__container-button">
            <Button
              type="secondary"
              typeButton="submit"
              text={intl.formatMessage({
                id: 'controlpanel.global.add_product'
              })}
              size="big"
              onClick={handleSubmit}
              icon={<Icon icon="add" color={Colors.bbWhite} />}
              disabled={
                !(formik.isValid && formik.dirty) ||
                elegibleQuantity?.length <= 0
              }
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
