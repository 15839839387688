import React, {
  useLayoutEffect,
  useMemo,
  useState,
  useRef,
  useEffect
} from 'react';

import { Form, useFormikContext } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import {
  LOGO_INPUT,
  FAVICON_INPUT
} from 'app/modules/Shops/pages/Shopify/config';
import { useSubheader } from 'app/context/SubheaderContext';
import Divider from 'app/components/Divider';
import FileForm from 'app/modules/Shops/pages/Shopify/components/FileForm';
import Footer from 'app/modules/Shops/pages/Shopify/components/Footer';
import Header from 'app/modules/Shops/pages/Shopify/components/Header';
import MainForm from 'app/modules/Shops/pages/Shopify/components/MainForm';
import SelectsForm from 'app/modules/Shops/pages/Shopify/components/SelectsForm';
import ShopShippingProcess from 'app/components/ShopShippingProcess';
import FormikContext from 'app/modules/Shops/pages/Shopify/context/FormikContext';
import {
  SHOPIFY_FORM_PROPTYPES,
  SHOP_SHIPMENT_STATUS,
  COLOR_MAP,
  STEPS
} from 'app/modules/Shops/pages/Shopify/utils';

import 'app/modules/Shops/pages/Shopify/ShopifyForm.scss';
import Spinner from 'app/components/Spinner';
import Checkbox from 'app/components/Checkbox/Checkbox';
import { size } from 'lodash';

const { ENTER_DETAILS, WRONG_REQUIREMENTS, SHOP_FINISHED } =
  SHOP_SHIPMENT_STATUS;

export default function ShopifyForm({
  shop,
  shopHistory,
  setShop,
  setShopHistory,
  academyLink,
  demoLink
}) {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const { shopId, catalogue, theme, statusKey } = shop;
  const container = useRef(null);

  const shopBreadcrumbTitle = useMemo(
    () =>
      `${intl.formatMessage({
        id: 'controlpanel.breadcrumbs.shop'
      })}: ${shopId}`,
    [shopId, intl]
  );
  const { setTitle, setIcon, setBreadcrumbs } = useSubheader();

  useLayoutEffect(() => {
    setTitle(shopBreadcrumbTitle);
    setIcon({ icon: 'store' });
    setBreadcrumbs([
      {
        pathname: '/shops',
        title: intl.formatMessage({ id: 'controlpanel.breadcrumbs.shops' })
      },
      {
        pathname: window.location.pathname,
        title: shopBreadcrumbTitle
      }
    ]);
  }, [intl, setBreadcrumbs, setIcon, setTitle, shopBreadcrumbTitle]);

  const [steps, currentProcessStatus, milestones, hasHistory] = useMemo(() => {
    shopHistory?.sort(
      (a, b) => new Date(b.attributes.date) - new Date(a.attributes.date)
    );
    const getStatus = SHOP_SHIPMENT_STATUS?.[statusKey];
    const getSteps = STEPS.map((step) => {
      if (getStatus === WRONG_REQUIREMENTS && step.key === ENTER_DETAILS) {
        return { ...step, status: 'CURRENT', color: COLOR_MAP.CURRENT };
      }
      if (getStatus === WRONG_REQUIREMENTS) {
        return { ...step, status: 'PENDING', color: COLOR_MAP.PENDING };
      }
      if (step.key < getStatus) {
        return { ...step, status: 'DONE', color: COLOR_MAP.DONE };
      }
      if (step.key === getStatus) {
        return { ...step, status: 'CURRENT', color: COLOR_MAP.CURRENT };
      }
      return { ...step, status: 'PENDING', color: COLOR_MAP.PENDING };
    });
    const getMilestones = shopHistory.map((el) => el.attributes);
    const getHasHistory = !!shopHistory?.length;
    return [getSteps, getStatus, getMilestones, getHasHistory];
  }, [shopHistory, statusKey]);

  const mainColWidth = useMemo(() => {
    if (!hasHistory || currentProcessStatus === ENTER_DETAILS) {
      return { span: 12 };
    }
    if (currentProcessStatus === SHOP_FINISHED) {
      return 12;
    }
    return 10;
  }, [currentProcessStatus, hasHistory]);

  const [disabledFileInput, setDisabledFileInput] = useState(false);
  const FormObserver = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      if (size(values?.customizedLogo) > 0) {
        setDisabledFileInput(true);
      } else {
        setDisabledFileInput(false);
      }
    }, [values]);
    return null;
  };

  return (
    <Row>
      {hasHistory && currentProcessStatus !== ENTER_DETAILS && (
        <Col
          lg={12}
          xl={{ span: 4, order: 2 }}
          className="shop-shipping-process-shopify-wrapper"
        >
          <ShopShippingProcess
            title={intl.formatMessage({
              id: 'controlpanel.shops.shop_shipping_process.title'
            })}
            milestones={milestones}
          />
        </Col>
      )}
      <Col
        xl={
          hasHistory && currentProcessStatus !== ENTER_DETAILS
            ? { span: 8, order: 1 }
            : 12
        }
        lg={12}
      >
        {shop && (
          <FormikContext
            shop={{ ...shop, confirmEmail: shop.email }}
            setIsLoading={setIsLoading}
            setShop={setShop}
            setShopHistory={setShopHistory}
            disabledLogoAndFavicon={disabledFileInput}
          >
            <Form>
              <FormObserver />
              <div className="shopify-form">
                <Header
                  steps={steps}
                  hasHistory={hasHistory}
                  academyLink={academyLink}
                />
                <Divider type="solid" />
                <Row className="shopify-form__body">
                  {isLoading ? (
                    <Spinner
                      size="4x"
                      containerHeight={container?.current?.clientHeight || 600}
                    />
                  ) : (
                    <div ref={container}>
                      <Col
                        md={
                          hasHistory && currentProcessStatus !== ENTER_DETAILS
                            ? 10
                            : { span: 12 }
                        }
                      >
                        <MainForm
                          currentProcessStatus={currentProcessStatus}
                          academyLink={academyLink}
                        />
                      </Col>
                      <Col md={12}>
                        <Divider type="dash" />
                      </Col>
                      <Col md={mainColWidth}>
                        <FileForm
                          currentProcessStatus={currentProcessStatus}
                          content={LOGO_INPUT}
                          fieldName="logo"
                          disabled={disabledFileInput}
                        />
                        <FileForm
                          currentProcessStatus={currentProcessStatus}
                          content={FAVICON_INPUT}
                          fieldName="favicon"
                          disabled={disabledFileInput}
                        />
                      </Col>
                      <Col md={{ span: 9, offset: 3 }}>
                        <Checkbox
                          id="customizedLogo"
                          name="customizedLogo"
                          type="checkbox"
                          label={intl.formatMessage({
                            id: 'controlpanel.shops.customized_logo'
                          })}
                          value="yes"
                          disabled={
                            currentProcessStatus !== ENTER_DETAILS &&
                            currentProcessStatus !== WRONG_REQUIREMENTS
                          }
                        />
                      </Col>
                      <Col md={12}>
                        <Divider type="dash" />
                      </Col>
                      <Col
                        md={
                          hasHistory && currentProcessStatus !== ENTER_DETAILS
                            ? 10
                            : { span: 12 }
                        }
                      >
                        <SelectsForm
                          currentProcessStatus={currentProcessStatus}
                          values={{ catalogue, theme }}
                          demoLink={demoLink}
                        />
                      </Col>
                    </div>
                  )}
                </Row>
                {[ENTER_DETAILS, WRONG_REQUIREMENTS].includes(
                  currentProcessStatus
                ) && (
                  <>
                    <Divider type="solid" />
                    <Footer />
                  </>
                )}
              </div>
            </Form>
          </FormikContext>
        )}
      </Col>
    </Row>
  );
}

ShopifyForm.propTypes = SHOPIFY_FORM_PROPTYPES;
