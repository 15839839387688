import React from 'react';

import { PROP_TYPES } from 'app/components/Icon/utils/types';

import { Colors } from 'app/utils/colors';

import 'app/components/Icon/Icon.scss';

export default function Icon({
  prefix = 'fad',
  color = Colors.bbGrey2,
  icon,
  size,
  className,
  ...rest
}) {
  return (
    <i
      className={`${prefix} fa-${icon} fa-${size} ${className}`}
      style={{
        color
      }}
      {...rest}
    />
  );
}

Icon.propTypes = PROP_TYPES;
