import React, { useLayoutEffect } from 'react';

import { useIntl } from 'react-intl';
import { filter, size } from 'lodash';

import { DataTableProvider } from 'app/components/DataTable/context/DataTableContext';
import { getInvoicesDataTableConfiguration } from 'app/modules/Invoices/config/config';
import { useSubheader } from 'app/context/SubheaderContext';
import Body from 'app/modules/Invoices/components/Body';
import Card from 'app/components/Card';
import useNotificationsServiceHook from 'app/hooks/useNotificationsService.hook';
import UserNotificationAlert from 'app/components/UserNotificationAlert';

import 'app/modules/Invoices/Invoices.scss';

export default function Invoices() {
  const intl = useIntl();
  const subheader = useSubheader();
  const { setTitle, setIcon, setBreadcrumbs } = subheader;

  useLayoutEffect(() => {
    setTitle(intl.formatMessage({ id: 'controlpanel.asidemenu.invoices' }));
    setIcon({ icon: 'file-invoice-dollar' });
    setBreadcrumbs([]);
  }, [intl, setTitle, setBreadcrumbs, setIcon]);

  const {
    notifications: { alerts }
  } = useNotificationsServiceHook();
  const alertsFiltered = filter(
    alerts,
    (alert) => alert?.attributes?.section === 'INVOICE'
  );

  return (
    <div className="invoices">
      {size(alertsFiltered) > 0 && (
        <UserNotificationAlert alerts={alertsFiltered} />
      )}
      <DataTableProvider
        getTableContextConfiguration={getInvoicesDataTableConfiguration}
      >
        <Card extraClass="invoices-card-container" body={<Body />} />
      </DataTableProvider>
    </div>
  );
}
