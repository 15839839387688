import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { Colors } from 'app/utils/colors';
import { DOWNLOAD_MANUALS_PERMITED } from 'app/modules/Downloads/utils';
import { wrongPackContent } from 'app/components/ModalWrongPack';
import Card from 'app/components/Card/Card';
import DataTable from 'app/components/DataTable/DataTable';
import Icon from 'app/components/Icon/Icon';
import ModalLang from 'app/modules/Downloads/Pages/ProductsManuals/components/ModalLang';
import SearchBar from 'app/components/SearchBar/SearchBar';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

import 'app/modules/Downloads/Pages/ProductsManuals/ProductsManuals.scss';

export default function ProductsManuals() {
  const intl = useIntl();
  const formik = useFormikContext();
  const {
    filters: { filters },
    context: { saveInContextStorage }
  } = useDataTableContext();
  const [modalData, setModalData] = useState({
    show: false,
    productInfo: null
  });

  const {
    user: {
      attributes: { packId }
    }
  } = useSelector((state) => state.auth);

  const downloadPermited = DOWNLOAD_MANUALS_PERMITED.includes(packId);

  useEffect(() => {
    saveInContextStorage('setModalData', setModalData);
  }, [saveInContextStorage]);

  if (!downloadPermited) {
    return (
      <Card
        title={intl.formatMessage({
          id: 'controlpanel.downloads.mkt_resources'
        })}
        body={
          <div className="wrong-pack" style={{ marginTop: '15px' }}>
            <div className="wrong-pack__body">{wrongPackContent(intl)}</div>
          </div>
        }
      />
    );
  }

  return (
    <>
      <ModalLang show={modalData?.show} productInfo={modalData?.productInfo} />
      <Card
        extraClass="products-manuals"
        title={intl.formatMessage({
          id: 'controlpanel.downloads.product_manuals'
        })}
        header={
          <SearchBar
            placeholder={intl.formatMessage({
              id: 'controlpanel.global.search'
            })}
            name="search"
            button={{
              onClick: formik?.submitForm,
              type: 'secondary',
              size: 'big',
              icon: <Icon icon="search" prefix="fas" color={Colors.bbWhite} />
            }}
            value={filters?.search || ''}
            onClear={() => {
              formik.setFieldValue('search', '');
              formik.submitForm();
            }}
          />
        }
        body={<DataTable />}
      />
    </>
  );
}
