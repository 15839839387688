import React from 'react';

import { useIntl } from 'react-intl';

import { SIZE } from 'app/utils/sizes';
import { useWindow } from 'app/hooks/useWindow.hook';
import PayPalDetail from 'app/modules/MyAccount/pages/PaymentMethods/components/PayPal/PayPalDetail';
import usePaymentMethodsContext from 'app/modules/MyAccount/pages/PaymentMethods/context/PaymentMethodsContext';

import 'app/modules/MyAccount/pages/PaymentMethods/components/PayPal/PayPalDetails.scss';

export default function PayPalDetails() {
  const intl = useIntl();
  const { width } = useWindow();
  const mobile = width < SIZE.MD;

  const { paypal } = usePaymentMethodsContext();

  return (
    <div className="bb-payment-methods-paypal__paypal-items">
      <div className="bb-payment-methods-paypal__paypal-items--header">
        <p className="medium">
          {intl.formatMessage({
            id: 'controlpanel.payment_methods.paypal_details.title'
          })}
        </p>
      </div>
      <div className="bb-payment-methods-paypal__paypal-items--content">
        {paypal.map(({ id, ...paypalDetails }) => (
          <PayPalDetail key={id} {...paypalDetails} mobile={mobile} />
        ))}
      </div>
    </div>
  );
}
