import React from 'react';
import PropTypes from 'prop-types';

import { includes } from 'lodash';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import Icon from 'app/components/Icon';

import 'app/components/Aside/aside-menu/index.scss';

export default function AsideMenuItem({
  title,
  icon = null,
  iconClass = null,
  url = '',
  prefix = 'fad',
  isExternalLink = false
}) {
  const location = useLocation();
  const { pathname = [] } = location;

  let body = <p className="menu-text">{title}</p>;

  if (icon) {
    body = (
      <>
        <span className="menu-icon">
          <Icon icon={icon} prefix={prefix} size="lg" className={iconClass} />
        </span>
        <p className="menu-text">{title}</p>
      </>
    );
  }

  return (
    <li
      className={classNames('menu-item', {
        'menu-item-active': getMenuItemActive(url, pathname, isExternalLink)
      })}
    >
      {isExternalLink === false ? (
        <NavLink
          className={classNames('menu-link', {
            active: getMenuItemActive(url, pathname, isExternalLink)
          })}
          to={url}
          data-icon-src={icon}
          onClick={() =>
            document.getElementById('kt_aside_mobile_toggle').click()
          }
        >
          {body}
        </NavLink>
      ) : (
        <a
          className={classNames('menu-link', {
            active: getMenuItemActive(url, pathname, isExternalLink)
          })}
          href={url}
        >
          {body}
        </a>
      )}
    </li>
  );
}

const getMenuItemActive = (baseUrl, path, isExternalLink) => {
  if (isExternalLink === false) {
    const splitUrl = baseUrl.split('/');
    if (includes(path, splitUrl[1])) {
      return true;
    }
  }
  return false;
};

AsideMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  prefix: PropTypes.string,
  iconClass: PropTypes.string,
  icon: PropTypes.string,
  isExternalLink: PropTypes.bool
};
