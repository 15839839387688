import { map } from 'lodash';
import { useIntl } from 'react-intl';

export default function useDeliveryNoteStatus() {
  const intl = useIntl();

  return {
    NOT_PREPARED: {
      status: 'NOT_PREPARED',
      translation: intl.formatMessage({
        id: 'controlpanel.global.not_prepared'
      }),
      color: 'red'
    },
    PREPARED: {
      status: 'PREPARED',
      translation: intl.formatMessage({
        id: 'controlpanel.global.prepared'
      }),
      color: 'orange'
    },
    SHIPPED: {
      status: 'SHIPPED',
      translation: intl.formatMessage({
        id: 'controlpanel.global.shipped'
      }),
      color: 'green'
    }
  };
}

export const GetSelectStructure = () => {
  const orderStatus = useDeliveryNoteStatus();
  const selectStructure = [];

  map(orderStatus, (data, status) => {
    selectStructure.push({
      value: status,
      label: data.translation
    });
  });

  return selectStructure;
};
