import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form } from 'formik';
import { useIntl } from 'react-intl';

import {
  postRmaComment,
  patchRmaComment
} from 'app/modules/Devolutions/services';
import Button from 'app/components/Button';
import Divider from 'app/components/Divider';
import Textarea from 'app/components/Textarea';
import useRMAContext from 'app/modules/Devolutions/context/context';

const ChatComment = ({
  comment = '',
  isEditting = false,
  handleToggleComment,
  rmaLineId,
  commentId
}) => {
  const intl = useIntl();
  const buttons = useRef(null);
  const { getRmaProducts } = useRMAContext();

  useEffect(() => {
    if (!buttons?.current) return;
    buttons.current.scrollIntoView({ behavior: 'smooth' });
  }, [buttons]);

  return (
    <>
      <Divider type="dash" />
      <Formik
        initialValues={{ comment: comment || '', commentId }}
        onSubmit={async (values, formik) => {
          if (isEditting) {
            await patchRmaComment({
              data: {
                type: 'rma-line-comment',
                id: values?.commentId,
                attributes: {
                  rmaLineId,
                  comment: values.comment
                }
              }
            });
          } else {
            await postRmaComment({
              data: {
                type: 'rma-line-comment',
                attributes: {
                  rmaLineId,
                  comment: values.comment
                }
              }
            });
          }

          formik.resetForm();
          handleToggleComment(false);
          await getRmaProducts();
        }}
      >
        {(formik) => (
          <Form>
            <Textarea name="comment" />

            <div className="chat-message-actions--comment" ref={buttons}>
              <Button
                size="small"
                text={intl.formatMessage({ id: 'controlpanel.global.close' })}
                onClick={() => handleToggleComment(false)}
              />
              <Button
                size="small"
                text={intl.formatMessage({
                  id: 'controlpanel.global.save_alt'
                })}
                type="secondary"
                onClick={() => formik.handleSubmit()}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

ChatComment.propTypes = {
  handleToggleComment: PropTypes.func.isRequired,
  rmaLineId: PropTypes.string.isRequired,
  comment: PropTypes.string,
  isEditting: PropTypes.bool,
  commentId: PropTypes.string.isRequired
};

export default ChatComment;
