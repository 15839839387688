export const addNewFilterToRenderList = ({
  e: {
    target: { id, value, attributes }
  },
  setRenderedFilters,
  currentStatus,
  INITIAL_VALUES
}) => {
  if (id !== 'selectFilter') return;

  const prevValue = attributes?.['data-prev-value']?.value;

  setRenderedFilters((prev) => {
    if (prevValue === 'all') {
      return [...prev.filter((filter) => filter !== 'all'), value];
    }

    currentStatus.setFieldValue(prevValue, INITIAL_VALUES?.[prevValue]);

    const index = prev.indexOf(prevValue);
    const cleanArray = prev.filter(
      (filter) => filter !== 'all' && filter !== prevValue
    );
    cleanArray.splice(index, 0, value);
    return cleanArray;
  });
};
