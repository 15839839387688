import React from 'react';

import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { size } from 'lodash';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { DropdownTopbarItemToggler } from 'app/layout/partials/DropdownTopbarItemToggler';
import { URLS } from 'app/config/urls';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import UserNotificationsBody from 'app/components/UserNotificationsBody';
import useNotificationsServiceHook from 'app/hooks/useNotificationsService.hook';

import 'app/components/UserNotificationsDropdown/index.scss';

export default function UserNotificationsDropdown() {
  const intl = useIntl();

  const {
    notifications: { notifications }
  } = useNotificationsServiceHook();

  const existsNotifications = size(notifications) > 0;

  return (
    <Dropdown className="user-notifications-dropdown">
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="kt_quick_notifications_toggle"
        className="kt_quick_notifications_toggle"
      >
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="tooltip-kt_quick_notifications_toggle">
              {intl.formatMessage({ id: 'controlpanel.global.notifications' })}
            </Tooltip>
          }
        >
          <div
            className="bb-btn-item pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <Icon icon="bell-on" color={Colors.bbSecondary} size="lg" />
            {existsNotifications && <span className="pulse-ring" />}
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="user-notifications-dropdown__container"
        align="end"
      >
        <div className="user-notifications-dropdown__container-header">
          <h5 className="bold">
            {intl.formatMessage({ id: 'controlpanel.global.notifications' })}
          </h5>
          <Button
            text={intl.formatMessage({
              id: 'controlpanel.payment_methods.credit_cards_detail.show_button'
            })}
            size="small"
            onClick={() => window.open(URLS.dashboard, '_SELF')}
            disabled={!existsNotifications}
          />
        </div>
        <UserNotificationsBody />
      </Dropdown.Menu>
    </Dropdown>
  );
}
