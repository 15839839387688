import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import SVG from 'react-inlinesvg';

import {
  Select,
  getMultipleSelectDefaultValues,
  getSelectDefaultValues
} from 'app/components/Select';
import {
  SHOP_SHIPMENT_STATUS,
  MAIN_TITLE
} from 'app/modules/Shops/pages/Shopify/utils';
import { Colors } from 'app/utils/colors';
import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';
import { USER_INPUTS } from 'app/modules/Shops/pages/Shopify/config';
import Button from 'app/components/Button/Button';
import Icon from 'app/components/Icon/Icon';
import Input from 'app/components/Input';

import 'app/modules/Shops/pages/Shopify/components/MainForm.scss';

const { ENTER_DETAILS, WRONG_REQUIREMENTS } = SHOP_SHIPMENT_STATUS;

const isMultiple = (name) => name === 'languages' || name === 'currency';

export default function MainForm({ currentProcessStatus, academyLink }) {
  const intl = useIntl();
  const formik = useFormikContext();

  const LINK_TO_ACADEMY = academyLink;

  const isDisabled = useCallback(
    (name) => {
      if (name === 'country' || name === 'domain') {
        return true;
      }
      if (
        currentProcessStatus === ENTER_DETAILS ||
        currentProcessStatus === WRONG_REQUIREMENTS
      ) {
        return false;
      }
      return true;
    },
    [currentProcessStatus]
  );
  const defaultSelectOptions = useMemo(() => {
    const {
      input: { options: mainLanguageOptions }
    } = USER_INPUTS.find((input) => input.input.name === 'mainLanguage');
    const {
      input: { options: languagesOptions }
    } = USER_INPUTS.find((input) => input.input.name === 'languages');
    const {
      input: { options: currencyOptions }
    } = USER_INPUTS.find((input) => input.input.name === 'currency');

    const mainLanguage = getSelectDefaultValues(mainLanguageOptions, [
      formik?.values?.mainLanguage
    ]);
    const languages = getMultipleSelectDefaultValues(languagesOptions, [
      formik?.values?.languages
    ]);
    const currency = getMultipleSelectDefaultValues(currencyOptions, [
      formik?.values?.currency
    ]);

    return {
      mainLanguage,
      languages,
      currency
    };
  }, [
    formik?.values?.currency,
    formik?.values?.languages,
    formik?.values?.mainLanguage
  ]);

  return (
    <div className="shopify-form__container">
      <Row className="shopify-form__container-header">
        <Col md={12} className="shopify-form__container-title input-label">
          <p className="big medium">{intl.formatMessage({ id: MAIN_TITLE })}</p>
          <Button
            className="button-view-more"
            text={intl.formatMessage({
              id: 'controlpanel.global.more_info'
            })}
            icon={<Icon icon="graduation-cap" color={Colors.bbSecondary} />}
            size="medium"
            onClick={() => window.open(LINK_TO_ACADEMY, '_blanck')}
          />
        </Col>
      </Row>

      {USER_INPUTS.map((item, index) => {
        const {
          paragraph,
          input: {
            name,
            placeholder,
            textPrepend = null,
            textAppend = null,
            type = 'input',
            options = []
          }
        } = item;
        return (
          <Row
            key={`${index}-${paragraph}`}
            className="shopify-form__input-wrapper"
          >
            <Col
              md={3}
              className={`shopify-form__input-label input-label ${
                name === 'domain' ? 'domain' : ''
              }`}
            >
              <p>{intl.formatMessage({ id: paragraph })}</p>
              {name === 'domain' && (
                <div>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        {intl.formatMessage({
                          id: 'controlpanel.shopify_form.title.data'
                        })}
                      </Tooltip>
                    }
                  >
                    <SVG
                      src={toAbsoluteUrl('/media/svg/bigbuy/info-square.svg')}
                    />
                  </OverlayTrigger>
                </div>
              )}
            </Col>
            <Col md={9} className="shopify-form__input input-label">
              {type === 'input' && (
                <Input
                  formik={formik}
                  name={name}
                  disabled={isDisabled(name)}
                  placeholder={
                    formik?.values?.[name] ||
                    intl.formatMessage({ id: placeholder })
                  }
                  textPrepend={textPrepend}
                  textAppend={textAppend}
                />
              )}
              {type === 'select' && (
                <div id={name}>
                  <Select
                    formik={formik}
                    id={name}
                    name={name}
                    placeholder={intl.formatMessage({ id: placeholder })}
                    options={options}
                    searchable
                    defaultValue={defaultSelectOptions[name]}
                    textPrepend={textPrepend}
                    textAppend={textAppend}
                    disabled={isDisabled(name)}
                    multiple={isMultiple(name)}
                  />
                </div>
              )}
            </Col>
          </Row>
        );
      })}
    </div>
  );
}

MainForm.propTypes = {
  currentProcessStatus: PropTypes.number.isRequired,
  academyLink: PropTypes.string.isRequired
};
