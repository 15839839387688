import * as Yup from 'yup';

const getPasswordValiadation = (intl) =>
  Yup.string()
    .min(
      8,
      intl.formatMessage(
        { id: 'controlpanel.form.validation.min_length' },
        { length: 8 }
      )
    )
    .max(
      255,
      intl.formatMessage(
        { id: 'controlpanel.form.validation.max_length' },
        { length: 255 }
      )
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      intl.formatMessage({ id: 'controlpanel.form.validation.password_regex' })
    )
    .required(
      intl.formatMessage({ id: 'controlpanel.form.validation.required' })
    );

export const getValidationSchema = (intl) =>
  Yup.object({
    oldPassword: Yup.string().required(
      intl.formatMessage({ id: 'controlpanel.form.validation.required' })
    ),
    newPassword: getPasswordValiadation(intl),
    repeatNewPassword: Yup.string()
      .required(
        intl.formatMessage({ id: 'controlpanel.form.validation.required' })
      )
      .oneOf(
        [Yup.ref('newPassword'), null],
        intl.formatMessage({
          id: 'controlpanel.form.validation.password_not_equal'
        })
      )
  });
