import { genericRequest } from 'app/api/genericRequest';

export const singleOrderActions = async ({
  id,
  type,
  message = null,
  setLoading = null,
  customSuccessFeedback = null
}) => {
  const attributes = {
    type
  };

  if (message) {
    attributes.cancellationReason = message;
  }

  const response = await genericRequest({
    url: `orders/${id}/actions`,
    method: 'POST',
    data: {
      data: {
        type: 'single-order-action',
        attributes
      }
    },
    getHeaders: true,
    setLoading,
    customSuccessFeedback
  });

  if (response?.status === 204) {
    return true;
  }

  return false;
};
