import * as Yup from 'yup';

import { base64toFile } from 'app/utils/file-conversor64';
import { checkEmail } from 'app/api/my-account/user-data/check-email';

const KB = 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const getValidationSchema = (intl, setInputLoading, user) =>
  Yup.object({
    email: Yup.string()
      .required(
        intl.formatMessage({ id: 'controlpanel.form.validation.required' })
      )
      .email(intl.formatMessage({ id: 'controlpanel.form.validation.email' }))
      .test(
        'Unique Email',
        intl.formatMessage({ id: 'controlpanel.form.validation.email-exists' }),
        async (email) => {
          if (!email) {
            return false;
          }
          if (email !== user?.attributes?.email) {
            setInputLoading(true);
            const isValidEmail = await checkEmail(email);
            setInputLoading(false);
            return isValidEmail;
          }
          return true;
        }
      ),
    company: Yup.string().max(
      50,
      intl.formatMessage(
        { id: 'controlpanel.form.validation.max_length' },
        { length: '50' }
      )
    ),
    file: Yup.mixed()
      .test(
        'fileSize',
        intl.formatMessage({ id: 'controlpanel.form.validation.file-size' }),
        async (value) => {
          const file = await base64toFile(value);
          if (!file || file?.size / KB >= 20) {
            return false;
          }

          return true;
        }
      )
      .test(
        'fileFormat',
        intl.formatMessage({ id: 'controlpanel.form.validation.file-format' }),
        async (value) => {
          const file = await base64toFile(value);
          if (!file || SUPPORTED_FORMATS.includes(file?.type)) {
            return false;
          }

          return true;
        }
      )
  });
