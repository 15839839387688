import PropTypes from 'prop-types';

import { Colors as colors } from 'app/utils/colors';

export const PROP_TYPES = {
  prefix: PropTypes.oneOf(['fad', 'fak', 'fas']),
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOf([
    '2xl',
    'xl',
    'lg',
    'sm',
    'xs',
    '2xs',
    '10x',
    '9x',
    '8x',
    '7x',
    '6x',
    '5x',
    '4x',
    '3x',
    '2x',
    '1x'
  ]),
  className: PropTypes.string
};

export const DEFAULT_PROPS = {
  prefix: 'fad',
  color: colors.bbGrey2
};
