import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';
import { useIntl } from 'react-intl';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';

import Button from 'app/components/Button';
import DropshippingShopCardEcommerceList from 'app/components/DropshippingShopCard/DropshippingShopCardEcommerceList';
import Icon from 'app/components/Icon';
import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';

import 'app/components/DropshippingShopCard/DropshippingShopCard.scss';

export default function DropshippingShopCard({ shops = null }) {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'controlpanel.dropshipping_shop_card.title'
  });
  const onClickButton = () => {
    window.location.href = `${process.env.REACT_APP_BIGBUY_URL}subscription/checkout`;
  };

  const hasShops = useMemo(() => !!shops?.length, [shops]);
  const iconSize = hasShops ? '2x' : '6x';
  const noShopsClass = useMemo(() => (!hasShops ? 'no-shops' : ''), [hasShops]);
  const text = useMemo(
    () =>
      hasShops
        ? intl.formatMessage({ id: 'controlpanel.dropshipping_shop_card.text' })
        : intl.formatMessage({
            id: 'controlpanel.dropshipping_shop_card.text_no_shop'
          }),
    [hasShops, intl]
  );
  const cta = useMemo(
    () =>
      hasShops
        ? intl.formatMessage({ id: 'controlpanel.dropshipping_shop_card.cta' })
        : intl.formatMessage({
            id: 'controlpanel.dropshipping_shop_card.cta_no_shop'
          }),
    [hasShops, intl]
  );

  return (
    <Card className="dropshipping-shop-card">
      <div className={`dropshipping-shop-card__banner ${noShopsClass}`}>
        <div className="dropshipping-shop-card__banner-content">
          <Row>
            <Col
              md={12}
              className={`center dropshipping-shop-card__banner-title ${noShopsClass}`}
            >
              <Icon icon="store" color="white" size={iconSize} />
              <h4 className="bold">{title}</h4>
            </Col>
            <Col
              md={12}
              className="center dropshipping-shop-card__banner-description"
            >
              <p className="regular big">{text}</p>
            </Col>
            <Col
              md={12}
              className="center dropshipping-shop-card__banner-button"
            >
              <Button
                type="primary"
                size="big"
                text={cta}
                icon={<Icon icon="shopping-cart" />}
                onClick={onClickButton}
              />
            </Col>
            {!hasShops && (
              <Col
                md={12}
                className="center dropshipping-shop-card__banner-ecomerce-logos"
              >
                {getEcommerceLogo('shopify')}
              </Col>
            )}
          </Row>
        </div>
      </div>
      <Container className={`dropshipping-shop-card__shops ${noShopsClass}`}>
        <div>
          {map(shops, (shop, index) => {
            const { logo, mainText, type, url, catalog } = shop;
            return (
              <DropshippingShopCardEcommerceList
                key={index}
                image={logo}
                mainText={mainText}
                ecommerceLogo={getEcommerceLogo(type)}
                url={url}
                catalog={catalog}
                type={type}
              />
            );
          })}
        </div>
      </Container>
    </Card>
  );
}

const getEcommerceLogo = (ecommerce) => {
  if (ecommerce === 'shopify') {
    return (
      <Image
        src={toAbsoluteUrl('/media/logos/shopify-logo.png')}
        className="ecommerce-logo shopify-default"
        fluid
      />
    );
  }
  return (
    <Image
      src={toAbsoluteUrl('/media/logos/prestashop-logo.png')}
      className="ecommerce-logo prestashop-default"
      fluid
    />
  );
};

DropshippingShopCard.propTypes = {
  shops: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      mainText: PropTypes.string,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      catalog: PropTypes.string.isRequired
    })
  )
};
