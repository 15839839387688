import { genericRequest } from 'app/api/genericRequest';

export const stockTrackingActions = async ({
  attributes,
  wishlistId,
  setLoading = null,
  customSuccessFeedback = null
}) => {
  const response = await genericRequest({
    url: `wishlists/${wishlistId}/remove-products`,
    method: 'POST',
    data: {
      data: {
        type: 'remove-wishlist-products',
        attributes
      }
    },
    getHeaders: true,
    setLoading,
    customSuccessFeedback
  });

  if (response?.status === 204) {
    return true;
  }

  return false;
};
