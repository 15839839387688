import React, { useEffect, useState } from 'react';

import { Col, Modal, Row } from 'react-bootstrap';
import { Form, Formik, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { getSelectDefaultValues, Select } from 'app/components/Select';
import { purseMovements } from 'app/modules/Purse/components/Modals';
import Button from 'app/components/Button';
import Input from 'app/components/Input';

export default function Withdrawal() {
  const intl = useIntl();
  const wallet = useSelector((state) => state.auth.user.attributes.wallet);
  const [show, setShow] = useState(false);
  const types = {
    transfer: 'BANKWIRE',
    paypal: 'PAYPAL'
  };
  const MIN_WALLET = 50;
  const [type, setType] = useState(types.transfer);
  const selectOptions = [
    {
      value: types.transfer,
      label: intl.formatMessage({
        id: 'controlpanel.global.transfer'
      })
    },
    {
      value: types.paypal,
      label: intl.formatMessage({
        id: 'controlpanel.global.paypal'
      })
    }
  ];

  const FormObserver = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      setType(values.type);
    }, [values]);
    return null;
  };

  return (
    <div className="purse-modal-withdrawal">
      <Button
        onClick={() => setShow(true)}
        text={intl.formatMessage({
          id: 'controlpanel.purse.modal_withdrawal.button'
        })}
        type="secondary"
        size="small"
      />
      <Formik
        initialValues={{ amount: '', type: types.transfer, typeValue: '' }}
        validationSchema={Yup.object().shape({
          amount: Yup.number()
            .test(
              'Min Value',
              intl.formatMessage(
                { id: 'controlpanel.form.validation.min_value' },
                { value: MIN_WALLET }
              ),
              (value) => {
                if (value < MIN_WALLET) {
                  return false;
                }
                return true;
              }
            )
            .test(
              'Value exceded',
              intl.formatMessage(
                { id: 'controlpanel.form.validation.min_withdrawal' },
                { wallet }
              ),
              (value) => {
                if (value > wallet) {
                  return false;
                }
                return true;
              }
            )
            .required(
              intl.formatMessage({
                id: 'controlpanel.form.validation.required'
              })
            ),
          type: Yup.string().required(
            intl.formatMessage({ id: 'controlpanel.form.validation.required' })
          ),
          typeValue:
            type === types.transfer
              ? Yup.string()
                  .min(
                    10,
                    intl.formatMessage(
                      {
                        id: 'controlpanel.form.validation.min_length'
                      },
                      {
                        length: 10
                      }
                    )
                  )
                  .required(
                    intl.formatMessage({
                      id: 'controlpanel.form.validation.required'
                    })
                  )
              : Yup.string()
                  .required(
                    intl.formatMessage({
                      id: 'controlpanel.form.validation.required'
                    })
                  )
                  .email(
                    intl.formatMessage({
                      id: 'controlpanel.form.validation.email'
                    })
                  )
        })}
        onSubmit={async (values, formik) => {
          const response = await purseMovements(
            {
              type: 'wallet-withdrawal',
              amount: values.amount,
              paymentMethod: values.type,
              account: values.typeValue
            },
            {
              header: intl.formatMessage({
                id: 'controlpanel.purse.notification_withdrawal.success_title'
              }),
              content: intl.formatMessage(
                {
                  id: 'controlpanel.purse.notification_withdrawal.success_msg'
                },
                { amount: values.amount }
              )
            }
          );

          if (response?.status === 204) {
            setShow(false);
            formik.resetForm();
          }

          return false;
        }}
      >
        {(formik) => (
          <Form>
            <FormObserver />
            <Modal
              size="lg"
              show={show}
              onHide={() => {
                setShow(false);
                formik.resetForm();
              }}
            >
              <Modal.Header>
                <p className="bold">
                  {intl.formatMessage({
                    id: 'controlpanel.purse.modal_withdrawal.header'
                  })}
                </p>
              </Modal.Header>
              <Modal.Body className="purse-modal-withdrawal--body">
                <Row>
                  <Col md={12}>
                    <Input
                      name="amount"
                      type="number"
                      label={intl.formatMessage({
                        id: 'controlpanel.global.amount'
                      })}
                      placeholder={intl.formatMessage({
                        id: 'controlpanel.purse.modal_deposit.input_placeholder'
                      })}
                      textPrepend="€"
                    />
                  </Col>
                </Row>
                <Row className="purse-modal-withdrawal--body_type">
                  <Col md={12}>
                    <Select
                      id="type"
                      name="type"
                      label={intl.formatMessage({
                        id: 'controlpanel.purse.table.column.paymentMethod'
                      })}
                      options={selectOptions}
                      defaultValue={getSelectDefaultValues(selectOptions, [
                        formik.initialValues.type
                      ])}
                      bottomComponent={
                        <p className="purse-bottom-label">
                          {intl.formatMessage({
                            id: 'controlpanel.purse.modal_withdrawal.select_bottom'
                          })}
                        </p>
                      }
                      onChange={() => formik.setFieldValue('typeValue', '')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {type === types.transfer ? (
                      <Input
                        name="typeValue"
                        label={intl.formatMessage({
                          id: 'controlpanel.global.bank_account'
                        })}
                        placeholder="LLNNNNNNNNNNNNNNNNNNNNNN"
                        bottomComponent={
                          <p className="purse-bottom-label">
                            {intl.formatMessage({
                              id: 'controlpanel.purse.modal_withdrawal.select_bank_bottom'
                            })}
                          </p>
                        }
                      />
                    ) : (
                      <Input
                        name="typeValue"
                        label={intl.formatMessage({
                          id: 'controlpanel.global.email_paypal_account'
                        })}
                        placeholder="example@example.com"
                      />
                    )}
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="default"
                  onClick={() => {
                    setShow(false);
                    formik.resetForm();
                  }}
                  text={intl.formatMessage({
                    id: 'controlpanel.global.cancel'
                  })}
                  size="big"
                />
                <Button
                  type="primary"
                  typeButton="submit"
                  disabled={!formik.isValid}
                  text={intl.formatMessage({
                    id: 'controlpanel.purse.modal_withdrawal.button'
                  })}
                  size="big"
                  onClick={() => formik.submitForm()}
                />
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  );
}
