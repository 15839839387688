import BasicTableConfig from 'app/components/DataTable/utils/BasicTableConfig';

export const getOptions = () => ({
  sizePerPageList: [
    { text: '10', value: 10 },
    { text: '50', value: 50 },
    { text: '100', value: 100 }
  ],
  sizePerPage: 10,
  page: 1,
  defaultSorted: [
    {
      dataField: 'reference',
      order: 'asc'
    }
  ],
  keyField: 'reference',
  showTotal: true,
  ...BasicTableConfig()
});
