import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { map, isEqual } from 'lodash';
import { useFormikContext } from 'formik';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import {
  canAddNewFilters,
  canSendForm
} from 'app/components/DataTableFilters/utils';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import DatatableFiltersRow from 'app/components/DataTableFilters/DataTableFiltersRow';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

import 'app/components/DataTableFilters/DataTableFilters.scss';

export default function DataTableFilters({ buttonClassName = null }) {
  const {
    filters: {
      activeFilters,
      renderedFilters,
      setRenderedFilters,
      useFiltersConfiguration
    }
  } = useDataTableContext();
  const { getComponentFilter, getOptionsToSelect } = useFiltersConfiguration();
  const [show, setShow] = useState(false);
  const selectOptions = useMemo(
    () => getOptionsToSelect(),
    [getOptionsToSelect]
  );

  const intl = useIntl();

  const { hasFilters, hasMoreThanOneFilter } = useMemo(
    () => ({
      hasFilters: Boolean(Object.entries(renderedFilters)?.length),
      hasMoreThanOneFilter: Boolean(Object.entries(renderedFilters)?.length > 1)
    }),
    [renderedFilters]
  );

  const addButtonIsDisabled = useMemo(
    () => !canAddNewFilters({ renderedFilters, selectOptions }),
    [renderedFilters, selectOptions]
  );

  useEffect(() => {
    setRenderedFilters(Object.keys(activeFilters));
  }, [activeFilters, setRenderedFilters]);

  useEffect(() => {
    if (hasFilters) {
      return;
    }

    const baseFilter = { all: '' };

    if (isEqual(renderedFilters, baseFilter)) {
      return;
    }

    setRenderedFilters(Object.keys(baseFilter));
  }, [renderedFilters, hasFilters, setRenderedFilters]);

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleCleanFilters = () => {
    setRenderedFilters(Object.keys(activeFilters));
  };

  const formik = useFormikContext();

  const noFilterSelected = useMemo(
    () => renderedFilters.includes('all') && renderedFilters?.length === 1,
    [renderedFilters]
  );

  const sendButtonIsEnabled = useMemo(
    () => canSendForm({ noFilterSelected, renderedFilters, formik }),
    [formik, noFilterSelected, renderedFilters]
  );

  return (
    <div className="datatable-filters">
      <Button
        onClick={() => setShow(true)}
        text={intl.formatMessage({ id: 'controlpanel.global.filters' })}
        type="secondary"
        size="big"
        icon={<Icon icon="filter" prefix="fas" color={Colors.bbWhite} />}
        className={buttonClassName}
      />
      <Modal
        size="lg"
        show={show}
        onShow={handleCleanFilters}
        onHide={handleCloseModal}
        aria-labelledby="modal-columns"
      >
        <Modal.Header className="datatable-filters-header">
          <p className="bold">
            {intl.formatMessage({ id: 'controlpanel.global.filter_by' })}
          </p>
        </Modal.Header>
        <Modal.Body className="datatable-filters-body">
          {map(renderedFilters, (filter) => (
            <DatatableFiltersRow
              key={filter}
              filter={filter}
              getComponentFilter={getComponentFilter}
              selectOptions={selectOptions}
              hasMoreThanOneFilter={hasMoreThanOneFilter}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="secondary"
            onClick={() => setRenderedFilters((prev) => [...prev, 'all'])}
            text={intl.formatMessage({ id: 'controlpanel.global.add_filter' })}
            size="big"
            icon={<Icon color="#FFFFFF" icon="plus" prefix="fas" />}
            disabled={addButtonIsDisabled}
          />
          <Button
            type="primary"
            text={
              noFilterSelected
                ? intl.formatMessage({
                    id: 'controlpanel.global.remove_filters'
                  })
                : intl.formatMessage({ id: 'controlpanel.global.apply_filter' })
            }
            size="big"
            onClick={() => {
              formik.submitForm();
              handleCloseModal();
            }}
            disabled={!sendButtonIsEnabled}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

DataTableFilters.propTypes = {
  buttonClassName: PropTypes.string
};
