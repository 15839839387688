import React from 'react';

import { Dropdown, DropdownButton } from 'react-bootstrap';
import { map, size } from 'lodash';
import { useIntl } from 'react-intl';

import useDataTableComponentsContext from 'app/components/DataTableComponents/hooks/useDataTableComponentsContext';

import 'app/components/Pagination/PaginationToolbar/PaginationToolbar.scss';

export function PaginationToolbar() {
  const intl = useIntl();
  const { context, setContext } = useDataTableComponentsContext();

  if (size(context?.api?.meta) === 0) {
    return null;
  }

  const {
    meta: { count },
    options: { page, sizePerPage, sizePerPageList }
  } = context.api;

  const onSizeChange = (newSize) => {
    setContext((prevState) => {
      const newState = { ...prevState };
      newState.api.options.sizePerPage = newSize;
      newState.api.options.page = 1;
      return {
        ...newState
      };
    });
  };

  return (
    <div className="pagination-toolbar">
      <DropdownButton
        title={sizePerPage}
        className="pagination-toolbar__dropdown"
        drop="down"
      >
        {map(sizePerPageList, (option, index) => {
          const { text, value } = option;
          return (
            <Dropdown.Item
              key={index}
              active={sizePerPage === value}
              eventKey={index}
              onClick={() => onSizeChange(value)}
              className="pagination-toolbar__dropdown-menu-item"
              as="p"
            >
              {text}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
      <p className="small regular pagination-toolbar__text  d-none d-md-flex">
        {intl.formatMessage(
          {
            id: 'controlpanel.pagination.text'
          },
          {
            pageFrom: page * sizePerPage - sizePerPage + 1,
            pageTo: page * sizePerPage,
            totalResults: count
          }
        )}
      </p>
    </div>
  );
}
