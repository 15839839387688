export const runSuccessFeedback = () => {
  const successEvent = new CustomEvent('api-request-success');
  document.dispatchEvent(successEvent);
};

export const runErrorFeedback = ({ errorAlertProps }) => {
  const errorEvent = new CustomEvent('api-request-error', {
    bubbles: true,
    detail: {
      ...errorAlertProps
    }
  });
  document.dispatchEvent(errorEvent);
};

export const runCustomFeedback = ({ customSuccessFeedback }) => {
  const customFeedbackEvent = new CustomEvent('run-custom-feedback', {
    bubbles: true,
    detail: {
      variant: 'info',
      content: '',
      ...customSuccessFeedback
    }
  });
  document.dispatchEvent(customFeedbackEvent);
};
