import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'app/components/Icon';
import { getInputClasses } from 'app/components/Input/input.utils';
import { inputsType } from 'app/components/Input/Input.constants';

export default function InputAppend({
  disabled = false,
  isTypePassword = false,
  isHidingText = false,
  textAppend = null,
  error = null,
  touched,
  isFocus,
  setInputType
}) {
  const handleChangeInputType = useCallback(() => {
    setInputType(isHidingText ? inputsType.text : inputsType.password);
  }, [isHidingText, setInputType]);

  const classNameParagraph = classNames(
    'input-group-text regular',
    getInputClasses(error, touched),
    {
      disabled,
      active: isFocus
    }
  );

  return (
    <div className="input-group-append">
      <p className={classNameParagraph}>
        {textAppend}

        {isTypePassword && (
          <span onClick={handleChangeInputType} role="none">
            <Icon icon={isHidingText ? 'eye' : 'eye-slash'} size="lg" />
          </span>
        )}
      </p>
    </div>
  );
}

InputAppend.propTypes = {
  touched: PropTypes.bool.isRequired,
  isFocus: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  textAppend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.string,
  isTypePassword: PropTypes.bool,
  isHidingText: PropTypes.bool,
  setInputType: PropTypes.func.isRequired
};
