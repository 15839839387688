import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import 'app/components/UserInitials/index.scss';

export default function UserInitials({
  classes = {
    userInitials: '',
    nameFirstCharacter: '',
    surnameFirstCharacter: ''
  }
}) {
  const { user } = useSelector((state) => state.auth);

  const userInitials = useMemo(() => {
    const nameFirstCharacter = user?.attributes?.name
      ? user?.attributes?.name.charAt(0)
      : '';

    const surnameFirstCharacter = user?.attributes?.surnames
      ? user?.attributes?.surnames.charAt(0)
      : '';

    return {
      name: nameFirstCharacter,
      surname: surnameFirstCharacter
    };
  }, [user?.attributes?.name, user?.attributes?.surnames]);
  return (
    <span
      className={
        classes?.userInitials
          ? `${classes.userInitials}`
          : 'font-size-h5 font-weight-bold user-initials'
      }
    >
      <h5
        className={
          classes?.nameFirstCharacter ? `${classes.nameFirstCharacter}` : 'bold'
        }
      >
        {userInitials?.name.toUpperCase() + userInitials?.surname.toLowerCase()}
      </h5>
    </span>
  );
}

UserInitials.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string)
};
