export const COLOR_MAP = {
  ENTER_DETAILS: 'grey',
  CHECKING_REQUIREMENTS: 'grey',
  WRONG_REQUIREMENTS: 'red',
  INSTALLATION_IN_PROGRESS: 'blue',
  SHOP_FINISHED: 'green',
  SHOP_ON_HOLD: 'grey',
  SHOP_NOT_SYNCHRONIZED: 'grey',
  PREPARING_INSTALLATION: 'blue'
};

export const TRANSLATION_KEY_MAP = {
  ENTER_DETAILS: 'controlpanel.shops.status.insert_data',
  CHECKING_REQUIREMENTS: 'controlpanel.shops.status.checking',
  WRONG_REQUIREMENTS: 'controlpanel.shops.status.error',
  INSTALLATION_IN_PROGRESS: 'controlpanel.shops.status.building',
  SHOP_FINISHED: 'controlpanel.shops.status.done',
  SHOP_ON_HOLD: 'controlpanel.shops.status.waiting',
  SHOP_NOT_SYNCHRONIZED: 'controlpanel.shops.status.unsynchronized',
  PREPARING_INSTALLATION: 'controlpanel.shops.status.preparing_installation'
};

export const STATUSES_WITHOUT_DESCRIPTION = [
  'SHOP_ON_HOLD',
  'SHOP_NOT_SYNCHRONIZED'
];
