export const canSendForm = ({ noFilterSelected, renderedFilters, formik }) => {
  if (noFilterSelected) {
    return true;
  }

  const checkedFilters = [...renderedFilters];
  const removeAllFromFilters = () => {
    const index = checkedFilters.indexOf('all');

    if (index > -1) {
      checkedFilters.splice(index, 1);
    }
  };

  removeAllFromFilters();

  if (!checkedFilters?.length) {
    return false;
  }

  let canSend = true;

  checkedFilters.forEach((filter) => {
    const targetFilter = formik.values?.[filter];

    if (!targetFilter) {
      canSend = false;
    }
    if (Array.isArray(targetFilter) && !targetFilter?.length) {
      canSend = false;
    }
    if (typeof targetFilter === 'object' && !Array.isArray(targetFilter)) {
      const keys = Object.keys(targetFilter);

      if (!keys?.length) {
        canSend = false;
      }
      keys.forEach((key) => {
        if (!targetFilter?.[key]) {
          canSend = false;
        }
      });
    }
  });

  return canSend;
};
