import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

export default function DefaultAlert({ text = '' }) {
  const intl = useIntl();

  return (
    <p
      className="small"
      dangerouslySetInnerHTML={{
        __html: intl.formatMessage({
          id: text
        })
      }}
    />
  );
}

DefaultAlert.propTypes = {
  text: PropTypes.string
};
