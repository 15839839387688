import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLang } from 'app/context/i18n';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';

import 'app/modules/MyAccount/pages/Syncronize/components/SynchronizeWithBigBuyApiKey.scss';

function SynchronizeInactive({ onShowApiKey, disabled }) {
  const intl = useIntl();

  return (
    <Row>
      <Col md={{ offset: 3, span: 7 }}>
        <Button
          text={intl.formatMessage({
            id: 'controlpanel.my_account.synchronize.api_key.button'
          })}
          type="primary"
          size="big"
          typeButton="button"
          disabled={disabled}
          onClick={onShowApiKey}
          icon={<Icon icon="eye" color={Colors.bbGrey2} size="lg" />}
        />
      </Col>
    </Row>
  );
}

SynchronizeInactive.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onShowApiKey: PropTypes.func.isRequired
};

function SynchronizeActive({ sanboxKey, productionKey }) {
  const intl = useIntl();

  return (
    <>
      <Row className="bb-synchronize-api-key__row-info">
        <Col md={3}>
          <p className="bb-synchronize-api-key__p-info">
            {intl.formatMessage({
              id: 'controlpanel.my_account.synchronize.api_key.sandbox'
            })}
          </p>
        </Col>
        <Col md={7}>
          <p className="bb-synchronize-api-key__p-key small">{sanboxKey}</p>
        </Col>
      </Row>
      <Row className="bb-synchronize-api-key__row-info">
        <Col md={3}>
          <p className="bb-synchronize-api-key__p-info">
            {intl.formatMessage({
              id: 'controlpanel.my_account.synchronize.api_key.production'
            })}
          </p>
        </Col>
        <Col md={7}>
          <p className="bb-synchronize-api-key__p-key small">{productionKey}</p>
        </Col>
      </Row>
    </>
  );
}

SynchronizeActive.propTypes = {
  sanboxKey: PropTypes.string.isRequired,
  productionKey: PropTypes.string.isRequired
};

export default function SynchronizeWithBigBuyApiKey({
  background = Colors.bbWhite,
  disabled = true,
  apiKeySandbox = '',
  apiKeyProduction = '',
  onShowApiKey,
  showApiKey
}) {
  const intl = useIntl();
  const lang = useLang();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (showApiKey && apiKeySandbox && apiKeyProduction) {
      setActive(true);
    }
  }, [showApiKey, apiKeySandbox, apiKeyProduction]);

  return (
    <div className="bb-synchronize-api-key" style={{ background }}>
      <Row>
        <Col md={{ offset: 3, span: 7 }}>
          <p className="big medium">
            {intl.formatMessage({
              id: 'controlpanel.my_account.synchronize.api_key.title'
            })}
          </p>
          <p className="bb-synchronize-api-key__p-des">
            <FormattedMessage
              id="controlpanel.my_account.synchronize.api_key.text"
              values={{
                a: (...chunks) => (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={
                      lang === 'es'
                        ? 'https://www.bigbuy.eu/public/doc/Guia_API_BigBuy_ES.pdf'
                        : 'https://www.bigbuy.eu/public/doc/Guia_API_BigBuy_EN.pdf'
                    }
                  >
                    {chunks}
                  </a>
                )
              }}
            />
          </p>
          <p className="bb-synchronize-api-key__p-des">
            <FormattedMessage
              id="controlpanel.my_account.synchronize.api_key.text2"
              values={{
                a: (...chunks) => (
                  <a rel="noopener noreferrer" target="_blank" href={chunks}>
                    {chunks}
                  </a>
                )
              }}
            />
          </p>
        </Col>
      </Row>
      {active ? (
        <SynchronizeActive
          sanboxKey={apiKeySandbox}
          productionKey={apiKeyProduction}
        />
      ) : (
        <SynchronizeInactive disabled={disabled} onShowApiKey={onShowApiKey} />
      )}
    </div>
  );
}

SynchronizeWithBigBuyApiKey.propTypes = {
  background: PropTypes.string,
  disabled: PropTypes.bool,
  apiKeySandbox: PropTypes.string,
  apiKeyProduction: PropTypes.string,
  onShowApiKey: PropTypes.func.isRequired,
  showApiKey: PropTypes.bool.isRequired
};
