import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs } from 'app/components/SubHeader/components/BreadCrumbs/BreadCrumbs';
import Icon from 'app/components/Icon';
import {
  getTitle,
  getBreadcrumbs,
  useSubheader
} from 'app/context/SubheaderContext';
import Modals from 'app/modules/Purse/components/Modals';
import PrintOrderButton from 'app/modules/OrderView/components/PrintOrderButton';

import 'app/components/SubHeader/SubHeader.scss';

export function SubHeader({ impersonate = false }) {
  const location = useLocation();
  const intl = useIntl();
  const { messages } = intl;

  const subheader = useSubheader();
  const { title, setTitle, breadcrumbs, setBreadcrumbs, icon, setIcon } =
    subheader;

  useLayoutEffect(() => {
    const resultTitle = getTitle('kt_aside_menu');
    setTitle(resultTitle.title);
    setIcon({ icon: resultTitle.icon });

    const resultBreadcrumbs = getBreadcrumbs(location.pathname, messages);
    setBreadcrumbs(resultBreadcrumbs);
  }, [location.pathname, setTitle, setBreadcrumbs, setIcon, messages]);

  return (
    <div
      id="kt_subheader"
      className={`${
        impersonate === true ? 'impersonate' : ''
      } subheader py-2 py-lg-4 subheader-solid`}
    >
      <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="d-flex align-items-center flex-wrap mr-1">
          <div className="d-flex align-items-baseline mr-5">
            <p className="bold big">
              {icon?.icon && (
                <Icon
                  size="lg"
                  className="icon-title bb-icon subheader-icon"
                  {...icon}
                />
              )}
              {title}
            </p>
          </div>

          <BreadCrumbs items={breadcrumbs} />
        </div>
        <div className=" align-items-center">
          {location.pathname.includes('purse') && <Modals />}
          {location.pathname.includes('orders/view') && (
            <PrintOrderButton orderId={location.pathname.split('/')[3]} />
          )}
        </div>
      </div>
    </div>
  );
}

SubHeader.propTypes = {
  impersonate: PropTypes.bool
};
