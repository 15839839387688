import React from 'react';
import PropTypes from 'prop-types';
import { Carousel as BaseCarousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './styles.scss';

function Carousel({ interval = 10000, children }) {
  return (
    <BaseCarousel
      className="bb-carousel"
      showStatus={false}
      showThumbs={false}
      showArrows={false}
      infiniteLoop
      autoPlay
      interval={interval}
    >
      {children}
    </BaseCarousel>
  );
}

Carousel.propTypes = {
  interval: PropTypes.number,
  children: PropTypes.element
};

export default Carousel;
