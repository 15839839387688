import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { CONCEPT_TRANSLATION_MAP } from 'app/modules/Purse/utils';

export const FormatConcept = ({ concept }) => {
  const intl = useIntl();

  return (
    <p className="small purse-data-table concept">
      {intl.formatMessage({ id: CONCEPT_TRANSLATION_MAP[concept] })}
    </p>
  );
};

FormatConcept.propTypes = {
  concept: PropTypes.string.isRequired
};
