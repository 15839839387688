import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'app/components/Image/Image';

import 'app/modules/Wishlist/config/table/formatters/ProductFormatter/ProductFormatter.scss';

export default function ProductFormatter({ image = null, title }) {
  return (
    <div className="product-formatter">
      <Image src={image} alt={title} />
      {title}
    </div>
  );
}

ProductFormatter.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired
};
