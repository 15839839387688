import { useIntl } from 'react-intl';

export default function GetColDataTable() {
  const intl = useIntl();

  return [
    {
      dataField: 'id',
      hiddenToUser: true,
      hidden: true,
      sort: false,
      draggable: false,
      text: 'id'
    },
    {
      dataField: 'orderNumber',
      text: intl.formatMessage({
        id: 'controlpanel.purse.table.column.orderNumber'
      }),
      sort: true,
      hidden: false,
      draggable: false
    },
    {
      dataField: 'date',
      text: intl.formatMessage({ id: 'controlpanel.global.date' }),
      sort: true,
      hidden: false
    },
    {
      dataField: 'concept',
      text: intl.formatMessage({
        id: 'controlpanel.purse.table.column.concept'
      }),
      sort: true,
      hidden: false,
      draggable: false
    },
    {
      dataField: 'status',
      text: intl.formatMessage({
        id: 'controlpanel.purse.table.column.status'
      }),
      sort: true,
      hidden: false,
      draggable: false
    },
    {
      dataField: 'modality',
      text: intl.formatMessage({
        id: 'controlpanel.global.modality'
      }),
      sort: true,
      hidden: false,
      draggable: false
    },
    {
      dataField: 'amount',
      text: intl.formatMessage({
        id: 'controlpanel.purse.table.column.amount'
      }),
      sort: true,
      hidden: false,
      draggable: false
    }
  ];
}
