import React from 'react';

import { useSelector } from 'react-redux';

import 'app/components/ImpersonateAlert/styles.scss';

export default function ImpersonateAlert() {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="impersonate-alert">
      <p>{`Impersonado como: ${user?.attributes?.email} (${user.id})`}</p>
      <a
        className="bb-link"
        href={`${process.env.REACT_APP_BIGBUY_URL}exit_impersonate`}
      >
        Salir impersonalización
      </a>
    </div>
  );
}
