export const getTabs = (intl) => [
  {
    id: 0,
    name: intl.formatMessage({ id: 'controlpanel.global.all_plural' }),
    filters: null
  },
  {
    id: 1,
    name: intl.formatMessage({ id: 'controlpanel.global.pending_validation' }),
    filters: {
      status: ['PENDING_PAYMENT', 'PENDING_INVOICING']
    }
  },
  {
    id: 2,
    name: intl.formatMessage({ id: 'controlpanel.global.cancelled' }),
    filters: {
      status: ['CANCELLED', 'CANCELLED_PAYMENT', 'REIMBURSED_PAYMENT']
    }
  },
  {
    id: 3,
    name: intl.formatMessage({ id: 'controlpanel.global.in_process' }),
    filters: { status: ['PROCESSING', 'PROCESSED'] }
  }
];
