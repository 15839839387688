import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import AlertBody from 'app/components/Alert/components/AlertBody';
import AlertDismissButton from 'app/components/Alert/components/AlertDismissButton';
import AlertHeader from 'app/components/Alert/components/AlertHeader';

import { Colors } from 'app/utils/colors';

const { bbGreen, bbOrange, bbSecondaryDark, bbRed } = Colors;

const VARIANT_COLOR_MAP = {
  success: bbGreen,
  danger: bbRed,
  warning: bbOrange,
  info: bbSecondaryDark
};

export default function AlertStructure({
  header = null,
  content = null,
  dismissible = null,
  children = null,
  textColor = null,
  variant = null,
  handleClose
}) {
  if (children && isValidElement(children)) {
    return children;
  }

  const containerClasses = classNames('bb-alert__container', {
    gap: header && content
  });

  const dismissClasses = classNames('bb-alert__actions', {
    'bb-alert__dismiss-btn--in-header': header,
    'bb-alert__dismiss-btn--in-body': content && !header,
    [`bb-alert__icon bb-alert-${variant}`]: variant
  });

  return (
    <div className={containerClasses}>
      {header && <AlertHeader content={header} textColor={textColor} />}
      {content && <AlertBody content={content} textColor={textColor} />}
      {dismissible && (
        <AlertDismissButton
          className={dismissClasses}
          color={VARIANT_COLOR_MAP[variant]}
          handleClose={handleClose}
        />
      )}
    </div>
  );
}

AlertStructure.propTypes = {
  header: PropTypes.node,
  content: PropTypes.node,
  children: PropTypes.node,
  dismissible: PropTypes.bool,
  variant: PropTypes.string,
  textColor: PropTypes.string,
  handleClose: PropTypes.func.isRequired
};
