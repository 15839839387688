import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Field, useFormikContext } from 'formik';
import { format } from 'date-fns';
import * as locales from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { useIntl } from 'react-intl';

import { useLang } from 'app/context/i18n';
import Label from 'app/components/Label';

import 'app/components/SelectDateRange/SelectDateRange.scss';
import 'react-datepicker/dist/react-datepicker.css';

export default function SelectDateRange({
  dateFormat = 'yyyy/MM/dd',
  tooltipText = '',
  label = '',
  defaultStartDate = null,
  defaultEndDate = null,
  nameStartDate,
  nameEndDate
}) {
  const formik = useFormikContext();
  const lang = useLang();
  const locale = getLocale(lang);
  const [showCalendar, setShowCalendar] = useState(false);
  const startDefault = defaultStartDate ? new Date(defaultStartDate) : null;
  const endDefault = defaultEndDate ? new Date(defaultEndDate) : null;
  const [startDate, setStartDate] = useState(startDefault);
  const [endDate, setEndDate] = useState(endDefault);
  const intl = useIntl();

  const selectOptions = [
    {
      value: 'today',
      label: intl.formatMessage({ id: 'controlpanel.global.date_today' })
    },
    {
      value: 'last7',
      label: intl.formatMessage(
        {
          id: 'controlpanel.global.date_last_7days'
        },
        { days: '7' }
      )
    },
    {
      value: 'last30',
      label: intl.formatMessage(
        {
          id: 'controlpanel.global.date_last_30days'
        },
        { days: '30' }
      )
    },
    {
      value: 'last90',
      label: intl.formatMessage(
        {
          id: 'controlpanel.global.date_last_90days'
        },
        { days: '90' }
      )
    },
    {
      value: 'last12',
      label: intl.formatMessage(
        {
          id: 'controlpanel.global.date_last_12months'
        },
        { months: '12' }
      )
    },
    {
      value: 'calendar',
      label: intl.formatMessage({
        id: 'controlpanel.global.custom'
      })
    }
  ];
  const onChangeDatePicker = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start) {
      setInputValues(
        formik.setFieldValue,
        nameStartDate,
        format(start, dateFormat)
      );
    }

    if (end) {
      setInputValues(
        formik.setFieldValue,
        nameEndDate,
        format(end, dateFormat)
      );
    }

    if (start && end) {
      setShowCalendar(false);
    }
  };

  const onChangeSelect = (e) => {
    setShowCalendar(false);
    const date = new Date();

    setInputValues(formik.setFieldValue, nameEndDate, format(date, dateFormat));

    switch (e.value) {
      case 'calendar':
        setShowCalendar(true);
        break;
      case 'last7':
        date.setDate(date.getDate() - 7);
        break;
      case 'last30':
        date.setDate(date.getDate() - 30);
        break;
      case 'last90':
        date.setDate(date.getDate() - 90);
        break;
      case 'last12':
        date.setDate(date.getDate() - 360);
        break;

      default:
        setShowCalendar(false);
        break;
    }

    setInputValues(
      formik.setFieldValue,
      nameStartDate,
      format(date, dateFormat)
    );
  };

  return (
    <div className="select-data-range">
      <Field type="hidden" name={nameStartDate} />
      <Field type="hidden" name={nameEndDate} />
      {label && (
        <Label
          label={label}
          tooltipText={tooltipText}
          htmlFor="select-picker"
        />
      )}
      <div>
        <Select
          id="select-picker"
          onChange={onChangeSelect}
          options={selectOptions}
          getOptionLabel={(option) => {
            if (option.value === 'calendar' && startDate && endDate) {
              return `${format(startDate, dateFormat)} - ${format(
                endDate,
                dateFormat
              )}`;
            }

            return option.label;
          }}
          onFocus={() => setShowCalendar(false)}
          blurInputOnSelect
          isOptionSelected={(option) => {
            if (option.value === 'calendar' && startDate && endDate) {
              return true;
            }

            return false;
          }}
          defaultValue={() => {
            if (startDate && endDate) {
              return selectOptions[5];
            }
            return null;
          }}
        />
      </div>
      <div style={{ display: showCalendar === false ? 'none' : 'block' }}>
        <DatePicker
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          selected={startDate}
          onChange={onChangeDatePicker}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          locale={locale}
        />
      </div>
      {(formik.touched[nameStartDate] && formik.errors[nameStartDate]) ||
      (formik.touched[nameEndDate] && formik.errors[nameEndDate]) ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            {`${formik.errors[nameStartDate]} ${formik.errors[nameEndDate]}`}
          </div>
        </div>
      ) : null}
    </div>
  );
}

const setInputValues = (setFieldValue, inputName, newValue) => {
  setFieldValue(inputName, newValue);
};

const getLocale = (lang) => {
  let defaultLang;
  switch (lang) {
    case 'en':
      defaultLang = 'enGB';
      break;

    case 'no':
      defaultLang = 'enGB';
      break;

    default:
      defaultLang = lang;
      break;
  }

  return locales[defaultLang];
};

SelectDateRange.propTypes = {
  nameStartDate: PropTypes.string.isRequired,
  nameEndDate: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  tooltipText: PropTypes.string,
  label: PropTypes.string,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string
};
