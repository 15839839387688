import React from 'react';

import Tabs from 'app/components/TabBar';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

export default function DataTableTabs() {
  const {
    tabs: { currentTab, handleTabChange, tabs }
  } = useDataTableContext();

  return <Tabs value={currentTab} onChange={handleTabChange} tabs={tabs} />;
}
