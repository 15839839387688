export const getTabs = (intl) => [
  {
    id: 0,
    name: intl.formatMessage({ id: 'controlpanel.global.all_plural' }),
    filters: null
  },
  {
    id: 1,
    name: intl.formatMessage({
      id: 'controlpanel.trackings.tabs.ready_to_shipment'
    }),
    filters: { status: ['READY_TO_SHIP'] }
  },
  {
    id: 2,
    name: intl.formatMessage({ id: 'controlpanel.trackings.tabs.in_transit' }),
    filters: { status: ['IN_TRANSIT'] }
  },
  {
    id: 3,
    name: intl.formatMessage({ id: 'controlpanel.trackings.tabs.exceptions' }),
    filters: { status: ['DELIVERY_EXCEPTION', 'OTHER_EXCEPTION'] }
  },
  {
    id: 4,
    name: intl.formatMessage({ id: 'controlpanel.trackings.tabs.delivered' }),
    filters: { status: ['DELIVERED'] }
  }
];
