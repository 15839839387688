import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Image } from 'app/components/Image/Image';

import 'app/modules/Downloads/components/SocialSelling/SocialSelling.scss';

export default function SocialSelling({
  image,
  description,
  onClick,
  initialSelected = false
}) {
  const [selected, setSelected] = useState(initialSelected);

  useEffect(() => {
    setSelected(initialSelected);
  }, [initialSelected]);

  const handleClick = () => {
    onClick();
    setSelected(!selected);
  };

  return (
    <div
      className="social-selling"
      onClick={handleClick}
      role="button"
      tabIndex={0}
      onKeyDown={handleClick}
    >
      <div className="social-selling__checkbox">
        <input
          className="bb-checkbox"
          type="checkbox"
          checked={selected}
          readOnly
        />
      </div>
      <div className="social-selling__image">
        <Image src={image} alt={description} />
      </div>
      <div className="social-selling__description">
        <p className="small">{description}</p>
      </div>
    </div>
  );
}

SocialSelling.propTypes = {
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  initialSelected: PropTypes.bool
};
