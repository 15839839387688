import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { size } from 'lodash';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { formatFiltersToEndpoint } from 'app/utils/filters';
import { recalculatePgOptionsOnDeleteItems } from 'app/components/DataTable/utils';
import { stockTrackingActions } from 'app/api/stockTrackingActions';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import Popup from 'app/components/Popup';
import Spinner from 'app/components/Spinner';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

export default function PopupDelete({ show, setShow, id = null }) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const intl = useIntl();
  const {
    api: { fetchData },
    datatable: {
      rows: { excludedRows, selectedRows, selectAll, setSelectedRows },
      paginationOptions,
      setPaginationOptions,
      totalSize,
      setTotalSize
    },
    filters: { filters },
    context: { readContextStorage }
  } = useDataTableContext();
  const { wishlistId } = readContextStorage('wishlistData');

  const handleClose = () => {
    setShow(false);
    setDisabled(false);
  };

  const attributes = {};
  const formatedFilters = formatFiltersToEndpoint(filters);

  let countIds = 1;

  if (id) {
    attributes.includedIds = [id];
  } else {
    attributes.all = selectAll;
    attributes.includedIds = selectedRows;
    attributes.excludedIds = excludedRows;
  }

  if (size(formatedFilters) > 0) {
    attributes.filter = formatedFilters;
  }

  if (selectAll) {
    countIds = Number(totalSize) - size(excludedRows);
  } else if (id) {
    countIds = 1;
  } else {
    countIds = size(selectedRows);
  }

  return (
    <Popup
      show={show}
      header={
        <div className="bb-popup-repeat-order__header">
          <p className="bold">
            {intl.formatMessage({
              id: 'controlpanel.global.delete_product_title'
            })}
          </p>
        </div>
      }
      body={
        <div className="bb-popup-repeat-order__body">
          <p>
            {intl.formatMessage({
              id: 'controlpanel.global.delete_product_text'
            })}
          </p>
        </div>
      }
      footer={
        <div className="bb-popup-repeat-order__footer">
          <Button
            text={intl.formatMessage({
              id: 'controlpanel.global.close'
            })}
            type="default"
            size="big"
            onClick={handleClose}
          />
          <Button
            text={intl.formatMessage({ id: 'controlpanel.global.delete' })}
            type="primary"
            size="big"
            disabled={disabled}
            icon={
              loading === false ? (
                <Icon icon="trash" color={Colors.bbGrey2} />
              ) : (
                <Spinner />
              )
            }
            onClick={async () => {
              setDisabled(true);
              const deleteStockTracking = await stockTrackingActions({
                attributes,
                wishlistId,
                setLoading,
                customSuccessFeedback: {
                  variant: 'success',
                  content: intl.formatMessage({
                    id: 'controlpanel.global.delete_success'
                  }),
                  autoClose: true,
                  dismissible: false
                }
              });
              handleClose();
              if (deleteStockTracking === true) {
                setSelectedRows([]);
                const newPgOptions = recalculatePgOptionsOnDeleteItems(
                  paginationOptions,
                  countIds
                );
                setPaginationOptions(newPgOptions);
                setTotalSize(newPgOptions?.totalSize);
                fetchData();
              }
            }}
          />
        </div>
      }
      handleClose={handleClose}
    />
  );
}

PopupDelete.propTypes = {
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  id: PropTypes.string
};
