import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Button from 'app/components/Button/Button';
import Timeline from 'app/components/Timeline';
import WalletImage from 'app/assets/png/wallet_image_dashboard.png';

import {
  DEFAULT_SIZE,
  BUTTON_SIZE
} from 'app/components/WalletCard/walletcard.constants';
import { formatCurrency } from 'app/utils/currency';
import { URLS } from 'app/config/urls';

import 'app/components/WalletCard/WalletCard.scss';

export default function WalletCard({
  type = 'default',
  numTimes = 2,
  times,
  titleWallet,
  balanceTitle,
  balanceValue,
  titleInfo,
  payInTitle,
  drawOutTitle,
  currentLang
}) {
  const navigate = useNavigate();

  const onRedirectPocket = () => {
    navigate(URLS.purse, { replace: true });
  };

  return (
    <div className="bb-wallet-card">
      <div
        className={`bb-wallet-card__image ${
          type === DEFAULT_SIZE
            ? 'bb-wallet-card__image--default'
            : 'bb-wallet-card__image--medium'
        }`}
      >
        <Image
          src={WalletImage}
          alt="image"
          className={`${type === DEFAULT_SIZE ? '--default' : '--medium'}`}
        />
      </div>
      <div className="bb-wallet-card__header">
        <h5 className="bold">{titleWallet}</h5>
        <Button
          text={titleInfo}
          size={type === DEFAULT_SIZE ? BUTTON_SIZE.BIG : BUTTON_SIZE.MEDIUM}
          onClick={onRedirectPocket}
        />
      </div>
      <div
        className={`bb-wallet-card__center ${
          type === DEFAULT_SIZE
            ? 'bb-wallet-card__center--default'
            : 'bb-wallet-card__center--medium'
        }`}
      >
        <p className="big bold">{balanceTitle}</p>
        <h2 className="bold">{formatCurrency(balanceValue, 0, currentLang)}</h2>
      </div>
      <div
        className={`bb-wallet-card__bottom ${
          type === DEFAULT_SIZE
            ? 'bb-wallet-card__bottom--default'
            : 'bb-wallet-card__bottom--medium'
        }`}
      >
        <Button
          size="big"
          type="primary"
          text={payInTitle}
          onClick={onRedirectPocket}
        />
        <Button size="big" text={drawOutTitle} onClick={onRedirectPocket} />
        <Timeline times={times.slice(0, numTimes)} />
      </div>
    </div>
  );
}

const colors = PropTypes.oneOf([
  'red',
  'green',
  'yellow',
  'blue',
  'purple',
  'grey'
]);

const TimesItem = PropTypes.shape({
  lastChild: PropTypes.bool,
  leftSide: PropTypes.shape({
    dateTitle: PropTypes.string.isRequired,
    dateSubtitle: PropTypes.string,
    dateColor: colors
  }),
  rightSide: PropTypes.shape({
    eventTitle: PropTypes.string.isRequired,
    eventSubtitle: PropTypes.string,
    eventColor: colors
  }),
  circleColor: colors
});

WalletCard.propTypes = {
  type: PropTypes.oneOf(['default', 'medium']),
  times: PropTypes.arrayOf(TimesItem).isRequired,
  numTimes: PropTypes.number,
  titleWallet: PropTypes.string.isRequired,
  balanceTitle: PropTypes.string.isRequired,
  balanceValue: PropTypes.number.isRequired,
  titleInfo: PropTypes.string.isRequired,
  payInTitle: PropTypes.string.isRequired,
  drawOutTitle: PropTypes.string.isRequired,
  currentLang: PropTypes.string.isRequired
};
