import React from 'react';
import PropTypes from 'prop-types';

import Button from '../bigbuy-group-library/Button';

import 'app/components/Promo/Promo.scss';

export default function Promo({ image, buttons }) {
  return (
    <div className="bb-promo-card">
      <img src={image} alt={image} />
      <div className="bb-promo-card__links">
        {buttons.map((x) => (
          <Button
            variant={x.variant}
            type={x.type}
            onClick={() => window.open(x.url)}
            size="small"
          >
            {x.content}
          </Button>
        ))}
      </div>
    </div>
  );
}

Promo.propTypes = {
  image: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};
