import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { filter, map } from 'lodash';
import { useIntl } from 'react-intl';

import { NOTIFICATION_PROPS, markAsRead } from 'app/api/notifications';
import Button from 'app/components/Button';
import Card from 'app/components/Card';
import Icon from 'app/components/Icon';
import useNotificationsServiceHook from 'app/hooks/useNotificationsService.hook';
import useNotificationsTypes from 'app/hooks/useNotificationsTypes.hook';

import 'app/components/UserNotificationAlert/UserNotificationAlert.scss';

export default function UserNotificationAlert({ alerts }) {
  const intl = useIntl();
  const notificationsType = useNotificationsTypes();
  const { setNotifications } = useNotificationsServiceHook();

  return (
    <Card
      extraClass="card-notification-alert"
      body={
        <>
          <h5 className="bold">
            {intl.formatMessage({ id: 'controlpanel.global.alerts' })}
          </h5>
          {map(alerts, (alert, index) => {
            const { linkText, link, title, text, type } = alert?.attributes;
            const { defaultLink, defaultTextLink, icon } =
              notificationsType[alert?.attributes?.section];
            let dissmissNotification = null;

            if (type !== 'ERROR') {
              dissmissNotification = (
                <Icon
                  icon="remove"
                  color="#50565B"
                  className="notification-dissmiss"
                  prefix="far"
                  size="lg"
                  onClick={async () => {
                    await markAsRead(alert?.id);

                    const newAlerts = filter(
                      alerts,
                      (obj) => obj?.id !== alert?.id
                    );
                    setNotifications((prevState) => ({
                      ...prevState,
                      alerts: [...newAlerts]
                    }));
                  }}
                />
              );
            }

            return (
              <Row key={index}>
                <Col md={12}>
                  <div
                    className={`user-notification-alert alert--${type.toLowerCase()}`}
                  >
                    <div className="user-notification-alert__container">
                      <div className="user-notification-alert__container-header">
                        <Icon icon={icon} className="alert-icon" />
                      </div>
                      <div className="user-notification-alert__container-body">
                        <p className="big bold title-alert">
                          {title}
                          {dissmissNotification}
                        </p>
                        <p>{text}</p>
                      </div>
                    </div>
                    <div className="user-notification-alert__footer">
                      <Button
                        text={linkText || defaultTextLink}
                        size="medium"
                        type="outline"
                        onClick={() =>
                          window.open(link || defaultLink, '_SELF')
                        }
                      />
                      {dissmissNotification}
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </>
      }
    />
  );
}

UserNotificationAlert.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.shape(NOTIFICATION_PROPS)).isRequired
};
