import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';

export default function AlertHeader({
  content = null,
  children = null,
  textColor: color = null
}) {
  let componentContent = content;

  if (children && isValidElement(children)) componentContent = children;

  if (typeof content === 'string')
    componentContent = (
      <p
        dangerouslySetInnerHTML={{ __html: content }}
        className="bold"
        style={{ color }}
      />
    );

  return <div className="bb-alert__header">{componentContent}</div>;
}

AlertHeader.propTypes = {
  content: PropTypes.node,
  children: PropTypes.node,
  textColor: PropTypes.string
};
