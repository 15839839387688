import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { useLang } from 'app/context/i18n';

import Button from 'app/components/Button';
import { Colors } from 'app/utils/colors';

import 'app/modules/MyAccount/pages/Syncronize/components/SynchronizeWithBigBuyFTP.scss';

function SynchronizeInactive({ onEnableFTP, disabled }) {
  const intl = useIntl();

  return (
    <Row>
      <Col md={{ offset: 3, span: 7 }}>
        <Button
          type="primary"
          text={intl.formatMessage({
            id: 'controlpanel.my_account.synchronize.ftp.button'
          })}
          size="big"
          typeButton="button"
          disabled={disabled}
          onClick={onEnableFTP}
        />
      </Col>
    </Row>
  );
}

SynchronizeInactive.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onEnableFTP: PropTypes.func.isRequired
};

function SynchronizeActive({ server, user, password }) {
  const intl = useIntl();

  return (
    <>
      <Row className="bb-synchronize-ftp__row-info">
        <Col md={3}>
          <p className="bb-synchronize-ftp__p-info">
            {intl.formatMessage({
              id: 'controlpanel.my_account.synchronize.ftp.server'
            })}
          </p>
        </Col>
        <Col md={7}>
          <p className="medium">{server}</p>
        </Col>
      </Row>
      <Row className="bb-synchronize-ftp__row-info">
        <Col md={3}>
          <p className="bb-synchronize-ftp__p-info">
            {intl.formatMessage({
              id: 'controlpanel.my_account.synchronize.ftp.user'
            })}
          </p>
        </Col>
        <Col md={7}>
          <p className="medium">{user}</p>
        </Col>
      </Row>
      <Row className="bb-synchronize-ftp__row-info">
        <Col md={3}>
          <p className="bb-synchronize-ftp__p-info">
            {intl.formatMessage({
              id: 'controlpanel.my_account.synchronize.ftp.password'
            })}
          </p>
        </Col>
        <Col md={7}>
          <p className="medium">{password}</p>
        </Col>
      </Row>
    </>
  );
}

SynchronizeActive.propTypes = {
  server: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired
};

export default function SynchronizeWithBigBuyFTP({
  background = Colors.bbWhite,
  ftpDomain = '',
  ftpPassword = '',
  ftpUser = '',
  disabled = true,
  onEnableFTP
}) {
  const intl = useIntl();
  const lang = useLang();

  const [active, setActive] = useState(false);

  useEffect(() => {
    if (ftpDomain && ftpPassword && ftpUser) {
      setActive(true);
    }
  }, [ftpDomain, ftpPassword, ftpUser]);

  return (
    <div className="bb-synchronize-ftp" style={{ background }}>
      <Row>
        <Col md={{ offset: 3, span: 7 }}>
          <p className="big medium">
            {intl.formatMessage({
              id: 'controlpanel.my_account.synchronize.ftp.title'
            })}
          </p>
          <p>
            <FormattedMessage
              id="controlpanel.my_account.synchronize.text"
              values={{
                a: (...chunks) => (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={
                      lang === 'es'
                        ? 'https://www.bigbuy.eu/public/doc/Datos_acceso_FTP_ES.pdf'
                        : 'https://www.bigbuy.eu/public/doc/Datos_acceso_FTP_EN.pdf'
                    }
                  >
                    {chunks}
                  </a>
                )
              }}
            />
          </p>
        </Col>
      </Row>
      {active ? (
        <SynchronizeActive
          server={ftpDomain}
          user={ftpUser}
          password={ftpPassword}
        />
      ) : (
        <SynchronizeInactive disabled={disabled} onEnableFTP={onEnableFTP} />
      )}
    </div>
  );
}

SynchronizeWithBigBuyFTP.propTypes = {
  background: PropTypes.string,
  ftpDomain: PropTypes.string,
  ftpPassword: PropTypes.string,
  ftpUser: PropTypes.string,
  disabled: PropTypes.bool,
  onEnableFTP: PropTypes.func.isRequired
};
