import React from 'react';
import PropTypes from 'prop-types';

import { Col, Image } from 'react-bootstrap';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import Button from 'app/components/Button';

import 'app/components/MarketplaceConnectorList/MarketplaceConnectorListCard.scss';

export default function MarketplaceConnectorListCard({
  image = null,
  mainText = null,
  purchased = null,
  id
}) {
  const intl = useIntl();
  const hasPurchases = purchased?.length > 0;

  const onClickButton = () => {
    window.open(
      `${process.env.REACT_APP_BIGBUY_URL}subscription/checkout?serviceProductId=${id}`,
      '_blank'
    );
  };
  return (
    <Col xs={6} md={4} lg={3} className="connector-list-card">
      <div
        className={classNames('connector-list-card_container', {
          active: hasPurchases
        })}
      >
        <div className="connector-list-card_container__info">
          {image && (
            <div className="connector-list-card_container__image">
              <Image src={image} />
            </div>
          )}
          {mainText && (
            <p className="bold connector-list-card_container__name">
              {mainText}
            </p>
          )}
        </div>

        {hasPurchases ? (
          <div className="connector-list-card_container__date">
            <p className="medium">
              {intl.formatMessage({ id: 'controlpanel.global.active' })}
            </p>
            <p className="regular">{purchased[0]}</p>
          </div>
        ) : (
          <div className="connector-list-card_container__button">
            <Button
              size="small"
              text={intl.formatMessage({
                id: 'controlpanel.marketplace_connector_card.buy'
              })}
              svg="/media/svg/bigbuy/medium/shopping-cart.svg"
              onClick={onClickButton}
            />
          </div>
        )}
      </div>
    </Col>
  );
}

MarketplaceConnectorListCard.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string,
  mainText: PropTypes.string,
  purchased: PropTypes.arrayOf(PropTypes.string)
};
