import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PROP_TYPES as BTN_PROPS } from 'app/components/Button/utils';
import { PROP_TYPES as DROPDOWN_PROPS } from 'app/components/Dropdown/types';
import Button from 'app/components/Button';
import Dropdown from 'app/components/Dropdown';

import 'app/components/ButtonDropdown/ButtonDropdown.scss';

export default function ButtonDropdown({
  buttonOptions = {
    text: '',
    type: 'default',
    size: 'huge',
    style: {},
    icon: null,
    tooltipText: '',
    disabled: false,
    typeButton: 'button',
    onClick: () => null,
    children: undefined,
    className: null,
    id: ''
  },
  dropdownOptions
}) {
  const [show, setShow] = useState(false);

  const btOptions = {
    type: 'primary',
    size: 'medium',
    typeButton: 'button',
    ...buttonOptions
  };

  return (
    <div className="bb-button-dropdown">
      <Button {...btOptions} onClick={() => setShow(!show)} />
      <Dropdown
        {...dropdownOptions}
        show={show}
        onClick={() => setShow(false)}
      />
    </div>
  );
}

ButtonDropdown.propTypes = {
  buttonOptions: PropTypes.shape(BTN_PROPS),
  dropdownOptions: PropTypes.shape(DROPDOWN_PROPS).isRequired
};
