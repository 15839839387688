import React, { isValidElement, useMemo } from 'react';

import classNames from 'classnames';

import Icon from 'app/components/Icon';
import {
  ICON_PROPS,
  ICON_COLOR_MAP
} from 'app/components/Notification/utils/index';

export default function NotificationIcon({
  infoIcon = null,
  children = null,
  className = null,
  variant = null
}) {
  const color = useMemo(() => ICON_COLOR_MAP[variant], [variant]);

  if (children && isValidElement(children)) {
    return children;
  }

  const classes = classNames('bb-notification__icon', className);

  return (
    <div className={classes}>
      <Icon {...infoIcon} color={color} />
    </div>
  );
}

NotificationIcon.propTypes = ICON_PROPS;
