import { PACK_TYPE_NUMBER, PACK_COLORS } from 'app/utils/constants';

export const getPackColorMap = () => {
  const colorMap = {};
  Object.values(PACK_TYPE_NUMBER).forEach((value) => {
    colorMap[value] = PACK_COLORS[value];
  });
  return colorMap;
};

const getPackColor = (id) => getPackColorMap()[id];

export default getPackColor;
