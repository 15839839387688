import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import logo from 'app/assets/png/paypal-logo.png';

import 'app/modules/MyAccount/pages/PaymentMethods/components/PayPal/PayPalDetail.scss';

export default function PayPalDetail({ ownerName }) {
  const intl = useIntl();
  return (
    <Row className="bb-payment-methods-paypal__paypal-item">
      <Col
        className="bb-payment-methods-paypal__paypal-item--image"
        xl={3}
        md={2}
      >
        <img src={logo} alt="" />
      </Col>
      <Col className="bb-payment-methods-paypal__paypal-item--info" md={4}>
        <p className="bb-payment-methods-paypal__paypal-item--info-title">
          {intl.formatMessage({
            id: 'controlpanel.payment_methods.paypal_detail.title'
          })}
        </p>
        <p className="bb-payment-methods-paypal__paypal-item--info-subtitle medium">
          {ownerName}
        </p>
      </Col>
    </Row>
  );
}

PayPalDetail.propTypes = {
  ownerName: PropTypes.string.isRequired
};
