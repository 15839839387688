import React from 'react';

import PaymentMethods from 'app/modules/MyAccount/pages/PaymentMethods/PaymentMethods';
import { PaymentMethodsProvider } from 'app/modules/MyAccount/pages/PaymentMethods/context/PaymentMethodsContext';

export default function PaymentMethodsPage() {
  return (
    <PaymentMethodsProvider>
      <PaymentMethods />
    </PaymentMethodsProvider>
  );
}
