import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'app/components/Icon';
import { colorsTypePropType } from 'app/components/PackInfoCard/PackInfoCard.utils';
import 'app/components/PackInfoCard/components/PackInfoHeader.scss';

export default function PackInfoHeader({
  title,
  packId,
  packNameText,
  colors,
  useSmallView
}) {
  const intl = useIntl();
  return (
    <div
      className={classNames([
        'bb-pack-info-header',
        {
          'bb-pack-info-header--small': useSmallView,
          'bb-pack-info-header--normal': !useSmallView
        }
      ])}
      style={{
        backgroundColor: colors.hedaderBG,
        color: colors.hedaderTheme
      }}
    >
      <h5 className="bold">{title}</h5>
      <div className="bb-pack-info-header__box">
        <Icon icon="cube" size="7x" color={colors.hedaderTheme} />
        <p
          className="bb-pack-info-header__box__tag big bold"
          style={{
            backgroundColor: colors.hedaderTheme
          }}
        >
          {packId === 0
            ? intl.formatMessage({ id: 'controlpanel.pack.name.without_pack' })
            : intl.formatMessage(
                { id: 'controlpanel.pack.name.active' },
                { packName: packNameText }
              )}
        </p>
      </div>
    </div>
  );
}

PackInfoHeader.propTypes = {
  colors: colorsTypePropType.isRequired,
  title: PropTypes.string.isRequired,
  packId: PropTypes.number.isRequired,
  packNameText: PropTypes.string.isRequired,
  useSmallView: PropTypes.bool.isRequired
};
