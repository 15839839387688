import React from 'react';

import { Dropdown, DropdownButton } from 'react-bootstrap';
import { map, size } from 'lodash';
import { useIntl } from 'react-intl';

import { getClasses } from 'app/components/Pagination/PaginationLinks/utils';

export default function BasicTableConfig() {
  const intl = useIntl();
  return {
    paginationTotalRenderer: (from, to, sizePage) => (
      <p className="small regular pagination-toolbar__text  d-none d-lg-flex">
        {intl.formatMessage(
          {
            id: 'controlpanel.pagination.text'
          },
          {
            pageFrom: from,
            pageTo: to,
            totalResults: sizePage
          }
        )}
      </p>
    ),
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="pagination-toolbar">
        <DropdownButton
          title={currSizePerPage}
          className="pagination-toolbar__dropdown"
          drop="down"
        >
          {map(options, (option, index) => (
            <Dropdown.Item
              key={index}
              active={currSizePerPage === option.pageButtonRenderer}
              eventKey={index}
              onClick={() => onSizePerPageChange(option.page)}
              className="pagination-toolbar__dropdown-menu-item"
              as="p"
            >
              {option.text}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    ),
    pageListRenderer: ({ pages, onPageChange }) => {
      const pagesCount = size(pages);
      const disabledClass = pagesCount > 1 ? '' : 'disabled';
      return (
        <div className="pagination-links">
          {pagesCount < 2 && <div />}
          {pagesCount > 1 && (
            <div className={`d-flex flex-wrap py-2 mr-3 ${disabledClass}`}>
              {pages.map((p) => (
                <p
                  className={getClasses({ active: p.active })}
                  key={p.page}
                  onClick={() => onPageChange(p.page)}
                  aria-hidden="true"
                >
                  {p.page}
                </p>
              ))}
            </div>
          )}
        </div>
      );
    }
  };
}
