import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useMemo
} from 'react';
import PropTypes from 'prop-types';

import { FROM_CONTEXT_DEFAULT_PROPS } from 'app/components/Alert/utils/types';

const initalFeedbackState = FROM_CONTEXT_DEFAULT_PROPS;

const initialState = {
  giveFeedback: () => {},
  closeFeedback: () => {},
  animateLoadingBar: () => {},
  feedback: initalFeedbackState,
  animationBarIsRunning: false
};

const FeedbackContext = createContext(initialState);

export const FeedbackProvider = ({ children }) => {
  const [feedbackState, setFeedbackState] = useState(initalFeedbackState);
  const [animationBarIsRunning, setAnimationBarIsRunning] = useState(false);

  const feedbackHandler = (params) =>
    setFeedbackState((prev) => ({ ...prev, ...params, show: true }));

  const handleAnimationBar = () => setAnimationBarIsRunning((prev) => !prev);

  const closeFeedbackHandler = () => setFeedbackState(initalFeedbackState);

  const value = useMemo(
    () => ({
      giveFeedback: feedbackHandler,
      closeFeedback: closeFeedbackHandler,
      animateLoadingBar: handleAnimationBar,
      animationBarIsRunning,
      feedback: feedbackState
    }),
    [animationBarIsRunning, feedbackState]
  );

  // Handle feedback context from events:
  const handleSuccessFromEvent = () => {
    handleAnimationBar();
  };
  const handleAlertFromEvent = (e) => {
    feedbackHandler(e.detail);
  };

  useEffect(() => {
    document.addEventListener('api-request-success', handleSuccessFromEvent);
    document.addEventListener('api-request-error', handleAlertFromEvent);
    document.addEventListener('run-custom-feedback', handleAlertFromEvent);

    const cleanup = () => {
      document.removeEventListener(
        'api-request-success',
        handleSuccessFromEvent
      );
      document.removeEventListener('api-request-error', handleAlertFromEvent);
      document.removeEventListener('run-custom-feedback', handleAlertFromEvent);
    };

    return () => cleanup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FeedbackContext.Provider value={value}>
      {children}
    </FeedbackContext.Provider>
  );
};

FeedbackProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const useFeedbackContext = () => useContext(FeedbackContext);

export default useFeedbackContext;
