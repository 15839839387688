import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import LinearProgress from '@material-ui/core/LinearProgress';

const ControlledLoadingBar = ({
  current,
  total,
  hideWhenCompleted = false,
  hideWhenZero = false
}) => {
  const intl = useIntl();

  const pending = useMemo(
    () => Math.round(((total - current) / total) * 100),
    [current, total]
  );

  const verifyedInteger = useMemo(
    () => (Number.isNaN(pending) || !Number.isFinite(pending) ? 0 : pending),
    [pending]
  );

  if (hideWhenCompleted && verifyedInteger === 100) {
    return null;
  }

  if (hideWhenZero && verifyedInteger === 0) {
    return null;
  }

  return (
    <div>
      <p>
        {intl.formatMessage(
          {
            id: 'controlpanel.loading_bar.orders'
          },
          {
            total,
            current: total - current
          }
        )}
      </p>
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={verifyedInteger}
      />
    </div>
  );
};

export default ControlledLoadingBar;

ControlledLoadingBar.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  hideWhenCompleted: PropTypes.bool,
  hideWhenZero: PropTypes.bool
};
