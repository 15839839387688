/* eslint-disable no-unused-expressions */
import { endPoint } from 'app/config/axios/customAxios';
import {
  runErrorFeedback,
  runSuccessFeedback,
  runCustomFeedback
} from './utils/feedbackEvent';
import { startLoading, endLoading } from './utils/loadingFunction';

export async function genericRequest({
  url,
  method = 'get',
  data = {},
  getHeaders = false,
  localData = false,
  errorAlertProps = {},
  blockFeedback = false,
  setLoading = null,
  customSuccessFeedback = null,
  signal = null,
  catchError = false
}) {
  blockFeedback || startLoading(setLoading);

  try {
    const result = await endPoint.request({
      url,
      method,
      baseURL: !localData
        ? process.env.REACT_APP_API_URL
        : process.env.REACT_APP_API_URL_LOCAL,
      data,
      signal
    });

    // Handle success feedback:
    blockFeedback || endLoading(setLoading);
    blockFeedback || runSuccessFeedback();
    customSuccessFeedback && runCustomFeedback({ customSuccessFeedback });

    return getHeaders ? result : result?.data;
  } catch (error) {
    if (
      process.env.NODE_ENV === 'development' &&
      error?.code !== 'ERR_CANCELED'
    ) {
      // eslint-disable-next-line no-console
      console.error(url, error?.response);
    }

    if (catchError) return { isError: true, error, response: error?.response };

    if (error?.code === 'ERR_CANCELED') {
      return true;
    }

    blockFeedback || endLoading(setLoading);

    if (error?.response) {
      const { status, data: errorData } = error.response;
      const errorMessage =
        errorData?.errors?.[0]?.title || errorData?.title || status || '';

      const showErrorFeedback = () => {
        blockFeedback ||
          runErrorFeedback({
            errorAlertProps: {
              variant: 'danger',
              content: errorMessage,
              dismissible: true,
              autoClose: true,
              ...errorAlertProps
            }
          });
      };

      // 400 Error en los datos de la petición
      // 403 El usuario no tiene permisos para realizar la acción
      // 404 No existe el endpoint

      switch (status) {
        // 401 El usuario no está autenticado
        case 401:
          navigate('tiendab2b.html?authuser=1');
          return false;
        case 403:
          showErrorFeedback();
          return false;
        // 409 Datos ya existen en la base de datos
        case 409:
          showErrorFeedback();
          return false;

        default:
          showErrorFeedback();
          break;
      }
    }

    throw error;
  }
}

const navigate = (url) =>
  (window.location.href = `${process.env.REACT_APP_BIGBUY_URL}${url}`);
