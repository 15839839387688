import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import 'app/components/TabBar/TabBar.scss';

export default function TabBar({
  value,
  onChange,
  tabs,
  scrollButtons = 'off'
}) {
  return (
    <Tabs
      className="bb-tab-bar"
      value={value}
      onChange={onChange}
      variant="scrollable"
      aria-label="scrollable tabs"
      scrollButtons={scrollButtons}
    >
      {map(tabs, (tab) => (
        <Tab key={tab.id} label={tab.name} />
      ))}
    </Tabs>
  );
}

TabBar.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  scrollButtons: PropTypes.oneOf(['auto', 'desktop', 'off', 'on']),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.node,
      name: PropTypes.string
    })
  ).isRequired
};
