import React from 'react';

import { Link } from 'react-router-dom';

import { Colors } from 'app/utils/colors';
import Icon from 'app/components/Icon';

import 'app/components/HeaderMobile/HeaderMobile.scss';

export function HeaderMobile() {
  return (
    <>
      {/* begin::Header Mobile */}
      <div
        id="kt_header_mobile"
        className="header-mobile align-items-center header-mobile-fixed"
      >
        {/* begin::Logo */}
        <Link to="/">
          <img
            alt="logo"
            src="https://cms.bigbuy.eu/uploads/bigbuy_logo_374c505207.svg"
            className="logo-header"
          />
        </Link>
        {/* end::Logo */}

        {/* begin::Toolbar */}
        <div className="d-flex align-items-center">
          {/* begin::Aside Mobile Toggle */}
          <button
            type="button"
            className="bb-btn reset-styles"
            id="kt_aside_mobile_toggle"
            aria-label="Toggle"
          >
            <Icon icon="bars" color={Colors.bbGrey3} size="lg" />
          </button>
          {/* end::Aside Mobile Toggle */}

          {/* begin::Topbar Mobile Toggle */}
          <button
            type="button"
            className="bb-btn reset-styles"
            id="kt_header_mobile_topbar_toggle"
            aria-label="Toggle"
          >
            <Icon icon="user" color={Colors.bbGrey3} size="lg" />
          </button>
          {/* end::Topbar Mobile Toggle */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header Mobile */}
    </>
  );
}
