import React from 'react';
import PropTypes from 'prop-types';

import { getRedColumnsStatus } from 'app/hooks/useShopsStatus.hook';

export const FormatGeneric = ({ text = '', statusKey }) => {
  let classes = 'bb-shops__data-table__cell small';

  if (!text) {
    return '';
  }

  if (getRedColumnsStatus().includes(statusKey)) {
    classes += ' warning';
  }

  return <p className={classes}>{text}</p>;
};

FormatGeneric.propTypes = {
  text: PropTypes.string,
  statusKey: PropTypes.string.isRequired
};
