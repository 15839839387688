import { useIntl } from 'react-intl';

import { useNavigate } from 'react-router-dom';

import { DateFormatter } from 'app/modules/OrderView/config/TableFormatters/DateFormatter';
import { ActionsFormatter } from 'app/modules/OrderView/config/TableFormatters/ActionsFormatter';

export default function GetColDataTable() {
  const intl = useIntl();
  const navigate = useNavigate();

  return [
    {
      dataField: 'invoiceNumber',
      text: intl.formatMessage({ id: 'controlpanel.global.invoice_number' }),
      sort: true,
      hidden: false
    },
    {
      dataField: 'date',
      text: intl.formatMessage({ id: 'controlpanel.global.date' }),
      sort: true,
      hidden: false,
      formatter: DateFormatter
    },
    {
      dataField: 'payment',
      text: intl.formatMessage({ id: 'controlpanel.global.paymentmethod' }),
      sort: true,
      hidden: false
    },
    // TODO: V2, se deja todo preparado
    // {
    //   dataField: 'type',
    //   text: intl.formatMessage({ id: 'controlpanel.global.type' }),
    //   sort: true,
    //   hidden: false
    // },
    {
      dataField: 'amount',
      text: intl.formatMessage({ id: 'controlpanel.global.amount' }),
      sort: true,
      hidden: false
    },
    {
      dataField: 'actions',
      text: intl.formatMessage({ id: 'controlpanel.global.table_actions' }),
      formatter: ActionsFormatter,
      classes: 'column-action',
      sort: false,
      formatExtraData: {
        navigate,
        intl
      }
    }
  ];
}
