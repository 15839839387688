import React from 'react';
import PropTypes from 'prop-types';

import { ICON_MAP } from 'app/modules/Trackings/utils';
import Icon from 'app/components/Icon';
import {
  getAllStatusExceptions,
  getGroupedStatus
} from 'app/hooks/useTrackingsStatus.hook';

const getIcon = ({ statusKey }) => {
  if (getAllStatusExceptions().includes(statusKey)) {
    return ICON_MAP.redExclamationTriangle;
  }

  if (getGroupedStatus().VOIDED.includes(statusKey)) {
    return ICON_MAP.blueExlamationBell;
  }

  return null;
};

export const FormatIcon = ({ statusKey }) => {
  const icon = getIcon({ statusKey });

  if (icon) {
    return (
      <div className="bb-trackings__data-table__cell--icon-wrapper">
        <Icon {...icon} />
      </div>
    );
  }

  return null;
};

FormatIcon.propTypes = {
  statusKey: PropTypes.string.isRequired
};
