import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Colors } from 'app/utils/colors';
import { formatDateToLocale, formatHours } from 'app/utils/dates';
import Icon from 'app/components/Icon';

import 'app/modules/Devolutions/components/ChatMessage/ChatMessage.scss';

export default function ChatMessage({ admin, date, comment }) {
  const icon = useMemo(() => (admin ? 'comments' : 'user'), [admin]);

  return (
    <div className="devolutions-chat-message">
      <div className="devolutions-chat-message__header">
        <Icon icon={icon} color={Colors.bbGrey2} />
        {admin && <p className="small bold">Servicio Postventa</p>}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="devolutions-chat-message__header__label regular">
          {`${formatDateToLocale(date * 1000)} - ${formatHours(date * 1000)}`}
        </label>
      </div>
      <div className="devolutions-chat-message__body">
        <p className="regular small">{comment}</p>
      </div>
    </div>
  );
}

ChatMessage.propTypes = {
  admin: PropTypes.bool.isRequired,
  date: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired
};
