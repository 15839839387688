import React from 'react';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { URLS } from 'app/config/urls';
import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';

import 'app/components/Brand/Brand.scss';

export function Brand() {
  return (
    <>
      {/* begin::Brand */}
      <div className="brand flex-column-auto" id="kt_brand">
        {/* begin::Logo */}
        <Link to={`${URLS.home}`} className="brand-logo">
          <img
            alt="logo"
            src="https://cms.bigbuy.eu/uploads/bigbuy_logo_374c505207.svg"
          />
        </Link>
        {/* end::Logo */}

        {/* begin::Toggle */}
        <button
          className="brand-toggle btn btn-sm px-0"
          id="kt_aside_toggle"
          type="button"
          aria-label="toggle"
        >
          <span className="svg-icon svg-icon-xl">
            <SVG
              src={toAbsoluteUrl(
                '/media/svg/icons/Navigation/Angle-double-left.svg'
              )}
            />
          </span>
        </button>
        {/* end::Toolbar */}
      </div>
      {/* end::Brand */}
    </>
  );
}
