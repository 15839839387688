import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { useLang } from 'app/context/i18n';
import { Select, getSelectDefaultValues } from 'app/components/Select';
import { SELECT_INPUTS } from 'app/modules/Shops/pages/Shopify/config';
import { SELECTS_TITLE } from 'app/modules/Shops/pages/Shopify/utils';

import 'app/modules/Shops/pages/Shopify/components/Forms.scss';

export default function SelectsForm({ values, demoLink }) {
  const intl = useIntl();
  const lang = useLang();
  const formik = useFormikContext();
  const [selectInputs, setSelectInputs] = useState([]);

  const handleClick = () => window.open(demoLink, '_blank');

  useEffect(() => {
    (async () => {
      setSelectInputs(await SELECT_INPUTS(lang));
    })();
  }, [lang]);

  return (
    <div className="shopify-form__container">
      <Row>
        <Col
          md={{ span: 9, offset: 3 }}
          className="shopify-form__container-title"
        >
          <p className="big medium">
            {intl.formatMessage({ id: SELECTS_TITLE })}
          </p>
        </Col>
      </Row>

      {selectInputs.map((item, index) => {
        const {
          paragraph,
          warning,
          field: { name, options }
        } = item;
        return (
          <div id={name} key={`select-${name}-${index}`}>
            <Row className="shopify-form__input-wrapper shopify-form__input-wrapper--select">
              <Col md={3} className="shopify-form__input-label">
                <p>{intl.formatMessage({ id: paragraph })}</p>
              </Col>
              <Col md={9} className="shopify-form__input">
                <Select
                  formik={formik}
                  id={name}
                  name={name}
                  placeholder={<p>{values[name]}</p>}
                  searchable
                  options={options}
                  defaultValue={getSelectDefaultValues(options, [
                    formik?.values?.[name]
                  ])}
                  disabled={
                    formik?.values?.statusKey !== 'ENTER_DETAILS' &&
                    formik?.values?.statusKey !== 'WRONG_REQUIREMENTS'
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col
                md={{ span: 9, offset: 3 }}
                className="shopify-form__input__files__description"
              >
                <p className="small">{intl.formatMessage({ id: warning })}</p>
              </Col>
            </Row>
          </div>
        );
      })}
      <Row>
        <Col
          md={{ span: 9, offset: 3 }}
          className="shopify-form__input__files__description__link"
        >
          <div
            onClick={handleClick}
            onKeyDown={handleClick}
            role="button"
            tabIndex={0}
          >
            <p>
              {intl.formatMessage({ id: 'controlpanel.global.view_themes' })}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

SelectsForm.propTypes = {
  values: PropTypes.shape({
    catalogue: PropTypes.string,
    theme: PropTypes.string
  }).isRequired,
  demoLink: PropTypes.string.isRequired
};
