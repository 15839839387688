import React, { useMemo } from 'react';

import { BasicDataTable } from 'app/components/DataTable';
import Columns from 'app/modules/Devolutions/components/TableProducts/config/Columns';
import useRMAContext from 'app/modules/Devolutions/context/context';
import {
  getDataTableStructure,
  getExpandRow
} from 'app/modules/Devolutions/components/TableProducts/config';
import { getRowClass } from 'app/modules/Devolutions/components/TableProducts/formatters/getRowClass';
import Summary from 'app/components/DataTable/Summary';

import 'app/modules/Devolutions/components/TableProducts/TableProducts.scss';
import { useIntl } from 'react-intl';

export default function TableProducts() {
  const { expanded, rmaProducts, loading, rmaStatus, rmaProductsMeta } =
    useRMAContext();

  const intl = useIntl();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const entities = useMemo(
    () => getDataTableStructure(rmaProducts),
    [rmaProducts]
  );

  let summaryLines = null;
  if (rmaStatus === 'PENDING_PAYMENT' || rmaStatus === 'PAID') {
    summaryLines = [
      {
        text: {
          bold: true,
          value: intl.formatMessage({ id: 'controlpanel.global.taxable_base' })
        },
        price: {
          bold: true,
          value: `${rmaProductsMeta?.totalWithoutTax?.toFixed(2)} €`
        }
      },
      {
        text: {
          value: intl.formatMessage({ id: 'controlpanel.global.iva' })
        },
        price: {
          bold: true,
          value: `${rmaProductsMeta?.totalTax?.toFixed(2)} €`
        }
      },
      {
        text: {
          bold: true,
          color: 'blue',
          size: 'big',
          value: intl
            .formatMessage({ id: 'controlpanel.global.total' })
            .toUpperCase()
        },
        price: {
          bold: true,
          color: 'blue',
          size: 'big',
          value: `${rmaProductsMeta?.total?.toFixed(2)} €`
        }
      }
    ];
  }

  if (typeof rmaStatus === 'undefined') {
    return null;
  }

  return (
    <div className="table-products">
      <BasicDataTable
        id="products-rma"
        columns={Columns(rmaStatus)}
        entities={entities}
        keyField="id"
        loading={loading}
        rowClasses={getRowClass}
        expandRow={
          rmaStatus === 'DRAFT'
            ? getExpandRow({ entities, expanded, rmaStatus })
            : false
        }
      />
      {summaryLines && <Summary lines={summaryLines} />}
    </div>
  );
}
