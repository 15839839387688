import React from 'react';
import PropTypes from 'prop-types';

import { getRedColumnsStatus } from 'app/hooks/useShopsStatus.hook';
import Icon from 'app/components/Icon';
import { Colors } from 'app/utils/colors';

export const FormatIcon = ({ statusKey }) => {
  if (getRedColumnsStatus().includes(statusKey)) {
    return (
      <div className="bb-shops__data-table__cell--warning-wrapper">
        <Icon icon="exclamation-triangle" color={Colors.bbRed} />
      </div>
    );
  }

  return null;
};

FormatIcon.propTypes = {
  statusKey: PropTypes.string.isRequired
};
