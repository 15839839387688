import React from 'react';

import {
  AddCreditCardHeader,
  AddCreditCardBody,
  AddCreditCardFooter
} from 'app/modules/MyAccount/pages/PaymentMethods/components/PopupContent';

export const INITIAL_POPUP_CONTENT = {
  popupHeader: () => <div />,
  popupBody: () => <div />,
  popupFooter: () => <div />
};

export const CLOSE_POPUP = 'CLOSE_POPUP';
export const ADD_CREDIT_CARD = 'ADD_CREDIT_CARD';

export const ACTIONS_MAP = {
  ADD_CREDIT_CARD: {
    popupHeader: (args) => <AddCreditCardHeader {...args} />,
    popupBody: (args) => <AddCreditCardBody {...args} />,
    popupFooter: (args) => <AddCreditCardFooter {...args} />
  }
};

export const OK = 'OK';
export const KO = 'KO';
