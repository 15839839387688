import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import useRMAContext from 'app/modules/Devolutions/context/context';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import { postRmaLine } from 'app/modules/Devolutions/services';

const CloneAction = ({
  sku,
  orderId,
  rmaId,
  quantity,
  eligibleQuantity,
  returnReason,
  enabled
}) => {
  const intl = useIntl();
  const { setLoading, getRmaProducts } = useRMAContext();

  const handleClone = () => {
    const totalQuantity = Math.min(eligibleQuantity, quantity);

    const cloneRmaLine = async () => {
      await postRmaLine(
        {
          data: {
            type: 'rma-line',
            attributes: {
              sku,
              orderId,
              rmaId,
              returnReason,
              quantity: totalQuantity
            }
          }
        },
        {
          customSuccessFeedback: {
            variant: 'success',
            content: intl.formatMessage({
              id: 'controlpanel.feedback.save_changes.success'
            }),
            autoClose: true,
            dismissible: false
          },
          setLoading
        }
      );
      await getRmaProducts();
    };
    cloneRmaLine();
  };

  return (
    <Button
      icon={<Icon icon="files" color={Colors.bbSecondary} />}
      size="medium"
      tooltipText={intl.formatMessage({
        id: 'controlpanel.global.clone'
      })}
      onClick={() => handleClone()}
      disabled={!enabled}
    />
  );
};

CloneAction.propTypes = {
  sku: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  rmaId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  eligibleQuantity: PropTypes.number.isRequired,
  returnReason: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired
};

export default CloneAction;
