import { genericRequest } from 'app/api/genericRequest';

/**
 * @param {array} ids
 * @param {string} mediaType
 * @param {array} languages
 */

export const getMediaZipFiles = async (ids, mediaType, languages = null) => {
  try {
    const request = await genericRequest({
      url: 'media-download-request',
      method: 'POST',
      data: {
        data: {
          type: 'media-download-request',
          attributes: {
            ids,
            languages,
            mediaType
          }
        }
      }
    });
    return request;
  } catch (error) {
    return error;
  }
};
