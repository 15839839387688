import { INITIAL_VALUES } from 'app/modules/Orders/config/filters';
import { getTabs } from 'app/modules/Orders/config/tabs';
import {
  getColumnsToDataTable,
  getNoResultsConfig,
  getDataTableStructure,
  rowClasses
} from 'app/modules/Orders/config/table';
import { useFiltersConfiguration } from 'app/modules/Orders/hooks/useFiltersConfiguration';
import { validate } from 'app/modules/Orders/config/validate';

export const getOrdersDataTableConfiguration = ({ intl }) => ({
  contextId: 'orders',
  // Datatable
  apiUrl: 'orders',
  getColumnsToDataTable,
  getDataTableStructure,
  getNoResultsConfig,
  extraTableConfiguration: {
    rowClasses
  },
  // Filters
  INITIAL_VALUES,
  useFiltersConfiguration,
  // Tabs
  tabs: getTabs(intl),
  // Validator Inputs
  validate: (inputContext) => validate(inputContext, intl)
});
