import React from 'react';

import { URLS } from 'app/config/urls';
import Add from 'app/modules/Devolutions/pages/Add';
import Devolutions from 'app/modules/Devolutions';

export const devolutionsRoutes = {
  path: URLS.devolutions,
  children: [
    { path: '', element: <Devolutions /> },
    { path: URLS.devolutionsAdd, element: <Add /> },
    { path: URLS.devolutionsAddWithId, element: <Add /> }
  ]
};
