/* eslint-disable object-shorthand */
import React, { useEffect } from 'react';

import classNames from 'classnames';

import AlertStructure from 'app/components/Alert/components/AlertStructure';
import useFeedbackContext from 'app/context/FeedbackContext';

import 'app/components/Alert/Alert.scss';

export default function AlertFromContext() {
  const {
    closeFeedback: handleClose,
    feedback: {
      background,
      autoClose,
      children,
      fixedTo,
      fixedAlignment,
      className,
      variant,
      show,
      ...rest
    }
  } = useFeedbackContext();

  useEffect(() => {
    if (!autoClose) {
      return;
    }
    const closeTimer = setTimeout(() => {
      clearTimeout(closeTimer);
      handleClose();
    }, 2000);
  }, [autoClose, handleClose]);

  if (!show) {
    return null;
  }

  const classes = classNames('bb-alert', `bb-alert-${variant}`, className);

  const componentContent = (
    <div className={classes} style={{ background: background }}>
      <AlertStructure variant={variant} handleClose={handleClose} {...rest}>
        {children}
      </AlertStructure>
    </div>
  );

  if (!fixedTo) {
    return componentContent;
  }

  const wrapperClasses = classNames('bb-alert__wrapper', {
    [`align-${fixedAlignment}`]: fixedAlignment,
    [`fixed-to-${fixedTo}`]: fixedTo
  });

  return <div className={wrapperClasses}>{componentContent}</div>;
}
