import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { size } from 'lodash';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import ButtonsHeader from 'app/modules/Wishlist/components/ButtonsHeader';
import ColumnReorderModal from 'app/components/ColumnReorderModal';
import DataTable from 'app/components/DataTable';
import DataTableFilters from 'app/components/DataTableFilters';
import DataTableFiltersTags from 'app/components/DataTableFiltersTags';
import Icon from 'app/components/Icon';
import SearchBar from 'app/components/SearchBar';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

export default function StockTracking() {
  const intl = useIntl();
  const context = useDataTableContext();
  const {
    filters: { filters, removeFilter, parsedFiltersArray }
  } = context;

  const { submitForm } = useFormikContext();

  return (
    <div className="wishlist__datatable-container">
      <Row className="bb-data-table__filters">
        {size(context?.api?.apiResponse?.data) > 0 && (
          <>
            <Col xs={12} md={5} className="bb-data-table__searchbar">
              <SearchBar
                placeholder={intl.formatMessage({
                  id: 'controlpanel.global.search'
                })}
                name="search"
                button={{
                  onClick: submitForm,
                  type: 'secondary',
                  size: 'big',
                  icon: (
                    <Icon icon="search" prefix="fas" color={Colors.bbWhite} />
                  )
                }}
                value={filters?.search || ''}
                onClear={() => removeFilter('search')}
              />
            </Col>
            <Col xs={12} md={7} className="bb-data-table__filters-buttons">
              <DataTableFilters />
              <ColumnReorderModal />
            </Col>
          </>
        )}

        {size(parsedFiltersArray) > 0 && (
          <Col md={12}>
            <DataTableFiltersTags />
          </Col>
        )}
      </Row>

      <DataTable />
      {size(context?.api?.apiResponse?.data) > 0 && <ButtonsHeader />}
    </div>
  );
}
