import React from 'react';
import PropTypes from 'prop-types';

import { selectedOnClick } from 'app/components/DataTableComponents/utils';
import SelectedLayer from 'app/components/SelectedLayer';
import useDataTableComponentsContext from 'app/components/DataTableComponents/hooks/useDataTableComponentsContext';

import 'app/modules/Downloads/components/ImageView/ImageView.scss';

export default function ImageView({
  id,
  size = 'medium',
  mediaType = 'marketing-media',
  imgSrc
}) {
  const { context, setContext } = useDataTableComponentsContext();

  return (
    <div key={id} className={`image-view ${size}`}>
      <SelectedLayer
        id={id}
        onClick={() => selectedOnClick(id, mediaType, context, setContext)}
        initialSelected={context?.selected?.includes(id)}
      >
        <div
          className="image-view__image"
          style={{
            background: `url('${imgSrc}') no-repeat`
          }}
        />
      </SelectedLayer>
    </div>
  );
}

ImageView.propTypes = {
  id: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  imgSrc: PropTypes.string.isRequired,
  size: PropTypes.string,
  mediaType: PropTypes.string
};
