import { Colors } from 'app/utils/colors';

export const ITEMS = [
  {
    icon: 'network-wired',
    translationId: 'controlpanel.multichannel.icons_list.1',
    color: Colors.bbSecondary,
    size: 'lg'
  },
  {
    icon: 'scanner-keyboard',
    translationId: 'controlpanel.multichannel.icons_list.2',
    color: Colors.bbSecondary,
    size: 'lg'
  },
  {
    icon: 'tags',
    translationId: 'controlpanel.multichannel.icons_list.3',
    color: Colors.bbSecondary,
    size: 'lg'
  },
  {
    icon: 'hand-holding-box',
    translationId: 'controlpanel.multichannel.icons_list.4',
    color: Colors.bbSecondary,
    size: 'lg'
  },
  {
    icon: 'truck',
    translationId: 'controlpanel.multichannel.icons_list.5',
    color: Colors.bbSecondary,
    size: 'lg'
  },
  {
    icon: 'warehouse-alt',
    translationId: 'controlpanel.multichannel.icons_list.6',
    color: Colors.bbSecondary,
    size: 'lg'
  }
];
