import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import 'app/modules/MyAccount/pages/Notifications/components/SectionMessage.scss';

export default function SectionMessage({ sectionTranslationKey }) {
  const intl = useIntl();

  return (
    <p className="bb-notification-panel--regular-font margin">
      {intl.formatMessage({
        id: `controlpanel.my_account.notifications.${sectionTranslationKey}_message`
      })}
    </p>
  );
}

SectionMessage.propTypes = {
  sectionTranslationKey: PropTypes.string.isRequired
};
