import React from 'react';
import PropTypes from 'prop-types';
import Divider from 'app/components/Divider';
import 'app/components/FormCard/FormCard.scss';

export default function FormCard({ buttons = null, children = null, title }) {
  return (
    <div className="bb-formcard">
      <div className="bb-formcard__container">
        <p className="big bold">{title}</p>

        {!!buttons && buttons && (
          <div className="bb-formcard__container__buttons">{buttons}</div>
        )}
      </div>

      <Divider />
      <div className="bb-formcard__body">{children}</div>
    </div>
  );
}

FormCard.propTypes = {
  title: PropTypes.string.isRequired,
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
