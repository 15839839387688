/* eslint-disable no-case-declarations */
import { map } from 'lodash';
import { useIntl } from 'react-intl';

import { formatCurrency } from 'app/utils/currency';
import { CONCEPT_TRANSLATION_MAP } from 'app/modules/Purse/utils';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';
import useOrdersStatus from 'app/hooks/useOrdersStatus.hook';
import usePaymentMethods from 'app/hooks/usePaymentMethods.hook';
import useTrackingsStatus from 'app/hooks/useTrackingsStatus.hook';
import useDevolutionsStatus from 'app/hooks/useDevolutionsStatus.hook';

export default function useDefaultFormatterTag(filters) {
  const intl = useIntl();
  const paymentMethods = usePaymentMethods();
  const orderStatus = useOrdersStatus();
  const trackingsStatus = useTrackingsStatus();
  const devolutionsStatus = useDevolutionsStatus();
  const {
    context: { contextId }
  } = useDataTableContext();
  const formattedText = {};

  map(filters, (value, filter) => {
    const translationId = `controlpanel.global.filter.${filter}`;
    let translationText = '';

    if (value) {
      switch (filter) {
        //* Tipo fecha
        case 'date':
        case 'expedition':
        case 'expedition_date':
          translationText = `${intl.formatMessage({
            id: translationId
          })}: ${value.start} - ${value.end}`;
          break;

        //* Tipo boolean yes / no
        case 'stock':
        case 'dropshipping':
          let text = intl.formatMessage({ id: 'controlpanel.global.yes' });
          if (value === 'false') {
            text = intl.formatMessage({ id: 'controlpanel.global.no' });
          }

          translationText = `${intl.formatMessage({
            id: translationId
          })}: ${text}`;
          break;

        case 'virtual':
          if (value === 'false') {
            text = intl.formatMessage({
              id: 'controlpanel.orders.filters.virtual.order_products_tag'
            });
          } else {
            text = intl.formatMessage({
              id: 'controlpanel.orders.filters.virtual.order_services_tag'
            });
          }

          translationText = `${intl.formatMessage({
            id: 'controlpanel.orders.filters.type_order'
          })}: ${text}`;
          break;

        //* Tipo range slider price format
        case 'amount':
        case 'pvp':
        case 'pvd':
        case 'discount':
          translationText = `${intl.formatMessage({
            id: translationId
          })}: ${formatCurrency(value[0])} - ${formatCurrency(value[1])}`;
          break;

        //* Tipo translations map (añadir al mapeo TRANSLATIONS_MAP)
        case 'payment':
        case 'status':
          let translationsMap = [];

          if (filter === 'payment') {
            translationsMap = paymentMethods;
          } else if (filter === 'status' && contextId === 'orders') {
            translationsMap = orderStatus;
          } else if (filter === 'status' && contextId === 'trackings') {
            translationsMap = trackingsStatus;
          } else if (filter === 'status' && contextId === 'devolutions') {
            translationsMap = devolutionsStatus;
          }

          const valueTranslationKeys = value.map(
            (item) => translationsMap?.[item]?.translation
          );

          translationText = `${intl.formatMessage({
            id: translationId
          })}: ${valueTranslationKeys.join(', ')}`;
          break;

        case 'carrier':
          const parsedArray = value.toString().replace(',', ', ');

          translationText = `${intl.formatMessage({
            id: translationId
          })}: ${parsedArray}`;
          break;

        case 'is_read':
          const valueTranslationKey =
            value === 'true'
              ? 'controlpanel.global.read'
              : 'controlpanel.global.not_read';

          translationText = `${intl.formatMessage({
            id: translationId
          })}: ${intl.formatMessage({ id: valueTranslationKey })}`;
          break;

        case 'concept':
          const valueString = intl.formatMessage({
            id: CONCEPT_TRANSLATION_MAP?.[value]
          });

          translationText = `${intl.formatMessage({
            id: translationId
          })}: ${valueString}`;
          break;

        default:
          translationText = `${intl.formatMessage({
            id: translationId
          })}: ${value}`;
          break;
      }

      formattedText[filter] = translationText;
    }
  });

  return formattedText;
}
