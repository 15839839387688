import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';

import { getPages, getPagesCount } from 'app/utils/TablePaginationHelpers';
import { getClasses } from 'app/components/Pagination/PaginationLinks/utils';

import 'app/components/Pagination/PaginationLinks/PaginationLinks.scss';

export function PaginationLinks({ paginationProps }) {
  const { totalSize, sizePerPage, page, paginationSize, onPageChange } =
    paginationProps;

  const pagesCount = useMemo(
    () => getPagesCount(totalSize, sizePerPage),
    [totalSize, sizePerPage]
  );
  const pages = useMemo(
    () => getPages(page, pagesCount, paginationSize),
    [page, pagesCount, paginationSize]
  );

  const isFirstPage = useMemo(() => page === 1, [page]);
  const isLastPage = useMemo(() => page === pagesCount, [page, pagesCount]);

  const handleFirstPage = () => {
    onPageChange(1);
  };

  const handlePrevPage = () => {
    onPageChange(page - 1);
  };

  const handleNextPage = () => {
    if (page < pagesCount) {
      onPageChange(page + 1);
    }
  };

  const handleLastPage = () => {
    onPageChange(pagesCount);
  };

  const handleSelectedPage = (pageNum) => {
    onPageChange(pageNum);
  };

  const disabledClass = pagesCount > 1 ? '' : 'disabled';
  const arrowBackClass = page === 1 ? 'd-none d-md-flex' : 'd-md-flex';
  const arrowNextClass = page === pagesCount ? 'd-none d-md-flex' : 'd-md-flex';

  return (
    <div className="pagination-links">
      {pagesCount > 1 && (
        <div className={`d-flex flex-wrap py-2 mr-3 ${disabledClass}`}>
          <p
            className={getClasses({
              disableInPage: 'first-page',
              isFirstPage,
              additional: 'd-none d-md-flex'
            })}
            onClick={() => !isFirstPage && handleFirstPage(paginationProps)}
            aria-hidden="true"
          >
            <i className="ki ki-bold-double-arrow-back icon-xs" />
          </p>

          <p
            className={getClasses({
              disableInPage: 'first-page',
              isFirstPage,
              additional: arrowBackClass
            })}
            onClick={() => !isFirstPage && handlePrevPage(paginationProps)}
            aria-hidden="true"
          >
            <i className="ki ki-bold-arrow-back icon-xs" />
          </p>

          {page > 3 && (
            <p
              className={getClasses({
                disabled: true,
                additional: 'd-none d-md-flex'
              })}
            >
              ...
            </p>
          )}
          {map(pages, (pageNumber) => (
            <p
              className={getClasses({ active: page === pageNumber })}
              key={pageNumber}
              onClick={() => handleSelectedPage(pageNumber)}
              aria-hidden="true"
            >
              {pageNumber}
            </p>
          ))}
          {page < pagesCount && pagesCount > 3 && (
            <p
              className={getClasses({
                disabled: true,
                additional: 'd-none d-md-flex'
              })}
            >
              ...
            </p>
          )}
          <p
            className={getClasses({
              disableInPage: 'last-page',
              isLastPage,
              additional: arrowNextClass
            })}
            onClick={() => !isLastPage && handleNextPage(paginationProps)}
            aria-hidden="true"
          >
            <i className="ki ki-bold-arrow-next icon-xs" />
          </p>
          <p
            className={getClasses({
              disableInPage: 'last-page',
              isLastPage,
              additional: 'd-none d-md-flex'
            })}
            onClick={() => !isLastPage && handleLastPage(paginationProps)}
            aria-hidden="true"
          >
            <i className="ki ki-bold-double-arrow-next icon-xs" />
          </p>
        </div>
      )}
    </div>
  );
}

PaginationLinks.propTypes = {
  paginationProps: PropTypes.shape({
    totalSize: PropTypes.node.isRequired,
    sizePerPage: PropTypes.node.isRequired,
    page: PropTypes.node.isRequired,
    paginationSize: PropTypes.node.isRequired,
    onPageChange: PropTypes.func.isRequired
  }).isRequired
};
