import { PACK_TYPE, PACK_TYPE_NUMBER } from 'app/utils/constants';

export const parserPack = (id, intl, lang) => {
  const pack = {
    type: getPackType(id),
    actions: generateAction(id, intl, lang)
  };
  return pack;
};

// TODO: Añadir equivalencias de packs antiguos
const getPackType = (type) => {
  switch (type) {
    case PACK_TYPE_NUMBER.WITHOUT:
      return PACK_TYPE.WITHOUT;
    case PACK_TYPE_NUMBER.FREE:
    case PACK_TYPE_NUMBER.B2B:
    case PACK_TYPE_NUMBER.BASIC:
      return PACK_TYPE.B2B;
    case PACK_TYPE_NUMBER.ECOMMERCE:
      return PACK_TYPE.ECOMMERCE;
    case PACK_TYPE_NUMBER.MARKETPLACES:
      return PACK_TYPE.MARKETPLACES;
    default:
      return PACK_TYPE.WITHOUT;
  }
};

const generateAction = (action, intl, lang) => {
  switch (action) {
    case PACK_TYPE_NUMBER.WITHOUT:
      return [
        {
          icon: 'cubes',
          text: intl.formatMessage({
            id: 'controlpanel.pack.comparation.title'
          }),
          link: `${process.env.REACT_APP_BIGBUY_URL}${lang}${intl.formatMessage(
            {
              id: 'controlpanel.url.wholesale_pack'
            }
          )}`
        },
        {
          icon: 'cube',
          text: intl.formatMessage({ id: 'controlpanel.pack.active.title' }),
          link: `${process.env.REACT_APP_BIGBUY_URL}${lang}/subscription/checkout`,
          alt: true
        }
      ];
    case PACK_TYPE_NUMBER.B2B:
    case PACK_TYPE_NUMBER.FREE:
    case PACK_TYPE_NUMBER.BASIC:
      return [
        {
          customIcon: '/media/svg/bigbuy/large/box-alt-arrow.svg',
          text: intl.formatMessage({ id: 'controlpanel.pack.impruve.title' }),
          link: `${process.env.REACT_APP_BIGBUY_URL}${lang}/subscription/checkout`
        },
        {
          icon: 'cubes',
          text: intl.formatMessage({
            id: 'controlpanel.pack.comparation.title'
          }),
          link: `${process.env.REACT_APP_BIGBUY_URL}${lang}${intl.formatMessage(
            {
              id: 'controlpanel.url.wholesale_pack'
            }
          )}`
        }
      ];
    case PACK_TYPE_NUMBER.ECOMMERCE:
      return [
        {
          customIcon: '/media/svg/bigbuy/large/box-alt-arrow.svg',
          text: intl.formatMessage({ id: 'controlpanel.pack.impruve.title' }),
          link: `${process.env.REACT_APP_BIGBUY_URL}${lang}/subscription/checkout`
        },
        {
          icon: 'cubes',
          text: intl.formatMessage({
            id: 'controlpanel.pack.comparation.title'
          }),
          link: `${process.env.REACT_APP_BIGBUY_URL}${lang}${intl.formatMessage(
            {
              id: 'controlpanel.url.wholesale_pack'
            }
          )}`
        }
      ];
    case PACK_TYPE_NUMBER.MARKETPLACES:
      return [
        {
          icon: 'cubes',
          text: intl.formatMessage({
            id: 'controlpanel.pack.comparation.title'
          }),
          link: `${process.env.REACT_APP_BIGBUY_URL}${lang}${intl.formatMessage(
            {
              id: 'controlpanel.url.wholesale_pack'
            }
          )}`
        }
      ];
    default:
      return [];
  }
};
