import React from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';
import { useIntl } from 'react-intl';
import SVG from 'react-inlinesvg';

import { getIncludeLangs } from 'app/utils/compareLanguageFlags';
import { languages } from 'app/utils/constants';
import { PRODUCT_INFO } from 'app/modules/Downloads/Pages/ProductsManuals/utils/props';
import Button from 'app/components/Button/Button';
import Popup from 'app/components/Popup/Popup';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

import 'app/modules/Downloads/Pages/ProductsManuals/components/ModalLang/ModalLang.scss';

export default function ModalLang({ show, productInfo = null }) {
  const intl = useIntl();
  const {
    context: { readContextStorage }
  } = useDataTableContext();
  const { setModalData } = readContextStorage();
  const handleClose = () => {
    setModalData({
      show: false,
      productInfo: null
    });
  };

  if (!productInfo) return null;
  return (
    <div className="modal-products-manuals">
      <Popup
        show={show}
        header={
          <div>
            <p className="bold">
              {intl.formatMessage(
                { id: 'controlpanel.products_manual.title_modal' },
                { product: productInfo?.sku }
              )}
            </p>
          </div>
        }
        body={
          <>
            <p className="modal-products-manuals__description">
              {intl.formatMessage(
                { id: 'controlpanel.products_manual.description_modal' },
                { product: productInfo?.sku }
              )}
            </p>
            <div className="modal-products-manuals__languages">
              {map(
                getIncludeLangs(languages, productInfo?.languages),
                (language) => (
                  <div
                    key={language?.id}
                    className="modal-products-manuals__languages-container"
                  >
                    <div className="modal-products-manuals__languages-container-image">
                      <SVG src={language?.flag} />
                    </div>
                    <div className="modal-products-manuals__languages-container-text">
                      <p>{language?.name}</p>
                    </div>
                  </div>
                )
              )}
            </div>
          </>
        }
        footer={
          <div className="modal-products-manuals__languages-footer">
            <Button
              text={intl.formatMessage({ id: 'controlpanel.global.close' })}
              type="default"
              size="big"
              typeButton="button"
              onClick={handleClose}
            />
            <Button
              text={intl.formatMessage({ id: 'controlpanel.global.download' })}
              type="primary"
              size="big"
              onClick={() => window.open(productInfo?.link, '_blank')}
            />
          </div>
        }
        handleClose={handleClose}
      />
    </div>
  );
}

ModalLang.propTypes = {
  show: PropTypes.bool.isRequired,
  productInfo: PropTypes.shape(PRODUCT_INFO)
};
