import PropTypes from 'prop-types';

export const PROP_TYPES = {
  title: PropTypes.string.isRequired,
  showButtons: PropTypes.bool,
  containerMaxHeight: PropTypes.number
};

export const DEFAULT_PROPS = {
  showButtons: false,
  containerMaxHeight: null
};
