import { formatDateToLocale } from 'app/utils/dates';
import { TYPE_MOVEMENT, COLOR_MOVEMENT } from 'app/utils/constants';

export const parserWalletcard = (movements, intl) => {
  const times = [];
  movements?.forEach((movement) => {
    const color =
      movement.attributes.concept === TYPE_MOVEMENT.DEPOSIT
        ? COLOR_MOVEMENT.GREEN
        : COLOR_MOVEMENT.RED;
    const title =
      movement.attributes.concept === TYPE_MOVEMENT.DEPOSIT
        ? intl.formatMessage({ id: 'controlpanel.walletcard.deposit' })
        : intl.formatMessage({ id: 'controlpanel.walletcard.transfer' });

    const dateTime = new Date(movement.attributes.date * 1000);
    const hour = `${dateTime.getHours()}:${dateTime.getMinutes()}`;
    const date = formatDateToLocale(dateTime);
    const time = {
      leftSide: {
        dateTitle: date,
        dateSubtitle: hour,
        dateColor: COLOR_MOVEMENT.GREY
      },
      rightSide: {
        eventTitle: title,
        eventSubtitle: `${movement.attributes.amount}`,
        eventColor: color
      },
      circleColor: color
    };
    times.push(time);
  });
  return {
    times: times || []
  };
};
