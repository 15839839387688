import React, { useMemo, useLayoutEffect } from 'react';
import Proptypes from 'prop-types';

import { useSubheader } from 'app/context/SubheaderContext';
import { useIntl } from 'react-intl';

import prestashopData from 'app/modules/Shops/pages/Prestashop/config';

import 'app/modules/Shops/pages/Prestashop/PrestashopForm.scss';

export default function PrestashopForm({
  shop = {
    shopId: '',
    sshHost: '',
    sshUser: '',
    sshPort: '',
    shopUrl: '',
    shopUser: '',
    shopPassword: '',
    blogUrl: '',
    blogUser: '',
    blogPassword: '',
    emailUrl: '',
    emailUser: '',
    emailPassword: '',
    databaseUser: '',
    databasePassword: '',
    blogDatabaseUser: '',
    blogDatabasePassword: ''
  }
}) {
  const intl = useIntl();
  const psData = prestashopData(shop);
  const { shopId } = shop;
  const { setTitle, setIcon, setBreadcrumbs } = useSubheader();

  const shopBreadcrumbTitle = useMemo(
    () =>
      `${intl.formatMessage({
        id: 'controlpanel.breadcrumbs.shop'
      })}: ${shopId}`,
    [shopId, intl]
  );

  useLayoutEffect(() => {
    setTitle(shopBreadcrumbTitle);
    setIcon({ icon: 'store' });
    setBreadcrumbs([
      {
        pathname: '/shops',
        title: intl.formatMessage({ id: 'controlpanel.breadcrumbs.shops' })
      },
      {
        pathname: window.location.pathname,
        title: shopBreadcrumbTitle
      }
    ]);
  }, [intl, setBreadcrumbs, setIcon, setTitle, shopBreadcrumbTitle]);

  return (
    <div className="container prestashop-form">
      <h4 className="prestashop-form__title bold">
        {intl.formatMessage({
          id: 'controlpanel.prestashop_form.label.title'
        })}
      </h4>
      <div className="prestashop-form__container">
        {psData.map((item, index) => {
          const { sectionTitle, data } = item;
          return (
            <div className="prestashop-form__item" key={`col-${index}`}>
              <p className="prestashop-form__title bold">
                {intl.formatMessage({ id: sectionTitle })}
              </p>
              {data.map((itemData) => {
                const {
                  title,
                  input: { text }
                } = itemData;
                return (
                  <div className="prestashop-form__content">
                    <p className="prestashop-form__content__title bold">
                      {intl.formatMessage({ id: title })}
                    </p>
                    <p className="prestashop-form__content__text">{text}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

PrestashopForm.propTypes = {
  shop: Proptypes.shape({
    shopId: Proptypes.string,
    sshHost: Proptypes.string,
    sshUser: Proptypes.string,
    sshPort: Proptypes.string,
    shopUrl: Proptypes.string,
    shopUser: Proptypes.string,
    shopPassword: Proptypes.string,
    blogUrl: Proptypes.string,
    blogUser: Proptypes.string,
    blogPassword: Proptypes.string,
    emailUrl: Proptypes.string,
    emailUser: Proptypes.string,
    emailPassword: Proptypes.string,
    databaseUser: Proptypes.string,
    databasePassword: Proptypes.string,
    blogDatabaseUser: Proptypes.string,
    blogDatabasePassword: Proptypes.string
  })
};
