import React from 'react';
import PropTypes from 'prop-types';

import { map, size } from 'lodash';

import NoResultsFound from 'app/components/NoResultsFound';
import Spinner from 'app/components/Spinner';

import 'app/components/DataTableComponents/DataTableComponents.scss';

export default function DataTableComponents({
  loading = false,
  pagination = null,
  components
}) {
  if (loading === true) {
    return <Spinner />;
  }

  return (
    <div className="data-table-components">
      {size(components) > 0 ? (
        <div className="data-table-components__container">
          {map(components, (component) => component)}
          {pagination}
        </div>
      ) : (
        <NoResultsFound />
      )}
    </div>
  );
}

DataTableComponents.propTypes = {
  components: PropTypes.arrayOf(PropTypes.node).isRequired,
  loading: PropTypes.bool,
  pagination: PropTypes.node
};
