import React from 'react';

import classNames from 'classnames';

import { BLUE_ALERT, RED_ALERT } from 'app/modules/Orders/utils';

export const formatGeneric = ({ text, intl, status }) => {
  const classes = classNames('bb-orders__data-table__cell small', {
    red: RED_ALERT.includes(status),
    blue: BLUE_ALERT.includes(status)
  });

  return (
    <p className={classes}>{intl ? intl.formatMessage({ id: text }) : text}</p>
  );
};
