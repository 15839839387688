import { map } from 'lodash';

export const setNotificationsRead = (notifications, notificationId = null) => {
  const newNotifications = [];
  map(notifications, (notification) => {
    newNotifications.push({
      ...notification,
      attributes: {
        ...notification?.attributes,
        read:
          !notificationId || notificationId === notification?.id
            ? true
            : notification?.attributes?.read
      }
    });
  });

  return newNotifications;
};
