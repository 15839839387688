/* eslint-disable react/prop-types */
/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PostHogProvider } from 'posthog-js/react';
import TagManager from 'react-gtm-module';

import { AlertFromContext } from 'app/components/Alert';
import { FeedbackProvider } from 'app/context/FeedbackContext';
import { I18nProvider } from 'app/context/i18n';
import { LayoutSplashScreen } from 'app/layout/core/MetronicSplashScreen';
import { NotificationsProvider } from 'app/context/NotificationsServiceContext';
import { Routes } from 'app/config/Routes';
import AuthInit from 'app/modules/Auth/_redux/AuthInit';
import { TAG_MANAGER_ID } from 'app/utils/constants';
import posthog from 'posthog-js';
import Didomi from './components/Didomi';

export default function App({ store, basename }) {
  const tagManagerArgs = {
    gtmId: TAG_MANAGER_ID
  };

  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only'
  });

  TagManager.initialize(tagManagerArgs);

  return (
    /* Provide Redux store */
    <Provider store={store}>
      <NotificationsProvider>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              {/* Allows the App to handle feedback through a context */}
              <FeedbackProvider>
                {/* Render routes with provided `Layout`. */}
                <AuthInit>
                  <PostHogProvider client={posthog}>
                    <Didomi />
                    <Routes />
                    <AlertFromContext />
                  </PostHogProvider>
                </AuthInit>
              </FeedbackProvider>
            </I18nProvider>
          </BrowserRouter>
        </React.Suspense>
      </NotificationsProvider>
    </Provider>
  );
}
