import React from 'react';
import PropTypes from 'prop-types';

export const getSelectFilter = ({ selectOptions, filter, renderedFilters }) => (
  <select
    defaultValue={filter}
    className="form-control selector-filter bb-select-html"
    id="selectFilter"
    data-prev-value={filter}
  >
    {selectOptions.map(({ value, label }) => (
      <option
        key={value}
        value={value}
        disabled={value === 'all' || renderedFilters.includes(value)}
      >
        {label}
      </option>
    ))}
  </select>
);

export const SelectDisable = ({ text }) => (
  <select className="form-control bb-select-html" disabled>
    <option>{text}</option>
  </select>
);

SelectDisable.propTypes = {
  text: PropTypes.string.isRequired
};
