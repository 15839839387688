import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'react-bootstrap';

import Icon from 'app/components/Icon';

import 'app/components/NoResultsFound/NoResultsFound.scss';

export default function NoResultsFound({
  icon = 'close',
  color = '#DDDFE5',
  prefix = 'fad',
  size = '6x',
  text = null,
  ...rest
}) {
  return (
    <Container className="no-results-found">
      <Row>
        <Col md={12}>
          <Icon
            icon={icon}
            color={color}
            prefix={prefix}
            size={size}
            {...rest}
          />
        </Col>
        <Col md={12} className="no-results-found__text">
          <p className="big">
            {text || (
              <FormattedMessage id="controlpanel.global.no_results_founds" />
            )}
          </p>
        </Col>
      </Row>
    </Container>
  );
}

NoResultsFound.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  prefix: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string
};
