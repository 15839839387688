import React from 'react';

import { map, size } from 'lodash';

import useDataTableComponentsContext from 'app/components/DataTableComponents/hooks/useDataTableComponentsContext';
import { getPages, getPagesCount } from 'app/utils/TablePaginationHelpers';
import { getClasses } from './utils';

import 'app/components/Pagination/PaginationLinks/PaginationLinks.scss';

export function PaginationLinks() {
  const { context, setContext } = useDataTableComponentsContext();
  if (size(context?.api?.meta) === 0) {
    return null;
  }

  const {
    meta: { count },
    options: { page, sizePerPage, paginationSize }
  } = context.api;

  const onPageChange = (newPage) => {
    setContext((prevState) => {
      const newState = { ...prevState };
      newState.api.options.page = newPage;
      return {
        ...newState
      };
    });
  };

  const pagesCount = getPagesCount(count, sizePerPage);

  const pages = getPages(page, pagesCount, paginationSize);

  const isFirstPage = page === 1;
  const isLastPage = page === pagesCount;

  const handleFirstPage = () => {
    onPageChange(1);
  };

  const handlePrevPage = () => {
    onPageChange(page - 1);
  };

  const handleNextPage = () => {
    if (page < pagesCount) {
      onPageChange(page + 1);
    }
  };

  const handleLastPage = () => {
    onPageChange(pagesCount);
  };

  const handleSelectedPage = (pageNum) => {
    onPageChange(pageNum);
  };

  const disabledClass = pagesCount > 1 ? '' : 'disabled';
  const arrowBackClass = page === 1 ? 'd-none d-md-flex' : 'd-md-flex';
  const arrowNextClass = page === pagesCount ? 'd-none d-md-flex' : 'd-md-flex';

  return (
    <div className="pagination-links">
      {pagesCount > 1 && (
        <div className={`d-flex flex-wrap py-2 mr-3 ${disabledClass}`}>
          <p
            className={getClasses({
              disableInPage: 'first-page',
              isFirstPage,
              additional: 'd-none d-md-flex'
            })}
            onClick={() => !isFirstPage && handleFirstPage()}
            aria-hidden="true"
          >
            <i className="ki ki-bold-double-arrow-back icon-xs" />
          </p>

          <p
            className={getClasses({
              disableInPage: 'first-page',
              isFirstPage,
              additional: arrowBackClass
            })}
            onClick={() => !isFirstPage && handlePrevPage()}
            aria-hidden="true"
          >
            <i className="ki ki-bold-arrow-back icon-xs" />
          </p>

          {page > 3 && (
            <p
              className={getClasses({
                disabled: true,
                additional: 'd-none d-md-flex'
              })}
            >
              ...
            </p>
          )}
          {map(pages, (pageNumber) => (
            <p
              className={getClasses({ active: page === pageNumber })}
              key={pageNumber}
              onClick={() => handleSelectedPage(pageNumber)}
              aria-hidden="true"
            >
              {pageNumber}
            </p>
          ))}
          {page < pagesCount && pagesCount > 3 && (
            <p
              className={getClasses({
                disabled: true,
                additional: 'd-none d-md-flex'
              })}
            >
              ...
            </p>
          )}
          <p
            className={getClasses({
              disableInPage: 'last-page',
              isLastPage,
              additional: arrowNextClass
            })}
            onClick={() => !isLastPage && handleNextPage()}
            aria-hidden="true"
          >
            <i className="ki ki-bold-arrow-next icon-xs" />
          </p>
          <p
            className={getClasses({
              disableInPage: 'last-page',
              isLastPage,
              additional: 'd-none d-md-flex'
            })}
            onClick={() => !isLastPage && handleLastPage()}
            aria-hidden="true"
          >
            <i className="ki ki-bold-double-arrow-next icon-xs" />
          </p>
        </div>
      )}
    </div>
  );
}
