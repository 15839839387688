import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { Colors } from 'app/utils/colors';
import { patchRmaLine as patchRmaLineService } from 'app/modules/Devolutions/services';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import useRMAContext from 'app/modules/Devolutions/context/context';

const EditAction = ({ id, enabled }) => {
  const intl = useIntl();
  const formik = useFormikContext();
  const { isEdittingId, setIsEdittingId, getRmaProducts } = useRMAContext();

  const isEditting = useMemo(() => isEdittingId === id, [isEdittingId, id]);

  const editIcon = useMemo(() => (isEditting ? 'check' : 'edit'), [isEditting]);

  const editText = useMemo(
    () =>
      isEditting ? 'controlpanel.global.save' : 'controlpanel.global.edit_data',
    [isEditting]
  );

  const handleSetEditMode = () => {
    setIsEdittingId(id);
  };

  const handleSaveLine = async () => {
    const { lineDescription: returnReason, lineUnits: quantity } =
      formik.values;

    await patchRmaLineService({
      id,
      data: {
        type: 'rma-line',
        attributes: {
          returnReason,
          quantity
        }
      }
    });

    await getRmaProducts();
    setIsEdittingId(null);
  };

  return (
    <Button
      icon={<Icon icon={editIcon} color={Colors.bbSecondary} />}
      size="medium"
      tooltipText={intl.formatMessage({
        id: editText
      })}
      onClick={isEditting ? handleSaveLine : handleSetEditMode}
      disabled={!enabled}
    />
  );
};

EditAction.propTypes = {
  id: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired
};

export default EditAction;
