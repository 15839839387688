import React from 'react';
import PropTypes from 'prop-types';

import { FieldArray } from 'formik';
import { Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import Input from 'app/components/Input';

import Divider from 'app/components/Divider/Divider';

export default function PackagesList({ formik, disabled }) {
  const intl = useIntl();
  return (
    <FieldArray
      name="packages"
      render={() => (
        <div>
          {formik.values.packages?.map((dimension, index) => (
            <div className="collection-address-form__directions" key={index}>
              <Col md={{ span: 8, offset: 2 }} xs={12}>
                <h6 className="direction-title">
                  {formik.values.packageType === 'pallets'
                    ? `${intl.formatMessage({
                        id: 'controlpanel.global.pallets'
                      })} ${index + 1}`
                    : `${intl.formatMessage({
                        id: 'controlpanel.global.bulks'
                      })} ${index + 1} `}
                </h6>
                <Divider />
              </Col>
              <Col md={{ span: 4, offset: 2 }}>
                <Input
                  name={`packages.${index}.height`}
                  type="number"
                  placeholder={
                    disabled === true
                      ? `${dimension.height}`
                      : `${intl.formatMessage({
                          id: 'controlpanel.global.height'
                        })} cm`
                  }
                  label={intl.formatMessage({
                    id: 'controlpanel.global.height'
                  })}
                  disabled={disabled}
                />
              </Col>
              <Col md={{ span: 4 }} xs={12}>
                <Input
                  name={`packages.${index}.width`}
                  type="number"
                  placeholder={
                    disabled === true
                      ? `${dimension.width}`
                      : `${intl.formatMessage({
                          id: 'controlpanel.global.width'
                        })} cm`
                  }
                  label={intl.formatMessage({
                    id: 'controlpanel.global.width'
                  })}
                  disabled={disabled}
                />
              </Col>
              <Col md={{ span: 4, offset: 2 }}>
                <Input
                  name={`packages.${index}.depth`}
                  type="number"
                  placeholder={
                    disabled === true
                      ? `${dimension.depth}`
                      : `${intl.formatMessage({
                          id: 'controlpanel.global.depth'
                        })} cm`
                  }
                  label={intl.formatMessage({
                    id: 'controlpanel.global.depth'
                  })}
                  disabled={disabled}
                />
              </Col>
              <Col md={{ span: 4 }} xs={12}>
                <Input
                  name={`packages.${index}.weight`}
                  type="number"
                  placeholder={
                    disabled === true
                      ? `${dimension.weight}`
                      : intl.formatMessage({
                          id: 'controlpanel.global.estimated_weight'
                        })
                  }
                  label={intl.formatMessage({
                    id: 'controlpanel.global.estimated_weight'
                  })}
                  disabled={disabled}
                />
              </Col>
            </div>
          ))}
        </div>
      )}
    />
  );
}

PackagesList.propTypes = {
  packageType: PropTypes.string.isRequired,
  formik: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired
};
