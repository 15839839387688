import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';

import {
  getSolutionsOptions,
  getShopOnlineOptions,
  getContactOptions,
  getContactPreferencesOptions
} from 'app/modules/MyAccount/pages/Information/InformationForm/FormConfig';
import { Select, getSelectDefaultValues } from 'app/components/Select';
import Checkbox from 'app/components/Checkbox';
import Divider from 'app/components/Divider';
import Input from 'app/components/Input';
import Textarea from 'app/components/Textarea';

import 'app/modules/MyAccount/pages/Information/InformationForm/InformationForm.scss';

export default function InformationForm({
  showSelect,
  showInput,
  parentContactPreferences = 0
}) {
  const intl = useIntl();
  const formik = useFormikContext();

  const solutionsOptions = getSolutionsOptions();
  const shopOnlineOptions = getShopOnlineOptions();
  const contactOptions = getContactOptions();
  const contactPreferencesOptions = getContactPreferencesOptions(
    parentContactPreferences
  );
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (!firstLoad) {
      formik.setFieldTouched('contactPreferences', true);
      formik.setFieldValue('contactPreferences', '', true);
      changeSelectLabel('controlpanel.about_you.select.title');
    }

    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentContactPreferences]);

  const changeSelectLabel = (key) => {
    const select = document.getElementsByClassName('css-qc6sy-singleValue');
    if (select.length > 0) {
      select[0].innerHTML = intl.formatMessage({
        id: key
      });
    }
  };

  return (
    <div className="bb-user-additional-panel">
      <Row className="bb-user-additional-panel__header">
        <Col md={3} />
        <Col md={7} className="bb-user-additional-panel__header__row">
          <p className="medium big">
            {intl.formatMessage({ id: 'controlpanel.about_you.header' })}
          </p>
        </Col>
        <Col md={2} />
      </Row>

      <Row className="bb-user-additional-panel__company">
        <Col md={3} />
        <Col md={7} className="bb-user-additional-panel__company__column">
          <p>
            {intl.formatMessage({ id: 'controlpanel.about_you.description' })}
          </p>
          <Textarea
            name="additionalInfo"
            placeholder={intl.formatMessage({
              id: 'controlpanel.about_you.textarea.placeholder'
            })}
          />
        </Col>
        <Col md={2} />
      </Row>

      <Divider type="dash" />

      <Row className="bb-user-additional-panel__header">
        <Col md={3} />
        <Col md={7} className="bb-user-additional-panel__header__row">
          <p className="medium big">
            {intl.formatMessage({ id: 'controlpanel.about_you.sell.header' })}
          </p>
        </Col>
        <Col md={2} />
      </Row>

      <Row className="bb-user-additional-panel__sell-channel">
        <Col md={3} className="bb-user-additional-panel__sell-channel__title">
          <p>
            {intl.formatMessage({
              id: 'controlpanel.about_you.sell.title.channel.sell'
            })}
          </p>
        </Col>
        <Col
          md={7}
          className="bb-user-additional-panel__sell-channel__checkboxes"
        >
          {solutionsOptions &&
            solutionsOptions.map((checkbox, index) => (
              <Checkbox
                key={`${index}-${checkbox.id}`}
                id={checkbox.id}
                name={checkbox.name}
                type="checkbox"
                label={checkbox.label}
                value={checkbox.value}
                multiple={checkbox.multiple}
                showError={checkbox.showError}
              />
            ))}
        </Col>
        <Col md={2} />
      </Row>

      <Row className="bb-user-additional-panel__online-shop">
        <Col md={3} className="bb-user-additional-panel__online-shop__title">
          <p>
            {intl.formatMessage({
              id: 'controlpanel.about_you.sell.title.shop.online'
            })}
          </p>
        </Col>
        <Col
          md={7}
          className="bb-user-additional-panel__online-shop__radiobuttons"
        >
          {shopOnlineOptions &&
            shopOnlineOptions.map((radio, index) => (
              <Checkbox
                key={`${index}-${radio.id}`}
                id={radio.id}
                name={radio.name}
                type="radio"
                label={radio.label}
                value={radio.value}
                multiple={radio.multiple}
                showError={radio.showError}
              />
            ))}
        </Col>
        <Col md={2} />
      </Row>

      {showInput && (
        <Row className="bb-user-additional-panel__input-web">
          <Col md={3} />
          <Col md={7} className="bb-user-additional-panel__input-web__input">
            <Input
              name="onlineShopURL"
              textPrepend="@"
              placeholder={intl.formatMessage({
                id: 'controlpanel.about_you.input.placeholder'
              })}
            />
          </Col>
          <Col md={2} />
        </Row>
      )}

      <Divider type="dash" className="bb-user-additional-panel__divider" />

      <Row className="bb-user-additional-panel__header">
        <Col md={3} />
        <Col md={7} className="bb-user-additional-panel__header__row">
          <p className="medium big">
            {intl.formatMessage({
              id: 'controlpanel.about_you.contact.header'
            })}
          </p>
        </Col>
        <Col md={2} />
      </Row>

      <Row className="bb-user-additional-panel__contact">
        <Col md={3} className="bb-user-additional-panel__contact__title">
          <p>
            {intl.formatMessage({ id: 'controlpanel.about_you.contact.title' })}
          </p>
        </Col>
        <Col md={7} className="bb-user-additional-panel__contact__radiobuttons">
          {contactOptions &&
            contactOptions.map((contact, index) => (
              <Checkbox
                key={`${index}-${contact.id}`}
                id={contact.id}
                name={contact.name}
                type="radio"
                label={contact.label}
                value={contact.value}
                multiple={contact.multiple}
                showError={contact.showError}
              />
            ))}
        </Col>
        <Col md={2} />
      </Row>

      {showSelect && (
        <Row className="bb-user-additional-panel__select">
          <Col md={3} />
          <Col md={7}>
            <Select
              id="contactPreferences"
              name="contactPreferences"
              options={contactPreferencesOptions}
              searchable
              defaultValue={getSelectDefaultValues(contactPreferencesOptions, [
                formik.initialValues.contactPreferences
              ])}
              placeholder={intl.formatMessage({
                id: 'controlpanel.about_you.select.title'
              })}
              onChange={(e) => changeSelectLabel(e.label.props.id)}
            />
          </Col>
          <Col md={2} />
        </Row>
      )}
    </div>
  );
}

InformationForm.propTypes = {
  showSelect: PropTypes.bool.isRequired,
  showInput: PropTypes.bool.isRequired,
  parentContactPreferences: PropTypes.string
};
