import React, { useEffect, useState } from 'react';

import { IntlProvider } from 'react-intl';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

// eslint-disable-next-line react/prop-types
export function I18nProvider({ children }) {
  const { user } = useSelector((state) => state.auth);
  const locale = user?.attributes?.locale || 'en';
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const messages = await import(`./messages/${locale}.json`);
        setTranslations(messages);
      } catch (error) {
        const messages = await import('./messages/default.json');
        setTranslations(messages);
      }
    })();
  }, [locale]);

  if (isEmpty(translations)) {
    return null;
  }

  return (
    <IntlProvider locale={locale} messages={translations}>
      {children}
    </IntlProvider>
  );
}
