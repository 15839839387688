import React from 'react';

import { formatCurrency } from 'app/utils/currency';
import ActionsFormatter from 'app/modules/Devolutions/components/TableProducts/formatters/ProductFormatter/ActionsFormatter';
import AlertFormatter from 'app/modules/Devolutions/components/TableProducts/formatters/AlertFormatter';
import ChatMessagesList from 'app/modules/Devolutions/components/ChatMessage/components/ChatMessagesList';
import EditReason from 'app/modules/Devolutions/components/TableProducts/formatters/ProductFormatter/EditReason';
import EditUnits from 'app/modules/Devolutions/components/TableProducts/formatters/ProductFormatter/EditUnits';
import OpenArrowFormatter from 'app/modules/Devolutions/components/TableProducts/formatters/OpenArrowFormatter';
import TotalRefund from 'app/modules/Devolutions/components/TableProducts/formatters/TotalRefund';
import ProductFormatter from 'app/modules/Devolutions/components/TableProducts/formatters/ProductFormatter';
import StatusFormatter from 'app/modules/Devolutions/components/TableProducts/formatters/StatusFormatter';
import { DateFormatter } from 'app/utils/columnsFormatters/DateFormatter';

export const getDataTableStructure = (data) =>
  data.map(
    ({
      id,
      attributes: {
        sku,
        orderId,
        orderDate,
        rmaId,
        returnReason,
        quantity,
        price,
        image,
        productDescription,
        status = 'DEFAULT',
        eligibleQuantity,
        discountedPercentage,
        totalPaid,
        reductionReason
      },
      included
    }) => ({
      id,
      product: <ProductFormatter image={image} title={productDescription} />,
      sku,
      orderId,
      orderDate: <DateFormatter date={orderDate} />,
      returnReason: <EditReason returnReason={returnReason} id={id} />,
      quantity: (
        <EditUnits
          quantity={quantity}
          id={id}
          eligibleQuantity={eligibleQuantity}
        />
      ),
      price: formatCurrency(price),
      statusCol: <StatusFormatter status={status} />,
      actions: (
        <ActionsFormatter
          id={id}
          sku={sku}
          orderId={orderId}
          status={status}
          rmaId={rmaId}
          quantity={quantity}
          eligibleQuantity={eligibleQuantity}
          returnReason={returnReason}
          hasMessages={Boolean(included?.length)}
          productDescription={productDescription}
        />
      ),
      included,
      status,
      totalReturned: (
        <TotalRefund
          total={totalPaid}
          percentage={discountedPercentage > 0 ? discountedPercentage : null}
          comment={reductionReason}
        />
      )
    })
  );

export const getExpandRow = ({ entities, expanded, rmaStatus }) => {
  const statusWhitChatExpandables = ['DRAFT'];

  const nonExpandable = [];
  entities.forEach(({ included, id }) => {
    if (!included?.length) nonExpandable.push(id);
  });

  return {
    renderer: ({ included, id }) => (
      <ChatMessagesList messages={included} rmaLineId={id} />
    ),
    nonExpandable,
    expanded,
    showExpandColumn: true,
    expandHeaderColumnRenderer: () => null,
    // eslint-disable-next-line no-shadow
    expandColumnRenderer: ({ expandable, expanded, rowKey }) => {
      const rowData = entities.filter((e) => e.id === rowKey);
      if (!statusWhitChatExpandables.includes(rmaStatus)) {
        return null;
      }
      if (!expandable) return null;
      return (
        <div className="devolutions__alert-actions">
          <OpenArrowFormatter expanded={expanded} />
          {rowData?.[0]?.status === 'INCIDENCE' && <AlertFormatter />}
        </div>
      );
    },
    expandByColumnOnly: true
  };
};
