import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import useRMAContext from 'app/modules/Devolutions/context/context';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';

const ExpandRow = ({ id }) => {
  const intl = useIntl();
  const { handleExpandRow } = useRMAContext();

  return (
    <Button
      icon={<Icon icon="comments" color={Colors.bbSecondary} />}
      size="medium"
      tooltipText={intl.formatMessage({
        id: 'controlpanel.global.messages'
      })}
      onClick={() => handleExpandRow(id)}
    />
  );
};

ExpandRow.propTypes = {
  id: PropTypes.string.isRequired
};

export default ExpandRow;
