import React from 'react';
import PropTypes from 'prop-types';

import Badge from 'app/components/Badge';
import useOrdersStatus from 'app/hooks/useOrdersStatus.hook';

export const StatusFormatter = ({ status = null }) => {
  const ordersStatus = useOrdersStatus();

  if (!status) {
    return null;
  }

  return (
    <Badge
      className="bb-badge"
      color={ordersStatus[status].badgeColor || null}
      text={ordersStatus[status].translation}
    />
  );
};

StatusFormatter.propTypes = {
  status: PropTypes.string
};
