import React, { useEffect, useState } from 'react';

import { map, size } from 'lodash';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectedOnClick } from 'app/components/DataTableComponents/utils';
import { wrongPackContent } from 'app/components/ModalWrongPack';
import { DOWNLOADS_RESOURCES_PERMITED } from 'app/modules/Downloads/utils';
import Card from 'app/components/Card/Card';
import DataTableComponents from 'app/components/DataTableComponents';
import Filters from 'app/modules/Downloads/components/Filters';
import Pagination from 'app/components/DataTableComponents/components/Pagination/Pagination';
import SocialSelling from 'app/modules/Downloads/components/SocialSelling';
import useApi from 'app/components/DataTableComponents/hooks/useApi';
import useDataTableComponentsContext from 'app/components/DataTableComponents/hooks/useDataTableComponentsContext';

import 'app/modules/Downloads/Pages/SocialSellingResources/SocialSellingResources.scss';

export default function SocialSellingResources() {
  useApi();
  const intl = useIntl();
  const { context, setContext } = useDataTableComponentsContext();
  const [components, setComponents] = useState([]);

  const {
    user: {
      attributes: { packId }
    }
  } = useSelector((state) => state.auth);

  const downloadPermited = DOWNLOADS_RESOURCES_PERMITED.includes(packId);

  useEffect(() => {
    if (size(context?.api?.data) > 0) {
      const newComponents = [];

      map(context?.api?.data, (data) => {
        newComponents.push(
          <SocialSelling
            key={data?.id}
            image={data?.attributes?.image}
            description={data?.attributes?.name}
            onClick={() =>
              selectedOnClick(data?.id, 'social-selling', context, setContext)
            }
            initialSelected={context?.selected?.includes(data?.id)}
          />
        );
      });
      setComponents(newComponents);
    } else {
      setComponents([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.api?.data, context?.selected]);

  if (!downloadPermited) {
    return (
      <Card
        title={intl.formatMessage({
          id: 'controlpanel.downloads.mkt_resources'
        })}
        body={
          <div className="wrong-pack" style={{ marginTop: '15px' }}>
            <div className="wrong-pack__body">{wrongPackContent(intl)}</div>
          </div>
        }
      />
    );
  }

  return (
    <Card
      title={intl.formatMessage({
        id: 'controlpanel.downloads.social_selling_resources'
      })}
      body={
        <div className="social-selling-resources">
          <Filters showSearch />
          <DataTableComponents
            components={components}
            loading={context?.api?.loading}
            pagination={<Pagination />}
          />
        </div>
      }
    />
  );
}
