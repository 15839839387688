import * as Yup from 'yup';

import { SUPPORTED_FORMATS, KB } from 'app/modules/Shops/pages/Shopify/utils';
import { base64toFile } from 'app/utils/file-conversor64';

export const getValidationSchema = (intl, disabledLogoAndFavicon) => {
  let imageValidation = Yup.mixed()
    .test(
      'fileSize',
      intl.formatMessage({
        id: 'controlpanel.form.validation.file-size'
      }),
      async (value) => {
        const file = await base64toFile(value);
        if (!file.name || file?.size / KB >= 20) {
          return false;
        }

        return true;
      }
    )
    .test(
      'fileFormat',
      intl.formatMessage({
        id: 'controlpanel.form.validation.file-format'
      }),
      async (value) => {
        const file = await base64toFile(value);
        if (!file || SUPPORTED_FORMATS.includes(file?.type)) {
          return false;
        }

        return true;
      }
    )
    .required();

  if (disabledLogoAndFavicon) {
    imageValidation = imageValidation.nullable();
  }

  return Yup.object({
    shopName: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'controlpanel.form.validation.required'
        })
      ),
    domain: Yup.string().nullable(),
    mainLanguage: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'controlpanel.form.validation.required'
        })
      ),
    languages: Yup.array()
      .nullable()
      .max(
        2,
        intl.formatMessage(
          {
            id: 'controlpanel.form.validation.max_x_languages'
          },
          {
            length: 2
          }
        )
      ),
    currency: Yup.array()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'controlpanel.form.validation.required'
        })
      )
      .max(
        3,
        intl.formatMessage({
          id: 'controlpanel.form.validation.max_3_currencies'
        })
      ),
    email: Yup.string()
      .nullable()
      .email(intl.formatMessage({ id: 'controlpanel.form.validation.email' }))
      .required(
        intl.formatMessage({
          id: 'controlpanel.form.validation.required'
        })
      ),
    confirmEmail: Yup.string()
      .nullable()
      .email(intl.formatMessage({ id: 'controlpanel.form.validation.email' }))
      .oneOf(
        [Yup.ref('email')],
        intl.formatMessage({
          id: 'controlpanel.form.validation.email_not_equal'
        })
      )
      .required(
        intl.formatMessage({
          id: 'controlpanel.form.validation.required'
        })
      ),
    country: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'controlpanel.form.validation.required'
        })
      ),
    province: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'controlpanel.form.validation.required'
        })
      ),
    address: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'controlpanel.form.validation.required'
        })
      ),
    city: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'controlpanel.form.validation.required'
        })
      ),
    zipCode: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'controlpanel.form.validation.required'
        })
      ),
    logo: imageValidation,
    favicon: imageValidation
  });
};
