import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { map, size } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useIntl, FormattedMessage } from 'react-intl';

import { BasicDataTable } from 'app/components/DataTable';
import { Colors } from 'app/utils/colors';
import { DateFormatter } from 'app/utils/columnsFormatters/DateFormatter';
import { formatCurrency } from 'app/utils/currency';
import { sortCaret } from 'app/components/DataTable/utils';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import Popup from 'app/components/Popup';

export default function GetColDataTable() {
  const intl = useIntl();

  return [
    {
      dataField: 'icon',
      hidden: false,
      sort: false,
      text: ''
    },
    {
      dataField: 'internalReference',
      text: intl.formatMessage({
        id: 'controlpanel.global.internal_reference'
      }),
      sort: true,
      hidden: false,
      sortCaret
    },
    {
      dataField: 'dateImport',
      text: intl.formatMessage({ id: 'controlpanel.global.import_date' }),
      sort: true,
      hidden: false,
      sortCaret
    },
    {
      dataField: 'receiver',
      text: intl.formatMessage({ id: 'controlpanel.global.receiver' }),
      sort: true,
      hidden: false,
      sortCaret
    },
    {
      dataField: 'carrier',
      text: intl.formatMessage({ id: 'controlpanel.global.carrier' }),
      sort: true,
      hidden: false,
      sortCaret
    },
    {
      dataField: 'descriptionError',
      headerClasses: 'column-description',
      text: intl.formatMessage({ id: 'controlpanel.global.information' }),
      sort: true,
      hidden: false,
      sortCaret
    },
    {
      dataField: 'orderId',
      text: intl.formatMessage({ id: 'controlpanel.global.order_number' }),
      sort: true,
      hidden: false,
      sortCaret
    },
    {
      dataField: 'total',
      text: intl.formatMessage({ id: 'controlpanel.global.amount' }),
      sort: true,
      hidden: false,
      sortCaret
    },

    {
      dataField: 'actions',
      text: intl.formatMessage({ id: 'controlpanel.global.table_actions' }),
      sort: false,
      hidden: false
    }
  ];
}

export const getTableEstructure = (entities) => {
  const filteredEntities = entities.map((entity) => {
    if (entity) return entity;
    return {
      internalReference: 'Server error',
      dateImport: '',
      receiver: '',
      carrier: '',
      orderId: '',
      total: '',
      descriptionError: '',
      error: true
    };
  });

  return filteredEntities.map(
    ({
      internalReference = '',
      dateImport = '',
      receiver = '',
      carrier = '',
      orderId = '',
      total = '',
      descriptionError = '',
      error = false
    }) => ({
      icon: <IconFormatter error={error} />,
      internalReference,
      dateImport: <DateFormatter date={dateImport} />,
      receiver,
      carrier,
      orderId,
      total: total ? formatCurrency(total) : null,
      descriptionError: (
        <ErrorInfoFormatter
          error={error}
          descriptionError={descriptionError?.message}
        />
      ),
      error,
      actions: (
        <ActionsFormatter
          error={error}
          orderId={orderId}
          alternatives={descriptionError?.alternatives}
        />
      )
    })
  );
};

const IconFormatter = ({ error = null }) => {
  if (!error) return '';
  return <Icon icon="exclamation-triangle" color={Colors.bbRed} prefix="fad" />;
};

const ErrorInfoFormatter = ({ descriptionError = '', error = null }) => {
  if (!error || !descriptionError) return null;
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          {descriptionError || (
            <FormattedMessage id="controlpanel.global.undefined_error" />
          )}
        </Tooltip>
      }
    >
      <div className="error-information-tooltip">
        <FormattedMessage id="controlpanel.global.undefined_error" />
        <Icon size="1x" icon="square-info" color="red" />
      </div>
    </OverlayTrigger>
  );
};

const ActionsFormatter = ({
  orderId = null,
  error = null,
  alternatives = {}
}) => {
  const intl = useIntl();
  const [showPopup, setShowPopup] = useState(false);
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="">
      {!error && (
        <Button
          icon={<Icon icon="eye" color={Colors.bbSecondary} />}
          onClick={() =>
            window.open(`/orders/view/${orderId}`, '_blank').focus()
          }
          size="medium"
          tooltipText={intl.formatMessage({
            id: 'controlpanel.global.view_associated_orders'
          })}
        />
      )}

      {size(alternatives) > 0 && (
        <>
          <Popup
            show={showPopup}
            body={
              <div>
                <BasicDataTable
                  id="alternatives"
                  keyField="reference"
                  columns={[
                    {
                      dataField: 'reference',
                      text: intl.formatMessage({
                        id: 'controlpanel.global.reference'
                      })
                    },
                    {
                      dataField: 'referenceAlternative',
                      text: intl.formatMessage({
                        id: 'controlpanel.global.alternative'
                      })
                    },
                    {
                      dataField: 'stock',
                      text: intl.formatMessage({
                        id: 'controlpanel.global.stock'
                      })
                    },
                    {
                      dataField: 'price',
                      text: intl.formatMessage({
                        id: 'controlpanel.api.order.print.price'
                      })
                    },
                    {
                      dataField: 'condition',
                      text: intl.formatMessage({
                        id: 'controlpanel.global.filter.status'
                      })
                    }
                  ]}
                  entities={getAlternativesData(alternatives)}
                />
              </div>
            }
            handleClose={handleClosePopup}
            header={<p className="bold big">Ver alternativas</p>}
          />
          <button
            type="button"
            className="bb-btn bb-btn__icon bb-btn__default bb-btn--medium btn btn-primary"
            size="medium"
            onClick={() => {
              setShowPopup(true);
            }}
            style={{
              'white-space': 'nowrap'
            }}
          >
            Ver alternativas
          </button>
        </>
      )}
    </div>
  );
};

const getAlternativesData = (values) => {
  const data = [];
  map(values, (value) => {
    data.push({
      reference: value?.reference,
      referenceAlternative: value?.alternativeReference,
      stock: value?.stock,
      price: formatCurrency(value?.price),
      condition: value?.condition
    });
  });

  return data;
};

IconFormatter.propTypes = {
  error: PropTypes.bool
};

ErrorInfoFormatter.propTypes = {
  descriptionError: PropTypes.string,
  error: PropTypes.bool
};

ActionsFormatter.propTypes = {
  error: PropTypes.bool,
  orderId: PropTypes.string,
  alternatives: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string,
      stock: PropTypes.number,
      price: PropTypes.number,
      condition: PropTypes.string
    })
  )
};
