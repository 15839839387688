import React from 'react';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import ChatMessage from 'app/modules/Devolutions/components/ChatMessage';
import Divider from 'app/components/Divider';
import Icon from 'app/components/Icon';

const iconColor = Colors.bbSecondary;

export const addActions = ({ message, index, length }) => {
  const {
    attributes: { isAdmin, date, comment },
    id
  } = message;

  let component = <ChatMessage isAdmin={isAdmin} date={date} text={comment} />;

  if (index < length - 1) {
    component = (
      <React.Fragment key={id}>
        {component}
        <Divider type="dash" />
      </React.Fragment>
    );
  }

  if (index === length - 1) {
    component = (
      <React.Fragment key={id}>
        {component}
        <div className="chat-message-actions">
          <Button type="secondary" size="small" text="Responder" />
          <Button
            size="small"
            text={length}
            icon={<Icon icon="comments" color={iconColor} swapOpacity />}
          />
        </div>
      </React.Fragment>
    );
  }

  return component;
};
