import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import { Form } from 'react-bootstrap';
import { useField } from 'formik';

import Label from 'app/components/Label';

import 'app/components/RangeSlider/RangeSlider.scss';

export default function RangeSlider({
  orientation = 'horizontal',
  size = 'medium',
  disabled = false,
  marks = false,
  step = 1,
  track = 'normal',
  label = null,
  tooltipText = null,
  initialValue = [0, 50],
  valueLabelDisplay = 'auto',
  min,
  max,
  name
}) {
  const [field, meta, helpers] = useField({ name });
  const { error, touched } = meta;
  const [value, setValue] = useState(initialValue);

  const handleChange = (event, newValue) => {
    helpers.setValue(newValue);
    setValue(newValue);
  };

  return (
    <Form.Group className="bb-form-group form-group fv-plugins-icon-container">
      {label && (
        <Label label={label} tooltipText={tooltipText} htmlFor={name} />
      )}
      <Slider
        className="bb-react-slider"
        {...field}
        value={value}
        onChange={handleChange}
        orientation={orientation}
        disabled={disabled}
        min={min}
        max={max}
        name={name}
        size={size}
        marks={marks}
        step={step}
        track={track}
        valueLabelDisplay={valueLabelDisplay}
      />
      {touched && error && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{error}</div>
        </div>
      )}
    </Form.Group>
  );
}

RangeSlider.propTypes = {
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  size: PropTypes.oneOf(['small', 'medium']),
  track: PropTypes.oneOf(['inverted', 'normal']),
  valueLabelDisplay: PropTypes.oneOf(['on', 'off', 'auto']),
  disabled: PropTypes.bool,
  marks: PropTypes.bool,
  step: PropTypes.number,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  initialValue: PropTypes.arrayOf(PropTypes.number)
};
