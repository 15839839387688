import { genericRequest as req } from 'app/api';
import { ACTIVE_RMA_STATUS } from 'app/modules/Devolutions/utils/constants';

const url = 'rma';
const controller = new AbortController();

export const getRma = async (config) => {
  const res = await req({ url, ...config });
  return res;
};

export const getActiveRmaDraft = async () => {
  const activeRmas = await req({
    url: `rma?filter[status]=${ACTIVE_RMA_STATUS.toString()}`,
    method: 'GET',
    data: {
      data: {
        type: 'rma'
      }
    },
    catchError: true,
    signal: controller.signal
  });

  const data = activeRmas?.data;

  if (data && Array.isArray(data) && data.length) {
    return data[0]?.id;
  }

  return null;
};

export const postRma = async (data, config) => {
  const res = await req({ url, method: 'POST', data, ...config });
  return res;
};

export const patchRma = async (id, status = 'PENDING_CONFIRMATION') => {
  const res = await req({
    url,
    method: 'PATCH',
    data: {
      data: {
        id,
        type: 'rma',
        attributes: {
          status
        }
      }
    },
    getHeaders: true,
    signal: controller.signal
  });
  return res;
};

export const getRmaById = async (id, config) => {
  const res = await req({
    url: `${url}/${id}`,
    ...config,
    signal: controller.signal
  });
  return res;
};

export const getElegibleOrders = async (id, filter, config) => {
  const res = await req({
    url: `${url}/${id}/eligible-orders?filter[search]=${filter}`,
    ...config,
    signal: controller.signal
  });
  return res;
};

export const getElegibleProducts = async (rmaId, orderId, config) => {
  const res = await req({
    url: `${url}/${rmaId}/eligible-orders/${orderId}/products`,
    ...config,
    signal: controller.signal
  });
  return res;
};

export const postRequestRma = async (data, config) => {
  const res = await req({
    url,
    method: 'POST',
    data,
    ...config,
    signal: controller.signal
  });
  return res;
};
