import React, { useState, useLayoutEffect, useEffect } from 'react';

import { genericRequest } from 'app/api';
import { useSubheader } from 'app/context/SubheaderContext';

export const MyPage = () => {
  const subheader = useSubheader();
  const [newBreadCrumbs] = useState([
    {
      pathname: '/my-page',
      title: 'Guía de estilos'
    },
    {
      pathname: '/orders',
      title: 'Guía de estilos 2'
    }
  ]);

  const { setTitle, setBreadcrumbs, setIcon } = subheader;
  useLayoutEffect(() => {
    setTitle('Page name');
    setIcon({ icon: 'user' });
    setBreadcrumbs(newBreadCrumbs);
  }, [setTitle, setIcon, setBreadcrumbs, newBreadCrumbs]);

  useEffect(() => {
    (async () => {
      // Petición para realizar pruebas del error 401
      const user = await genericRequest({ url: 'customers/me' });
      // eslint-disable-next-line no-console
      console.log('user mypage', user);
    })();
  });

  return <h1>My page</h1>;
};
