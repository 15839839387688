import React from 'react';
import { useIntl } from 'react-intl';

import Icon from 'app/components/Icon';
import Button from 'app/components/Button';
import usePaymentMethodsContext from 'app/modules/MyAccount/pages/PaymentMethods/context/PaymentMethodsContext';

export default function EmptyPayPal() {
  const intl = useIntl();

  const { handleGoToPayPalForm } = usePaymentMethodsContext();

  return (
    <>
      <p className="bb-payment-methods-paypal__p-info medium">
        {intl.formatMessage({
          id: 'controlpanel.payment_methods.paypal_details.title'
        })}
      </p>
      <Button
        text={intl.formatMessage({
          id: 'controlpanel.payment_methods.paypal_details.add_button'
        })}
        type="secondary"
        size="big"
        typeButton="button"
        onClick={handleGoToPayPalForm}
        icon={
          <Icon
            icon="fa-plus"
            color="#ffffff"
            size="lg"
            className="bb-payment-methods-paypal__button-icon"
            swapOpacity
          />
        }
      />
    </>
  );
}
