import React, { useLayoutEffect } from 'react';

import { useIntl } from 'react-intl';

import { DataTableProvider } from 'app/components/DataTable/context/DataTableContext';
import { getDevolutionsDataTableConfiguration } from 'app/modules/Devolutions/config';
import { useSubheader } from 'app/context/SubheaderContext';
import Card from 'app/components/Card';
import Table from 'app/modules/Devolutions/components/Table';
import FaqsOrderDelivered from 'app/modules/Devolutions/components/Faqs/FaqsOrderDelivered';
import FaqsReturnStatus from 'app/modules/Devolutions/components/Faqs/FaqsReturnStatus';

import Notifications from 'app/modules/Devolutions/components/Notifications';

import 'app/modules/Devolutions/Devolutions.scss';
import { filter, size } from 'lodash';
import useNotificationsServiceHook from 'app/hooks/useNotificationsService.hook';
import UserNotificationAlert from 'app/components/UserNotificationAlert';

export default function Devolutions() {
  const intl = useIntl();
  const subheader = useSubheader();
  const { setTitle, setIcon, setBreadcrumbs } = subheader;

  useLayoutEffect(() => {
    setTitle(intl.formatMessage({ id: 'controlpanel.asidemenu.devolutions' }));
    setIcon({ icon: 'retweet' });
    setBreadcrumbs([]);
  }, [intl, setTitle, setBreadcrumbs, setIcon]);

  const {
    notifications: { alerts }
  } = useNotificationsServiceHook();

  const alertsFiltered = filter(
    alerts,
    (alert) => alert?.attributes?.section === 'RETURN'
  );

  return (
    <DataTableProvider
      getTableContextConfiguration={getDevolutionsDataTableConfiguration}
    >
      {size(alertsFiltered) > 0 && (
        <UserNotificationAlert alerts={alertsFiltered} />
      )}
      <Card
        title={intl.formatMessage({
          id: 'controlpanel.global.do_you_want_to_create_a_return'
        })}
        body={
          <div className="devolutions">
            <Notifications />
            <div className="devolutions-table bb-data-table col-xs-12 col-md-12">
              <Table />
            </div>
          </div>
        }
      />
      <Card
        extraClass="devolutions-faqs-container"
        icon="info-square"
        title={intl.formatMessage({
          id: 'controlpanel.devolutions.faqs.order_delivered'
        })}
        body={<FaqsOrderDelivered />}
        collapsable
        initialOpen={false}
      />
      <Card
        extraClass="devolutions-faqs-container"
        icon="info-square"
        title={intl.formatMessage({
          id: 'controlpanel.devolutions.faqs.return_states'
        })}
        body={<FaqsReturnStatus />}
        collapsable
        initialOpen={false}
      />
    </DataTableProvider>
  );
}
