import React from 'react';

import { Row, Col } from 'react-bootstrap';

import EmptyPayPal from 'app/modules/MyAccount/pages/PaymentMethods/components/PayPal/EmptyPayPal';
import PayPalDetails from 'app/modules/MyAccount/pages/PaymentMethods/components/PayPal/PayPalDetails';
import usePaymentMethodsContext from 'app/modules/MyAccount/pages/PaymentMethods/context/PaymentMethodsContext';

import 'app/modules/MyAccount/pages/PaymentMethods/components/PayPal/PayPal.scss';

export default function PayPal() {
  const { paypal, hasPayPal } = usePaymentMethodsContext();

  return (
    <Row className="bb-payment-methods-paypal__row-info">
      <Col
        xxl={{ offset: 3, span: 9 }}
        className="bb-payment-methods-paypal__col-info"
      >
        {!hasPayPal ? <EmptyPayPal /> : <PayPalDetails paypal={paypal} />}
      </Col>
    </Row>
  );
}
