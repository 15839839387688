export const getFiltersUrl = (filters) => {
  // Si no tenemos filtros no necesitamos modificar la url
  if (!filters) {
    return '';
  }

  // En caso contrario transformamos los filtros en un array
  const filtersArray = Object.entries(filters);

  // Si no tiene valores, no necesitamos modificar la url
  if (!filtersArray?.length) {
    return '';
  }

  // En caso contrario...
  let filtersUrl = '';

  filtersArray.forEach(([filter, value]) => {
    // ...guardamos datos de inicio y final en rangos...
    if (typeof value === 'object' && !Array.isArray(value)) {
      filtersUrl += `&filter[${filter}]=${value.start},${value.end}`;
    } else {
      // ...o el dato directamente en otro caso (los arrays se
      // convierten automáticamente a strings separados por comas)
      filtersUrl += `&filter[${filter}]=${value}`;
    }
  });

  return filtersUrl;
};
