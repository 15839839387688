import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Collapse from 'react-bootstrap/Collapse';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import Divider from 'app/components/Divider';
import Icon from 'app/components/Icon';

import 'app/components/Card/Card.scss';

export default function Card({
  background = Colors.bbWhite,
  icon = null,
  iconBackground = Colors.bbSecondaryLight,
  title = null,
  divider = false,
  spaced = false,
  type = 'solid',
  buttons = [],
  body = null,
  header = null,
  extraClass = '',
  collapsable = false,
  initialOpen = true,
  preHeader = null
}) {
  const [open, setOpen] = useState(initialOpen);

  return (
    <div className={`bb-card ${extraClass}`} style={{ background }}>
      {preHeader && <div className="bb-card__preheader">{preHeader}</div>}

      <div className="bb-card__container">
        {(header || collapsable || icon || title || buttons.length > 0) && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            className="bb-card__container__header"
            onClick={() => {
              if (collapsable) {
                setOpen(!open);
              }
            }}
          >
            <div className="bb-card__container__header__title">
              {icon && (
                <div
                  className="bb-card__container__header__title__icon"
                  style={{ background: iconBackground }}
                >
                  <Icon icon={icon} color={Colors.bbSecondaryDark} size="lg" />
                </div>
              )}
              {title && <p className="big bold">{title}</p>}
            </div>
            {header && (
              <div className="bb-card__container__header__element">
                {header}
              </div>
            )}
            {buttons.length > 0 && (
              <div className="bb-card__container__header__buttons">
                {buttons.map((button, index) => (
                  <Button
                    key={`${index}-${button.text}`}
                    text={button.text}
                    icon={button.icon}
                    type={button.type}
                    size={button.size}
                    disabled={button.disabled}
                    typeButton={button.typeButton}
                    onClick={button.onClick}
                  />
                ))}
              </div>
            )}
            {collapsable && (
              <Icon
                className={classNames(
                  'bb-card__container__header__collapsable',
                  {
                    open
                  }
                )}
                icon="chevron-double-left"
                color={Colors.bbSecondary}
                aria-expanded={open}
                aria-controls="collapse-card"
                onClick={() => setOpen(!open)}
              />
            )}
          </div>
        )}
        {divider && <Divider type={type} />}
        {body && (
          <div
            className={classNames('bb-card__container__body', {
              'bb-card__container__body--spaced': !divider && spaced
            })}
          >
            <Collapse in={open}>
              <div id="collapse-card">{body}</div>
            </Collapse>
          </div>
        )}
      </div>
    </div>
  );
}

const ButtonProp = PropTypes.shape({
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  typeButton: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
});

Card.propTypes = {
  background: PropTypes.string,
  icon: PropTypes.string,
  iconBackground: PropTypes.string,
  title: PropTypes.string,
  divider: PropTypes.bool,
  spaced: PropTypes.bool,
  type: PropTypes.string,
  buttons: PropTypes.arrayOf(ButtonProp),
  body: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.instanceOf(null)
  ]),
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.instanceOf(null)
  ]),
  extraClass: PropTypes.string,
  collapsable: PropTypes.bool,
  initialOpen: PropTypes.bool,
  preHeader: PropTypes.node
};
