import React from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { map } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Colors } from 'app/utils/colors';
import Icon from 'app/components/Icon';

import 'app/components/DataTableColumnOrder/DataTableColumnOrder.scss';

export default function DataTableColumnOrder({
  title = null,
  onDragEnd = () => null,
  onChangeCheckbox = () => null,
  columns
}) {
  return (
    <div className="data-table-column-order">
      <div className="data-table-column-order__container">
        <DragDropContext onDragEnd={onDragEnd}>
          <FormControl component="fieldset">
            {title && <p className="big bold">{title}</p>}
            <Droppable droppableId="list">
              {(droppableProvided) => (
                <div
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                >
                  {map(columns, (column, index) => {
                    if (!column?.hiddenToUser) {
                      return (
                        <Draggable
                          key={column.dataField}
                          draggableId={column.dataField}
                          index={index}
                        >
                          {(draggableProvided) => (
                            <div
                              className="draggable"
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                            >
                              <FormControlLabel
                                value={column.dataField}
                                label={<p>{column.text}</p>}
                                labelPlacement="end"
                                control={
                                  <Checkbox
                                    className="column-checkbox"
                                    color="secondary"
                                    checked={!column?.hidden}
                                    onChange={() => onChangeCheckbox(index)}
                                  />
                                }
                              />
                              <Icon
                                icon="grip-vertical"
                                color={Colors.bbSecondary}
                                size="lg"
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    }

                    return null;
                  })}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </FormControl>
        </DragDropContext>
      </div>
    </div>
  );
}

DataTableColumnOrder.propTypes = {
  title: PropTypes.node,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string,
      hidden: PropTypes.bool,
      text: PropTypes.node
    })
  ).isRequired,
  onDragEnd: PropTypes.func,
  onChangeCheckbox: PropTypes.func
};
