/* eslint-disable no-shadow */
import React, { useState } from 'react';

import { Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { genericRequest } from 'app/api';
import { setUser } from 'app/modules/Auth/_redux/AuthActions';
import { URLS } from 'app/config/urls';
import Divider from 'app/components/Divider';
import FormCard from 'app/components/FormCard';
import MyAccountLayout from 'app/modules/MyAccount/components/MyAccountLayout';
import SynchronizeWithBigBuyApiKey from 'app/modules/MyAccount/pages/Syncronize/components/SynchronizeWithBigBuyApiKey';
import SynchronizeWithBigBuyFTP from 'app/modules/MyAccount/pages/Syncronize/components/SynchronizeWithBigBuyFTP';

import 'app/modules/MyAccount/pages/Syncronize/Syncronize.scss';

export default function Syncronize() {
  const { user } = useSelector((state) => state.auth);
  const [apiKeyActive, setApiKeyActive] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  const { apiKeySandbox, apiKeyProduction, ftpDomain, ftpPassword, ftpUser } =
    user?.attributes;

  const onEnableFTP = async () => {
    if (ftpDomain && ftpPassword && ftpUser) {
      return;
    }

    await genericRequest({
      url: `customers/me/actions`,
      method: 'POST',
      data: {
        data: {
          type: 'customer-action',
          attributes: {
            type: 'ftp-activation'
          }
        }
      }
    });

    const newUserData = await genericRequest({ url: 'customers/me' });
    dispatch(setUser(newUserData.data));
  };

  const onLoadApiKeys = async () => {
    if (!apiKeySandbox && !apiKeyProduction) {
      await genericRequest({
        url: `customers/me/actions`,
        method: 'POST',
        data: {
          data: {
            type: 'customer-action',
            attributes: {
              type: 'api-activation'
            }
          }
        }
      });

      const newUserData = await genericRequest({ url: 'customers/me' });
      dispatch(setUser(newUserData.data));
    }

    if (apiKeySandbox && apiKeyProduction) {
      setApiKeyActive(true);
    }
  };

  return (
    <MyAccountLayout currentPagePath={URLS.myAccountSyncronize}>
      <Col md={8}>
        <FormCard
          title={intl.formatMessage({
            id: 'controlpanel.my_account.synchronize.title'
          })}
        >
          <div className="bb-synchronize-bigbuy-page">
            <SynchronizeWithBigBuyFTP
              ftpDomain={ftpDomain}
              ftpPassword={ftpPassword}
              ftpUser={ftpUser}
              onEnableFTP={onEnableFTP}
              disabled={false}
            />
            <Divider type="dash" />
            <SynchronizeWithBigBuyApiKey
              apiKeySandbox={apiKeySandbox}
              apiKeyProduction={apiKeyProduction}
              disabled={false}
              onShowApiKey={onLoadApiKeys}
              showApiKey={apiKeyActive}
            />
          </div>
        </FormCard>
      </Col>
    </MyAccountLayout>
  );
}
