import React from 'react';

import AddProductForm from 'app/modules/Devolutions/components/AddProductForm';
import CollectionAddressCard from 'app/modules/Devolutions/components/CollectionAddressCard';
import CollectionAddressForm from 'app/modules/Devolutions/components/CollectionAddressForm';
import DefaultAlert from 'app/modules/Devolutions/components/NotificationDevolution/components/DefaultAlert';
import DraftList from 'app/modules/Devolutions/components/NotificationDevolution/components/DraftList';
import PrintForm from 'app/modules/Devolutions/components/NotificationDevolution/components/PrintForm';

export const MAP_STATUS_DEVOLUTION = {
  DRAFT: {
    alert: {
      iconCard: 'search',
      variant: 'info',
      title: 'controlpanel.devolutions.alert.draft.title',
      content: <DraftList />
    },
    alertAditionalContent: <AddProductForm />
  },
  PENDING_CONFIRMATION: {
    alert: {
      iconCard: 'tags',
      variant: 'warning',
      title: 'controlpanel.devolutions.alert.pending_confirmation.title',
      content: (
        <DefaultAlert text="controlpanel.devolutions.alert.pending_confirmation.text" />
      )
    }
  },
  PENDING_REVIEW: {
    alert: {
      iconCard: 'search',
      variant: 'warning',
      title: 'controlpanel.devolutions.alert.pending_review.title',
      content: (
        <DefaultAlert text="controlpanel.devolutions.alert.pending_review.text" />
      )
    }
  },
  PENDING_PICK_UP_ADDRESS: {
    alert: {
      iconCard: 'truck',
      variant: 'success',
      title: 'controlpanel.devolutions.alert.pending_pick_up_address.title',
      content: (
        <DefaultAlert text="controlpanel.devolutions.alert.pending_pick_up_address.text" />
      )
    },
    alertAditionalContent: <CollectionAddressForm />
  },
  PENDING_COLLECTION: {
    alert: {
      iconCard: 'tags',
      variant: 'reminder',
      title: 'controlpanel.devolutions.alert.pending_collection.title',
      content: (
        <DefaultAlert text="controlpanel.devolutions.alert.pending_collection.text" />
      )
    },
    alertAditionalContent: <PrintForm />,
    extraContent: <CollectionAddressCard />
  },
  PENDING_VALUATION: {
    alert: {
      iconCard: 'tags',
      variant: 'reminder',
      title: 'controlpanel.devolutions.alert.pending_valuation.title',
      content: (
        <DefaultAlert text="controlpanel.devolutions.alert.pending_valuation.text" />
      )
    }
  },
  PENDING_PAYMENT: {
    alert: {
      iconCard: 'tags',
      variant: 'reminder',
      title: 'controlpanel.devolutions.alert.pending_payment.title',
      content: (
        <DefaultAlert text="controlpanel.devolutions.alert.pending_payment.text" />
      )
    }
  },
  PAID: {
    alert: {
      iconCard: 'tags',
      variant: 'success',
      title: 'controlpanel.devolutions.alert.paid.title',
      content: <DefaultAlert text="controlpanel.devolutions.alert.paid.text" />
    }
  },
  REJECTED: {
    alert: {
      iconCard: 'tags',
      variant: 'grey',
      title: 'controlpanel.devolutions.alert.rejected.title',
      content: (
        <DefaultAlert text="controlpanel.devolutions.alert.rejected.text" />
      )
    }
  }
};
