import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { size } from 'lodash';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import ColumnReorderModal from 'app/components/ColumnReorderModal';
import DataTable from 'app/components/DataTable';
import DataTableFilters from 'app/components/DataTableFilters';
import DataTableFiltersTags from 'app/components/DataTableFiltersTags';
import DataTableTabs from 'app/components/DataTableTabs';
import Icon from 'app/components/Icon';
import PopupDownloadCSV from 'app/components/PopupDownloadCSV';
import PopupDownloadInvoices from 'app/components/PopupDownloadInvoices/PopupDownloadInvoices';
import SearchBar from 'app/components/SearchBar';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

import 'app/modules/Invoices/components/Body/Body.scss';

export default function Body() {
  const { submitForm } = useFormikContext();
  const {
    filters: { filters, parsedFiltersArray }
  } = useDataTableContext();

  const intl = useIntl();

  return (
    <div>
      <div className="export-csv-wrapper">
        <PopupDownloadInvoices fileType="invoice" />
        <PopupDownloadCSV fileType="invoice" />
      </div>
      <DataTableTabs />
      <Row className="bb-data-table__filters">
        <Col xs={12} md={5} className="bb-data-table__searchbar">
          <SearchBar
            placeholder={intl.formatMessage({
              id: 'controlpanel.invoices.searchbar_placeholder'
            })}
            name="search"
            button={{
              onClick: submitForm,
              type: 'secondary',
              size: 'big',
              icon: <Icon icon="search" prefix="fas" color={Colors.bbWhite} />
            }}
            value={filters?.search || ''}
          />
        </Col>
        <Col xs={12} md={7} className="bb-data-table__filters-buttons">
          <DataTableFilters />
          <ColumnReorderModal />
        </Col>
        {size(parsedFiltersArray) > 0 && (
          <Col md={12}>
            <DataTableFiltersTags />
          </Col>
        )}
      </Row>
      <DataTable />
    </div>
  );
}
