import React, { useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useLang } from 'app/context/i18n';

import { genericRequest } from 'app/api';
import Card from 'app/components/Card/Card';
import CategoryList from 'app/modules/Downloads/components/CategoryList';

import 'app/modules/Downloads/Pages/CsvXmlFiles/CsvXmlFiles.scss';

export default function CsvXmlFiles() {
  const intl = useIntl();
  const lang = useLang();
  const [list, setList] = useState(null);

  const fetchData = useCallback(
    async (controller) => {
      const result = await genericRequest({
        url: `csv-sections?lang=${lang}`,
        signal: controller.signal
      });
      setList(result?.data);
    },
    [lang]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller);

    return () => controller.abort();
  }, [fetchData]);

  if (!list) return null;

  return (
    <Card
      extraClass="csv-xml-files"
      title={intl.formatMessage({
        id: 'controlpanel.downloads.csv_xml_files'
      })}
      body={<CategoryList list={list} />}
    />
  );
}
