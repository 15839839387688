import React from 'react';

import {
  OverlayTrigger,
  Tooltip,
  Button as BoostrapButton
} from 'react-bootstrap';
import classNames from 'classnames';

import { getInnerContent, PROP_TYPES } from 'app/components/Button/utils';

import 'app/components/Button/Button.scss';

export default function Button({
  text = '',
  type = 'default',
  size = 'huge',
  style = {},
  icon = null,
  tooltipText = '',
  disabled = false,
  typeButton = 'button',
  onClick = () => null,
  children: componentChildren = undefined,
  className = null,
  id = ''
}) {
  const classes = classNames('bb-btn', className, {
    'bb-btn__icon': icon,
    [`bb-btn__${type}`]: type,
    [`bb-btn--${size}`]: size,
    'bb-btn--disabled': disabled
  });

  const children = (
    <BoostrapButton
      className={classes}
      style={style}
      type={typeButton}
      onClick={onClick}
      id={id}
    >
      {getInnerContent({ icon, text, componentChildren })}
    </BoostrapButton>
  );

  if (tooltipText) {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>{tooltipText}</Tooltip>}
        trigger={['hover', 'click']}
      >
        {children}
      </OverlayTrigger>
    );
  }

  return children;
}

Button.propTypes = PROP_TYPES;
