export const startLoading = (setLoading) => {
  if (typeof setLoading === 'function') {
    setLoading(true);
  }
};
export const endLoading = (setLoading) => {
  if (typeof setLoading === 'function') {
    setLoading(false);
  }
};
