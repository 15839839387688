import React from 'react';
import PropTypes from 'prop-types';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Icon from 'app/components/Icon';
import useTrackingsStatus from 'app/hooks/useTrackingsStatus.hook';

export const FormatStatus = ({ statusKey, carrierStatus }) => {
  const trackingsStatus = useTrackingsStatus();

  const { color, backgroundColor, translation } = trackingsStatus[statusKey];

  return (
    <span
      role="none"
      className="bb-trackings__data-table__cell--tag"
      style={{
        backgroundColor
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className="bb-trackings__data-table__cell medium"
        style={{ color }}
      >
        {translation}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{carrierStatus}</Tooltip>}
        >
          <div className="bb-trackings__data-table__cell--tag-tooltip">
            <Icon size="1x" icon="square-info" color={color} />
          </div>
        </OverlayTrigger>
      </label>
    </span>
  );
};

FormatStatus.propTypes = {
  statusKey: PropTypes.string.isRequired,
  carrierStatus: PropTypes.string.isRequired
};
