import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLang } from 'app/context/i18n';
import { formatDateToLocale } from 'app/utils/dates';

export default function NextEntries({
  nextEntries = { date: null, units: null }
}) {
  const intl = useIntl();
  const lang = useLang();
  const { units, date } = nextEntries;

  let text = intl.formatMessage({ id: 'controlpanel.global.no_date' });

  if (units && date) {
    text = intl.formatMessage(
      { id: 'controlpanel.global.units_in_month' },
      {
        units,
        month: formatDateToLocale(date, lang, { month: 'long' })
      }
    );
  }

  return <p className="small">{text}</p>;
}

NextEntries.propTypes = {
  nextEntries: PropTypes.shape({
    date: PropTypes.number,
    units: PropTypes.number
  })
};
