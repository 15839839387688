import React from 'react';

import { map } from 'lodash';

import { ActionsFormatters } from 'app/modules/Invoices/utils/formatters/ActionsFormatters';
import { DateFormatter } from 'app/utils/columnsFormatters/DateFormatter';
import { defaultPgOptions } from 'app/utils/defaultDataTableConfig';
import { formatCurrency } from 'app/utils/currency';
import { isMobile } from 'app/utils/sizes';
import { sortCaret, headerSortingStyle } from 'app/components/DataTable/utils';
import PaymentFormatter from 'app/utils/columnsFormatters/PaymentFormatter';

export const getColumnsToDataTable = (intl) => [
  {
    dataField: 'id',
    hidden: true,
    sort: false,
    draggable: false,
    text: 'id'
  },
  {
    dataField: 'invoiceNumber',
    hidden: false,
    sort: true,
    draggable: true,
    text: intl.formatMessage({
      id: 'controlpanel.global.invoice_number'
    }),
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'date',
    text: intl.formatMessage({ id: 'controlpanel.global.date' }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'amount',
    text: intl.formatMessage({ id: 'controlpanel.global.amount' }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'orderCount',
    text: intl.formatMessage({
      id: 'controlpanel.global.orders_number'
    }),
    sort: true,
    hidden: true,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'payment',
    text: intl.formatMessage({
      id: 'controlpanel.global.paymentmethod'
    }),
    sort: true,
    hidden: isMobile(),
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'actions',
    text: intl.formatMessage({
      id: 'controlpanel.global.table_actions'
    }),
    sort: false,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  }
];

export const getNoResultsConfig = (intl) => ({
  text: intl.formatMessage({ id: 'controlpanel.global.no_results_founds' }),
  icon: 'file-invoice-dollar'
});

export const getDataTableStructure = (data) => {
  const dataStructure = [];

  map(data, (value) => {
    const {
      id,
      attributes: {
        invoiceNumber = null,
        orderCount = null,
        date = null,
        amount = null,
        payment = null,
        link
      }
    } = value;

    dataStructure.push({
      id,
      invoiceNumber,
      orderCount,
      date: <DateFormatter date={date} />,
      amount: formatCurrency(amount),
      payment: <PaymentFormatter paymentMethod={payment} />,
      actions: <ActionsFormatters link={link} invoiceNumber={invoiceNumber} />
    });
  });

  return dataStructure;
};

export const pgOptions = {
  ...defaultPgOptions,
  defaultSorted: [
    {
      dataField: 'invoiceNumber',
      order: 'desc'
    }
  ]
};
