import React from 'react';
import PropTypes from 'prop-types';

import { getRedColumnsStatus } from 'app/hooks/useShopsStatus.hook';

export const FormatTheme = ({ theme, statusKey }) => {
  let classes = 'bb-shops__data-table__cell small';

  if (getRedColumnsStatus().includes(statusKey)) {
    classes += ' warning';
  }

  return <p className={classes}>{theme}</p>;
};

FormatTheme.propTypes = {
  theme: PropTypes.string.isRequired,
  statusKey: PropTypes.string.isRequired
};
