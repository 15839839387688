/* eslint-disable no-console */
export const saveInContextStorage = ({ field, data, setContextStorage }) => {
  if (!field || !data) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(
        'ERROR EN saveInContextStorage: Debes proporcionar un campo para guardar. No te preocupes por este error si se debe a un re-renderizado'
      );
    }
    return;
  }

  setContextStorage((prev) => {
    const storageKeys = Object.keys(prev);
    const keyInUse = storageKeys.find((storedField) => storedField === field);

    if (!keyInUse) {
      const newStorage = { ...prev };
      newStorage[field] = data;
      return newStorage;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.error(
        'ERROR EN saveInContextStorage: Ya estás utilizando esa clave, no puedes guardar más de un valor con el mismo identificador. Prueba a cambiarle de nombre o utilizar la función updateContextStorage. No te preocupes por este error si se debe a un re-renderizado'
      );
    }

    return prev;
  });
};

export const readContextStorage = ({ contextStorage }) => contextStorage;

export const updateContextStorage = ({ field, data, setContextStorage }) => {
  if (!field || !data) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(
        'ERROR EN updateContextStorage: Debes proporcionar un campo para actualizar. No te preocupes por este error si se debe a un re-renderizado'
      );
    }
    return;
  }

  setContextStorage((prev) => {
    const storageKeys = Object.keys(prev);
    const keyInUse = storageKeys.find((storedField) => storedField === field);

    if (keyInUse) {
      const oldStorage = { ...prev };
      oldStorage[field] = data;
      return data;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.error(
        'ERROR EN updateContextStorage: Esa clave no está almacenada en el contextStorage. No te preocupes por este error si se debe a un re-renderizado'
      );
    }

    return prev;
  });
};

export const removeFromContextStorage = ({ field, setContextStorage }) => {
  if (!field) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(
        'ERROR EN removeFromContextStorage: Debes proporcionar un campo para eliminar'
      );
    }
    return;
  }

  setContextStorage((prev) => {
    const storageKeys = Object.keys(prev);
    const keyInUse = storageKeys.find((storedField) => storedField === field);

    if (keyInUse) {
      const newStorage = { ...prev };
      delete newStorage[field];
      return newStorage;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.error(
        'ERROR EN removeFromContextStorage: Esa clave no se está utilizando'
      );
    }

    return prev;
  });
};
