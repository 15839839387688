import React from 'react';

import classNames from 'classnames';

import { formatDateByFormat, getHoursAndMinutes } from 'app/utils/dates';

import 'app/modules/OrderView/config/TableFormatters/DateFormatter/DateFormatter.scss';

export function DateFormatter(cell, row) {
  if (!cell) {
    return '';
  }

  const { status } = row;
  const date = new Date(cell * 1000);
  const formatedDate = formatDateByFormat(date, 'dd/mm/yyyy');

  return (
    <div className="date-formatter">
      <p className="small">{formatedDate}</p>
      <p
        className={classNames('date-formatter__hour small', {
          warning: status === 'PENDING_INVOICING'
        })}
      >
        {getHoursAndMinutes(date)}
      </p>
    </div>
  );
}
