import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import PopupDelete from 'app/modules/Wishlist/components/PopupDelete';

import 'app/modules/Wishlist/config/table/formatters/ActionsFormatter/ActionsFormatter.scss';
import PopupAddToCart from 'app/modules/Wishlist/components/PopupAddToCart';

export default function ActionsFormatters({ productUrl, id }) {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showAddToCart, setShowAddToCart] = useState(false);
  const intl = useIntl();

  return (
    <div className="actions-formatter">
      <Button
        icon={<Icon icon="eye" color={Colors.bbSecondary} prefix="fad" />}
        onClick={() => window.open(productUrl, '_blank')}
        size="medium"
        tooltipText={intl.formatMessage({
          id: 'controlpanel.global.view_product'
        })}
      />
      <Button
        icon={<Icon icon="trash" color={Colors.bbSecondary} prefix="fad" />}
        onClick={() => setShowDeletePopup(true)}
        size="medium"
        tooltipText={intl.formatMessage({
          id: 'controlpanel.global.delete_product'
        })}
      />
      <PopupDelete
        id={id}
        setShow={setShowDeletePopup}
        show={showDeletePopup}
      />
      <Button
        id="add_cart_clic_on_wishlist"
        type="primary"
        icon={<Icon icon="shopping-cart" color={Colors.bbWhite} prefix="fas" />}
        onClick={() => setShowAddToCart(true)}
        size="medium"
        tooltipText={intl.formatMessage({
          id: 'controlpanel.global.add_to_cart'
        })}
      />
      <PopupAddToCart id={id} setShow={setShowAddToCart} show={showAddToCart} />
    </div>
  );
}

ActionsFormatters.propTypes = {
  id: PropTypes.string.isRequired,
  productUrl: PropTypes.string.isRequired
};
