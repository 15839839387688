import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';

import { BasicDataTable } from 'app/components/DataTable';
import {
  getDataTableStructure,
  rowClasses
} from 'app/modules/Trackings/config/table';
import { GetColDataTable } from 'app/modules/OrderView/components/DeliveryNotes/config/Trackings';
import useDataProviderContext from 'app/hooks/useDataProviderContext';

export default function Trackings({ isLoading }) {
  const { trackings = [], fetchData } = useDataProviderContext();

  return (
    <Row>
      <Col md={12}>
        <BasicDataTable
          id="delivery-notes-trackings"
          columns={GetColDataTable(fetchData)}
          entities={getDataTableStructure(trackings)}
          keyField="id"
          rowClasses={rowClasses}
          loading={isLoading}
        />
      </Col>
    </Row>
  );
}

Trackings.propTypes = { isLoading: PropTypes.bool.isRequired };
