import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { map, split } from 'lodash';

import { capitalizeFirstLetter } from 'app/utils/text';

export const getTitle = (menuId) => {
  const result = {
    title: '',
    icon: ''
  };

  const menu = document.getElementById(menuId);
  if (!menu) {
    return result;
  }

  const activeMenuLink = menu.querySelector('.active');

  if (!activeMenuLink) {
    return result;
  }

  result.icon = activeMenuLink?.dataset?.iconSrc;
  result.title = activeMenuLink.querySelector('.menu-text').innerText;

  return result;
};

export const getBreadcrumbs = (path, messages) => {
  const breadcrumbs = [];

  let translationKey = 'breadcrumbs';
  let url = '';
  map(split(path, '/'), (breadcrumb) => {
    if (breadcrumb) {
      translationKey += `.${breadcrumb}`;
      let translation = messages[translationKey];

      if (!translation) {
        translation = capitalizeFirstLetter(
          breadcrumb.replace(/[^a-zA-Z ]/g, ' ')
        );
      }

      url += `/${breadcrumb}`;

      const result = {
        pathname: url,
        title: translation
      };

      breadcrumbs.push(result);
    }
  });

  return breadcrumbs;
};

const SubheaderContext = createContext();

export const useSubheader = () => useContext(SubheaderContext);

export const SubheaderConsumer = SubheaderContext.Consumer;

export function SubheaderProvider({ children }) {
  const [title, setTitle] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [icon, setIcon] = useState({ icon: 'circle', prefix: 'fad' });

  const value = {
    title,
    setTitle,
    breadcrumbs,
    setBreadcrumbs,
    icon,
    setIcon
  };

  return (
    <SubheaderContext.Provider value={value}>
      {children}
    </SubheaderContext.Provider>
  );
}

SubheaderProvider.propTypes = {
  children: PropTypes.node.isRequired
};
