import React, { createContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { changeLanguage } from 'app/api/changeLang';

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';
const initialState = {
  selectedLang: 'es'
};

function getConfig() {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      // eslint-disable-next-line no-console
      console.error(er);
    }
  }
  return initialState;
}

// Side effect
export async function setLanguage(lang) {
  const result = await changeLanguage(lang);
  if (result === 200) {
    window.location.reload();
  }
}

const I18nContext = createContext();

export function useLang() {
  const { user } = useSelector((state) => state.auth);
  return user?.attributes?.locale;
}

export const I18nConsumer = I18nContext.Consumer;

// eslint-disable-next-line react/prop-types
export function MetronicI18nProvider({ children }) {
  const lang = useMemo(getConfig, []);

  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
}
