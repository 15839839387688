import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Modal from 'app/components/Modal';
import CropImage from 'app/components/CropImage';
import Button from 'app/components/Button';

import 'app/modules/Shops/pages/Shopify/components/CropImageModal.scss';

export default function CropImageModal({
  name,
  image,
  label,
  show,
  onCancelModal,
  onUpdateModal,
  disabledSaveButton,
  onSaveModal,
  onCropImage
}) {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ id: label })}
      show={show}
      buttons={
        <>
          <p className="crop-info">
            {intl.formatMessage({
              id: 'controlpanel.global.crop_info'
            })}
          </p>
          <Button
            text={intl.formatMessage({ id: 'controlpanel.global.cancel' })}
            onClick={onCancelModal}
          />
          <Button
            text={intl.formatMessage({
              id: 'controlpanel.global.select_image'
            })}
            onClick={onUpdateModal}
          />
          <Button
            type="secondary"
            text={intl.formatMessage({
              id: 'controlpanel.global.save'
            })}
            disabled={disabledSaveButton}
            onClick={onSaveModal}
          />
        </>
      }
      body={
        <>
          <CropImage
            image={image}
            onComplete={onCropImage}
            cropSize={
              name === 'logo'
                ? { width: 235, height: 65 }
                : { width: 16, height: 16 }
            }
            minZoom={0.5}
          />
          {/* <p>
            Al subir tu imagen, ten en cuenta que la máscara resalta la región a
            guardar. Arrastra el área que quieres mostrar haciendo click sobre
            la imagen.
          </p> */}
        </>
      }
    />
  );
}

CropImageModal.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  onUpdateModal: PropTypes.func.isRequired,
  disabledSaveButton: PropTypes.bool.isRequired,
  onSaveModal: PropTypes.func.isRequired,
  onCropImage: PropTypes.func.isRequired
};
