import React from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';
import classNames from 'classnames';

import 'app/components/DataTable/Summary.scss';

export default function Summary({ lines }) {
  return (
    <div className="summary">
      <div className="summary-container">
        {map(lines, (line, key) => {
          const { text, price } = line;
          return (
            <div className="summary-line" key={key}>
              <p
                className={classNames(text?.size, text?.color, {
                  bold: text?.bold === true
                })}
              >
                {text?.value}
              </p>
              <p
                className={classNames(price?.size, price?.color, {
                  bold: price?.bold === true
                })}
              >
                {price?.value}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

Summary.propTypes = {
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.shape({
        bold: PropTypes.bool,
        color: PropTypes.string,
        size: PropTypes.string,
        value: PropTypes.string
      }),
      price: PropTypes.shape({
        bold: PropTypes.bool,
        color: PropTypes.string,
        size: PropTypes.string,
        value: PropTypes.string
      })
    })
  ).isRequired
};
