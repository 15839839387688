import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';

export default function ActionsFormatter({ link }) {
  const intl = useIntl();

  return (
    <div className="actions-formatter">
      <Button
        icon={<Icon icon="download" color={Colors.bbSecondary} prefix="fad" />}
        onClick={() => window.open(link, '_blank')}
        size="medium"
        tooltipText={intl.formatMessage({
          id: 'controlpanel.global.view_manual'
        })}
      />
    </div>
  );
}

ActionsFormatter.propTypes = {
  link: PropTypes.string.isRequired
};
