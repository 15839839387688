import { genericRequest } from 'app/api';

const makeOrder = async (order) => {
  const response = await genericRequest({
    url: 'orders/create-api-order',
    method: 'post',
    data: {
      data: {
        type: 'order-api-csv',
        attributes: { order }
      }
    },
    blockFeedback: true
  });

  return response?.data;
};

export default makeOrder;
