import React from 'react';
import PropTypes from 'prop-types';
import 'app/components/Divider/Divider.scss';

export default function Divider({ type = 'solid' }) {
  return <hr className={`bb-separator--${type}`} />;
}

Divider.propTypes = {
  type: PropTypes.oneOf(['solid', 'dash'])
};
