export const parseFilters = (filters) => {
  const parsedFilters = {};

  const filtersArray = Object.entries(filters);

  filtersArray.forEach(([key, value]) => {
    // No guardamos filtros sin valor ni funciones (no debería haberlas)
    if (
      !value ||
      typeof value === 'function' ||
      (Array.isArray(value) && !value?.length)
    ) {
      return;
    }
    // Si el valor es un tipo primitivo o un array con valor,
    // lo guardamos tal cual
    if (typeof value !== 'object' || (Array.isArray(value) && value?.length)) {
      parsedFilters[key] = value;
      return;
    }
    // Si el valor es un objeto, repetimos la función para determinar
    // si tiene valores dentro. En caso afirmativo, los guardamos
    const nestedFields = parseFilters(value);
    if (Object.values(nestedFields)?.length) {
      parsedFilters[key] = nestedFields;
    }
  });

  return parsedFilters;
};
