import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Button from 'app/components/Button';
import { genericRequest } from 'app/api';
import { setProductsInCart } from 'app/modules/Auth/_redux/AuthActions';

const getBtnText = (state) => {
  switch (state) {
    case 'purchased':
      return 'controlpanel.academy.show_course';
    case 'disabled':
      return 'controlpanel.academy.soon';
    default:
      return 'controlpanel.academy.discover';
  }
};

export default function CourseCardButton({
  serviceCheckout,
  disabled,
  btnLink = null,
  state,
  id
}) {
  const intl = useIntl();
  const [disabledButton, setisabledButton] = useState(disabled);
  const dispatch = useDispatch();

  const defaultButton = (
    <Button
      type={
        state === 'purchased' || state === 'disabled' ? 'default' : 'primary'
      }
      disabled={disabled}
      role="link"
      onClick={() => window.open(btnLink, '_blank')}
      text={intl.formatMessage({ id: getBtnText(state) })}
    />
  );

  const productCartButton = (
    <Button
      text={intl.formatMessage({
        id: 'controlpanel.global.add_to_cart'
      })}
      type={
        state === 'purchased' || state === 'disabled' ? 'default' : 'primary'
      }
      disabled={disabledButton}
      onClick={async () => {
        try {
          setisabledButton(true);
          const result = await genericRequest({
            url: `${process.env.REACT_APP_BIGBUY_URL}front/api/cart/add-course-to-cart`,
            method: 'POST',
            data: {
              data: {
                type: 'cart-add-course-to-cart',
                attributes: {
                  idProduct: id
                }
              }
            },
            getHeaders: true,
            customSuccessFeedback: {
              variant: 'success',
              content: intl.formatMessage({
                id: 'controlpanel.global.add_to_cart.success'
              }),
              autoClose: true,
              dismissible: false
            }
          });

          if (result.status === 200) {
            dispatch(setProductsInCart(result?.data?.meta?.numProducts));
          }
        } catch (error) {
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.log(error);
          }
        }
        setisabledButton(disabled);
      }}
    />
  );

  return !serviceCheckout && state !== 'purchased' && !disabled
    ? productCartButton
    : defaultButton;
}

CourseCardButton.propTypes = {
  serviceCheckout: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  btnLink: PropTypes.string,
  state: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};
