import React from 'react';
import PropTypes from 'prop-types';

import { formatDateByFormat, formatHours } from 'app/utils/dates';

export const DateFormatter = ({ date = null }) => {
  if (!date) {
    return null;
  }

  return (
    <div className="date-formatter">
      <p className="small date-formatter__date">
        {formatDateByFormat(date * 1000, 'dd/mm/yyyy')}
      </p>
      <p className="small date-formatter__hour ">{formatHours(date * 1000)}</p>
    </div>
  );
};

DateFormatter.propTypes = {
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
