import { endPoint } from 'app/config/axios/customAxios';

function cmsRequest({ api, locale }) {
  const BASE_URL_CMS = process.env.REACT_APP_CMS_GATEWAY_URL;

  let url = `${BASE_URL_CMS}controlpanel/${api}?locale=${locale}`;

  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    url += `&preview=true`;
  }

  return endPoint.request(url);
}

export default cmsRequest;
