import React from 'react';
import PropTypes from 'prop-types';
import { PaginationLinks, PaginationToolbar } from 'app/components/Pagination';

import 'app/components/Pagination/Pagination.scss';

export default function Pagination({ children, paginationProps }) {
  return (
    <div className="pagination">
      {children}

      <div className="pagination__container">
        <PaginationLinks paginationProps={paginationProps} />
        <PaginationToolbar paginationProps={paginationProps} />
      </div>
    </div>
  );
}

Pagination.propTypes = {
  children: PropTypes.element.isRequired,
  paginationProps: PropTypes.shape({
    totalSize: PropTypes.number.isRequired,
    sizePerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    paginationSize: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired
  }).isRequired
};
