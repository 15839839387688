import React from 'react';
import { FormattedMessage } from 'react-intl';

export const getSolutionsOptions = () => [
  {
    id: 'solutions-id-40',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_40" />,
    value: '40',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-41',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_41" />,
    value: '41',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-42',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_42" />,
    value: '42',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-43',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_43" />,
    value: '43',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-44',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_44" />,
    value: '44',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-45',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_45" />,
    value: '45',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-46',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_46" />,
    value: '46',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-47',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_47" />,
    value: '47',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-48',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_48" />,
    value: '48',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-49',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_49" />,
    value: '49',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-50',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_50" />,
    value: '50',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-51',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_51" />,
    value: '51',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-52',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_52" />,
    value: '52',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-53',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_53" />,
    value: '53',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-54',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_54" />,
    value: '54',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-55',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_55" />,
    value: '55',
    multiple: true,
    showError: false
  },
  {
    id: 'solutions-id-56',
    name: 'solutions',
    label: <FormattedMessage id="controlpanel.customer.answers.solutions_56" />,
    value: '56',
    multiple: true,
    showError: true
  }
];

export const getShopOnlineOptions = () => [
  {
    id: 'yes',
    name: 'onlineShop',
    label: <FormattedMessage id="controlpanel.global.yes" />,
    value: '32',
    multiple: true,
    showError: true
  },
  {
    id: 'no',
    name: 'onlineShop',
    label: <FormattedMessage id="controlpanel.global.no" />,
    value: '34',
    multiple: true,
    showError: false
  },
  {
    id: 'construction',
    name: 'onlineShop',
    label: <FormattedMessage id="controlpanel.global.work_in_progress" />,
    value: '33',
    multiple: true,
    showError: false
  }
];
export const getContactOptions = () => [
  {
    id: 'phone',
    name: 'parentContactPreferences',
    label: <FormattedMessage id="controlpanel.global.phone" />,
    value: '57',
    multiple: true,
    showError: true
  },
  {
    id: 'email',
    name: 'parentContactPreferences',
    label: <FormattedMessage id="controlpanel.global.email" />,
    value: '58',
    multiple: true,
    showError: false
  },
  {
    id: 'both',
    name: 'parentContactPreferences',
    label: <FormattedMessage id="controlpanel.global.both" />,
    value: '59',
    multiple: true,
    showError: false
  }
];

export const getContactPreferencesOptions = (parentContactPreferences) => {
  switch (parentContactPreferences) {
    case '57':
      // 57 - Phone
      return [
        {
          value: '60',
          label: (
            <FormattedMessage id="controlpanel.my_account.information.options.morning" />
          )
        },
        {
          value: '61',
          label: (
            <FormattedMessage id="controlpanel.my_account.information.options.midday" />
          )
        },
        {
          value: '62',
          label: (
            <FormattedMessage id="controlpanel.my_account.information.options.afternoon" />
          )
        },
        {
          value: '63',
          label: (
            <FormattedMessage id="controlpanel.my_account.information.options.indifferent" />
          )
        }
      ];

    case '59':
      //  59 - Both
      return [
        {
          value: '64',
          label: (
            <FormattedMessage id="controlpanel.my_account.information.options.morning" />
          )
        },
        {
          value: '65',
          label: (
            <FormattedMessage id="controlpanel.my_account.information.options.midday" />
          )
        },
        {
          value: '66',
          label: (
            <FormattedMessage id="controlpanel.my_account.information.options.afternoon" />
          )
        },
        {
          value: '67',
          label: (
            <FormattedMessage id="controlpanel.my_account.information.options.indifferent" />
          )
        }
      ];

    default:
      return [];
  }
};
