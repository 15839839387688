import React from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';
import { singleOrderActions } from 'app/api/singleOrderActions';

export default function ActionsFormatter({ actions = [], id, tracking }) {
  const intl = useIntl();
  const {
    context: { readContextStorage }
  } = useDataTableContext();
  const { setModalsData } = readContextStorage();
  const navigate = useNavigate();

  return (
    <div className="actions-formatter">
      <Button
        icon={<Icon icon="eye" color={Colors.bbSecondary} />}
        onClick={() => navigate(`view/${id}`)}
        size="medium"
        tooltipText={intl.formatMessage({
          id: 'controlpanel.global.view_order'
        })}
      />
      {tracking && (
        <Button
          icon={
            <Icon
              icon="map-marker-alt"
              color={Colors.bbSecondary}
              prefix="fad"
            />
          }
          onClick={() => navigate(`/trackings?addFilters=true&search=${id}`)}
          size="medium"
          tooltipText={intl.formatMessage({
            id: 'controlpanel.global.view_tracking'
          })}
        />
      )}
      {map(actions, (action, i) => {
        switch (action) {
          case 'REPEAT':
            return (
              <Button
                key={i}
                icon={
                  <Icon icon="clone" color={Colors.bbSecondary} prefix="fad" />
                }
                onClick={async () => {
                  const request = await singleOrderActions({
                    id,
                    type: 'repeat-order'
                  });

                  if (request) {
                    setModalsData({
                      deleteModal: {
                        show: false,
                        orderId: null
                      },
                      repeatModal: {
                        show: true,
                        orderId: id
                      }
                    });
                  }
                }}
                size="medium"
                tooltipText={intl.formatMessage({
                  id: 'controlpanel.global.repeat_order'
                })}
              />
            );
          case 'CANCEL':
            return (
              <Button
                key={i}
                icon={
                  <Icon icon="trash" color={Colors.bbSecondary} prefix="fad" />
                }
                onClick={() =>
                  setModalsData({
                    deleteModal: {
                      show: true,
                      orderId: id
                    },
                    repeatModal: {
                      show: false,
                      orderId: null
                    }
                  })
                }
                size="medium"
                tooltipText={intl.formatMessage({
                  id: 'controlpanel.global.cancel_order'
                })}
              />
            );
          case 'TICKET':
            return (
              <Button
                key={i}
                icon={
                  <Icon
                    icon="envelope"
                    color={Colors.bbSecondary}
                    prefix="fad"
                  />
                }
                onClick={() =>
                  window.location.replace(
                    `${process.env.REACT_APP_BIGBUY_URL}contact`
                  )
                }
                size="medium"
                tooltipText={intl.formatMessage({
                  id: 'controlpanel.global.create_ticket'
                })}
              />
            );

          default:
            return null;
        }
      })}
    </div>
  );
}

ActionsFormatter.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired,
  tracking: PropTypes.bool.isRequired
};
