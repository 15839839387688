import { INITIAL_VALUES } from 'app/modules/Purse/config/filters';
import {
  getColumnsToDataTable,
  getDataTableStructure,
  getNoResultsConfig
} from 'app/modules/Purse/config/table';
import { useFiltersConfiguration } from 'app/modules/Purse/hooks/useFiltersConfiguration';

export const getPurseDataTableConfiguration = () => ({
  apiUrl: 'wallet-movements',
  noSelectRow: true,
  INITIAL_VALUES,
  useFiltersConfiguration,
  getColumnsToDataTable,
  getNoResultsConfig,
  getDataTableStructure
});
