import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Container } from 'react-bootstrap';
import ConnectorListCard from 'app/components/MarketplaceConnectorList/MarketplaceConnectorListCard';

import 'app/components/MarketplaceConnectorList/MarketplaceConnectorList.scss';

export default function MarketplaceConnectorList({ title, connectors }) {
  return (
    <Container className="marketplace-connector-list">
      <Row>
        <Col md={12} className="marketplace-connector-list__title">
          <h5 className="bold">{title}</h5>
        </Col>
      </Row>
      <Row>
        {connectors.map((connector) => (
          <ConnectorListCard
            key={connector.id}
            id={connector.id}
            image={connector?.image}
            mainText={connector?.mainText}
            purchased={connector?.purchased}
          />
        ))}
      </Row>
    </Container>
  );
}

MarketplaceConnectorList.propTypes = {
  title: PropTypes.string.isRequired,
  connectors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string),
      purchased: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string
    })
  ).isRequired
};
