import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { clone, map, remove } from 'lodash';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';
import { ALL, NONE } from 'app/modules/Trackings/utils';
import Dropdown from 'app/components/Dropdown';

import 'app/components/Checkbox/Checkbox.scss';

export const useDataTableSelectRow = ({
  selectedRows,
  setSelectedRows,
  keyField,
  dropdownOptions = null,
  dropdownLogic = null,
  entities = null,
  disableDropdown = false
}) => {
  const intl = useIntl();
  const [selectAll, setSelectAll] = useState(false);
  const [selectCondition, setSelectCondition] = useState(null);
  const [excludedRows, setExcludedRows] = useState([]);

  const options = useMemo(() => {
    if (dropdownOptions) return dropdownOptions;

    return [
      {
        id: 0,
        title: intl.formatMessage({ id: 'controlpanel.global.all' }),
        value: ALL
      },
      {
        id: 1,
        title: intl.formatMessage({ id: 'controlpanel.global.none' }),
        value: NONE
      }
    ];
  }, [dropdownOptions, intl]);

  const basicDropdownLogic = useMemo(
    () => (option) => {
      setSelectAll(false);
      setSelectCondition(null);
      if (option === ALL) setSelectAll(true);
      if (option === NONE) setSelectedRows([]);
    },
    [setSelectedRows]
  );

  const customDropdownLogic = useMemo(() => {
    if (dropdownLogic) {
      return (option) => {
        basicDropdownLogic(option);
        setSelectCondition(dropdownLogic(option));
      };
    }

    return basicDropdownLogic;
  }, [basicDropdownLogic, dropdownLogic]);

  useEffect(() => {
    if (!selectAll) return;

    const allEntities = entities.map(({ id }) => id);
    const notExcludedEntities = allEntities.filter(
      (id) => !excludedRows.includes(id)
    );

    setSelectedRows(notExcludedEntities);
  }, [entities, excludedRows, selectAll, setSelectedRows]);

  useEffect(() => {
    if (!selectCondition || selectAll) return;

    const { filterFunction } = selectCondition;

    const selectedEntities = entities.filter((entity) =>
      filterFunction(entity)
    );
    setSelectedRows(selectedEntities.map(({ id }) => id));
  }, [entities, excludedRows, selectAll, selectCondition, setSelectedRows]);

  useEffect(() => {
    setExcludedRows([]);
  }, [selectAll]);

  const manageExcludeRows = (type, ids) => {
    if (selectAll) {
      const cloneExcludeRows = clone(excludedRows);

      map(ids, (id) => {
        switch (type) {
          case 'remove':
            remove(cloneExcludeRows, (n) => n === id);
            break;

          case 'add':
            if (!cloneExcludeRows.includes(id)) {
              cloneExcludeRows.push(id);
            }
            break;

          default:
            break;
        }
      });

      setExcludedRows(cloneExcludeRows);
    }
  };

  const selectRow = {
    selectRow: {
      mode: 'checkbox',
      clickToSelect: false,
      selected: selectedRows,
      onSelect: (row, isSelect) => {
        const cloneSelectedsRows = clone(selectedRows);

        if (isSelect) {
          cloneSelectedsRows.push(row[keyField]);
          manageExcludeRows('remove', [row[keyField]]);
        } else {
          remove(cloneSelectedsRows, (n) => n === row[keyField]);
          manageExcludeRows('add', [row[keyField]]);
        }

        setSelectedRows(cloneSelectedsRows);
      },
      selectionRenderer: ({ mode, checked }) => (
        <Checkbox mode={mode} checked={checked} />
      ),
      onSelectAll: (isSelect, rows) => {
        const cloneSelectedsRows = clone(selectedRows);
        const removeIds = [];
        const addIds = [];
        if (isSelect) {
          map(rows, (row) => {
            cloneSelectedsRows.push(row[keyField]);
            removeIds.push(row[keyField]);
          });
          manageExcludeRows('remove', removeIds);
        } else {
          map(rows, (row) => {
            remove(cloneSelectedsRows, (n) => n === row[keyField]);
            addIds.push(row[keyField]);
          });
          manageExcludeRows('add', addIds);
        }
        setSelectedRows(cloneSelectedsRows);
      },
      selectionHeaderRenderer: ({ mode, checked }) =>
        disableDropdown ? (
          <Checkbox mode={mode} checked={checked} />
        ) : (
          <CheckboxDropdown
            mode={mode}
            checked={checked}
            options={options}
            logic={customDropdownLogic}
          />
        )
    }
  };

  return { selectRow, selectAll, excludedRows, selectCondition, setSelectAll };
};

const Checkbox = ({ mode, checked }) => (
  <div className="form-check form-check-custom form-check-solid bb-checkbox">
    <input
      className="form-check-input"
      type={mode}
      checked={checked}
      readOnly
    />
  </div>
);

Checkbox.propTypes = {
  mode: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired
};

const CheckboxDropdown = ({ options = [], logic = null, mode, checked }) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className="form-check form-check-custom form-check-solid bb-checkbox"
      role="none"
    >
      <Checkbox mode={mode} checked={checked} />
      <SVG
        src={toAbsoluteUrl('/media/svg/bigbuy/large/caret-down.svg')}
        className="dropdown-tooltip"
        onClick={(e) => {
          e.stopPropagation();
          setShow((prev) => !prev);
        }}
      />
      <Dropdown
        show={show}
        options={options}
        onClick={(e) => e.stopPropagation()}
        onSelected={(value) => {
          setShow(false);
          return logic(value);
        }}
      />
    </div>
  );
};

CheckboxDropdown.propTypes = {
  mode: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      value: PropTypes.node
    })
  ),
  logic: PropTypes.func
};
