import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getNotifications } from 'app/api/notifications';

export const NotificationsServiceContext = createContext({});

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  if (isFirstLoad === true) {
    const controller = new AbortController();
    (async () => {
      const data = await getNotifications(controller);
      setNotifications(getNotificationsStructure(data));
    })();

    setIsFirstLoad(false);
  }

  useEffect(() => {
    setInterval(() => {
      const controller = new AbortController();
      (async () => {
        const data = await getNotifications(controller);
        setNotifications(getNotificationsStructure(data));
      })();
    }, 60000);
    return () => clearInterval();
  }, []);

  return (
    <NotificationsServiceContext.Provider
      value={{ notifications, setNotifications }}
    >
      {children}
    </NotificationsServiceContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const getNotificationsStructure = (data) => {
  const alerts = data?.filter(
    (obj) =>
      (obj?.attributes?.type === 'INFO' && obj?.attributes?.read === false) ||
      (obj?.attributes?.type === 'WARNING' &&
        obj?.attributes?.read === false) ||
      (obj?.attributes?.type === 'ERROR' && obj?.attributes?.read === false)
  );
  const notifications = data?.filter(
    (obj) => obj?.attributes?.type === 'NOTIFICATION'
  );
  return {
    notifications,
    alerts
  };
};
