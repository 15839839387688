import { authActionTypes } from 'app/modules/Auth/_redux/AuthConstants';

export const login = (user) => ({
  type: authActionTypes.Login,
  payload: { user }
});

export const logout = () => ({
  type: authActionTypes.Logout
});

export const setUser = (user) => ({
  type: authActionTypes.SetUser,
  payload: { user }
});

export const setAccessToken = (user) => ({
  type: authActionTypes.SetAccessToken,
  payload: { user }
});

export const setWalletLimit = (walletLimit) => ({
  type: authActionTypes.SetWalletLimit,
  payload: walletLimit
});

export const setProductsInCart = (numberProducts) => ({
  type: authActionTypes.SetProductsInCart,
  payload: numberProducts
});
