// eslint-disable-next-line camelcase
import ps_country from 'app/modules/MyAccount/pages/Billing/utils/ps_country.json';

const checkISOCode = (checkingCode, country) => {
  const chunk = checkingCode.slice(0, country.length);
  const newString = checkingCode.slice(country.length);
  return {
    result: chunk === country,
    newString
  };
};

const checkNumber = (checkingCode) => {
  const chunk = checkingCode.slice(0, 1);
  const newString = checkingCode.slice(1);
  return {
    result: Boolean(chunk.match(/\d/g)),
    newString
  };
};

const checkLetter = (checkingCode) => {
  const chunk = checkingCode.slice(0, 1);
  const newString = checkingCode.slice(1);
  return {
    result: Boolean(chunk.match(/[A-Za-z]/g)),
    newString
  };
};

const checkSpace = (checkingCode) => {
  const chunk = checkingCode.slice(0, 1);
  const newString = checkingCode.slice(1);
  return {
    result: chunk === ' ',
    newString
  };
};

const checkDash = (checkingCode) => {
  const chunk = checkingCode.slice(0, 1);
  const newString = checkingCode.slice(1);
  return {
    result: chunk === '-',
    newString
  };
};

const CHECKERS_MAP = {
  C: checkISOCode,
  N: checkNumber,
  L: checkLetter,
  ' ': checkSpace,
  '-': checkDash
};

export const testZipCodes = (info) => {
  const { postCode: zipCode, country } = info.parent;

  const countryJson = ps_country.find((el) => el.iso_code === country);
  const format = countryJson?.zip_code_format;

  if (!format || !format?.length) {
    return true;
  }

  let checkingCode = zipCode;
  const results = [];

  Array.from(format).forEach((key) => {
    const { result, newString } = CHECKERS_MAP[key](checkingCode, country);
    checkingCode = newString;
    results.push(result);
  });

  return results.every((res) => res === true);
};
