import React, { useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';

import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';
import { useSubheader } from 'app/context/SubheaderContext';

import 'app/pages/ErrorPage/ErrorPage.scss';

export default function ErrorPage() {
  const subheader = useSubheader();
  const { setTitle, setIcon } = subheader;

  useLayoutEffect(() => {
    setTitle('Error 404');
    setIcon({ icon: 'exclamation-square' });
  }, [setTitle, setIcon]);

  return (
    <div className="d-flex flex-column flex-root">
      <div
        id="error-page"
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{ textAlign: 'center' }}
      >
        <img
          src={toAbsoluteUrl('/media/error/bb-bg-error.png')}
          alt="Error page"
        />
        <p className="big bold">
          {useIntl().formatMessage({ id: 'controlpanel.global.error_404' })}
        </p>
      </div>
    </div>
  );
}
