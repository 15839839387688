import { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { useIntl } from 'react-intl';

import { getCookieValue } from 'app/utils/cookie';
import { genericRequest } from 'app/api';
import { login } from 'app/modules/Auth/_redux/AuthActions';
import { DIALOG_COOKIE, SESSION_COOKIE } from 'app/utils/constants';

const AuthInit = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const { locale } = intl;

  const onUnauthorized = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_BIGBUY_URL}${locale}/tiendab2b.html`;
  }, [locale]);

  useEffect(() => {
    const cookie = getCookieValue(SESSION_COOKIE);
    if (!cookie) {
      onUnauthorized();
    }
  });
  const authLogin = useCallback(async () => {
    try {
      const user = await genericRequest({ url: 'customers/me' });
      dispatch(login(user.data));
      const dialogCookie = Cookies.get(DIALOG_COOKIE);
      if (!dialogCookie) {
        Cookies.set(DIALOG_COOKIE, 'default', { expires: 4 });
      }
    } catch (e) {
      onUnauthorized();
    }
  }, [dispatch, onUnauthorized]);

  useEffect(() => {
    authLogin();
    return () => authLogin();
  }, [authLogin]);

  return children;
};

export default AuthInit;
