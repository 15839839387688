import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import DefaultLanguage from 'app/modules/MyAccount/pages/Notifications/components/DefaultLanguage';
import SectionMessage from 'app/modules/MyAccount/pages/Notifications/components/SectionMessage';
import Switch from 'app/components/Switch';

import 'app/modules/MyAccount/pages/Notifications/components/Section.scss';

export default function Section({
  currentLanguage,
  showCurrentLanguage = false,
  sectionTranslationKey,
  formikKey
}) {
  const intl = useIntl();
  const formik = useFormikContext();

  return (
    <>
      <Row className="bb-notification-panel__section">
        <Col lg={3} />
        <Col lg={7} className="bb-notification-panel__section-header">
          <p className="medium bb-notification-panel--big-font">
            {intl.formatMessage({
              id: `controlpanel.my_account.notifications.${sectionTranslationKey}_title`
            })}
          </p>
        </Col>
        <Col lg={2} />
      </Row>
      <Row>
        <Col lg={3} className="bb-notification-panel__section-subtitle">
          <p className="bb-notification-panel--regular-font">
            {intl.formatMessage({
              id: `controlpanel.my_account.notifications.${sectionTranslationKey}_subtitle`
            })}
          </p>
        </Col>
        <Col lg={9}>
          <Switch
            id={`${formikKey}.isActive`}
            name={`${formikKey}.isActive`}
            checked={formik?.values?.[formikKey]?.isActive}
            onChange={({ id, checked }) => formik.setFieldValue(id, checked)}
            labelComponent={
              <p className="bb-notification-panel--regular-font">
                {intl.formatMessage({ id: 'controlpanel.global.yes' })}
              </p>
            }
            formik={formik}
          />
          {showCurrentLanguage ? (
            <DefaultLanguage currentLanguage={currentLanguage} />
          ) : (
            <SectionMessage sectionTranslationKey={sectionTranslationKey} />
          )}
        </Col>
        <Col lg={2} />
      </Row>
    </>
  );
}

Section.propTypes = {
  currentLanguage: PropTypes.shape().isRequired,
  showCurrentLanguage: PropTypes.bool,
  sectionTranslationKey: PropTypes.string.isRequired,
  formikKey: PropTypes.string.isRequired
};
