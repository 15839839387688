import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { Row, Col } from 'react-bootstrap';

import Button from 'app/components/Button';
import Popup from 'app/components/Popup/Popup';

import 'app/modules/Shops/pages/Shopify/components/Footer.scss';

export default function Footer() {
  const intl = useIntl();
  const { submitForm, values, initialValues } = useFormikContext();
  const [showAlertPopUp, setShowAlertPopUp] = useState(false);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);

  const handleClick = () => {
    if (
      values?.catalogue !== initialValues?.catalogue ||
      values?.theme !== initialValues?.theme
    ) {
      setShowAlertPopUp(true);
    } else {
      submitForm();
    }
  };

  let text = intl.formatMessage({
    id: 'controlpanel.shops.change_theme_and_catalog_text'
  });
  let title = intl.formatMessage({
    id: 'controlpanel.shops.change_theme_and_catalog_title'
  });

  if (
    values?.catalogue !== initialValues?.catalogue &&
    values?.theme === initialValues?.theme
  ) {
    title = intl.formatMessage({
      id: 'controlpanel.shops.change_catalog_title'
    });
    text = intl.formatMessage({
      id: 'controlpanel.shops.change_catalog_text'
    });
  } else if (
    values?.catalogue === initialValues?.catalogue &&
    values?.theme !== initialValues?.theme
  ) {
    title = intl.formatMessage({
      id: 'controlpanel.shops.change_theme_title'
    });
    text = intl.formatMessage({
      id: 'controlpanel.shops.change_theme_text'
    });
  }

  const handleClose = () => {
    setShowAlertPopUp(false);
  };

  return (
    <Row style={{ margin: 0 }}>
      <Popup
        className="shopify-form__popup-warning-changes"
        show={showAlertPopUp}
        handleClose={handleClose}
        header={<p className="bold">{title}</p>}
        body={<p>{text}</p>}
        footer={
          <div className="shopify-form__popup-warning-changes-footer">
            <Button
              text={intl.formatMessage({ id: 'controlpanel.global.cancel' })}
              size="medium"
              onClick={() => setShowAlertPopUp(false)}
            />
            <Button
              text={intl.formatMessage({
                id: 'controlpanel.global.yes_modify'
              })}
              type="primary"
              size="medium"
              onClick={() => {
                setSendButtonDisabled(true);
                submitForm();
              }}
              disabled={sendButtonDisabled}
            />
          </div>
        }
      />
      <Col md={12} className="shopify-form__footer">
        <Button
          text={intl.formatMessage({ id: 'controlpanel.global.send_data' })}
          type="primary"
          size="medium"
          onClick={handleClick}
        />
      </Col>
    </Row>
  );
}
