import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import Spinner from 'app/components/Spinner';

export default function PrintOrderButton({ orderId }) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const { bbSecondary } = Colors;

  return (
    <Button
      text={intl.formatMessage({ id: 'controlpanel.global.print_order' })}
      size="medium"
      typeButton="button"
      onClick={async () => {
        setLoading(true);
        window.location.href = `${process.env.REACT_APP_API_URL}orders/${orderId}/pdf-order`;
        setLoading(false);
      }}
      icon={
        loading === false ? (
          <Icon icon="print" color={bbSecondary} />
        ) : (
          <Spinner />
        )
      }
    />
  );
}

PrintOrderButton.propTypes = { orderId: PropTypes.string.isRequired };
