import React from 'react';
import PropTypes from 'prop-types';

import { PROP_TYPES } from 'app/components/Icon/utils/types';
import Icon from 'app/components/Icon';

export default function AlertDismissButton({
  icon = null,
  handleClose = null,
  className = null,
  color = null
}) {
  const handleKeyDown = ({ code }) => {
    if (code === 'Escape' || code === 'Enter' || code === 'NumpadEnter')
      handleClose();
  };
  return (
    <div
      role="button"
      tabIndex={0}
      className={className}
      onClick={handleClose}
      onKeyDown={handleKeyDown}
    >
      <Icon icon="xmark" size="lg" color={color} {...icon} />
    </div>
  );
}

AlertDismissButton.propTypes = {
  icon: PropTypes.shape(PROP_TYPES),
  handleClose: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string
};
