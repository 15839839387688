import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getInputClasses } from 'app/components/Input/input.utils';

export default function InputPrepend({
  touched,
  disabled = false,
  textPrepend = null,
  error = null
}) {
  const classNameParagraph = classNames(
    'input-group-text regular',
    getInputClasses(error, touched),
    {
      disabled
    }
  );

  return (
    <div className="input-group-prepend">
      <p className={classNameParagraph}>{textPrepend}</p>
    </div>
  );
}

InputPrepend.propTypes = {
  touched: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  textPrepend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.string
};
