import React, { useState } from 'react';

import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import AttachInvoiceForm from 'app/modules/OrderView/components/AttachInvoiceForm';
import AttachReceiptForm from 'app/modules/OrderView/components/AttachReceiptForm';
import ButtonDropdown from 'app/components/ButtonDropdown';
import Icon from 'app/components/Icon';
import useDataProviderContext from 'app/hooks/useDataProviderContext';

export default function AttachFilesModal() {
  const intl = useIntl();
  const { order } = useDataProviderContext();
  const { paymentMethod, status } = order?.attributes;
  const [showAttachReceipt, setShowAttachReceipt] = useState(false);
  const [showAttachInvoice, setShowAttachInvoice] = useState(false);
  const options = [];

  // Opciones para adjuntar justificante de factura de exportación
  if (status === 'PENDING_INVOICING') {
    options.push({
      id: 0,
      title: intl.formatMessage({ id: 'controlpanel.global.attach_invoice' }),
      value: 'invoice'
    });
  }

  // Opciones para adjuntar justificante de pago
  if (
    paymentMethod === 'BANKWIRE' &&
    (status === 'PENDING_PAYMENT' || status === 'PENDING_INVOICING')
  ) {
    options.push({
      id: 1,
      title: intl.formatMessage({ id: 'controlpanel.global.attach_receipt' }),
      value: 'receipt'
    });
  }

  if (options.length === 0) {
    return false;
  }

  return (
    <div className="attach-files-modal">
      <ButtonDropdown
        dropdownOptions={{
          options,
          onSelected: (id) => {
            if (id === 'invoice') {
              setShowAttachInvoice(true);
              setShowAttachReceipt(false);
            } else {
              setShowAttachInvoice(false);
              setShowAttachReceipt(true);
            }
          }
        }}
        buttonOptions={{
          text: intl.formatMessage({ id: 'controlpanel.global.attach' }),
          type: 'default',
          icon: <Icon icon="file-import" color={Colors.bbSecondary} />
        }}
      />
      <AttachReceiptForm
        handleClose={() => setShowAttachReceipt(false)}
        show={showAttachReceipt}
      />
      <AttachInvoiceForm
        handleClose={() => setShowAttachInvoice(false)}
        show={showAttachInvoice}
      />
    </div>
  );
}
