import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { genericRequest } from 'app/api';
import { setWalletLimit } from 'app/modules/Auth/_redux/AuthActions';
import Button from 'app/components/Button';
import NotificationFormActive from 'app/modules/Purse/components/NotificationForm/NotificationFormActive';
import NotificationFormDisabled from 'app/modules/Purse/components/NotificationForm/NotificationFormDisabled';
import Popup from 'app/components/Popup';

export default function NotificationForm() {
  const intl = useIntl();
  const user = useSelector((state) => state);
  const dispatch = useDispatch();

  const [notificationAmount, setNotificationAmount] = useState(
    user.auth.user.attributes.walletLimit
  );

  const initialConfigPopup = {
    showPopup: false,
    popupTitle: '',
    popupMsg: ''
  };
  const [popupConfig, setPopupConfig] = useState(initialConfigPopup);

  useEffect(() => {
    if (user.auth.user.attributes.walletLimit !== notificationAmount) {
      dispatch(setWalletLimit(notificationAmount));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationAmount]);

  return (
    <div className="purse-card-notifications__active">
      <Popup
        handleClose={() => setPopupConfig(initialConfigPopup)}
        show={popupConfig.showPopup}
        header={<p className="bold">{popupConfig.popupTitle}</p>}
        body={<p>{popupConfig.popupMsg}</p>}
        footer={
          <Button
            size="big"
            text={intl.formatMessage({ id: 'controlpanel.global.accept' })}
            type="primary"
            onClick={() => setPopupConfig(initialConfigPopup)}
          />
        }
      />
      {notificationAmount === null ? (
        <NotificationFormActive
          notificationAmount={notificationAmount}
          updateWalletMovements={updateWalletMovements}
          setNotificationAmount={setNotificationAmount}
          setPopupConfig={setPopupConfig}
        />
      ) : (
        <NotificationFormDisabled
          notificationAmount={notificationAmount}
          updateWalletMovements={updateWalletMovements}
          setNotificationAmount={setNotificationAmount}
          setPopupConfig={setPopupConfig}
        />
      )}
    </div>
  );
}

const updateWalletMovements = async (amount, enabled) => {
  const response = await genericRequest({
    url: 'wallet-movements/actions',
    method: 'post',
    data: {
      data: {
        type: 'wallet-action',
        attributes: {
          type: 'wallet-notification',
          amount,
          enabled
        }
      }
    },
    getHeaders: true
  });

  return response;
};
