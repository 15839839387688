import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { size } from 'lodash';
import { useIntl } from 'react-intl';
import { useLang } from 'app/context/i18n';

import { Colors } from 'app/utils/colors';
import { getCSV } from 'app/api';
import { MAX_DOWNLOAD_FILES } from 'app/utils/constants';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import Popup from 'app/components/Popup';
import Spinner from 'app/components/Spinner';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

export default function PopupDownloadCSV({ fileType }) {
  const intl = useIntl();
  const lang = useLang();
  const [loadingExport, setLoadingExport] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const {
    datatable: {
      rows: { excludedRows, selectAll, selectCondition, selectedRows },
      totalSize
    },
    filters: { filters },
    context: { readContextStorage }
  } = useDataTableContext();

  const { wishlistId } = readContextStorage('wishlistData');

  if (selectCondition?.filterName === 'read') {
    filters.is_read = 'true';
  }
  if (selectCondition?.filterName === 'notRead') {
    filters.is_read = 'false';
  }

  const countSelected = useMemo(() => {
    if (selectAll) {
      const totalResults = totalSize - size(excludedRows);

      if (totalResults > MAX_DOWNLOAD_FILES) {
        return MAX_DOWNLOAD_FILES;
      }

      return totalResults;
    }

    if (size(selectedRows) > MAX_DOWNLOAD_FILES) {
      return MAX_DOWNLOAD_FILES;
    }

    return size(selectedRows);
  }, [selectedRows, selectAll, excludedRows, totalSize]);

  return (
    <div>
      <Button
        type="outline"
        text={intl.formatMessage({
          id: 'controlpanel.global.export_csv'
        })}
        size="medium"
        icon={<Icon icon="arrow-alt-to-bottom" color={Colors.bbGrey2} />}
        disabled={size(selectedRows) === 0}
        onClick={() => setShow(true)}
        tooltipText={intl.formatMessage(
          {
            id: 'controlpanel.global.export_csv.tooltip'
          },
          { number: MAX_DOWNLOAD_FILES }
        )}
      />
      <Popup
        show={show}
        header={
          <div className="bb-popup-repeat-order__header">
            <p className="bold">
              {intl.formatMessage({
                id: 'controlpanel.global.export_csv.title'
              })}
            </p>
          </div>
        }
        body={
          <div className="bb-popup-repeat-order__body">
            <p>
              {intl.formatMessage(
                {
                  id: 'controlpanel.global.export_csv.body'
                },
                {
                  number: countSelected || ''
                }
              )}
            </p>
          </div>
        }
        footer={
          <div className="bb-popup-repeat-order__footer">
            <Button
              text={intl.formatMessage({
                id: 'controlpanel.global.cancel'
              })}
              type="default"
              size="big"
              onClick={handleClose}
            />
            <Button
              text={intl.formatMessage({
                id: 'controlpanel.global.download'
              })}
              type="primary"
              size="big"
              onClick={async () => {
                await getCSV({
                  fileType,
                  includedIds: selectedRows,
                  excludedIds: excludedRows,
                  selectAll,
                  filters: { ...filters, wishlistId },
                  lang,
                  setLoading: setLoadingExport
                });
                handleClose();
              }}
              icon={
                loadingExport ? (
                  <Spinner />
                ) : (
                  <Icon icon="arrow-alt-to-bottom" color={Colors.bbGrey2} />
                )
              }
            />
          </div>
        }
        handleClose={handleClose}
      />
    </div>
  );
}

PopupDownloadCSV.propTypes = {
  fileType: PropTypes.string.isRequired
};
