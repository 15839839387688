import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import Chart from 'react-apexcharts';

import { Colors } from 'app/utils/colors';
import NoResultsFound from 'app/components/NoResultsFound';

import 'app/components/ApexChart/ApexChart.scss';

export default function ApexChart({
  title,
  data = null,
  background = Colors.bbWhite,
  type
}) {
  const intl = useIntl();
  return (
    <div className="bb-chart" style={{ backgroundColor: background }}>
      <h5 className="bold">{title}</h5>
      {data ? (
        <Chart options={data.options} series={data.series} type={type} />
      ) : (
        <NoResultsFound
          icon="chart-mixed"
          text={intl.formatMessage({
            id: 'controlpanel.dashboard.chart_no_results'
          })}
        />
      )}
    </div>
  );
}

ApexChart.propTypes = {
  title: PropTypes.string.isRequired,
  background: PropTypes.string,
  data: PropTypes.shape({
    series: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.number)
      })
    ).isRequired,
    options: PropTypes.shape({}).isRequired
  }),
  type: PropTypes.oneOf(['bar', 'pie']).isRequired
};
