import React, { useLayoutEffect } from 'react';

import { KTUtil } from 'app/layout/assets/js/components/util';
import KTLayoutHeaderTopbar from 'app/layout/assets/js/base/header-topbar';
import KTLayoutAside from 'app/layout/assets/js/base/aside';
import KTLayoutAsideToggle from 'app/layout/assets/js/base/aside-toggle';
import KTLayoutAsideMenu from 'app/layout/assets/js/base/aside-menu';
import KTLayoutScrolltop from 'app/layout/assets/js/extended/scrolltop';

export function LayoutInit() {
  useLayoutEffect(() => {
    // Initialization
    KTUtil.ready(function () {
      // Init Header Topbar For Mobile Mode
      KTLayoutHeaderTopbar.init('kt_header_mobile_topbar_toggle');

      // Init Aside
      KTLayoutAside.init('kt_aside');

      // Init Aside Menu Toggle
      KTLayoutAsideToggle.init('kt_aside_toggle');
      //
      // Init Aside Menu
      KTLayoutAsideMenu.init('kt_aside_menu');

      // Init Scrolltop
      KTLayoutScrolltop.init('kt_scrolltop');
    });
  }, []);
  return <div />;
}
