import { React } from 'react';

import {
  FormatActions,
  FormatCatalogue,
  FormatGeneric,
  FormatIcon,
  FormatPlatform,
  FormatStatus,
  FormatTheme
} from 'app/modules/Shops/utils/formatters';
import { sortCaret, headerSortingStyle } from 'app/components/DataTable/utils';
import { DateFormatter } from 'app/utils/columnsFormatters/DateFormatter';

const getColumnsToDataTable = (intl) => [
  {
    dataField: 'icon',
    text: '',
    hidden: false
  },
  {
    dataField: 'id',
    text: intl.formatMessage({
      id: 'controlpanel.global.shop_id'
    }),
    sort: false,
    hidden: false
  },
  {
    dataField: 'shopName',
    text: intl.formatMessage({
      id: 'controlpanel.shops.tabledata.title.shop_name'
    }),

    sort: true,
    hidden: false,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'platform',
    text: intl.formatMessage({
      id: 'controlpanel.shops.tabledata.title.platform'
    }),
    sort: true,
    hidden: false,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'template',
    text: intl.formatMessage({
      id: 'controlpanel.shops.tabledata.title.template'
    }),
    sort: true,
    hidden: false,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'catalogue',
    text: intl.formatMessage({
      id: 'controlpanel.shops.tabledata.title.catalogue'
    }),
    sort: true,
    hidden: false,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'status',
    text: intl.formatMessage({
      id: 'controlpanel.shops.tabledata.title.status'
    }),
    sort: true,
    hidden: false,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'updateDate',
    text: intl.formatMessage({
      id: 'controlpanel.shops.tabledata.title.updateDate'
    }),
    sort: true,
    hidden: false,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'actions',
    text: intl.formatMessage({
      id: 'controlpanel.shops.tabledata.title.actions'
    }),
    sort: false,
    hidden: false
  }
];

const getNoResultsConfig = (intl) => ({
  text: intl.formatMessage({ id: 'controlpanel.global.no_results_founds' }),
  icon: 'location-dot'
});

const getDataTableStructure = (data) =>
  data.map(
    ({
      id,
      type: platform,
      attributes: {
        shopName = null,
        theme = null,
        catalogue = null,
        statusKey = null,
        updateDate = null,
        status = null
      }
    }) => ({
      id,
      icon: <FormatIcon statusKey={statusKey} />,
      shopName: <FormatGeneric statusKey={statusKey} text={shopName} />,
      platform: <FormatPlatform platform={platform} statusKey={statusKey} />,
      template: <FormatTheme theme={theme} statusKey={statusKey} />,
      catalogue: (
        <FormatCatalogue statusKey={statusKey} catalogue={catalogue} />
      ),
      status: <FormatStatus statusKey={statusKey} status={status} />,
      updateDate: <DateFormatter date={updateDate} />,
      actions: <FormatActions statusKey={statusKey} id={id} />
    })
  );

export const getShopsTableConfiguration = () => ({
  apiUrl: 'shops360',
  noSelectRow: true,
  getColumnsToDataTable,
  getNoResultsConfig,
  getDataTableStructure,
  extraTableConfiguration: {
    rowClasses
  }
});

export const rowClasses = (row) => {
  const statusRow = row?.status?.props?.statusKey;

  if (statusRow === 'ENTER_DETAILS' || statusRow === 'WRONG_REQUIREMENTS') {
    return 'red';
  }
  return '';
};
