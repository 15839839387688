import { find } from 'lodash';
import { useSelector } from 'react-redux';

export default function useGetPermisionsSection(section) {
  const { user } = useSelector((state) => state.auth);

  const sectionPermisions = find(
    user?.attributes?.permissions,
    (permissions) => permissions?.key === section
  );

  if (sectionPermisions?.allowed === true) {
    return true;
  }
  return false;
}
