import React, { useState, useEffect, useLayoutEffect } from 'react';

import { filter, size } from 'lodash';
import { useIntl } from 'react-intl';

import Card from 'app/components/Card';
import { useSubheader } from 'app/context/SubheaderContext';
import Body from 'app/modules/Orders/components/Body';
import CancelOrder from 'app/modules/Orders/components/Modals/CancelOrder';
import RepeatOrder from 'app/modules/Orders/components/Modals/RepeatOrder';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';
import useNotificationsServiceHook from 'app/hooks/useNotificationsService.hook';
import UserNotificationAlert from 'app/components/UserNotificationAlert';

export default function Orders() {
  const {
    filters: { resetFilters },
    context: { saveInContextStorage }
  } = useDataTableContext();
  const intl = useIntl();
  const subheader = useSubheader();
  const { setTitle, setIcon, setBreadcrumbs } = subheader;

  useLayoutEffect(() => {
    setTitle(intl.formatMessage({ id: 'controlpanel.asidemenu.orders' }));
    setIcon({ icon: 'clipboard' });
    setBreadcrumbs([]);
  }, [intl, setTitle, setBreadcrumbs, setIcon]);

  const defaultModalConfig = {
    deleteModal: {
      show: false,
      orderId: null
    },
    repeatModal: {
      show: false,
      orderId: null
    }
  };

  const [modalsData, setModalsData] = useState(defaultModalConfig);

  useEffect(() => {
    saveInContextStorage('modalsData', defaultModalConfig);
    saveInContextStorage('setModalsData', setModalsData);

    return () => {
      saveInContextStorage('setModalsData', defaultModalConfig);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveInContextStorage]);

  const {
    notifications: { alerts }
  } = useNotificationsServiceHook();

  const alertsFiltered = filter(
    alerts,
    (alert) => alert?.attributes?.section === 'ORDER'
  );

  return (
    <>
      {size(alertsFiltered) > 0 && (
        <UserNotificationAlert alerts={alertsFiltered} />
      )}
      <Card
        className="orders"
        body={
          <>
            <CancelOrder
              modalDeleteData={modalsData.deleteModal}
              setModalDeleteData={setModalsData}
              handleUpdateOrdersList={resetFilters}
            />
            <RepeatOrder
              modalData={modalsData.repeatModal}
              setModalData={setModalsData}
              handleUpdateOrdersList={resetFilters}
            />
            <Body />
          </>
        }
      />
    </>
  );
}
