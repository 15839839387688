import React from 'react';
import { Modal as BoostrapModal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import 'app/components/Modal/Modal.scss';

export default function Modal({
  show = false,
  body = null,
  buttons = null,
  centered = false,
  size = 'md',
  title
}) {
  return (
    <div className="bb-modal">
      <BoostrapModal
        show={show}
        size={size}
        centered={centered}
        className="bb-modal__container"
      >
        <BoostrapModal.Header className="bb-modal__container__header">
          <BoostrapModal.Title>
            <p className="big bold">{title}</p>
          </BoostrapModal.Title>
        </BoostrapModal.Header>
        <BoostrapModal.Body className="bb-modal__container__body">
          {body}
        </BoostrapModal.Body>
        <BoostrapModal.Footer className="bb-modal__container__footer">
          {buttons}
        </BoostrapModal.Footer>
      </BoostrapModal>
    </div>
  );
}

Modal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  body: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  centered: PropTypes.bool,
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm'])
};
