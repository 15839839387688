import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import NotificationBadge from 'app/components/NotificationBadge';

import 'app/modules/Wishlist/config/table/formatters/StockFormatter/StockFormatter.scss';

export default function StockFormatter({ stock }) {
  const intl = useIntl();
  const { active, quantity } = stock;
  let text = intl.formatMessage({ id: 'controlpanel.global.not_available' });

  if (active) {
    text = quantity
      ? `${intl.formatMessage({
          id: 'controlpanel.global.yes'
        })} - ${quantity} ${intl.formatMessage({
          id: 'controlpanel.global.units'
        })}`
      : `${intl.formatMessage({
          id: 'controlpanel.global.yes'
        })}`;
  }

  return (
    <div className="stock-formatter">
      <NotificationBadge color={active ? 'green' : 'red'} />
      <p
        className="small"
        style={{ color: active ? Colors.bbGreen : Colors.bbRed }}
      >
        {text}
      </p>
    </div>
  );
}

StockFormatter.propTypes = {
  stock: PropTypes.shape({
    active: PropTypes.bool,
    quantity: PropTypes.number
  }).isRequired
};
