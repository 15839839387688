import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import Time from 'app/components/ShopShippingProcess/components/Time';

import 'app/components/ShopShippingProcess/components/ShopsTimeline/ShopsTimeline.scss';

export default function ShopsTimeline({ times }) {
  const intl = useIntl();

  return (
    <div className="bb-timeline">
      {times.length === 0 ? (
        <p>
          {intl.formatMessage({
            id: 'controlpanel.walletcard.empty_movements'
          })}
        </p>
      ) : (
        Array.isArray(times) &&
        times.map((time, index) => (
          <Time
            key={`${index}-time`}
            leftSide={time?.leftSide}
            rightSide={time?.rightSide}
            circleColor={time?.circleColor}
            lastChild={index === times.length - 1}
          />
        ))
      )}
    </div>
  );
}

const colors = PropTypes.oneOf([
  'red',
  'green',
  'yellow',
  'blue',
  'purple',
  'grey'
]);

const TimesItem = PropTypes.shape({
  lastChild: PropTypes.bool,
  leftSide: PropTypes.shape({
    dateTitle: PropTypes.string.isRequired,
    dateSubtitle: PropTypes.string,
    dateColor: colors
  }),
  rightSide: PropTypes.shape({
    eventTitle: PropTypes.string.isRequired,
    eventSubtitle: PropTypes.string,
    eventColor: colors
  }),
  circleColor: colors
});

ShopsTimeline.propTypes = {
  times: PropTypes.arrayOf(TimesItem).isRequired
};
