import React, { useEffect, useState } from 'react';

import { map, size } from 'lodash';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { DOWNLOADS_RESOURCES_PERMITED } from 'app/modules/Downloads/utils';
import { wrongPackContent } from 'app/components/ModalWrongPack';
import Card from 'app/components/Card/Card';
import DataTableComponents from 'app/components/DataTableComponents';
import Filters from 'app/modules/Downloads/components/Filters';
import Pagination from 'app/components/DataTableComponents/components/Pagination/Pagination';
import useApi from 'app/components/DataTableComponents/hooks/useApi';
import useDataTableComponentsContext from 'app/components/DataTableComponents/hooks/useDataTableComponentsContext';
import ImageView from 'app/modules/Downloads/components/ImageView';

import 'app/modules/Downloads/Pages/ResourcesMKT/ResourcesMKT.scss';

export default function ResourcesMKT() {
  useApi();
  const intl = useIntl();
  const { context } = useDataTableComponentsContext();
  const [components, setComponents] = useState([]);

  const {
    user: {
      attributes: { packId }
    }
  } = useSelector((state) => state.auth);

  const downloadPermited = DOWNLOADS_RESOURCES_PERMITED.includes(packId);

  useEffect(() => {
    if (size(context?.api?.data) > 0) {
      const smallComponents = [];
      const mediumComponents = [];
      const bigComponents = [];

      map(context?.api?.data, (data) => {
        const {
          attributes: { width }
        } = data;

        let imgSize = 'small';

        if (width > 800) {
          imgSize = 'big';
        }

        if (width > 350 && width < 800) {
          imgSize = 'medium';
        }

        const component = (
          <ImageView
            key={data?.id}
            id={data?.id}
            imgSrc={data?.attributes.image}
            size={imgSize}
          />
        );

        switch (imgSize) {
          case 'big':
            bigComponents.push(component);
            break;
          case 'medium':
            mediumComponents.push(component);
            break;
          default:
            smallComponents.push(component);
            break;
        }
      });

      setComponents([
        ...bigComponents,
        ...mediumComponents,
        ...smallComponents
      ]);
    } else {
      setComponents([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.api?.data, context?.selected]);

  if (!downloadPermited) {
    return (
      <Card
        title={intl.formatMessage({
          id: 'controlpanel.downloads.mkt_resources'
        })}
        body={
          <div className="wrong-pack" style={{ marginTop: '15px' }}>
            <div className="wrong-pack__body">{wrongPackContent(intl)}</div>
          </div>
        }
      />
    );
  }

  return (
    <Card
      title={intl.formatMessage({
        id: 'controlpanel.downloads.mkt_resources'
      })}
      body={
        <>
          <Filters showTheme />
          <div className="image-view-container">
            <DataTableComponents
              components={components}
              loading={context?.api?.loading}
              pagination={<Pagination />}
            />
          </div>
        </>
      }
    />
  );
}
