import React from 'react';
import PropTypes from 'prop-types';

import Badge from 'app/components/Badge';
import useDevolutionsStatus from 'app/hooks/useDevolutionsStatus.hook';

export const FormatStatus = ({ statusKey }) => {
  const devolutionsStatus = useDevolutionsStatus();

  const { badgeColor, translation } = devolutionsStatus[statusKey];

  return <Badge className="bb-badge" color={badgeColor} text={translation} />;
};

FormatStatus.propTypes = {
  statusKey: PropTypes.string.isRequired
};
