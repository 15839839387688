import React, { useCallback, useEffect, useState } from 'react';

import { map } from 'lodash';

import {
  getColumnsToDataTable,
  getDataTableStructure,
  getNoResultsConfig
} from 'app/modules/Wishlist/config/table';
import { DataTableProvider } from 'app/components/DataTable/context/DataTableContext';
import { genericRequest } from 'app/api';
import { INITIAL_VALUES } from 'app/modules/Wishlist/config/filters';
import { useFiltersConfiguration } from 'app/modules/Wishlist/hooks/useFiltersConfiguration';
import Wishlist from 'app/modules/Wishlist/Wishlist';

export default function WishlistDataProvider() {
  const [wishlistId, setWishlistId] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const fetchData = useCallback(async (controller) => {
    const result = await genericRequest({
      url: `wishlists`,
      signal: controller.signal
    });
    const newsTabs = [];
    map(result.data, (data) => {
      newsTabs.push({
        id: data?.id,
        name: data?.attributes?.name,
        type: data?.attributes?.type
      });
    });
    setTabs(newsTabs);
    setSelectedTab(0);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller);

    return () => controller.abort();
  }, [fetchData]);

  useEffect(() => {
    if (typeof tabs[selectedTab]?.id !== 'undefined') {
      setWishlistId(tabs[selectedTab]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, tabs]);

  if (!tabs || !wishlistId) return null;

  return (
    <DataTableProvider
      getTableContextConfiguration={() => ({
        apiUrl: `wishlists/${wishlistId}/products`,
        getColumnsToDataTable,
        getDataTableStructure,
        getNoResultsConfig,
        INITIAL_VALUES,
        useFiltersConfiguration
      })}
    >
      <Wishlist
        wishlistId={wishlistId}
        setWishlistId={setWishlistId}
        tabs={tabs}
        setTabs={setTabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </DataTableProvider>
  );
}
