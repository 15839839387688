import { genericRequest as req } from 'app/api';

const url = 'rma-lines';
const controller = new AbortController();

export const getRmaLinesByRmaId = async (id, config) => {
  const res = await req({
    url: `rma/${id}/${url}`,
    ...config,
    signal: controller.signal
  });
  return res;
};

export const postRmaLine = async (data, config) => {
  const res = await req({ url, method: 'POST', data, ...config });
  return res;
};

export const patchRmaLine = async ({ id, ...data }, config) => {
  const res = await req({
    url: `${url}/${id}`,
    method: 'PATCH',
    data,
    ...config,
    signal: controller.signal
  });
  return res;
};

export const deleteRmaLine = async (id, feedbackText) => {
  const res = await req({
    url: `${url}/${id}`,
    method: 'DELETE',
    customSuccessFeedback: {
      variant: 'success',
      content: feedbackText,
      autoClose: true,
      dismissible: false
    }
  });
  return res;
};

export const getComments = async (includedIds, config) => {
  const res = await req({
    url: `rma-line-comments?filter[id]=${includedIds}`,
    ...config,
    signal: controller.signal
  });
  return res;
};

export const postRmaComment = async (data, config) => {
  const res = await req({
    url: `rma-line-comments`,
    method: 'POST',
    data,
    ...config,
    signal: controller.signal
  });
  return res;
};

export const patchRmaComment = async (data, config) => {
  const res = await req({
    url: `rma-line-comments/${data?.data?.id}`,
    method: 'PATCH',
    data,
    ...config,
    signal: controller.signal
  });
  return res;
};
