import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';

export default function useShopStatus() {
  const intl = useIntl();

  const {
    bbRedLight,
    bbRed,
    bbPurpleLight,
    bbPurple,
    bbGreenLight,
    bbGreen,
    bbGrey5,
    bbGrey2
  } = Colors;

  return {
    ENTER_DETAILS: {
      backgroundColor: bbRedLight,
      color: bbRed,
      translation: intl.formatMessage({
        id: 'controlpanel.shops.status.insert_data'
      })
    },
    CHECKING_REQUIREMENTS: {
      backgroundColor: bbPurpleLight,
      color: bbPurple,
      translation: intl.formatMessage({
        id: 'controlpanel.shops.status.checking'
      })
    },
    WRONG_REQUIREMENTS: {
      backgroundColor: bbRedLight,
      color: bbRed,
      translation: intl.formatMessage({ id: 'controlpanel.shops.status.error' })
    },
    INSTALLATION_IN_PROGRESS: {
      backgroundColor: bbPurpleLight,
      color: bbPurple,
      translation: intl.formatMessage({
        id: 'controlpanel.shops.status.building'
      })
    },
    SHOP_FINISHED: {
      backgroundColor: bbGreenLight,
      color: bbGreen,
      translation: intl.formatMessage({ id: 'controlpanel.shops.status.done' })
    },
    SHOP_NOT_SYNCHRONIZED: {
      backgroundColor: bbGrey5,
      color: bbGrey2,
      translation: intl.formatMessage({
        id: 'controlpanel.shops.status.unsynchronized'
      })
    },
    SHOP_ON_HOLD: {
      backgroundColor: bbGrey5,
      color: bbGrey2,
      translation: intl.formatMessage({
        id: 'controlpanel.shops.status.waiting'
      })
    },
    PREPARING_INSTALLATION: {
      backgroundColor: bbPurpleLight,
      color: bbPurple,
      translation: intl.formatMessage({
        id: 'controlpanel.shops.status.preparing_installation'
      })
    }
  };
}
export const getRedColumnsStatus = () => [
  'ENTER_DETAILS',
  'WRONG_REQUIREMENTS'
];
