import React, { isValidElement, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';

import Input from 'app/components/Input';
import Label from 'app/components/Label';
import CustomerType from 'app/modules/MyAccount/pages/Billing/components/CustomerType';

// eslint-disable-next-line camelcase
import ps_state from 'app/modules/MyAccount/pages/Billing/utils/ps_state.json';
import {
  particularFormFields,
  freelanceFormFields,
  companyFormFields
} from 'app/modules/MyAccount/pages/Billing/utils';

const FIELDS_MAP = {
  1: particularFormFields,
  2: freelanceFormFields,
  3: companyFormFields
};

export default function BillForm({ customerType }) {
  const intl = useIntl();
  const formik = useFormikContext();

  useEffect(() => {
    if (formik.values.country !== 'SPAIN') {
      return;
    }

    const userInput = formik.values.postCode;

    const getState = (code) => {
      const userKey = code.slice(0, 2);
      const selectedState = ps_state.find((_) => _.postcode === userKey);
      return selectedState?.name;
    };

    const parsedState = getState(userInput);

    formik.setFieldValue('state', parsedState);
    // eslint-disable-next-line consistent-return
    return parsedState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.postCode]);
  return (
    <>
      <CustomerType customerType={customerType} />
      {FIELDS_MAP[customerType].map(
        ({ component, name, labelKey, placeholder, disabled, ...rest }, i) => {
          if (component && isValidElement(component)) {
            return <div key={i}>{component}</div>;
          }

          return (
            <Row className="input-billing" key={`${name}-${i}`}>
              <Col md={3} className="input-label">
                <Label
                  label={intl.formatMessage({ id: labelKey })}
                  htmlFor={name}
                />
              </Col>
              <Col md={7}>
                <Input
                  name={name}
                  placeholder={placeholder || formik.values[name] || ''}
                  {...rest}
                  disabled={disabled}
                />
              </Col>
            </Row>
          );
        }
      )}
    </>
  );
}

BillForm.propTypes = {
  customerType: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};
