export const parseShop = (shop) => ({
  shopId: shop.shopId || '',
  address: shop.address || '',
  city: shop.city || '',
  country: shop.country || '',
  email: shop.email || '',
  confirmEmail: shop.email || '',
  province: shop.province || '',
  zipCode: shop.zipCode || '',
  currency: shop.currency || [],
  domain: shop.domain || '',
  favicon: shop.favicon || '',
  mainLanguage: shop.mainLanguage || '',
  languages: shop.languages || [],
  logo: shop.logo || '',
  shopName: shop.shopName || '',
  status: shop.status || '',
  statusKey: shop.statusKey || '',
  updateDate: shop.updateDate || '',
  catalogue: shop.catalogue || '',
  theme: shop.theme || '',
  customizedLogo:
    shop.customizedLogo === true || shop.customizedLogo?.length > 0
      ? ['yes']
      : []
});
