import React from 'react';

import { Brand } from 'app/components/Brand/Brand';
import { AsideMenu } from 'app/components/Aside/aside-menu/AsideMenu';

export function Aside() {
  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
      >
        <Brand />

        {/* begin::Aside Menu */}
        <div
          id="kt_aside_menu_wrapper"
          className="aside-menu-wrapper flex-column-fluid"
        >
          <AsideMenu />
        </div>
        {/* end::Aside Menu */}
      </div>
      {/* end::Aside */}
    </>
  );
}
