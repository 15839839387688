import React, { isValidElement } from 'react';

export default function getInnerContent({ icon, text, componentChildren }) {
  if (componentChildren && isValidElement(componentChildren)) {
    return componentChildren;
  }

  const content = [];

  if (icon) content.push(<div key="button-icon">{icon}</div>);

  if (text) {
    content.push(
      <p className="bb-btn__text" key="button-text">
        {text}
      </p>
    );
  }

  return content;
}
