export const validate = ({ values, renderedFilters }, intl) => {
  const errors = {};

  renderedFilters.forEach((filter) => {
    if (!values[filter] && filter !== 'all') {
      errors[filter] = intl.formatMessage({
        id: 'controlpanel.form.validation.required'
      });
    }
  });

  return errors;
};
