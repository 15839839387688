import React from 'react';

import classNames from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider
} from 'react-bootstrap-table2-paginator';
import { size } from 'lodash';
import { useIntl } from 'react-intl';

import NoResultsFound from 'app/components/NoResultsFound';
import Pagination from 'app/components/Pagination';
import Spinner from 'app/components/Spinner';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

import 'app/components/DataTable/DataTable.scss';

export default function DataTable() {
  const {
    datatable: {
      columns: { columns },
      entities,
      getNoResultsConfig,
      customSpinner,
      loading,
      paginationOptions,
      rows: { selectRowConfig },
      setPaginationOptions,
      tableConfiguration,
      extraTableConfiguration
    }
  } = useDataTableContext();
  const intl = useIntl();
  const noResultsFound = getNoResultsConfig(intl);

  let noResultsFoundComponent = (
    <NoResultsFound {...getNoResultsConfig(intl)} />
  );

  if (noResultsFound?.component) {
    noResultsFoundComponent = noResultsFound?.component;
  }

  return (
    <div
      className={classNames('bb-data-table', {
        loading,
        'one-entitie': entities.length === 1
      })}
    >
      {loading === true && (customSpinner || <Spinner />)}

      {size(entities) > 0 ? (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <Pagination paginationProps={paginationProps}>
              <BootstrapTable
                {...tableConfiguration}
                remote
                keyField={paginationOptions.keyField}
                data={entities}
                columns={columns}
                pagination={paginationFactory()}
                defaultSorted={paginationOptions.defaultSorted}
                columnToggle
                onTableChange={(event, newValues) => {
                  const newOptions = {
                    ...paginationOptions,
                    page: newValues.page || 1,
                    sizePerPage: newValues.sizePerPage,
                    defaultSorted: [
                      {
                        dataField: newValues.sortField,
                        order: newValues.sortOrder
                      }
                    ]
                  };
                  setPaginationOptions(newOptions);
                }}
                {...selectRowConfig}
                {...paginationTableProps}
                {...extraTableConfiguration}
              />
            </Pagination>
          )}
        </PaginationProvider>
      ) : (
        loading === false && noResultsFoundComponent
      )}
    </div>
  );
}
