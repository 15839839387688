import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ShopsTimeline from 'app/components/ShopShippingProcess/components/ShopsTimeline';
import { formatDateToLocale, formatHours } from 'app/utils/dates';
import {
  COLOR_MAP,
  TRANSLATION_KEY_MAP,
  STATUSES_WITHOUT_DESCRIPTION
} from 'app/components/ShopShippingProcess/constants';

import 'app/components/ShopShippingProcess/ShopShippingProcess.scss';

export default function ShopShippingProcess({ title, milestones }) {
  const times = useMemo(() => {
    const sortedTimes = milestones.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    const getTimes = sortedTimes.map((el, i) => {
      const { date: updateDate, status, description } = el;
      const currentElementColor = i === 0 ? COLOR_MAP[status] : 'grey';
      const eventTitle = TRANSLATION_KEY_MAP[status];
      const date = new Date(updateDate * 1000);

      const leftSide = {
        dateTitle: formatDateToLocale(date) || '',
        dateSubtitle: formatHours(date) || '',
        dateColor: 'grey'
      };

      const rightSide = {
        eventTitle,
        eventSubtitle: STATUSES_WITHOUT_DESCRIPTION.includes(status)
          ? ''
          : description,
        eventColor: currentElementColor
      };

      return { leftSide, rightSide, circleColor: currentElementColor };
    });
    return getTimes;
  }, [milestones]);

  return (
    <div className="shop-shipping-process">
      <h5 className="shop-shipping-process__title bold">{title}</h5>
      <ShopsTimeline times={times} />
    </div>
  );
}

const milestone = PropTypes.shape({
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
});

ShopShippingProcess.propTypes = {
  title: PropTypes.string.isRequired,
  milestones: PropTypes.arrayOf(milestone).isRequired
};
