import React from 'react';

import { sortCaret } from 'app/components/DataTable/utils';
import {
  FormatActions,
  FormatStatus
} from 'app/modules/Devolutions/utils/formatters';

import { DateFormatter } from 'app/utils/columnsFormatters/DateFormatter';

export const getColumnsToDataTable = (intl, ...rest) => [
  {
    dataField: 'id',
    text: '',
    hidden: true,
    sort: false,
    draggable: false
  },
  {
    dataField: 'code',
    text: intl.formatMessage({ id: 'controlpanel.devolutions.table.code' }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret
  },
  {
    dataField: 'date',
    text: intl.formatMessage({ id: 'controlpanel.devolutions.table.date_sat' }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret
  },
  {
    dataField: 'amount',
    text: intl.formatMessage({
      id: 'controlpanel.global.filter.amount'
    }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret
  },
  {
    dataField: 'status',
    text: intl.formatMessage({ id: 'controlpanel.global.filter.status' }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret
  },
  {
    dataField: 'actions',
    text: intl.formatMessage({ id: 'controlpanel.global.table_actions' }),
    sort: false,
    hidden: false,
    draggable: true,
    sortCaret
  },
  ...rest
];

export const getDataTableStructure = (data) =>
  data.map(
    ({
      id,
      attributes: { date = null, code = null, amount = null, status = null },
      meta: { editable = null, label = null },
      links
    }) => ({
      id,
      code,
      date: <DateFormatter date={date} />,
      amount,
      status: (
        <FormatStatus
          statusKey={status}
          status={status}
          carrierStatus={status}
        />
      ),
      actions: (
        <FormatActions
          id={id}
          devolutionId={code}
          editable={editable}
          label={label}
          labelUrl={links ? links.labelUrl : null}
          status={status}
        />
      )
    })
  );

export const getNoResultsConfig = (intl) => ({
  text: intl.formatMessage({ id: 'controlpanel.devolutions.no_results' }),
  icon: 'location-dot'
});
