import React from 'react';
import PropTypes, { shape } from 'prop-types';
import { useIntl } from 'react-intl';

import Icon from 'app/components/Icon';

import 'app/components/IconsList/IconsList.scss';

export default function IconsList({
  items = [],
  title = '',
  listClassName = ''
}) {
  const intl = useIntl();
  return (
    <div className="icons-list">
      {title && (
        <p className="icons-list__title">{intl.formatMessage({ id: title })}</p>
      )}
      <ul className={`icons-list__items ${listClassName}`}>
        {items.map(({ prefix, icon, color, size, translationId }, index) => (
          <li key={index} className="icons-list__item">
            <div className="icons-list__item-icon">
              <Icon prefix={prefix} icon={icon} color={color} size={size} />
            </div>
            <div className="icons-list__item-text">
              <p>{intl.formatMessage({ id: translationId })}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

IconsList.propTypes = {
  items: PropTypes.arrayOf(shape),
  title: PropTypes.string,
  listClassName: PropTypes.string
};
