import React, { useEffect, useState, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { translateCountryToLocale } from 'app/utils/parsers/translateCountryToLocale';
import { genericRequest } from 'app/api';
import Redirect from 'app/components/Redirect';
import Spinner from 'app/components/Spinner';
import ShopifyForm from 'app/modules/Shops/pages/Shopify/ShopifyForm';
import PrestashopForm from 'app/modules/Shops/pages/Prestashop/PrestashopForm';

export default function ShopsLayout() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const params = useParams();
  const intl = useIntl();
  const { id } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [platform, setPlatform] = useState(null);

  const [shop, setShop] = useState({});
  const [shopHistory, setShopHistory] = useState([]);
  const [academyLink, setAcademyLink] = useState(null);
  const [demoLink, setDemoLink] = useState(null);

  const userShopsIds = useMemo(() => {
    const shopsList = user?.relationships?.shops360?.data;
    return shopsList ? shopsList.map(({ id: shopId }) => shopId) : [];
  }, [user]);

  useEffect(() => {
    if (!userShopsIds.includes(id)) {
      navigate('/shops');
      return;
    }

    const fetchUserShop = async () => {
      setIsLoading(true);

      const { data: allShops, links } = await genericRequest({
        url: 'shops360',
        blockFeedback: true
      });

      setAcademyLink(links?.academy);
      setDemoLink(links?.dropshipping);

      const targetShop = allShops.find((item) => item.id === id);

      setPlatform(targetShop?.type);
      setShop({
        ...targetShop?.attributes,
        shopId: id,
        country: translateCountryToLocale({
          country: targetShop?.attributes?.country,
          lang: intl.locale
        })
      });

      if (targetShop?.type === 'shopify') {
        const history = await genericRequest({
          url: `shops360/${id}/statuses`,
          blockFeedback: true
        });
        setShopHistory(history?.data);
      }

      setIsLoading(false);
    };

    fetchUserShop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Spinner size="4x" />;
  }

  if (platform === 'shopify' && Object.entries(shop).length > 0) {
    return (
      <ShopifyForm
        shopHistory={shopHistory}
        shop={shop}
        academyLink={academyLink}
        demoLink={demoLink}
        setShop={setShop}
        setShopHistory={setShopHistory}
      />
    );
  }

  if (platform === 'prestashop' && Object.entries(shop).length > 0) {
    return <PrestashopForm shop={shop} />;
  }

  return <Redirect to="/shops" />;
}
