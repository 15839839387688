import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Colors as colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import WholesalerPackTag from 'app/modules/MyAccount/pages/WholesalerPack/components/WholesalerPackTag';

import 'app/modules/MyAccount/pages/WholesalerPack/components/WholesalerPackWithoutPack.scss';

export default function WholesalerPackWithoutPack() {
  const intl = useIntl();

  return (
    <div className="bb-wholesaler-pack-without-pack">
      <Row>
        <Col md={3}>
          <p className="bb-wholesaler-pack-without-pack__p-sub-title">
            {intl.formatMessage({
              id: 'controlpanel.my_account.pack.without.title'
            })}
          </p>
        </Col>
        <Col md={9}>
          <WholesalerPackTag
            id={0}
            name={intl.formatMessage({
              id: 'controlpanel.my_account.pack.without.tag'
            })}
          />
          <div className="bb-wholesaler-pack-without-pack__buttons">
            <Button
              text={intl.formatMessage({
                id: 'controlpanel.my_account.pack.without.comparative'
              })}
              size="big"
              onClick={() => {
                window.location.href = intl.formatMessage({
                  id: 'controlpanel.my_account.pack.without.comparative_url'
                });
              }}
              icon={
                <Icon
                  icon="cubes"
                  size="lg"
                  swapOpacity
                  color={colors.bbSecondary}
                />
              }
            />
            <Button
              text={intl.formatMessage({
                id: 'controlpanel.my_account.pack.without.activate'
              })}
              type="primary"
              size="big"
              onClick={() => {
                window.location.href = intl.formatMessage({
                  id: 'controlpanel.my_account.pack.without.activate_url'
                });
              }}
              icon={<Icon icon="cube" size="lg" swapOpacity />}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
