import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Select } from 'app/components/Select';
import useRMAContext from 'app/modules/Devolutions/context/context';

const EditUnits = ({ quantity, id, eligibleQuantity }) => {
  const { isEdittingId } = useRMAContext();
  const availableQuantity = useMemo(() => {
    const length = quantity + eligibleQuantity;

    const optionsArray = Array.from({ length }, (_, i) => ({
      value: i + 1,
      label: String(i + 1)
    }));
    return optionsArray;
  }, [quantity, eligibleQuantity]);

  if (isEdittingId === id) {
    return (
      <Select
        name="lineUnits"
        id="lineUnits"
        placeholder="0"
        options={availableQuantity}
        defaultValue={{ value: quantity, label: String(quantity) }}
        reactSelect={{
          menuPosition: 'fixed'
        }}
      />
    );
  }

  return <p>{quantity}</p>;
};

EditUnits.propTypes = {
  quantity: PropTypes.number.isRequired,
  eligibleQuantity: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired
};

export default EditUnits;
