import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const DataTableComponentsContext = createContext({});

export const DataTableComponentsProvider = ({ children, config }) => {
  const [context, setContext] = useState(config);

  useEffect(() => {
    const cloneConfig = { ...config };
    cloneConfig.api.options.page = 1;
    cloneConfig.api.options.sizePerPage = 10;
    cloneConfig.api.loading = true;

    setContext(cloneConfig);
  }, [config]);

  return (
    <DataTableComponentsContext.Provider value={{ context, setContext }}>
      {children}
    </DataTableComponentsContext.Provider>
  );
};

DataTableComponentsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.shape({}).isRequired
};
