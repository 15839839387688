import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { map, size } from 'lodash';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { genericRequest } from 'app/api';
import { getFiltersByFieldName } from 'app/api/generalServices/filters';
import { Select, getSelectDefaultValues } from 'app/components/Select';
import Button from 'app/components/Button/Button';
import Icon from 'app/components/Icon/Icon';
import SearchBar from 'app/components/SearchBar/SearchBar';
import Spinner from 'app/components/Spinner/Spinner';
import TagsGroup from 'app/components/TagsGroup';
import useDataTableComponentsContext from 'app/components/DataTableComponents/hooks/useDataTableComponentsContext';
import { useLang } from 'app/context/i18n';

import 'app/modules/Downloads/components/Filters/Filters.scss';
import { getSelectStructure } from 'app/utils/filters';

export default function Filters({ showTheme = false, showSearch = false }) {
  const intl = useIntl();
  const lang = useLang();
  const { context, setContext } = useDataTableComponentsContext();
  const formRef = useRef();
  const selectTag = useRef();
  const selectThemeName = 'theme';
  const [theme, setTheme] = useState([]);

  useEffect(() => {
    if (showTheme) {
      (async () => {
        const request = await getFiltersByFieldName(['theme'], lang);
        setTheme(getSelectStructure(request[0]?.values, 'key', 'value'));
      })();
    }
  }, [showTheme, lang]);

  const [tags, setTags] = useState([]);

  const removeFilter = (filter) => {
    if (filter === selectThemeName) {
      selectTag?.current?.setValue(null);
    }

    formRef?.current?.setFieldValue(filter, '');
    const newFilters = { ...context?.filters };
    delete newFilters[filter];

    setContext((prevState) => ({
      ...prevState,
      filters: newFilters
    }));
  };

  useEffect(() => {
    const newTags = [];
    map(context?.filters, (value, filter) => {
      switch (filter) {
        case selectThemeName:
          newTags.push({
            closeHandler: () => removeFilter(filter),
            translation: `${intl.formatMessage({
              id: 'controlpanel.global.theme'
            })}: ${value}`
          });
          break;

        case 'search':
          newTags.push({
            closeHandler: () => removeFilter(filter),
            translation: `${intl.formatMessage({
              id: 'controlpanel.global.search'
            })}: ${value}`
          });
          break;

        default:
          break;
      }
    });

    setTags(newTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.filters]);

  const [loading, setLoading] = useState(false);

  const downloadLinks = async (ids, type) => {
    setLoading(true);
    const response = await genericRequest({
      url: 'media-download-request',
      method: 'POST',
      data: {
        data: {
          type: 'media-download-request',
          attributes: {
            ids,
            mediaType: type
          }
        }
      }
    });
    setLoading(false);
    window.location.href = response?.links?.download_url;
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={context?.filters || {}}
      onSubmit={(values) => {
        const newFilters = clearFilters(values);

        setContext((prevState) => {
          const newState = { ...prevState };
          newState.api.options.page = 1;
          newState.filters = newFilters;
          return {
            ...newState
          };
        });
      }}
    >
      {(formik) => (
        <Form className="filters-form">
          <Row>
            <Col className="filters-form__search">
              {showSearch && (
                <SearchBar
                  placeholder={intl.formatMessage({
                    id: 'controlpanel.global.search'
                  })}
                  name="search"
                  button={{
                    onClick: () => formik.submitForm(),
                    type: 'secondary',
                    size: 'big',
                    icon: (
                      <Icon icon="search" prefix="fas" color={Colors.bbWhite} />
                    )
                  }}
                  value={context?.filters?.search || ''}
                  onClear={() => {
                    formik.setFieldValue('search', '');
                    formik.submitForm();
                  }}
                />
              )}

              <Button
                type="primary"
                text={intl.formatMessage({
                  id: 'controlpanel.global.download'
                })}
                size="big"
                icon={
                  loading === true ? (
                    <Spinner />
                  ) : (
                    <Icon icon="arrow-alt-to-bottom" color={Colors.bbGrey2} />
                  )
                }
                disabled={size(context?.selected) === 0 || loading === true}
                onClick={() => downloadLinks(context?.selected, context?.info)}
              />
            </Col>
          </Row>
          {/* // * En caso de añadir más filtros sacar el row del if */}
          {showTheme && (
            <Row className="filters-form__selects">
              <Col>
                <Select
                  reactSelect={{ ref: selectTag }}
                  id={selectThemeName}
                  name={selectThemeName}
                  options={theme}
                  defaultValue={getSelectDefaultValues(theme, [
                    formik.values?.theme
                  ])}
                  placeholder={intl.formatMessage({
                    id: 'controlpanel.global.placeholder.select'
                  })}
                  label={intl.formatMessage({
                    id: 'controlpanel.global.theme'
                  })}
                  onChange={() => formik.submitForm()}
                  clearable
                />
              </Col>
            </Row>
          )}
          {size(tags) > 0 && (
            <Row>
              <Col>
                <TagsGroup tags={tags} />
              </Col>
            </Row>
          )}
        </Form>
      )}
    </Formik>
  );
}

Filters.propTypes = {
  showTheme: PropTypes.bool,
  showSearch: PropTypes.bool
};

const clearFilters = (filters) => {
  const filtersCleared = {};
  map(filters, (filter, key) => {
    if (filter !== '') {
      filtersCleared[key] = filter;
    }
  });

  return filtersCleared;
};
