import { addActions } from 'app/modules/Devolutions/components/ChatMessage/utils/addActions';

export const orderMessagesByDateAsc = (messages) =>
  messages.sort((a, b) => a.attributes.date - b.attributes.date);

export const getFirstTwoElements = (messages) => messages.slice(0, 2);

export const convertToComponent = (messages) =>
  messages.map((message, index, arr) =>
    addActions({ message, index, length: arr.length })
  );

export const isLastMessageFromAdmin = (messages) => {
  if (!messages?.length) {
    return false;
  }

  const orderedMessages = orderMessagesByDateAsc(messages);

  const lastMessage = orderedMessages[orderedMessages.length - 1];

  return lastMessage.attributes.admin;
};
