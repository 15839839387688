import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import classNames from 'classnames';

import {
  getAllStatusExceptions,
  getGroupedStatus
} from 'app/hooks/useTrackingsStatus.hook';

export const FormatGeneric = ({
  translate = false,
  text = null,
  read,
  statusKey
}) => {
  const intl = useIntl();
  const allExceptions = getAllStatusExceptions();
  const voided = getGroupedStatus().VOIDED;

  if (!text) return null;

  const classes = classNames('bb-trackings__data-table__cell small', {
    bold: read,
    red: allExceptions.includes(statusKey),
    blue: voided.includes(statusKey)
  });

  return (
    <p className={classes}>
      {translate ? intl.formatMessage({ id: text }) : text}
    </p>
  );
};

FormatGeneric.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.node
  ]),
  statusKey: PropTypes.string.isRequired,
  read: PropTypes.bool.isRequired,
  translate: PropTypes.bool
};
