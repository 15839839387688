import { genericRequest } from 'app/api';
import { formatFiltersToEndpoint } from 'app/utils/filters';

export const getPDF = async ({
  type = 'download',
  format = 'zip',
  fileType = 'invoice',
  selectAll: all = false,
  includedIds = [],
  excludedIds = [],
  filters = null,
  setLoading = null,
  id
}) => {
  const payload = {
    data: {
      type,
      attributes: {
        format,
        fileType,
        all
      }
    }
  };

  if (id) {
    payload.data.id = id;
  }

  if (!all && includedIds?.length > 0) {
    payload.data.attributes.includedIds = includedIds;
  }

  if (all && excludedIds?.length > 0) {
    payload.data.attributes.excludedIds = excludedIds;
  }

  if (filters) {
    payload.data.attributes.filter = formatFiltersToEndpoint(filters);
  }

  try {
    const response = await genericRequest({
      url: `downloads/zip-downloads`,
      method: 'post',
      data: payload,
      setLoading,
      getHeaders: true
    });

    if (response?.status === 201) {
      window.location = response.data.data.attributes.link;
    }

    return response;
  } catch (err) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(err);
    }
    return null;
  }
};
