import React from 'react';
import PropTypes from 'prop-types';

import useShopStatus from 'app/hooks/useShopsStatus.hook';

export const FormatStatus = ({ statusKey }) => {
  const shopsStatus = useShopStatus();
  const { color, backgroundColor, translation } = shopsStatus[statusKey];

  return (
    <span
      role="none"
      className="bb-shops__data-table__cell--tag"
      style={{
        backgroundColor
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="bb-shops__data-table__cell" style={{ color }}>
        {translation}
      </label>
    </span>
  );
};

FormatStatus.propTypes = {
  statusKey: PropTypes.string.isRequired
};
