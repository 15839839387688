export const getTabs = (intl) => [
  {
    id: 0,
    name: intl.formatMessage({
      id: 'controlpanel.global.all_plural'
    }),
    filters: null
  },
  {
    id: 1,
    name: intl.formatMessage({
      id: 'controlpanel.devolutions.tabs.in_process'
    }),
    filters: {
      status: [
        'DRAFT',
        'PENDING_VALUATION',
        'PENDING_PAYMENT',
        'PENDING_COLLECTION',
        'PENDING_PICK_UP_ADDRESS'
      ]
    }
  },
  {
    id: 2,
    name: intl.formatMessage({
      id: 'controlpanel.devolutions.tabs.paid'
    }),
    filters: { status: ['PAID'] }
  },
  {
    id: 3,
    name: intl.formatMessage({
      id: 'controlpanel.global.rejected'
    }),
    filters: { status: ['REJECTED'] }
  }
];
