import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { filter, size } from 'lodash';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { useSubheader } from 'app/context/SubheaderContext';
import Button from 'app/components/Button';
import Card from 'app/components/Card';
import HowToStockTracking from 'app/modules/Wishlist/components/HowToStockTracking';
import Icon from 'app/components/Icon';
import PopupDeleteWishlist from 'app/modules/Wishlist/components/PopupDeleteWishlist';
import StockTracking from 'app/modules/Wishlist/Tabs/StockTracking';
import TabBar from 'app/components/TabBar';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';
import useNotificationsServiceHook from 'app/hooks/useNotificationsService.hook';
import UserNotificationAlert from 'app/components/UserNotificationAlert';

import salicornio from 'app/assets/png/salicornio.png';
import 'app/modules/Wishlist/Wishlist.scss';

export default function Wishlist({
  wishlistId,
  tabs,
  selectedTab,
  setSelectedTab,
  setTabs
}) {
  const intl = useIntl();
  const subheader = useSubheader();
  const { setTitle, setBreadcrumbs, setIcon } = subheader;
  useLayoutEffect(() => {
    setBreadcrumbs([]);
  }, [setTitle, setIcon, setBreadcrumbs]);

  const {
    notifications: { alerts }
  } = useNotificationsServiceHook();

  const alertsFiltered = filter(
    alerts,
    (alert) => alert?.attributes?.section === 'PRODUCT'
  );

  const context = useDataTableContext();

  useEffect(() => {
    if (wishlistId) {
      context.api.fetchData();
      context.context.saveInContextStorage('wishlistData', {
        tabs,
        selectedTab,
        wishlistId,
        setSelectedTab,
        setTabs
      });
      context.context.updateContextStorage('wishlistData', {
        tabs,
        selectedTab,
        wishlistId,
        setSelectedTab,
        setTabs
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlistId]);

  if (!wishlistId || !tabs) return null;

  return (
    <div
      className="wishlist"
      id="wishlist"
      data-name={tabs[selectedTab]?.name}
      data-type={tabs[selectedTab]?.type}
      data-total={context?.api?.apiResponse?.meta?.totalCount}
    >
      {size(alertsFiltered) > 0 && (
        <UserNotificationAlert alerts={alertsFiltered} />
      )}
      <Row className="wishlist__datatable">
        <Col md={12}>
          <Card
            icon="info-square"
            title={intl.formatMessage({
              id: 'controlpanel.wishlist.title'
            })}
            body={
              <>
                <TabBar
                  value={selectedTab}
                  onChange={(e, newValue) => {
                    context.datatable.rows.setSelectAll(false);
                    context.datatable.rows.setSelectedRows([]);
                    context.datatable.setPaginationOptions({
                      ...context.datatable.paginationOptions,
                      page: 1
                    });
                    context.filters.resetFilters();
                    setSelectedTab(newValue);
                  }}
                  tabs={tabs}
                  scrollButtons="on"
                />
                <StockTracking />
              </>
            }
          />
        </Col>
      </Row>

      <Row className="wishlist__howto">
        <Col md={12}>
          <Card
            icon="info-square"
            title={intl.formatMessage({
              id: 'controlpanel.global.frequently_asked_questions'
            })}
            body={<HowToStockTracking />}
          />
        </Col>
      </Row>
    </div>
  );
}

const Tab = ({ children, index, activeTab }) => (
  <div
    role="tabpanel"
    hidden={activeTab !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {children}
  </div>
);

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  activeTab: PropTypes.number.isRequired
};

Wishlist.propTypes = {
  wishlistId: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.node,
      name: PropTypes.string,
      type: PropTypes.number
    })
  ).isRequired,
  selectedTab: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  setTabs: PropTypes.func.isRequired
};

export const WishlistNoResultsFound = () => {
  const intl = useIntl();
  const [showPopup, setShowPopup] = useState(false);
  const data = document.getElementById('wishlist');
  const showButton =
    Number(data?.dataset?.type) !== 1 && Number(data?.dataset?.total) === 0;

  return (
    <div className="wishlist__datatable__no-results-found">
      <PopupDeleteWishlist show={showPopup} setShow={setShowPopup} />
      <div className="wishlist__datatable__no-results-found-content">
        <p>
          {intl.formatMessage(
            {
              id: 'controlpanel.wishlist.delete_wishlist.warning'
            },
            {
              name: data?.dataset?.name
            }
          )}
        </p>
        {showButton && (
          <Button
            className="button-delete"
            type="default"
            text={intl.formatMessage({ id: 'controlpanel.global.delete' })}
            size="medium"
            icon={<Icon icon="xmark" color={Colors.bbRed} prefix="fal" />}
            onClick={() => setShowPopup(true)}
          />
        )}
        <img src={salicornio} alt="No results foud" />
      </div>
    </div>
  );
};
