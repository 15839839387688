import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import ColumnReorderModal from 'app/components/ColumnReorderModal';
import DataTable from 'app/components/DataTable';
import DataTableFilters from 'app/components/DataTableFilters';
import DataTableFiltersTags from 'app/components/DataTableFiltersTags';
import Icon from 'app/components/Icon';
import SearchBar from 'app/components/SearchBar';
import DataTableTabs from 'app/components/DataTableTabs';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

import 'app/modules/Trackings/components/Body/Body.scss';
import PopupDownloadCSV from 'app/components/PopupDownloadCSV';

export default function Body() {
  const intl = useIntl();
  const { submitForm } = useFormikContext();
  const {
    filters: { filters, removeFilter, parsedFilters }
  } = useDataTableContext();

  return (
    <div>
      <div className="export-csv-wrapper">
        <PopupDownloadCSV fileType="tracking" />
      </div>
      <DataTableTabs />
      <Row className="bb-data-table__filters">
        <Col xs={12} md={5} className="bb-data-table__searchbar">
          <SearchBar
            placeholder={intl.formatMessage({
              id: 'controlpanel.trackings.filters.search_label'
            })}
            name="search"
            button={{
              onClick: submitForm,
              type: 'secondary',
              size: 'big',
              icon: <Icon icon="search" prefix="fas" color={Colors.bbWhite} />
            }}
            value={filters?.search || ''}
            onClear={() => removeFilter('search')}
          />
        </Col>
        <Col xs={12} md={7} className="bb-data-table__filters-buttons">
          <DataTableFilters />
          <ColumnReorderModal />
        </Col>
        {parsedFilters && Object.entries(parsedFilters).length > 0 && (
          <Col md={12}>
            <DataTableFiltersTags />
          </Col>
        )}
      </Row>
      <DataTable />
    </div>
  );
}
