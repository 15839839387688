import React from 'react';
import PropTypes from 'prop-types';

import { map, size } from 'lodash';
import { useIntl } from 'react-intl';
import SVG from 'react-inlinesvg';

import { getIncludeLangs } from 'app/utils/compareLanguageFlags';
import { languages } from 'app/utils/constants';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';
import { PRODUCT_INFO } from 'app/modules/Downloads/Pages/ProductsManuals/utils/props';

export default function LangFormatter({ langs = [], productInfo = null }) {
  const intl = useIntl();
  const {
    context: { readContextStorage }
  } = useDataTableContext();
  const { setModalData } = readContextStorage();

  const sizeLangs = size(langs);
  const MAX_SIZE_LANGS = 6;
  const isGreaterThanMaxSize = sizeLangs > MAX_SIZE_LANGS;
  const showLangs = isGreaterThanMaxSize ? langs.slice(0, 3) : langs;
  const handleClick = () => setModalData({ show: true, productInfo });

  return (
    <div className="table-languages">
      {map(getIncludeLangs(languages, showLangs), (language) => (
        <div
          key={`table-language-${language}`}
          className="table-languages__container"
        >
          <div className="table-languages__container-image">
            <SVG src={language?.flag} />
          </div>
        </div>
      ))}
      {isGreaterThanMaxSize && (
        <div
          role="button"
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={handleClick}
        >
          <p className="link small">
            {intl.formatMessage({ id: 'controlpanel.global.view_all' })}
          </p>
        </div>
      )}
    </div>
  );
}
LangFormatter.propTypes = {
  langs: PropTypes.arrayOf(PropTypes.string),
  productInfo: PropTypes.shape(PRODUCT_INFO)
};
