import React from 'react';

import { useIntl } from 'react-intl';

import { PROP_TYPES } from 'app/components/FormSpinner/utils';
import Button from 'app/components/Button';
import FormCard from 'app/components/FormCard';
import Spinner from 'app/components/Spinner';

import 'app/components/FormSpinner/FormSpinner.scss';

export default function FormSpinner({
  title,
  showButtons = false,
  containerMaxHeight = null
}) {
  const intl = useIntl();

  const buttons = !showButtons ? null : (
    <>
      <Button
        size="big"
        text={intl.formatMessage({
          id: 'controlpanel.global.cancel'
        })}
        className="bb-form-spinner--button"
        disabled
      />
      <Button
        size="big"
        type="secondary"
        text={intl.formatMessage({
          id: 'controlpanel.global.save'
        })}
        typeButton="submit"
        className="bb-form-spinner--button"
        disabled
      />
    </>
  );

  return (
    <div
      className="bb-form-spinner"
      style={containerMaxHeight ? { maxHeight: `${containerMaxHeight}px` } : {}}
    >
      <FormCard title={intl.formatMessage({ id: title })} buttons={buttons}>
        <Spinner size="4x" />
      </FormCard>
    </div>
  );
}

FormSpinner.propTypes = PROP_TYPES;
