import PropTypes from 'prop-types';

export const PROP_TYPES = {
  text: PropTypes.node,
  type: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'tertiary',
    'outline'
  ]),
  size: PropTypes.oneOf(['huge', 'big', 'medium', 'small']),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  tooltipText: PropTypes.string,
  disabled: PropTypes.bool,
  typeButton: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string
};

export const DEFAULT_PROPS = {
  text: '',
  type: 'default',
  size: 'huge',
  style: {},
  icon: null,
  tooltipText: '',
  disabled: false,
  typeButton: 'button',
  onClick: () => null,
  children: undefined,
  className: null,
  id: ''
};
