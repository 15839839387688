import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { genericRequest } from 'app/api';
import { getSchemaValidation } from 'app/modules/Devolutions/components/CollectionAddressForm/utils';
import { Select, getSelectDefaultValues } from 'app/components/Select';
import Button from 'app/components/Button';
import Checkbox from 'app/components/Checkbox';
import Divider from 'app/components/Divider/Divider';
import FormInfo from 'app/modules/Devolutions/components/NotificationDevolution/components/FormInfo';
import Input from 'app/components/Input';
import PackagesList from 'app/modules/Devolutions/components/PackagesList';
import Textarea from 'app/components/Textarea';
import useRMAContext from 'app/modules/Devolutions/context/context';

import 'app/modules/Devolutions/components/CollectionAddressForm/CollectionAddressForm.scss';

export default function CollectionAddressForm({ disabled = false }) {
  const intl = useIntl();
  const { rmaId: id, initializeRma } = useRMAContext();

  const [initialValues, setInitialValues] = useState(false);
  const [dimensions, setDimensions] = useState([]);
  const [quantityPackages, setQuantityPackages] = useState(0);
  const [countries, setCountries] = useState([]);

  const [formikActions, setFormikActions] = useState([]);

  const validationSchema = disabled === true ? null : getSchemaValidation(intl);

  useEffect(() => {
    const getUserData = async () => {
      const {
        data: {
          attributes: {
            address,
            city,
            comments,
            company,
            contactPerson,
            country,
            phone,
            postCode,
            rmaId,
            packages,
            quantity,
            pallet
          }
        },
        meta: { countryList }
      } = await genericRequest({
        url: `/rma/${id}/pickup-address`
      });

      setCountries(countryList);

      const formContent = {
        rmaId,
        contactPerson,
        companyName: company,
        address,
        country,
        city,
        zipCode: postCode,
        phone,
        comments: comments || '',
        quantity: quantity || 0,
        packages: packages || [],
        packageType: pallet ? 'pallets' : 'bultos'
      };
      setInitialValues(formContent);
    };
    getUserData();
  }, [id]);

  const handleFormSubmission = async (values) => {
    const formValues = {
      rmaId: values.rmaId,
      contactPerson: values.contactPerson,
      company: values.companyName,
      address: values.address,
      country: values.country,
      city: values.city,
      postCode: values.zipCode,
      phone: values.phone,
      comments: values.comments ? values.comments : '',
      packages: values.packages,
      quantity: values.quantity,
      pallet: values.packageType === 'pallets'
    };

    const response = await genericRequest({
      url: 'rma-address',
      method: 'post',
      data: {
        data: {
          type: 'rma-pickup-address',
          attributes: formValues
        }
      },
      customSuccessFeedback: {
        variant: 'success',
        content: intl.formatMessage({
          id: 'controlpanel.feedback.save_changes.success'
        }),
        autoClose: true,
        dismissible: false
      }
    });

    if (response) {
      setDimensions(values.packages);
      initializeRma();
    }
  };

  useEffect(() => {
    if (quantityPackages > 0) {
      const actions = formikActions;
      setDimensions(actions?.values?.packages);

      if (Number(quantityPackages) > dimensions.length) {
        const difference = Number(quantityPackages) - dimensions.length;

        for (let index = 0; index < difference; index++) {
          const structure = {
            height: '',
            width: '',
            depth: '',
            weight: ''
          };
          dimensions.push(structure);
        }
      } else {
        const difference = dimensions.length - quantityPackages;

        dimensions.length -= difference;
      }

      actions.setFieldValue('packages', dimensions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityPackages, dimensions]);

  const handleReloadPackages = (e, actions) => {
    setQuantityPackages(e.target.value);
    setFormikActions(actions);
  };

  return (
    <div>
      {initialValues && (
        <Formik
          // dirty
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleFormSubmission}
        >
          {(formik) => (
            <Form
              validateOnChange={false}
              validateOnBlur={false}
              onChange={(e) =>
                e.target.name === 'quantity'
                  ? handleReloadPackages(e, formik)
                  : null
              }
              className={classNames('collection-address-form', { disabled })}
            >
              <Row>
                <Col md={{ span: 8, offset: 2 }} xs={12}>
                  <Input
                    name="contactPerson"
                    placeholder={
                      disabled === true
                        ? formik?.values?.contactPerson
                        : intl.formatMessage({
                            id: 'controlpanel.form.label.contact_person'
                          })
                    }
                    label={intl.formatMessage({
                      id: 'controlpanel.form.label.contact_person'
                    })}
                    disabled={disabled}
                  />
                </Col>
                <Col md={{ span: 8, offset: 2 }} xs={12}>
                  <Input
                    name="companyName"
                    placeholder={
                      disabled === true
                        ? formik?.values?.companyName
                        : intl.formatMessage({
                            id: 'controlpanel.global.company'
                          })
                    }
                    label={intl.formatMessage({
                      id: 'controlpanel.global.company'
                    })}
                    disabled={disabled}
                  />
                </Col>
                <Col md={{ span: 8, offset: 2 }} xs={12}>
                  <Input
                    name="address"
                    placeholder={
                      disabled === true
                        ? formik?.values?.address
                        : intl.formatMessage({
                            id: 'controlpanel.global.pickup_address'
                          })
                    }
                    label={intl.formatMessage({
                      id: 'controlpanel.global.pickup_address'
                    })}
                    disabled={disabled}
                  />
                </Col>
                <Col
                  md={{ span: 4, offset: 2 }}
                  className="collection-address-form__select"
                >
                  <Select
                    options={countries}
                    defaultValue={getSelectDefaultValues(
                      countries[formik?.values?.country]
                    )}
                    formik={formik}
                    id="country"
                    name="country"
                    placeholder={intl.formatMessage({
                      id: 'controlpanel.form.label.country'
                    })}
                    label={intl.formatMessage({
                      id: 'controlpanel.form.label.country'
                    })}
                    searchable
                    disabled={disabled}
                  />
                </Col>
                <Col md={{ span: 4 }} xs={12}>
                  <Input
                    name="city"
                    placeholder={
                      disabled === true
                        ? formik?.values?.city
                        : intl.formatMessage({
                            id: 'controlpanel.form.label.city'
                          })
                    }
                    label={intl.formatMessage({
                      id: 'controlpanel.form.label.city'
                    })}
                    disabled={disabled}
                  />
                </Col>
                <Col md={{ span: 4, offset: 2 }}>
                  <Input
                    name="zipCode"
                    placeholder={
                      disabled === true
                        ? formik?.values?.zipCode
                        : intl.formatMessage({
                            id: 'controlpanel.global.zipCode'
                          })
                    }
                    label={intl.formatMessage({
                      id: 'controlpanel.global.zipCode'
                    })}
                    disabled={disabled}
                  />
                </Col>
                <Col md={{ span: 4 }} xs={12}>
                  <Input
                    name="phone"
                    placeholder={
                      disabled === true
                        ? formik?.values?.phone
                        : intl.formatMessage({
                            id: 'controlpanel.form.label.telephone'
                          })
                    }
                    label={intl.formatMessage({
                      id: 'controlpanel.form.label.telephone'
                    })}
                    disabled={disabled}
                  />
                </Col>
                <Col
                  md={{ span: 4, offset: 2 }}
                  className="collection-address-form__radio"
                >
                  <Checkbox
                    formik={formik}
                    id="radio1"
                    name="packageType"
                    type="radio"
                    label={intl.formatMessage({
                      id: 'controlpanel.global.bulks'
                    })}
                    value="bultos"
                    disabled={disabled}
                  />
                  <Checkbox
                    formik={formik}
                    id="radio2"
                    name="packageType"
                    type="radio"
                    label={intl.formatMessage({
                      id: 'controlpanel.global.pallets'
                    })}
                    value="pallets"
                    disabled={disabled}
                  />
                </Col>
                <Col md={{ span: 4 }} xs={12}>
                  <Input
                    name="quantity"
                    type="number"
                    placeholder={
                      disabled === true
                        ? `${formik?.values?.quantity}`
                        : intl.formatMessage({
                            id: 'controlpanel.global.number'
                          })
                    }
                    label={intl.formatMessage({
                      id: 'controlpanel.global.number'
                    })}
                    disabled={disabled || !formik.values.packageType}
                  />
                </Col>
                <PackagesList
                  packageType={formik.values.packageType}
                  formik={formik}
                  disabled={disabled}
                />
                <Col md={{ span: 8, offset: 2 }} xs={12}>
                  <Divider />
                </Col>
                <Col
                  md={{ span: 8, offset: 2 }}
                  className="collection-address-form__textarea"
                >
                  <Textarea
                    name="comments"
                    placeholder={
                      disabled === true
                        ? formik?.values?.comments
                        : intl.formatMessage({
                            id: 'controlpanel.global.information_for_the_carrier'
                          })
                    }
                    label={intl.formatMessage({
                      id: 'controlpanel.global.information_for_the_carrier'
                    })}
                    disabled={disabled}
                  />
                </Col>
                {disabled === false && (
                  <>
                    <Col
                      md={{ span: 4, offset: 6 }}
                      className="collection-address-form__button"
                    >
                      <Button
                        size="medium"
                        type="secondary"
                        text={intl.formatMessage({
                          id: 'controlpanel.global.save'
                        })}
                        onClick={() => formik.submitForm()}
                      />
                    </Col>
                    <Col md={{ span: 8, offset: 2 }}>
                      <FormInfo />
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

CollectionAddressForm.propTypes = {
  disabled: PropTypes.bool
};
