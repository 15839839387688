import React, { useMemo } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useLang } from 'app/context/i18n';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getColorOfType } from 'app/components/PackInfoCard/PackInfoCard.utils';
import { parserPack } from 'app/utils/parsers/pack.parser';
import PackInfoAction from 'app/components/PackInfoCard/components/PackInfoAction';
import PackInfoDate from 'app/components/PackInfoCard/components/PackInfoDate';
import PackInfoHeader from 'app/components/PackInfoCard/components/PackInfoHeader';

import 'app/components/PackInfoCard/PackInfoCard.scss';

export default function PackInfoCard({
  title,
  packId,
  packNameText,
  isRenovationActive,
  date = null,
  useSmallView = false
}) {
  const intl = useIntl();
  const lang = useLang();
  const parsedPack = useMemo(
    () => parserPack(packId, intl, lang),
    [packId, intl, lang]
  );
  const { type, actions } = parsedPack;

  const [isActionMultipleLines, actionColSize] = useMemo(() => {
    let isMultipleLines = false;
    let colSize = 12;

    if (!useSmallView) {
      const isWithoutPack = packId === 0;
      const haveOnlyOneAction = actions.length === 1;
      isMultipleLines = !(haveOnlyOneAction || isWithoutPack);
      if (isMultipleLines) {
        colSize = 6;
      }
    }

    return [isMultipleLines, colSize];
  }, [actions, packId, useSmallView]);
  const colors = useMemo(() => getColorOfType(type), [type]);

  return (
    <div
      className={classNames(['bb-pack-info-card'], {
        'bb-pack-info-card--small': useSmallView,
        'bb-pack-info-card--normal': !useSmallView
      })}
    >
      <PackInfoHeader
        title={title}
        packId={packId}
        packNameText={packNameText}
        colors={colors}
        useSmallView={useSmallView}
      />
      <div className="bb-pack-info-card__cards-container">
        <Row>
          {actions.map((action, index) => (
            <Col key={`pack-info-action-${index}`} xs={12} lg={actionColSize}>
              <PackInfoAction
                action={action}
                isMultipleLines={isActionMultipleLines}
                useSmallView={useSmallView}
              />
            </Col>
          ))}
        </Row>
        {date && (
          <PackInfoDate
            date={date}
            dateText={
              isRenovationActive
                ? intl.formatMessage({
                    id: 'controlpanel.pack_info_card.next_renewal'
                  })
                : intl.formatMessage({
                    id: 'controlpanel.pack_info_card.date_of_expiry'
                  })
            }
            type={type}
            colors={colors}
          />
        )}
      </div>
    </div>
  );
}

PackInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  packId: PropTypes.number.isRequired,
  packNameText: PropTypes.string.isRequired,
  isRenovationActive: PropTypes.bool.isRequired,
  date: PropTypes.string,
  useSmallView: PropTypes.bool
};
