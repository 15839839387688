export const URLS = {
  auth: '/auth',
  credit: '/credit',
  dashboard: '/dashboard',
  devolutions: '/devolutions',
  devolutionsAdd: '/devolutions/add',
  downloads: '/downloads/:section',
  downloadsMKT: '/downloads/marketing-resources',
  downloadsSocialSelling: '/downloads/social-selling-resources',
  downloadsBanners: '/downloads/banners-newsletters',
  downloadsManuals: '/downloads/products-manuals',
  downloadsCSV: '/downloads/csv-xml-files',
  devolutionsAddWithId: '/devolutions/add/:idRma',
  error: '/error',
  forgotPassword: '/forgot-password',
  forgotPasswordSuccess: '/forgot-password-success',
  home: '/',
  invoices: '/invoices',
  login: '/login',
  logout: '/logout',
  multichannel: '/multichannel',
  myAccount: '/my-account',
  myAccountBilling: 'billing',
  myAccountInformation: 'information',
  myAccountNotifications: 'notifications',
  myAccountPassword: 'password',
  myAccountPaymentMethods: 'payment',
  myAccountSummary: 'resume',
  myAccountSyncronize: 'syncronize',
  myAccountUserData: 'user-data',
  myAccountWholesalerPack: 'wholesaler-pack',
  orderByCsv: 'order-by-csv',
  orders: '/orders',
  ordersView: 'view/:id',
  ordersDeliveryNotes: ':orderId/delivery-notes/:deliveryNoteId',
  productInformation: '/product-information',
  purse: '/purse',
  recoveryPassword: '/recovery-password',
  shops: '/shops',
  tracking: '/trackings',
  wishlist: '/wishlist',
  academy: '/bigacademy'
};
