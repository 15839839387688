export const defaultTableConfiguration = {
  wrapperClasses: 'table-responsive',
  classes: 'table table-head-custom table-vertical-center overflow-hidden',
  bootstrap4: true,
  bordered: false
};

export const defaultPgOptions = {
  custom: true,
  totalSize: 0,
  sizePerPageList: [
    { text: '10', value: 10 },
    { text: '50', value: 50 },
    { text: '100', value: 100 }
  ],
  sizePerPage: 10,
  page: 1,
  defaultSorted: [
    {
      dataField: 'id',
      order: 'desc'
    }
  ],
  keyField: 'id'
};
