import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import TagManager from 'react-gtm-module';
import * as Yup from 'yup';

import { genericRequest } from 'app/api';
import { setUser } from 'app/modules/Auth/_redux/AuthActions';
import { Modal } from 'react-bootstrap';
import Form from 'app/components/Form';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import { Select } from 'app/components/Select';
import Textarea from 'app/components/Textarea';
import { TAG_MANAGER_ID } from 'app/utils/constants';

import 'app/modules/MyAccount/pages/WholesalerPack/components/WholesalerPackCancelPackModal.scss';

export default function WholesalerPackCancelPackModal({
  show,
  handleClose,
  setIsLoading
}) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      reason: '',
      cancellationDescription: ''
    },
    validationSchema: Yup.object().shape({
      cancellationDescription: Yup.string().required(
        intl.formatMessage({ id: 'controlpanel.form.validation.required' })
      ),
      reason: Yup.string()
        .min(
          100,
          intl.formatMessage({
            id: 'controlpanel.my_account.pack.cancel_modal.min_lenght'
          })
        )
        .required(
          intl.formatMessage({ id: 'controlpanel.form.validation.required' })
        )
    }),
    onSubmit: async () => {
      setIsLoading(true);
      handleClose();
      try {
        const payload = {
          data: {
            type: 'customer-action',
            attributes: {
              type: 'pack-cancellation',
              cancellationReason: formik.values.reason,
              cancellationDescription: formik.values.cancellationDescription
            }
          }
        };

        const response = await genericRequest({
          url: 'customers/me/actions',
          method: 'POST',
          data: payload,
          getHeaders: true,
          customSuccessFeedback: {
            variant: 'success',
            content: intl.formatMessage({
              id: 'controlpanel.feedback.save_changes.success'
            }),
            autoClose: true,
            dismissible: false
          }
        });

        const newUser = await genericRequest({ url: 'customers/me' });
        dispatch(setUser(newUser.data));

        if (response?.status === 204) {
          const tagManagerArgs = {
            gtmId: TAG_MANAGER_ID,
            dataLayer: {
              event: 'baja',
              mail: newUser.data.attributes.email
            },
            dataLayerName: 'dataLayer'
          };
          TagManager.initialize(tagManagerArgs);
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      }

      formik.resetForm();
      // handleClose();
      setIsLoading(false);
    }
  });

  const selectOptions = [
    {
      value: 'TECHNICAL_ISSUES',
      label: intl.formatMessage({
        id: 'controlpanel.my_account.pack.cancel_modal.select_motive_1'
      })
    },
    {
      value: 'SWITCHING_COMPANY',
      label: intl.formatMessage({
        id: 'controlpanel.my_account.pack.cancel_modal.select_motive_2'
      })
    },
    {
      value: 'NOT_ACHIEVE_RESULTS',
      label: intl.formatMessage({
        id: 'controlpanel.my_account.pack.cancel_modal.select_motive_3'
      })
    },
    {
      value: 'PROFITABILITY',
      label: intl.formatMessage({
        id: 'controlpanel.my_account.pack.cancel_modal.select_motive_4'
      })
    },
    {
      value: 'SHUTTING_DOWN_COMPANY',
      label: intl.formatMessage({
        id: 'controlpanel.my_account.pack.cancel_modal.select_motive_5'
      })
    },
    {
      value: 'SERVICE_PAUSE',
      label: intl.formatMessage({
        id: 'controlpanel.my_account.pack.cancel_modal.select_motive_6'
      })
    },
    {
      value: 'HOW_TO_USE',
      label: intl.formatMessage({
        id: 'controlpanel.my_account.pack.cancel_modal.select_motive_7'
      })
    },
    {
      value: 'MISSING_FEATURES',
      label: intl.formatMessage({
        id: 'controlpanel.my_account.pack.cancel_modal.select_motive_8'
      })
    },
    {
      value: 'CUSTOMER_SUPPORT',
      label: intl.formatMessage({
        id: 'controlpanel.my_account.pack.cancel_modal.select_motive_9'
      })
    },
    {
      value: 'OTHER',
      label: intl.formatMessage({
        id: 'controlpanel.my_account.pack.cancel_modal.select_motive_10'
      })
    }
  ];

  return (
    <Modal
      className="bb-wholesaler-pack-modal"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header>
        <p className="bold">
          {intl.formatMessage({
            id: 'controlpanel.my_account.pack.cancel_modal.title'
          })}
        </p>
        <Icon icon="xmark" size="lg" onClick={handleClose} swapOpacity />
      </Modal.Header>
      <Modal.Body>
        <p>
          {intl.formatMessage({
            id: 'controlpanel.my_account.pack.cancel_modal.message'
          })}
        </p>
        <p className="bb-wholesaler-pack-modal__label">
          <span className="bb-wholesaler-pack-modal__span">*</span>
          {intl.formatMessage({
            id: 'controlpanel.my_account.pack.cancel_modal.select_title'
          })}
        </p>
        <Form formik={formik}>
          <Select
            formik={formik}
            id="cancellationDescription"
            name="cancellationDescription"
            placeholder={intl.formatMessage({
              id: 'controlpanel.my_account.pack.cancel_modal.select_placeholder'
            })}
            options={selectOptions}
          />
        </Form>
        <p className="bb-wholesaler-pack-modal__label">
          {intl.formatMessage({
            id: 'controlpanel.my_account.pack.cancel_modal.label'
          })}
        </p>
        <Form formik={formik}>
          <Textarea
            formik={formik}
            name="reason"
            onChange={(text) => formik.setFieldValue('textarea_B', text)}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text={intl.formatMessage({
            id: 'controlpanel.global.back'
          })}
          type="default"
          size="big"
          onClick={handleClose}
        />
        <Button
          text={intl.formatMessage({
            id: 'controlpanel.my_account.pack.cancel_modal.confirm'
          })}
          type="primary"
          size="big"
          onClick={formik.submitForm}
        />
      </Modal.Footer>
    </Modal>
  );
}

WholesalerPackCancelPackModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired
};
