import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import Button from 'app/components/Button';
import Popup from 'app/components/Popup';
import Textarea from 'app/components/Textarea';
import Spinner from 'app/components/Spinner';

import 'app/modules/Orders/components/Modals/CancelOrder/CancelOrder.scss';
import { singleOrderActions } from 'app/api/singleOrderActions';

export default function CancelOrder({
  modalDeleteData = {
    show: false,
    orderId: null
  },
  setModalDeleteData = null,
  handleUpdateOrdersList
}) {
  const [loading, setLoading] = useState(false);
  const popupBody = useRef(null);
  const { show, orderId } = modalDeleteData;
  const intl = useIntl();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const minHeight = useMemo(() => popupBody?.current?.clientHeight, [loading]);

  const onCancel = () => {
    setModalDeleteData({
      show: false,
      orderId: null
    });
  };

  const onSubmit = async (values) => {
    try {
      const request = await singleOrderActions({
        id: orderId,
        type: 'cancel-order',
        message: values?.cancelOrderMessage,
        setLoading,
        customSuccessFeedback: {
          variant: 'success',
          content: intl.formatMessage({
            id: 'controlpanel.global.order_cancell'
          }),
          autoClose: true,
          dismissible: false
        }
      });

      if (request) {
        handleUpdateOrdersList();
        onCancel();
      }
    } catch (error) {
      onCancel();
    }
  };

  return (
    <Formik
      initialValues={{
        cancelOrderMessage: ''
      }}
      validationSchema={Yup.object().shape({
        cancelOrderMessage: Yup.string().required(
          intl.formatMessage({ id: 'controlpanel.form.validation.required' })
        )
      })}
      onSubmit={async (values) => {
        // eslint-disable-next-line no-console
        await onSubmit(values);
        return false;
      }}
    >
      {(formik) => (
        <Popup
          show={show}
          header={
            <div className="bb-popup-cancelorder__header">
              <p className="bold">
                {intl.formatMessage({ id: 'controlpanel.orders.cancel' })}
              </p>
            </div>
          }
          body={
            loading ? (
              <Spinner containerHeight={minHeight || 0} size="4x" />
            ) : (
              <div className="bb-popup-cancelorder__body" ref={popupBody}>
                <p className="bb-popup-cancelorder__body__question">
                  {intl.formatMessage(
                    {
                      id: 'controlpanel.orders.modal_delete.confirmation_msg'
                    },
                    {
                      id: orderId
                    }
                  )}
                </p>
                <p className="bb-popup-cancelorder__body__explain">
                  {intl.formatMessage({
                    id: 'controlpanel.orders.modal_delete.label'
                  })}
                </p>
                <Textarea formik={formik} name="cancelOrderMessage" />
              </div>
            )
          }
          footer={
            <div className="bb-popup-cancelorder__footer">
              <Button
                text={intl.formatMessage({ id: 'controlpanel.global.cancel' })}
                type="default"
                size="big"
                typeButton="button"
                onClick={onCancel}
                disabled={loading}
              />
              <Button
                text={intl.formatMessage({ id: 'controlpanel.orders.cancel' })}
                type="primary"
                size="big"
                typeButton="submit"
                onClick={formik.handleSubmit}
                disabled={loading}
              />
            </div>
          }
          handleClose={onCancel}
        />
      )}
    </Formik>
  );
}

CancelOrder.propTypes = {
  modalDeleteData: PropTypes.shape({
    show: PropTypes.bool,
    orderId: PropTypes.string
  }),
  setModalDeleteData: PropTypes.func,
  handleUpdateOrdersList: PropTypes.func.isRequired
};
