// Packs que permiten la descarga de Material de Marketing, Social Selling y Newsletters
export const DOWNLOADS_RESOURCES_PERMITED = [
  2, // Pro
  150, // Business
  151, // Premium
  201, // B2B
  202, // Dropship
  203, // Enterprise
  210, // Ecommerce
  211 // Marketplaces
];

// Packs que permiten la descarga de Manuales de producto
export const DOWNLOAD_MANUALS_PERMITED = [
  2, // Pro
  150, // Business
  151, // Premium
  201, // B2B
  202, // Dropship
  203, // Enterprise
  210, // Ecommerce
  211 // Marketplaces
];
// Packs que permiten la descarga de CSVs de productos
export const DOWNLOAD_CSV_PERMITED = [
  2, // Pro
  150, // Business
  151, // Premium
  202, // Dropship
  203, // Enterprise
  210, // Ecommerce
  211 // Marketplaces
];
