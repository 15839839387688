import React from 'react';

import {
  PaginationLinks,
  PaginationToolbar
} from 'app/components/DataTableComponents/components/Pagination';

import 'app/components/Pagination/Pagination.scss';

export default function Pagination() {
  return (
    <div className="pagination">
      <div className="pagination__container">
        <PaginationLinks />
        <PaginationToolbar />
      </div>
    </div>
  );
}
