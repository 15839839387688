import React, { isValidElement } from 'react';

import classNames from 'classnames';

import { NotificationCloseButton } from 'app/components/Notification/components';
import { CONTENT_PROPS } from 'app/components/Notification/utils';
import { Colors } from 'app/utils/colors';

export default function NotificationContent({
  content = null,
  children = null,
  className = null,
  closeIcon = {
    icon: 'xmark',
    prefix: 'fad',
    color: Colors.bbGrey2
  },
  handleClose = null,
  dismissible = false
}) {
  if (children && isValidElement(children)) {
    return children;
  }

  const classes = classNames('bb-notification__body', className);

  return (
    <div className={classes}>
      {typeof content === 'string' ? (
        <p
          dangerouslySetInnerHTML={{ __html: content }}
          className="small bb-notification__body--text"
        />
      ) : (
        content
      )}
      {dismissible && (
        <NotificationCloseButton
          className="bb-notification__close-btn bb-notification__close-btn--in-body"
          closeIcon={closeIcon}
          handleClose={handleClose}
        />
      )}
    </div>
  );
}

NotificationContent.propTypes = CONTENT_PROPS;
