import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useLang } from 'app/context/i18n';

import { Colors } from 'app/utils/colors';
import { formatCurrency } from 'app/utils/currency';
import { getCSV } from 'app/api';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import Spinner from 'app/components/Spinner';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

export default function Header() {
  const intl = useIntl();
  const lang = useLang();
  const [loading, setLoading] = useState(false);
  const {
    api,
    filters: { filters }
  } = useDataTableContext();

  const [wallet, setWallet] = useState(0);

  useEffect(() => {
    setWallet(api?.apiResponse?.meta?.wallet || 0);
  }, [api]);

  let icon = <Icon icon="download" color={Colors.bbSecondary} />;
  if (loading) {
    icon = <Spinner />;
  }

  return (
    <div className="purse-card-movements__header">
      <p className="bold">
        {intl.formatMessage({ id: 'controlpanel.purse.current_balance' })}
        <span>{` ${formatCurrency(wallet)}`}</span>
      </p>
      <Button
        size="big"
        text={intl.formatMessage({ id: 'controlpanel.purse.download' })}
        icon={icon}
        tooltipText={intl.formatMessage({
          id: 'controlpanel.purse.button_csv.tooltip'
        })}
        onClick={async () => {
          await getCSV({
            fileType: 'wallet-movement',
            selectAll: true,
            filters,
            lang,
            setLoading
          });
        }}
        disabled={api?.apiResponse?.meta?.count === 0}
      />
    </div>
  );
}
