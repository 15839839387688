import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { clone, filter, map } from 'lodash';

import { getOptions } from 'app/modules/OrderView/components/DeliveryNotes/config/Products';
import GetColDataTable, {
  getTableEstructure
} from 'app/modules/OrderView/config/DeliveryNotes/tableProduct';
import { BasicDataTable } from 'app/components/DataTable';
import { Colors } from 'app/utils/colors';
import Icon from 'app/components/Icon';
import SearchBar from 'app/components/SearchBar';

import useDataProviderContext from 'app/hooks/useDataProviderContext';

import Button from 'app/components/Button/Button';
import DeliveryNotesPopupProducts from '../DeliveryNotesPopupProducts';

export default function Products() {
  const intl = useIntl();
  const { deliveryNote, products } = useDataProviderContext();

  const [entities, setEntities] = useState(products);

  const [showPopupProducts, setShowPopupProducts] = useState(false);

  const { numberOfPackages } = deliveryNote?.attributes;

  const formik = useFormikContext();

  const handleDelete = () => {
    formik.setFieldValue('search', formik.initialValues.search);
  };

  useEffect(() => {
    if (formik?.values?.search || formik?.values?.selectedPackage) {
      filterProducts(formik?.values);
    } else {
      setEntities(products);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values]);

  const filterProducts = (formikFilters) => {
    let newEntities = clone(products);

    map(formikFilters, (filterValue, filterName) => {
      if (filterValue) {
        const strToSearch = filterValue.toLowerCase();
        const arrayFiltered = filter(newEntities, (obj) => {
          const { name, quantity, reference } = obj.attributes;
          if (filterName === 'selectedPackage') {
            return (
              obj.attributes.package.toLowerCase().search(strToSearch) >= 0
            );
          }

          return (
            name.toLowerCase().search(strToSearch) >= 0 ||
            obj.attributes.package.toLowerCase().search(strToSearch) >= 0 ||
            String(quantity).search(strToSearch) >= 0 ||
            reference.toLowerCase().search(strToSearch) >= 0
          );
        });

        newEntities = arrayFiltered;
      }
    });

    setEntities(newEntities);
  };

  return (
    <Row>
      <Col xs={12} md={5} className="bb-data-table__searchbar">
        <SearchBar
          placeholder={intl.formatMessage({
            id: 'controlpanel.global.search'
          })}
          name="search"
          value={formik.values?.search || ''}
          onClear={() => handleDelete()}
        />
      </Col>

      {numberOfPackages > 1 && (
        <Col className="bb-data-table__filter" md={5}>
          <Button
            onClick={() => setShowPopupProducts(true)}
            text={intl.formatMessage({
              id: 'controlpanel.global.filters'
            })}
            type="secondary"
            size="big"
            icon={<Icon icon="filter" prefix="fas" color={Colors.bbWhite} />}
          />
          <DeliveryNotesPopupProducts
            setEntities={setEntities}
            setEntitiesFilter={setEntities}
            show={showPopupProducts}
            setShow={setShowPopupProducts}
          />
        </Col>
      )}

      <Col md={12}>
        <BasicDataTable
          id="delivery-notes-products"
          columns={GetColDataTable(numberOfPackages)}
          entities={getTableEstructure(entities)}
          keyField="reference"
          options={getOptions()}
        />
      </Col>
    </Row>
  );
}
