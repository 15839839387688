import React from 'react';

import { ALL, NONE, READ, NOT_READ } from 'app/modules/Trackings/utils';
import { sortCaret, headerSortingStyle } from 'app/components/DataTable/utils';
import { isMobile } from 'app/utils/sizes';
import {
  FormatIcon,
  FormatGeneric,
  FormatActions,
  FormatStatus
} from 'app/modules/Trackings/utils/formatters';
import { DateFormatter } from 'app/utils/columnsFormatters/DateFormatter';
import { getGroupedStatus } from 'app/hooks/useTrackingsStatus.hook';

export const getColumnsToDataTable = (intl, ...rest) => [
  {
    dataField: 'id',
    text: '',
    hidden: true,
    sort: false,
    draggable: false
  },
  {
    dataField: 'icon',
    text: '',
    hidden: false,
    sort: false,
    draggable: false
  },
  {
    dataField: 'date',
    text: intl.formatMessage({
      id: 'controlpanel.trackings.table.date'
    }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'orderId',
    text: intl.formatMessage({
      id: 'controlpanel.trackings.table.order'
    }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'internalReference',
    text: intl.formatMessage({
      id: 'controlpanel.global.internal_reference'
    }),
    sort: true,
    hidden: true,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'status',
    text: intl.formatMessage({
      id: 'controlpanel.trackings.table.status'
    }),
    sort: true,
    hidden: isMobile(),
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'carrier',
    text: intl.formatMessage({
      id: 'controlpanel.trackings.table.carrier'
    }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'tracking',
    text: intl.formatMessage({ id: 'controlpanel.global.trackings' }),
    sort: true,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'expeditionDate',
    text: intl.formatMessage({
      id: 'controlpanel.global.filters.expedition'
    }),
    sort: true,
    hidden: true,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  {
    dataField: 'actions',
    text: intl.formatMessage({
      id: 'controlpanel.trackings.table.actions'
    }),
    sort: false,
    hidden: false,
    draggable: true,
    sortCaret,
    headerSortingStyle
  },
  ...rest
];

export const getDataTableStructure = (data) =>
  data.map(
    ({
      id,
      attributes: {
        date = null,
        deliveryNoteNumber = null,
        orderId = null,
        internalReference = null,
        status = null,
        carrier = null,
        carrierStatus = null,
        tracking = null,
        expeditionDate = null,
        read = null,
        carrierLink = null,
        statusKey = null
      }
    }) => ({
      id,
      icon: <FormatIcon statusKey={statusKey} />,
      date: <DateFormatter date={date} />,
      deliveryNoteNumber: (
        <FormatGeneric
          text={deliveryNoteNumber}
          read={read}
          statusKey={statusKey}
        />
      ),
      orderId: (
        <FormatGeneric text={orderId} read={read} statusKey={statusKey} />
      ),
      internalReference: (
        <FormatGeneric
          text={internalReference}
          read={read}
          statusKey={statusKey}
        />
      ),
      status: (
        <FormatStatus
          statusKey={statusKey}
          status={status}
          carrierStatus={carrierStatus}
        />
      ),
      carrier: (
        <FormatGeneric text={carrier} read={read} statusKey={statusKey} />
      ),
      carrierStatus,
      tracking: (
        <FormatGeneric text={tracking} read={read} statusKey={statusKey} />
      ),
      expeditionDate: <DateFormatter date={expeditionDate} />,
      read,
      carrierLink: (
        <FormatGeneric text={carrierLink} read={read} statusKey={statusKey} />
      ),
      statusKey: (
        <FormatGeneric text={statusKey} read={read} statusKey={statusKey} />
      ),
      actions: <FormatActions id={id} orderId={orderId} read={read} />
    })
  );

export const getNoResultsConfig = (intl) => ({
  text: intl.formatMessage({ id: 'controlpanel.trackings.no_results' }),
  icon: 'location-dot'
});

export const getDropdownOptions = (intl) => [
  {
    id: 0,
    title: intl.formatMessage({ id: 'controlpanel.global.all' }),
    value: ALL
  },
  {
    id: 1,
    title: intl.formatMessage({ id: 'controlpanel.global.none' }),
    value: NONE
  },
  {
    id: 2,
    title: intl.formatMessage({ id: 'controlpanel.global.read' }),
    value: READ
  },
  {
    id: 3,
    title: intl.formatMessage({ id: 'controlpanel.global.not_read' }),
    value: NOT_READ
  }
];

export const getCustomDropdownLogic = (option) => {
  if (option === READ)
    return { filterName: 'read', filterFunction: ({ read }) => read };
  if (option === NOT_READ)
    return { filterName: 'notRead', filterFunction: ({ read }) => !read };
  return null;
};

export const rowClasses = (row) => {
  const statusRow = row?.status?.props?.statusKey;
  const { DELIVERY_EXCEPTION, OTHER_EXCEPTION } = getGroupedStatus();

  let extraClasses = '';

  if (row?.read) {
    extraClasses += 'bold';
  }

  if ([...DELIVERY_EXCEPTION, ...OTHER_EXCEPTION].includes(statusRow)) {
    extraClasses += ' red';
  }

  return extraClasses;
};
