import React, { useEffect, useLayoutEffect, useState } from 'react';

import { filter, size } from 'lodash';
import { useIntl } from 'react-intl';

import Card from 'app/components/Card';
import { genericRequest } from 'app/api';
import { parserCourses } from 'app/utils/parsers/courses.parser';
import { useSubheader } from 'app/context/SubheaderContext';
import useNotificationsServiceHook from 'app/hooks/useNotificationsService.hook';
import UserNotificationAlert from 'app/components/UserNotificationAlert';
import CourseCard from 'app/components/CourseCard';
import { ACADEMY_NAME } from 'app/utils/constants';

import 'app/pages/Academy/Academy.scss';

export default function Academy() {
  const intl = useIntl();

  const {
    notifications: { alerts }
  } = useNotificationsServiceHook();

  const alertsFiltered = filter(
    alerts,
    (alert) => alert?.attributes?.section === 'ACADEMY'
  );

  const subheader = useSubheader();
  const { setTitle, setIcon, setBreadcrumbs } = subheader;
  const [dataCourses, setDataCourses] = useState(null);
  useLayoutEffect(() => {
    setTitle(ACADEMY_NAME);
    setIcon({ icon: 'book-open-cover' });
    setBreadcrumbs([]);
  }, [setTitle, setBreadcrumbs, setIcon, intl]);

  useEffect(() => {
    const getCourses = async () => {
      const courses = await genericRequest({
        url: 'academy-courses'
      });
      const parsedData = parserCourses(courses);
      setDataCourses(parsedData);
    };
    getCourses();
  }, [intl]);
  return (
    <>
      {size(alertsFiltered) > 0 && (
        <UserNotificationAlert alerts={alertsFiltered} />
      )}
      <Card
        extraClass="bb-courses"
        icon="book-open-cover"
        title={intl.formatMessage({
          id: 'controlpanel.academy.title'
        })}
        body={
          <div className="bb-courses-body">
            {dataCourses?.parsedCourses?.map((course, index) => (
              <CourseCard
                id={course.id}
                features={course.features}
                image={course.image}
                link={course.link}
                linktext={course.linktext}
                key={index}
                video={course.video}
                descriptionLines={4}
                title={course.title}
                teacher={course.teacher}
                description={course.description}
                serviceCheckout={course.serviceCheckout}
                tags={course.tags}
                discount={course.discount}
                btnLink={course.btnLink}
                state={course.state}
              />
            ))}
          </div>
        }
      />
    </>
  );
}
