/* eslint-disable no-nested-ternary */
import React, { useMemo, useState, useRef } from 'react';

import { Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { formatDateToLocale } from 'app/utils/dates';
import { genericRequest } from 'app/api';
import { PACK_TYPE_NUMBER } from 'app/utils/constants';
import { setUser } from 'app/modules/Auth/_redux/AuthActions';
import { URLS } from 'app/config/urls';
import Button from 'app/components/Button';
import FormCard from 'app/components/FormCard';
import FormSpinner from 'app/components/FormSpinner';
import MyAccountLayout from 'app/modules/MyAccount/components/MyAccountLayout';
import Spinner from 'app/components/Spinner';
import WholesalerPackForm from 'app/modules/MyAccount/pages/WholesalerPack/components/WholesalerPackForm';
import WholesalerPackWithoutPack from 'app/modules/MyAccount/pages/WholesalerPack/components/WholesalerPackWithoutPack';

import 'app/modules/MyAccount/pages/WholesalerPack/WholesalerPack.scss';

export default function WholesalerPack() {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const container = useRef(null);

  const dispatch = useDispatch();
  const { user: loggedInfo } = useSelector((state) => state.auth);

  const { id } = loggedInfo;

  const { packName, dateDue, packId, walletPackPayment } = useMemo(
    () => loggedInfo?.attributes,
    [loggedInfo?.attributes]
  );

  const date = useMemo(() => formatDateToLocale(dateDue), [dateDue]);

  const havePack = useMemo(
    () => packId && packId !== PACK_TYPE_NUMBER.WITHOUT,
    [packId]
  );

  if (isLoading) {
    <MyAccountLayout currentPagePath={URLS.myAccountWholesalerPack}>
      <Col md={8}>
        <FormSpinner title="controlpanel.my_account.pack.title" showButtons />
      </Col>
    </MyAccountLayout>;
  }

  return (
    <MyAccountLayout currentPagePath={URLS.myAccountWholesalerPack}>
      <Col md={8}>
        <Formik
          enableReinitialize
          initialValues={{
            expirationDate: date,
            isActivatePaymentByWallet: walletPackPayment
          }}
          onSubmit={async (values, formikBag) => {
            const response = await genericRequest({
              url: 'customers/me',
              method: 'patch',
              data: {
                data: {
                  id,
                  type: 'customer',
                  attributes: {
                    walletPackPayment: values.isActivatePaymentByWallet
                  }
                }
              },
              setLoading: setIsLoading,
              customSuccessFeedback: {
                variant: 'success',
                content: intl.formatMessage({
                  id: 'controlpanel.feedback.save_changes.success'
                }),
                autoClose: true,
                dismissible: false
              }
            });
            if (response) {
              dispatch(setUser(response?.data));
              formikBag.resetForm();
            }

            return response;
          }}
        >
          {(formik) => (
            <FormCard
              title={intl.formatMessage({
                id: 'controlpanel.my_account.pack.title'
              })}
              buttons={
                havePack && (
                  <>
                    <Button
                      size="big"
                      text={intl.formatMessage({
                        id: 'controlpanel.global.cancel'
                      })}
                      onClick={formik.resetForm}
                      disabled={isLoading}
                    />
                    <Button
                      size="big"
                      type="secondary"
                      text={intl.formatMessage({
                        id: 'controlpanel.global.save'
                      })}
                      typeButton="submit"
                      onClick={formik.submitForm}
                      disabled={!(formik.isValid && formik.dirty)}
                    />
                  </>
                )
              }
            >
              {isLoading ? (
                <Spinner
                  size="4x"
                  containerHeight={container?.current?.clientHeight}
                />
              ) : havePack ? (
                <div ref={container}>
                  <WholesalerPackForm
                    actualPackName={packName}
                    actualPackID={packId}
                    dateDue={date}
                    setIsLoading={setIsLoading}
                  />
                </div>
              ) : (
                <WholesalerPackWithoutPack />
              )}
            </FormCard>
          )}
        </Formik>
      </Col>
    </MyAccountLayout>
  );
}
