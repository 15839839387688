import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';
import 'app/components/Tag/Tag.scss';

export default function Tag({
  size = 'regular',
  color = 'grey',
  showIcon = false,
  onClickedTag = () => null,
  onClose = () => null,
  id,
  text
}) {
  return (
    <span
      role="none"
      className={`bb-tag bb-tag__${color} bb-tag__${size}`}
      onClick={() => onClickedTag(id)}
      onKeyDown={() => onClickedTag(id)}
    >
      <div>{text}</div>
      {showIcon && (
        <SVG
          src={toAbsoluteUrl('/media/svg/bigbuy/large/times.svg')}
          className="close-icon"
          onClick={() => onClose(id)}
        />
      )}
    </span>
  );
}

Tag.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['regular', 'small']),
  color: PropTypes.oneOf(['grey', 'red', 'blue', 'yellow', 'green', 'purple']),
  showIcon: PropTypes.bool,
  onClickedTag: PropTypes.func,
  onClose: PropTypes.func
};
