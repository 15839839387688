import React from 'react';
import PropTypes from 'prop-types';
import 'app/components/Dropdown/Dropdown.scss';

export default function Dropdown({
  show = false,
  onSelected = () => null,
  onClick = () => {},
  options
}) {
  const onSelectOption = (idSelected) => {
    const option = options.find((opt) => opt.id === idSelected);
    onSelected(option.value);
  };

  if (!show) return null;

  return (
    <div className="bb-dropdown" onClick={onClick} role="none">
      {options.map((option, index) => {
        const { id, title } = option;
        return (
          <div
            role="none"
            key={`${index}-${title}`}
            className="bb-dropdown__row"
            onKeyDown={() => onSelectOption(id)}
            onClick={() => onSelectOption(id)}
          >
            <span className="bb-dropdown__row__title">{title}</span>
          </div>
        );
      })}
    </div>
  );
}

const OptionItem = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.node])
});

Dropdown.propTypes = {
  options: PropTypes.arrayOf(OptionItem).isRequired,
  show: PropTypes.bool,
  onSelected: PropTypes.func,
  onClick: PropTypes.func
};
