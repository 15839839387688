/* eslint-disable object-shorthand */
import React, { useState, useEffect } from 'react';

import classNames from 'classnames';

import { PROP_TYPES } from 'app/components/Alert/utils';
import AlertStructure from 'app/components/Alert/components/AlertStructure';

import 'app/components/Alert/Alert.scss';

export default function Alert({
  background = null,
  children = null,
  autoClose = null,
  fixedTo = 'bottom',
  fixedAlignment = 'right',
  className = null,
  variant = 'info',
  ...rest
}) {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (!autoClose) {
      return;
    }
    const closeTimer = setTimeout(handleClose, 2000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(closeTimer);
  }, [autoClose]);

  if (!show) {
    return null;
  }

  const classes = classNames('bb-alert', `bb-alert-${variant}`, className);

  const componentContent = (
    <div className={classes} style={{ background: background }}>
      <AlertStructure variant={variant} handleClose={handleClose} {...rest}>
        {children}
      </AlertStructure>
    </div>
  );

  if (!fixedTo) {
    return componentContent;
  }

  const wrapperClasses = classNames('bb-alert__wrapper', {
    [`align-${fixedAlignment}`]: fixedAlignment,
    [`fixed-to-${fixedTo}`]: fixedTo
  });

  return <div className={wrapperClasses}>{componentContent}</div>;
}

Alert.propTypes = PROP_TYPES;
