import React, { isValidElement } from 'react';

import classNames from 'classnames';

import Icon from 'app/components/Icon';
import { CLOSE_BTN_PROP_TYPES } from 'app/components/Notification/utils/index';
import { Colors } from 'app/utils/colors';

const { bbGrey2 } = Colors;
export default function NotificationCloseButton({
  children = null,
  className = null,
  closeIcon = { icon: 'xmark', prefix: 'fad', color: Colors.bbGrey2 },
  handleClose = null
}) {
  if (children && isValidElement(children)) {
    return children;
  }

  const handleKeyDown = ({ code }) => {
    if (code === 'Escape' || code === 'Enter' || code === 'NumpadEnter')
      handleClose();
  };

  const classes = classNames('bb-notification__close-btn', className);

  return (
    <div
      className={classes}
      onClick={handleClose}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      {children && isValidElement(children) ? (
        children
      ) : (
        <Icon color={bbGrey2} {...closeIcon} />
      )}
    </div>
  );
}

NotificationCloseButton.propTypes = CLOSE_BTN_PROP_TYPES;
