import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import Button from 'app/components/Button';
import Popup from 'app/components/Popup';

import 'app/modules/Orders/components/Modals/RepeatOrder/RepeatOrder.scss';

export default function RepeatOrder({
  modalData = {
    show: false,
    orderId: null
  },
  setModalData = null
}) {
  const { show } = modalData;
  const intl = useIntl();

  const onCancel = () => {
    setModalData({
      show: false,
      orderId: null
    });
  };

  const handleGoToCart = () => {
    window.location.assign(`${process.env.REACT_APP_BIGBUY_URL}cart/checkout`);
  };

  return (
    <Popup
      show={show}
      header={
        <div className="bb-popup-repeat-order__header">
          <p className="bold">
            {intl.formatMessage({
              id: 'controlpanel.orders.modal_repeat.title'
            })}
          </p>
        </div>
      }
      body={
        <div className="bb-popup-repeat-order__body">
          <p>
            {intl.formatMessage({ id: 'controlpanel.orders.modal_repeat.msg' })}
          </p>
        </div>
      }
      footer={
        <div className="bb-popup-repeat-order__footer">
          <Button
            text={intl.formatMessage({
              id: 'controlpanel.global.close'
            })}
            type="default"
            size="big"
            onClick={onCancel}
          />
          <Button
            text={intl.formatMessage({
              id: 'controlpanel.orders.modal_repeat.btn'
            })}
            type="primary"
            size="big"
            onClick={handleGoToCart}
          />
        </div>
      }
      handleClose={onCancel}
    />
  );
}

RepeatOrder.propTypes = {
  modalData: PropTypes.shape({
    show: PropTypes.bool,
    orderId: PropTypes.string
  }),
  setModalData: PropTypes.func
};
