import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { clone, map, remove, size } from 'lodash';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import SVG from 'react-inlinesvg';

import { getMediaZipFiles } from 'app/api/downloads/getMediaZipFiles';
import { languages } from 'app/utils/constants';
import Button from 'app/components/Button/Button';
import Icon from 'app/components/Icon/Icon';
import Spinner from 'app/components/Spinner/Spinner';

import 'app/modules/Downloads/components/ModalLanguages/ModalLanguages.scss';

export default function ModalLanguages({
  title,
  idCategory,
  setIdCategory,
  viewModal,
  setViewModal,
  idsLang,
  setIdsLang
}) {
  const intl = useIntl();
  const handleClose = () => {
    setViewModal(false);
    setIdsLang([]);
    setIdCategory(null);
    setLoading(false);
  };
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      className="modal-language-body"
      show={viewModal}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title>
          <p className="big bold">{title}</p>
          <Icon icon="close" onClick={handleClose} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-languages-title">
          {intl.formatMessage({
            id: 'controlpanel.downloads.download_file_lang'
          })}
        </p>
        <div className="modal-languages">
          {map(languages, (language) => (
            <div
              key={language?.id}
              className="modal-languages__container"
              onClick={() =>
                handleClick(language?.lang, idsLang, setIdsLang, loading)
              }
              role="button"
              onKeyDown={() =>
                handleClick(language?.lang, idsLang, setIdsLang, loading)
              }
              tabIndex={0}
            >
              <div className="modal-languages__container-checkbox">
                <input
                  type="checkbox"
                  className="bb-checkbox"
                  readOnly
                  checked={idsLang.includes(language?.lang)}
                  disabled={loading}
                />
              </div>
              <div className="modal-languages__container-image">
                <SVG src={language?.flag} />
              </div>
              <div className="modal-languages__container-text">
                <p>{language?.name}</p>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleClose}
          text={intl.formatMessage({ id: 'controlpanel.global.back' })}
          size="big"
        />
        <Button
          onClick={async () => {
            setLoading(true);
            const request = await getMediaZipFiles(
              [idCategory],
              'csv-section',
              idsLang
            );

            if (request?.links?.download_url) {
              window.location = request?.links?.download_url;
            }

            handleClose();
          }}
          text={intl.formatMessage({ id: 'controlpanel.global.download' })}
          size="big"
          type="primary"
          disabled={size(idsLang) === 0 || loading === true}
          icon={loading === true ? <Spinner /> : null}
        />
      </Modal.Footer>
    </Modal>
  );
}

ModalLanguages.propTypes = {
  title: PropTypes.string.isRequired,
  idCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setIdCategory: PropTypes.func.isRequired,
  viewModal: PropTypes.bool.isRequired,
  setViewModal: PropTypes.func.isRequired,
  idsLang: PropTypes.arrayOf(PropTypes.number).isRequired,
  setIdsLang: PropTypes.func.isRequired
};

const handleClick = (id, idsLang, setIdsLang, loading) => {
  if (loading === false) {
    const cloneIdsLang = clone(idsLang);
    if (idsLang.includes(id) === false) {
      cloneIdsLang.push(id);
      setIdsLang(cloneIdsLang);
    } else {
      setIdsLang(remove(cloneIdsLang, (n) => n !== id));
    }
  }
};
