import React from 'react';
import PropTypes from 'prop-types';

import 'app/modules/Devolutions/components/TableProducts/formatters/ProductFormatter/ProductFormatter.scss';
import { Image } from 'app/components/Image/Image';

export default function ProductFormatter({ image = '', title }) {
  return (
    <div className="product-formatter">
      <Image src={image} alt={title} />
      <div dangerouslySetInnerHTML={{ __html: title }} />
    </div>
  );
}

ProductFormatter.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired
};
