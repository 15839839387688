import React, { useCallback } from 'react';

import { DidomiSDK } from '@didomi/react';
import { usePostHog } from 'posthog-js/react';
import { useSelector } from 'react-redux';

export default function Didomi() {
  const posthog = usePostHog();
  const { user } = useSelector((state) => state.auth);
  const posthogInit = useCallback(() => {
    if (user) {
      posthog.identify(user.id.toString(), {
        email: user.attributes.email,
        user_type: user.attributes.hasPack ? 'client' : 'lead'
      });
    }
  }, [posthog, user]);

  if (!user) return null;

  return (
    <DidomiSDK
      apiKey={process.env.REACT_APP_PUBLIC_DIDOMI_API_KEY}
      noticeId={process.env.REACT_APP_PUBLIC_DIDOMI_NOTICE_ID}
      onReady={(didomi) => {
        if (
          didomi.getUserConsentStatusForVendor('c:posthog-LU94YpzD') === true
        ) {
          posthogInit();
        }
      }}
    />
  );
}
