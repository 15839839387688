export const formatCurrency = (
  price,
  decimal = 2,
  currencyFormat = 'es',
  currency = 'EUR'
) => {
  switch (currencyFormat) {
    case 'en':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: decimal
      }).format(price);
    default:
      return new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency,
        minimumFractionDigits: decimal
      }).format(price);
  }
};
