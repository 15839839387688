import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { map } from 'lodash';
import { useIntl } from 'react-intl';

import 'app/components/Pagination/PaginationToolbar/PaginationToolbar.scss';

export function PaginationToolbar({ paginationProps }) {
  const { sizePerPageList, sizePerPage, onSizePerPageChange, totalSize, page } =
    paginationProps;
  const intl = useIntl();
  const onSizeChange = (newSize) => {
    onSizePerPageChange(newSize);
  };

  return (
    <div className="pagination-toolbar">
      <DropdownButton
        title={sizePerPage}
        className="pagination-toolbar__dropdown"
        drop="down"
      >
        {map(sizePerPageList, (option, index) => {
          const { text, value } = option;
          return (
            <Dropdown.Item
              key={index}
              active={sizePerPage === value}
              eventKey={index}
              onClick={() => onSizeChange(value)}
              className="pagination-toolbar__dropdown-menu-item"
              as="p"
            >
              {text}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
      <p className="small regular pagination-toolbar__text  d-none d-md-flex">
        {intl.formatMessage(
          {
            id: 'controlpanel.pagination.text'
          },
          {
            pageFrom: page * sizePerPage - sizePerPage + 1,
            pageTo: page * sizePerPage,
            totalResults: totalSize
          }
        )}
      </p>
    </div>
  );
}

PaginationToolbar.propTypes = {
  paginationProps: PropTypes.shape({
    totalSize: PropTypes.number.isRequired,
    sizePerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    sizePerPageList: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.number
      })
    ).isRequired,
    onSizePerPageChange: PropTypes.func.isRequired
  }).isRequired
};
