import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Field, useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import { List, ListItem } from '@material-ui/core';
import { map, size } from 'lodash';
import classNames from 'classnames';

import { getInputClasses } from 'app/components/Input/input.utils';
import Label from 'app/components/Label';

import 'app/components/Input/Input.scss';
import 'app/components/InputFilter/InputFilter.scss';

export default function InputFilter({
  arrayToFilter = null,
  disabled = false,
  label = '',
  minCharsToFilter = 3,
  onClickHandler = null,
  tooltipText = null,
  type = 'text',
  loading = false,
  onChange = null,
  name,
  placeholder
}) {
  const formik = useFormikContext();

  const value = useMemo(() => formik.values[name], [formik.values, name]);

  const [arrayFiltered, setArrayFiltered] = useState([]);
  const [showSpinner, setShowSpinner] = useState(loading);

  useEffect(() => {
    if (size(formik.values[name]) >= minCharsToFilter) {
      const newValues = filterArray(arrayToFilter, formik.values[name]);
      setArrayFiltered(newValues?.length > 1 ? newValues : []);
      setShowSpinner(false);
      return;
    }
    setArrayFiltered([]);
    setShowSpinner(false);
  }, [
    arrayToFilter,
    formik.values,
    formik.values?.[name],
    minCharsToFilter,
    name
  ]);

  return (
    <Field name={name} className="input-filter">
      {({ field, meta: { error, touched }, form }) => (
        <Form.Group className="bb-form-group form-group fv-plugins-icon-container">
          {label && (
            <Label label={label} tooltipText={tooltipText} htmlFor={name} />
          )}
          <div className="input-group">
            <input
              {...field}
              id={name}
              autoComplete="off"
              placeholder={placeholder}
              disabled={disabled}
              className={classNames(
                'form-control',
                getInputClasses(error, touched),
                {
                  showSpinner
                }
              )}
              type={type}
              onChange={(e) => {
                setShowSpinner(true);
                formik.setFieldValue(name, e.target.value);
                onChange(e);
              }}
              value={value}
            />
            {arrayFiltered?.length > 0 && (
              <List className="input-filter__results-container">
                {map(arrayFiltered, (filterResult, index) => {
                  const { text, key } = filterResult;

                  return (
                    <ListItem
                      button
                      key={index}
                      onClick={(e) => {
                        form.setFieldValue(name, key);
                        setArrayFiltered([]);

                        if (onClickHandler) {
                          onClickHandler({ e, key });
                        }
                      }}
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: text
                        }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            )}
          </div>
          {touched && error && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{error}</div>
            </div>
          )}
        </Form.Group>
      )}
    </Field>
  );
}

const filterArray = (arrayToFilter, strFilter) => {
  const filteredArray = [];

  arrayToFilter.forEach((str) => {
    const stringToLowerCase = String(str).toLowerCase();

    if (stringToLowerCase.includes(strFilter.toLowerCase())) {
      const pattern = new RegExp(strFilter, 'gi');
      filteredArray.push({
        text: stringToLowerCase.replace(pattern, `<b>${strFilter}</b>`),
        key: str
      });
    }
  });
  return filteredArray;
};

InputFilter.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number']),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  onClickHandler: PropTypes.func,
  minCharsToFilter: PropTypes.number,
  arrayToFilter: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  onChange: PropTypes.func
};
