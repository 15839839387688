export const filterObjectKeys = ({ values, keys }) => {
  const cleanObject = {};

  keys.forEach((key) => {
    if (!values?.[key]) {
      return;
    }
    cleanObject[key] = values[key];
  });

  return cleanObject;
};
