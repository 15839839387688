import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { getSelectFilter } from 'app/components/DataTableFilters/Components';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';
import Button from 'app/components/Button';
import Divider from 'app/components/Divider';
import Icon from 'app/components/Icon';

export default function DatatableFiltersRow({
  filter,
  selectOptions,
  getComponentFilter,
  hasMoreThanOneFilter
}) {
  const {
    filters: { filters, removeFilterFromModal, renderedFilters }
  } = useDataTableContext();
  const intl = useIntl();
  const { setFieldValue } = useFormikContext();

  const filtersSelected = useMemo(
    () => hasMoreThanOneFilter || !renderedFilters.includes('all'),
    [hasMoreThanOneFilter, renderedFilters]
  );

  return (
    <Row className="datatable-filters-body__row">
      {!filtersSelected && (
        <Col md={12} className="datatable-filters-body__row-element">
          <p>
            {intl.formatMessage({
              id: 'controlpanel.global.no_filters_selected'
            })}
          </p>
        </Col>
      )}
      <Col md={5} className="datatable-filters-body__row-element">
        {getSelectFilter({ selectOptions, filter, renderedFilters })}
      </Col>
      <Col md={5} className="datatable-filters-body__row-element">
        {getComponentFilter(filter)}
      </Col>
      <Col md={2} className="datatable-filters-body__row-element-button">
        {filtersSelected && (
          <Button
            icon={<Icon icon="trash" color="#0090FF" />}
            color="blue"
            size="big"
            style={{ marginBottom: '5px' }}
            onClick={() => {
              removeFilterFromModal(filter);
              setFieldValue(filter, filters?.filter);
            }}
          />
        )}
      </Col>
      <Col md={12} className="datatable-filters-body__row-divider">
        <Divider type="dash" />
      </Col>
    </Row>
  );
}

DatatableFiltersRow.propTypes = {
  filter: PropTypes.string.isRequired,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      disabled: PropTypes.bool
    })
  ).isRequired,
  getComponentFilter: PropTypes.func.isRequired,
  hasMoreThanOneFilter: PropTypes.bool.isRequired
};
