import React from 'react';
import Topbar from 'app/components/Topbar';
import AnimateLoading from 'app/components/AnimateLoading';

export function Header() {
  return (
    <>
      {/* begin::Header */}
      <div className="header header-fixed" id="kt_header">
        {/* begin::Container */}
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          <AnimateLoading />
          <div
            className="header-menu-wrapper header-menu-wrapper-left"
            id="kt_header_menu_wrapper"
          />
          {/* begin::Topbar */}
          <Topbar />
          {/* end::Topbar */}
        </div>
        {/* end::Container */}
      </div>
      {/* end::Header */}
    </>
  );
}
