import { genericRequest } from 'app/api';

export async function checkEmail(email) {
  const response = await genericRequest({
    url: 'general-services/actions',
    method: 'POST',
    data: {
      data: {
        type: 'general-action',
        attributes: {
          type: 'email-check',
          email
        }
      }
    },
    getHeaders: true
  });
  return !response.data.data.attributes.exists;
}
