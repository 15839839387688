import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import classNames from 'classnames';

import Button from 'app/components/Button';

export default function MIPCard({
  className,
  titleTranslationKey,
  subtitleTranslationKey,
  contents,
  button: { text: buttonText, handleClick }
}) {
  const intl = useIntl();

  const classes = classNames('bb-multichannel__secondary-card', className);

  return (
    <div className={classes}>
      <div className="bb-multichannel__secondary-card__container">
        <h4 className="bb-multichannel__secondary-card__title bold">
          {intl.formatMessage({ id: titleTranslationKey })}
        </h4>
        <div className="bb-multichannel__secondary-card__contents">
          <p
            className="big bold bb-multichannel__secondary-card__subtitle"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: subtitleTranslationKey
              })
            }}
          />
          {contents.map((content, i) => (
            <p
              key={i}
              className="medium"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: content
                })
              }}
            />
          ))}
        </div>
        <div className="bb-multichannel__secondary-card__actions">
          <Button
            text={intl.formatMessage({
              id: buttonText
            })}
            type="primary"
            size="big"
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
}

MIPCard.propTypes = {
  className: PropTypes.string.isRequired,
  titleTranslationKey: PropTypes.string.isRequired,
  subtitleTranslationKey: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(PropTypes.string).isRequired,
  button: PropTypes.shape({
    text: PropTypes.string,
    handleClick: PropTypes.func
  }).isRequired
};
