import React, { isValidElement } from 'react';

import classNames from 'classnames';

import {
  NotificationIcon,
  NotificationHeader,
  NotificationContent,
  NotificationActions
} from 'app/components/Notification/components';
import { STRUCTURE_PROP_TYPES } from 'app/components/Notification/utils';
import { Colors } from 'app/utils/colors';

export default function NotificationStructure({
  header = null,
  content = null,
  infoIcon = null,
  closeIcon = { icon: 'xmark', prefix: 'fad', color: Colors.bbGrey2 },
  button = null,
  children = null,
  handleClose = null,
  className = null,
  dismissible = false,
  actions = null,
  variant = null
}) {
  if (children && isValidElement(children)) {
    return children;
  }

  const classes = classNames('bb-notification__structure', className);
  return (
    <>
      <div className={classes}>
        {infoIcon && <NotificationIcon infoIcon={infoIcon} variant={variant} />}
        <div className="bb-notification__content">
          {header && (
            <NotificationHeader
              header={header}
              closeIcon={closeIcon}
              handleClose={handleClose}
              dismissible={dismissible}
            />
          )}
          {content && (
            <NotificationContent
              content={content}
              closeIcon={header ? null : closeIcon}
              handleClose={header ? null : handleClose}
              dismissible={header ? false : dismissible}
            />
          )}
        </div>
      </div>
      <div className="bb-notification__actions">
        {actions ||
          (button && <NotificationActions actions={actions} button={button} />)}
      </div>
    </>
  );
}

NotificationStructure.propTypes = STRUCTURE_PROP_TYPES;
