import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-named-default
import { default as SelectComponent } from 'react-select';
import classNames from 'classnames';
import { map, concat, findKey } from 'lodash';
import { useFormikContext } from 'formik';

import Label from 'app/components/Label';

import 'app/components/Select/Select.scss';

export function Select({
  disabled = false,
  searchable = false,
  clearable = false,
  multiple = false,
  label = '',
  tooltipText = '',
  placeholder = '',
  textAppend = '',
  defaultValue = [],
  bottomComponent = null,
  onChange = null,
  options = [],
  reactSelect = null,
  id,
  name,
  loading: loadingProp = false
}) {
  const [isLoading, setisLoading] = useState(loadingProp);
  const [firstRender, setFirstRender] = useState(true);

  const loading = useMemo(
    () => loadingProp || isLoading,
    [isLoading, loadingProp]
  );

  const formik = useFormikContext();
  const returnValue = (objectValues) => {
    setisLoading(true);

    if (multiple) {
      let result = [];
      map(objectValues, (value) => {
        result = concat(result, value.value);
      });
      formik.setFieldValue(name, result, false);
      setisLoading(false);
      return;
    }

    formik.setFieldValue(name, objectValues ? objectValues?.value : '', false);

    if (onChange) {
      onChange(objectValues);
    }
    setisLoading(false);
  };

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    formik.setErrors(name, {});
    formik.setFieldTouched(name, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.[name]]);

  return (
    <div
      className={classNames('bb-select', {
        error: formik.touched[name] && formik.errors[name]
      })}
    >
      {label && (
        <Label label={label} tooltipText={tooltipText} htmlFor={name} />
      )}
      <div
        className={classNames('input-group', {
          'no-border-left': textAppend
        })}
      >
        {textAppend && (
          <div className="input-group-append">
            <p
              className={classNames(`input-group-text regular `, {
                disabled
              })}
            >
              {textAppend}
            </p>
          </div>
        )}
        <SelectComponent
          id={id}
          name={name}
          options={options}
          isDisabled={disabled}
          isLoading={loading}
          isClearable={clearable}
          isSearchable={searchable}
          isMulti={multiple}
          defaultValue={defaultValue}
          onChange={returnValue}
          placeholder={placeholder}
          blurInputOnSelect
          {...reactSelect}
        />
        {}
      </div>
      {formik.touched[name] && formik.errors[name] ? (
        <div
          className={classNames('fv-plugins-message-container', {
            'margin-top': bottomComponent
          })}
        >
          <div className="fv-help-block">{formik.errors[name]}</div>
        </div>
      ) : (
        bottomComponent
      )}
    </div>
  );
}

export const getSelectDefaultValues = (defaultValues, values) => {
  let keys = [];
  map(values, (value) => {
    const key = findKey(defaultValues, { value });
    keys = concat(keys, defaultValues[key]);
  });
  return keys;
};

export const getMultipleSelectDefaultValues = (options, values) => {
  const defaultValues = [];
  values.forEach((value) => {
    defaultValues.push(getSelectDefaultValues(options, value));
  });
  return defaultValues.flat();
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node.isRequired
    })
  ),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  multiple: PropTypes.bool,
  label: PropTypes.node,
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired
    })
  ),
  tooltipText: PropTypes.node,
  placeholder: PropTypes.node,
  textAppend: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bottomComponent: PropTypes.element,
  onChange: PropTypes.func,
  reactSelect: PropTypes.shape()
};
