export const parseStyles = ({ background, textColor }) => {
  if (!background && !textColor) {
    return null;
  }

  const style = {};

  if (background) style.background = background;

  if (textColor) style.color = textColor;

  return style;
};
