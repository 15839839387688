/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import PropTypes from 'prop-types';

import { filter, size } from 'lodash';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import useFeedbackContext from 'app/context/FeedbackContext';
import {
  getActiveRmaDraft,
  postRma as postRmaService,
  getRmaLinesByRmaId as getRmaLinesByRmaIdService,
  getElegibleProducts as getElegibleProductsService,
  getElegibleOrders as getElegibleOrdersService,
  deleteRmaLine as deleteRmaLineService,
  getRmaById
} from 'app/modules/Devolutions/services';
import { isLastMessageFromAdmin } from 'app/modules/Devolutions/components/ChatMessage/utils/transformMessages';

const RMAContext = createContext();

export const RMAProvider = ({ children }) => {
  const intl = useIntl();
  const { giveFeedback } = useFeedbackContext();
  const { idRma } = useParams();

  const [rmaId, setRmaId] = useState(idRma);
  const [rmaInfo, setRmaInfo] = useState([]);

  const [elegibleOrders, setElegibleOrders] = useState([]);
  const [filteredOrder, setFilteredOrder] = useState('');

  const [elegibleProducts, setElegibleProducts] = useState([]);
  const [selectedProduct, setSelecedProduct] = useState('');

  const [elegibleQuantity, setElegibleQuantity] = useState([]);
  const [selectedQuantity, setSelectedQuantity] = useState(0);

  const [rmaStatus, setRmaStatus] = useState();

  const [rmaProducts, setRmaProducts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingProductsSelect, setLoadingProductsSelect] = useState(false);

  const [isEdittingId, setIsEdittingId] = useState(false);

  const [canAskForDevolution, setCanAskForDevolution] = useState(false);

  const [expanded, setExpanded] = useState([]);
  const [rmaProductsMeta, setRmaProductsMeta] = useState([]);
  const [rmaApiInfo, setRmaApiInfo] = useState([]);

  const getRmaProducts = useCallback(async () => {
    if (!rmaId) return;

    const rmaLines = await getRmaLinesByRmaIdService(rmaId, { setLoading });

    const rmaLineWithComments = rmaLines?.data?.map((line) => {
      const included = rmaLines?.included?.filter(
        (comment) => Number(comment?.attributes?.rmaLineId) === Number(line?.id)
      );
      return {
        ...line,
        included
      };
    });

    setRmaProducts(rmaLineWithComments || []);

    const isReadyToAskForDevolution = Boolean(
      rmaLines?.meta?.readyForApplication
    );

    setRmaProductsMeta(rmaLines?.meta);

    const messages = rmaLines?.included;

    const allLinesAreValid =
      Array.isArray(rmaLines?.data) &&
      rmaLines.data.length &&
      !rmaLines.data?.some(
        ({ attributes }) =>
          attributes?.status === 'INCIDENCE' && isLastMessageFromAdmin(messages)
      );
    setCanAskForDevolution(isReadyToAskForDevolution && allLinesAreValid);
  }, [rmaId]);

  const initializeRma = useCallback(async () => {
    setLoading(true);

    // * Comprobamos si recibimos el id del rma por url
    if (rmaId) {
      // * Si lo recibimos recuperamos su información
      const getRmaInfo = await getRmaById(rmaId);
      setRmaInfo(getRmaInfo);
      setRmaStatus(getRmaInfo?.data?.attributes?.status);
      setRmaApiInfo(getRmaInfo?.data);
    } else {
      // * En caso de no recibirlo comprobamos si tiene alguno activo
      const activeRmaId = await getActiveRmaDraft();
      if (activeRmaId) {
        // * Si tiene rma activo recuperamos y cargamos los datos
        const getActiveRmaInfo = await getRmaById(activeRmaId);
        setRmaId(getActiveRmaInfo?.data?.id);
        setRmaStatus(getActiveRmaInfo?.data?.attributes?.status);
        setRmaApiInfo(getActiveRmaInfo);
      } else {
        // * Si no tiene creamos uno y cargamos los datos
        const createRma = await postRmaService(
          {
            data: {
              type: 'rma'
            }
          },
          { catchError: true }
        );

        setRmaId(createRma?.data?.id);
        setRmaStatus(createRma?.data?.attributes?.status);
        setRmaApiInfo(createRma);
      }
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    getRmaProducts().then(() => setLoading(false));
  }, [rmaId]);

  const getElegibleOrders = useCallback(async () => {
    const response = await getElegibleOrdersService(rmaId, filteredOrder);
    if (response?.meta?.orderIds?.length) {
      setElegibleOrders(response?.meta?.orderIds || []);
      getElegibleProducts();
      return;
    }

    giveFeedback({
      variant: 'danger',
      content: intl.formatMessage({
        id: 'controlpanel.error.rma_order_number'
      }),
      dismissible: true
    });
    // Solo actualizar con filteredOrder, rmaId
  }, [filteredOrder, rmaId]);

  const getElegibleProducts = useCallback(async () => {
    if (size(filteredOrder) === 8) {
      const response = await getElegibleProductsService(rmaId, filteredOrder, {
        setLoading: setLoadingProductsSelect
      });
      if (response?.meta?.products?.length > 0) {
        const validProducts = filter(
          response?.meta?.products,
          (obj) => obj?.excluded === false
        );

        if (size(validProducts) > 0) {
          setElegibleProducts(validProducts);
          return;
        }
      }

      giveFeedback({
        variant: 'danger',
        content: intl.formatMessage({
          id: 'controlpanel.error.rma_product_number'
        }),
        dismissible: true
      });

      setElegibleProducts([]);
    }
    // Solo actualizar con filteredOrder, rmaId
  }, [filteredOrder, rmaId]);

  const deleteRmaLine = useCallback(
    async ({ id, feedbackText }) => {
      await deleteRmaLineService(id, feedbackText);

      await getRmaProducts();
    },
    [getRmaProducts]
  );

  // 1. Create a new RMA or get current RMA id
  useEffect(() => initializeRma(), [initializeRma]);

  // 2. Get elegible orders when an RMA is loaded
  useEffect(() => {
    setElegibleOrders([]);
    setElegibleProducts([]);

    if (!rmaId || filteredOrder?.length < 3) return;
    getElegibleOrders();
  }, [rmaId, filteredOrder, getElegibleOrders]);

  // 3. Get elegible products when we select an elegible order
  // Se comenta el select y se añade llámada directa si getElegibleOrders tiene resultados debido a que los estados no se actualizaban correctamente
  // useEffect(() => {
  //   if (filteredOrder?.length !== 8) return;
  //   getElegibleProducts();
  // }, [filteredOrder, getElegibleProducts, rmaId]);

  // 4. Get elegible quantity when we select an elegible product
  useEffect(() => {
    if (!selectedProduct) {
      return;
    }

    const targetProduct = elegibleProducts.find(
      (product) => product.reference === selectedProduct
    );

    const availableQuantity = targetProduct?.eligibleQuantity || 0;

    if (!availableQuantity) {
      setElegibleQuantity([]);
      return;
    }

    const quantityArray = Array.from(
      { length: availableQuantity },
      (_, i) => i + 1
    );

    setElegibleQuantity(quantityArray);
  }, [elegibleProducts, selectedProduct]);

  const handleExpandRow = (rowId) => {
    if (expanded.includes(rowId)) return setExpanded([]);
    return setExpanded([rowId]);
  };

  const memoedValues = useMemo(
    () => ({
      rmaId,
      rmaInfo,
      elegibleOrders,
      filteredOrder,
      elegibleProducts,
      selectedProduct,
      elegibleQuantity,
      selectedQuantity,
      rmaStatus,
      rmaProducts,
      loading,
      loadingProductsSelect,
      isEdittingId,
      canAskForDevolution,
      expanded,
      handleExpandRow,
      setIsEdittingId,
      setElegibleOrders,
      setElegibleProducts,
      setElegibleQuantity,
      setFilteredOrder,
      setSelecedProduct,
      setSelectedQuantity,
      setLoading,
      deleteRmaLine,
      getRmaProducts,
      rmaProductsMeta,
      rmaApiInfo,
      initializeRma
    }),
    [
      rmaId,
      rmaInfo,
      elegibleOrders,
      filteredOrder,
      elegibleProducts,
      selectedProduct,
      elegibleQuantity,
      selectedQuantity,
      rmaStatus,
      rmaProducts,
      loading,
      loadingProductsSelect,
      isEdittingId,
      canAskForDevolution,
      expanded,
      deleteRmaLine,
      rmaProductsMeta,
      rmaApiInfo,
      getRmaProducts
    ]
  );
  return (
    <RMAContext.Provider value={memoedValues}>{children}</RMAContext.Provider>
  );
};

const useRMAContext = () => useContext(RMAContext);

export default useRMAContext;

RMAProvider.propTypes = { children: PropTypes.node.isRequired };
