import React, { useCallback, useEffect, useState } from 'react';

import { map } from 'lodash';
import { useIntl } from 'react-intl';

import { getColumnsToDataTable } from 'app/modules/OrderView/config/orderDetail';
import { formatCurrency } from 'app/utils/currency';
import { genericRequest } from 'app/api';
import { BasicDataTable } from 'app/components/DataTable';
import useDataProviderContext from 'app/hooks/useDataProviderContext';
import Summary from 'app/components/DataTable/Summary';

import 'app/modules/OrderView/components/OrdersLineDatatable/OrdersLineDatatable.scss';

export default function OrdersLineDatatable() {
  const intl = useIntl();
  const { order } = useDataProviderContext();
  const { id } = order;

  const [loading, setLoading] = useState(null);
  const [ordersLines, setOrdersLines] = useState([]);

  const fetchData = useCallback(async () => {
    const result = await genericRequest({
      url: `orders/${id}/order-lines`,
      setLoading
    });

    setOrdersLines(getDataTableStructure(result?.data));
  }, [id]);

  useEffect(() => {
    fetchData();
    return () => fetchData();
  }, [fetchData]);

  return (
    <div className="orders-line-datatable">
      <BasicDataTable
        id="orders-line-datatable"
        columns={getColumnsToDataTable(intl)}
        entities={ordersLines}
        keyField="name"
        loading={loading}
      />
      {!loading && <Summary lines={getSummaryLines(intl, order.attributes)} />}
    </div>
  );
}

const getDataTableStructure = (data) => {
  const dataEstructure = [];

  map(data, (value) => {
    const {
      name,
      taric,
      reference,
      quantity,
      unitPrice,
      discount,
      priceAfterDiscount,
      totalAmount
    } = value.attributes;

    dataEstructure.push({
      name,
      taric,
      reference,
      quantity,
      unitPrice: formatCurrency(unitPrice),
      discount: `${parseFloat(discount).toFixed(2)}%`,
      priceAfterDiscount: formatCurrency(priceAfterDiscount),
      totalAmount: formatCurrency(totalAmount)
    });
  });

  return dataEstructure;
};

const getSummaryLines = (intl, attributes) => {
  const {
    amount,
    equivalenceSurcharge,
    paymentMethodCost,
    shipping,
    subtotal,
    vat,
    paymentMethod
  } = attributes;

  return [
    {
      text: {
        bold: true,
        color: 'blue',
        value: intl.formatMessage({
          id: 'controlpanel.global.subtotal'
        })
      },
      price: {
        bold: true,
        color: 'blue',
        value: `${subtotal.toFixed(2)} €`
      }
    },
    {
      text: {
        value:
          paymentMethod !== 'CREDIT'
            ? intl.formatMessage({
                id: 'controlpanel.global.payment_cost'
              })
            : intl.formatMessage({
                id: 'controlpanel.global.payment_cost.credit'
              })
      },
      price: {
        bold: true,
        value: `${paymentMethodCost.toFixed(2)} €`
      }
    },
    {
      text: {
        value: intl.formatMessage({
          id: 'controlpanel.global.transport'
        })
      },
      price: {
        bold: true,
        value: `${shipping.toFixed(2)} €`
      }
    },
    {
      text: {
        bold: true,
        value: intl.formatMessage({
          id: 'controlpanel.global.taxable_base'
        })
      },
      price: {
        bold: true,
        value: `${(subtotal + paymentMethodCost + shipping).toFixed(2)} €`
      }
    },
    {
      text: {
        value: intl.formatMessage({
          id: 'controlpanel.global.iva'
        })
      },
      price: {
        value: `${vat.toFixed(2)} €`
      }
    },
    {
      text: {
        value: intl.formatMessage({
          id: 'controlpanel.global.equivalence_surcharge'
        })
      },
      price: {
        value: `${equivalenceSurcharge.toFixed(2)} €`
      }
    },
    {
      text: {
        bold: true,
        color: 'blue',
        size: 'big',
        value: intl.formatMessage({
          id: 'controlpanel.global.total'
        })
      },
      price: {
        bold: true,
        color: 'blue',
        size: 'big',
        value: `${amount.toFixed(2)} €`
      }
    }
  ];
};
