export const getInitialValues = ({ id: clientId = null, attributes }) => {
  const {
    name = '',
    surnames: lastname = '',
    email = '',
    logo = '',
    dropshippingName: company = '',
    multifactorAuthenticationEnabled
  } = attributes;

  return {
    clientId,
    name,
    lastname,
    email,
    logo,
    company,
    verification: multifactorAuthenticationEnabled ? 'yes' : 'no'
  };
};
