import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icon from 'app/components/Icon';
import { Colors } from 'app/utils/colors';
import 'app/components/Popup/Popup.scss';

export default function Popup({
  show,
  handleClose,
  body,
  header = null,
  footer = null,
  size = 'md'
}) {
  return (
    <Modal className="bb-popup" show={show} onHide={handleClose} size={size}>
      <Modal.Header>
        <>
          {header}
          <Icon
            className="bb-popup__close-icon"
            icon="xmark"
            size="lg"
            onClick={handleClose}
            swapOpacity
            color={Colors.bbGrey3}
          />
        </>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
}

Popup.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  footer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl'])
};
