const splitArray = (array, size) => {
  if (size <= 0) {
    throw Error('El tamaño de las listas resultantes debe ser al menos de 1');
  }
  if (!Array.isArray(array) || !array?.length) {
    throw Error('Debes proporcionar una lista válida');
  }

  const chunks = [];

  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    chunks.push(chunk);
  }

  return chunks;
};

export default splitArray;
