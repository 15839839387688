import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import Input from 'app/components/Input';

export default function ChangePasswordForm() {
  const intl = useIntl();

  return (
    <>
      <Row className="bb-change-password-page__form-row">
        <Col xs={12}>
          <p className="mobile medium bb-change-password-page__mobile-extra">
            {intl.formatMessage({
              id: 'controlpanel.my_account.password.title'
            })}
          </p>
        </Col>
      </Row>
      <Row className="bb-change-password-page__form-row">
        <Col lg={3}>
          <p className="bb-change-password-page__label">
            {intl.formatMessage({
              id: 'controlpanel.my_account.password.old_password_label'
            })}
          </p>
        </Col>
        <Col lg={6} className="old-password-input">
          <Input
            name="oldPassword"
            type="password"
            placeholder={intl.formatMessage({
              id: 'controlpanel.my_account.password.old_password_placeholder'
            })}
            className="old-password-input"
          />
        </Col>
      </Row>
      <Row className="bb-change-password-page__form-row">
        <Col lg={3}>
          <p className="bb-change-password-page__label">
            {intl.formatMessage({
              id: 'controlpanel.my_account.password.new_password_label'
            })}
          </p>
        </Col>
        <Col lg={6}>
          <Input
            name="newPassword"
            type="password"
            placeholder={intl.formatMessage({
              id: 'controlpanel.my_account.password.new_password_placeholder'
            })}
          />
        </Col>
      </Row>
      <Row className="bb-change-password-page__form-row">
        <Col lg={3}>
          <p className="bb-change-password-page__label">
            {intl.formatMessage({
              id: 'controlpanel.my_account.password.repeat_new_password_label'
            })}
          </p>
        </Col>
        <Col lg={6}>
          <Input
            name="repeatNewPassword"
            type="password"
            placeholder={intl.formatMessage({
              id: 'controlpanel.my_account.password.repeat_new_password_placeholder'
            })}
          />
        </Col>
      </Row>
    </>
  );
}
