import React, { useRef } from 'react';

import { Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { map, size } from 'lodash';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { filetoBase64 } from 'app/utils/file-conversor64';
import { genericRequest } from 'app/api';
import { getTableEstructure } from 'app/modules/Orders/pages/OrderByCsv/config/table';
import { Select } from 'app/components/Select';
import Button from 'app/components/Button';
import InputFile from 'app/components/InputFile';
import Spinner from 'app/components/Spinner';
import useBatchAPICalls from 'app/modules/Orders/pages/OrderByCsv/utils/useBatchAPICalls';
import useDataProviderContext from 'app/hooks/useDataProviderContext';
import usePaymentMethods from 'app/hooks/usePaymentMethods.hook';

const BATCH_SIZE = 1; // Nº de llamadas simultáneas a la API para crear cada pedido

export default function UploadForm() {
  const intl = useIntl();

  const {
    setLoading,
    setEntities,
    options,
    setOptions,
    canSendFile,
    setCanSendFile,
    setCountPendingOrders,
    loadingForm,
    setLoadingForm,
    setTotalRequestedOrders
  } = useDataProviderContext();

  const {
    user: {
      attributes: { paymentMethods }
    }
  } = useSelector((state) => state.auth);

  const MAP_PAYMENT_METHODS = usePaymentMethods();

  const batchAPICalls = useBatchAPICalls(setCountPendingOrders);

  const containerFormCsv = useRef(null);
  const getOptionsPayment = () => {
    const newPaymentMethos = [];

    map(paymentMethods, (paymentMethod) => {
      if (MAP_PAYMENT_METHODS[paymentMethod?.key]?.csvOrders === true) {
        // TODO: quitar lowercase cuando este resuelto por back
        newPaymentMethos.push({
          value: paymentMethod?.key?.toLowerCase(),
          label: MAP_PAYMENT_METHODS[paymentMethod?.key]?.translation
        });
      }
    });

    return newPaymentMethos;
  };

  return (
    <Formik
      initialValues={{
        select: '',
        file: null
      }}
      validationSchema={Yup.object({
        select: Yup.string().required(
          intl.formatMessage({
            id: 'controlpanel.form.validation.required'
          })
        ),
        file: Yup.mixed().required(
          intl.formatMessage({
            id: 'controlpanel.form.validation.file'
          })
        )
      })}
      onSubmit={async (values, formikBag) => {
        try {
          setLoading(true);
          setLoadingForm(true);
          const csv64 = await filetoBase64(values.file);

          const response = await genericRequest({
            url: 'orders/retrieve-csv-orders',
            method: 'POST',
            data: {
              data: {
                type: 'order-csv',
                attributes: {
                  csv: csv64,
                  paymentMethod: values.select
                }
              }
            },
            customSuccessFeedback: {
              variant: 'success',
              content: intl.formatMessage({
                id: 'controlpanel.feedback.upload_orders_csv.success'
              }),
              autoClose: true,
              dismissible: false
            }
          });

          if (response) {
            setEntities(getTableEstructure(response?.data?.attributes?.orders));
            setOptions({
              ...options,
              totalSize: size(response?.data?.attributes?.orders)
            });

            const orders = response?.data?.attributes?.orders;

            setCountPendingOrders(orders?.length);
            setTotalRequestedOrders(orders?.length);

            formikBag.resetForm({
              values: {
                select: '',
                file: null
              }
            });
            setCanSendFile(false);
            setLoadingForm(false);

            const ordersResult = await batchAPICalls({
              pendingOrders: orders,
              batchSize: BATCH_SIZE
            });

            setOptions((prev) => ({ ...prev, totalSize: ordersResult.length }));
            setEntities(getTableEstructure(ordersResult));
            setLoading(false);
            setCanSendFile(true);

            setTotalRequestedOrders(0);
          }

          return response;
        } catch (error) {
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.log(error);
          }
          formikBag.resetForm({
            values: {
              select: '',
              file: null
            }
          });

          formikBag.setErrors({});
          setLoadingForm(false);
          setLoading(false);

          return error;
        }
      }}
    >
      {(formik) => {
        if (loadingForm) {
          return (
            <Spinner
              containerHeight={containerFormCsv?.current?.clientHeight}
            />
          );
        }
        return (
          <Form formik={formik}>
            <Row ref={containerFormCsv}>
              <Col md={12} className="order-by-csv__upload-form-input">
                <Select
                  id="select"
                  name="select"
                  label={intl.formatMessage({
                    id: 'controlpanel.orders.csv.select.upload.label'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'controlpanel.orders.csv.select.upload.placeholder'
                  })}
                  options={getOptionsPayment()}
                  disabled={!canSendFile}
                />
              </Col>
              <Col md={12} className="order-by-csv__upload-form-input">
                <InputFile
                  label={intl.formatMessage({
                    id: 'controlpanel.orders.csv.input.upload.label'
                  })}
                  name="file"
                  placeholder={intl.formatMessage({
                    id: 'controlpanel.orders.csv.input.upload.placeholder'
                  })}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  svg={{ icon: 'file-import' }}
                  disabled={!canSendFile}
                />
              </Col>
              <Col md={12} className="order-by-csv__upload-form-button">
                <Button
                  size="big"
                  type="primary"
                  typeButton="submit"
                  disabled={!(formik.isValid && formik.dirty && canSendFile)}
                  text={intl.formatMessage({
                    id: canSendFile
                      ? 'controlpanel.orders.csv.button.upload.text'
                      : 'controlpanel.global.orders.status.processing'
                  })}
                />
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}
