import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { Select, getSelectDefaultValues } from 'app/components/Select';
import { useFormikContext } from 'formik';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon/Icon';
import Popup from 'app/components/Popup/Popup';
import useDataProviderContext from 'app/hooks/useDataProviderContext';

import 'app/modules/OrderView/components/DeliveryNotesPopupProducts.scss';

export default function DeliveryNotesPopupProducts({ show, setShow }) {
  const intl = useIntl();
  const { products } = useDataProviderContext();
  const formik = useFormikContext();

  const handleClose = () => {
    setShow(false);
  };

  const handleCancel = () => {
    formik.setFieldValue(
      'selectedPackage',
      formik.initialValues.selectedPackage
    );
  };

  const options = products.map((item) => ({
    label: item.attributes.package,
    value: item.attributes.package
  }));

  const defaultValue = [
    {
      label: intl.formatMessage({
        id: 'controlpanel.global.bulk'
      }),
      value: ''
    }
  ];

  return (
    <Popup
      show={show}
      size="lg"
      header={
        <p className="bold">
          {intl.formatMessage({
            id: 'controlpanel.global.filter_by'
          })}
        </p>
      }
      body={
        <div className="row">
          {!formik?.values?.selectedPackage && (
            <div className="col-12">
              <p className="mb-2">
                {intl.formatMessage({
                  id: 'controlpanel.global.no_filters_selected'
                })}
              </p>
            </div>
          )}

          <div className="col-md-5">
            <Select
              disabled
              name="selectedPackage"
              id="selectedPackage"
              defaultValue={defaultValue}
            />
          </div>
          <div className="col-md-5">
            <Select
              key={formik?.values?.selectedPackage}
              name="selectedPackage"
              id="selectedPackage"
              placeholder={intl.formatMessage({
                id: 'controlpanel.global.placeholder.select'
              })}
              options={options}
              defaultValue={getSelectDefaultValues(options, [
                formik?.values?.selectedPackage
              ])}
            />
          </div>
          {formik?.values?.selectedPackage && (
            <div className="col-md-2">
              <Button
                icon={
                  <Icon icon="trash" color={Colors.bbSecondary} prefix="fad" />
                }
                onClick={handleCancel}
                size="big"
                tooltipText={intl.formatMessage({
                  id: 'controlpanel.global.delete_filter'
                })}
              />
            </div>
          )}
        </div>
      }
      footer={
        <Button
          text={intl.formatMessage({
            id: 'controlpanel.global.accept'
          })}
          type="primary"
          size="big"
          onClick={() => {
            handleClose();
          }}
        />
      }
      handleClose={handleClose}
    />
  );
}

DeliveryNotesPopupProducts.propTypes = {
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};
