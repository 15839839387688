import React from 'react';
import { Image } from 'react-bootstrap';

import { toAbsoluteUrl } from 'app/utils/toAbsoluteUrl';

export const getEcommerceLogo = (ecommerce) => (
  <Image
    src={toAbsoluteUrl(`/media/logos/${ecommerce}-logo.png`)}
    className={`ecommerce-logo ${ecommerce}-default`}
    fluid
  />
);
