import PropTypes from 'prop-types';

import { PROP_TYPES as iconProptypes } from 'app/components/Icon/utils';
import { PROP_TYPES as buttonPropTypes } from 'app/components/Button/utils';

export const PROP_TYPES = {
  background: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.string,
  header: PropTypes.node,
  content: PropTypes.node,
  infoIcon: PropTypes.shape(iconProptypes),
  closeIcon: PropTypes.shape(iconProptypes),
  button: PropTypes.shape(buttonPropTypes),
  dismissible: PropTypes.bool,
  actions: PropTypes.node
};

export const STRUCTURE_PROP_TYPES = {
  header: PropTypes.node,
  content: PropTypes.node,
  infoIcon: PropTypes.shape(iconProptypes),
  closeIcon: PropTypes.shape(iconProptypes),
  button: PropTypes.shape(buttonPropTypes),
  children: PropTypes.node,
  handleClose: PropTypes.func,
  className: PropTypes.string,
  dismissible: PropTypes.bool,
  actions: PropTypes.node,
  variant: PropTypes.string
};

export const ICON_PROPS = {
  infoIcon: PropTypes.shape(iconProptypes),
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.string
};

export const CLOSE_BTN_PROP_TYPES = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeIcon: PropTypes.shape(iconProptypes),
  handleClose: PropTypes.func
};

export const HEADER_PROPS = {
  header: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  closeIcon: PropTypes.shape(iconProptypes),
  handleClose: PropTypes.func,
  dismissible: PropTypes.bool
};

export const CONTENT_PROPS = {
  content: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  closeIcon: PropTypes.shape(iconProptypes),
  handleClose: PropTypes.func,
  dismissible: PropTypes.bool
};

export const ACTIONS_PROPS = {
  button: PropTypes.shape(buttonPropTypes),
  actions: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node
};
