import React from 'react';

import { Colors } from 'app/utils/colors';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';

import 'app/modules/OrderView/config/TableFormatters/ActionsFormatter/ActionsFormatter.scss';

export function ActionsFormatter(cell, row, rowIndex, formatExtraData) {
  const { navigate, intl } = formatExtraData;
  const { bbSecondary } = Colors;
  const { extraData, invoiceNumber } = row;

  return (
    <div className="actions-formatter">
      <Button
        icon={<Icon icon="eye" color={bbSecondary} prefix="fad" />}
        onClick={() =>
          navigate(`/orders?addFilters=true&invoice=${invoiceNumber}`)
        }
        size="medium"
        tooltipText={intl.formatMessage({
          id: 'controlpanel.global.view_associated_orders'
        })}
      />
      <Button
        icon={<Icon icon="download" color={bbSecondary} prefix="fad" />}
        onClick={() => window.open(extraData.attributes.link)}
        size="medium"
        tooltipText={intl.formatMessage({
          id: 'controlpanel.global.download_invoice'
        })}
      />
    </div>
  );
}
