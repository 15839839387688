import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { filter } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { useWindow } from 'app/hooks/useWindow.hook';
import Button from 'app/components/Button/Button';
import Collapse from 'react-bootstrap/Collapse';
import Icon from 'app/components/Icon';
import Step from 'app/modules/Shops/pages/Shopify/components/Step';

import 'app/modules/Shops/pages/Shopify/components/Process.scss';

export default function Process({ steps, hasHistory, academyLink = null }) {
  const intl = useIntl();
  const { width } = useWindow();
  const isSmall = width < 992;
  const [open, setOpen] = useState(isSmall);

  const stepActive = filter(steps, (step) => step?.status === 'CURRENT');
  const handleClick = () => setOpen(!open);

  const LINK_TO_ACADEMY = academyLink;
  return (
    <Row className="shopify-form__process">
      <Col
        sm={hasHistory ? { span: 12 } : { span: 10 }}
        md={hasHistory ? { span: 12 } : { span: 8 }}
        className="shopify-form__process-wrapper"
      >
        {isSmall === true && (
          <Collapse in={open}>
            <div className="collapse-step__active">
              <Step step={{ ...stepActive[0] }} />
            </div>
          </Collapse>
        )}

        <Collapse in={!open}>
          <div className="collapse-steps">
            {steps.map(({ key, color, ...step }, i) => (
              <React.Fragment key={key}>
                <Step step={{ ...step, color }} />
                {i < steps.length - 1 && (
                  <div className="shopify-form__process-arrow">
                    <Icon prefix="fad" icon="arrow-right" color={color} />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </Collapse>

        {isSmall === true && (
          <>
            <div
              onClick={handleClick}
              onKeyDown={handleClick}
              role="button"
              tabIndex={0}
            >
              <p className="big link">
                {open === false
                  ? intl.formatMessage({
                      id: 'controlpanel.payment_methods.credit_cards_detail.hidde_button'
                    })
                  : intl.formatMessage({
                      id: 'controlpanel.payment_methods.credit_cards_detail.show_button'
                    })}
              </p>
            </div>
            <Button
              className="button-view-more"
              text={intl.formatMessage({
                id: 'controlpanel.global.more_info'
              })}
              icon={<Icon icon="graduation-cap" color={Colors.bbSecondary} />}
              size="medium"
              onClick={() => window.open(LINK_TO_ACADEMY, '_blanck')}
            />
          </>
        )}
      </Col>
    </Row>
  );
}

Process.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      translationKey: PropTypes.string.isRequired
    })
  ).isRequired,
  hasHistory: PropTypes.bool.isRequired,
  academyLink: PropTypes.string
};
