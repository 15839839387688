import React, { createRef } from 'react';

import { Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { CLOSE_POPUP } from 'app/modules/MyAccount/pages/PaymentMethods/utils';
import { URLS } from 'app/config/urls';
import CreditCards from 'app/modules/MyAccount/pages/PaymentMethods/components/CreditCards';
import Divider from 'app/components/Divider';
import FormCard from 'app/components/FormCard';
import FormSpinner from 'app/components/FormSpinner';
import MyAccountLayout from 'app/modules/MyAccount/components/MyAccountLayout';
import Notification from 'app/components/Notification';
import PayPal from 'app/modules/MyAccount/pages/PaymentMethods/components/PayPal';
import Popup from 'app/components/Popup';
import usePaymentMethodsContext from 'app/modules/MyAccount/pages/PaymentMethods/context/PaymentMethodsContext';

import 'app/modules/MyAccount/pages/PaymentMethods/PaymentMethods.scss';

export default function PaymentMethods() {
  const intl = useIntl();
  const form = createRef();
  const container = createRef();

  const {
    loading,
    popupIsOpen,
    hasCreditCards,
    hasPaycomet,
    hasPayPal,
    popupContent: { popupHeader, popupBody, popupFooter },
    handleTogglePopup
  } = usePaymentMethodsContext();

  return (
    <MyAccountLayout currentPagePath={URLS.myAccountPaymentMethods}>
      {loading ? (
        <Col md={8} className="bb-payment-methods-bigbuy-page">
          <FormSpinner
            title="controlpanel.payment_methods.title"
            showButtons
            containerMaxHeight={container?.current?.clientHeight}
          />
        </Col>
      ) : (
        <>
          <Col md={8} className="bb-payment-methods-bigbuy-page">
            <FormCard
              title={intl.formatMessage({
                id: 'controlpanel.payment_methods.title'
              })}
            >
              <div
                className="bb-payment-methods-bigbuy-page__content"
                ref={container}
              >
                {!hasCreditCards && !hasPaycomet && !hasPayPal && (
                  <div className="bb-payment-methods-notification">
                    <Notification
                      header={intl.formatMessage({
                        id: 'controlpanel.payment_methods.notification_header'
                      })}
                      content={intl.formatMessage({
                        id: 'controlpanel.payment_methods.notification_body'
                      })}
                      variant="info"
                      button={null}
                    />
                  </div>
                )}
                {(hasCreditCards || hasPaycomet) && (
                  <>
                    <CreditCards />
                    <Divider
                      type="dash"
                      className="bb-payment-methods-bigbuy-page__divider"
                    />
                  </>
                )}
                <PayPal />
              </div>
            </FormCard>
          </Col>
          <Formik
            initialValues={{ setAsDefault: false }}
            onSubmit={() => form.current.dispatchEvent(new Event('submit'))}
          >
            <Popup
              show={popupIsOpen}
              handleClose={() => handleTogglePopup(CLOSE_POPUP)}
              header={popupHeader()}
              body={popupBody({ ref: form })}
              footer={popupFooter({
                onClose: () => handleTogglePopup(CLOSE_POPUP)
              })}
            />
          </Formik>
        </>
      )}
    </MyAccountLayout>
  );
}
