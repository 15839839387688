import PropTypes from 'prop-types';

import usePaymentMethods from 'app/hooks/usePaymentMethods.hook';

export default function PaymentFormatter({ paymentMethod }) {
  const paymentMethods = usePaymentMethods();
  return paymentMethods[paymentMethod].translation;
}

PaymentFormatter.propTypes = {
  paymentMethod: PropTypes.string.isRequired
};
