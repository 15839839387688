import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { size } from 'lodash';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Colors } from 'app/utils/colors';
import { URLS } from 'app/config/urls';
import Button from 'app/components/Button';
import ColumnReorderModal from 'app/components/ColumnReorderModal';
import DataTable from 'app/components/DataTable';
import DataTableFilters from 'app/components/DataTableFilters';
import DataTableFiltersTags from 'app/components/DataTableFiltersTags';
import DataTableTabs from 'app/components/DataTableTabs';
import Icon from 'app/components/Icon';
import PopupDownloadCSV from 'app/components/PopupDownloadCSV';
import SearchBar from 'app/components/SearchBar';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

export default function Body() {
  const intl = useIntl();
  const navigate = useNavigate();

  const {
    filters: { filters, parsedFiltersArray }
  } = useDataTableContext();
  const { submitForm } = useFormikContext();

  return (
    <div>
      <Row>
        <Col md={12} className="export-csv-wrapper">
          <Button
            className="button-order-csv"
            text={intl.formatMessage({
              id: 'controlpanel.order.button.realize_csv.text'
            })}
            type="default"
            size="medium"
            icon={<Icon icon="file-csv" color={Colors.bbSecondary} />}
            onClick={() => navigate(URLS.orderByCsv)}
          />

          <PopupDownloadCSV fileType="order" />
        </Col>
      </Row>
      <DataTableTabs />
      <Row className="bb-data-table__filters">
        <Col xs={12} md={5} className="bb-data-table__searchbar">
          <SearchBar
            placeholder={intl.formatMessage({
              id: 'controlpanel.orders.searchbar_placeholder'
            })}
            name="search"
            button={{
              onClick: submitForm,
              type: 'secondary',
              size: 'big',
              icon: <Icon icon="search" prefix="fas" color={Colors.bbWhite} />
            }}
            value={filters?.search || ''}
          />
        </Col>
        <Col xs={12} md={7} className="bb-data-table__filters-buttons">
          <DataTableFilters />
          <ColumnReorderModal />
        </Col>
        {size(parsedFiltersArray) > 0 && (
          <Col md={12}>
            <DataTableFiltersTags />
          </Col>
        )}
      </Row>
      <DataTable />
    </div>
  );
}
