import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Colors } from 'app/utils/colors';
import { formatDateToLocale } from 'app/utils/dates';
import { LOGOS_MAP } from 'app/modules/MyAccount/utils/parser';
import { useLang } from 'app/context/i18n';
import Button from 'app/components/Button';
import Icon from 'app/components/Icon';
import PopupDeletePaycomet from 'app/modules/MyAccount/pages/PaymentMethods/components/CreditCards/PopupDeletePaycomet';

import './CreditCardsDetail.scss';

export default function PaycometDetail({
  mobile,
  ownerName,
  lastFour,
  expiryMonth,
  brand
}) {
  const intl = useIntl();
  const lang = useLang();

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  return (
    <>
      <PopupDeletePaycomet
        show={showDeletePopup}
        setShow={setShowDeletePopup}
      />

      <Row className="bb-payment-methods-credit-cards__credit-cards-item">
        <Col>
          <Row>
            {mobile && (
              <Col
                className="bb-payment-methods-credit-cards__credit-cards-item--mobile-name"
                sm={6}
              >
                <p className="medium">{ownerName}</p>
              </Col>
            )}
            <Col
              className="bb-payment-methods-credit-cards__credit-cards-item--image"
              md={1}
            >
              {!mobile && (
                <img
                  src={
                    LOGOS_MAP[brand] ? LOGOS_MAP[brand] : LOGOS_MAP.CARD_DEFAULT
                  }
                  alt=""
                />
              )}
            </Col>
            <Col
              className="bb-payment-methods-credit-cards__credit-cards-item--info"
              md={6}
            >
              {mobile && (
                <img
                  src={
                    LOGOS_MAP[brand] ? LOGOS_MAP[brand] : LOGOS_MAP.CARD_DEFAULT
                  }
                  alt=""
                />
              )}
              <div className="bb-payment-methods-credit-cards__credit-cards-item--info-container">
                <p className="bb-payment-methods-credit-cards__credit-cards-item--info-title medium">
                  {lastFour}
                </p>
                <p className="bb-payment-methods-credit-cards__credit-cards-item--info-subtitle">
                  {`${intl.formatMessage({
                    id: 'controlpanel.payment_methods.credit_cards_detail.due_date_message'
                  })} ${formatDateToLocale(`${expiryMonth}`, lang, {
                    month: 'short',
                    year: 'numeric'
                  })}`}
                </p>
              </div>
            </Col>
            <Col
              className="bb-payment-methods-credit-cards__credit-cards-item--interface"
              md={5}
            >
              <div className="bb-payment-methods-credit-cards__credit-cards-item--actions">
                <Button
                  tooltipText={intl.formatMessage({
                    id: 'controlpanel.payment_methods.popup.delete_card'
                  })}
                  icon={
                    mobile ? null : (
                      <Icon
                        icon="trash"
                        color={Colors.bbSecondary}
                        size="lg"
                        className="bb-payment-methods-credit-cards__button-icon"
                        swapOpacity
                      />
                    )
                  }
                  text={
                    mobile
                      ? intl.formatMessage({
                          id: 'controlpanel.payment_methods.credit_cards_detail.delete_button'
                        })
                      : null
                  }
                  onClick={() => setShowDeletePopup(true)}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

PaycometDetail.propTypes = {
  ownerName: PropTypes.string.isRequired,
  expiryMonth: PropTypes.string.isRequired,
  lastFour: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired,
  brand: PropTypes.string.isRequired
};
