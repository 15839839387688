import { Colors } from 'app/utils/colors';

const {
  bbGrey3,
  bbGrey7,
  bbRed,
  bbRedLight,
  bbGreen,
  bbGreenLight,
  bbPurple,
  bbPurpleLight
} = Colors;

export const EDIT = 'edit';
export const CLONE = 'clone';
export const DELETE = 'delete';
export const DEFAULT = 'DEFAULT';
export const CONFIRMED = 'CONFIRMED';
export const REJECTED = 'REJECTED';
export const INCIDENCE = 'INCIDENCE';
export const IN_PROGRESS = 'IN_PROGRESS';
export const OUTDATED = 'OUTDATED';
export const ACTION_REQUIRED = 'ACTION_REQUIRED';

export const RED_ROW = ['INCIDENCE'];
export const GREY_ROW = ['REJECTED'];

export const ALLOWED_ACTIONS = {
  DEFAULT: [],
  CONFIRMED: [CLONE, DELETE],
  REJECTED: [],
  INCIDENCE: [CLONE, DELETE],
  IN_PROGRESS: [CLONE, EDIT, DELETE],
  OUTDATED: [],
  ACTION_REQUIRED: [CLONE, DELETE]
};

export const STATUS_MAP = {
  CONFIRMED: {
    translation: 'controlpanel.global.validated',
    color: bbGreen,
    backgroundColor: bbGreenLight
  },
  REJECTED: {
    translation: 'controlpanel.global.rejected',
    color: bbRed,
    backgroundColor: bbRedLight
  },
  INCIDENCE: {
    translation: 'controlpanel.global.incidence',
    color: bbRed,
    backgroundColor: bbRedLight
  },
  IN_PROGRESS: {
    translation: 'controlpanel.global.in_progress',
    color: bbPurple,
    backgroundColor: bbPurpleLight
  },
  OUTDATED: {
    translation: 'controlpanel.global.outdated',
    color: bbGrey3,
    backgroundColor: bbGrey7
  },
  ACTION_REQUIRED: {
    translation: 'controlpanel.global.incidence',
    color: bbRed,
    backgroundColor: bbRedLight
  }
};

export const STATUS_WITHOUT_EDIT_BUTTON = [
  INCIDENCE,
  REJECTED,
  OUTDATED,
  CONFIRMED,
  ACTION_REQUIRED
];

export const STATUS_WITHOUT_BUTTON_RESPONSE_CHAT = [CONFIRMED, REJECTED];
