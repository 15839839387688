import { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { getFiltersConfiguration } from 'app/modules/Orders/config/filters';
import { GetSelectStructure as getOrdersStatusSelectStructure } from 'app/hooks/useOrdersStatus.hook';
import { GetSelectStructure as getPaymentsSelectStructure } from 'app/hooks/usePaymentMethods.hook';
import useDataTableContext from 'app/components/DataTable/context/DataTableContext';

export const useFiltersConfiguration = () => {
  const intl = useIntl();
  const formik = useFormikContext();
  const {
    api: { apiResponse }
  } = useDataTableContext();
  const paymentMethods = getPaymentsSelectStructure();
  const statusOptions = getOrdersStatusSelectStructure();
  const maxTotalAmount = useMemo(
    () => apiResponse?.meta?.maxTotalAmount || 0,
    [apiResponse?.meta?.maxTotalAmount]
  );

  const completeConfiguration = useMemo(
    () =>
      getFiltersConfiguration({
        intl,
        formik,
        paymentMethods,
        statusOptions,
        maxTotalAmount
      }),
    [formik, intl, maxTotalAmount, paymentMethods, statusOptions]
  );

  const getComponentFilter = (targetFilter) =>
    completeConfiguration.find((filter) => filter.value === targetFilter)
      .component;

  const getOptionsToSelect = () =>
    completeConfiguration.map(({ component, ...filter }) => filter);

  return {
    completeConfiguration,
    getComponentFilter,
    getOptionsToSelect
  };
};
