import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import 'app/components/SelectedLayer/SelectedLayer.scss';

export default function SelectedLayer({
  initialSelected = false,
  onClick = () => null,
  children
}) {
  const [selected, setSelected] = useState(initialSelected);

  useEffect(() => {
    setSelected(initialSelected);
  }, [initialSelected]);

  const handleClick = () => {
    onClick();
    setSelected(!selected);
  };

  return (
    <div
      className={classNames('bb-selected-layer', {
        selected
      })}
      onClick={handleClick}
      role="button"
      tabIndex={0}
      onKeyDown={handleClick}
    >
      <div className="bb-selected-layer__children">{children}</div>
      <div className="bb-selected-layer__layer">
        <input
          type="checkbox"
          className="bb-selected-layer__layer-checkbox bb-checkbox"
          checked={selected}
          readOnly
        />
      </div>
    </div>
  );
}

SelectedLayer.propTypes = {
  children: PropTypes.node.isRequired,
  initialSelected: PropTypes.bool,
  onClick: PropTypes.func
};
